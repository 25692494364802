import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import IndentCommand from './pisaindentcommand';
import { INDENT } from './pisaindentcommand';
import { indentOptions } from './pisaindentui';
import { upcastStyleTagAttr, downcastStyleTagAttr } from '../utils';

export default class IndentEditing extends Plugin {

	constructor( editor ) {
		super( editor );

		editor.config.define( INDENT, {
			options: indentOptions
		} );
	}

	init() {
		const editor = this.editor;

		editor.model.schema.extend( 'paragraph', { allowAttributes: INDENT } );
		editor.model.schema.extend( 'listItem', { allowAttributes: INDENT } );

		downcastStyleTagAttr( editor, 'paragraph', INDENT, "margin-left" );
		downcastStyleTagAttr( editor, 'listItem', INDENT, "margin-left" );
		upcastStyleTagAttr( editor, "p", "margin-left", INDENT );
		upcastStyleTagAttr( editor, "div", "margin-left", INDENT );
		upcastStyleTagAttr( editor, "li", "margin-left", INDENT );

		// editor.commands.add( INDENT, new IndentCommand( editor ) );
	}

}
