/**
 * PiSA utility class
 */

 export default class PisaUtil {

     /**
      * converts a (string) value into an integer
      * @param {String} val the (string) value to be converted
      * @returns {Number} the converted integer or 0 if conversion failed
      */
     static getInt(val) {
        if ( !val ) {
            return 0;
        }
        try {
            var num = Number.parseInt(val);
            if ( Number.isInteger(num) ) {
                return num;
            }
        }
        catch ( err ) {
            // *gulp*
        }
        return 0;
     }

     /**
      * retrieves CSS dimensions of a DOM element
      * @param {Element} elm the DOM element
      * @param {Array<String>} props array of CSS property names to be consiered
      * @returns {Number} the CSS dimensions
      */
     static getCssDims(elm, props) {
        var dim = 0;
        var css = window.getComputedStyle(elm);
        props.forEach(p => {
            dim += PisaUtil.getInt(css[p]);
        });
        return dim;
     }

     static createCallback(tag, attr) {
    	 let f = function(modelElement, viewWriter) {
    		 let args = {};
    		 args[attr] = modelElement.getAttribute(attr);
    		 viewWriter.createContainerElement(tag, args);
    	 }
    	 return f;
     }

     static setInheritedValues(config, type) {
       if ( type == "e2e" ) {
         PisaUtil.setInheritedE2e(config);
       }
       if ( type == "a2a" ) {
         PisaUtil.setInheritedA2a(config);
       }
     }

     static setInheritedE2e(config) {
       for ( var entry in config ) {
         let hasInheritedVal = false;
         let attr;
         let tag;
         for ( var attribute in config[entry] ){
           if ( attribute == 'name') {
             tag = config[entry][attribute];
           }
         }
         for ( var attribute in config[entry] ){
           if ( config[entry][attribute] == 'inherit') {
             attr = attribute;
             hasInheritedVal = true;
           }
         }
         if ( entry == 'view' && hasInheritedVal ) {
           let entryFunction = '( modelElement, viewWriter ) => viewWriter.createContainerElement("' +
           tag + '", {"' + attr + '": modelElement.getAttribute("' + attr + '") } )';
           config[entry] = eval(entryFunction);
         }
       }
     }

     static setInheritedA2a(config) {
       for ( var entry in config ) {
         let hasInheritedVal = false;
         let tag;
         for ( var attribute in config[entry] ) {
           if ( attribute == 'key' ) {
             tag = config[entry][attribute];
           }
         }
         for ( var attribute in config[entry] ) {
           if ( config[entry][attribute] == 'inherit') {
             hasInheritedVal = true;
           }
         }
         if ( entry == 'view' && hasInheritedVal ) {
           let entryFunction = 'modelAttributeValue => ( { key: "' + tag + '", value: modelAttributeValue } )';
           config[entry] = eval(entryFunction);
         }
       }
     }

     static cleanConfig(config) {
       for ( var child in config ) {
         if ( PisaUtil.isInvalid( config[child] ) ) {
           //console.log('Deleting invalid ' + child);
           delete config[child];
         } else if ( typeof config[child] == "object" ) {
           PisaUtil.cleanConfig( config[child] );
           if ( PisaUtil.isInvalid( config[child] ) ) {
             //console.log('Deleting invalid ' + child);
             delete config[child];
           }
         }
       }
     }

     static isInvalid(variable) {
       if ( variable == '' || variable == null || variable == undefined ) {
         return true;
       }
       if ( typeof variable == "object" && typeof variable != "string" && PisaUtil.getChildCount(variable) == 0 ) {
         return true;
       }
       return false ;
     }

     static getChildCount( obj ){
       if ( !obj || typeof obj != "object" ){
         return 0;
       }
       let childCounter = 0;
       for(var child in obj) {
         if ( obj[child] ){
           childCounter++;
         }
       }
       return childCounter;
     }

     static getSize(obj){
         var size = 0, key;
         for (key in obj) {
             if (obj.hasOwnProperty(key)) size++;
         }
         return size;
     }

     /*
      * @ deprecated
      */
     static cleanConvConfig(config) {
       if( PisaUtil.getSize(config) == 0){
         return config;
       }
       for(var fstLvlChd in config){
         if( PisaUtil.isInvalid(config[fstLvlChd]) ){
           delete config[fstLvlChd];
         } else if( PisaUtil.getSize(config[fstLvlChd]) > 0 ) {
           for(var scdLvlChd in config[fstLvlChd]){
             if( PisaUtil.isInvalid(config[fstLvlChd][scdLvlChd]) ){
               delete config[fstLvlChd][scdLvlChd];
             } else if( PisaUtil.getSize(config[fstLvlChd][scdLvlChd]) > 0 ){
             for(var trdLvlChd in config[fstLvlChd][scdLvlChd]){
               if( PisaUtil.isInvalid(config[fstLvlChd][scdLvlChd][trdLvlChd]) ){
                 delete config[fstLvlChd][scdLvlChd][trdLvlChd];
               } else if ( PisaUtil.getSize(config[fstLvlChd][scdLvlChd][trdLvlChd]) > 0 ){
                 for(var frtLvlChd in config[fstLvlChd][scdLvlChd][trdLvlChd]) {
                   if( PisaUtil.isInvalid(config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd]) ){
                     delete config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd];
                   } else if ( PisaUtil.getSize(config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd]) > 0 ){
                     for(var fftLvlChd in config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd]) {
                       if( PisaUtil.isInvalid(config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd][fftLvlChd]) ){
                         delete config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd][fftLvlChd];
                       }
                     }
                   }
                   if( PisaUtil.getSize(config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd] ) == 0 ) {
                     delete config[fstLvlChd][scdLvlChd][trdLvlChd][frtLvlChd];
                   }
                 }
               }
               if( PisaUtil.getSize(config[fstLvlChd][scdLvlChd][trdLvlChd] ) == 0 ) {
                 delete config[fstLvlChd][scdLvlChd][trdLvlChd];
               }
             }
           }
           if( PisaUtil.getSize(config[fstLvlChd][scdLvlChd]) == 0) {
             delete config[fstLvlChd][scdLvlChd];
           }
         }
       }
       if( PisaUtil.getSize(config[fstLvlChd]) == 0 ) {
         delete config[fstLvlChd];
       }
     }
   }

 }
