/**
 * PiSA basics plugin collection
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaGlobals from './pisaglobals';
import PisaObjects from './pisaobjects';

/**
 * PiSA "basics" plugin providing global event handlers and additional properties bound to an editor instance
 */
export default class PisaBasics extends Plugin {

	static get requires() {
		return [ PisaGlobals, PisaObjects ];
	}

	static get pluginName() {
		return 'PisaBasics';
	}
}