import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { setButton, createButton, fire } from '../utils';
import { deleteAllIcon } from '../icons';
import { SELECTALL } from './pisaselectallcommand';
import SelectAllCommand from './pisaselectallcommand';
import DeleteAllCommand from './pisadeleteallcommand';

export const DELETEALL = "pisaDeleteAll";

export default class PisaDeleteAll extends Plugin {

	init() {
		const editor = this.editor;

		editor.commands.add( SELECTALL, new SelectAllCommand( editor ) );
		editor.commands.add( DELETEALL, new DeleteAllCommand( editor ) );

		this.editor.ui.componentFactory.add( DELETEALL, locale => {
			let button = createButton( deleteAllIcon,
				editor.objects.tooltips.getT( DELETEALL ), editor.locale );
			button.on( 'execute', () => fire( editor, "contentDelete" ) );

			editor.objects.focus._addExecuteFocus( button );

			return button;
			// return setButton( deleteAllIcon, "Remove everything", DELETEALL, "", editor );
		} );
	}
}
