import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';
import { ATTRIBUTE_OPERATION, MOVE_OPERATION } from './placeholderchangesregistrator';

export default class PlaceholderChangesHanlder extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		hostPlugin.editor.model.on( "_afterChanges", ( eventInfo, args ) => {
			hostPlugin.onAfterChanges( eventInfo, args );
		}, { priority: Number.MIN_SAFE_INTEGER } );

	}

	// ---------------------------------------------------------------------------

	onAfterChanges( eventInfo, args ) {
		if ( !this.react ) return this._cleanUp() && this.resetKeydownOnSelection();
		if ( !this.lastInsert ) return;
		let lastInsertCopy = Validator.clone( this.lastInsert );
		if ( Validator.isString( this.keyAtPlaceholderStart ) )
			lastInsertCopy.keyAtPlaceholderStart = this.keyAtPlaceholderStart;
		// this (the removing of the value of "last insert") should happen before
		// handling, so the "_afterChanges" that happens after the handling does
		// not trigger another handling inside it;
		this._cleanUp();
		this.resetKeydownOnSelection();
		this._handleContentChange( lastInsertCopy );
	}

	_handleContentChange( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) ) return;
		if ( Validator.isObject( lastInsertInfo.documentFragment ) )
			return this._handleDocumentFragmentInsertion( lastInsertInfo );
		if ( Validator.isObject( lastInsertInfo.operation ) ) {
			if ( lastInsertInfo.operationType == ATTRIBUTE_OPERATION ||
				Validator.is( lastInsertInfo.operation, ATTRIBUTE_OPERATION ) )
				return this._handleAttributeOperation( lastInsertInfo );
			if ( lastInsertInfo.operationType == MOVE_OPERATION ||
				Validator.is( lastInsertInfo.operation, MOVE_OPERATION ) )
				return this._handleMoveOperation( lastInsertInfo );
		}
		if ( Validator.isArray( lastInsertInfo.args ) &&
			lastInsertInfo.args.length == 2 )
			return this._handleCharacterInsertion( lastInsertInfo );
	}

	// ---------------------------------------------------------------------------

	_handleDocumentFragmentInsertion( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( !Validator.isObjectPath( lastInsertInfo, "lastInsertInfo.eventInfo" ) )
			return this._cleanUp();
		if ( this.someSelectionInMultilinePlaceholder )
			return this._handleDocumentFragmentInsertionInMultilinePlaceholder( lastInsertInfo );
		let eventInfo = lastInsertInfo.eventInfo;
		// let batch = lastInsertInfo.batch || this.lastUndoBatch;
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		let startInlinePlaceholder = Validator
			.isObjectPath( eventInfo, "eventInfo.return.start" ) ?
			this.getInlinePlaceholder( eventInfo.return.start ) : void 0;
		let endInlinePlaceholder = Validator
			.isObjectPath( eventInfo, "eventInfo.return.end" ) ?
			this.getInlinePlaceholder( eventInfo.return.end ) : void 0;
		this._handleTwoPlaceholderRemoval( startInlinePlaceholder, endInlinePlaceholder, batch );
		this._cleanUp();
	}

	_handleAttributeOperation( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( !Validator.isObjectPath( lastInsertInfo, "lastInsertInfo.operation" ) )
			return this._cleanUp();;
		if ( this.someSelectionInMultilinePlaceholder )
			return this._handleAttributeOperationInMultilinePlaceholder( lastInsertInfo );
		let operations = Validator
			.isObjectPath( lastInsertInfo.operation, "operation.batch.operations" ) &&
			Validator.isArray( lastInsertInfo.operation.batch.operations ) &&
			lastInsertInfo.operation.batch.operations.length > 0 ?
			lastInsertInfo.operation.batch.operations : void 0;
		if ( !Validator.isArray( operations ) )
			return this._cleanUp( true ) &&
				this._removePlaceholderFromOperation( lastInsertInfo.operation,
					lastInsertInfo.batch );
		// reverse even if selection is bacwards
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		operations.reverse().forEach( operation => {
			this._removePlaceholderFromOperation( operation, batch );
		} );
		this._cleanUp();
	}

	_handleMoveOperation( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( !Validator.isObjectPath( lastInsertInfo,
				"lastInsertInfo.operation.sourcePosition.path" ) ||
			!Validator.isArray( lastInsertInfo.operation.sourcePosition.path ) )
			return this._cleanUp();
		// let path = lastInsertInfo.operation.sourcePosition.path;
		// let position = this.objectsPosition._pathToPosition( path, false );
		// if ( !this._positionExists( position ) ) {
		// 	if ( path.length <= 1 ) return;
		// 	path = path.slice( 0, -1 );
		// 	position = this.objectsPosition._pathToPosition( path, false );
		// 	if ( !this._positionExists( position ) ) return;
		// };
		// let inlinePlaceholder = this.getInlinePlaceholder( position );
		let inlinePlaceholder = this.getInlinePlaceholder( lastInsertInfo.operation.sourcePosition );
		if ( !Validator.isObject( inlinePlaceholder ) )
			return this._handleMoveOperationInMultilinePlaceholder( lastInsertInfo );
		// if ( !Validator.isObject( inlinePlaceholder ) ) return this._cleanUp();
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		this.removeInlinePlaceholder( inlinePlaceholder, batch );
		this._cleanUp();
	}

	_handleCharacterInsertion( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( !Validator.isObjectPath( lastInsertInfo, "lastInsertInfo.args" ) ||
			!Validator.isArray( lastInsertInfo.args ) ||
			lastInsertInfo.args.length != 2 ) return this._cleanUp();
		let inlinePlaceholder = this.getInlinePlaceholder( lastInsertInfo.args[ 1 ] );
		if ( Validator.isString( lastInsertInfo.keyAtPlaceholderStart ) &&
			!Validator.isObject( inlinePlaceholder ) ) {
			inlinePlaceholder = this.getInlinePlaceholderAfter( lastInsertInfo.args[ 1 ] );
		}
		if ( !Validator.isObject( inlinePlaceholder ) )
			return this._handleCharacterInsertionInMultilinePlaceholder( lastInsertInfo );
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		this.removeInlinePlaceholder( inlinePlaceholder, batch );
		this._cleanUp();
	}

	// ---------------------------------------------------------------------------

	_handleTwoPlaceholderRemoval( firstPlaceholder, secondPlaceholder, batch ) {
		if ( !Validator.isObject( firstPlaceholder ) ) {
			if ( !Validator.isObject( secondPlaceholder ) ) return;
			return this.removeInlinePlaceholder( secondPlaceholder, batch );
		}
		if ( !Validator.isObject( secondPlaceholder ) ||
			firstPlaceholder == secondPlaceholder ) {
			return this.removeInlinePlaceholder( firstPlaceholder, batch );
		}
		// start and end are both in placeholders, but different ones;
		// we should make sure both are removed in the same batch
		// if ( !Validator.isObject( batch ) ) batch = this.lastUndoBatch || this.newBatch;
		if ( !Validator.isObject( batch ) ) batch = this.getRegisteredBatch();
		// console.log( batch );
		[ firstPlaceholder, secondPlaceholder ].forEach( placeholder => {
			this.removeInlinePlaceholder( placeholder, batch );
		} );
	}

	_removePlaceholderFromOperation( operation, batch ) {
		if ( !Validator.couldBe( operation, "Operation" ) ||
			!Validator.couldBe( operation.range, "Range" ) ) return;
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return;
		const positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );
		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ) return;
		const advancedPlacement = this.getAdvancedPositionToPlaceholderPlacement( {
			positions: positions
		} );
		if ( !advancedPlacement.startsInsideButNotAtStart &&
			!advancedPlacement.endsInsideButNotAtEnd ) return;
		let startInlinePlaceholder = Validator
			.isObjectPath( operation, "operation.range.start" ) ?
			this.getInlinePlaceholder( operation.range.start ) : void 0;
		let endInlinePlaceholder = Validator
			.isObjectPath( operation, "operation.range.end" ) ?
			this.getInlinePlaceholder( operation.range.end ) : void 0;
		this._handleTwoPlaceholderRemoval( startInlinePlaceholder,
			endInlinePlaceholder, batch );
	}

	_handleCharacterInsertionInMultilinePlaceholder( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( !Validator.isObjectPath( lastInsertInfo, "lastInsertInfo.args" ) ||
			!Validator.isArray( lastInsertInfo.args ) ||
			lastInsertInfo.args.length != 2 ) return this._cleanUp();
		let multilinePlaceholder = this.getMultilinePlaceholder( lastInsertInfo.args[ 1 ] );
		if ( !Validator.isObject( multilinePlaceholder ) ) return this._cleanUp();
		const editor = this.editor;
		// let batch = lastInsertInfo.batch || this.newBatch;
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		editor.model.enqueueChange( batch, writer => {
			this.removeMultilinePlaceholder( multilinePlaceholder, batch, writer );
		} );
		this._cleanUp();
	}

	_handleMoveOperationInMultilinePlaceholder( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return this._cleanUp();
		let anchorMultilinePlaceholder = this.getMultilinePlaceholder( selection.anchor );
		let focusMultilinePlaceholder = selection.isCollapsed ? void 0 :
			this.getMultilinePlaceholder( selection.focus );
		const editor = this.editor;
		// let batch = lastInsertInfo.batch || this.newBatch;
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		editor.model.enqueueChange( batch, writer => {
			this.removeMultilinePlaceholder( anchorMultilinePlaceholder, batch, writer );
			this.removeMultilinePlaceholder( focusMultilinePlaceholder, batch, writer );
		} );
		this._cleanUp();
	}

	_handleAttributeOperationInMultilinePlaceholder( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		if ( Validator.isObject( lastInsertInfo.operation ) &&
			Validator.isString( lastInsertInfo.operation.key ) && [
				"placeholderName", "placeholderValue", "placeholderLineNumber",
				"groupIndex", "placeholderChildIndex", "isPlaceholderContainer",
				"contentEditable"
			].indexOf( lastInsertInfo.operation.key ) >= 0 ) return this._cleanUp();
		const editor = this.editor;
		const blocks = [ ...editor.model.document.selection.getSelectedBlocks() ];
		// let batch = lastInsertInfo.batch || this.newBatch;
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		editor.model.enqueueChange( batch, writer => {
			for ( let block of blocks )
				this.removeMultilinePlaceholder( block, batch, writer );
		} );
		this._cleanUp();
	}

	_handleDocumentFragmentInsertionInMultilinePlaceholder( lastInsertInfo ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		if ( !Validator.isObject( lastInsertInfo ) )
			lastInsertInfo = Validator.clone( this.lastInsert );
		const editor = this.editor;
		const blocks = [ ...editor.model.document.selection.getSelectedBlocks() ];
		// let batch = lastInsertInfo.batch || this.newBatch;
		let batch = this.getRegisteredBatch( lastInsertInfo );
		// console.log( batch );
		editor.model.enqueueChange( batch, writer => {
			for ( let block of blocks )
				this.removeMultilinePlaceholder( block, batch, writer );
		} );
		this._cleanUp();
		editor.model._runPendingChanges(); // does not help
	}

	_removeMultilinePlaceholderFromOperation( operation, batch ) {
		// if ( !Validator.couldBe( operation, "Operation" ) ||
		// 	!Validator.couldBe( operation.range, "Range" ) ) return;
		// let selection = this.currentSelection;
		// if ( !Validator.isObject( selection ) ) return;
		// let startInlinePlaceholder = Validator
		// 	.isObjectPath( operation, "operation.range.start" ) ?
		// 	this.getMultilinePlaceholder( operation.range.start ) : void 0;
		// let endInlinePlaceholder = Validator
		// 	.isObjectPath( operation, "operation.range.end" ) ?
		// 	this.getMultilinePlaceholder( operation.range.end ) : void 0;
	}
	// ---------------------------------------------------------------------------

	_handleBeforeRegistration( eventInfo, args ) {
		let handled = false;

	}

}

// const ATTRIBUTES_TO_MIRROR = [ "onAfterChanges", "_handleContentChange",
// 	"_handleDocumentFragmentInsertion", "_handleAttributeOperation",
// 	"_handleMoveOperation", "_handleCharacterInsertion",
// 	"_handleTwoPlaceholderRemoval", "_removePlaceholderFromOperation",
// 	"_handleBeforeRegistration"
// ];
