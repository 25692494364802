import './theme/crashededitor.css';
import './theme/toolbarblocker.css';
import AttachmentObject from '../pisautils/attachmentobject';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';
import EventHelper from '../pisautils/eventhelper';
import RedIconView from './rediconview';
import { /*createWaitingMessageBoxWithText,*/ createVanillaWaitingMessageBox } from '../pisamessagebox/createwaitingmessagebox';
import { lockEditingIcon, exclamationCircleIcon } from '../icons';
import { PISA_LOCK_WARNING, PISA_RSTARTED_WARNING, PISA_RESTART_MESSAGE } from './pisaeditorwatchdog';

export default class WatchdogUiManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
	}

	showMessageBox( forceMini = false, forceFull = false, forceMicro = false ) {
		this._updateWidgetId();
		if ( !Validator.isObjectPath( this, "this.args.container" ) ||
			!HtmHelper.isDiv( this.args.container ) ) return void 0;
		if ( HtmHelper.isDiv( this.messageBox ) ) {
			this.hideMessageBox();
			this.log( `When trying to show a message box over the editor with the` +
				` widget ID "${ this.wdgId }" another message box belonging to this` +
				` editor was found. The found message box will be removed and a new` +
				` one will be created.` );
			return this.showMessageBox();
		}
		this.addMessageBoxKeyBlocker();
		let text = Validator.isObjectPath( this, "this.editor.objects.tooltips" ) ?
			this.editor.objects.tooltips.getT( PISA_RESTART_MESSAGE ) : void 0;
		if ( !Validator.isString( text ) ) text = "Es ist ein unerwarteter Fehler" +
			" aufgetreten. Der Editor wird neu initialisiert.";
		if ( forceMini || forceFull || forceMicro ) {
			this.messageBox = createVanillaWaitingMessageBox( text, !!forceMini, forceMicro );
		} else {
			let containerHeight = HtmHelper.getBoundingHeight( this.args.container );
			let containerWidth = HtmHelper.getBoundingWidth( this.args.container );
			let forceMiniOrMicro = Validator.isPositiveNumber( containerHeight ) &&
				containerHeight < 250 && Validator.isPositiveNumber( containerWidth ) &&
				containerHeight <= containerWidth;
			this.messageBox = createVanillaWaitingMessageBox( text,
				forceMiniOrMicro && containerHeight > 90,
				forceMiniOrMicro && containerHeight <= 90 );
		}
		if ( !HtmHelper.isDiv( this.messageBox ) ) return void 0;
		HtmHelper.insertAsFirstChild( this.args.container, this.messageBox );
	}

	hideMessageBox() {
		this.removeMessageBoxKeyBlocker();
		if ( !HtmHelper.isDiv( this.messageBox ) ) {
			this.messageBox = void 0;
			return void 0;
		}
		this.messageBox.innerHTML = "";
		this.messageBox.remove();
		this.messageBox = void 0;
	}

	addMessageBoxKeyBlocker() {
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ) return void 0;
		if ( !Validator.isFunction( this.messageBoxKeyBlocker ) )
			this.messageBoxKeyBlocker = ( evt ) => {
				EventHelper.stop( evt );
			}
		editableElement.addEventListener( "keydown", this.messageBoxKeyBlocker );
	}

	removeMessageBoxKeyBlocker() {
		if ( !Validator.isFunction( this.messageBoxKeyBlocker ) ) return void 0;
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ) return void 0;
		editableElement.removeEventListener( "keydown", this.messageBoxKeyBlocker );
	}

	showToolbarBlocker() {
		this._updateWidgetId();
		if ( HtmHelper.isDiv( this.toolbarOverlay ) ) {
			this.removeToolbarBlocker();
			this.log( `When trying to show a toolbar blocker over the editor with` +
				` the widget ID "${ this.wdgId }" another toolbar blocker belonging` +
				` to this editor was found. The toolbar blocker will be removed and` +
				` a new one will be created.` );
			return this.showToolbarBlocker();
		}
		let toolbarView =
			Validator.isObjectPath( this, "this.editor.objects.toolbar.toolbar" ) ?
			this.editor.objects.toolbar.toolbar : void 0;
		if ( !Validator.isObject( toolbarView ) ||
			!HtmHelper.isHtmlElement( toolbarView.element ) ||
			!Validator.isFunction( toolbarView.element.getBoundingClientRect ) )
			return false;
		if ( Validator.isBoolean( toolbarView.isRendered ) &&
			!toolbarView.isRendered ) return false;
		if ( Validator.isBoolean( toolbarView.isVisible ) &&
			!toolbarView.isVisible ) return false;
		let height = HtmHelper.getBoundingHeight( toolbarView.element );
		if ( !Validator.isPositiveNumber( height ) ) return false;
		height = `${ height }px`;
		this.toolbarOverlay = document.createElement( "DIV" );
		this.toolbarOverlay.style.height = height;
		HtmHelper.addClass( this.toolbarOverlay, "cke5-read-only-toolbar-overlay" );
		HtmHelper.insertAsFirstChild( this.args.container, this.toolbarOverlay );
	}

	removeToolbarBlocker() {
		if ( !HtmHelper.isElement( this.toolbarOverlay ) ) {
			this.toolbarOverlay = void 0;
			return void 0;
		}
		this.toolbarOverlay.style.height = 0;
		this.toolbarOverlay.className = "";
		this.toolbarOverlay.innerHTML = "";
		if ( HtmHelper.isNode( this.args.container ) &&
			this.args.container.childNodes[ 0 ] == this.toolbarOverlay )
			this.args.container.removeChild( this.toolbarOverlay );
		this.toolbarOverlay.remove();
		this.toolbarOverlay = void 0;
	}

	showTopRightIcon( lockIcon = false ) {
		this._updateWidgetId();
		if ( Validator.isObject( this.topRightIconView ) &&
			HtmHelper.isDiv( this.topRightIconView.element ) ) {
			this.removeTopRightIcon();
			this.log( `When trying to show a warning icon in the right top corner` +
				` of the editor with the widget ID "${ this.wdgId }" another icon` +
				` belonging to this editor was found. The found icon will be removed` +
				` and a new one will be created.` );
			return this.showTopRightIcon( lockIcon );
		}
		let tooltipText = Validator.isObjectPath( this, "this.editor.objects.tooltips" ) &&
			Validator.isFunction( this.editor.objects.tooltips.getT ) ?
			this.editor.objects.tooltips.getT(
				!!lockIcon ? PISA_LOCK_WARNING : PISA_RSTARTED_WARNING ) : void 0;
		if ( !Validator.isString( tooltipText ) ) tooltipText = !lockIcon ?
			"Der Editor wurde auf Grund eines unerwarteten Fehlers neu initilaisiert." :
			"Der Text kann auf Grund eines unerwarteten Fehlers nicht editiert werden.";
		this.topRightIconView = new RedIconView( tooltipText,
			!!lockIcon ? lockEditingIcon : exclamationCircleIcon );
		this.topRightIconView.render();
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ||
			!HtmHelper.isNode( this.topRightIconView.element ) ||
			!HtmHelper.isNode( editableElement.parentElement ) ) return void 0;
		editableElement.parentElement.insertBefore(
			this.topRightIconView.element, editableElement );
	}

	removeTopRightIcon() {
		if ( !Validator.isObject( this.topRightIconView ) ||
			!HtmHelper.isDiv( this.topRightIconView.element ) ) return void 0;
		let topRightIconView = this.topRightIconView;
		this.topRightIconView = void 0;
		delete this.topRightIconView;
		topRightIconView.destroyIconView();
		topRightIconView.destroyTooltipView();
		topRightIconView.destroyElement();
		let editableElement = this.editableElement;
		if ( Validator.isObject( editableElement ) &&
			HtmHelper.isNode( editableElement.parentElement ) &&
			editableElement.parentElement.childNodes[ 0 ] ==
			topRightIconView.element )
			editableElement.parentElement.removeChild(
				topRightIconView.element );
		topRightIconView.originalDestroy();
		topRightIconView = void 0;
	}

	addToolbarRefreshListener() {
		if ( !Validator.isObject( this.editor ) ) return;
		this.onToolbarRefresh = () => {
			if ( !Validator.isObject( this.toolbarOverlay ) ) return;
			this.removeToolbarBlocker();
			this.showToolbarBlocker();
		};
		this.editor.on( "pisaToolbarRefresh", this.onToolbarRefresh );
	}

	hideToolbar() {
		if ( !Validator.isObjectPath( this, "this.editor.objects.toolbar" ) ||
			!Validator.isFunction( this.editor.objects.toolbar.hide ) ||
			!Validator.isFunction( this.editor.psaForceLayout ) ) return false;
		this.editor.objects.toolbar.hide();
		if ( this.editor.objects.toolbar.state != "hidden" ) return false;
		this.editor.psaForceLayout();
		return true;
	}

}
