import EmptyView from '../pisadropdown/emptyview';

export default class HourglassContainerView extends EmptyView {

	constructor( classesList = [] ) {
		super( classesList );
		this.extendTemplate( {
			attributes: {
				class: [ "hourglass-squares-container" ]
			}
		} );
	}

	focus() {
		//TODO add override annotation
		return void 0;
	}

}
