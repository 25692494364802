import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import DowncastWriter from '@ckeditor/ckeditor5-engine/src/view/downcastwriter';

// const START_WITH = "startWith";
const DATA_START_WITH = "data-start-with";

export default class PisaEmptyParagraphEditing extends Plugin {

	init() {
		const editor = this.editor;
		this.downcastWriter = new DowncastWriter();

		editor.model.schema.extend( 'paragraph', {
			allowAttributes: [ DATA_START_WITH, "bold" ]
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "p",
				key: DATA_START_WITH
			},
			model: DATA_START_WITH
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "div",
				key: DATA_START_WITH
			},
			model: DATA_START_WITH
		} );

		editor.conversion.for( 'downcast' ).attributeToAttribute( {
			model: {
				name: "paragraph",
				key: DATA_START_WITH
			},
			view: DATA_START_WITH
		} );

		editor.conversion.for( 'downcast' ).attributeToAttribute( {
			model: {
				name: "paragraph",
				key: "bold"
			},
			view: ( modelAttributeValue ) => {
				let elementDefinition = { name: "div", attributes: {} };
				elementDefinition.attributes[ DATA_START_WITH ] = "bold";
				// this.downcastWriter.setAttribute( DATA_START_WITH, "bold", data.item );
				return elementDefinition;
			}
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:bold:paragraph`,
				( evt, data, conversionApi ) => {
					if ( isElementInvalidOrHasChildren( data ) ) return;
					let oldValue = data.item.getAttribute( DATA_START_WITH ) ||
						data.item._attrs.get( DATA_START_WITH ) || "";
					// remove old value in any case
					let newValue = oldValue.replace( /bold;/gi, "" );
					// add new value only if it is requested
					if ( data.attributeNewValue == true && !data.attributeOldValue ) {
						newValue = oldValue.concat( "bold;" );
					}
					if ( newValue.length < 1 ) {
						this.downcastWriter.removeAttribute( DATA_START_WITH, data.item );
					} else {
						this.downcastWriter.setAttribute( DATA_START_WITH, newValue, data.item );
					}
					// console.log( data.item._attrs );
				} );
		} );

	}

}

function isElementInvalidOrHasChildren( data ) {
	if ( !data || typeof data != "object" || !data.item ||
		typeof data.item != "object" || data.item.name != "paragraph" ||
		!data.item._children || typeof data.item._children != "object" ||
		!data.item._children._nodes ||
		!( data.item._children._nodes instanceof Array ) ||
		data.item._children._nodes.length > 1 ) return true;
	let nodeList = data.item._children._nodes;
	let isEmpty = data.item.childCount == 0 || data.item.childCount == 1 &&
		nodeList.length == 1 && typeof nodeList[ 0 ]._data == "string" &&
		nodeList[ 0 ]._data.length == 0;
	return !isEmpty;
}
