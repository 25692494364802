import Command from '@ckeditor/ckeditor5-core/src/command';
import { CHANGE_TOOLBAR } from './pisachangetoolbarcommand';
import { SWITCH_VISIBILITY } from './pisaswitchvisibilitycommand';
import { enableAndExecute } from '../utils';

export const PLACE_BUTTON = "pisaPlacePlusButton";

export default class PisaPlaceButtonCommand extends Command {

	constructor( editor ) {
		super( editor, PLACE_BUTTON );
	}

	execute( options = {} ) {
		const editor = this.editor;
		let changeButton = getToolbarChangeButton( editor );
		if ( !changeButton ) return;
		const lastIndex = getLastIndexOnFirstLine( editor );
		editor.executeIf( CHANGE_TOOLBAR, { value: lastIndex } );
		editor.executeIf( SWITCH_VISIBILITY, { view: changeButton, visible: true } );
		// checkHeightAndRefresh( editor, lastIndex );
		// checkHeightAndRefresh( editor, lastIndex - 1 );

	}
}

function getLastVisibleIndex( toolbar ) {
	let toolbarItems = toolbar.items._items;
	let changeButton = toolbarItems[ toolbarItems.length - 1 ];
	let workingWidth = toolbar.element.clientWidth - changeButton.element.offsetWidth;
	let summarWidth = 0;
	for ( let i = 0; i < toolbarItems.length - 1; i++ ) {
		summarWidth += toolbarItems[ i ].element.offsetWidth + 4;
		if ( summarWidth >= workingWidth ) {
			return i;
		}
	}
	return ( toolbarItems.length - 1 );
}

export function getLastIndexOnFirstLine( editor ) {
	const toolbarItems = getToolbarItems( editor );
	if ( !toolbarItems ) return void 0;
	const toolbarElement = getToolbarElement( editor );
	if ( !toolbarElement ) return void 0;
	const changeButton = toolbarItems[ toolbarItems.length - 1 ];
	if ( !changeButton ) return void 0;
	const workingWidth = toolbarElement.clientWidth - changeButton.element.offsetWidth;
	if ( typeof workingWidth != "number" || workingWidth < 1 ) return void 0;
	let summarWidth = 0;
	for ( let i = 0; i < toolbarItems.length - 1; i++ ) {
		summarWidth += toolbarItems[ i ].element.offsetWidth + 4;
		// TODO check if it´s i or i - 1?
		if ( summarWidth >= workingWidth ) return i > 0 ? i - 1 : i;
	}
	return ( toolbarItems.length - 1 );
}

function checkHeightAndRefresh( editor, lastIndex ) {
	let toolbarHeight = editor.ui.view.toolbar.element.clientHeight;
	if ( toolbarHeight > 50 && lastIndex >= 0 ) {
		let toolbarItems = editor.ui.view.toolbar.items._items;
		let changeButton = toolbarItems[ toolbarItems - 1 ];
		enableAndExecute( editor, SWITCH_VISIBILITY, { view: toolbarItems[ lastIndex ], visible: false } );
		enableAndExecute( editor, SWITCH_VISIBILITY, { view: changeButton, visible: true } );
	}
}

export function getToolbarElement( editor ) {
	if ( !editor || typeof editor != "object" || !editor.ui ||
		typeof editor.ui != "object" || !editor.ui.view ||
		typeof editor.ui.view != "object" || !editor.ui.view.toolbar ||
		typeof editor.ui.view.toolbar != "object" ||
		!editor.ui.view.toolbar.element ||
		!( editor.ui.view.toolbar.element instanceof HTMLElement ) ) return void 0;
	return editor.ui.view.toolbar.element;
}

export function getToolbarItems( editor ) {
	if ( !editor || typeof editor != "object" || !editor.ui ||
		typeof editor.ui != "object" || !editor.ui.view ||
		typeof editor.ui.view != "object" || !editor.ui.view.toolbar ||
		typeof editor.ui.view.toolbar != "object" ||
		!editor.ui.view.toolbar.items ||
		typeof editor.ui.view.toolbar.items != "object" ||
		!( editor.ui.view.toolbar.items._items instanceof Array ) ||
		editor.ui.view.toolbar.items._items.length < 1 ) return void 0;
	return editor.ui.view.toolbar.items._items;
}

export function getToolbarChangeButton( editor ) {
	const toolbarItems = getToolbarItems( editor );
	if ( !toolbarItems ) return void 0;
	let changeButton = toolbarItems[ toolbarItems.length - 1 ];
	return !!changeButton && typeof changeButton == "object" ?
		changeButton : void 0;
}
