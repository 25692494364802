import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaBasics from '../pisabasics/pisabasics';
import { PLACEHOLDER } from './pisaplaceholderui';
import Placeholders from './placeholders';
// import { placeholderToLines, replaceGroup } from './showplaceholdercommand';
import { downcastStyleTagAttr, fire } from '../utils';
// import { placeholderEntriesToMap, setPlaceholderText } from './utils';
import DowncastWriter from '@ckeditor/ckeditor5-engine/src/view/downcastwriter';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';

export const DFN_TAG = 'dfn';

const FONT_STYLE = "font-style";
const STYLE = "style";
export const PLACEHOLDER_NAME = "title";
export const PLACEHOLDER_VALUE = "data-value";
export const OPENING_BRAKET = "{ ";
export const CLOSING_BRACKET = " }";
const CSS_CLASS = "class";
const ACTIVE_CLASSNAME = "pisa-active-placeholder";
const ATTRIBUTES = [ 'id', CSS_CLASS, STYLE, PLACEHOLDER_NAME, PLACEHOLDER_VALUE,
	'data-singleline', 'spellcheck' /*, 'contenteditable'*/
];

/**
 * There are 3 possible "placeholder types" and 2 "showing modes";
 *
 * showing modes:
 *		1. values shown
 *		2. titles shown
 *
 * placeholder types:
 *
 *		1. div placeholder container (isPlaceholderContainer = true) with only 1
 *		placeholder inside (dfn tag) that stores the key/title of a multiline
 *		placeholder on a separate line; must only appear in "titles" mode
 *
 *		2. div placeholder, that does not contain any dfn tags and represents a line
 *		from the value of a multiline placeholder; must only appear in "values" mode
 *
 *		3. dfn tag; can appear in both title and values mode; in values mode it can
 *		only apper inside a div that IS NOT a placeholder container; in titles mode
 *		it can appear in both a div that is not a placeholder container and in a
 *		div from (1)
 *
 * So we have 6 possible scenarios:
 *
 *		In titles mode:
 *			(1) type #1: div placeholder container with dfn tag -> show it;
 *			(2) type #2: div placeholder -> delete all lines except 1 if possible, save
 *							the not deleted one in groupTemp, clear the text inside and replace
 *							it when content is ready;
 *			(3) type #3: dfn tag -> delete the text content and replace it with the
 *							value of the "title" attribute;
 *
 *		In values mode:
 *			(4) type #1: div placeholder container with dfn tag -> save it in temp,
 *							clear the text inside it and replace it when content is ready;
 *			(5) type #2: div placeholder -> insert it but don't forget to do the registration;
 *			(6) type #3: dfn tag -> delete the text content and replace it with the
 *							value of the "data-value" attribute;
 *
 * Where scenarios are handled:
 *	1 -> standard conversion, div to paragraph to div, dfn to pisaPlaceholder to
 *			dfn, registration happens on downcast ( registerPlaceholderModelElement )
 *			TODO make sure that here it takes the title, not the text inside (replacement happens)
 *	2 -> upcast div to paragraph for removal, downcast paragraph to div for
 *			registration in groupTemp, registration in map happens
 *	3 -> standard conversion, dfn to pisaPlaceholder to dfn, deleting and replacing
 *			happens on dfn upcast, registration happens on pisaPlaceholder downcast
 *	4 -> text removal happens on dfn upcast, registration happens during
 *			replacement when content is ready (same as by "switching")
 *	5 -> div to paragraph to div, registration should happen on paragraph to div
 *			downcast
 *	6 -> standard conversion, dfn to pisaPlaceholder to dfn, deleting and replacing
 *			happens on dfn upcast, registration happens on pisaPlaceholder downcast
 *
 * scenarios 2 and 5 are implemented in pisablockdefinitionediting.js
 * because we register always modelElements, all registration happens in downcast
 */

export default class PisaDefinitionEditing extends Plugin {

	static get requires() {
		return [ PisaBasics, Placeholders ];
	}

	init() {
		const upcastWriter = new UpcastWriter();
		const downcastWriter = new DowncastWriter();
		const editor = this.editor;
		const dataProcessor = editor.data.processor || editor.getPsaDP();

		editor.on( "beforeSetContent", () => {
			editor.objects.placeholders.clearPlaceholderReferences();
		} );

		editor.on( "editorContentSet", () => {
			editor.objects.placeholders.clearTemp();
			editor.objects.placeholders.clearGroupTemp();
			editor.objects.placeholders.clearHtmlInlineTemp();
			editor.objects.placeholders.assurePlaceholderIntegrity();
			// editor.objects.placeholders.assureSingleLineValidity();
			editor.objects.placeholders.refreshToggle();
			// TODO we can remove this last part when we are sure that all placeholders'
			// content is right, with existing blob references
			editor.objects.placeholders.requestBlobTranslation();
		} );

		editor.model.schema.register( PLACEHOLDER, {
			allowIn: [ "$block", "$tableCell", "$clipboardHolder" ],
			// isObject: true, // if this is true, the editor will handle every inline placeholder as a block and will try to select the whole placeholder
			// isLimit: true, // if this is true, the editor will handle every inline placeholder as a block and will try to select the whole placeholder
			allowAttributes: ATTRIBUTES.concat( FONT_STYLE )
		} );

		editor.model.schema.extend( '$text', { allowIn: PLACEHOLDER } );
		editor.model.schema.extend( 'softBreak', { allowIn: PLACEHOLDER } );
		editor.model.schema.extend( 'pisaImage', { allowIn: PLACEHOLDER } );

		editor.conversion.for( 'upcast' ).elementToElement( {
			view: DFN_TAG,
			model: ( viewElement, modelWriter ) => {
				let placeholder = modelWriter.createElement( PLACEHOLDER );
				modelWriter.setAttribute( "spellcheck", false, placeholder );
				// modelWriter.setAttribute( "contenteditable", false, placeholder );
				upcastWriter.removeChildren( 0, viewElement._children.length, viewElement );
				if ( !!editor.objects && typeof editor.objects == "object" &&
					!!editor.objects.placeholders &&
					typeof editor.objects.placeholders == "object" &&
					editor.objects.placeholders.titlesShown ) {
					let text = viewElement._attrs.get( PLACEHOLDER_NAME );
					text.indexOf( OPENING_BRAKET ) != 0 ? text = OPENING_BRAKET + text + CLOSING_BRACKET : void 0;
					viewElement._attrs && viewElement._attrs.set( CSS_CLASS, ACTIVE_CLASSNAME );
					viewElement._classes && viewElement._classes.add( ACTIVE_CLASSNAME );
					modelWriter.setAttribute( CSS_CLASS, ACTIVE_CLASSNAME, placeholder );
					modelWriter.appendText( text, placeholder );
					return placeholder;
				}
				viewElement._attrs && viewElement._attrs.set( CSS_CLASS, "" );
				viewElement._classes && viewElement._classes.delete( ACTIVE_CLASSNAME );
				modelWriter.setAttribute( CSS_CLASS, "", placeholder );
				modelWriter.removeAttribute( CSS_CLASS, placeholder );
				if ( isMultilinePlaceholder( viewElement ) ) return placeholder;
				let dataValue = viewElement._attrs ? viewElement._attrs.get( PLACEHOLDER_VALUE ) : "";
				let value = dataProcessor._isEncoded64( dataValue ) ?
					dataProcessor._decode64( dataValue ) : dataValue;
				dataProcessor._isHtmStr( value ) ?
					editor.objects.placeholders.htmlInlineTemp.push( placeholder ) :
					modelWriter.appendText( value, placeholder );
				return placeholder;
			}
		} );

		editor.conversion.for( 'downcast' ).elementToElement( {
			model: PLACEHOLDER,
			view: ( modelElement, viewWriter ) => {
				// TODO use createAttributeElement instead of createContainerElement
				let placeholder = viewWriter.createContainerElement( DFN_TAG );
				viewWriter.setAttribute( "spellcheck", false, placeholder );
				// viewWriter.setAttribute( "contenteditable", false, placeholder );
				if ( !!editor.objects && typeof editor.objects == "object" &&
					!!editor.objects.placeholders &&
					typeof editor.objects.placeholders == "object" &&
					editor.objects.placeholders.titlesShown ) {
					modelElement._attrs && modelElement._attrs.set( CSS_CLASS, ACTIVE_CLASSNAME );
					viewWriter.addClass( ACTIVE_CLASSNAME, placeholder );
					viewWriter.setAttribute( CSS_CLASS, ACTIVE_CLASSNAME, placeholder );
				} else {
					modelElement._attrs && modelElement._attrs.set( CSS_CLASS, "" );
					viewWriter.removeClass( ACTIVE_CLASSNAME, placeholder );
					viewWriter.setAttribute( CSS_CLASS, "", placeholder );
					viewWriter.removeAttribute( CSS_CLASS, placeholder );
				}
				if ( isMultilinePlaceholder( modelElement ) &&
					getChildCount( modelElement ) == 0 &&
					!editor.objects.placeholders.titlesShown ) {
					// we are in "values" mode and there is a placeholder container with
					// an empty multiline placeholder that needs to be replaced with
					// placeholder lines, so we register the model in our "temp" array
					editor.objects.placeholders.temp.push( modelElement );
				} else {
					registerPlaceholderModelElement( editor, modelElement );
				}
				return placeholder;
			}
		} );

		ATTRIBUTES.forEach( attribute => {
			editor.conversion.for( 'downcast' ).attributeToAttribute( {
				model: {
					name: PLACEHOLDER,
					key: attribute
				},
				view: attribute
			} );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: DFN_TAG,
					key: attribute
				},
				model: attribute
			} );
		} );

		downcastStyleTagAttr( editor, PLACEHOLDER, FONT_STYLE, FONT_STYLE );
	}
}

export function registerPlaceholderModelElement( editor, modelElement ) {
	let dataProcessor = editor.data.processor || editor.getPsaDP();
	let title = modelElement._attrs.get( "title" ) || "";
	if ( !title ) {
		console.warn( "A placeholder without title can not be registered." );
		return;
	}
	let value = modelElement._attrs.get( "data-value" ) || "";
	// there is no way (? or is it) to know/make sure if the value is already encoded,
	// however it most likely is (if noone inserts very specific but broken htm
	// through other sources), so we will assume that this is the encoded value.
	let wasEncoded = !value.match( /(\s)/g ) || value.match( /(\s)/g ).length <= 0;
	// TODO we should limit getting those values (decodedValue, value) only for
	// when the placeholder is not registered yet or they are not set.
	let decodedValue = value;
	if ( wasEncoded ) {
		try {
			decodedValue = dataProcessor._decode64( value );
		} catch ( e ) {
			wasEncoded = false;
		}
	}
	// do not make an else here please
	if ( !wasEncoded ) {
		try {
			value = dataProcessor._encode64( decodedValue );
		} catch ( e ) {
			console.warn( `Could not understand if the value for the placeholder ${ title }=` +
				`"${ value }" is encoded or not.` );
		}
	}
	let isSingleLine = modelElement._attrs.get( "data-singleline" ) != "false" &&
		modelElement._attrs.get( "data-singleline" ) != false;
	// TODO attach listener to the model element, so when it is deleted ( by cut,
	// backspace etc.) it will be removed from the map
	registerPlaceholder( editor, title, decodedValue, value, isSingleLine, modelElement );
}

export function registerPlaceholder( editor, key, unicodeValue, base64Value, singleLine, modelElement ) {
	let entryValue = getUpdatedEntryValue( editor, key, unicodeValue, base64Value, singleLine, modelElement );
	// overwriting
	editor.objects.placeholders.map.set( key, entryValue );
}

export function getUpdatedEntryValue( editor, key, unicodeValue, base64Value, singleLine, modelElement ) {
	let entryValue = editor.objects.placeholders.map.get( key );
	if ( entryValue == undefined ) entryValue = {};
	// it is unclear if, for the case when the placeholder was already registered,
	// we should check if the values also match and update them; as for now, we will
	// not do any of that and just register the (model) element
	// if ( entryValue.unicodeValue == undefined ) entryValue.unicodeValue = unicodeValue;
	// if ( entryValue.base64Value == undefined ) entryValue.base64Value = base64Value;
	// if ( entryValue.singleLine == undefined ) entryValue.singleLine = singleLine;

	// update
	if ( entryValue.unicodeValue == undefined ||
		( entryValue.unicodeValue != unicodeValue && !!unicodeValue &&
			typeof unicodeValue == "string" && unicodeValue.length > 0 ) )
		entryValue.unicodeValue = unicodeValue;
	// update
	if ( entryValue.base64Value == undefined ||
		( entryValue.base64Value != base64Value && !!base64Value &&
			typeof base64Value == "string" && base64Value.length > 0 ) )
		entryValue.base64Value = base64Value;
	// update
	if ( entryValue.singleLine == undefined || typeof entryValue.singleLine != "boolean" ||
		( typeof singleLine == "boolean" && entryValue.singleLine != singleLine ) )
		entryValue.singleLine = singleLine;
	if ( entryValue.elements == undefined ) entryValue.elements = [];
	if ( entryValue.occurences == undefined ) entryValue.occurences = 0;

	let isInList = false;
	// we do that because, for some reason (?), the model conversion for one
	// element happens multiple times but we only need him there once
	for ( let element of entryValue.elements ) {
		if ( element != modelElement ) continue;
		isInList = true;
		break;
	}
	if ( !isInList ) {
		entryValue.elements.push( modelElement );
		entryValue.occurences++;
	}
	return entryValue;
}

function isMultilinePlaceholder( viewOrModelElement ) {
	if ( isFalse( viewOrModelElement, "data-singleline" ) ) return true;
	if ( viewOrModelElement.parent &&
		( viewOrModelElement.parent.name == "pisaPlaceholder" ||
			viewOrModelElement.parent.name == "paragraph" ||
			viewOrModelElement.parent.name == "div" ) &&
		( isTrue( viewOrModelElement.parent, "data-placeholder-container" ) ||
			isTrue( viewOrModelElement.parent, "isPlaceholderContainer" ) ||
			isFalse( viewOrModelElement.parent, "data-singleline" )
			// || hasValidAttribute( viewOrModelElement.parent, "data-title" ) ||
			// hasValidAttribute( viewOrModelElement.parent, "data-value" ) ||
			// hasValidAttribute( viewOrModelElement.parent, "data-placeholder-lines" ) ||
			// hasValidAttribute( viewOrModelElement.parent, "data-current-index" ) ||
			// hasValidAttribute( viewOrModelElement.parent, "data-group-index" )
		) ) return true;
	return false;
}

function isTrue( element, attributeName ) {
	return attributeIs( element, attributeName, "true" ) ||
		attributeIs( element, attributeName, true );
}

function isFalse( element, attributeName ) {
	return attributeIs( element, attributeName, "false" ) ||
		attributeIs( element, attributeName, false );
}

function attributeIs( element, attributeName, value ) {
	return !!element._attrs && element._attrs instanceof Map &&
		element._attrs.size > 0 &&
		element._attrs.get( attributeName ) == value;
}

function hasValidAttribute( element, attributeName ) {
	if ( !element || typeof element != "object" || !element._attrs ||
		!( element._attrs instanceof Map ) || element._attrs.size < 1 ) return false;
	let value = element._attrs.get( attributeName );
	if ( typeof value == "boolean" ) return true;
	if ( typeof value == "number" && value != NaN ) return true;
	if ( typeof value == "string" && value.length > 0 ) return true;
	return false;
}

function getChildCount( viewOrModelElement ) {
	if ( !viewOrModelElement || !viewOrModelElement._children ||
		!viewOrModelElement._children._nodes ) return 0;
	return viewOrModelElement._children._nodes.length;
}
