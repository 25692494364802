import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';
import { setButton } from '../utils';
import { SUPERSCRIPT } from './pisasuperscriptediting';
import { superscriptIcon } from '../icons';

export default class PisaSuperscriptUI extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( SUPERSCRIPT, new AttributeCommand( editor, SUPERSCRIPT ) );

		editor.ui.componentFactory.add( SUPERSCRIPT, locale => {
			return setButton( superscriptIcon,
				editor.objects.tooltips.getT( SUPERSCRIPT ), SUPERSCRIPT, "", editor );
		} );
	}
}
