import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { updateNodeListeners } from '../pisaimageresize/utils';
import { WIDTH, HEIGHT, LENGTH_WITH_PRIORITY } from './pisaimageediting';

export default class Images extends Plugin {

	init() {
		const editor = this.editor;
		// editor.counters = editor.counters || {};
		// editor.counters.imageId = 0;
		editor.objects = editor.objects || {};
		editor.objects.images = this;

		this.map = new Map();
		this.lastBatch = null;
	}

	updateMap() {
		const editor = this.editor;
		this._renewBatch();
		for ( let [ idKey, image ] of this.map ) {
			let id = image._attrs.get( "id" );
			if ( id && idKey && id == idKey ) continue;
			if ( !id ) {
				editor.model.enqueueChange( this.lastBatch,
					writer => writer.setAttribute( "id", idKey, image ) );
				continue;
			}
			this.map.set( id, image );
			this.map.delete( idKey );
		}
		this._removeAndNullifyBatch();
	}

	cleanMap() {
		this.map = new Map();
	}

	updateAllListeners() {
		this.updateMap();
		this.map.forEach( ( image, key ) => {
			this._updateNodeListeners( key );
		} )
	}

	_updateNodeListeners( key ) {
		updateNodeListeners( this.editor.wdgId, key );
	}

	removeAllListeners() {
		this.updateMap();
		for ( let [ key, image ] of this.map ) {
			let node = this._getImageNode( key );
			if ( !this._isImage( node ) ) continue;
			node.removeAttribute( "onMouseOver" );
			node.removeAttribute( "onClick" );
			node.removeAttribute( "onMouseOut" );
		}
	}

	removeAllIds() {
		this.updateMap();
		for ( let [ key, image ] of this.map ) {
			let node = this._getImageNode( key );
			if ( !this._isImage( node ) ) continue;
			node.removeAttribute( "id" );
		}
	}

	clearIdsAndListeners() {
		const editor = this.editor;
		const self = this;
		editor.model.change( writer => {
			self.map.forEach( ( image, key ) => {
				writer.removeAttribute( "onMouseOver", image );
				writer.removeAttribute( "onClick", image );
				writer.removeAttribute( "onMouseOut", image );
				writer.removeAttribute( "id", image );
			} );
		} );
	}

	setAllIds() {
		const editor = this.editor;
		editor.model.change( writer => {
			images.map.forEach( ( image, key ) => {
				writer.setAttribute( "id", key, image );
			} );
		} );
	}

	setAllBorders() {
		this.updateMap();
		for ( let [ key, image ] of this.map ) {
			let node = this._getImageNode( key );
			if ( !this._isImage( node ) ) continue;
			let styleBefore = node.getAttribute( "style" );
			let newStyle = this.getStyleWithoutBorder( styleBefore ) +
				"border: 5px solid transparent;";
			node.setAttribute( "style", newStyle );
			// node.style.border = "5px solid transparent";
		}
	}

	_getImageNode( nodeId ) {
		const node = window.document.getElementById( nodeId );
		return this._isImage( node ) ? node : void 0;
	}

	setAllDimensions() {
		const editor = this.editor;
		this._renewBatch();
		for ( let [ key, image ] of this.map ) {
			let node = this._getImageNode( key );
			if ( !this._isImage( node ) ) {
				console.warn( `Could not find IMG node with "id" attribute "${ key }" ` +
					`in the document. Image model with this "id" is registered in editor.objects.images.map.` );
				continue;
			}
			if ( !this._hasNoDimensionsSet( node ) ) continue;
			let nodeRect = node.getBoundingClientRect();
			let length = nodeRect[ LENGTH_WITH_PRIORITY ] ? LENGTH_WITH_PRIORITY :
				LENGTH_WITH_PRIORITY == WIDTH ? HEIGHT : WIDTH;
			let dimension = nodeRect[ length ];
			if ( !dimension ) {
				console.warn( `Could not assign any dimension to image without width ` +
					`and height inside CKE5. IMG node "id" attribute: ${ key }` );
				continue;
			}
			dimension < 5 ? dimension = 5 : dimension > 1000 ? dimension = 1000 : void 0;
			dimension = String( dimension ).concat( "px" );
			editor.model.enqueueChange( this.lastBatch, writer => {
				writer.setAttribute( length, dimension, image );
			} );
		}
		this._removeAndNullifyBatch();
		this.updateAllListeners();
	}

	_isImage( node ) {
		return node instanceof HTMLImageElement;
	}

	_hasNoDimensionsSet( node ) {
		if ( !this._isImage( node ) ) return false;
		return !node.style[ WIDTH ] && !node.style[ HEIGHT ] &&
			!node.getAttribute( WIDTH ) && !node.getAttribute( HEIGHT );
	}

	_renewBatch() {
		this.lastBatch = this.editor.model.createBatch();
	}

	_nullifyBatch() {
		this.lastBatch = null;
	}

	_removeBatchFromUndo() {
		let undo = this.editor.commands.get( "undo" );
		if ( !undo || typeof undo != "object" ||
			!( undo._stack instanceof Array ) || undo._stack.length < 1 ) return;
		let lastEntry = undo._stack[ undo._stack.length - 1 ];
		if ( !lastEntry || typeof lastEntry.batch != "object" ||
			lastEntry.batch != this.lastBatch ) return;
		undo._stack.pop();
	}

	_removeAndNullifyBatch() {
		this._removeBatchFromUndo();
		this._nullifyBatch();
	}

	getStyleWithoutBorder( styleString ) {
		if ( styleString.indexOf( "border:" ) < 0 ) return styleString;
		let styleBeforeBorder = styleString.substring( 0, styleString.indexOf( "border:" ) );
		let styleAfterBorder = styleString.substring(
			styleString.indexOf( "border:" ) + "border:".length );
		styleAfterBorder = styleAfterBorder.indexOf( ";" ) < 0 ? "" :
			styleAfterBorder.substring( styleAfterBorder.indexOf( ";" ) + 1 );
		while ( styleBeforeBorder.endsWith( ";" ) ) {
			styleBeforeBorder = styleBeforeBorder.substring( 0, styleBeforeBorder.length - 1 );
		}
		while ( styleAfterBorder.startsWith( ";" ) ) {
			styleAfterBorder = styleAfterBorder.substring( 1 );
		}
		let styleWithoutBorder = !this.isValidString( styleBeforeBorder ) ?
			( !this.isValidString( styleAfterBorder ) ? "" : styleAfterBorder ) :
			!this.isValidString( styleAfterBorder ) ? styleBeforeBorder + ";" :
			styleBeforeBorder + ";" + styleAfterBorder;
		if ( styleWithoutBorder.length > 0 && !styleWithoutBorder.endsWith( ";" ) )
			styleWithoutBorder = styleWithoutBorder + ";";
		return styleWithoutBorder;
	}

	isValidString( value ) {
		return !!value && typeof value == "string" && value.length > 0;
	}

}
