import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaDeleteAll from '../pisadeleteall/pisadeleteall';
import { DELETEALL } from '../pisadeleteall/pisadeleteall';
import PlainTextCommand from './pisaplaintextcommand';
import DisableButtonsCommand from './pisadisablebuttonscommand';
import Validator from '../pisautils/validator';
import { DISABLEBUTTONS } from './pisadisablebuttonscommand';
import { /*htmlToDocumentFragment,*/ enableAndExecute, createButton } from '../utils';
import { plainTextIcon, richTextIcon } from '../icons';
import { enableAllowedCommands } from './pisaplaintextcommand';

export const PLAINTEXT = "pisaPlainText";
export const SWITCH_TO_HTML = "switchToHtml";
export const SWITCH_TO_PLAIN = "switchToPlainText";

export default class PisaPlainText extends Plugin {

	static get requires() {
		return [ PisaDeleteAll ];
	}

	static get pluginName() {
		return PLAINTEXT.charAt( 0 ).toUpperCase() + PLAINTEXT.slice( 1 );
	}

	get lastBatch() {
		if ( this.batches.size < 1 ) return this._newBatch();
		return this.batches[ this.batches.length - 1 ];
	}

	get newToPlainBatch() {
		let newBatch = this.editor.model.createBatch();
		newBatch.toPlain = true;
		this.batches.push( newBatch );
		return newBatch;
	}

	get newToHtmlBatch() {
		let newBatch = this.editor.model.createBatch();
		newBatch.toPlain = false;
		this.batches.push( newBatch );
		return newBatch;
	}

	get _newBatch() {
		let newBatch = this.editor.model.createBatch();
		this.batches.push( newBatch );
		return newBatch;
	}

	get dataProcessor() {
		return Validator.isObjectPath( this.editor, "editor.data.processor" ) ?
			this.editor.data.processor : Validator.isFunction( this.editor.getPsaDP ) ?
			this.editor.getPsaDP() : void 0;
	}

	init() {
		const self = this;
		const editor = this.editor;
		this.isPlain = false;
		this.lastRedoIsRelevant = false;
		this.batches = [];
		editor.objects = editor.objects || {};
		editor.objects.plainText = this;

		editor.commands.add( DISABLEBUTTONS, new DisableButtonsCommand( editor ) );
		editor.commands.add( PLAINTEXT, new PlainTextCommand( editor ) );

		let undo = editor.commands._commands.get( "undo" );
		let redo = editor.commands._commands.get( "redo" );

		editor.ui.componentFactory.add( PLAINTEXT, locale => {
			const buttonView = createButton( richTextIcon,
				editor.objects.tooltips.getT( PLAINTEXT ), locale );
			buttonView.on( 'execute', () => {
				self.isPlain = self._getPlainState();
				enableAndExecute( editor, PLAINTEXT );
				self.isPlain = self._getPlainState();
				self._changeIcon( buttonView );
				if ( editor.objects && editor.objects.placeholders )
					editor.objects.placeholders.refreshToggle();
			} );

			editor.on( 'psaTextMode', ( e, plain ) => {
				self._onMode( buttonView, plain );
			} );

			if ( Validator.is( undo, "UndoCommand" ) &&
				Validator.is( redo, "RedoCommand" ) ) {
				undo.on( "execute", ( eventInfo ) => {
					if ( undo._stack.length < 1 ||
						this.batches.indexOf( undo._stack[ undo._stack.length - 1 ].batch ) < 0 ) return;
					this._toggleStateAndUi( buttonView );
				}, { priority: Number.MAX_SAFE_INTEGER } );

				this.listenTo( undo, 'revert', ( evt, undoneBatch, undoingBatch ) => {
					if ( this.batches.indexOf( undoneBatch ) < 0 ) return;
					if ( this.batches.indexOf( undoingBatch ) >= 0 ) return;
					this.batches.push( undoingBatch );
				}, { priority: Number.MAX_SAFE_INTEGER } );

				redo.on( "execute", ( eventInfo ) => {
					if ( redo._stack.length < 1 ||
						this.batches.indexOf( redo._stack[ redo._stack.length - 1 ].batch ) < 0 )
						return this.lastRedoIsRelevant = false;
					this._toggleStateAndUi( buttonView );
					this.lastRedoIsRelevant = true;
				}, { priority: Number.MAX_SAFE_INTEGER } );

				redo.on( "execute", ( eventInfo ) => {
					if ( !this.lastRedoIsRelevant ) return;
					this.lastRedoIsRelevant = false;
					if ( undo._stack.length < 1 ) return;
					let batch = undo._stack[ undo._stack.length - 1 ].batch;
					if ( this.batches.indexOf( batch ) >= 0 ) return;
					this.batches.push( batch );
				}, { priority: Number.MIN_SAFE_INTEGER } );
			}

			editor.objects.focus._addExecuteFocus( buttonView );

			return buttonView;
		} );
	}

	_toggleStateAndUi( buttonView ) {
		let textWasPlain = this.dataProcessor.isPlain();
		let makeTextPlain = !textWasPlain;
		this.dataProcessor.setPlainText( makeTextPlain );
		this._onMode( buttonView, makeTextPlain );
		if ( makeTextPlain ) this.editor.executeIf( DISABLEBUTTONS, {}, true );
		else enableAllowedCommands( this.editor );
		this.editor.fire( 'psaCttChn', true );
	}

	_getPlainState() {
		let editor = this.editor;
		return editor.getPsaDP().isPlain();
	}

	_changeIcon( buttonView ) {
		const editor = this.editor;
		buttonView.icon = this.isPlain ? plainTextIcon : richTextIcon;
		buttonView.tooltip = this.isPlain ?
			editor.objects.tooltips.getT( SWITCH_TO_HTML ) :
			editor.objects.tooltips.getT( SWITCH_TO_PLAIN );
	}

	_onMode( buttonView, plain ) {
		this.isPlain = plain || false;
		this._changeIcon( buttonView );
	}

}
