
import Command from '@ckeditor/ckeditor5-core/src/command';
import { getAllowedCommands } from './pisaplaintextcommand';

export const DISABLEBUTTONS = "pisaDisableButtons";

export default class DisableButtonsCommand extends Command {

	constructor( editor ) {
		super( editor, DISABLEBUTTONS );
	}

	execute( options = {} ) {
		const editor = this.editor;
		const commands = editor.commands._commands;
		const allowedCommands = getAllowedCommands( editor );
		for ( const [ key, unused ] of commands.entries() ) {
			if ( allowedCommands.indexOf( key ) >= 0 ) continue;
			this._disable( key );
		}
	}

	_disable( commandName ) {
		if ( !commandName || typeof commandName != "string" ) {
			console.warn( `Trying to disable invalid command ${ commandName }. Operation aborted.` );
			return;
		}
		const editor = this.editor;
		const command = editor.commands.get( commandName );
		if ( !command ) {
			console.warn( `Command with the name ${ commandName } was not registered. Nothing to disable.` );
			return;
		}
		command.set( "isEnabled", false );
		command.on( "change:isEnabled", ( eventInfo, name, value, oldValue ) => {
			value ? command.set( "isEnabled", false ) : void 0;
		} );
	}
}
