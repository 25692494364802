import EmptyView from '../pisadropdown/emptyview';

export default class MessageBoxOverlayView extends EmptyView {

	constructor( classesList = [] ) {
		super( classesList );
		this.extendTemplate( {
			attributes: {
				class: [ "cke5-message-box-overlay", "cke5-message-box-overlay-grey" ]
			}
		} );
	}

	focus() {
		//TODO add override annotation
		return void 0;
	}

}
