import AttachmentObject from '../pisautils/attachmentobject';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';

export default class WatchdogContentManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "editorContent",
			getCallback: () => {
				hostPlugin._updateLastContent();
				return hostPlugin.lastContent;
			},
			setCallback: ( newEditorContentValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "_dataProcessor",
			getCallback: () => {
				return Validator.isObjectPath( hostPlugin, "hostPlugin.editor.data.processor" ) ?
					hostPlugin.editor.data.processor : void 0;
			},
			setCallback: ( newDataProcessorValue ) => { return false; }
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "editingEnabled",
			getCallback: () => {
				if ( typeof hostPlugin._editingEnabled != "boolean" ) {
					let type = typeof hostPlugin._editingEnabled;
					return hostPlugin.log( `The property "_editingEnabled" of the watchdog for` +
						` the editor with the widget ID "${ hostPlugin.wdgId }" should be of type` +
						` "boolean", but the current value is of type "${ type }".` );
				}
				return hostPlugin._editingEnabled;
			},
			setCallback: ( newValue ) => {
				if ( typeof newValue != "boolean" ) {
					return hostPlugin.log( `The property "editingEnabled" of the watchdog for` +
						` the editor with the widget ID "${ hostPlugin.wdgId }" can not be directly` +
						` set. The value should be of type "boolean".` );
				}
				hostPlugin.log( `The property "editingEnabled" of the watchdog for the editor` +
					` with the widget ID "${ hostPlugin.wdgId }" can not be directly set to the` +
					` value "${ newValue }".` );
			}
		} );
	}

	_getEditorContentDiv() {
		return getContentDiv( this.editor );
	}

	_updateLastContent() {
		if ( this._setLastContentToCurrentData() ) return true;
		if ( this._setLastContentToInnerHtml() ) return true;
		return this._setLastContentToContainerInnerHtml();
	}

	_disableContentEditing() {
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ) return false;
		// editableElement.setAttribute( "contenteditable", "false" );
		editableElement.setAttribute( "contenteditable", "true" );
		HtmHelper.addClass( editableElement, "pisa-crashed-editor" );
		return true;
	}

	_rememberPlainTextState() {
		let dataProcessor = this._dataProcessor;
		if ( !Validator.isObject( dataProcessor ) ||
			!Validator.isFunction( dataProcessor.isPlain ) ) return false;
		this._lastEditorWasPlain = dataProcessor.isPlain();
		return true;
	}

	_restorePlainTextState() {
		let dataProcessor = this._dataProcessor;
		if ( !Validator.isObject( dataProcessor ) ||
			!Validator.isFunction( dataProcessor.setPlainText ) ) return false;
		const plainTextSet = dataProcessor.setPlainText( this._lastEditorWasPlain );
		if ( Validator.isBoolean( plainTextSet ) ) return plainTextSet;
		return true;
	}

	_selectEditorContent() {
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ) return false;
		const selection = window.getSelection();
		const range = document.createRange();
		range.selectNodeContents( editableElement );
		selection.removeAllRanges();
		selection.addRange( range );
	}

	_notifyAboutContentChanges() {
		if ( this.lastSetContent == this.lastContent ||
			!Validator.isString( this.lastContent ) ||
			!Validator.isString( this.lastSetContent ) ) return false;
		if ( !Validator.isObject( this.editor ) ||
			!Validator.isFunction( this.editor.fire ) ) return false;
		const dataProcessor = this._dataProcessor;
		const parameters = {
			ctt: this.lastContent,
			htm: Validator.isObject( dataProcessor ) &&
				Validator.isFunction( dataProcessor.isPlain ) ?
				!dataProcessor.isPlain() : true
		}
		this.editor.fire( "restartContentChanged", parameters );
		return true;
	}

	_setLastContentToCurrentData() {
		let dataProcessor = this._dataProcessor;
		if ( !Validator.isObject( dataProcessor ) ||
			!Validator.isFunction( dataProcessor.getCleanData ) )
			return this.log( `The last editor data for the editor with the widget` +
				` ID "${ this.wdgId }" could not be saved using the` +
				` data processor, because the function` +
				` "editor.data.processor.getCleanData" is invalid.`, false );
		let success = true;
		try {
			this.lastContent = String( dataProcessor.getCleanData() );
		} catch ( err ) {
			this.error( err, `The last editor data for the editor with the widget ID` +
				` "${ this.wdgId }" could not be saved using the data processor.` );
			success = false;
		}
		return success;
	}

	_setLastContentToInnerHtml() {
		let innerHtml = getInnerHtml( this.editor );
		if ( !Validator.isString( innerHtml ) )
			return this.log( `The last editor data for the editor with the widget` +
				` ID "${ this.wdgId }" could not be saved using the inner HTML of` +
				` the editor.`, false );
		this.lastContent = innerHtml;
		return true;
	}

	_setLastContentToContainerInnerHtml() {
		const message = `The last editor data for the editor with the widget` +
			` ID "${ this.wdgId }" could not be saved using the inner HTML of` +
			` the container for the editor.`;
		if ( !Validator.isObject( this.args ) ||
			!( this.args.container instanceof HTMLElement ) )
			return this.log( message, false );
		let contentDivs = this.args.container.getElementsByClassName( "ck-content" );
		if ( !Validator.isIterable( contentDivs ) || contentDivs.length < 1 )
			return this.log( message, false );
		contentDivs = [ ...contentDivs ]
			.filter( contentDiv => contentDiv instanceof HTMLDivElement );
		if ( contentDivs.length < 1 ) return this.log( message, false );
		const innerHTML = String( contentDivs[ 0 ].innerHTML );
		if ( !Validator.isString( innerHTML ) ) return this.log( message, false );
		this.lastContent = innerHTML;
		return true;
	}

	_setContentAfterRefresh() {
		if ( !Validator.isString( this.lastContent ) ) return false;
		let dataProcessor = this._dataProcessor;
		if ( !Validator.isObject( dataProcessor ) ||
			!Validator.isFunction( dataProcessor.setContent ) )
			return this.log( `The last editor for the editor with the widget ID` +
				` "${ this.wdgId }"data could not be set using the` +
				` data processor, because the function` +
				` "editor.data.processor.setContent" is invalid.`, false );
		dataProcessor.setContent( this.lastContent );
		this.editor.fire( "editorContentSet" );
		return true;
	}

	_removeEditorData() {
		const logMessage = `The editor data for the editor with the widget ID` +
			` "${ this.wdgId }" could not be removed during watchdog restart.`;
		if ( !Validator.isObjectPath( this, "this.editor.data" ) ||
			!Validator.isFunction( this.editor.data.set ) )
			return this.log( logMessage, false );
		let success = true;
		try {
			// this.editor.setData( "" );
			this.editor.data.set( "" );
		} catch ( err ) {
			success = false;
			this.error( err, logMessage );
		}
		return success;
	}

}

function getInnerHtml( editor ) {
	let contentDiv = getContentDiv( editor );
	return contentDiv instanceof HTMLElement ? String( contentDiv.innerHTML ) : "";
}

function getContentDiv( editor ) {
	if ( !Validator.isObjectPath( editor, "editor.ui.view.element.childNodes" ) ) return void 0;
	let childNodes = [ ...editor.ui.view.element.childNodes ];
	let mainDiv = void 0;
	for ( let childNode of childNodes ) {
		if ( childNode.tagName != "DIV" ||
			childNode.className.indexOf( "ck-editor__main" ) < 0 ) continue;
		mainDiv = childNode;
		break;
	}
	if ( !( mainDiv instanceof HTMLElement ) ) return void 0;
	let mainDivChildren = [ ...mainDiv.childNodes ];
	let ckContentDiv = void 0;
	for ( let childNode of mainDivChildren ) {
		if ( childNode.tagName != "DIV" ||
			childNode.className.indexOf( "ck-content ck-editor__editable" ) < 0 ) continue;
		ckContentDiv = childNode;
		break;
	}
	return ckContentDiv instanceof HTMLElement ? ckContentDiv : void 0;
}
