import Validator from './validator';

export default class AttachmentObject {

	constructor( hostObject ) {
		this.attachSelf( hostObject );
	}

	attachSelf( hostObject ) {
		this.assignFunctionsTo( this, hostObject );
	}

	assignFunctionsTo( sourceObject, hostObject ) {
		const propertiesToMirror = Object.getOwnPropertyNames(
				Object.getPrototypeOf( sourceObject ) )
			.filter( propertyName =>
				Validator.isFunction( sourceObject[ propertyName ] ) );
		this._mirrorAll( hostObject, propertiesToMirror );
	}

	mirror( hostPlugin, propertyName ) {
		if ( !Validator.isString( propertyName ) ) return;
		if ( !Validator.isObject( hostPlugin ) ) return;
		this._mirror( hostPlugin, propertyName );
	}

	mirrorIf( hostPlugin, propertyName ) {
		if ( !Validator.isString( propertyName ) ) return false;
		if ( !Validator.isObject( hostPlugin ) ) return false;
		return this._mirrorIf( hostPlugin, propertyName );
	}

	mirrorAll( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this.mirror( hostPlugin, propertyName );
		}
	}

	mirrorAllIf( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this.mirrorIf( hostPlugin, propertyName );
		}
	}

	_mirror( hostPlugin, propertyName ) {
		hostPlugin[ propertyName ] = this[ propertyName ];
	}

	_mirrorIf( hostPlugin, propertyName ) {
		if ( hostPlugin.hasOwnProperty( propertyName ) ) return false;
		this._mirror( hostPlugin, propertyName );
		return true;
	}

	_mirrorAll( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this._mirror( hostPlugin, propertyName );
		}
	}

	_mirrorAllIf( hostPlugin, propertiesToMirror ) {
		if ( !Validator.isArray( propertiesToMirror ) ) return;
		for ( const propertyName of propertiesToMirror ) {
			this._mirrorIf( hostPlugin, propertyName );
		}
	}

	_addUnmodifiableGetter( { hostPlugin, propertyName, getCallback, setCallback } ) {
		Object.defineProperty( hostPlugin, propertyName, {
			get: () => {
				return getCallback();
			},
			set: ( newPropertyValue ) => {
				setCallback( newPropertyValue );
			}
		} );
	}

	_addNonWritableProperty( hostPlugin, propertyName, propertyValue ) {
		Object.defineProperty( hostPlugin, propertyName, {
			value: propertyValue,
			writable: false
		} );
	}

	_sayHi() {
		console.groupCollapsed( this.constructor.name );
		console.trace( " " );
		console.groupEnd();
	}

}
