import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaListEditing from './pisalistediting';
import { setButton } from '../utils';
import { startList, continueList } from '../commandnames';
import { START_LIST, CONTINUE_LIST } from './pisalistediting';

// import undoIcon from '@ckeditor/ckeditor5-undo/theme/icons/undo.svg';
// import redoIcon from '@ckeditor/ckeditor5-undo/theme/icons/redo.svg';
import { numberingIcon, startListIcon } from '../icons';

export default class PisaList extends Plugin {

	static get requires() {
		return [ PisaListEditing ];
	}

	static get pluginName() {
		return 'PisaList';
	}

	init() {
		const editor = this.editor;

		editor.ui.componentFactory.add( startList, locale => {
			return setButton( startListIcon,
				editor.objects.tooltips.getT( START_LIST ), startList, "", editor );
		} );

		editor.ui.componentFactory.add( continueList, locale => {
			return setButton( numberingIcon,
				editor.objects.tooltips.getT( CONTINUE_LIST ), continueList, "", editor );
		} );
	}
}
