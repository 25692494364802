import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import FontSizeCommand from '@ckeditor/ckeditor5-font/src/fontsize/fontsizecommand';
import { addUpcastConversion, addUpcastContainerConversion } from '../utils';
import { FONT_TAG } from '../pisafonttag/pisafonttagediting';
export const FONT_SIZE = 'fontSize';
export const FONT_SIZE_ATTRIBUTE = 'font-size';

const CSS_LENGTH_UNITS = [ "vmin", "vmax", "pt", "px", "pc", "in", "cm", "mm",
	"rem", "em", "ex", "ch", "vw", "vh", "%"
];

const CSS_KEYWORDS_CONVERSION_MAP = getConversionMap();

export default class FontSizeEditing extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		const editor = this.editor;

		editor.objects = editor.objects || {};
		editor.objects.font = editor.objects.font || {};
		editor.objects.font.size = {};
		editor.objects.font.size.default = window.getComputedStyle(
			window.document.getElementsByTagName( "html" )[ 0 ] ).fontSize;
		editor.objects.font.size.default.indexOf( "px" ) < 0 ? void 0 :
			editor.objects.font.size.default = Number(
				editor.objects.font.size.default.replace( "px", "" ) * 0.75 );
		if ( !editor.objects.font.size.default )
			editor.objects.font.size.default = 12;

		editor.model.schema.extend( '$text', { allowAttributes: FONT_SIZE } );
		addDowncastConversion( editor );
		addUpcastConversion( editor, FONT_SIZE_ATTRIBUTE, FONT_SIZE, [ "span", "a" ] );
		addUpcastContainerConversion( editor, FONT_SIZE_ATTRIBUTE, FONT_SIZE );
		// editor.commands.add( FONT_SIZE, new FontSizeCommand( editor ) );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: FONT_TAG,
				key: "size"
			},
			model: {
				key: "fontSize",
				value: viewElement => {
					let size = viewElement.getAttribute( "size" );
					return size;
				}
			}
		} );
	}
}

function addDowncastConversion( editor ) {
	editor.conversion.for( 'downcast' ).attributeToElement( {
		model: {
			key: FONT_SIZE,
			name: '$text'
		},
		view: ( modelValue, viewWriter ) => {
			let value = convertValueToPtValue( modelValue, editor.objects.font.size.default );
			if ( typeof value != "string" ) return;
			return viewWriter.createAttributeElement( 'span', {
				style: FONT_SIZE_ATTRIBUTE.concat( ":" ).concat( value )
			} );
		}
	} );
}

function convertValueToPtValue( value, defaultValue ) {
	if ( typeof value == "number" ) value = String( value );
	if ( !value || typeof value != "string" || value.length < 1 ) return void 0;
	for ( let keyword of CSS_KEYWORDS_CONVERSION_MAP.keys() ) {
		if ( value.indexOf( keyword ) < 0 ) continue;
		value = CSS_KEYWORDS_CONVERSION_MAP.get( keyword );
		break;
	}
	value = normalizeFullValue( value );
	if ( typeof value != "string" ) return void 0;
	let numberPart = getNormalizedNumberPart( value );
	if ( typeof numberPart != "string" ) return void 0;
	let unitPart = getNormalizedUnitPart( value, numberPart );
	if ( typeof unitPart != "string" ) return void 0;
	let finalConvertedValue = getConvertedValue( numberPart, unitPart, defaultValue );
	return finalConvertedValue;
}

function normalizeFullValue( value ) {
	if ( typeof value == "number" ) value = String( value );
	if ( !value || typeof value != "string" ||
		value.length < 1 || !value.match( /\d/ ) ) return void 0;
	while ( value.length > 0 && !value.charAt( 0 ).match( /\d/ ) ) {
		value = value.substring( 1 );
	}
	value = value.replace( /[\,\.]+/g, "." );
	return value.length < 1 || !value.match( /\d/ ) ? void 0 : value;
}

function getNormalizedNumberPart( fullValue ) {
	if ( !fullValue || typeof fullValue != "string" || fullValue.length < 1 ||
		!fullValue.match( /\d/ ) ) return void 0;
	let numberPart = fullValue.match( /\d+(?:[\.\,]\d+)?/g );
	numberPart instanceof Array ? numberPart = numberPart[ 0 ] : numberPart = void 0;
	return !numberPart || typeof numberPart != "string" || numberPart.length < 1 ||
		!fullValue.startsWith( numberPart ) ? void 0 : numberPart;
}

function getNormalizedUnitPart( fullValue, numberPart ) {
	if ( !fullValue || typeof fullValue != "string" || fullValue.length < 1 ||
		!fullValue.match( /\d/ ) || !numberPart || typeof numberPart != "string" ||
		numberPart.length < 1 || !numberPart.match( /\d/ ) ) return void 0;
	let unitPart = fullValue.length > numberPart.length ?
		fullValue.substring( numberPart.length ) : "";
	unitPart = unitPart.toLowerCase().replace( /[^acehimnprtvwx%]+/g, "" );
	for ( let unit of CSS_LENGTH_UNITS ) {
		if ( unitPart.indexOf( unit ) < 0 ) continue;
		unitPart = unit;
		break;
	}
	return unitPart.length > 0 && CSS_LENGTH_UNITS.indexOf( unitPart ) < 0 ?
		void 0 : unitPart;
}

function getConvertedValue( numberValue, unit, defaultValue ) {
	let fullValue = void 0;
	let number = Number( numberValue );
	switch ( unit ) {
		case "":
			fullValue = getValueBasedOnDefault( number, defaultValue );
			break;
		case "pt":
			fullValue = numberValue + "pt";
			break;
		case "pc":
			fullValue = String( number * 12 ) + "pt";
			break;
		case "px":
			fullValue = String( number * 3 / 4 ) + "pt";
			break;
		case "in":
			fullValue = String( number * 72 ) + "pt";
			break;
		case "cm":
			fullValue = String( number * 72 / 2.54 ) + "pt";
			break;
		case "mm":
			fullValue = String( number * 7.2 / 2.54 ) + "pt";
			break;
		case "em":
		case "rem":
			fullValue = String( defaultValue * number ) + "pt";
			break;
		case "%":
			fullValue = String( defaultValue * number * 0.01 ) + "pt";
			break;
		case "vw":
		case "vh":
			let length = unit == "vh" ? "clientHeight" : "clientWidth";
			let clientLength = !( window.document.documentElement instanceof HTMLElement ) ? 0 :
				window.document.documentElement[ length ];
			fullValue = typeof clientLength == "number" && clientLength > 0 ?
				String( number * clientLength * 3 / 400 ) + "pt" : numberValue + unit;
			break;
		case "vmax":
		case "vmin":
			let clientWidth = window.document.documentElement instanceof HTMLElement ?
				window.document.documentElement.clientWidth : 0;
			let clientHeight = window.document.documentElement instanceof HTMLElement ?
				window.document.documentElement.clientHeight : 0;
			let minOrMaxValue = typeof clientWidth != "number" || clientWidth <= 0 ?
				( typeof clientHeight != "number" || clientHeight <= 0 ? 0 : clientHeight ) :
				typeof clientHeight != "number" || clientHeight <= 0 ? clientWidth :
				unit == "vmin" ? ( clientHeight > clientWidth ? clientWidth : clientHeight ) :
				clientHeight > clientWidth ? clientHeight : clientWidth;
			fullValue = typeof minOrMaxValue == "number" && minOrMaxValue > 0 ?
				String( number * minOrMaxValue * 3 / 400 ) + "pt" : numberValue + unit;
			break;
		default:
			// TODO: ex to pt and ch to pt conversion is missing
			fullValue = numberValue + unit;
			break;
	}
	return fullValue;
}


function getValueBasedOnDefault( number, defaultValue ) {
	if ( typeof number != "number" || typeof defaultValue != "number" ) return void 0;
	if ( number > 7 ) return String( number ) + "pt";
	let multiplicator = 1;
	switch ( number ) {
		case 1:
			multiplicator = 0.6;
			break;
		case 2:
			multiplicator = 0.88;
			break;
		case 3:
			multiplicator = 1;
			break;
		case 4:
			multiplicator = 1.2;
			break;
		case 5:
			multiplicator = 1.5;
			break;
		case 6:
			multiplicator = 2;
			break;
		case 7:
			multiplicator = 3;
			break;
		default:
			multiplicator = 1;
			break;
	}
	return String( defaultValue * multiplicator ) + "pt";
}

function getConversionMap() {
	let map = new Map();
	map.set( "xx-small", "1" );
	map.set( "x-small", "75%" );
	map.set( "smaller", "66%" );
	map.set( "small", "2" );
	map.set( "medium", "3" );
	map.set( "xxx-large", "7" );
	map.set( "xx-large", "6" );
	map.set( "x-large", "5" );
	map.set( "larger", "150%" );
	map.set( "large", "4" );
	map.set( "inherit", "3" );
	map.set( "initial", "3" );
	map.set( "unset", "3" );
	return map;
}
