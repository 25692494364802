
import View from '@ckeditor/ckeditor5-ui/src/view';
// import '../../theme/components/inputtext/inputtext.css';

export default class EmptyInputView extends View {

	constructor( locale, parameters ) {
		super( locale );

		this.set( 'value' );
		this.set( 'id' );
		this.set( 'placeholder', parameters.placeholder || "" );
    this.set( 'maxlength',  parameters.maxlength || "" );
    this.set( 'minlength',  parameters.minlength || "" );
    this.set( 'pattern',  parameters.pattern || "" );
    this.set( 'max',  parameters.max || "" );
    this.set( 'min',  parameters.min || "" );
    this.set( 'step',  parameters.step || "" );
		this.set( 'isReadOnly', false );
		this.set( 'hasError', false );
		this.set( 'ariaDesribedById' );

		const bind = this.bindTemplate;

		this.setTemplate( {
			tag: 'input',
			attributes: {
				type: parameters.type,
				class: parameters.cssClasses,
				id: bind.to( 'id' ),
        placeholder: bind.to( 'placeholder' ),
				maxlength: bind.to( 'maxlength' ),
        minlength: bind.to( 'minlength' ),
        pattern: bind.to( 'pattern' ),
        max: bind.to( 'max' ),
        step: bind.to( 'step' ),
        min: bind.to( 'min' ),
				readonly: bind.to( 'isReadOnly' ),
				'aria-invalid': bind.if( 'hasError', true ),
				'aria-describedby': bind.to( 'ariaDesribedById' )
			},
			on: {
				input: bind.to( 'input' )
			}
		} );

		/**
		 * Fired when the user types in the input. Corresponds to the native
		 * DOM `input` event.
		 *
		 * @event input
		 */
	}


	render() {
		super.render();

		const setValue = value => {
			this.element.value = ( !value && value !== 0 ) ? '' : value;
		};

		setValue( this.value );

		this.on( 'change:value', ( evt, name, value ) => {
			setValue( value );
		} );
	}

	select() {
		this.element.select();
	}

	focus() {
		this.element.focus();
	}

}
