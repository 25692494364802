import Command from '@ckeditor/ckeditor5-core/src/command';
import { isSingleLine, isInline } from './utils';
import {
	getMainRoot,
	removeLastUndoSteps,
	isValidModelPosition,
	areLiveSelectionRangesValid,
	getObjectProto,
	positionAtRoot,
	isPositionParentText,
	handleInvalidSelectionRanges,
	positionOutsideOfText /*, setSelection, saveLastRange*/
} from '../utils';
import { getUpdatedEntryValue, OPENING_BRAKET, CLOSING_BRACKET } from './pisadefinitionediting';
import { isInsidePlaceholder, isPositionInPlaceholder, setSelectionAfterNode } from './pisaplaceholderui';
import Text from '@ckeditor/ckeditor5-engine/src/model/text';

export const INSERT_PLACEHOLDER = "insertPlaceholder";

export const EMPTY_PLACEHODER_VALUE = '<span style="color:transparent;">&#8203;</span>';

export default class InsertPlaceholderCommand extends Command {

	constructor( editor ) {
		super( editor, INSERT_PLACEHOLDER );
	}

	refresh() {
		const editor = this.editor;
		let selection = editor.model.document.selection;
		this.isEnabled = !isPositionInPlaceholder( selection.anchor ) &&
			!isPositionInPlaceholder( selection.focus ) &&
			( selection._ranges && selection._ranges.length > 0 ?
				( !isPositionInPlaceholder( selection._ranges[ 0 ].start ) &&
					!isPositionInPlaceholder( selection._ranges[ 0 ].end ) ) :
				true );
	}

	execute( options = {} ) {
		const editor = this.editor;
		if ( isInsidePlaceholder( editor ) ) return;
		if ( !areLiveSelectionRangesValid( editor ) &&
			!setSelectionAfterNode( editor ) &&
			!handleInvalidSelectionRanges( editor ) ) {
			editor.objects.placeholders.renewBatch();
			removeLastUndoSteps( editor );
			return;
		};
		if ( !options.key || typeof options.key != "string" ) {
			console.warn( `Could not insert placeholder with invalid key.` );
			return;
		}
		// TODO prove if current selection or options.selection has any attributes or
		// even worse we are INSIDE another definition tag and insert it after
		let mainRoot = editor.model.document.getRoot() || getMainRoot( editor );
		if ( !mainRoot || typeof mainRoot._children != "object" ||
			typeof mainRoot._children._nodes != "object" ||
			mainRoot._children._nodes.length == 0 || ( mainRoot._children._nodes.length == 1 &&
				( typeof mainRoot._children._nodes[ 0 ] != "object" ||
					typeof mainRoot._children._nodes[ 0 ]._children != "object" ||
					typeof mainRoot._children._nodes[ 0 ]._children._nodes != "object" ||
					mainRoot._children._nodes[ 0 ]._children._nodes.length == 0
				) ) ) {
			editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
				writer.setSelection( positionAtRoot( editor, false ) );
			} );
			editor.objects.selection.update();
		}
		// editor.objects.selection.update();
		let selection = editor.objects.selection.lastInModel;
		let dataProcessor = editor.data.processor || editor.getPsaDP();
		if ( !dataProcessor ) return;
		// if the html string does not contain any html tags, set the html flag to false
		options.html && !dataProcessor._isHtmStr( options.value ) ?
			options.html = false : void 0;
		if ( !options.value || typeof options.value != "string" ) {
			console.warn( `The placeholder ${ options.key } has no value and ` +
				`will not be seen in values mode.` );
			options.value = EMPTY_PLACEHODER_VALUE;
			options.html = true;
		}
		let viewFrag = dataProcessor.strToViewFrag( options.value, !options.html );
		// let docFrag = dataProcessor.htmToDocFrag( options.html ?
		// 	options.value : dataProcessor._textStrToHtml( options.value ) );
		if ( !viewFrag ) return;
		// TODO test if fragment contains placeholders (dfn elements)  and convert them to <i>?
		editor.objects.placeholders.renewBatch();
		isInline( viewFrag ) ? insertDefinitionTag( editor, {
			title: options.key,
			value: options.value,
			selection: selection,
			singleLine: true
		} ) : insertMultilinePlaceholder( editor, options.key, options.value, selection );
		editor.objects.selection.update();
		editor.objects.placeholders.renewBatch();
		editor.objects.placeholders.refreshToggle();
	}
}

function insertDefinitionTag( editor, options ) {
	if ( !editor || typeof options != "object" || typeof options.title != "string" ||
		options.title.length <= 0 ) return;
	let selection = options.selection || editor.objects.selection.lastInModel;
	let position = getValidPosition( selection, editor );
	if ( !position ) return;
	position = position.clone();
	if ( position.textNode && !position.textNode.parent ) {
		console.warn( "Position's text node has no parent." );
		// console.log( position );
	}
	if ( typeof options.value != "string" || options.value.length <= 0 ) {
		options.value = EMPTY_PLACEHODER_VALUE;
		options.html = true;
	}
	insertDfnAtPosition( editor, position, options );
	// editor.objects.selection.update();
}

export function insertDfnAtPosition( editor, position, options ) {
	if ( !editor || !isValidModelPosition( position ) || isPositionParentText( position ) ) return;
	if ( typeof options != "object" || typeof options.title != "string" ||
		options.title.length <= 0 ) return;
	options.value = typeof options.value == "string" ? options.value : "";
	let dataProcessor = editor.data.processor || editor.getPsaDPP();
	if ( !dataProcessor ) return;
	options.encodedValue = dataProcessor._encode64( options.value );
	// at this point we are sure the value is inline, so if it has html tags, then
	// only inline (formatting) tags, so we do not need to test if it is multiline
	// or not
	if ( !dataProcessor._isHtmStr( options.value ) ) {
		let textNode = new Text( options.value );
		options.textNodes = [ textNode ];
	} else {
		let docFrag = dataProcessor.htmToDocFrag( options.value );
		if ( !docFrag ) return;
		options.textNodes = docFrag._children._nodes[ 0 ]._children._nodes;
	}
	options.position = position;
	insertDfn( editor, options );
}

function insertDfn( editor, options ) {
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		options.writer = writer;
		insertDfnInOneBatch( editor, options );
	} );
}

function insertDfnInOneBatch( editor, options ) {
	if ( !editor || typeof editor != "object" || !options ||
		typeof options != "object" ) return;
	options.placeholder = createPlaceholder( options );
	if ( !options.placeholder ) return;
	options.attributes = getAttributes( options );
	insertTextInPlaceholder( editor, options );
	activatePlaceholder( editor, options );
	if ( !isValidModelPosition( options.position ) ) return;
	removeSelection( editor, options.selection );
	insertPlaceholderByWriter( options );
}

function createPlaceholder( options ) {
	if ( !options || typeof options != "object" || !options.writer ||
		typeof options.writer != "object" ||
		typeof options.writer.createElement != "function" || !options.title ||
		typeof options.title != "string" || options.title.length < 1 ) return;
	return options.writer.createElement( "pisaPlaceholder", {
		title: options.title,
		"data-value": options.encodedValue || "",
		"data-singleline": typeof options.singleLine == "boolean" ?
			options.singleLine : true
	} );
}

function removeSelection( editor, selection ) {
	if ( !selection || typeof selection != "object" || !editor ||
		typeof editor != "object" || !editor.model || typeof editor.model != "object" ||
		typeof editor.model.deleteContent != "function" ) {
		console.warn( "Couldn't delete selected content before inserting placeholder" +
			" at position." );
		return;
	};
	if ( selection.isCollapsed != false ) return;
	try {
		editor.model.deleteContent( selection, { leaveUnmerged: true } );
	} catch ( e ) {
		console.warn( "Couldn't delete selected content before inserting " +
			"placeholder at position. Error:" );
		console.warn( e );
	}
}

function activatePlaceholder( editor, options ) {
	if ( !editor || typeof editor != "object" || !options ||
		typeof options != "object" || !editor.objects ||
		typeof editor.objects != "object" || !editor.objects.placeholders ||
		typeof editor.objects.placeholders != "object" ||
		!editor.objects.placeholders.titlesShown ) return;
	if ( !options.placeholder || typeof options.placeholder != "object" ) {
		console.warn( "Could not activate invalid placeholder." );
		return;
	}
	if ( !options.writer || typeof options.writer != "object" ||
		typeof options.writer.setAttribute != "function" ) {
		console.warn( "Could not activate placeholder without a valid writer." );
		return;
	}
	options.writer.setAttribute( "class", "pisa-active-placeholder",
		options.placeholder );
}

function insertPlaceholderByWriter( options ) {
	if ( !options || typeof options != "object" ) return;
	if ( !options.placeholder || typeof options.placeholder != "object" ) {
		console.warn( "Could not insert DFN tag in a single batch without a valid placeholder." );
		return;
	}
	if ( !options.position || typeof options.position != "object" ||
		typeof options.position.parent != "object" ||
		typeof options.position.parent.offsetToIndex != "function" ) {
		console.warn( "Could not insert DFN tag in a single batch without a valid position." );
		return;
	}
	if ( !options.writer || typeof options.writer != "object" ||
		typeof options.writer.insert != "function" ||
		typeof options.writer.setSelection != "function" ||
		typeof options.writer.setAttribute != "function" ) {
		console.warn( "Could not insert DFN tag in a single batch without a valid writer." );
		return;
	}
	try {
		options.writer.insert( options.placeholder, options.position );
		options.writer.setSelection( options.placeholder, "after" );
		if ( options.attributes )
			options.attributes.forEach( ( value, attribute ) => {
				options.writer.setAttribute( attribute, value, options.placeholder );
			} );
	} catch ( e ) {
		console.warn( "Couldn't insert placeholder at position. Error:" );
		console.warn( e );
	}
}

function getAttributes( options ) {
	if ( !options || typeof options != "object" ) return new Map();
	if ( options.attributes && typeof options.attributes == "object" ) return options.attributes;
	let position = options.position;
	if ( !position || typeof position != "object" ) return new Map();
	let attributes = getTextNodeAttrs( position.textNode ) ||
		getTextNodeAttrs( position.nodeBefore ) ||
		getTextNodeAttrs( position.nodeAfter ) || new Map();
	return attributes;
}

function getTextNodeAttrs( textNode ) {
	if ( !textNode || typeof textNode != "object" ||
		typeof textNode._data != "string" || !( textNode._attrs instanceof Map ) ||
		textNode._attrs.size < 1 ) return void 0;
	return textNode._attrs;
}

function insertTextInPlaceholder( editor, options ) {
	if ( !editor || typeof editor != "object" || !options ||
		typeof options != "object" || !options.writer ||
		typeof options.writer != "object" ||
		typeof options.writer.appendText != "function" ||
		typeof options.writer.setAttribute != "function" ||
		typeof options.writer.insert != "function" ||
		!options.placeholder ||
		typeof options.placeholder != "object" ) return;
	let writer = options.writer;
	if ( editor.objects.placeholders.titlesShown &&
		typeof options.title == "string" ) {
		try {
			writer.appendText( OPENING_BRAKET + options.title + CLOSING_BRACKET,
				options.attributes, options.placeholder );
		} catch ( e ) {
			console.warn( "Couldn't append title text to placeholder. Error:" );
			console.warn( e );
		}
		return;
	}
	if ( editor.objects.placeholders.titlesShown || typeof options.value != "string" ) return;
	options.textNodes.forEach( textNode => {
		options.attributes.forEach( ( value, attribute ) => {
			if ( !textNode.name && !textNode._attrs.get( attribute ) ) {
				let doBreak = false;
				try {
					writer.setAttribute( attribute, value, textNode );
				} catch ( e ) {
					console.warn( "Couldn't set attribute on text node in placeholder. Error:" );
					console.warn( e );
					doBreak = true;
				}
				if ( doBreak ) return;
			}
		} );
	} );
	for ( let i = options.textNodes.length - 1; i >= 0; i-- ) {
		let doBreak = false;
		try {
			writer.insert( options.textNodes[ i ], options.placeholder, 0 );
		} catch ( e ) {
			console.warn( "Couldn't insert text node in placeholder. Error:" );
			console.warn( e );
			doBreak = true;
		}
		if ( doBreak ) break;
	}
}

export function getValidPosition( selection, editor ) {
	let position = selection.getFirstPosition();
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = positionToPosition( editor, position );
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = selection.getLastPosition();
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = positionToPosition( editor, position );
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = editor.objects.selection.getLastPosition( true, true );
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = positionToPosition( editor, position );
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = editor.objects.selection.getLastPosition( true, false );
	position = getStablePosition( editor, position );
	if ( position ) return position.clone();
	// if ( isValidModelPosition( position ) ) return position;
	position = positionToPosition( editor, position );
	position = getStablePosition( editor, position );
	return position ? position.clone() : null;
	// return isValidModelPosition( position ) ? position : null;
}

function getStablePosition( editor, position ) {
	if ( !isValidModelPosition( position ) ) return null;
	if ( !isPositionParentText( position ) ) return position.clone();
	position = positionOutsideOfText( editor, position.path );
	if ( isValidModelPosition( position ) && !isPositionParentText( position ) )
		return position.clone();
	return null;
}

function positionToPosition( editor, position ) {
	let success = true;
	try {
		position = editor.model.createPositionAt( position );
	} catch ( e ) {
		success = false;
		console.warn( `Couldn't create model position from damaged position.` );
	}
	if ( success ) return position;
	try {
		position = editor.model.createPositionFromPath( position.root, position.path );
		success = true;
	} catch ( e ) {
		console.warn( `Couldn't create model position from damaged position.` );
	}
	return success ? position : null;
}

function insertMultilinePlaceholder( editor, title, value, selectionOrPosition ) {
	if ( !editor.objects.placeholders.titlesShown ) {
		insertPlaceholderLines( editor, title, value, selectionOrPosition );
		return;
	}
	let insertPosition = getContainerInsertPosition( editor, selectionOrPosition );
	insertDefinitionContainer( editor, title, value, insertPosition );
}

export function insertDefinitionContainer( editor, title, value, selectionOrPosition ) {
	if ( !editor || typeof title != "string" || typeof value != "string" ) return;
	let insertPosition = getPosition( selectionOrPosition );
	if ( !insertPosition ) return;
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		let paragraph = writer.createElement( "paragraph", { isPlaceholderContainer: true } );
		writer.insert( paragraph, insertPosition );
		let range = writer.createRangeIn( paragraph );
		insertDfnAtPosition( editor, range.start, {
			title: title,
			value: value,
			singleLine: false
		} );
	} );
}

function getContainerInsertPosition( editor, selectionOrPosition ) {
	selectionOrPosition = selectionOrPosition || editor.objects.selection.lastInModel;
	let position = getPosition( selectionOrPosition );
	if ( !position || !position.path || position.path.length <= 0 ||
		typeof position.path[ 0 ] != "number" ) return;
	let rootElement = getMainRoot( editor );
	if ( !rootElement || !rootElement._children || !rootElement._children._nodes ||
		rootElement._children._nodes.length < position.path[ 0 ] + 1 ) return;
	let prevoiusElement = rootElement._children._nodes[ position.path[ 0 ] ];
	return editor.model.change( writer => {
		return writer.createPositionAt( prevoiusElement, "after" );
	} );
}

export function insertPlaceholderLines( editor, title, value, selectionOrPosition ) {
	let position = getPosition( selectionOrPosition );
	if ( !position ) return;
	let fragmentAndValue = getDocFragAndValue( editor, title, value );
	if ( !fragmentAndValue || !fragmentAndValue.docFrag || !fragmentAndValue.encodedValue ) return;
	position = editor.data.processor.calibratePosition( position, fragmentAndValue.docFrag );
	if ( !position ) return;
	// double registration - here and in paragraph downcast
	registerMultilinePlaceholder( editor, title, value,
		fragmentAndValue.encodedValue, fragmentAndValue.docFrag );
	try {
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			writer.insert( fragmentAndValue.docFrag, position );
		} );
	} catch ( e ) {
		console.warn( "Couldn't insert multiline placeholder value at given position. Error:" );
		console.warn( e );
	}
}

export function getDocFragAndValue( editor, title, value ) {
	let dataProcessor = editor.data ? editor.data.processor : editor.getPsaDP();
	if ( !dataProcessor ) return;
	dataProcessor._isHtmStr( value ) ? void 0 :
		value = dataProcessor._textStrToHtml( value );
	let docFrag = dataProcessor.htmToDocFrag( value );
	if ( !docFrag || !docFrag._children ||
		!docFrag._children._nodes ) return;
	let quantity = docFrag._children._nodes.length;
	let encodedValue = editor.data.processor._encode64( value );
	let index = 0;
	let groupIndex = editor.objects.placeholders.getGroupIndex();
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		docFrag._children._nodes.forEach( element => {
			writer.setAttributes( {
				contentEditable: false,
				placeholderName: title,
				placeholderValue: encodedValue,
				placeholderLineNumber: quantity,
				placeholderChildIndex: index,
				isPlaceholderContainer: false,
				groupIndex: groupIndex
			}, element );
			index++;
		} );
	} );
	return { docFrag: docFrag, encodedValue: encodedValue };
}

export function registerMultilinePlaceholder( editor, key, unicodeValue, base64Value, docFrag ) {
	let entryValue = getUpdatedEntryValue( editor, key, unicodeValue, base64Value, false, docFrag );
	let groupIndex = undefined;
	docFrag._children._nodes.forEach( element => {
		element && typeof element._attrs == "object" ?
			groupIndex = element._attrs.get( "groupIndex" ) : void 0;
		// if ( !!groupIndex ) return; // where should this be and why is this here
	} );
	let modelElementData = {
		lineCount: docFrag._children._nodes.length,
		groupIndex: groupIndex,
		elementsList: Array.from( docFrag._children._nodes )
	}
	entryValue.elementGroups = entryValue.elementGroups || [];
	entryValue.elementGroups.push( modelElementData );
	entryValue.singleLine = false;
	entryValue.elements = [];
	editor.objects.placeholders.map.set( key, entryValue );
}

export function getPosition( selectionOrPosition ) {
	let prototype = getObjectProto( selectionOrPosition ) || "";
	return prototype.indexOf( "Selection" ) >= 0 ?
		selectionToPosition( selectionOrPosition ) :
		prototype.indexOf( "Position" ) >= 0 ?
		selectionOrPosition : null;
}

function selectionToPosition( selection ) {
	if ( !selection ) return null;
	let position = selection.focus || selection.getLastPosition() || selection.anchor ||
		selection.getFirstPosition();
	return isValidModelPosition( position ) ? position : null;
}
