import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import { createButton, fire, onDomEvent, createDocumentFragment, getObjectProto, stopEvent } from '../utils';
import { pasteIcon } from '../icons';
import { isPositionInPlaceholder } from '../pisaplaceholder/pisaplaceholderui';
import { getTrasferText } from '../pisaimage/pisaimageui';
// import htmlToImage from 'html-to-image';
import Validator from '../pisautils/validator';
import PluginUtils from '../pisautils/pluginutils';
import { containerChildrenMutated } from '@ckeditor/ckeditor5-typing/src/utils/utils';
import diff from '@ckeditor/ckeditor5-utils/src/diff';
import DomConverter from '@ckeditor/ckeditor5-engine/src/view/domconverter';
export const PASTE = "pisaPaste";
export const CLIPBOARD_PASTE = "clipboardPaste";

export default class PisaPaste extends Plugin {

	init() {

		new PluginUtils( {
			hostPlugin: this,
			logOutputColor: "#8c14fc",
			errorOutputColor: "#8c14fc"
		} );

		const editor = this.editor;
		this.addPasteComponent();
		this.addTouchPasteComponent();
		this.addKeydownPasteHandler();
		// this.addDomPasteHandler();
		// this.addDomInputHandler();
		this.addViewDocumentClipboardInputExcelInsertHandler();
		this.addClipboardPluginInputTransformation();
		this.addViewDocumentClipboardInputTouchHanlder();

		// editor.editing.view.document.on( "beforeinput", ( eventInfo, data ) => {
		// 	this._log( `"editor.editing.view.document" reacting to "beforeinput"` +
		// 		`\nPriority: Number.POSITIVE_INFINITY` );
		// }, { prioriy: Number.POSITIVE_INFINITY } );

		editor.editing.view.document.on( "mutations", ( eventInfo, mutations, viewSelection ) => {
			if ( !this.isTouch ) return;
			if ( !containerChildrenMutated( mutations ) ) return;

			const mutationsCommonAncestor = getMutationsContainer( mutations );
			if ( !mutationsCommonAncestor ) return;

			const domConverter = this.editor.editing.view.domConverter;
			const domMutationCommonAncestor = domConverter
				.mapViewToDom( mutationsCommonAncestor );
			const freshDomConverter = new DomConverter();
			const modelFromCurrentDom = this.editor.data.toModel(
				freshDomConverter.domToView( domMutationCommonAncestor )
			).getChild( 0 );

			const currentModel = this.editor.editing.mapper
				.toModelElement( mutationsCommonAncestor );
			if ( !currentModel ) return;

			const modelFromDomChildren = Array.from( modelFromCurrentDom.getChildren() );
			const currentModelChildren = Array.from( currentModel.getChildren() );
			const lastDomChild = modelFromDomChildren[ modelFromDomChildren.length - 1 ];
			const lastCurrentChild = currentModelChildren[ currentModelChildren.length - 1 ];

			if ( lastDomChild && lastDomChild.is( 'softBreak' ) && lastCurrentChild &&
				!lastCurrentChild.is( 'softBreak' ) )
				modelFromDomChildren.pop();

			const schema = this.editor.model.schema;
			if ( isSafeForTextMutation( modelFromDomChildren, schema ) &&
				isSafeForTextMutation( currentModelChildren, schema ) ) return;

			eventInfo.stop();
			// this._log( `"editor.editing.view.document" reacting to "mutations" and` +
			// 	`firing "clipboardPaste".\nPriority: Number.POSITIVE_INFINITY` );
			fire( this.editor, CLIPBOARD_PASTE );

		}, { prioriy: Number.POSITIVE_INFINITY } );

		// editor.model.on( "applyOperation", ( eventInfo, args ) => {
		// 	if ( !Validator.isArray( args, true ) ) return;
		// 	if ( !Validator.is( args[ 0 ], "InsertOperation" ) ) return;
		// 	this._log( `"editor.model" reacting to "applyOperation" for` +
		// 		` "InsertOperation"\nPriority: Number.POSITIVE_INFINITY` );
		// }, { priority: Number.POSITIVE_INFINITY } );

		// editor.model.on( "insertContent", ( eventInfo, args ) => {
		// 	this._log( `"editor.model" reacting to "insertContent"` +
		// 		`\nPriority: Number.POSITIVE_INFINITY` );
		// }, { priority: Number.POSITIVE_INFINITY } );

		editor.editing.view.document.on( "paste", ( eventInfo, args ) => {
			// this._log( `"editor.editing.view.document" reacting to "paste"` +
			// 	`\nPriority: Number.POSITIVE_INFINITY` );
			if ( !Validator.isObjectPath( args, "args.domEvent" ) ||
				!Validator.is( args.domEvent.clipboardData, "DataTransfer" ) ||
				!Validator.is( args.domEvent.clipboardData.items, "DataTransferItemList" ) ) return;
			let items = args.domEvent.clipboardData.items;
			let htmItem = [ ...args.domEvent.clipboardData.items ].find( item =>
				Validator.is( item, "DataTransferItem" ) && item.kind == "string" &&
				item.type == "text/html" );
			if ( !Validator.isObject( htmItem ) ) return;
			// this._log( `Data processor recognized a paste event with HTM content and` +
			// 	` will switch to html.\nPriority: Number.POSITIVE_INFINITY` );
			this.switchToHtm();
		}, { priority: Number.POSITIVE_INFINITY } );

		// editor.model.on( "_beforeChanges", ( eventInfo, args ) => {
		// 	this._log( `"editor.model" reacting to "_beforeChanges"` +
		// 		`\nPriority: Number.POSITIVE_INFINITY` );
		// }, { priority: Number.POSITIVE_INFINITY } );

		// editor.model.on( "_afterChanges", ( eventInfo, args ) => {
		// 	this._log( `"editor.model" reacting to "_afterChanges"` +
		// 		`\nPriority: Number.POSITIVE_INFINITY` );
		// }, { priority: Number.POSITIVE_INFINITY } );
	}

	get isTouch() {
		return Validator.isObjectPath( window, "window.pisasales" ) &&
			window.pisasales.isTouch;
	}

	addPasteComponent() {
		const editor = this.editor;

		if ( !Validator.isObjectPath( editor, "editor.ui.componentFactory" ) ||
			!Validator.isFunction( editor.ui.componentFactory.add ) ) return;

		editor.ui.componentFactory.add( PASTE, locale => {
			let pasteButton = createButton( pasteIcon,
				editor.objects.tooltips.getT( PASTE ), editor.locale );
			pasteButton.on( "execute", () => {
				if ( !window.navigator || !window.navigator.clipboard ||
					!window.navigator.clipboard.readText ) return;
				let position = editor.objects.selection.getLastPosition();
				if ( isPositionInPlaceholder( position ) ) return;
				window.navigator.clipboard.readText().then( text => {
					// text = text.replace( /\n\n/g, '\n' );
					editor.getPsaDP().insertText( text, false, true );
					editor.objects.images.setAllBorders();
					editor.objects.images.updateAllListeners();
				} );
				editor.objects.images.updateAllListeners();
				editor.balloons.hideAll();
			} );

			editor.objects.focus._addExecuteFocus( pasteButton );
			return pasteButton;
		} );

		delete this.addPasteComponent;
	}

	addTouchPasteComponent() {
		const editor = this.editor;

		if ( !Validator.isObjectPath( editor, "editor.ui.componentFactory" ) ||
			!Validator.isFunction( editor.ui.componentFactory.add ) ) return;

		editor.ui.componentFactory.add( CLIPBOARD_PASTE, locale => {
			let pasteButton = createButton( pasteIcon,
				editor.objects.tooltips.getT( CLIPBOARD_PASTE ), editor.locale );
			pasteButton.on( "execute", () => {
				// this._log( `Paste toolbar button clicked. Firing "${ CLIPBOARD_PASTE }".` );
				fire( editor, CLIPBOARD_PASTE );
				editor.balloons.hideAll();
				editor.objects.images.setAllBorders();
				editor.objects.images.updateAllListeners();
			} );

			editor.objects.focus._addExecuteFocus( pasteButton );

			return pasteButton;
		} );
		delete this.addTouchPasteComponent;
	}

	addKeydownPasteHandler() {
		const editor = this.editor;
		let self = this;
		let handlePaste = function ( evt ) {
			if ( !Validator.isObject( evt ) ) return;
			// self._log( `Keydown event identified, data processor will recognize if` +
			// 	` it is a "paste" event and switch to html.\nPriority not set.` +
			// 	`\nctrlKey: ${ evt.ctrlKey }\nmetaKey: ${ evt.metaKey }` +
			// 	`\ncode: ${ evt.code }\nkey: ${ evt.key }\nkeyCode: ${ evt.keyCode }` );
			if ( evt.code != "KeyV" && evt.key != "v" && evt.keyCode != 86 ) return;
			if ( !evt.ctrlKey && evt.metaKey != "true" && evt.metaKey != true ) return;
			if ( self.switchToHtm( self.editor ) ) return;
			// TODO if no Data processor check with commands
		};
		onDomEvent( editor, "keydown", handlePaste );
		delete this.addKeydownPasteHandler;
	}

	addDomPasteHandler() {
		const editor = this.editor;
		let self = this;
		let handlePaste = function ( evt ) {
			if ( !Validator.isObject( evt ) ) return;
			// self._log( `Paste event identified.` +
			// 	`\nPriority not set.` );
		};
		onDomEvent( editor, "paste", handlePaste );
		delete this.addDomPasteHandler;
	}

	addDomInputHandler() {
		const editor = this.editor;
		let self = this;
		let handlePaste = function ( evt ) {
			if ( !Validator.isObject( evt ) ) return;
			// self._log( `Input event identified.` +
			// 	`\nPriority not set.` );
		};
		onDomEvent( editor, "input", handlePaste );
		delete this.addDomInputHandler;
	}

	switchToHtm( editorInstance ) {
		if ( !Validator.isObject( editorInstance ) ) editorInstance = this.editor;
		// if ( !Validator.isObjectPath( editorInstance, "editorInstance.data.processor" ) ||
		// 	!Validator.isFunction( editorInstance.data.processor.isPlain ) )
		// 	editorInstance = this.editor;
		// if ( !Validator.isObjectPath( editorInstance, "editorInstance.data.processor" ) ||
		// 	!Validator.isFunction( editorInstance.data.processor.isPlain ) )
		// 	return this._log( `Data processor is invalid and did not switch to html.`, false );
		if ( !editorInstance.data.processor.isPlain() ) return true;
		// if ( !Validator.isFunction( editorInstance.data.processor.setPlainText ) )
		// 	return this._log( `Data processor does not have the function` +
		// 		` "setPlainText" and did not switch to html.`, false );
		editorInstance.data.processor.setPlainText( false );
		return true;
	}

	addViewDocumentClipboardInputExcelInsertHandler() {
		const editor = this.editor;

		if ( !Validator.isObjectPath( editor, "editor.editing.view.document" ) ||
			!Validator.isFunction( editor.editing.view.document.on ) ) return;

		// insert excel tables as htm not as image
		editor.editing.view.document.on( "clipboardInput", ( eventInfo, data ) => {
			// this._log( `editor.editing.view.document reacting to "clipboardInput".` +
			// 	`\nPriority: Number.POSITIVE_INFINITY` );
			if ( !Validator.isObjectPath( data, "data.dataTransfer._native" ) ) return;
			let text = getTrasferText( data.dataTransfer );
			if ( !editor.data.processor.isFromExcel( text ) ) return;
			editor.data.processor.insertText( text, true );
			if ( Validator.isObject( eventInfo ) &&
				Validator.isFunction( eventInfo.stop ) )
				stopEvent( eventInfo, data );
			// priority "positive infinity" is required because a similar listener for
			// images has a priority of Number.MAX_SAFE_INTEGER
		}, { priority: Number.POSITIVE_INFINITY } );

		delete this.addViewDocumentClipboardInputExcelInsertHandler;
	}

	addClipboardPluginInputTransformation() {
		const editor = this.editor;
		if ( !Validator.isObjectPath( editor, "editor.plugins" ) ||
			!Validator.isFunction( editor.plugins.get ) ) return;
		const clipboardPlugin = editor.plugins.get( "Clipboard" );
		if ( !Validator.isObject( clipboardPlugin ) ||
			!Validator.isFunction( clipboardPlugin.on ) ) return;
		clipboardPlugin.on( "inputTransformation", ( eventInfo, data ) => {
			// this._log( `"Clipboard" plugin reacting to "inputTransformation".` +
			// 	`\nPriority not set.` );
			if ( !this.isTouch && this._handleTextWithImage( eventInfo, data ) ) return;
			if ( !Validator.isObjectPath( data, "data.content._children" ) ||
				!Validator.isArray( data.content._children ) ||
				data.content._children.length <= 0 ) return;
			// remove placeholder lines (because of the unique group index)
			data.content._children = data.content._children.filter( element => {
				return !( element.name == "div" && typeof element._attrs == "object" &&
					element._attrs.size > 0 && ( element._attrs.get( "data-title" ) ||
						element._attrs.get( "data-value" ) ||
						element._attrs.get( "data-placeholder-container" ) ||
						element._attrs.get( "data-placeholder-container" ) == false ) );
			} );
			let hasBreaks = false;
			let hasText = false;
			for ( let element of data.content._children ) {
				hasBreaks = element.name == "br" ? true : hasBreaks;
				hasText = element._textData ? true : hasText;
				if ( hasText && hasBreaks ) break;
			}
			if ( !hasText || !hasBreaks ) return;
			let viewFrag = normalizeDocFrag( [ ...data.content._children ] );
			let dataProcessor = editor.getPsaDP() || editor.data.processor;
			if ( !viewFrag || !dataProcessor ) return;
			let modelFrag = editor.data.toModel( viewFrag );
			if ( !modelFrag ) return;
			try {
				dataProcessor._insertDocFrag( modelFrag );
				eventInfo.stop();
			} catch ( err ) {
				this._error( err, `Text with breaks <br> could not be converted into` +
					` text with paragraphs <div>.` );
			}
		} );

		delete this.addClipboardPluginInputTransformation;
	}

	addViewDocumentClipboardInputTouchHanlder() {
		const editor = this.editor;

		if ( !Validator.isObjectPath( editor, "editor.editing.view.document" ) ||
			!Validator.isFunction( editor.editing.view.document.on ) ) return;

		editor.editing.view.document.on( "clipboardInput", ( eventInfo, data ) => {
			// this._log( `editor.editing.view.document reacting to "clipboardInput".` +
			// 	`\nPriority not set.` );
			if ( !this.isTouch ) return;
			if ( Validator.isObjectPath( data, "data.dataTransfer._native" ) &&
				Validator.isArray( data.dataTransfer._native.types, true ) ) return;
			if ( Validator.isObject( eventInfo ) &&
				Validator.isFunction( eventInfo.stop ) ) eventInfo.stop();
			// this._log( "The clipboard input data has no clipboard item types." );
			fire( editor, CLIPBOARD_PASTE );
		} );

		delete this.addViewDocumentClipboardInputTouchHanlder;
	}

	_handleTextWithImage( eventInfo, data ) {
		let clipboardData = Validator.isObjectPath( data, "data.dataTransfer" ) &&
			data.dataTransfer._native instanceof DataTransfer &&
			Validator.isFunction( data.dataTransfer._native.getData ) ?
			data.dataTransfer._native : void 0;
		if ( !clipboardData ) return false;
		const editor = this.editor;
		let htm = clipboardData.getData( "text/html" ) || "";
		if ( !insertTextWithImages( editor, htm ) ) return false;
		stopEvent( eventInfo, data );
		return true;
	}

}

export function isFilesOnlyText( editor, text ) {
	if ( !Validator.isFunctionPath( editor,
			"editor.data.processor.getCleanImgTags" ) ||
		!Validator.isString( text ) ) return false;
	const imageTagsMap = editor.data.processor.getCleanImgTags( text );
	return imageTagsMap instanceof Map && !!imageTagsMap.get( "filesOnly" );
}

export function insertTextWithImages( editor, text ) {
	let imgTagsMap = editor.data.processor.getCleanImgTags( text );
	if ( !imgTagsMap || !( imgTagsMap instanceof Map ) || imgTagsMap.size < 1 ||
		imgTagsMap.get( "filesOnly" ) == true ) return false;
	let images = {};
	imgTagsMap.forEach( ( imgProps, imgTag ) => {
		if ( imgTag != "filesOnly" && !imgProps.isFile && !imgProps.isData &&
			typeof imgProps == "object" && typeof imgProps.src == "string" &&
			imgProps.src.length > 0 ) images[ imgTag ] = imgProps.src;
	} );
	editor.data.processor.setImgTemp( text );
	fire( editor, "pisaUploadImage", images );
	return true;
}

function normalizeDocFrag( children ) {
	let childNodes = [];
	let upcastWriter = new UpcastWriter();
	for ( let i = 0; i < children.length; i++ ) {
		let current = children[ i ];
		let next = i < children.length - 1 ? children[ i + 1 ] : void 0;
		let previous = i > 0 ? children[ i - 1 ] : void 0;
		if ( Validator.isElementObject( current, "br" ) &&
			Validator.isTextOrInlineViewElement( next ) ) {
			let text = upcastWriter.clone( next, true );
			let div = upcastWriter.createElement( "div", {}, [ text ] );
			childNodes.push( div );
			i++;
			continue;
		}
		if ( Validator.isElementObject( current, "br" ) ) {
			let div = upcastWriter.createElement( "div", {}, [] );
			childNodes.push( div );
			continue;
		}
		if ( !Validator.isTextOrInlineViewElement( current ) ) {
			let element = upcastWriter.clone( current, true );
			childNodes.push( element );
			continue;
		}
		let text = upcastWriter.clone( current, true );
		if ( !Validator.isTextOrInlineViewElement( previous ) ||
			childNodes.length <= 0 ) {
			let div = upcastWriter.createElement( "div", {}, [ text ] );
			childNodes.push( div );
			continue;
		}
		let previousParent = childNodes[ childNodes.length - 1 ];
		if ( Validator.isElementObject( previousParent, "div" ) ) {
			upcastWriter.appendChild( text, previousParent );
			continue;
		}
		let div = upcastWriter.createElement( "div", {}, [ text ] );
		childNodes.push( div );
	}
	return createDocumentFragment( childNodes );
}

function getLinkFromImgTag( imgTagStr ) {
	if ( !imgTagStr || typeof imgTagStr != "string" ||
		imgTagStr.indexOf( 'src="' ) < 0 ) return void 0;
	let result = imgTagStr.split( 'src="' );
	if ( !result || !( result instanceof Array ) || result.length < 2 ||
		typeof result[ 1 ] != "string" || result[ 1 ].indexOf( '"' ) < 0 )
		return void 0;
	result = result[ 1 ].split( '"' );
	if ( !result || !( result instanceof Array ) || result.length < 1 ||
		typeof result[ 0 ] != "string" )
		return void 0;
	return result[ 0 ];
}

function getMutationsContainer( mutations ) {
	const lca = mutations
		.map( mutation => mutation.node )
		.reduce( ( commonAncestor, node ) => {
			return commonAncestor.getCommonAncestor( node, { includeSelf: true } );
		} );

	if ( !lca ) {
		return;
	}

	// We need to look for container and root elements only, so check all LCA's
	// ancestors (starting from itself).
	return lca.getAncestors( { includeSelf: true, parentFirst: true } )
		.find( element => element.is( 'containerElement' ) || element.is( 'rootElement' ) );
}

function calculateChanges( diffResult ) {
	// Index where the first change happens. Used to set the position from which nodes will be removed and where will be inserted.
	let firstChangeAt = null;
	// Index where the last change happens. Used to properly count how many characters have to be removed and inserted.
	let lastChangeAt = null;

	// Get `firstChangeAt` and `lastChangeAt`.
	for ( let i = 0; i < diffResult.length; i++ ) {
		const change = diffResult[ i ];

		if ( change != 'equal' ) {
			firstChangeAt = firstChangeAt === null ? i : firstChangeAt;
			lastChangeAt = i;
		}
	}

	// How many characters, starting from `firstChangeAt`, should be removed.
	let deletions = 0;
	// How many characters, starting from `firstChangeAt`, should be inserted.
	let insertions = 0;

	for ( let i = firstChangeAt; i <= lastChangeAt; i++ ) {
		// If there is no change (equal) or delete, the character is existing in `oldText`. We count it for removing.
		if ( diffResult[ i ] != 'insert' ) {
			deletions++;
		}

		// If there is no change (equal) or insert, the character is existing in `newText`. We count it for inserting.
		if ( diffResult[ i ] != 'delete' ) {
			insertions++;
		}
	}

	return { insertions, deletions, firstChangeAt };
}

function isSafeForTextMutation( children, schema ) {
	return children.every( child => schema.isInline( child ) );
}
