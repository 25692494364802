import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import BackgroundColorCommand from './pisabckgcommand';
import { addUpcastConversion, addUpcastContainerConversion } from '../utils';

export const BACKGROUND_COLOR = 'backgroundColor';
export const BACKGROUND_COLOR_ATTRIBUTE = 'background-color';

export default class BackgroundColorEditing extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		const editor = this.editor;
		editor.model.schema.extend( '$text', { allowAttributes: BACKGROUND_COLOR } );
		addDowncastConversion( editor );
		addUpcastConversion( editor, BACKGROUND_COLOR_ATTRIBUTE, BACKGROUND_COLOR, [ "span", "a" ] );
		addUpcastContainerConversion( editor, BACKGROUND_COLOR_ATTRIBUTE, BACKGROUND_COLOR );
		addUpcastConversion( editor, "background", BACKGROUND_COLOR, [ "span" ] );
		addUpcastContainerConversion( editor, "background", BACKGROUND_COLOR );
		// editor.commands.add( BACKGROUND_COLOR, new BackgroundColorCommand( editor ) );
	}
}

function addDowncastConversion( editor ) {
	editor.conversion.for( 'downcast' ).attributeToElement( {
		model: {
			key: BACKGROUND_COLOR,
			name: '$text'
		},
		view: ( modelAttributeValue, viewWriter ) => {
			return viewWriter.createAttributeElement( 'span', {
				style: BACKGROUND_COLOR_ATTRIBUTE.concat( ":" ).concat( modelAttributeValue )
			} );
		}
	} );
}
