import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { PLACEHOLDER } from './pisaplaceholderui';
import { DFN_TAG } from './pisadefinitionediting';
import DowncastWriter from '@ckeditor/ckeditor5-engine/src/view/downcastwriter';
import { downcastStyleTagAttr, upcastStyleTagAttr, upcastStyleTagAttribute, findChildElement } from '../utils';
import { BACKGROUND_COLOR, BACKGROUND_COLOR_ATTRIBUTE } from '../pisabckgcolor/pisabckgediting';
import { FONT_COLOR, FONT_COLOR_ATTRIBUTE } from '../pisafontcolor/pisacolorediting';
import { FONT_FAMILY, FONT_FAMILY_ATTRIBUTE } from '../pisafontfamily/pisafontfamilyediting';
import { FONT_SIZE, FONT_SIZE_ATTRIBUTE } from '../pisafontsize/pisafontsizeediting';
import { SUBSCRIPT } from '../pisasubscript/pisasubscriptediting';
import { SUPERSCRIPT } from '../pisasuperscript/pisasuperscriptediting';
import { BOLD } from '../iconsui/boldui';
import { ITALIC } from '../iconsui/italicui';
import { UNDERLINE } from '../iconsui/underlineui';
import { STRIKETHROUGH } from '../iconsui/strikethroughui';

const FONT_WEIGHT_ATTRIBUTE = "font-weight";
const FONT_WEIGHT = "fontWeight";
const FONT_STYLE_ATTRIBUTE = "font-style";
const FONT_STYLE = "fontStyle";
const TEXT_DECORATION_ATTRIBUTE = "text-decoration";
const TEXT_DECORATION = "textDecoration";
const VERTICAL_ALIGN = "verticalAlign";
const VERTICAL_ALIGN_ATTRIBUTE = "vertical-align";
const NORMAL = "normal";

// TODO code
export default class PisaDefinitionStyleEditing extends Plugin {

	init() {
		const editor = this.editor;
		let viewModelMap = createMap();
		const downcastWriter = new DowncastWriter();
		let self = this;
		// const upcastWriter = new UpcastWriter();

		viewModelMap.forEach( ( modelAttribute, viewAttribute ) => {
			editor.model.schema.extend( PLACEHOLDER, { allowAttributes: modelAttribute } );

			downcastStyleTagAttr( editor, PLACEHOLDER, modelAttribute, viewAttribute );
			upcastStyleTagAttribute( editor, DFN_TAG, viewAttribute, modelAttribute );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: DFN_TAG,
					key: viewAttribute
				},
				model: modelAttribute
			} );
		} );

		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: BOLD } );
		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: ITALIC } );
		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: UNDERLINE } );
		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: STRIKETHROUGH } );
		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: SUBSCRIPT } );
		editor.model.schema.extend( PLACEHOLDER, { allowAttributes: SUPERSCRIPT } );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ BOLD }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ BOLD }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					let newValue = !!data.attributeNewValue ? BOLD : NORMAL;
					dispatcher.convertAttribute( data.range, FONT_WEIGHT,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ ITALIC }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ ITALIC }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					let newValue = !!data.attributeNewValue ? ITALIC : NORMAL;
					dispatcher.convertAttribute( data.range, FONT_STYLE,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ UNDERLINE }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ UNDERLINE }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					// TODO should look not only at strikethrough, but also at "text-decoration"
					let isStrikethrough = data.item.getAttribute( STRIKETHROUGH ) == true;
					let newValue = !!data.attributeNewValue ?
						( isStrikethrough ? "underline line-through" : "underline" ) :
						( isStrikethrough ? "line-through" : "none" );
					dispatcher.convertAttribute( data.range, TEXT_DECORATION,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ STRIKETHROUGH }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ STRIKETHROUGH }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					// TODO should look not only at underline, but also at "text-decoration"
					let isUnderline = data.item.getAttribute( UNDERLINE ) == true;
					let newValue = !!data.attributeNewValue ?
						( isUnderline ? "underline line-through" : "line-through" ) :
						( isUnderline ? "underline" : "none" );
					dispatcher.convertAttribute( data.range, TEXT_DECORATION,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ SUBSCRIPT }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ SUBSCRIPT }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					let newValue = !!data.attributeNewValue ? "sub" : "baseline";
					dispatcher.convertAttribute( data.range, VERTICAL_ALIGN,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:${ SUPERSCRIPT }:${ PLACEHOLDER }`,
				( evt, data, conversionApi ) => {
					let copyOfConversionApi = self._getCopyOfConversionApi( conversionApi );
					if ( typeof copyOfConversionApi != "object" ) {
						console.warn( `CK Editor 5 could not set the attribute "${ SUPERSCRIPT }" ` +
							`on the model element of type "${ PLACEHOLDER }"` );
						return;
					}
					let newValue = !!data.attributeNewValue ? "super" : "baseline";
					dispatcher.convertAttribute( data.range, VERTICAL_ALIGN,
						null, newValue, downcastWriter );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		// editor.conversion.for( 'downcast' ).attributeToAttribute( {
		// 	model: {
		// 		name: 'pisaPlaceholder',
		// 		key: 'italic'
		// 	},
		// 	view: modelAttributeValue => {
		// 		if ( !modelAttributeValue ) {
		// 			return {
		// 				key: 'style',
		// 				value: {
		// 					'font-style': 'normal'
		// 				}
		// 			}
		// 		}
		//
		// 		// Return what to as a view element attribute:
		// 		return {
		// 			key: 'style',
		// 			value: {
		// 				'font-style': 'italic'
		// 			}
		// 		};
		// 	}
		// } );


		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"font-weight": "bold"
				}
			},
			model: BOLD
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"font-style": "italic"
				}
			},
			model: ITALIC
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"text-decoration": /underline/g
				}
			},
			model: UNDERLINE
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"text-decoration": /line-through/g
				}
			},
			model: STRIKETHROUGH
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"vertical-align": "sub"
				}
			},
			model: SUBSCRIPT
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: DFN_TAG,
				styles: {
					"vertical-align": "super"
				}
			},
			model: SUPERSCRIPT
		} );

	}

	_getCopyOfConversionApi( conversionApi ) {
		let copyOfConversionApi = void 0;
		let success = false;

		try {
			eval( "copyOfConversionApi = { ...conversionApi }" );
			success = true;
		} catch ( e ) {
			success = false;
			copyOfConversionApi = void 0;
		}
		if ( success && typeof copyOfConversionApi == "object" )
			return copyOfConversionApi;

		try {
			eval( "copyOfConversionApi = Object.assign( {}, conversionApi )" );
			success = true;
		} catch ( e ) {
			success = false;
			copyOfConversionApi = void 0;
		}
		if ( success && typeof copyOfConversionApi == "object" )
			return copyOfConversionApi;

		if ( typeof _ != "function" && typeof _.cloneDeep != "function" ) {
			console.warn( `CK Editor 5 could not create a copy of the Conversion Api. ` +
				`The Conversion Api will have no consumable and no writer so ` +
				`editor.setData() and editor.getData() will not work properly and/or ` +
				`will throw error(s) if the Conversion Api is further used.` );
			return void 0;
		}

		try {
			copyOfConversionApi = {
				writer: _.cloneDeep( conversionApi.writer ),
				consumable: _.cloneDeep( conversionApi.consumable )
			};
			success = true;
		} catch ( e ) {
			success = false;
			copyOfConversionApi = void 0;
		}

		if ( success && typeof copyOfConversionApi == "object" )
			return copyOfConversionApi;

		console.warn( `CK Editor 5 could not create a copy of the Conversion Api. ` +
			`The Conversion Api will have no consumable and no writer so ` +
			`editor.setData() and editor.getData() will not work properly and/or ` +
			`will throw error(s) if the Conversion Api is further used.` );
		return void 0;
	}

}

function createMap() {
	let map = new Map();
	map.set( BACKGROUND_COLOR_ATTRIBUTE, BACKGROUND_COLOR );
	map.set( FONT_COLOR_ATTRIBUTE, FONT_COLOR );
	map.set( FONT_FAMILY_ATTRIBUTE, FONT_FAMILY );
	map.set( FONT_SIZE_ATTRIBUTE, FONT_SIZE );
	map.set( FONT_WEIGHT_ATTRIBUTE, FONT_WEIGHT );
	map.set( FONT_STYLE_ATTRIBUTE, FONT_STYLE );
	map.set( TEXT_DECORATION_ATTRIBUTE, TEXT_DECORATION );
	map.set( VERTICAL_ALIGN_ATTRIBUTE, VERTICAL_ALIGN );
	return map;
}
