import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import InsertParagraphCommand from './pisainsertparagraphcommand';
import { updateLastSelection, createButton, defineButton, addClasses, closeDropdownOnBlur, addViewChildren } from '../utils';
import { addParagraphAbove, addParagraphBelow, addParagraphIcon } from '../icons';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const PARAGRAPH_BEFORE = "pisaInsertParagraphBefore";
export const PARAGRAPH_AFTER = "pisaInsertParagraphAfter";
export const INSERT_PARAGRAPH = "pisaInsertParagraph";

export default class PisaInsertParagraphUI extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( INSERT_PARAGRAPH, new InsertParagraphCommand( editor ) );
		const command = editor.commands.get( INSERT_PARAGRAPH );
		let balloonPanel = editor.plugins._plugins.get( "PisaPanelBalloons" );

		editor.ui.componentFactory.add( PARAGRAPH_BEFORE, locale => {
			let paragraphAbove = createButton( addParagraphAbove,
				editor.objects.tooltips.getT( PARAGRAPH_BEFORE ), editor.locale );
			paragraphAbove.bind( 'isEnabled' ).to( command );
			paragraphAbove.on( "execute", () => {
				updateLastSelection( editor );
				editor.executeIf( INSERT_PARAGRAPH, { where: "before" }, false );
				if ( !!balloonPanel && typeof balloonPanel == "object" ) balloonPanel.positionActive();
			} );
			editor.objects.focus._addExecuteFocus( paragraphAbove );
			return paragraphAbove;
		} );

		editor.ui.componentFactory.add( PARAGRAPH_AFTER, locale => {
			let paragraphAbove = createButton( addParagraphBelow,
				editor.objects.tooltips.getT( PARAGRAPH_AFTER ), editor.locale );
			paragraphAbove.bind( 'isEnabled' ).to( command );
			paragraphAbove.on( "execute", () => {
				updateLastSelection( editor );
				editor.executeIf( INSERT_PARAGRAPH, { where: "after" }, false );
				if ( !!balloonPanel && typeof balloonPanel == "object" )
					balloonPanel.positionActive();
			} );
			editor.objects.focus._addExecuteFocus( paragraphAbove );
			return paragraphAbove;
		} );

		editor.ui.componentFactory.add( INSERT_PARAGRAPH, locale => {
			let dropdownView = createDropdown( editor.locale );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			defineButton( dropdownView.buttonView, addParagraphIcon,
				editor.objects.tooltips.getT( INSERT_PARAGRAPH ) );
			dropdownView.bind( 'isEnabled' ).to( command );
			closeDropdownOnBlur( dropdownView );
			addViewChildren( dropdownView.panelView, {
				0: editor.ui.componentFactory.create( PARAGRAPH_BEFORE ),
				1: editor.ui.componentFactory.create( PARAGRAPH_AFTER )
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, INSERT_PARAGRAPH );

			return dropdownView;
		} );
	}
}
