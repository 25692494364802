import Validator from './validator';

const DOM_RECT_PROPERTIES = [ "x", "y", "width", "height", "top", "right", "bottom", "left" ];

export default class HtmHelper {

	static isEvent( inputParameter ) {
		return inputParameter instanceof Event;
	}

	static isNode( inputParameter ) {
		return inputParameter instanceof Node;
	}

	/**
	 * any Element is automatically a Node (inheritance)
	 */
	static isElement( inputParameter ) {
		return inputParameter instanceof Element;
	}

	/**
	 * any HTMLElement is automatically an Element and a Node (inheritance)
	 */
	static isHtmlElement( inputParameter ) {
		return inputParameter instanceof HTMLElement;
	}

	static isDiv( inputParameter ) {
		return inputParameter instanceof HTMLDivElement;
	}

	static isDOMRect( inputParameter ) {
		return inputParameter instanceof DOMRect;
	}

	static findTag( inputParameter, childTagName ) {
		if ( !HtmHelper.isElement( inputParameter ) ||
			!Validator.isString( childTagName ) ) return void 0;
		childTagName = childTagName.toUpperCase();
		if ( inputParameter.tagName === childTagName ) return inputParameter;
		return HtmHelper.findTagChild( inputParameter, childTagName );
	}

	static findTagChild( inputParameter, childTagName ) {
		if ( !HtmHelper.isElement( inputParameter ) ||
			!Validator.isString( childTagName ) ) return void 0;
		const allLevelChildren = inputParameter.getElementsByTagName( "*" );
		if ( !Validator.isIterable( allLevelChildren ) ) return void 0;
		childTagName = childTagName.toUpperCase();
		return [ ...allLevelChildren ]
			.find( childElement => childElement.tagName === childTagName );
	}

	static getBoundingClientRect( inputParameter ) {
		if ( !HtmHelper.isElement( inputParameter ) ||
			!Validator.isFunction( inputParameter.getBoundingClientRect ) )
			return void 0;
		return inputParameter.getBoundingClientRect();
	}

	static _getBoundingValue( inputParameter, valueName ) {
		if ( !Validator.isString( valueName ) ||
			DOM_RECT_PROPERTIES.indexOf( valueName ) < 0 ) return void 0;
		let rect = HtmHelper.getBoundingClientRect( inputParameter );
		if ( !HtmHelper.isDOMRect( rect ) ) return void 0;
		return rect[ valueName ];
	}

	static getBoundingHeight( inputParameter ) {
		return HtmHelper._getBoundingValue( inputParameter, "height" );
	}

	static getBoundingWidth( inputParameter ) {
		return HtmHelper._getBoundingValue( inputParameter, "width" );
	}

	static isHTMLCollection( inputParameter ) {
		return inputParameter instanceof HTMLCollection;
	}

	static isNodeList( inputParameter ) {
		return inputParameter instanceof NodeList;
	}

	static getAllLevelChildren( inputParameter ) {
		if ( !( inputParameter instanceof HTMLElement ) ) return [];
		const children = inputParameter.getElementsByTagName( "*" );
		if ( !Validator.isIterable( children ) ) return [];
		return [ ...children ];
	}

	static hasChildren( inputParameter ) {
		return HtmHelper.isElement( inputParameter ) &&
			HtmHelper.isHTMLCollection( inputParameter.children ) &&
			inputParameter.children.length > 0;
	}

	static hasChildNodes( inputParameter ) {
		return HtmHelper.isNode( inputParameter ) &&
			HtmHelper.isNodeList( inputParameter.childNodes ) &&
			inputParameter.childNodes.length > 0;
	}

	static insertAsFirstChild( parent, prospectiveChild ) {
		if ( !HtmHelper.hasChildNodes( parent ) ||
			!HtmHelper.isNode( prospectiveChild ) ) return void 0;
		if ( !Validator.isFunction( parent.insertBefore ) ) return void 0;
		let firstChild = parent.childNodes[ 0 ];
		parent.insertBefore( prospectiveChild, firstChild );
	}

	static getParentNodeOfTag( inputParameter, tagName, tagNamesToStopAt = [] ) {
		if ( !Validator.isString( tagName ) ||
			!HtmHelper.isNode( inputParameter ) ) return void 0;
		if ( Validator.isString( tagNamesToStopAt ) )
			tagNamesToStopAt = [ tagNamesToStopAt.toUpperCase() ];
		if ( !Validator.isArray( tagNamesToStopAt ) ) tagNamesToStopAt = [];
		tagName = tagName.toUpperCase();
		let currentNode = inputParameter;
		while ( currentNode.tagName != tagName &&
			tagNamesToStopAt.indexOf( currentNode.tagName ) < 0 &&
			HtmHelper.isNode( currentNode.parentNode ) ) {
			currentNode = currentNode.parentNode;
		}
		return currentNode.tagName == tagName ? currentNode : void 0;
	}

	static addClass( inputParameter, className ) {
		if ( !Validator.isString( className ) ) return void 0;
		if ( !HtmHelper.isElement( inputParameter ) ) return void 0;
		if ( !Validator.isObject( inputParameter.classList ) ||
			!Validator.isFunction( inputParameter.classList.add ) ) return void 0;
		if ( Validator.isFunction( inputParameter.classList.contains ) &&
			!!inputParameter.classList.contains( className ) ) return void 0;
		inputParameter.classList.add( className );
	}

	static removeClass( inputParameter, className ) {
		if ( !Validator.isString( className ) ) return void 0;
		if ( !HtmHelper.isElement( inputParameter ) ) return void 0;
		if ( !Validator.isObject( inputParameter.classList ) ||
			!Validator.isFunction( inputParameter.classList.remove ) ) return void 0;
		inputParameter.classList.remove( className );
	}

	static replaceClass( inputParameter, oldClassName, newClassName ) {
		if ( !Validator.isString( oldClassName ) ||
			!Validator.isString( newClassName ) ) return void 0;
		if ( !HtmHelper.isElement( inputParameter ) ) return void 0;
		if ( !Validator.isObject( inputParameter.classList ) ) return void 0;
		if ( Validator.isFunction( inputParameter.classList.contains ) &&
			!inputParameter.classList.contains( oldClassName ) )
			return HtmHelper.addClass( inputParameter, newClassName );
		if ( !Validator.isFunction( inputParameter.classList.replace ) ) return void 0;
		inputParameter.classList.replace( oldClassName, newClassName );
	}

	static isRange( inputParameter ) {
		return inputParameter instanceof Range;
	}

	static createRangeOnNode( node ) {
		if ( !HtmHelper.isNode( node ) ) return void 0;
		let domRange = document.createRange();
		domRange.setStart( node, 0 );
		domRange.setEnd( node, 0 );
		return domRange;
	}

	static generateRandomString( prefix = "", suffix = "", length = void 0 ) {
		let now = Date.now();
		let randomString = ( now * Math.random() ).toString( 36 ).replace( /\./g, now );
		if ( Validator.isPositiveInteger( length ) )
			randomString = randomString.length >= length ?
			randomString.substring( 0, length - 1 ) :
			randomString + Math.pow( 10, length - randomString.length - 1 );
		if ( Validator.isString( prefix ) ) randomString = prefix + randomString;
		if ( Validator.isString( suffix ) ) randomString = randomString + suffix;
		return randomString;
	}

	static generateImageId() {
		return HtmHelper.generateRandomString( "pisa-ck-editor-image-" );
	}

}
