import Command from '@ckeditor/ckeditor5-core/src/command';
import { getRange } from '../utils';
export const PISAOBJECT = "pisaObject";
export const PISAOBJECTLINK = 'pisaObjectLink';

export default class PisaObjectLinkCommand extends Command {

	constructor( editor ) {
		super( editor, PISAOBJECT );
	}

	execute( options = {} ) {
		if ( typeof options.key != "string" ) return;
		const editor = this.editor;
		options.selection ? isInsideLink( options.selection, editor ) : void 0;
		editor.executeIf( "pisaFire", { event: PISAOBJECTLINK, options: { key: options.key } }, true );
	}

}

function isInsideLink( selection, editor ) {
	if ( !selection || !selection.anchor || !selection.anchor.parent || !selection.anchor.path ||
		selection.anchor.path.length == 0 || selection.anchor.parent.name != "paragraph" ||
		!selection.anchor.parent._children || !selection.anchor.parent._children._nodes ||
		selection.anchor.parent._children._nodes.length == 0 ) return false;
	let lastInPath = selection.anchor.path[ selection.anchor.path.length - 1 ];
	for ( let child of selection.anchor.parent._children._nodes ) {
		if ( child.startOffset > lastInPath || child.endOffset < lastInPath ) continue;
		if ( !child._attrs.get( "linkHref" ) ) return false;
		let path = selection.anchor.path.slice( 0, -1 );
		let range = getRange( editor, path, child.endOffset, child.endOffset );
		editor.model.document.selection._setTo( range );
		return true;
	}
	return false;
}
