import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export const FONT_TAG = "font";

export default class PisaFontTagEditing extends Plugin {

	constructor( editor ) {
		super( editor );
		const conversion = editor.conversion;
		let self = this;

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: FONT_TAG,
				key: "face"
			},
			model: "fontFamily"
		} );

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: FONT_TAG,
				key: "color"
			},
			model: "fontColor"
		} );

	}

}
