import Command from '@ckeditor/ckeditor5-core/src/command';
import Position from '@ckeditor/ckeditor5-engine/src/model/position.js';
import { changeSelection } from '../pisacopyformat/pisacopyformatcommand';
import Validator from '../pisautils/validator';

export const REMOVEFORMAT = 'pisaRemoveFormat';

export default class RemoveFormatCommand extends Command {

	constructor( editor ) {
		super( editor, REMOVEFORMAT );
	}

	execute( options = {} ) {
		const editor = this.editor;
		const selection = this.editor.model.document.selection._selection;
		const blocks = Array.from( selection.getSelectedBlocks() );
		const ranges = Validator.isObjectPath( editor, "editor.objects.selection" ) &&
			Validator.isFunction( editor.objects.selection._viewSelectionToModelRange ) ?
			editor.objects.selection._viewSelectionToModelRange() : void 0;
		// attribute names ARE case sensitive
		const attributes = [ "fontColor", "fontFamily", "fontSize", "bold", "italic",
			"underline", "strikethrough", "alignment", "linkHref",
			"backgroundColor", "pisaSpacing", "pisaSpellcheck",
			"pisaSubscript", "pisaSuperscript"
		];
		// ON / OFF comands
		const onOffCommands = [ "blockQuote", "numberedList", "bulletedList", "code" ];

		let attributeValues = {
			alignment: "left",
			backgroundColor: undefined,
			blockQuote: false,
			bold: false,
			bulletedList: false,
			code: false,
			fontColor: undefined,
			fontFamily: undefined,
			fontSize: undefined,
			italic: false,
			link: undefined,
			numberedList: false,
			strikethrough: false,
			underline: false,
			pisaSpacing: undefined,
			pisaSpellcheck: undefined,
			pisaSubscript: false,
			pisaSuperscript: false
		}

		changeSelection( editor, attributeValues );

		editor.executeIf( "code", {}, true ); // DO NOT REMOVE

		editor.model.change( writer => {

			for ( let attribute of attributes ) {
				let ranges = editor.model.schema.getValidRanges( selection.getRanges(), attribute );
				for ( const range of ranges ) {
					writer.removeAttribute( attribute, range );
				}
				for ( const block of blocks ) {
					writer.removeAttribute( attribute, block );
				}
			}
			for ( const block of blocks ) {
				let indentCommand = editor.commands.get( "indent" );
				if ( !!indentCommand && typeof indentCommand == "object" &&
					indentCommand.value != "0pt" ) {
					writer.setAttribute( "indent", "0pt", block );
				}
				let fontColorCommand = editor.commands.get( "fontColor" );
				if ( !!fontColorCommand && typeof fontColorCommand == "object" &&
					fontColorCommand.value != "none" && fontColorCommand.value != undefined ) {
					writer.setAttribute( "fontColor", void 0, block );
				}
				let backgroundColorCommand = editor.commands.get( "backgroundColor" );
				if ( !!backgroundColorCommand && typeof backgroundColorCommand == "object" &&
					backgroundColorCommand.value != "none" && backgroundColorCommand.value != undefined ) {
					writer.setAttribute( "backgroundColor", void 0, block );
				}
				if ( Validator.isMap( block._attrs ) && block._attrs.size > 0 ) {
					block._attrs.forEach( ( attributeValue, attributeName ) => {
						writer.setAttribute( attributeName, void 0, block );
						writer.removeAttribute( attributeName, block );
					} );

					block._attrs = new Map();
				}
			}

			for ( let cmd of onOffCommands ) {
				if ( editor.commands.get( cmd ).value ) {
					editor.executeIf( cmd, {}, true );
				}
			}

		} );

		// editor.updateSourceElement();
		// editor.ui.update();
		// editor.execute( "undo" );
		// editor.execute( "redo" );

		editor.editing.view.focus();
	}

}

export function removeContent( editor, writer ) {
	if ( !Validator.isObjectPath( editor, "editor.model.document" ) ||
		!Validator.isFunction( editor.model.document.getRoot ) ||
		!Validator.isFunction( editor.model.change ) ) return void 0;
	let root = editor.model.document.getRoot();
	if ( !Validator.isObjectPath( root, "root._children._nodes" ) ||
		!Validator.isArray( root._children._nodes ) ) return void 0;
	if ( root._children._nodes.length < 1 ) return "";
	while ( root._children._nodes.length > 1 ) {
		writer.remove( root._children._nodes[ 0 ] );
	}
	if ( root._children._nodes.length > 0 )
		writer.remove( root._children._nodes[ 0 ] );
}
