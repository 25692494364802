import Validator from '../pisautils/validator';
import EventHelper from '../pisautils/eventhelper';
import HtmHelper from '../pisautils/htmhelper';
import FocusManager from './focusmanager';
import AttachmentObject from '../pisautils/attachmentobject';

export const UP_KEYWORDS = [ "ArrowUp", "PageUp", "PgUp" ];
export const DOWN_KEYWORDS = [ "ArrowDown", "PageDown", "PgDn" ];

export default class KeyupFocusManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		new FocusManager( hostPlugin );
	}

	resetFlags( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ) return false;
		dropdownView.isBeingEdited = void 0;
		delete dropdownView.isBeingEdited;
		dropdownView.matchingFont = void 0;
		delete dropdownView.matchingFont;
		dropdownView.matchingSize = void 0;
		delete dropdownView.matchingSize;
		dropdownView.activeButton = void 0;
		delete dropdownView.activeButton;
		return true;
	}

	keyIs( evt, keyOrCodeName ) {
		return Validator.isString( keyOrCodeName ) && evt instanceof KeyboardEvent &&
			( evt.key === keyOrCodeName || evt.code === keyOrCodeName );
	}

	keyCouldBe( evt, keyOrCodeName ) {
		if ( !Validator.isString( keyOrCodeName ) ||
			!( evt instanceof KeyboardEvent ) ) return false;
		const lowerCaseKeyOrCodeName = keyOrCodeName.toLowerCase();
		if ( Validator.isString( evt.key ) &&
			evt.key.toLowerCase() === lowerCaseKeyOrCodeName ) return true;
		return Validator.isString( evt.code ) &&
			evt.code.toLowerCase() === lowerCaseKeyOrCodeName;
	}

	keyContains( evt, keyOrCodePart ) {
		if ( !Validator.isString( keyOrCodePart ) ||
			!( evt instanceof KeyboardEvent ) ) return false;
		if ( Validator.isString( evt.key ) &&
			evt.key.indexOf( keyOrCodePart ) >= 0 ) return true;
		return Validator.isString( evt.code ) &&
			evt.code.indexOf( keyOrCodePart ) >= 0;
	}

	keyMightContain( evt, keyOrCodePart ) {
		if ( !Validator.isString( keyOrCodePart ) ||
			!( evt instanceof KeyboardEvent ) ) return false;
		const lowerCaseKeyOrCodePart = keyOrCodePart.toLowerCase();
		if ( Validator.isString( evt.key ) &&
			evt.key.toLowerCase().indexOf( lowerCaseKeyOrCodePart ) >= 0 ) return true;
		return Validator.isString( evt.code ) &&
			evt.code.toLowerCase().indexOf( lowerCaseKeyOrCodePart ) >= 0;
	}

	isPrintableKeyEvent( evt ) {
		return evt instanceof KeyboardEvent && Validator.isPrintableKey( evt.key );
	}

	isValidDropdownAndEvent( dropdownView, evt ) {
		return Validator.isObjectPath( dropdownView, "dropdownView.buttonView" ) &&
			Validator.isObject( dropdownView.panelView ) &&
			Validator.isArray( dropdownView.actualList, true ) &&
			dropdownView.panelView.isVisible && evt instanceof KeyboardEvent &&
			!evt.ctrlKey;
	}

	focus( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ||
			!( dropdownView.element instanceof HTMLElement ) ) return false;
		if ( !Validator.isObjectPath( this.editor, "editor.balloons" ) ) {
			dropdownView.element.focus();
			return true;
		}
		this.editor.balloons.doNotHide = true;
		dropdownView.element.focus();
		this.editor.balloons.doNotHide = false;
		return true;
	}

	blur( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ||
			!( dropdownView.element instanceof HTMLElement ) ) return false;
		dropdownView.element.blur();
		return true;
	}

	addTabindexAndKeyupListener( {
		dropdownView,
		keyupMethodName,
		list,
		tabindex = 0
	} ) {
		const tabIndexAdded = this.addTabindex( dropdownView, tabindex );
		const actualListSet = this.setActualList( dropdownView, list );
		const keyupListenerAdded = this.addKeyupListener( dropdownView, keyupMethodName );
		return tabIndexAdded && actualListSet && keyupListenerAdded;
	}

	setActualList( dropdownView, list ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isIterable( list ) ) return false;
		dropdownView.actualList = [ ...list ];
		return true;
	}

	addTabindex( dropdownView, tabIndexValue = 0 ) {
		if ( !Validator.isObject( dropdownView ) ||
			!( dropdownView.element instanceof HTMLElement ) ) return false;
		const tabindex = Validator.isInteger( tabIndexValue ) ? tabIndexValue : 0;
		dropdownView.element.setAttribute( "tabindex", tabindex );
		return true;
	}

	addKeyupListener( dropdownView, keyupMethodName ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isString( keyupMethodName ) ||
			!Validator.isFunction( this[ keyupMethodName ] ) ) return false;
		dropdownView[ keyupMethodName ] = ( evt ) => {
			this[ keyupMethodName ]( dropdownView, evt );
		};
		const listenerAdded = EventHelper.addListener( {
			contextInstance: dropdownView,
			eventName: "keyup",
			methodName: keyupMethodName
		} );
		return listenerAdded;
	}

	scrollAndFocus( dropdownView, returnValue = void 0 ) {
		this.scrollToSelectedButton( dropdownView );
		this.focus( dropdownView );
		return returnValue;
	}

	focusIfChildIsFocused( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ) return false;
		const activeElement = window.document.activeElement;
		if ( !( activeElement instanceof HTMLElement ) ||
			!( dropdownView.element instanceof HTMLElement ) )
			return this.focus( dropdownView );
		if ( activeElement === dropdownView.element ) return true;
		const children = HtmHelper.getAllLevelChildren( dropdownView.element );
		if ( children.indexOf( activeElement ) < 0 ) return false;
		return this.focus( dropdownView );
	}

	getSelectedButtonAndSetActive( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isFunction( dropdownView.getSelectedButtonAndSetActive ) )
			return void 0;
		return dropdownView.getSelectedButtonAndSetActive();
	}

	getNeighbourToSelectedButton( dropdownView, upperNeighbour = true ) {
		if ( !Validator.isObject( dropdownView ) ) return void 0;
		this.focus( dropdownView );
		const activeButton = this.getSelectedButtonAndSetActive( dropdownView );
		if ( !Validator.isObject( activeButton ) ) return void 0;
		let listItemViews = dropdownView.getListItemViews();
		if ( !Validator.isIterable( listItemViews ) ) return void 0;
		listItemViews = [ ...listItemViews ];
		let activeIndex;
		for ( let index = 0; index < listItemViews.length; index++ ) {
			const listItemView = listItemViews[ index ];
			const buttonView = dropdownView.getFirstButtonFromListItem( listItemView );
			if ( buttonView != activeButton ) continue;
			activeIndex = index;
			break;
		}
		if ( !Validator.isPositiveNumber( activeIndex ) ) return void 0;
		if ( !!upperNeighbour && activeIndex === 0 ) return void 0;
		else if ( !upperNeighbour && activeIndex >= listItemViews.length - 1 )
			return void 0;
		const newIndex = activeIndex + ( !!upperNeighbour ? -1 : 1 );
		const listItemView = listItemViews[ newIndex ];
		return dropdownView.getFirstButtonFromListItem( listItemView );
	}

	getLabelFromNeighbourToSelectedButton( dropdownView, upperNeighbour = true ) {
		const neighbourButton = this.getNeighbourToSelectedButton( dropdownView, upperNeighbour );
		return Validator.isObject( neighbourButton ) &&
			Validator.isString( neighbourButton.label ) ? neighbourButton.label : void 0;
	}

	setBasicDropdownFunctionality( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ) return false;

		const self = this;

		Object.defineProperty( dropdownView, "fontFamilyOpen", {
			get: () => {
				if ( !Validator.isObjectPath( self, "self.editor.objects.dropdowns.fontFamily" ) )
					return false;
				return !!self.editor.objects.dropdowns.fontFamily.isOpen;
			},
			set: ( newValue ) => {
				if ( !Validator.isObjectPath( self, "self.editor.objects.dropdowns.fontFamily" ) )
					return;
				self.editor.objects.dropdowns.fontFamily.isOpen = !!newValue;
			}
		} );

		Object.defineProperty( dropdownView, "fontSizeOpen", {
			get: () => {
				if ( !Validator.isObjectPath( self, "self.editor.objects.dropdowns.fontSize" ) )
					return false;
				return !!self.editor.objects.dropdowns.fontSize.isOpen;
			},
			set: ( newValue ) => {
				if ( !Validator.isObjectPath( self, "self.editor.objects.dropdowns.fontSize" ) )
					return;
				self.editor.objects.dropdowns.fontSize.isOpen = !!newValue;
			}
		} );

		dropdownView.getFirstButtonFromListItem = ( listItem ) => {
			if ( !Validator.is( listItem, "ListItemView" ) ||
				!Validator.isObject( listItem.children ) ||
				!Validator.isArray( listItem.children._items, true ) ) return void 0;
			const firstItem = listItem.children._items[ 0 ];
			return Validator.is( firstItem, "ButtonView" ) ? firstItem : void 0;
		};

		dropdownView.selectAndGetActiveButton = activeValue => {
			if ( !Validator.isString( activeValue ) ) return void 0;
			const listViewItems = dropdownView.getListItemViews();
			if ( !Validator.isIterable( listViewItems ) ) return void 0;
			let activeButton;
			for ( let listViewItem of listViewItems ) {
				const buttonView = dropdownView.getFirstButtonFromListItem( listViewItem );
				if ( !Validator.isObject( buttonView ) ) continue;
				buttonView.isOn = buttonView.label === activeValue;
				if ( buttonView.isOn && !Validator.isObject( activeButton ) )
					activeButton = buttonView;
			}
			// activeButton.focus();
			return activeButton;
		};

		dropdownView.getSelectedButtonAndSetActive = ( doubleCheckWithList = true ) => {
			if ( Validator.isObject( dropdownView.activeButton ) &&
				!!dropdownView.activeButton.isOn ) return dropdownView.activeButton;
			let listItemViews = dropdownView.getListItemViews();
			if ( !Validator.isIterable( listItemViews ) ) return void 0;
			let selectedButton;
			const prooveIfInsideList = !!doubleCheckWithList &&
				Validator.isArray( dropdownView.actualList, true );
			for ( let listItemView of listItemViews ) {
				const buttonView = dropdownView.getFirstButtonFromListItem( listItemView );
				if ( !Validator.isObject( buttonView ) || !buttonView.isOn ) continue;
				if ( prooveIfInsideList &&
					!dropdownView.actualList.some( listElement =>
						listElement.startsWith( buttonView.label ) ) ) continue;
				selectedButton = buttonView;
				break;
			}
			dropdownView.activeButton = selectedButton;
			return selectedButton;
		}

		dropdownView.getListView = () => {
			if ( !Validator.isObjectPath( dropdownView.panelView, "panelView.children" ) ||
				!Validator.isArray( dropdownView.panelView.children._items, true ) )
				return void 0;
			let listView = dropdownView.panelView.children._items[ 0 ];
			while ( !Validator.is( listView, "ListView" ) &&
				Validator.isObject( listView.items ) &&
				Validator.isArray( listView.items._items, true ) ) {
				listView = listView.items._items[ 0 ];
			}
			if ( !Validator.is( listView, "ListView" ) ) return void 0;
			return listView;
		};

		dropdownView.getListItemViews = () => {
			const listView = dropdownView.getListView();
			if ( !Validator.is( listView, "ListView" ) ||
				!Validator.isObject( listView.items ) ||
				!Validator.isArray( listView.items._items, true ) ) return void 0;
			return [ ...listView.items._items ];
		};

		const listView = dropdownView.getListView();

		if ( !Validator.isObject( listView ) ) return true;

		listView._focusCycler = {
			focusFirst: () => {},
			focusLast: () => {}
		};

		listView.focusTracker = void 0;

		return true;
	}

}
