import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Validator from '../pisautils/validator';
import PisaPrintCommand from './pisaprintcommand';
import { PRINTCONTENT } from './pisaprint';
import { stopEvent } from '../utils';

export default class PisaPrintListener extends Plugin {

	static get pluginName() {
		return 'PisaPrintListener';
	}

	init() {
		this._addControlPListener();
	}

	_addControlPListener() {
		const editor = this.editor;
		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( !Validator.isObject( data ) || !Validator.couldBe( data.domEvent, "Event" ) ) return;
			if ( !data.domEvent.ctrlKey ) return;
			if ( data.domEvent.key != "p" && data.domEvent.code != "KeyP" ) return;
			editor.executeIf( PRINTCONTENT );
			stopEvent( eventInfo, data );
		}, { priority: Number.MAX_SAFE_INTEGER } );
		delete this._addControlPListener;
	}

}
