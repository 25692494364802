import EditorWatchdog from '@ckeditor/ckeditor5-watchdog/src/editorwatchdog';
import { throttle } from 'lodash-es';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';
import { MAKE_EDITOR_READONLY_EVENT, HANDLE_ERROR_EVENT, HANDLE_MESSAGE } from '../../pisaext/pisadp';

export default class BasicWatchdog extends EditorWatchdog {

	constructor( args, onReady, Editor, watchdogConfig = {} ) {
		super( Editor, watchdogConfig );
		this.onready = Validator.isFunction( onReady ) ? onReady : () => {};
		this.args = args;
		this.psaExt = void 0;
		this.lastContent = "";
		this.lastSetContent = "";
		this.stopHandling = false;
		this.restartCounter = 0;
		this.wdgId = void 0;
		this._editingEnabled = false;
		this._lastEditorWasPlain = false;
		/**
		 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/editorwatchdog~_throttledSave}
		 * @override
		 */
		this._throttledSave = throttle(
			this._save.bind( this ),
			typeof watchdogConfig.saveInterval === 'number' ? watchdogConfig.saveInterval : 5000
		);
		/**
		 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/watchdog~_boundErrorHandler}
		 * @override
		 */
		this._boundErrorHandler = evt => {
			const error = evt.error || evt.reason;
			if ( error instanceof Error ) {
				this._handleError( error, evt );
			}
		};
	}

	/**
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/editorwatchdog~_save}
	 * @override
	 */
	_save() {
		return void 0;
	}

	/**
	 * attached event listeners to the divs as well, in case the errors are not
	 * CKEditorError objects
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/watchdog~_startErrorHandling}
	 * @override
	 */
	_startErrorHandling() {
		super._startErrorHandling();
		if ( !Validator.isObject( this.args ) ||
			!HtmHelper.isDiv( this.args.container ) ) return;
		let container = this.args.container;
		container.addEventListener( 'error', this._boundErrorHandler );
		container.addEventListener( 'unhandledrejection', this._boundErrorHandler );
	}

	/**
	 * detached the previously attached event listeners from the divs as well,
	 * (previously added in case the errors are not CKEditorError objects)
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/watchdog~_stopErrorHandling}
	 * @override
	 */
	_stopErrorHandling() {
		super._stopErrorHandling();
		if ( !Validator.isObject( this.args ) ||
			!HtmHelper.isDiv( this.args.container ) ) return;
		let container = this.args.container;
		container.removeEventListener( 'error', this._boundErrorHandler );
		container.removeEventListener( 'unhandledrejection', this._boundErrorHandler );
	}

	/**
	 * the only change from the method in the superclass is the call of the
	 * "_startErrorHandling" method, the other code is identical
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/editorwatchdog~create}
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/watchdog~_startErrorHandling}
	 * @override
	 */
	create( elementOrData = this._elementOrData, config = this._config, context ) {
		return Promise.resolve()
			.then( () => {
				this._startErrorHandling(); // solely changed line; was: super._startErrorHandling();
				this._elementOrData = elementOrData;
				this._config = this._cloneEditorConfiguration( config ) || {};
				this._config.context = context;
				return this._creator( elementOrData, this._config );
			} )
			.then( editor => {
				if ( !Validator.isObject( editor ) )
					return this._setToPermanentlyCrashed();
				this._editor = editor;
				this._addWidgetIdTemporarySetter( editor );
				editor.model.document._validateSelectionRange = () => { return true; };
				editor.model.document.on( 'change:data', this._throttledSave );
				this._lastDocumentVersion = editor.model.document.version;
				this._data = this._getData();
				this.state = 'ready';
				this._fire( 'stateChange' );
				let self = this;
				editor.on( "beforeSetContent", () => {
					self._editingEnabled = false;
				} );
				editor.on( "editorContentSet", () => {
					self._editingEnabled = true;
					self.lastSetContent = String( self.editorContent );
					if ( !Validator.isObject( self.args ) ) return;
					if ( !self.args.isSecondEditor ) return;
					self.restoreSelection();
					self.focus();
				}, { priority: Number.MIN_SAFE_INTEGER } );
				editor.once( MAKE_EDITOR_READONLY_EVENT, ( eventInfo, options ) => {
					self._setToPermanentlyCrashed();
					if ( !Validator.isObject( options ) ||
						!Validator.isString( options.content ) ) return;
					let contentContainer = self._getEditorContentDiv();
					if ( !HtmHelper.isElement( contentContainer ) ) return;
					contentContainer.innerHTML = options.content;
				} );
				editor.on( HANDLE_ERROR_EVENT, ( eventInfo, options ) => {
					if ( !Validator.isObject( options ) ) return;
					if ( Validator.isObject( options.error ) )
						options.error.wdgId = editor.wdgId;
					self._handleError( options.error, options.evt );
				}, { priority: Number.MAX_SAFE_INTEGER } );
				// editor.on( HANDLE_MESSAGE, ( eventInfo, options ) => {
				// 	if ( !Validator.isObject( options ) ) return;
				// 	self.log( options.message );
				// }, { priority: Number.MAX_SAFE_INTEGER } );
			} );
	}

}
