import AttachmentObject from '../pisautils/attachmentobject';
import PluginUtils from '../pisautils/pluginutils';
import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';
import { IMAGE_CLICKED } from './pisaimageui';
import { findMatchingImage } from './pisaimageediting';

const LOG_CHANGES = true;

export default class ImageClickHanlder extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );

		new PluginUtils( {
			hostPlugin: hostPlugin,
			logChanges: LOG_CHANGES,
			logOutputColor: "#87d37c"
		} );

		Object.defineProperty( hostPlugin, "editableElement", {
			get: () => {
				return !Validator.isObjectPath( hostPlugin, "hostPlugin.editor.ui.view.editable" ) ||
					!( hostPlugin.editor.ui.view.editable.element instanceof Element ) ?
					void 0 : hostPlugin.editor.ui.view.editable.element;
			},
			set: ( newValue ) => {
				hostPlugin._logAsUnmodifiable( "editableElement", newValue );
			}
		} );
	}

	_addImageClickListener() {
		const editor = this.editor;

		editor.on( IMAGE_CLICKED, ( eventInfo, data ) => {
			eventInfo.stop();
			const dataObjectExists = Validator.isObject( data );
			if ( !dataObjectExists ) return;

			const imageBalloonsAllowed = Validator
				.isObjectPath( editor, "editor.balloons.balloons.image" );
			if ( !imageBalloonsAllowed ) return;

			const selectedPisaImage = this.getSelectedPisaImage( data );
			if ( !Validator.couldBe( selectedPisaImage, "Element" ) ||
				selectedPisaImage.name != "pisaImage" ) return;

			const imageIsInsideLink =
				Validator.isString( selectedPisaImage._attrs.get( "linkHref" ) );
			const imageId = data.id || selectedPisaImage._attrs.get( "id" );
			if ( !imageIsInsideLink )
				return this.showImageBalloonOnNode( data.node, imageId );

			const imageBalloonShown = editor.balloons.balloons.image.isVisible;
			if ( imageBalloonShown )
				return editor.balloons.balloons.image.hide();

			const linkBalloonShown =
				Validator.isObjectPath( editor, "editor.objects.linkBalloon" ) &&
				editor.objects.linkBalloon.isVisible;
			if ( !linkBalloonShown ) return;

			const linkBalloonShownOnSameElement =
				editor.objects.linkBalloon.isInsideSameLink;
			if ( !linkBalloonShownOnSameElement ) return;

			return this.showImageBalloonOnNode( data.node, imageId );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		delete this._addImageClickListener;
	}

	getSelectedPisaImage( data ) {
		if ( Validator.isObjectPath( data, "data.imageElement" ) )
			return data.imageElement;
		if ( !Validator.isObject( data ) ) return void 0;
		if ( "id" in data &&
			Validator.isObjectPath( this.editor, "editor.objects.images" ) &&
			Validator.isMap( this.editor.objects.images.map ) )
			return this.editor.objects.images.map.get( data.id );
		if ( "node" in data ) return findMatchingImage( editor, data.node )
		return void 0;
	}

	showImageBalloonOnNode( node, imageId ) {
		const success = this._showImageBalloonOnNode( node, imageId );
		if ( !success ) this._log( `Could not show image balloon on image node` +
			` with the id "${ imageId }".` );
		return success;
	}

	_showImageBalloonOnNode( node, imageId ) {
		const editor = this.editor;
		if ( !Validator.isString( imageId ) ) return false;
		if ( !( node instanceof Node ) ) {
			const editableElement = this.editableElement;
			if ( !( editableElement instanceof Element ) ) return false;
			node = editableElement.getElementById( imageId );
			if ( !( node instanceof Node ) ) return false;
		}
		if ( !Validator.isObjectPath( editor, "editor.balloons.balloons.image" ) ) return false;
		if ( Validator.isFunction( this.editor.balloons.hideAll ) )
			editor.balloons.hideAll();
		let success = true;
		try {
			editor.balloons._showOnNode( "image", node );
			// we assign the image ID to the image balloon after the balloon is shown
			editor.balloons.balloons.image.imageId = imageId;
		} catch ( err ) {
			success = false;
		}
		return success;
	}

}
