import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import FontFamilyCommand from '@ckeditor/ckeditor5-font/src/fontfamily/fontfamilycommand';
import { addUpcastConversion, addUpcastContainerConversion } from '../utils';

export const FONT_FAMILY = 'fontFamily';
export const FONT_FAMILY_ATTRIBUTE = 'font-family';

export default class FontFamilyEditing extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		const editor = this.editor;
		editor.model.schema.extend( '$text', { allowAttributes: FONT_FAMILY } );
		addDowncastConversion( editor );
		addUpcastConversion( editor, FONT_FAMILY_ATTRIBUTE, FONT_FAMILY, [ "span", "a" ] );
		addUpcastContainerConversion( editor, FONT_FAMILY_ATTRIBUTE, FONT_FAMILY );
		// editor.commands.add( FONT_FAMILY, new FontFamilyCommand( editor ) );
	}
}

function addDowncastConversion( editor ) {
	editor.conversion.for( 'downcast' ).attributeToElement( {
		model: {
			key: FONT_FAMILY,
			name: '$text'
		},
		view: ( modelAttributeValue, viewWriter ) => {
			return viewWriter.createAttributeElement( 'span', {
				style: `${FONT_FAMILY_ATTRIBUTE}:`.concat( modelAttributeValue )
			} );
		}
	} );
}
