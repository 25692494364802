import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import { CELL_WIDTH, CELL_HEIGHT, TABLE_CELL } from './pisacellcommand';
import { upcastStyleTagAttr, downcastStyleTagAttr } from '../utils';

export default class PisaCellEditing extends Plugin {

	init() {
		const editor = this.editor;
		const schema = editor.model.schema;
		const conversion = editor.conversion;

		schema.extend( TABLE_CELL, { allowAttributes: CELL_WIDTH } );
		schema.extend( TABLE_CELL, { allowAttributes: CELL_HEIGHT } );

		// conversion.for( 'downcast' ).attributeToAttribute( {
		// 	model: {
		// 		name: TABLE_CELL,
		// 		key: CELL_WIDTH
		// 	},
		// 	view: "width"
		// } );
		//
		// conversion.for( 'downcast' ).attributeToAttribute( {
		// 	model: {
		// 		name: TABLE_CELL,
		// 		key: CELL_HEIGHT
		// 	},
		// 	view: "height"
		// } );

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "td",
				key: "width"
			},
			model: CELL_WIDTH
		} );

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "td",
				key: "height"
			},
			model: CELL_HEIGHT
		} );

		upcastStyleTagAttr( editor, "td", "height", CELL_HEIGHT );
		downcastStyleTagAttr( editor, TABLE_CELL, CELL_HEIGHT, "height" );
		upcastStyleTagAttr( editor, "td", "width", CELL_WIDTH );
		downcastStyleTagAttr( editor, TABLE_CELL, CELL_WIDTH, "width" );
	}

}
