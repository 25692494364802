import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import BackgroundColorCommand from './pisabckgcommand';
import { createColorDropdown } from '../pisafontcolor/pisacolorui';
import { backgroundIcon } from '../icons';

const BACKGROUND_COLOR = "backgroundColor";

export default class BackgroundColorUI extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( BACKGROUND_COLOR, new BackgroundColorCommand( editor ) );
		editor.ui.componentFactory.add( BACKGROUND_COLOR, locale => {
			return createColorDropdown( editor, BACKGROUND_COLOR, backgroundIcon,
				editor.objects.tooltips.getT( BACKGROUND_COLOR ) );
		} );
	}

}
