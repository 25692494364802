import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { upcastStyleTagAttr, downcastStyleTagAttr } from '../utils';
import { TABLE_CELL } from './pisacellcommand';
import { CELL_STYLE } from './pisacellstylecommand';
// import PisaCellStyleCommand from './pisacellstylecommand';

export const CELL_PADDING = "padding";
export const CELL_BORDER_WIDTH = "border-width";
export const CELL_BORDER_COLOR = "border-color";
export const CELL_BORDER_STYLE = "border-style";
export const CELL_BACKGROUND_COLOR = "background-color";
const TD = "td";

export default class PisaCellStyleEditing extends Plugin {

	init() {
		const editor = this.editor;
		const schema = editor.model.schema;
		const conversion = editor.conversion;

		schema.extend( TABLE_CELL, { allowAttributes: CELL_PADDING } );
		schema.extend( TABLE_CELL, { allowAttributes: CELL_BORDER_WIDTH } );
		schema.extend( TABLE_CELL, { allowAttributes: CELL_BORDER_COLOR } );
		schema.extend( TABLE_CELL, { allowAttributes: CELL_BORDER_STYLE } );
		schema.extend( TABLE_CELL, { allowAttributes: CELL_BACKGROUND_COLOR } );

		// editor.commands.add( CELL_STYLE, new PisaCellStyleCommand( editor ) );

		modelToView( editor );
		viewToModel( editor );

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "table",
				key: "border"
			},
			model: "border"
		} );

		editor.model.schema.extend( "table", { allowAttributes: "border" } );
	}

}

function viewToModel( editor ) {
	upcastStyleTagAttr( editor, TD, CELL_PADDING );
	upcastStyleTagAttr( editor, TD, CELL_BORDER_WIDTH );
	upcastStyleTagAttr( editor, TD, CELL_BORDER_COLOR );
	upcastStyleTagAttr( editor, TD, CELL_BORDER_STYLE );
	upcastStyleTagAttr( editor, TD, CELL_BACKGROUND_COLOR );
}

function modelToView( editor ) {
	downcastStyleTagAttr( editor, TABLE_CELL, CELL_PADDING );
	downcastStyleTagAttr( editor, TABLE_CELL, CELL_BORDER_WIDTH );
	downcastStyleTagAttr( editor, TABLE_CELL, CELL_BORDER_COLOR );
	downcastStyleTagAttr( editor, TABLE_CELL, CELL_BORDER_STYLE );
	downcastStyleTagAttr( editor, TABLE_CELL, CELL_BACKGROUND_COLOR );
}

function getCells( tableElement, isViewElement = true ) {
	!isViewElement && tableElement._classes && tableElement._styles ?
		isViewElement = true : void 0;
	return isViewElement ? getViewTableCells( tableElement ) : getModelTableCells( tableElement );
}

function getViewTableCells( tableViewElement ) {
	let cellList = []
	if ( !tableViewElement || !tableViewElement._children ||
		tableViewElement._children.length <= 0 ) return cellList;
	tableViewElement._children.filter( child => child.name == "tbody" ).forEach( tBody => {
		tBody._children.filter( child => ( child.name == "tr" || child.name == "th" ) )
			.forEach( column => {
				column._children.filter( child => child.name == "td" ).forEach( cell => {
					cellList.push( cell );
				} );
			} );
	} );
	return cellList;
}

function getModelTableCells( tableModelElement ) {
	let cellList = []
	if ( !tableModelElement || !tableModelElement._children || !tableModelElement._children._nodes ||
		tableModelElement._children._nodes.length <= 0 ) return cellList;
	tableModelElement._children._nodes.filter( child => child.name == "tableRow" )
		.forEach( row => {
			if ( row._children && row._children._nodes && row._children._nodes.length > 0 ) {
				row._children._nodes.filter( child => child.name == "tableCell" )
					.forEach( cell => {
						cellList.push( cell );
					} );
			}
		} );
	return cellList;
}
