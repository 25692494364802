import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';
import HtmHelper from '../../pisautils/htmhelper';
import FunctionHelper from '../../pisautils/functionhelper';
import { removeLastUndoSteps } from '../../utils';

export default class UndoStepsHandler extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		hostPlugin.batches = new Map();
		hostPlugin.lastBatch = void 0;

		hostPlugin.unmodifiableGetter( "newBatch", () => {
			if ( !Validator.isObjectPath( hostPlugin, "hostPlugin.editor.model" ) ||
				!Validator.isFunction( hostPlugin.editor.model.createBatch ) ) return void 0;
			return hostPlugin.editor.model.createBatch();
		} );

		hostPlugin.unmodifiableGetter( "undoCommand", () => {
			return Validator.isObjectPath( hostPlugin, "hostPlugin.editor.commands._commands" ) &&
				Validator.isMap( hostPlugin.editor.commands._commands ) ?
				hostPlugin.editor.commands._commands.get( "undo" ) : void 0;
		} );

		hostPlugin.unmodifiableGetter( "redoCommand", () => {
			return Validator.isObjectPath( hostPlugin, "this.editor.commands._commands" ) &&
				Validator.isMap( hostPlugin.editor.commands._commands ) ?
				hostPlugin.editor.commands._commands.get( "redo" ) : void 0;
		} );

		hostPlugin.unmodifiableGetter( "undoStack", () => {
			const undoCommand = hostPlugin.undoCommand;
			if ( !Validator.couldBe( undoCommand, "UndoCommand" ) ) return void 0;
			if ( !Validator.isArray( undoCommand._stack ) ) return void 0;
			return undoCommand._stack;
		} );

		hostPlugin.unmodifiableGetter( "redoStack", () => {
			const redoCommand = hostPlugin.redoCommand;
			if ( !Validator.couldBe( redoCommand, "RedoCommand" ) ) return void 0;
			if ( !Validator.isArray( redoCommand._stack ) ) return void 0;
			return redoCommand._stack;
		} );

		hostPlugin.unmodifiableGetter( "lastUndoEntry", () => {
			const undoStack = hostPlugin.undoStack;
			if ( !Validator.isArray( undoStack ) || undoStack.length < 1 ) return void 0;
			const lastEntry = undoStack[ undoStack.length - 1 ];
			return Validator.isObjectPath( lastEntry, "lastEntry.batch" ) &&
				Validator.isObject( lastEntry.selection ) ? lastEntry : void 0;
		} );

		hostPlugin.unmodifiableGetter( "lastRedoEntry", () => {
			const redoStack = hostPlugin.redoStack;
			if ( !Validator.isArray( redoStack ) || redoStack.length < 1 ) return void 0;
			const lastEntry = redoStack[ redoStack.length - 1 ];
			return Validator.isObjectPath( lastEntry, "lastEntry.batch" ) &&
				Validator.isObject( lastEntry.selection ) ? lastEntry : void 0;
		} );

		hostPlugin.unmodifiableGetter( "lastUndoBatch", () => {
			const undoEntry = hostPlugin.lastUndoEntry;
			if ( !Validator.isObject( undoEntry ) ) return void 0;
			return Validator.is( undoEntry.batch, "Batch" ) ? undoEntry.batch : void 0;
		} );

		hostPlugin.unmodifiableGetter( "lastRedoBatch", () => {
			const redoEntry = hostPlugin.lastRedoEntry;
			if ( !Validator.isObject( redoEntry ) ) return void 0;
			return Validator.is( redoEntry.batch, "Batch" ) ? redoEntry.batch : void 0;
		} );

		hostPlugin.unmodifiableGetter( "newBatchId", () => {
			return HtmHelper.generateRandomString( "batch-" );
		} );

		let onUndoHighPrio = ( eventInfo ) => {
			hostPlugin.lastBatch = hostPlugin.lastUndoBatch;
		};
		let onUndoLowPrio = ( eventInfo ) => {
			let lastBatch = hostPlugin.lastBatch;
			if ( !Validator.isObject( lastBatch ) ||
				!Validator.isString( lastBatch.id ) ) return hostPlugin.voidLastBatch();
			let entry = hostPlugin.getEntryFromBatch( lastBatch );
			if ( !Validator.isObjectPath( entry, "entry.batch" ) )
				return hostPlugin._log( `A bacth with the ID "${ lastBatch.id }"` +
					` was detected in the undo stack, but was not registered in the` +
					` batches map/list.`, hostPlugin.voidLastBatch() );
			if ( !Validator.isString( entry.batch.id ) )
				entry.batch.id = lastBatch.id;
			if ( entry.batch.id != lastBatch.id )
				hostPlugin._log( `The batch registered in the batches map/list under` +
					` the ID "${ lastBatch.id }" has an ID (as property "id")` +
					` that does not correspond to the ID it was registered under and` +
					` has the value of "${ entry.batch.id }".` );
			else if ( entry.batch != lastBatch )
				hostPlugin._log( `An undo batch has the same ID as a batch` +
					` registered in the batches map/list, but the two batches are not` +
					` the same object instance. Batch ID: "${ lastBatch.id }".` );
			entry.inline ? hostPlugin
				._handleUndoOnInlinePlaceholder( eventInfo, lastBatch, entry ) :
				hostPlugin
				._handleUndoOnMultilinePlaceholder( eventInfo, lastBatch, entry );
			hostPlugin.voidLastBatch();
		};

		const undoCommand = hostPlugin.undoCommand;
		if ( Validator.isObject( undoCommand ) ) {
			undoCommand.on( "execute", ( eventInfo ) => {
				onUndoHighPrio( eventInfo );
			}, hostPlugin.priority.thirdMax );

			undoCommand.on( "execute", ( eventInfo ) => {
				onUndoLowPrio( eventInfo );
			}, hostPlugin.priority.thirdMin );
		}
		// undoCommand.on( "execute", ( eventInfo ) => {
		// 	// depending on the priority of the listener, the batch could be in the
		// 	// undo or redo stack:
		// 	// high priority -> undo stack
		// 	// low priority -> redo stack
		// 	let lastBatch = hostPlugin.lastRedoBatch;
		// 	// let lastBatch = hostPlugin.lastUndoBatch;
		// 	if ( !Validator.isObject( lastBatch ) ||
		// 		!Validator.isString( lastBatch.id ) ) return;
		// 	let entry = hostPlugin.getEntryFromBatch( lastBatch );
		// 	if ( !Validator.isObjectPath( entry, "entry.batch" ) )
		// 		return hostPlugin._log( `A bacth with the ID "${ lastBatch.id }"` +
		// 			` was detected in the undo stack, but was not registered in the` +
		// 			` batches map/list.` );
		// 	if ( !Validator.isString( entry.batch.id ) )
		// 		entry.batch.id = lastBatch.id;
		// 	if ( entry.batch.id != lastBatch.id )
		// 		hostPlugin.log( `The batch registered in the batches map/list under` +
		// 			` the ID "${ lastBatch.id }" has an ID (as property "id")` +
		// 			` that does not correspond to the ID it was registered under and` +
		// 			` has the value of "${ entry.batch.id }".` );
		// 	else if ( entry.batch != lastBatch )
		// 		hostPlugin._log( `An undo batch has the same ID as a batch` +
		// 			` registered in the batches map/list, but the two batches are not` +
		// 			` the same object instance. Batch ID: "${ lastBatch.id }".` );
		// 	entry.inline ? hostPlugin
		// 		._handleUndoOnInlinePlaceholder( eventInfo, lastBatch, entry ) :
		// 		hostPlugin
		// 		._handleUndoOnMultilinePlaceholder( eventInfo, lastBatch, entry );
		// }, hostPlugin.priority.thirdMin );
	}

	voidLastBatch() {
		this.lastBatch = void 0;
	}

	registerBatch( batch, placeholdersList, inline = true ) {
		!!inline ? this.registerInlineBatch( batch, placeholdersList ) :
			this.registerMultilineBatch( batch, placeholdersList );
	}

	registerMultilineBatch( batch, placeholdersList ) {
		if ( !Validator.is( batch, "Batch" ) ) return false;
		if ( !Validator.isObject( placeholdersList ) ) placeholdersList = [];
		if ( !Validator.isArray( placeholdersList ) )
			placeholdersList = [ placeholdersList ];
		if ( !Validator.isString( batch.id ) ) batch.id = this.newBatchId;
		let entry = this.batches.get( batch.id );
		let placeholderTitleGroups = Validator
			.isObjectPath( entry, "entry.placeholderTitleGroups" ) ?
			entry.placeholderTitleGroups : new Map();
		let newPlaceholderTitleValue = void 0;
		let newPlaceholderAttributes = void 0;
		let newGroupIndex = void 0;
		for ( let placeholder of placeholdersList ) {
			if ( !Validator.isObject( placeholder ) ||
				!Validator.isMap( placeholder._attrs ) ) continue;
			let placeholderName = placeholder._attrs.get( "placeholderName" );
			if ( !Validator.isString( placeholderName ) ) continue;
			let groupIndex = placeholder._attrs.get( "groupIndex" );
			if ( !Validator.isString( groupIndex ) &&
				!Validator.isPositiveInteger( groupIndex ) ) continue;
			newPlaceholderTitleValue = placeholderName;
			newGroupIndex = String( groupIndex );
			newPlaceholderAttributes = Validator.isMap( placeholder._attrs ) ?
				new Map( placeholder._attrs ) : new Map();
			break;
		}
		if ( !Validator.isString( newPlaceholderTitleValue ) )
			return this._log( `A new batch could not be registered in the batches` +
				` map/list, because the placeholder title could not be found.`, false );

		let placeholderGroupsMap = Validator.isMap( placeholderTitleGroups ) ?
			placeholderTitleGroups.get( newPlaceholderTitleValue ) : new Map();
		if ( !Validator.isMap( placeholderGroupsMap ) )
			placeholderGroupsMap = new Map();
		let placeholderGroup = placeholderGroupsMap.get( newGroupIndex );
		if ( !Validator.isObject( placeholderGroup ) ) placeholderGroup = {};
		let placeholderLinesGroupsList = placeholderGroup.groupsList;
		if ( !Validator.isArray( placeholderLinesGroupsList ) )
			placeholderLinesGroupsList = [];
		placeholderLinesGroupsList.push( placeholdersList );
		placeholderGroup.groupsList = placeholderLinesGroupsList;
		if ( !Validator.isMap( placeholderGroup._attrs ) )
			placeholderGroup._attrs = newPlaceholderAttributes;
		if ( !Validator.isBoolean( placeholderGroup.inline ) )
			placeholderGroup.inline = false;
		placeholderGroupsMap.set( newGroupIndex, placeholderGroup );
		placeholderTitleGroups
			.set( newPlaceholderTitleValue, placeholderGroupsMap );

		this.batches.set( batch.id, {
			inline: false,
			batch: batch,
			placeholderTitleGroups: placeholderTitleGroups
		} );
		// console.log( this.batches );
		return true;
	}

	registerInlineBatch( batch, inlinePlaceholder ) {
		if ( !Validator.is( batch, "Batch" ) ) return false;
		if ( !Validator.isObject( inlinePlaceholder ) ) return false;
		if ( Validator.isArray( inlinePlaceholder ) ) {
			if ( inlinePlaceholder.length != 1 ) return false;
			inlinePlaceholder = inlinePlaceholder[ 0 ];
		}
		if ( !Validator.isString( batch.id ) ) batch.id = this.newBatchId;
		let entry = this.batches.get( batch.id );
		let placeholderTitleGroups = Validator
			.isObjectPath( entry, "entry.placeholderTitleGroups" ) ?
			entry.placeholderTitleGroups : new Map();

		if ( !Validator.isMap( inlinePlaceholder._attrs ) )
			return this._log( `A new batch could not be registered in the batches` +
				` map/list, because the placeholder title could not be found.`, false );
		let newPlaceholderTitleValue = inlinePlaceholder._attrs.get( "title" );
		if ( !Validator.isString( newPlaceholderTitleValue ) )
			return this._log( `A new batch could not be registered in the batches` +
				` map/list, because the placeholder title could not be found.`, false );
		let newPlaceholderAttributes = Validator.isMap( inlinePlaceholder._attrs ) ?
			new Map( inlinePlaceholder._attrs ) : new Map();

		let groupIndex = HtmHelper.generateRandomString();

		let placeholderGroupsMap = Validator.isMap( placeholderTitleGroups ) ?
			placeholderTitleGroups.get( newPlaceholderTitleValue ) : new Map();
		if ( !Validator.isMap( placeholderGroupsMap ) )
			placeholderGroupsMap = new Map();
		let placeholderGroup = {
			groupsList: [ inlinePlaceholder ],
			_attrs: newPlaceholderAttributes,
			inline: true
		}
		placeholderGroupsMap.set( groupIndex, placeholderGroup );
		placeholderTitleGroups.set( newPlaceholderTitleValue, placeholderGroupsMap );

		this.batches.set( batch.id, {
			inline: true,
			batch: batch,
			placeholderTitleGroups: placeholderTitleGroups
		} );
		// console.log( this.batches );
		return true;
	}

	clearBatchesList( inline = true ) {
		this.batches = new Map();
		// console.log( this.batches );
	}

	getEntryFromBatch( batch ) {
		if ( !Validator.isObject( batch ) ) return false;
		return this.getEntryFromBatchId( batch.id );
	}

	removeBatchFromList( batch ) {
		if ( !Validator.isObject( batch ) ) return false;
		return this.removeBatchIdFromList( batch.id );
	}

	getEntryFromBatchId( batchId ) {
		if ( !Validator.isString( batchId ) ) return false;
		return this.batches.get( batchId );
	}

	removeBatchIdFromList( batchId ) {
		if ( !Validator.isString( batchId ) ) return false;
		this.batches.set( batchId, void 0 );
		this.batches.delete( batchId );
		// console.log( this.batches );
		return true;
	}

	_removeLastUndoSteps( quantity = 1 ) {
		if ( !Validator.isNumber( quantity ) ) quantity = 1;
		removeLastUndoSteps( this.editor, quantity );
	}

	_removeLastUndoStep() {
		removeLastUndoSteps( this.editor );
	}

	doWithLastBatchRemoval( callback, argumentsArr, scope, stepsQuantity = 1 ) {
		let returnValue = FunctionHelper.exec( callback, argumentsArr, scope );
		this._removeLastUndoSteps( stepsQuantity );
		return returnValue;
	}

	removeLastBatch() {
		this._removeLastUndoStep();
	}

	getRegisteredBatch( insertInfo = null ) {
		if ( Validator.isObject( insertInfo ) &&
			Validator.is( insertInfo.batch, "Batch" ) )
			return insertInfo.batch;
		let lastUndoBatch = this.lastUndoBatch;
		if ( Validator.is( lastUndoBatch, "Batch" ) ) return lastUndoBatch;
		return this.newBatch;
	}

	_handleUndoOnInlinePlaceholder( eventInfo, lastBatch, entry ) {
		// console.log( "undo on inline plh" );
	}

	_handleUndoOnMultilinePlaceholder( eventInfo, lastBatch, entry ) {
		// console.log( "undo on multiline plh" );
		entry.placeholderTitleGroups.forEach( ( groupIndexMap, placeholderTitle ) => {
			groupIndexMap.forEach( ( groupObject, groupIndex ) => {
				for ( let placeholdersList of groupObject.groupsList ) {
					this._handleUndoMultilinePlaceholderList( {
						eventInfo: eventInfo,
						lastBatch: lastBatch,
						entry: entry,
						placeholderTitle: placeholderTitle,
						groupIndexMap: groupIndexMap,
						groupIndex: groupIndex,
						groupObject: groupObject,
						placeholdersList: placeholdersList
					} );
				}
			} );
		} );
	}

	_handleUndoMultilinePlaceholderList( {
		eventInfo,
		lastBatch,
		entry,
		placeholderTitle,
		groupIndexMap,
		groupIndex,
		groupObject,
		placeholdersList
	} ) {
		if ( placeholdersList.length == groupObject._attrs.get( "placeholderLineNumber" ) ||
			String( placeholdersList.length ) == groupObject._attrs.get( "placeholderLineNumber" ) ) {
			// this happens on attribute operations and on character insertions when selection is not collapsed
			this.objectsPlaceholders.registerMultilinePlaceholder( {
				key: placeholderTitle,
				base64Value: groupObject._attrs.get( "placeholderValue" ),
				placeholderLines: placeholdersList
			} );
			return;
		}
	}

}
