
import View from '@ckeditor/ckeditor5-ui/src/view';

export default class EmptyView extends View {

	constructor( classesList = [] ) {

		super();

		this.items = this.createCollection();
    this.setTemplate( {
			tag: 'div',
			children: this.items
		} );

    for ( let cssClass of classesList ) {
      this.extendTemplate( {
        attributes: {
  				class: [
  					cssClass
  				]
  			}
      } );
    }

	}

  focus() {
		this.items.first.focus();
	}

	render() {
		super.render();
	}
}
