import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/placeholder.css';
import PlaceholdersUI from './placeholdersui';
import PisaPlaceholderValuesUI from './pisaplaceholdervaluesui';
import PisaDefinitionEditing from './pisadefinitionediting';
import PisaBlockDefinitionEditing from './pisablockdefinitionediting';
import PisaDefinitionStyleEditing from './pisadefinitionstyleediting';
import ShowPlaceholderCommand from './showplaceholdercommand';
import InsertPlaceholderCommand from './insertplaceholdercommand';
import RefreshPlaceholderCommand from './refreshplaceholdercommand';
import PlaceholderToTextCommand from './placeholdertotextcommand';
import { INSERT_PLACEHOLDER, getValidPosition } from './insertplaceholdercommand';
import { REFRESH_PLACEHOLDER } from './refreshplaceholdercommand';
import { PLACEHOLDER_TO_TEXT } from './placeholdertotextcommand';
import { TOGGLE_PLACEHOLDER_EDITING, ENABLE_PLACEHOLDER_EDITING, DISABLE_PLACEHOLDER_EDITING } from './pisaplaceholdervaluesui';
import { insertTabSpace } from '../pisaselection/pisalisteners';
import dropdownArrowIcon from '@ckeditor/ckeditor5-ui/theme/icons/dropdown-arrow.svg';
import IconView from '@ckeditor/ckeditor5-ui/src/icon/iconview';
import EmptyView from '../pisadropdown/emptyview';
import {
	createButton,
	addClasses,
	addViewChildren,
	bindClickToExecute,
	fire,
	updateLastSelection,
	onDomEvent,
	stopEvent,
	getObjectProto,
	isValidModelPosition,
	setSelectionOnElement,
	removeLastUndoSteps,
	areLiveSelectionRangesValid,
	handleInvalidSelectionRanges,
	getModelElementByPath
} from '../utils';
import Validator from '../pisautils/validator';
import { placeholderIcon, refreshIcon, editPlaceholdersIcon } from '../icons';

export const PLACEHOLDER = "pisaPlaceholder";
export const PLACEHOLDER_NAMES = "pisaPlaceholderNames";
export const PLACEHOLDER_VALUES = "pisaPlaceholderValues";

const ALLOWED_KEYS = [ "F2", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight" ];
// const STYLING_COMMANDS = [ "bold", "italic", "underline", "strikethrough" ];

const LOG_CHANGES = true;
const ENABLE_EDITING_IN_VALUES_MODE = true;

export default class PisaPlaceholderUI extends Plugin {

	static get pluginName() {
		return 'PisaPlaceholderUI';
	}

	static get requires() {
		return [ PisaDefinitionEditing,
			PisaBlockDefinitionEditing,
			PisaDefinitionStyleEditing
		];
	}

	init() {

		Object.defineProperty( this, "editingEnabled", {
			value: ENABLE_EDITING_IN_VALUES_MODE,
			writable: true,
			configurable: false
		} );

		const editor = this.editor;
		let flags = new PlaceholdersUI( this );

		if ( ENABLE_EDITING_IN_VALUES_MODE ) {
			let valuesEditing = new PisaPlaceholderValuesUI( this );
			this.editor.temp = valuesEditing;
		} else {
			this.valuesEditing = {
				handleMouseDown: () => {},
				handleMouseUp: () => {},
				onSelectionChangeDone: () => {},
				onKeydown: () => {},
				onKeyup: () => {}
			};
		}

		let self = this;
		let cleanup = this.cleanup;

		editor.commands.add( PLACEHOLDER, new ShowPlaceholderCommand( editor ) );
		editor.commands.add( INSERT_PLACEHOLDER, new InsertPlaceholderCommand( editor ) );
		editor.commands.add( REFRESH_PLACEHOLDER, new RefreshPlaceholderCommand( editor ) );
		editor.commands.add( PLACEHOLDER_TO_TEXT, new PlaceholderToTextCommand( editor ) );
		const toggleCommand = editor.commands.get( PLACEHOLDER );
		const insertCommad = editor.commands.get( INSERT_PLACEHOLDER );

		editor.ui.componentFactory.add( PLACEHOLDER, locale => {
			let arrowButton = createButton( dropdownArrowIcon,
				editor.objects.tooltips.getT( INSERT_PLACEHOLDER ), locale );
			addClasses( arrowButton, [ "pisa-arrow-down-button" ] );
			addClasses( arrowButton.iconView, [ "pisa-arrow-down-icon" ] );
			let button = createButton( placeholderIcon,
				editor.objects.tooltips.getT( PLACEHOLDER ), locale );
			let refreshButton = createButton( refreshIcon,
				editor.objects.tooltips.getT( REFRESH_PLACEHOLDER ), locale );
			// let togglePlaceholderEditingButton = !ENABLE_EDITING_IN_VALUES_MODE ?
			// 	void 0 : createButton( editPlaceholdersIcon,
			// 		editor.objects.tooltips.getT( ( this.editingEnabled ?
			// 			DISABLE_PLACEHOLDER_EDITING : ENABLE_PLACEHOLDER_EDITING ) ), locale );

			arrowButton.on( "execute", () => {
				if ( isInsidePlaceholder( editor ) ) return;
				editor.objects.selection.update();
				editor.fire( PLACEHOLDER, { ins: true } );
			} );
			button.on( "execute", () => {
				if ( editor.objects.placeholders.titlesShown ) {
					if ( ENABLE_EDITING_IN_VALUES_MODE )
						this.valuesEditing.registerOperations = false;
					button.isOn = false;
					button.tooltip = editor.objects.tooltips.getT( PLACEHOLDER_NAMES );
					editor.executeIf( PLACEHOLDER, { show: false }, false );
					if ( ENABLE_EDITING_IN_VALUES_MODE )
						this.valuesEditing.registerOperations = true;
					return;
				}
				button.isOn = true;
				button.tooltip = editor.objects.tooltips.getT( PLACEHOLDER_VALUES );
				editor.executeIf( PLACEHOLDER, { show: true }, false );
			} );
			refreshButton.on( "execute", () => {
				editor.fire( PLACEHOLDER, { ins: false } );
			} );
			// if ( ENABLE_EDITING_IN_VALUES_MODE ) {
			// 	togglePlaceholderEditingButton.on( "execute", () => {
			// 		this.editingEnabled = !this.editingEnabled;
			// 		togglePlaceholderEditingButton.isOn = this.editingEnabled;
			// 		togglePlaceholderEditingButton.tooltip = editor.objects.tooltips.getT(
			// 			( this.editingEnabled ? DISABLE_PLACEHOLDER_EDITING :
			// 				ENABLE_PLACEHOLDER_EDITING ) );
			// 	} );
			// 	togglePlaceholderEditingButton.isOn = this.editingEnabled;
			// }

			// we need an array with all buttons for the case when there will be multiple
			// "toggle placeholder" buttons in the same editor ui (toolbar, balloons etc.)
			// so that the functions and "button" instance isn't overriden every time
			// (to avoid saving ONLY the last one created by the callback)
			editor.objects.placeholders.ui.toggleButtons.push( button );

			let container = new EmptyView( [ "" ] );
			button.bind( 'isEnabled' ).to( toggleCommand );
			refreshButton.bind( 'isEnabled' ).to( toggleCommand );
			arrowButton.bind( 'isEnabled' ).to( insertCommad );
			// if ( ENABLE_EDITING_IN_VALUES_MODE )
			// 	togglePlaceholderEditingButton.bind( 'isEnabled' ).to( toggleCommand );

			editor.objects.focus._addExecuteFocus( button );
			editor.objects.focus._addExecuteFocus( refreshButton );
			editor.objects.focus._addExecuteFocus( arrowButton );

			let viewChildren = {
				1: button,
				2: arrowButton,
				3: refreshButton
			};

			// if ( ENABLE_EDITING_IN_VALUES_MODE )
			// 	viewChildren[ "4" ] = togglePlaceholderEditingButton;

			addViewChildren( container, viewChildren );

			editor.objects.focus._addExecuteFocus( container );

			return container;
		} );

		// make sure the button show the actual state after command is executed for
		// the case when it's executed from outside
		toggleCommand.on( "execute", eventInfo => {
			editor.objects.placeholders.ui.harmonizeAllToggleButtons();
		}, { priority: "lowest" } );

		let mouseDown = ( evt ) => {
			if ( this.changesAllowed ) return this.cleanup( true ) &&
				this.valuesEditing.handleMouseDown( evt );

			let anchor = editor.objects.position.currentAnchor;
			let focus = editor.objects.position.currentFocus;
			if ( !isAtPlaceholderMargin( editor ) &&
				( !anchor || !isPositionInPlaceholder( anchor ) ) &&
				( !focus || !isPositionInPlaceholder( focus ) ) ) return;
			self.setMouseIsDown( true );
		}
		onDomEvent( this.editor, "mousedown", mouseDown );

		let mouseUp = ( evt ) => {
			if ( this.changesAllowed ) return this.cleanup( true ) &&
				this.valuesEditing.handleMouseUp( evt );

			if ( !self.mouseIsDown ) return;
			self.setMouseIsDown( false );
		}
		onDomEvent( this.editor, "mouseup", mouseUp );

		editor.editing.view.document.on( 'selectionChangeDone', ( eventInfo, data ) => {
			if ( this.changesAllowed ) return this.cleanup( true ) &&
				this.valuesEditing.onSelectionChangeDone( eventInfo, data );

			if ( !data || !data.newSelection ) return;
			if ( data.newSelection.isCollapsed ) {
				let position = editor.objects.selection.getLastPosition( true, true ) ||
					editor.objects.selection.getLastPosition( true, false );
				if ( isAtPlaceholderStart( position ) &&
					position.parent.name == "pisaPlaceholder" ) {
					let newPosition = editor.model.createPositionBefore( position.parent );
					editor.model.change( writer => writer.setSelection( newPosition ) );
				} else if ( isAtPlaceholderEnd( position ) &&
					position.parent.name == "pisaPlaceholder" ) {
					let newPosition = editor.model.createPositionAfter( position.parent );
					editor.model.change( writer => writer.setSelection( newPosition ) );
				} else if ( isPositionInPlaceholder( position ) &&
					position.parent.name == "pisaPlaceholder" ) {
					let currentOffset = position.offset || 0;
					let maxOffset = position.parent.maxOffset || 0;
					let newPosition = currentOffset < maxOffset * 0.5 ?
						editor.model.createPositionBefore( position.parent ) :
						editor.model.createPositionAfter( position.parent );
					editor.model.change( writer => writer.setSelection( newPosition ) );
				}
				editor.objects.selection.update();
				return cleanup();
			}
			let endPosition = editor.objects.selection.getLastPosition( true, false );
			let startPosition = editor.objects.selection.getLastPosition( true, true );
			let isStartInPlh = isPositionInPlaceholder( startPosition );
			let isEndInPlh = isPositionInPlaceholder( endPosition );
			if ( !editor.objects.position.currentlyAtDocumentStart &&
				!editor.objects.position.currentlyAtDocumentEnd ) {
				this.setSelectAll( false );
			} else if ( editor.objects.position.fullDocumentSelected ) {
				this.setSelectAll( true );
			}
			if ( !isStartInPlh && !isEndInPlh ) return;
			let isStartInMultiline = isPositionInMultilinePlaceholder( startPosition );
			let isEndInMutliline = isPositionInMultilinePlaceholder( endPosition );
			if ( isStartInMultiline && isEndInMutliline ) {
				self._handlePositionInMultilinePlh( startPosition );
				self.setPlaceholderSelected( true );
				editor.objects.selection.update();
				return;
			}
			let newStartPosition = !isStartInPlh ? startPosition :
				editor.model.createPositionBefore( startPosition.parent );
			let newEndPosition = !isEndInPlh ? endPosition :
				editor.model.createPositionAfter( endPosition.parent );
			let range = editor.model.createRange( newStartPosition, newEndPosition );
			editor.model.change( writer => writer.setSelection( range ) );
			self.setPlaceholderSelected( ( startPosition.parent === endPosition.parent ) );
			editor.objects.selection.update();
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( this.changesAllowed ) return this.cleanup( true );
			// && this.valuesEditing.onKeydown( eventInfo, data, Number.MAX_SAFE_INTEGER );
			if ( areLiveSelectionRangesValid( editor ) ) return;
			if ( keyIs( data, "Backspace" ) || /*keyIs( data, "Delete" ) ||*/
				!setSelectionAfterNode( editor ) )
				stopEvent( eventInfo, data );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.editing.view.document.on( 'keyup', ( eventInfo, data ) => {
			if ( this.changesAllowed ) return this.cleanup( true ) &&
				this.valuesEditing.onKeyup( eventInfo, data );

			if ( !self.placeholderDeleted ||
				( !keyIs( data, "Backspace" ) && !keyIs( data, "Delete" ) ) ) return;
			// make sure that, in the case of multiline placeholder lines, the whole
			// line and the attributes of placeholder lines/containers are also removed
			self.setPlaceholderDeleted( false );
			let selection = editor.objects.selection.lastInModel.isCollapsed ?
				editor.objects.selection.lastInModel :
				editor.model.document.selection.isCollapsed ?
				editor.model.document.selection : null;
			if ( !selection ) return;
			let parent = selection.anchor && selection.anchor.parent ?
				selection.anchor.parent : selection.focus && selection.focus.parent ?
				selection.focus.parent : null;
			if ( !parent || !parent._attrs || parent._attrs.size < 1 ||
				( !parent._attrs.get( "isPlaceholderContainer" ) &&
					!parent._attrs.get( "placeholderName" ) ) ) return;
			let undo = editor.commands.get( "undo" );
			let lastBatch = undo && undo._stack && undo._stack.length > 0 ?
				undo._stack.slice( -1 )[ 0 ].batch : editor.model.createBatch();
			editor.model.enqueueChange( lastBatch, writer => {
				writer.remove( parent );
			} );
			// removeLastUndoSteps( editor, 2 );
		}, { priority: 0 } );

		// if we are inside a placeholder, prevent default & stop propagation
		// unless it's like F2 or navigation keys
		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( this.changesAllowed ) return this.cleanup( true );
			// && this.valuesEditing.onKeydown( eventInfo, data, 5 );
			// if it is a navigation key or F2, let the event run normally
			if ( isAllowedKey( data ) ) return;
			if ( self.mouseIsDown ) {
				stopEvent( eventInfo, data );
				return;
			}
			// if a placeholder was previously selected with "Backspace" or "Delete",
			// delete it on a repetitive click on one of those (let the event propagate)
			// and reset the "placeholderSelected" state
			if ( self.placeholderSelected &&
				( keyIs( data, "Backspace" ) || keyIs( data, "Delete" ) ) )
				return cleanup( null, false, true, false );
			// handle Enter
			if ( keyIs( data, "Enter" ) &&
				self._handleEnter( eventInfo, data ) ) return cleanup();
			// handle Space
			if ( ( keyIs( data, "Space" ) || keyIs( data, " " ) ) &&
				self._handleOneCharKey( eventInfo, data, " " ) ) return cleanup();
			// handle Backspace
			if ( keyIs( data, "Backspace" ) &&
				self._handleBackspace( eventInfo, data ) ) return self.setSelectAll( false );
			// handle Delete
			if ( keyIs( data, "Delete" ) &&
				self._handleDelete( eventInfo, data ) ) return;
			// handle Tab
			if ( keyIs( data, "Tab" ) &&
				self._handleTab( eventInfo, data ) ) return cleanup();
			// // handle Arrow left
			// if ( keyIs( data, "ArrowLeft" ) &&
			// 	self._handleArrowLeft( eventInfo, data ) ) {
			// 	editor.objects.selection.update();
			// 	return;
			// }
			// // handle Arrow right
			// if ( keyIs( data, "ArrowRight" ) &&
			// 	self._handleArrowRight( eventInfo, data ) ) {
			// 	editor.objects.selection.update();
			// 	return;
			// }

			// handle any printable key (letters, numbers, commas, points, etc.)
			// if ( data && data.domEvent && typeof data.domEvent.key == "string" &&
			// 	data.domEvent.key.length == 1 &&
			// 	self._handleOneCharKey( eventInfo, data, data.domEvent.key ) ) return;
			if ( data && data.domEvent && typeof data.domEvent.key == "string" &&
				data.domEvent.key.length == 1 ) {
				if ( data.domEvent.ctrlKey ) {
					// ctrl + z is the equivalent to "undo"
					if ( keyIs( data, "z" ) ) return cleanup();
					if ( keyIs( data, "a" ) ) return self.setSelectAll( true );
					if ( !isInsidePlaceholder( editor ) ) {
						if ( keyIs( data, "x" ) ) return cleanup();
						if ( keyIs( data, "c" ) )
							return cleanup( null, false, false, self.selectAll );
					}
					if ( keyIs( data, "v" ) && self._handlePaste() ) return cleanup();
				}
				if ( self._handleOneCharKey( eventInfo, data, data.domEvent.key ) ) return;
				self.setSelectAll( false );
			}
			// if we are outside of a placeholder, reset the "placeholderSelected" state
			// TODO reset should be done after selectionChangeDone aswell
			if ( !isInsidePlaceholder( editor ) )
				return cleanup( null, false, false, self.selectAll );
			stopEvent( eventInfo, data );
		}, { priority: "highest" } );

		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( this.changesAllowed ) return this.cleanup( true ) &&
				this.valuesEditing.onKeydown( eventInfo, data, Infinity );

			// handle Arrow left
			if ( keyIs( data, "ArrowLeft" ) &&
				self._handleArrowLeft( eventInfo, data ) ) {
				editor.objects.selection.update();
				return;
			}
			// handle Arrow right
			if ( keyIs( data, "ArrowRight" ) &&
				self._handleArrowRight( eventInfo, data ) ) {
				editor.objects.selection.update();
				return;
			}
		}, { priority: Infinity } );
		// }, { priority: Number.POSITIVE_INFINITY } );
		// }, { priority: Number.MAX_VALUE } );

		// let handleArrows = ( keyboardEvent ) => {
		// 	console.log( "dom keydown" );
		// }
		// onDomEvent( editor, "keydown", handleArrows );

		let commandNames = [ "alignment", "blockQuote", "numberedList", "bulletedList",
			"indentList", "outdentList", "insertTable", "indent", "pisaInsertImage",
			"pisaSpacing", "pisaSubscript", "pisaSuperscript", "pisaStartList",
			"pisaContinueList", "pisaObject"
		];

		commandNames.forEach( commandName => {
			let command = editor.commands.get( commandName );
			command && command.on( "execute", ( eventInfo ) => {
				if ( !isInsidePlaceholder( editor ) ) return;
				eventInfo.stop();
			}, { priority: "highest" } );
		} );

		// editor.editing.view.document.on( 'selectionChangeDone', () => {
		// 	if ( !isInsidePlaceholder( editor ) ) return;
		// 	STYLING_COMMANDS.forEach( command => disableButtonsCommand._disable( command ) );
		// } );

	}

	get changesAllowed() {
		return !!this.editingEnabled && !this.titlesShown;
	}

	get titlesShown() {
		return this.editor.objects.placeholders.titlesShown;
		// return Validator.isObjectPath( this, "this.editor.objects.placeholders" ) ?
		// this.editor.objects.placeholders.titlesShown : false;
	}

	_handleBackspace( eventInfo, data ) {
		const editor = this.editor;
		let position = editor.objects.selection.getLastPosition();

		// if we are at the end of a placeholder element, find/get the respective
		// placeholder element, set the selection on it and stop the event propagation
		// as well as further position check inside keydown handler
		if ( isAtPlaceholderEnd( position ) )
			return this._handlePositionAtPlhMargin( position, eventInfo, data, true );

		// if we are inside a multiline placeholder element, find/get the respective
		// placeholder element, set the selection on it and stop the event propagation
		// as well as further position check inside keydown handler
		if ( isPositionInMultilinePlaceholder( position ) )
			return this._handlePositionInMultilinePlh( position, eventInfo, data );

		// if we are inside a inline placeholder element, stop the event propagation
		// as well as further position check inside keydown handler
		if ( !isNextCharacterInPlaceholder( position ) ) return false;
		stopEvent( eventInfo, data );
		return true;
	}

	_handleDelete( eventInfo, data ) {
		const editor = this.editor;
		let position = editor.objects.selection.getLastPosition();

		// if the whole document is selected, let delete be handled as per usual
		// and stop the event propagation as well as further position check inside
		// keydown handler
		if ( this.selectAll || editor.objects.position.fullDocumentSelected )
			return this.cleanup( true );

		// if we are at the start of a placeholder element, find/get the respective
		// placeholder element, set the selection on it and stop the event propagation
		// as well as further position check inside keydown handler
		if ( isAtPlaceholderStart( position ) )
			return this._handlePositionAtPlhMargin( position, eventInfo, data, false );

		// if we are inside a multiline placeholder element, find/get the respective
		// placeholder element, set the selection on it and stop the event propagation
		// as well as further position check inside keydown handler
		if ( isPositionInMultilinePlaceholder( position ) )
			return this._handlePositionInMultilinePlh( position, eventInfo, data );

		// if we are inside a inline placeholder element, stop the event propagation
		// as well as further position check inside keydown handler
		if ( !isCharOnRightInPlaceholder( position ) ) return false;
		stopEvent( eventInfo, data );
		return true;
	}

	_handleEnter( eventInfo, data ) {
		const editor = this.editor;
		let endPosition = editor.objects.selection.getLastPosition( true, false );
		let startPosition = editor.objects.selection.getLastPosition( true, true );
		let end = isAtPlaceholderEnd( endPosition );
		let start = isAtPlaceholderStart( startPosition );
		let placeholder = end ? getPlaceholderAt( endPosition, true ) : start ?
			getPlaceholderAt( startPosition, false ) : null;
		if ( !placeholder || ( placeholder.name != "pisaPlaceholder" &&
				placeholder.name != "paragraph" ) ) return false;
		if ( placeholder.name == "paragraph" &&
			this._handleEnterInPlhLine( placeholder, eventInfo, data ) ) return true;
		if ( placeholder.name != "pisaPlaceholder" ) return false;
		if ( !isPlaceholderInContainer( placeholder ) ) {
			let where = end ? "after" : "before";
			// if last child in paragraph/first child in paragraph
			editor.model.change( writer => writer.setSelection( placeholder, where ) );
			return true;
		}
		if ( this._handleEnterInPlhLine( placeholder.parent, eventInfo, data, start ) ) return true;
		return false;
	}

	_handleOneCharKey( eventInfo, data, key ) {
		const editor = this.editor;
		let position = editor.objects.selection.getLastPosition();
		let end = isAtPlaceholderEnd( position );
		let start = isAtPlaceholderStart( position );
		let startPlaceholder = start ? getPlaceholderAt( position, false ) : null;
		let endPlaceholder = end ? getPlaceholderAt( position, true ) : null;
		// if both start and end are true, most likely we are between two placeholders
		if ( start && end && !!startPlaceholder && !!endPlaceholder &&
			typeof startPlaceholder == "object" && typeof endPlaceholder == "object" &&
			startPlaceholder.name == "pisaPlaceholder" &&
			endPlaceholder.name == "pisaPlaceholder" &&
			startPlaceholder != endPlaceholder ) {
			let attributes = getTextChildAttributes( endPlaceholder ) ||
				getTextChildAttributes( startPlaceholder, 0 ) ||
				getSiblingAttributes( endPlaceholder ) ||
				getSiblingAttributes( startPlaceholder, false ) || {};
			editor.model.change( writer => {
				const text = writer.createText( key, attributes );
				writer.insert( text, endPlaceholder, "after" );
				let newPosition = writer.createPositionAt( endPlaceholder.parent,
					endPlaceholder.startOffset + endPlaceholder.offsetSize + 1 );
				writer.setSelection( newPosition );
			} );
			stopEvent( eventInfo, data );
			return true;
		}
		let placeholder = endPlaceholder || startPlaceholder || null;
		if ( !placeholder || placeholder.name != "pisaPlaceholder" ||
			isPlaceholderInContainer( placeholder ) ) return false;
		if ( start ) {
			editor.model.change( writer => writer.setSelection( placeholder, "before" ) );
			return true;
		}
		let attributes = placeholder.nextSibling &&
			placeholder.nextSibling.name != "pisaPlaceholder" &&
			typeof placeholder.nextSibling._data == "string" ?
			placeholder.nextSibling._attrs : {};
		editor.model.change( writer => {
			const text = writer.createText( key, attributes );
			writer.insert( text, placeholder, "after" );
			if ( !placeholder.parent || typeof placeholder.startOffset != "number" ||
				typeof placeholder.offsetSize != "number" ) return;
			let newPosition = writer.createPositionAt( placeholder.parent,
				placeholder.startOffset + placeholder.offsetSize + 1 );
			writer.setSelection( newPosition );
		} );
		stopEvent( eventInfo, data );
		return true;
	}

	_handleTab( eventInfo, data ) {
		if ( !this._moveOutsideOfPlh() ) return false;
		insertTabSpace( this.editor, false );
		stopEvent( eventInfo, data );
		return true;
	}

	_handleArrowLeft( eventInfo, data ) {
		const editor = this.editor;
		editor.objects.selection.update();
		let startPosition = editor.objects.selection.getLastPosition( true, true );
		if ( !isAtPlaceholderEnd( startPosition ) && !isPositionInPlaceholder( startPosition ) ) return true;
		if ( isPositionInMultilinePlaceholder( startPosition ) && shiftPressed( data ) ) {
			this._handlePositionInMultilinePlh( startPosition );
			this.setPlaceholderSelected( true );
			editor.objects.selection.update();
			stopEvent( eventInfo, data );
			return true;
		}
		let placeholder = startPosition.parent && typeof startPosition.parent == "object" &&
			startPosition.parent.name == "pisaPlaceholder" ?
			startPosition.parent : getPlaceholderAt( startPosition, true );
		if ( !placeholder ) return false;
		// if ( data && typeof data == "object" && data.domEvent &&
		// 	typeof data.domEvent == "object" ) {
		// 	data.domEvent.preventDefault();
		// 	data.domEvent.stopPropagation();
		// }
		stopEvent( eventInfo, data );
		let newStartPosition = editor.model.createPositionBefore( placeholder );
		if ( !shiftPressed( data ) ) {
			editor.model.change( writer => writer.setSelection( newStartPosition ) );
			editor.objects.selection.update();
			return true;
		}
		let endPosition = editor.objects.selection.getLastPosition( true, false );
		let range = editor.model.createRange( newStartPosition, endPosition );
		editor.model.change( writer => writer.setSelection( range ) );
		this.setPlaceholderSelected( true );
		editor.objects.selection.update();
		return true;
	}

	_handleArrowRight( eventInfo, data ) {
		const editor = this.editor;
		editor.objects.selection.update();
		let endPosition = editor.objects.selection.getLastPosition( true, false );
		if ( !isAtPlaceholderStart( endPosition ) && !isPositionInPlaceholder( endPosition ) ) return true;
		if ( isPositionInMultilinePlaceholder( endPosition ) && shiftPressed( data ) ) {
			this._handlePositionInMultilinePlh( endPosition );
			this.setPlaceholderSelected( true );
			editor.objects.selection.update();
			stopEvent( eventInfo, data );
			return true;
		}
		let placeholder = endPosition.parent && typeof endPosition.parent == "object" &&
			endPosition.parent.name == "pisaPlaceholder" ?
			endPosition.parent : getPlaceholderAt( endPosition, false );
		if ( !placeholder ) return false;
		// if ( data && typeof data == "object" && data.domEvent &&
		// 	typeof data.domEvent == "object" ) {
		// 	data.domEvent.preventDefault();
		// 	data.domEvent.stopPropagation();
		// }
		stopEvent( eventInfo, data );
		let newEndPosition = editor.model.createPositionAfter( placeholder );
		if ( !shiftPressed( data ) ) {
			editor.model.change( writer => writer.setSelection( newEndPosition ) );
			editor.objects.selection.update();
			return true;
		}
		let startPosition = editor.objects.selection.getLastPosition( true, true );
		let range = editor.model.createRange( startPosition, newEndPosition );
		editor.model.change( writer => writer.setSelection( range ) );
		this.setPlaceholderSelected( true );
		editor.objects.selection.update();
		return true;
	}

	_handlePaste() {
		return this._moveOutsideOfPlh();
	}

	_moveOutsideOfPlh() {
		const editor = this.editor;
		let position = editor.objects.selection.getLastPosition();
		let end = isAtPlaceholderEnd( position );
		let start = isAtPlaceholderStart( position );
		let placeholder = end ? getPlaceholderAt( position, true ) : start ?
			getPlaceholderAt( position, false ) : null;
		if ( !placeholder || placeholder.name != "pisaPlaceholder" ||
			isPlaceholderInContainer( placeholder ) ) return false;
		editor.model.change( writer => writer.setSelection( placeholder, end ? "after" : "before" ) );
		return true;
	}

	_handlePositionAtPlhMargin( position, eventInfo, data, atEnd = false ) {
		let placeholder = getPlaceholderAt( position, atEnd );
		if ( !placeholder ) return false;
		return this._selectAllPlhLines( placeholder, eventInfo, data );
	}

	_handlePositionInMultilinePlh( position, eventInfo, data ) {
		let element = position.parent;
		while ( element.parent && element.name != "paragraph" ) {
			element = element.parent;
		}
		return this._selectAllPlhLines( element, eventInfo, data );
	}

	_selectAllPlhLines( placeholder, eventInfo, data ) {
		const editor = this.editor;
		let firstLine = getPlaceholderBorderLine( placeholder, true );
		if ( !firstLine && this._setSelection( placeholder, eventInfo, data ) ) return true;
		let lastLine = getPlaceholderBorderLine( placeholder, false );
		if ( !lastLine && this._setSelection( placeholder, eventInfo, data ) ) return true;
		let range = editor.model.change( writer => {
			let startPosition = writer.createPositionBefore( firstLine );
			let endPosition = writer.createPositionAfter( lastLine );
			return writer.createRange( startPosition, endPosition );
		} );
		if ( !range && this._setSelection( placeholder, eventInfo, data ) ) return true;
		this._setSelection( range, eventInfo, data );
		return true;
	}

	_setSelection( rangeOrElement, eventInfo, data ) {
		setSelectionOn( this.editor, rangeOrElement );
		this.setPlaceholderSelected( true );
		if ( !!data ) stopEvent( eventInfo, data );
		return true;
	}

	_handleEnterInPlhLine( placeholderLine, eventInfo, data, startOfLine = false ) {
		const editor = this.editor;
		if ( editor.objects.placeholders.titlesShown )
			return this._enterAfterLine( placeholderLine, eventInfo, data, startOfLine );
		if ( isBorderLine( placeholderLine, false ) )
			return this._enterAfterLine( placeholderLine, eventInfo, data );
		if ( isBorderLine( placeholderLine, true ) )
			return this._enterAfterLine( placeholderLine, eventInfo, data, true );
	}

	_enterAfterLine( line, eventInfo, data, before = false ) {
		const editor = this.editor;
		editor.model.change( writer => {
			let position = before ?
				writer.createPositionBefore( line ) :
				writer.createPositionAfter( line );
			let paragraph = writer.createElement( "paragraph" );
			writer.insert( paragraph, position, 0 );
			writer.setSelection( paragraph, "in" );
		} );
		this.setPlaceholderSelected( false );
		stopEvent( eventInfo, data );
		return true;
	}

}

export function setSelectionAfterNode( editor ) {
	let couldSet = false;
	let selection = editor.model.document.selection;
	if ( !selection ) return couldSet;
	let anchor = selection.anchor;
	let focus = selection.focus;
	if ( !focus || !( focus.path instanceof Array ) || focus.path.length <= 0 ||
		!anchor || !( anchor.path instanceof Array ) || anchor.path.length <= 0 )
		return couldSet;
	let root = editor.model.document.getRoot();
	let reducedPath = [ ...focus.path ];
	reducedPath.pop();
	let node = getModelElementByPath( root, reducedPath );
	if ( !node ) return couldSet;
	let start = anchor.path[ anchor.path.length - 1 ];
	let end = focus.path[ focus.path.length - 1 ];
	if ( start != 0 || end != node.maxOffset ) return couldSet;
	try {
		editor.model.change( writer => writer.setSelection( node, "after" ) );
		couldSet = true;
	} catch ( e ) {
		couldSet = false;
	}
	return couldSet;
}

function isPlaceholderInContainer( placeholder ) {
	return placeholder.parent && placeholder.parent.name == "paragraph" &&
		typeof placeholder.parent._attrs == "object" &&
		( placeholder.parent._attrs.get( "isPlaceholderContainer" ) == true ||
			placeholder.parent._attrs.get( "isPlaceholderContainer" ) == "true" );
}

function getPlaceholderBorderLine( currentLine, firstLine = false ) {
	let groupIndex = typeof currentLine._attrs == "object" ?
		( currentLine._attrs.get( "groupIndex" ) || 0 ) : 0;
	let sibling = firstLine ? "previousSibling" : "nextSibling";
	let borderLine = currentLine;
	while ( borderLine[ sibling ] && borderLine[ sibling ].name == "paragraph" &&
		typeof borderLine[ sibling ]._attrs == "object" &&
		typeof borderLine[ sibling ]._attrs.get( "placeholderName" ) == "string" &&
		borderLine[ sibling ]._attrs.get( "groupIndex" ) == groupIndex ) {
		borderLine = borderLine[ sibling ];
	}
	return borderLine;
}

function isBorderLine( line, firstLine = false ) {
	let currentIndex = line._attrs.get( "groupIndex" );
	let sibling = firstLine ? "previousSibling" : "nextSibling";
	return !line[ sibling ] || line[ sibling ].name != "paragraph" ||
		typeof line[ sibling ]._attrs != "object" ||
		!line[ sibling ]._attrs.get( "placeholderName" ) ||
		line[ sibling ]._attrs.get( "groupIndex" ) != currentIndex;
}

function setSelectionOn( editor, rangeOrElement ) {
	editor.model.change( writer => {
		writer.setSelection( rangeOrElement, "on" );
	} );
	editor.objects.selection.update();
}

export function keyIs( data, keyName ) {
	return data && data.domEvent && ( data.domEvent.key == keyName ||
		data.domEvent.code == keyName );
}

function shiftPressed( data ) {
	return data && typeof data == "object" && ( ( data.domEvent &&
			typeof data.domEvent == "object" &&
			( data.domEvent.shiftKey == true || data.domEvent.shiftKey == "true" ) ) ||
		( data.shiftKey == true || data.shiftKey == "true" ) );
}

function getPlaceholderAt( position, before = false ) {
	let nodeAt = before ? "nodeBefore" : "nodeAfter";
	return position.parent && ( position.parent.name == "pisaPlaceholder" ||
			( position.parent.name == "paragraph" && position.parent._attrs &&
				typeof position.parent._attrs.get( "placeholderName" ) == "string" ) ) ?
		position.parent : ( position[ nodeAt ] &&
			position[ nodeAt ].name == "pisaPlaceholder" ? position[ nodeAt ] : null );
}

function isAllowedKey( data ) {
	if ( !data || !data.domEvent || ( !data.domEvent.key && !data.domEvent.code ) ) return false;
	let key = data.domEvent.key || data.domEvent.code;
	for ( let allowedKey of ALLOWED_KEYS ) {
		if ( key == allowedKey ) return true;
	}
	return false;
}

export function createArrowView( executable = true ) {
	const arrowView = new IconView();
	arrowView.content = dropdownArrowIcon;
	arrowView.extendTemplate( {
		attributes: {
			class: 'ck-dropdown__arrow'
		}
	} );
	executable ? bindClickToExecute( arrowView ) : void 0;
	return arrowView;
}

export function isInsidePlaceholder( editor ) {
	let startPosition = editor.objects && editor.objects.selection ?
		editor.objects.selection.getLastPosition() :
		( editor.model.document.selection.anchor ||
			editor.model.document.selection._ranges[ 0 ].start );
	if ( isPositionInPlaceholder( startPosition ) ) return true;
	let endPosition = editor.objects && editor.objects.selection ?
		editor.objects.selection.getLastPosition( true, false ) :
		( editor.model.document.selection.focus ||
			editor.model.document.selection._ranges[ 0 ].end );
	if ( isPositionInPlaceholder( endPosition ) ) return true;
	return false;
}

export function isAtPlaceholderMargin( editor ) {
	let startPosition = editor.objects && editor.objects.selection ?
		editor.objects.selection.getLastPosition() :
		( editor.model.document.selection.anchor ||
			editor.model.document.selection._ranges[ 0 ].start );
	if ( isAtPlaceholderStart( startPosition ) ) return true;
	if ( isAtPlaceholderEnd( startPosition ) ) return true;
	let endPosition = editor.objects && editor.objects.selection ?
		editor.objects.selection.getLastPosition( true, false ) :
		( editor.model.document.selection.focus ||
			editor.model.document.selection._ranges[ 0 ].end );
	if ( isAtPlaceholderStart( endPosition ) ) return true;
	if ( isAtPlaceholderEnd( endPosition ) ) return true;
	return false;
}

function isAtPlaceholderStart( position ) {
	if ( !isValidModelPosition( position ) ) return false;
	if ( position.nodeAfter && position.nodeAfter.name == "pisaPlaceholder" ) return true;
	if ( !position.parent || ( position.parent.name != "pisaPlaceholder" &&
			( !position.parent._attrs || typeof position.parent._attrs.get( "placeholderName" ) != "string" )
		) ) return false;
	return position.path && position.path.length > 0 && position.path[ position.path.length - 1 ] == 0;
}

function isAtPlaceholderEnd( position ) {
	if ( !isValidModelPosition( position ) ) return false;
	if ( position.nodeBefore && position.nodeBefore.name == "pisaPlaceholder" ) return true;
	if ( !position.parent || ( position.parent.name != "pisaPlaceholder" &&
			( !position.parent._attrs || typeof position.parent._attrs.get( "placeholderName" ) != "string" )
		) ) return false;
	return position.path && position.path.length > 0 &&
		position.path[ position.path.length - 1 ] == position.parent.maxOffset;
}

export function isPositionInPlaceholder( position ) {
	// TODO what if position is right between two placeholder containers (divs)
	if ( !isValidModelPosition( position ) ) return false;
	return isPositionInInlinePlaceholder( position ) ||
		isPositionInMultilinePlaceholder( position );
}

function isPositionInInlinePlaceholder( position ) {
	return position && position.parent && isInlinePlaceholder( position.parent );
}

export function isInlinePlaceholder( element, isParent = false ) {
	if ( !element ) return false;
	if ( element.name == "pisaPlaceholder" ) return true;
	if ( !isParent && element.parent && isInlinePlaceholder( element.parent, true ) ) return true;
	if ( !element._attrs || element._attrs.size <= 0 ) return false;
	if ( typeof element._attrs.get( "data-value" ) == "string" ) return true;
	return false;
}

export function isPositionInMultilinePlaceholder( position ) {
	if ( !position ) return false;
	let element = null;
	try {
		element = position.parent;
	} catch ( err ) {
		console.warn( "Couldn't establish if position is inside a multiline " +
			"placeholder. Unable to get position parent." );
	}
	if ( !element ) return false;
	while ( element.parent && element.name != "paragraph" ) {
		element = element.parent;
	}
	return isMultilinePlaceholder( element );
}

export function isMultilinePlaceholder( element ) {
	if ( !element ) return false;
	if ( element.name != "paragraph" ) return false;
	if ( !element._attrs || element._attrs.size <= 0 ) return false;
	if ( element._attrs.get( "isPlaceholderContainer" ) == true ) return true;
	if ( element._attrs.get( "contentEditable" ) == false ) return true;
	if ( typeof element._attrs.get( "placeholderName" ) == "string" ) return true;
	if ( typeof element._attrs.get( "placeholderValue" ) == "string" ) return true;
	if ( typeof element._attrs.get( "placeholderLineNumber" ) == "number" ) return true;
	if ( typeof element._attrs.get( "placeholderLineNumber" ) == "string" ) return true;
	if ( typeof element._attrs.get( "placeholderChildIndex" ) == "number" ) return true;
	if ( typeof element._attrs.get( "placeholderChildIndex" ) == "string" ) return true;
	return false;
}

export function getPlaceholderLineParent( element ) {
	if ( !element ) return;
	while ( element.parent && element.name != "paragraph" ) {
		element = element.parent;
	}
	return isMultilinePlaceholder( element ) ? element : null;
}

function isNextCharacterInPlaceholder( position ) {
	if ( !isValidModelPosition( position ) ) return false;
	let index = position.index;
	// the index should be > 0, but should the offset?
	if ( typeof index != "number" || index <= 0 || typeof position.offset != "number" ||
		position.offset <= 0 || typeof position.parent != "object" ) return false;
	if ( position.nodeBefore && position.nodeBefore.name == "pisaPlaceholder" ) return true;
	if ( !position.parent._children || !position.parent._children._nodes ||
		position.parent._children._nodes.length < index + 1 ) return false;
	let children = [ ...position.parent._children._nodes ];
	if ( children[ index - 1 ].name == "pisaPlaceholder" &&
		children[ index ].startOffset >= position.offset ) return true;
	return false;
}

function isCharOnRightInPlaceholder( position ) {
	if ( !isValidModelPosition( position ) ) return false;
	if ( position.nodeAfter && position.nodeAfter.name == "pisaPlaceholder" ) return true;
	let index = position.index;
	if ( typeof index != "number" || index < 0 || typeof position.offset != "number" ||
		position.offset < 0 || typeof position.parent != "object" ) return false;
	if ( !position.parent._children || !position.parent._children._nodes ||
		position.parent._children._nodes.length < index + 2 ) return false; // +2 bc nodeAfter
	let children = [ ...position.parent._children._nodes ];
	if ( children[ index + 1 ].name == "pisaPlaceholder" &&
		children[ index ].endOffset <= position.offset ) return true;
	return false;
}

function getTextChildAttributes( parentElement, childIndex ) {
	if ( !parentElement || typeof parentElement != "object" ||
		typeof parentElement._children != "object" ||
		!( parentElement._children._nodes instanceof Array ) ||
		parentElement._children._nodes.length < 1 ) return void 0;
	if ( typeof childIndex != "number" ||
		childIndex > parentElement._children._nodes.length - 1 )
		childIndex = parentElement._children._nodes.length - 1;
	let textChild = parentElement._children._nodes[ childIndex ];
	if ( !textChild || typeof textChild != "object" ||
		typeof textChild._data != "string" || !( textChild._attrs instanceof Map ) ||
		textChild._attrs.size < 1 ) return void 0;
	return textChild._attrs;
}

function getSiblingAttributes( currentElement, previousSibling = true ) {
	if ( !currentElement || typeof currentElement != "object" ) return void 0;
	let sibling = !!previousSibling ? "previousSibling" : "nextSibling";
	if ( !currentElement[ sibling ] || typeof currentElement[ sibling ] != "object" ||
		currentElement[ sibling ].name == "pisaPlaceholder" ||
		typeof currentElement[ sibling ]._data != "string" ||
		!( currentElement[ sibling ]._attrs instanceof Map ) ||
		currentElement[ sibling ]._attrs.size < 1 ) return void 0;
	return currentElement[ sibling ]._attrs;
}

export function getAllPlaceholderLines( currentLine ) {
	let groupIndex = getGroupIndex( currentLine );
	let lines = [ currentLine ];
	let previousLine = currentLine;
	while ( isMultilinePlaceholder( previousLine.previousSibling ) &&
		getGroupIndex( previousLine.previousSibling ) == groupIndex ) {
		previousLine = previousLine.previousSibling;
		lines.unshift( previousLine );
	}
	let nextLine = currentLine;
	while ( isMultilinePlaceholder( nextLine.nextSibling ) &&
		getGroupIndex( nextLine.nextSibling ) == groupIndex ) {
		nextLine = nextLine.nextSibling;
		lines.push( nextLine );
	}
	return lines;
}

function getGroupIndex( modelElement ) {
	return Validator.isObject( modelElement ) &&
		Validator.isMap( modelElement._attrs ) ?
		( modelElement._attrs.get( "groupIndex" ) || 0 ) : 0;
}
