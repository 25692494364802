import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';
import { getAllPlaceholderLines } from '../pisaplaceholderui';
// import { inlinePlaceholderToText } from '../placeholdertotextcommand';

export default class PlaceholderToTextTransformer extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

	}

	removeInlinePlaceholder( placeholder, batch = null, writer = null ) {
		this._log( "removeInlinePlaceholder" );
		if ( !Validator.isObject( placeholder ) ) return false;
		this.objectsPlaceholders.removeReference( placeholder );
		if ( Validator.isObject( writer ) ) {
			this.registerInlineBatch( batch, placeholder );
			this.inlinePlaceholderToText( writer, placeholder );
			return true;
		}
		if ( !Validator.isObject( batch ) ) batch = this.newBatch;
		this.registerInlineBatch( batch, placeholder );
		this.editor.model.enqueueChange( batch, writer => {
			this.inlinePlaceholderToText( writer, placeholder );
		} );
		return true;
	}

	inlinePlaceholderToText( writer, placeholder ) {
		if ( !Validator.isObject( placeholder ) ) return false;
		let selection = this.currentSelection;
		let earliestPath = this.cloneEarliestPositionPath();
		let newPath = void 0;
		if ( Validator.isArray( earliestPath ) && [ 3, 6 ].indexOf( earliestPath.length ) >= 0 &&
			earliestPath[ earliestPath.length - 1 ] == 0 &&
			Validator.isObject( selection ) && selection.isCollapsed ) {
			newPath = [ ...earliestPath ];
			newPath.pop();
		}
		let childNodes = Validator.isObjectPath( placeholder,
				"placeholder._children._nodes" ) &&
			Validator.isArray( placeholder._children._nodes ) ?
			placeholder._children._nodes : void 0;
		if ( !Validator.isObject( childNodes ) ) return false;
		let children = [ ...childNodes ];
		let positionBefore = writer.createPositionBefore( placeholder );
		if ( !this._positionExists( positionBefore ) ) return;
		let placeholderStyleAttributes = this
			.getInlinePlaceholderStyleAttributes( placeholder, true );
		for ( let i = children.length - 1; i >= 0; i-- ) {
			let child = children[ i ];
			[ ...placeholderStyleAttributes.entries() ].forEach( entry => {
				if ( !Validator.isMap( child._attrs ) ||
					!!child._attrs.get( entry[ 0 ] ) ) return;
				writer.setAttribute( entry[ 0 ], entry[ 1 ], child );
			} );
			writer.insert( child, positionBefore );
		}
		writer.remove( placeholder );
		if ( !Validator.isArray( newPath ) ) return true;
		let position = writer.createPositionFromPath(
			this.editor.model.document.getRoot(), newPath );
		if ( !this._positionExists( position ) ) return true;
		let range = writer.createRange( position );
		writer.setSelection( range );
		return true;
	}

	getInlinePlaceholderStyleAttributes( placeholder, asMap = false ) {
		let returnObject = !asMap ? {} : new Map();
		if ( !Validator.isObject( placeholder ) ) return returnObject;
		if ( !Validator.isMap( placeholder._attrs ) || placeholder._attrs.size < 1 )
			return returnObject;
		let attributesNames = [ ...placeholder._attrs.keys() ]
			.filter( attributeName => [ "spellcheck", "title", "data-value", "class" ]
				.indexOf( attributeName ) < 0 );
		if ( attributesNames.length < 1 ) return returnObject;
		if ( asMap ) {
			for ( let attributeName of attributesNames )
				returnObject.set( attributeName, placeholder._attrs.get( attributeName ) );
		} else {
			for ( let attributeName of attributesNames )
				returnObject[ attributeName ] = placeholder._attrs.get( attributeName );
		}
		return returnObject;
	}

	getGroupIndex( multilinePlaceholderLine, asNumber = false ) {
		if ( !Validator.isObject( multilinePlaceholderLine ) ||
			!Validator.isMap( multilinePlaceholderLine._attrs ) ) return void 0;
		let groupIndex = multilinePlaceholderLine._attrs.get( "groupIndex" );
		return asNumber ? ( Validator.isPositiveInteger( groupIndex ) ? groupIndex :
				Number( groupIndex ) ) : Validator.isString( groupIndex ) ? groupIndex :
			String( groupIndex );
	}

	getMultilinePlaceholderLines( randomLine ) {
		// return getAllPlaceholderLines( randomLine );
		if ( !this.isMultilinePlaceholder( randomLine ) ) return [];
		let lines = [ randomLine ];
		const groupIndex = this.getGroupIndex( randomLine );
		if ( !Validator.isString( groupIndex ) ) return lines;
		let previousLine = randomLine;
		while ( this.isMultilinePlaceholder( previousLine.previousSibling ) &&
			this.getGroupIndex( previousLine.previousSibling ) == groupIndex ) {
			previousLine = previousLine.previousSibling;
			lines.unshift( previousLine );
		}
		let nextLine = randomLine;
		while ( this.isMultilinePlaceholder( nextLine.nextSibling ) &&
			this.getGroupIndex( nextLine.nextSibling ) == groupIndex ) {
			nextLine = nextLine.nextSibling;
			lines.push( nextLine );
		}
		return lines;
	}

	removeMultilinePlaceholder( placeholderLine, batch = null, writer = null ) {
		if ( !this.isMultilinePlaceholder( placeholderLine ) ) return false;
		this.objectsPlaceholders.removeReference( placeholderLine );
		const groupIndex = placeholderLine._attrs.get( "groupIndex" );
		const totalNumberOfLines = placeholderLine._attrs.get( "placeholderLineNumber" );
		const placeholderLines = this.getMultilinePlaceholderLines( placeholderLine );
		if ( !Validator.isArray( placeholderLines ) )
			placeholderLines = [ placeholderLine ];
		if ( placeholderLines.length != totalNumberOfLines )
			this._log( `The quantity of found placeholder lines for the multiline` +
				` placeholder with the group index ${ groupIndex }` +
				` (${ placeholderLines.length }) does not match the value of the` +
				` attribute ${ "placeholderLineNumber" } (${totalNumberOfLines}).` );
		this.registerMultilineBatch( batch, placeholderLines );
		this.removeMultilinePlaceholderLines( placeholderLines, batch, writer );
	}

	removeMultilinePlaceholderLines( placeholderLines, batch = null, writer = null ) {
		if ( !Validator.isObject( placeholderLines ) ) return false;
		if ( !Validator.isArray( placeholderLines ) )
			placeholderLines = [ placeholderLines ];
		let success = true;
		for ( let multilinePlaceholderLine of placeholderLines )
			if ( !this.removeMultilinePlaceholderLine( multilinePlaceholderLine, batch, writer ) )
				success = false;
		return success;
	}

	removeMultilinePlaceholderLine( multilinePlaceholder, batch = null, writer = null ) {
		if ( !Validator.isObject( multilinePlaceholder ) ) return false;
		if ( Validator.isObject( writer ) ) {
			this.multilinePlaceholderLineToText( multilinePlaceholder, writer );
			return true;
		}
		if ( !Validator.isObject( batch ) ) batch = this.editor.model.createBatch();
		this.editor.model.enqueueChange( batch, writer => {
			this.multilinePlaceholderLineToText( multilinePlaceholder, writer );
		} );
		return true;
	}

	multilinePlaceholderLinesToText( placeholderLines, writer ) {
		if ( !Validator.isObject( placeholderLine ) || !Validator.isObject( writer ) ) return;
		if ( !Validator.isArray( placeholderLines ) ) placeholderLines = [ placeholderLines ];
		for ( let placeholderLine of placeholderLines )
			this.multilinePlaceholderLineToText( placeholderLine, writer );
	}

	multilinePlaceholderLineToText( placeholderLine, writer ) {
		if ( !Validator.isObject( placeholderLine ) || !Validator.isObject( writer ) ) return;
		[ "placeholderName", "placeholderValue", "placeholderLineNumber",
			"placeholderChildIndex", "groupIndex", "isPlaceholderContainer",
			"contentEditable"
		]
		.forEach( attribute =>
			writer.removeAttribute( attribute, placeholderLine ) );
	}

}

// const ATTRIBUTES_TO_MIRROR = [ "removeInlinePlaceholder",
// 	"inlinePlaceholderToText",
// 	"getInlinePlaceholderStyleAttributes"
// ];
