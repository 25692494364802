import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import BackgroundColorCommand from './pisabckgcommand';
import { backgroundIcon } from '../icons';
import { createCompactColorView } from '../pisafontcolor/pisacolorcompactui';

export const BACKGROUND_COLOR_COMPACT = "backgroundColorCompact";
const BACKGROUND_COLOR = "backgroundColor";

export default class BackgroundColorCompactUI extends Plugin {

	init() {
		const editor = this.editor;
		if ( !editor.commands.get( BACKGROUND_COLOR ) )
			editor.commands.add( BACKGROUND_COLOR, new BackgroundColorCommand( editor ) );
		editor.ui.componentFactory.add( BACKGROUND_COLOR_COMPACT, locale => {
			return createCompactColorView( editor, BACKGROUND_COLOR, backgroundIcon,
				editor.objects.tooltips.getT( BACKGROUND_COLOR_COMPACT ) );
		} );
	}

}
