import UnlinkCommand from '@ckeditor/ckeditor5-link/src/unlinkcommand';
import { onlyPisaImageSelected } from './pisaextendedlinkcommand';
import Validator from '../pisautils/validator';

export default class PisaExtendedUnlinkCommand extends UnlinkCommand {

	/**
	 * @inheritDoc
	 * @see {@link module:@ckeditor/ckeditor5-link/src/unlinkcommand} UnlinkCommand
	 * @override
	 * made sure when only an image is selected that is inside a link, the unlink
	 * command is enabled
	 */
	refresh() {
		if ( !this.onlyPisaImageSelected ) return super.refresh();
		const positionHelper = this.positionHelper;
		if ( !Validator.isObject( positionHelper ) ) return super.refresh();
		const currentSelection = this.editor.model.document.selection;
		const selectionBackward =
			positionHelper._isSelectionBackward( currentSelection );
		const startExtremity = selectionBackward ? "focus" : "anchor";
		const imageElement =
			Validator.isObjectPath( currentSelection,
				`currentSelection.${ startExtremity }.nodeAfter` ) &&
			currentSelection[ startExtremity ].nodeAfter.name == "pisaImage" ?
			currentSelection[ startExtremity ].nodeAfter : void 0;
		if ( !Validator.isObject( imageElement ) ||
			!Validator.isMap( imageElement._attrs ) ||
			!Validator.isString( imageElement._attrs.get( "linkHref" ) ) )
			return super.refresh();
		this.isEnabled = true;
	}

	get onlyPisaImageSelected() {
		return onlyPisaImageSelected( this.editor );
	}

	get positionHelper() {
		return Validator.isObjectPath( this, "this.editor.objects.position" ) ?
			this.editor.objects.position : void 0;
	}

}
