import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CopyFormatCommand from './pisacopyformatcommand';
import PisaEmptyParagraphEditing from './pisaemptyparagraphediting';
import { COPYFORMAT } from './pisacopyformatcommand';
import { setButton } from '../utils';
import { copyFormatIcon } from '../icons';

export default class CopyFormat extends Plugin {

	static get requires() {
		return [ PisaEmptyParagraphEditing ];
	}

	init() {
		const editor = this.editor;

		editor.commands.add( COPYFORMAT, new CopyFormatCommand( editor ) );

		editor.ui.componentFactory.add( COPYFORMAT, locale => {
			return setButton( copyFormatIcon,
				editor.objects.tooltips.getT( COPYFORMAT ), COPYFORMAT, "", editor );
		} );
	}
}
