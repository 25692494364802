import Validator from './validator';
// import Warner from './warner';

export default class StringHelper {

	/**
	 * removes all whitespaces characters from the beginning of a string; also
	 * "cleans" the string from non-printable ASCII characters
	 */
	static trimStart( inputParameter ) {
		if ( !Validator.isString( inputParameter ) ) return inputParameter;
		inputParameter = Validator.removeNonPrintableAsciiChars( inputParameter );
		return inputParameter.trimStart(); // does not clean non-printable characters
		// or
		return inputParameter.trimLeft(); // does not clean non-printable characters
		// or
		while ( inputParameter.length > 0 &&
			!!inputParameter.charAt( 0 ).match( /\s/gi ) )
			inputParameter = inputParameter.substring( 1 );
		return inputParameter;
	}

	/**
	 * removes all whitespaces characters from the end of a string; also
	 * "cleans" the string from non-printable ASCII characters
	 */
	static trimEnd( inputParameter ) {
		if ( !Validator.isString( inputParameter ) ) return inputParameter;
		inputParameter = Validator.removeNonPrintableAsciiChars( inputParameter );
		return inputParameter.trimEnd(); // does not clean non-printable characters
		// or
		return inputParameter.trimRight(); // does not clean non-printable characters
		// or
		while ( inputParameter.length > 0 &&
			!!inputParameter.charAt( inputParameter.length - 1 ).match( /\s/gi ) )
			inputParameter = inputParameter.substr( 0, inputParameter.length - 1 );
		return inputParameter;
	}

	/**
	 * "cleans" a string from non-printable ASCII characters
	 */
	static clean( inputParameter ) {
		if ( !Validator.isString( inputParameter ) ) return inputParameter;
		return Validator.removeNonPrintableAsciiChars( inputParameter );
	}

	static lengthToNumber( inputParameter ) {
		if ( !Validator.isString( inputParameter ) &&
			!Validator.isNumber( inputParameter ) ) return inputParameter;
		const numericValue = Number( inputParameter.toString().replace( /[^\d\,\.]+/, "" )
			.replace( /[\.\,]+/, "." ).replace( /[\.]+/, "." ) );
		return Validator.isValidNumber( numericValue ) ? numericValue : void 0;
	}

	static lengthToRoundNumber( inputParameter ) {
		const numericValue = StringHelper.lengthToNumber( inputParameter );
		return Validator.isNumber( numericValue ) ? Math.round( numericValue ) : void 0;
	}

	static roundUpNumericLength( inputParameter ) {
		const numericValue = StringHelper.lengthToRoundNumber( inputParameter );
		return Validator.isNumber( numericValue ) ? String( numericValue ) : void 0;
	}

}
