import Command from '@ckeditor/ckeditor5-core/src/command';
import { onDomEvent } from '../utils';

export const COPYFORMAT = 'pisaCopyFormat';

export default class CopyFormatCommand extends Command {

	constructor( editor ) {
		super( editor, COPYFORMAT );
		this.selectionChanged = false;
		this.values = {};
		let panel = editor.plugins._plugins.get( 'PisaPanelBalloons' );

		onDomEvent( editor, "mouseup", () => {
			if ( !this.selectionChanged ) return;
			changeSelection( editor, this.values );
			if ( panel && typeof panel == "object" ) panel.hideAll();
			this.selectionChanged = false;
		} );
	}

	execute( options = {} ) {
		const editor = this.editor;
		this.values = this._getAttributeValues() || {};

		editor.editing.view.document.once( "selectionChangeDone", ( eventInfo, data ) => {
			this.selectionChanged = true;
		} );
	}

	_getAttributeValues() {
		let attributeValues = {};
		const editor = this.editor;
		const attributes = [ "fontColor", "fontFamily", "fontSize", "bold", "italic",
			"underline", "strikethrough", "alignment", "link",
			"backgroundColor", "blockQuote", "numberedList", "bulletedList",
			"pisaSpacing", "pisaSpellcheck", "code", "pisaSubscript", "pisaSuperscript"
		];

		for ( let attribute of attributes ) {
			let command = editor.commands.get( attribute );
			if ( !command || typeof command != "object" ) continue;
			attributeValues[ attribute ] = command.value;
		}
		return attributeValues;
	}

}

export function changeSelection( editor, attributeValues ) {

	const selection = editor.model.document.selection;

	for ( let command in attributeValues ) {
		if ( typeof attributeValues[ command ] != "boolean" ) continue;
		let commandObject = editor.commands.get( command );
		if ( !commandObject || typeof commandObject != "object" ) continue;
		if ( commandObject.value != attributeValues[ command ] ) {
			editor.executeIf( command );
		}
	}
	editor.model.change( writer => {
		for ( let attribute in attributeValues ) {
			if ( typeof attributeValues[ attribute ] != "boolean" && attributeValues[ attribute ] ) {
				const ranges = editor.model.schema.getValidRanges( selection.getRanges(), attribute );
				for ( const range of ranges ) {
					writer.setAttribute( attribute, attributeValues[ attribute ], range );
				}
			} else if ( attributeValues[ attribute ] == undefined ) {
				const ranges = editor.model.schema.getValidRanges( selection.getRanges(), attribute );
				for ( const range of ranges ) {
					writer.removeAttribute( attribute, range );
				}
			}
		}
	} );
}
