import Validator from '../pisautils/validator';
import EventHelper from '../pisautils/eventhelper';
import AttachmentObject from '../pisautils/attachmentobject';
import KeyupFocusManager from '../pisaselection/keyupfocusmanager';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import { FONTS } from './pisafontfamilyui';
import { UP_KEYWORDS, DOWN_KEYWORDS } from '../pisaselection/keyupfocusmanager';

const SPACE_KEYS = [ " ", "Space" ];
const SPECIAL_CHARACTERS = [ "-", ";" ];

export default class FontFamilyFocusManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		new KeyupFocusManager( hostPlugin );
	}

	onCompactKeyUp( dropdownView, evt ) {
		if ( !Validator.isObject( dropdownView ) ||
			!( evt instanceof KeyboardEvent ) || evt.ctrlKey ) return false;
		if ( this.keyIs( evt, "Enter" ) )
			return this.processCompactEnter( dropdownView, evt );
		if ( UP_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowUp( dropdownView, evt );
		if ( DOWN_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowDown( dropdownView, evt );
		if ( this.keyIs( evt, "Escape" ) )
			return this.onEscape( dropdownView, evt );
		if ( !Validator.isPrintableKey( evt.key ) ) return false;
		if ( Validator.isStringLetter( evt.key ) ||
			Validator.isStringNumber( evt.key ) )
			return this.processCompactKeyUp( dropdownView, evt );
	}

	onKeyUp( dropdownView, evt ) {
		if ( this.keyIs( evt, "Escape" ) )
			return this.onEscape( dropdownView, evt );
		if ( !this.isValidDropdownAndEvent( dropdownView, evt ) ) {
			this.focusIfChildIsFocused( dropdownView );
			return false;
		}
		if ( UP_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowUp( dropdownView, evt );
		if ( DOWN_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowDown( dropdownView, evt );
		let currentValue = dropdownView.isBeingEdited ?
			dropdownView.buttonView.label : "";
		if ( this.keyIs( evt, "Enter" ) )
			return this.onEnter( currentValue, dropdownView, evt );
		if ( this.keyIs( evt, "Backspace" ) )
			return this.onBackspace( currentValue, dropdownView, evt );
		if ( SPACE_KEYS.indexOf( evt.key ) >= 0 || SPACE_KEYS.indexOf( evt.code ) >= 0 )
			return this.onSpace( currentValue, dropdownView, evt );
		if ( SPECIAL_CHARACTERS.indexOf( evt.key ) >= 0 )
			return this.onSpecialSign( currentValue, dropdownView, evt, evt.key );
		// if ( this.keyIs( evt, "Delete" ) )
		// 	return this.onDelete( dropdownView, evt );
		if ( !Validator.isPrintableKey( evt.key ) ) return false;
		if ( Validator.isStringLetter( evt.key ) )
			return this.onLetterKeyUp( currentValue, dropdownView, evt );
		if ( Validator.isStringNumber( evt.key ) )
			return this.onDigitKeyUp( currentValue, dropdownView, evt );
		this.focusIfChildIsFocused( dropdownView );
		return false;
	}

	processCompactEnter( dropdownView, evt ) {
		this.onEnter( " ", dropdownView, evt );
	}

	onEnter( currentValue, dropdownView, evt ) {
		EventHelper.stop( evt );
		if ( !Validator.isObject( dropdownView ) ) return false;
		if (
			// Validator.isString( currentValue ) &&
			Validator.isObject( dropdownView.activeButton ) &&
			Validator.isFunction( dropdownView.activeButton.fire ) )
			dropdownView.activeButton.fire( "execute" );
		dropdownView.isOpen = false;
		dropdownView.element.blur();
		return true;
	}

	processCompactKeyUp( dropdownView, evt ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isArray( dropdownView.actualList, true ) ||
			!( evt instanceof KeyboardEvent ) ) return false;
		const lowerCasePrefix = evt.key.toLowerCase();
		const matchingFont = dropdownView.actualList.find( font =>
			Validator.isString( font ) &&
			font.toLowerCase().startsWith( lowerCasePrefix ) );
		return this.highlightListBasedOnFont( dropdownView, matchingFont );
	}

	onEscape( dropdownView, evt ) {
		EventHelper.stop( evt );
		if ( Validator.isObject( dropdownView ) ) {
			dropdownView.isOpen = false;
			if ( dropdownView.element instanceof HTMLElement )
				dropdownView.element.blur();
		}
		if ( Validator.isObjectPath( this.editor, "editor.editing.view" ) &&
			Validator.isFunction( this.editor.editing.view.focus ) )
			this.editor.editing.view.focus();
		return true;
	}

	onBackspace( currentValue, dropdownView, evt ) {
		EventHelper.stop( evt );
		if ( !Validator.isString( currentValue ) ) return false;
		currentValue = currentValue.substring( 0, currentValue.length - 1 );
		if ( !Validator.isString( currentValue ) )
			dropdownView.buttonView.label = "";
		else this.findAndHighlightFont( dropdownView, currentValue );
		return true;
	}

	onSpace( currentValue, dropdownView, evt ) {
		this.onSpecialSign( currentValue, dropdownView, evt, " " );
	}

	onSpecialSign( currentValue, dropdownView, evt, sign ) {
		if ( typeof currentValue != "string" ||
			!Validator.isObject( dropdownView ) ||
			!Validator.isString( sign ) ) return false;
		EventHelper.stop( evt );
		if ( !Validator.isString( currentValue ) ||
			currentValue.charAt( currentValue.length - 1 ) == sign ) return false;
		currentValue += sign;
		return this.findAndHighlightFont( dropdownView, currentValue );
	}

	onArrowUp( dropdownView, evt ) {
		return this.highlightNeighbour( dropdownView, evt, true );
	}

	onArrowDown( dropdownView, evt ) {
		return this.highlightNeighbour( dropdownView, evt, false );
	}

	highlightNeighbour( dropdownView, evt, upperNeighbour = true ) {
		EventHelper.stop( evt );
		const newLabel = this
			.getLabelFromNeighbourToSelectedButton( dropdownView, !!upperNeighbour );
		if ( !Validator.isString( newLabel ) )
			return this.scrollAndFocus( dropdownView, false );
		if ( !!upperNeighbour && newLabel === "Default" )
			return this.scrollAndFocus( dropdownView, false );
		dropdownView.buttonView.label = newLabel;
		dropdownView.isBeingEdited = false;
		this.highlightListBasedOnFont( dropdownView, newLabel );
		this.focus( dropdownView );
		return true;
	}

	// onDelete( dropdownView, evt ) {
	// if ( !Validator.isObject( dropdownView ) ) return false;
	// 	console.log( "delete" );
	// EventHelper.stop( evt );
	// return true;
	// }

	onLetterKeyUp( currentValue, dropdownView, evt ) {
		if ( typeof currentValue != "string" ||
			!Validator.isObject( dropdownView ) ) return false;
		const lastCharacter = currentValue.length < 0 ? void 0 :
			currentValue.charAt( currentValue.length - 1 );
		currentValue += currentValue.length < 0 ? evt.key.toUpperCase() :
			// lastCharacter == " " ? evt.key.toUpperCase() :
			// lastCharacter != "-" ? evt.key.toLowerCase() :
			evt.shiftKey ? evt.key.toUpperCase() : evt.key.toLowerCase();
		this.findAndHighlightFont( dropdownView, currentValue );
		EventHelper.stop( evt );
		return true;
	}

	onDigitKeyUp( currentValue, dropdownView, evt ) {
		if ( typeof currentValue != "string" ||
			!Validator.isObject( dropdownView ) ) return false;
		currentValue += evt.key;
		this.findAndHighlightFont( dropdownView, currentValue );
		EventHelper.stop( evt );
		return true;
	}

	findAndHighlightFont( dropdownView, fontPrefix ) {
		if ( !Validator.isString( fontPrefix ) ||
			!Validator.isObject( dropdownView ) ||
			!Validator.isArray( dropdownView.actualList, true ) ) return false;
		const lowerCasePrefix = fontPrefix.toLowerCase();
		const matchingFont = dropdownView.actualList.find( font =>
			Validator.isString( font ) &&
			font.toLowerCase().startsWith( lowerCasePrefix ) );
		if ( !Validator.isString( matchingFont ) ) return false;
		dropdownView.buttonView.label = fontPrefix;
		dropdownView.isBeingEdited = true;
		return this.highlightListBasedOnFont( dropdownView, matchingFont );
	}

	highlightListBasedOnFont( dropdownView, matchingFont ) {
		if ( !Validator.isObject( dropdownView ) ) return false;
		if ( Validator.isString( matchingFont ) ) {
			this._updateAllButtonsAndLabels( matchingFont, matchingFont, false );
			dropdownView.matchingFont = matchingFont;
		}
		this.scrollToSelectedButton( dropdownView );
		this.setMainButtonFont( dropdownView );
		return true;
	}

	scrollToSelectedButton( dropdownView ) {
		if ( !Validator.isObjectPath( dropdownView, "dropdownView.panelView" ) ||
			!Validator.isObject( dropdownView.activeButton ) ||
			!( dropdownView.activeButton.element instanceof HTMLElement ) ||
			!( dropdownView.panelView.element instanceof HTMLElement ) ) return false;
		const buttonElement = dropdownView.activeButton.element;
		const buttonRect = buttonElement.getBoundingClientRect();
		if ( !( buttonRect instanceof DOMRect ) ) return false;
		const panelElement = dropdownView.panelView.element;
		const panelRect = panelElement.getBoundingClientRect();
		if ( !( panelRect instanceof DOMRect ) ) return false;
		panelElement.scrollTop += buttonRect.top - panelRect.top;
		if ( panelElement.scrollTop == 0 )
			return this._scrollToButtonInsideScrollArea( buttonRect, panelElement );
		return true;
	}

	_scrollToButtonInsideScrollArea( buttonRect, panelElement ) {
		if ( !( buttonRect instanceof DOMRect ) ||
			!( panelElement instanceof HTMLElement ) ) return false;
		if ( panelElement.children.length < 1 ) return false;
		const firstChildElement = panelElement.children[ 0 ];
		if ( !( firstChildElement instanceof HTMLDivElement ) ) return false;
		const childRect = firstChildElement.getBoundingClientRect();
		if ( !( childRect instanceof DOMRect ) ) return false;
		firstChildElement.scrollTop += buttonRect.top - childRect.top;
		return true;
	}

	setMainButtonFont( dropdownView ) {
		if ( !Validator.isObjectPath( dropdownView, "dropdownView.buttonView" ) ||
			!( dropdownView.buttonView.element instanceof HTMLElement ) ||
			!Validator.isString( dropdownView.matchingFont ) ) return false;
		dropdownView.buttonView.element.fontFamily = dropdownView.matchingFont;
		return true;
	}

	_afterCompactDropdownRender( dropdownView ) {
		this.addDropDownFunctionality( dropdownView );
		return this.addTabindexAndKeyupListener( {
			dropdownView: dropdownView,
			keyupMethodName: "onCompactKeyUp",
			list: FONTS
		} );
	}

	_afterFullDropdownRender( dropdownView ) {
		this.addDropDownFunctionality( dropdownView );
		return this.addTabindexAndKeyupListener( {
			dropdownView: dropdownView,
			keyupMethodName: "onKeyUp",
			list: FONTS
		} );
	}

	_addFocusKeyupReactions( dropdownView ) {
		if ( !Validator.isObjectPath( dropdownView, "dropdownView.panelView" ) ||
			!Validator.isFunction( dropdownView.panelView.on ) ) return false;

		if ( !Validator.isObject( this.editor.objects ) )
			this.editor.objects = {};
		if ( !Validator.isObject( this.editor.objects.dropdowns ) )
			this.editor.objects.dropdowns = {};
		if ( !Validator.isObject( this.editor.objects.dropdowns.fontFamily ) )
			this.editor.objects.dropdowns.fontFamily = { isOpen: false };

		// on close
		dropdownView.panelView.on( "change:isVisible", ( eventInfo, propertyName,
			isVisible, wasVisibleBefore ) => {
			if ( isVisible && !wasVisibleBefore ) return;
			// console.log( "font family close" );
			this.resetFlags( dropdownView );
			this._updateToLiveFont();
			if ( !dropdownView.fontSizeOpen ) {
				GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( isVisible );
				this.blur( dropdownView );
			}
			dropdownView.fontFamilyOpen = false;
		}, { priority: Number.MAX_SAFE_INTEGER } );

		// on open
		dropdownView.panelView.on( "change:isVisible", ( eventInfo, propertyName,
			isVisible, wasVisibleBefore ) => {
			if ( !isVisible || wasVisibleBefore ) return;
			// console.log( "font family open" );
			this.resetFlags( dropdownView );
			GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( isVisible );
			this.focus( dropdownView );
			this.getSelectedButtonAndSetActive( dropdownView );
			this.scrollToSelectedButton( dropdownView );
			dropdownView.fontFamilyOpen = true;
		}, { priority: Number.MIN_SAFE_INTEGER } );

		return true;
	}

	addDropDownFunctionality( dropdownView ) {
		this.setBasicDropdownFunctionality( dropdownView );
		if ( !Validator.isObject( dropdownView ) ) return false;
	}

}
