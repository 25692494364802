import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import PisaSpellcheckCommand from './pisaspellcheckcommand';
import PisaSpellcheckCommandInit from './pisaspellcheckcommandinit';
import { SPELLCHECK } from './pisaspellcheckcommand';
import { setButton } from '../utils';
import { spellcheckIcon } from '../icons';

export default class PisaSpellcheckUI extends Plugin {

	static get requires() {
		return [ PisaSpellcheckCommandInit ];
	}

	init() {
		const editor = this.editor;

		// editor.commands.add( SPELLCHECK, new PisaSpellcheckCommand( editor ) );

		editor.ui.componentFactory.add( SPELLCHECK, locale => {
			return setButton( spellcheckIcon,
				editor.objects.tooltips.getT( SPELLCHECK ), SPELLCHECK, "", editor );
		} );

		// editor.once( 'ready', () => editor.execute( SPELLCHECK ) );
	}

}
