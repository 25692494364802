import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';
import { setButton } from '../utils';
import { SUBSCRIPT } from './pisasubscriptediting';
import { subscriptIcon } from '../icons';

export default class PisaSubscriptUI extends Plugin {

	init() {
		const editor = this.editor;

		editor.commands.add( SUBSCRIPT, new AttributeCommand( editor, SUBSCRIPT ) );

		editor.ui.componentFactory.add( SUBSCRIPT, locale => {
			return setButton( subscriptIcon,
				editor.objects.tooltips.getT( SUBSCRIPT ), SUBSCRIPT, "", editor );
		} );
	}
}
