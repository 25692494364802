import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import IndentCommand from './pisaindentcommand';
import { INDENT } from './pisaindentcommand';
import { createButton } from '../utils';
import { indentLeft, indentRight } from '../icons';

export const indentOptions = [ '0pt', 'p', 'm', '36pt', '72pt', '108pt', '144pt', '180pt', '216pt', '252pt', '288pt' ];

const icons = new Map( [
	[ 'p', indentLeft ],
	[ 'm', indentRight ]
] );

export default class IndentUI extends Plugin {

	get localizedOptionTitles() {
		const t = this.editor.t;

		return {
			'p': this.editor.objects.tooltips.getT( `${ INDENT }:p` ),
			'm': this.editor.objects.tooltips.getT( `${ INDENT }:m` )
		};
	}

	static get pluginName() {
		return 'IndentUI';
	}

	init() {
		this.editor.commands.add( INDENT, new IndentCommand( this.editor ) );
		this._addButton( 'p' );
		this._addButton( 'm' );
	}

	_addButton( option ) {
		const editor = this.editor;

		editor.ui.componentFactory.add( `${ INDENT }:${ option }`, locale => {
			const command = editor.commands.get( INDENT );
			const buttonView = createButton( icons.get( option ),
				this.localizedOptionTitles[ option ], locale );

			buttonView.bind( 'isEnabled' ).to( command );

			this.listenTo( buttonView, 'execute', () => {
				editor.executeIf( INDENT, { value: option }, false );
				editor.editing.view.focus();
			} );

			editor.objects.focus._addExecuteFocus( buttonView );

			return buttonView;
		} );
	}
}
