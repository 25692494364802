import * as colors from "../pisacolorscheme";

export const ckeColorList = colorsStringToList( colors.gray + colors.brown +
	colors.red + colors.orange + colors.yellow + colors.green + colors.cyan +
	colors.blue + colors.purple + colors.pink );

function colorsStringToList( colorsString ) {
	if ( !colorsString || typeof colorsString != "string" || colorsString.indexOf( "," ) < 0 ) {
		return [];
	}
	colorsString = formatColorsString( colorsString );
	let colorsList = colorsString.split( "," );
	for ( var i = 0; i < colorsList.length; i++ ) {
		colorsList[ i ] = colorsList[ i ].replace( /[^#\w\d]+/g, "" );
		if ( colorsList[ i ].indexOf( "#" ) < 0 ) {
			colorsList[ i ] = "#" + colorsList[ i ];
		}
		if ( colorsList[ i ].length != 4 && colorsList[ i ].length != 7 ) {
			colorsList.splice( i, 1 );
		}
	}
	return colorsList;
}

function formatColorsString( colorsString ) {
	if ( !colorsString || typeof colorsString != "string" || colorsString.indexOf( "," ) < 0 ) {
		return "";
	}
	colorsString = colorsString.replace( /[^#,\w\d]+/g, "" ).replace( /,{2,}/g, "," ).replace( /#{2,}/g, "#" );
	if ( colorsString.charAt( 0 ) == "," ) {
		colorsString = colorsString.substring( 1, colorsString.length - 1 );
	}
	if ( colorsString.charAt( colorsString.length - 1 ) == "," ) {
		colorsString = colorsString.substring( 0, colorsString.length - 2 );
	}
	if ( colorsString.indexOf( "#" ) < 0 ) {
		colorsString = "#" + colorsString.replace( /,/g, ",#" );
	}
	return colorsString;
}
