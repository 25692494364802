import Command from '@ckeditor/ckeditor5-core/src/command';
import { ALIGN_TABLE } from './pisatableui';
import { DECREASE_TABLE_MARGIN } from './pisatableui';
import { lastValidSelection } from '../pisafire/pisafirecommand';
import { setSelection } from '../utils';

export default class PisaDecreaseMarginCommand extends Command {

	constructor( editor ) {
		super( editor, DECREASE_TABLE_MARGIN );
	}

	refresh() {
		this.isEnabled = selectionHasTable( this.editor );
	}

	execute( options = {} ) {
		const editor = this.editor;
		const selection = lastValidSelection( editor, options );
		editor.execute( ALIGN_TABLE, { value: "m" } );
		selection && setSelection( editor, selection.getFirstRange() );
	}
}

export function isInsideTable( element ) {
	if ( !element ) return false;
	while ( element.parent ) {
		if ( element.name && element.name == "table" ) {
			return true;
			break;
		}
		element = element.parent;
	}
	return false;
}

export function selectionHasTable( editor ) {
	let selection = editor.model.document.selection;
	let selectionHasTable = isInsideTable( selection.anchor.parent );
	if ( !selectionHasTable && selection.anchor.parent.name == "$root" && selection.anchor.path.length == 1 ) {
		let index = selection.anchor.path[ 0 ];
		let element = selection.anchor.parent._children._nodes[ index ];
		selectionHasTable = isInsideTable( element );
	}
	return selectionHasTable;
}
