import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class PisaWordBreak extends Plugin {

	static get pluginName() {
		return 'PisaWordBreak';
	}

	init() {
		const editor = this.editor;

		editor.model.schema.register( 'wordBreak', {
			allowIn: [ "$block", "$tableCell", "$clipboardHolder" ],
			isObject: true,
			isLimit: true,
			allowAttributesOf: '$text'
		} );

		editor.conversion.for( 'upcast' ).elementToElement( {
			model: 'wordBreak',
			view: 'wbr'
		} );

		editor.conversion.for( 'downcast' ).elementToElement( {
			model: 'wordBreak',
			view: ( modelElement, viewWriter ) => {
				let wordBreak = viewWriter.createEmptyElement( "wbr" );
				return wordBreak;
			}
		} );
	}
}
