
import Command from '@ckeditor/ckeditor5-core/src/command';

export const SWITCH_VISIBILITY = "pisaSwitchVisibility";

export default class PisaSwitchVisibilityCommand extends Command {

  constructor( editor ) {
		super( editor, SWITCH_VISIBILITY );
	}

  execute( options = {} ) {
    const editor = this.editor;
    if ( !options.view || !( options.visible || options.visible == false ) ) return;
    Boolean( options.visible ) ? showView( options.view ) : hideView( options.view );
  }

}

function showView( view ) {
  view.isVisible = true;
  if ( !view.element ) return;
  view.element && view.element.nodeName && view.element.nodeName == "BUTTON"
  ? view.element.setAttribute( "style", "visibility:visible;display:inline-flex;" )
  : view.element.setAttribute( "style", "visibility:visible;display:inline-block;" );
  view.element.removeAttribute( "invisible" );
}

function hideView( view ) {
  view.isVisible = false;
  if ( !view.element ) return;
  view.element.setAttribute( "style", "visibility:hidden;display:none;" );
  view.element.invisible = true;
}
