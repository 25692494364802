import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Validator from '../pisautils/validator';
import { createButton } from '../utils';
import { maximizeIcon, threeDots } from '../icons';

export const MAXIMIZE_EDITOR = "pisaMaximize";

export default class PisaMaximize extends Plugin {

	init() {
		const editor = this.editor;
		editor.ui.componentFactory.add( MAXIMIZE_EDITOR, locale => {
			let maximizeButton = createButton( maximizeIcon,
				editor.objects.tooltips.getT( MAXIMIZE_EDITOR ), editor.locale );
			maximizeButton.on( "execute", ( eventInfo ) => {
				if ( editor.isReadOnly ) return;
				if ( Validator.isObjectPath( editor, "editor.objects.linkBalloon" ) &&
					Validator.isFunction( editor.objects.linkBalloon.hide ) )
					editor.objects.linkBalloon.hide();
				if ( Validator.isObject( editor.balloons ) &&
					Validator.isFunction( editor.balloons.hideAll ) )
					editor.balloons.hideAll();
				editor.fire( MAXIMIZE_EDITOR );
			} );
			return maximizeButton;
		} );
	}
}
