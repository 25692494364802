import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaSpellcheckCommand from './pisaspellcheckcommand';
import { SPELLCHECK } from './pisaspellcheckcommand';

export default class PisaSpellcheckCommandInit extends Plugin {

	init() {
		const editor = this.editor;
		let command = editor.commands.get( SPELLCHECK );
		if ( !!command && typeof command == "object" ) return;
		editor.commands.add( SPELLCHECK, new PisaSpellcheckCommand( editor ) );
		editor.once( 'ready', () => editor.execute( SPELLCHECK ) );
	}

}
