import Validator from './validator';
import Warner from './warner';

const FUNCTION_FAILURE_MESSAGE = "exec function failed miserably";

export default class FunctionHelper {

	static exec( functionObject, argumentsArr = [], scope = null,
		calledInTryCatch = false ) {
		if ( !Validator.isFunction( functionObject ) )
			return calledInTryCatch ? FUNCTION_FAILURE_MESSAGE : void 0;
		if ( !Validator.isArray( argumentsArr ) &&
			Validator.isObject( argumentsArr ) ) argumentsArr = [ argumentsArr ];
		if ( !Validator.isObject( scope ) ) scope = null;
		let functionString = "functionObject.call(scope,";
		let argumentIndex = 0;
		while ( argumentIndex < argumentsArr.length )
			functionString += `argumentsArr[ ${ argumentIndex++ } ],`;
		functionString = functionString.endsWith( "," ) ?
			functionString.slice( 0, -1 ) + ")" : functionString + ")";
		return eval( functionString );
		// or
		return functionObject.apply( scope, argumentsArr );
		// or
		return functionObject( ...[ scope ].concat( argumentsArr ) ); // not sure about scope
	}

	static tryCatch( {
		functionObject,
		argumentsArr = [],
		scope = null,
		logError = true,
		returnSuccess = true,
		returnError = false,
		rethrowError = false,
		additionalMessage = null,
		wdgId
	} ) {
		let success = false;
		let errorToReturn = void 0;
		try {
			let result = FunctionHelper.exec( functionObject, argumentsArr, scope, true );
			success = result != FUNCTION_FAILURE_MESSAGE;
		} catch ( error ) {
			success = false;
			if ( logError ) Warner.output( {
				error: true,
				errorText: error,
				collapse: true,
				text: `Couldn't execute the function "${ functionObject.name }". Editor` +
					` widget id: ${ wdgId }.` + ( !Validator.isString( additionalMessage ) ?
						"" : `\n${ additionalMessage }` ),
				color: "#f1828d",
				addTimestamp: true
			} );
			if ( Validator.isString( wdgId ) ) error.wdgId = wdgId;
			if ( returnError ) errorToReturn = error;
			if ( rethrowError ) throw error;
		}
		return returnError ? errorToReturn : returnSuccess ? success : void 0;
	}

	static rethrow( error, wdgId ) {
		if ( !Validator.isObject( error ) ) return;
		if ( Validator.isString( wdgId ) ) error.wdgId = wdgId;
		throw error;
	}

}
