import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { defineButton, addClasses, closeDropdownOnBlur, addDropdownCloseListener } from '../utils';
import { clipboardIcon } from '../icons';
import PisaCopy, { COPY } from './pisacopy';
import PisaCut, { CUT } from './pisacut';
import PisaPaste, { PASTE, CLIPBOARD_PASTE } from './pisapaste';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
export const CLIPBOARD = "pisaClipboard";

export default class PisaClipboard extends Plugin {

	static get requires() {
		return [ PisaCopy, PisaCut, PisaPaste ];
	}

	init() {
		const editor = this.editor;

		editor.ui.componentFactory.add( CLIPBOARD, locale => {
			let dropdownView = createDropdown( editor.locale );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			defineButton( dropdownView.buttonView, clipboardIcon,
				editor.objects.tooltips.getT( CLIPBOARD ) );
			closeDropdownOnBlur( dropdownView );
			addDropdownCloseListener( editor, dropdownView );

			dropdownView.panelView.children.add( editor.ui.componentFactory.create( COPY ), 0 );
			dropdownView.panelView.children.add( editor.ui.componentFactory.create( CUT ), 1 );
			if ( window.pisasales && window.pisasales.isTouch ) {
				dropdownView.panelView.children.add( editor.ui.componentFactory.create( CLIPBOARD_PASTE ), 2 );
				return dropdownView;
			}

			// if you want to (re)insert the paste button into the dropdown, use:
			// if ( !window.navigator || !window.navigator.clipboard ||
			// 	!window.navigator.clipboard.readText ) return dropdownView;
			// dropdownView.panelView.children.add( editor.ui.componentFactory.create( PASTE ), 2 );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, CLIPBOARD );

			return dropdownView;
		} );

	}
}
