import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { CHANGE_TOOLBAR } from './pisachangetoolbarcommand';
import ChangeToolbarCommand from './pisachangetoolbarcommand';
import { PLACE_BUTTON } from './pisaplacebuttoncommand';
import PisaPlaceButtonCommand from './pisaplacebuttoncommand';
import { SWITCH_VISIBILITY } from './pisaswitchvisibilitycommand';
import PisaSwitchVisibilityCommand from './pisaswitchvisibilitycommand';
import { enableAndExecute, createButton } from '../utils';
import { arrowDownIcon, moreToolbarIcon, lessToolbarIcon, threeDots } from '../icons';
import PisaToolbar from './pisatoolbar';
import { SHOW_MORE_TOOLBAR_OPTIONS, SHOW_LESS_TOOLBAR_OPTIONS } from './pisatoolbar';

export default class ChangeToolbar extends Plugin {

	static get requires() {
		return [ PisaToolbar ];
	}

	init() {
		const editor = this.editor;
		const toolbar = editor.objects.toolbar;

		if ( !editor.commands.get( SWITCH_VISIBILITY ) )
			editor.commands.add( SWITCH_VISIBILITY, new PisaSwitchVisibilityCommand( editor ) );
		if ( !editor.commands.get( CHANGE_TOOLBAR ) )
			editor.commands.add( CHANGE_TOOLBAR, new ChangeToolbarCommand( editor ) );
		if ( !editor.commands.get( PLACE_BUTTON ) )
			editor.commands.add( PLACE_BUTTON, new PisaPlaceButtonCommand( editor ) );

		editor.ui.componentFactory.add( CHANGE_TOOLBAR, locale => {

			const buttonView = createButton( threeDots,
				editor.objects.tooltips.getT( SHOW_MORE_TOOLBAR_OPTIONS ), locale );

			buttonView.on( 'execute', ( eventInfo ) => {
				if ( editor.isReadOnly ) return;
				toolbar.toggle();
				toolbar.fireChange();
				toolbar.syncButtonWithState();
			} );

			// buttonView.bind( "isOn" ).to( editor, "state", "ready" );

			toolbar.changeButtons.push( buttonView );

			return buttonView;
		} );

	}
}

function execute( editor, commandName, buttonView, tooltip, icon ) {
	enableAndExecute( editor, commandName );
	buttonView.icon = icon;
	buttonView.tooltip = tooltip;
	enableAndExecute( editor, "pisaFire", { event: "pisaToolbarChanged" } );
}
