import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';

export const SUPERSCRIPT = 'pisaSuperscript';
const supTag = "sup";

export default class PisaSuperscriptEditing extends Plugin {

	init() {
		const editor = this.editor;

		editor.model.schema.extend( '$text', { allowAttributes: SUPERSCRIPT } );

		editor.conversion.attributeToElement( {
			model: SUPERSCRIPT,
			view: supTag
		} );

		// editor.commands.add( SUPERSCRIPT, new AttributeCommand( editor, SUPERSCRIPT ) );
	}

}
