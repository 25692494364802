import Command from '@ckeditor/ckeditor5-core/src/command';
import { VERTICAL_ALIGN } from './pisaimagepositionediting';
import { getStats, setListenersByWriter } from '../pisaimageresize/utils.js';
import { setSelectionOnElement } from '../utils';

export default class PisaImagePositionCommand extends Command {

	constructor( editor ) {
		super( editor, VERTICAL_ALIGN );
	}

	execute( options = {} ) {
		const editor = this.editor;
		options.value != "top" && options.value != "middle" ? options.value = "bottom" : void 0;
		let stats = getStats( editor, options.selection || null );
		if ( !stats || ( !stats.range && !stats.node ) || !stats.attributes ) return;
		let value = stats.attributes && stats.attributes.pisaVerticalAlign != "middle" ? "middle" :
			( !stats.node || !stats.node.lastAlignment ? ( options.value || "middle" ) :
				( stats.node.lastAlignment == "top" ? "bottom" : "top" ) );
		stats.node ? ( value == "middle" ?
			stats.node.lastAlignment = stats.attributes.pisaVerticalAlign ||
			"bottom" : stats.node.lastAlignment = value ) : void 0;
		editor.model.change( writer => {
			writer.setAttribute( VERTICAL_ALIGN, value, stats.node || stats.range );
		} );
		setListenersByWriter( editor, stats.node, stats.attributes.id );
		editor.objects && editor.objects.images ? editor.objects.images.updateAllListeners() : void 0;
		stats.node && setSelectionOnElement( editor, stats.node, true, true, false );
		editor.model.change( writer => {
			writer.setSelection( stats.node, "on" );
		} );
	}
}
