import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaList from './pisalist';
import ListUI from '../iconsui/listui';
import EmptyView from '../pisadropdown/emptyview';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import dropdownArrowIcon from '@ckeditor/ckeditor5-ui/theme/icons/dropdown-arrow.svg';
import { addClasses, createButton, defineButton, addViewChildren, closeDropdownOnBlur } from '../utils';
import { numberedListIcon, bulletedListIcon } from '../icons';
import { numberedList, startList, continueList } from '../commandnames';
import { LIST_VALUE, CONTINUE_LIST } from './pisalistediting';
import Validator from '../pisautils/validator';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const NUMBERED_LIST_DROPDOWN = "pisaNumberedListDropdown";

const LIST_ITEM = "listItem";
const NUMBERED_LIST_TYPE = "numbered";
const BULLETED_LIST_TYPE = "bulleted";
const LIST_TYPE_ATTRIBUTE = "listType";
const LIST_INDENT_ATTRIBUTE = "listIndent";

export default class PisaListUI extends Plugin {

	static get requires() {
		return [ PisaList, ListUI ];
	}

	static get pluginName() {
		return 'PisaListUI';
	}

	init() {
		const editor = this.editor;
		const numberedListCommand = editor.commands._commands.get( numberedList );
		this.continueListCommand = editor.commands._commands.get( CONTINUE_LIST );
		const self = this;

		editor.ui.componentFactory.add( NUMBERED_LIST_DROPDOWN, locale => {
			let numberedListButton = this.editor.ui.componentFactory.create( numberedList );
			numberedListButton.on( "execute", ( eventInfo ) => {
				self._hideBalloons();
			} );

			let startNumerationButton = this.editor.ui.componentFactory.create( startList );
			let continueNumerationButton = this.editor.ui.componentFactory.create( continueList );
			let startContinueDropdown = createDropdown( editor.locale );
			startContinueDropdown.set( 'panelPosition', 'sw' );
			defineButton( startContinueDropdown.buttonView,
				dropdownArrowIcon, editor.objects.tooltips.getT( NUMBERED_LIST_DROPDOWN ) );
			addClasses( startContinueDropdown.buttonView, [ "pisa-arrow-down-button" ] );
			addClasses( startContinueDropdown.buttonView.iconView, [ "pisa-hidden" ] );
			startContinueDropdown.buttonView.iconView.set( 'isVisible', false );
			addClasses( startContinueDropdown.panelView, [ "pisa-vertical-grid" ] );
			startContinueDropdown.panelView.on( "change:isVisible", (
				eventInfo, name, value, oldValue ) => {
				if ( !value || oldValue ) return;
				self._hideBalloons();
			} );
			startContinueDropdown.bind( 'isEnabled' ).to( numberedListCommand );
			closeDropdownOnBlur( startContinueDropdown );
			editor.objects.focus._addExecuteFocus( startContinueDropdown );
			addViewChildren( startContinueDropdown.panelView, {
				0: startNumerationButton,
				1: continueNumerationButton
			} );

			let container = new EmptyView( [ "" ] );
			addViewChildren( container, {
				0: numberedListButton,
				1: startContinueDropdown
			} );
			editor.objects.focus._addExecuteFocus( container );
			editor.objects.focus._addExecuteFocus( startContinueDropdown.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( startContinueDropdown.buttonView, NUMBERED_LIST_DROPDOWN );

			return container;
		} );

		// editor.model.document.registerPostFixer( writer => {
		// 	this._fixListItemNumbers( writer );
		// } );

	}

	_hideBalloons() {
		if ( !Validator.isObjectPath( this, "this.editor.balloons" ) ||
			!Validator.isFunction( this.editor.balloons.hideAll ) ) return;
		this.editor.balloons.hideAll();
	}

	_fixListItemNumbers( writer ) {
		const editor = this.editor;
		let changes = editor.model.document.differ.getChanges().filter( change =>
			this._isChangeBulletedToNumberedListItem( change ) );
		if ( changes.length <= 0 ) return;
		let listItems = this._getListItemsFromChanges( changes );
		if ( listItems.length <= 0 ) return;
		editor.model.change( writer => {
			for ( let listItem of listItems ) {
				this._processListItem( writer, listItem );
			}
		} );
	}

	_isChangeBulletedToNumberedListItem( change ) {
		return Validator.isObject( change ) && change.type == "attribute" &&
			change.attributeKey == LIST_TYPE_ATTRIBUTE &&
			change.attributeOldValue == BULLETED_LIST_TYPE &&
			change.attributeNewValue == NUMBERED_LIST_TYPE &&
			Validator.is( change.range, "Range" );
	}

	_getListItemsFromChanges( changes ) {
		let listItems = [];
		if ( !Validator.isArray( changes ) ) return listItems;
		for ( let change of changes ) {
			let listItem = this._getListItemFromRange( change.range );
			if ( !Validator.isObject( listItem ) ) continue;
			listItems.push( listItem );
		}
		return listItems;
	}

	_getListItemFromRange( range ) {
		if ( !Validator.is( range, "Range" ) ) return;
		let startItem = Validator.isElement( range.start.nodeAfter, LIST_ITEM ) ?
			range.start.nodeAfter :
			Validator.isElement( range.start.parent, LIST_ITEM ) ?
			range.start.parent : void 0;
		let endItem = Validator.isElement( range.end.nodeBefore, LIST_ITEM ) ?
			range.end.nodeBefore :
			Validator.isElement( range.end.parent, LIST_ITEM ) ?
			range.end.parent : void 0;
		let listItem = !Validator.isObject( startItem ) ? endItem :
			!Validator.isObject( endItem ) ? startItem :
			startItem == endItem ? startItem : void 0;
		if ( Validator.isObject( listItem ) ) listItem.range = range;
		return listItem;
	}

	_processListItem( writer, listItem ) {
		if ( !Validator.isObject( this.continueListCommand ) ) return;
		if ( !Validator.isElement( listItem.previousSibling, LIST_ITEM ) ) return;
		if ( !Validator.isMap( listItem.previousSibling._attrs ) ||
			listItem.previousSibling._attrs
			.get( LIST_TYPE_ATTRIBUTE ) != NUMBERED_LIST_TYPE ||
			!Validator.isMap( listItem._attrs ) ||
			listItem._attrs.get( LIST_INDENT_ATTRIBUTE ) !=
			listItem.previousSibling._attrs.get( LIST_INDENT_ATTRIBUTE ) ) return;
		let value = 1 + this.continueListCommand
			._getListElementValue( listItem.previousSibling );
		writer.setAttribute( LIST_VALUE, value, listItem );
	}

}
