import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import View from '@ckeditor/ckeditor5-ui/src/view';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { addClasses, defineButton, closeDropdownOnBlur, getObjectProto } from '../utils';
import PisaObjectLinkCommand from './pisaobjectlinkcommand';
import { PISAOBJECT } from './pisaobjectlinkcommand';
// import PisaInsertObjectLinkCommand from './pisainsertobjectlinkcommand';
// import { INSERT_OBJECT_LINK } from './pisainsertobjectlinkcommand';
import PisaSwitchVisibilityCommand from '../pisatoolbar/pisaswitchvisibilitycommand';
import { SWITCH_VISIBILITY } from '../pisatoolbar/pisaswitchvisibilitycommand';
import { CHANGE_TOOLBAR } from '../pisatoolbar/pisachangetoolbarcommand';
import { isInsidePlaceholder } from '../pisaplaceholder/pisaplaceholderui';
import { objectLinkIcon } from '../icons';
import { addPanelViewHeightHandler } from '../pisafontfamily/pisafontfamilyui';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export default class PisaObjectLink extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( PISAOBJECT, new PisaObjectLinkCommand( editor ) );
		// editor.commands.add( INSERT_OBJECT_LINK, new PisaInsertObjectLinkCommand( editor ) );
		const command = editor.commands.get( PISAOBJECT );
		this.dropdownViews = [];

		if ( !editor.commands.get( SWITCH_VISIBILITY ) )
			editor.commands.add( SWITCH_VISIBILITY, new PisaSwitchVisibilityCommand( editor ) );

		editor.once( "ready", () => {
			if ( hasValidObjectMenu( editor ) ) return;
			this.dropdownViews.forEach( dropdownView => {
				editor.executeIf( SWITCH_VISIBILITY, { view: dropdownView, visible: false }, true );
				dropdownView.isEnabled = false;
			} );
		} );

		editor.ui.componentFactory.add( PISAOBJECT, () => {
			const dropdownView = createDropdown( editor.locale );
			_refreshDropdownVisibility( dropdownView, editor );
			this.dropdownViews.push( dropdownView );
			if ( !hasValidObjectMenu( editor ) ) {
				console.warn( "Could not initialize PisaObjectLink plugin for cke5 editor: " +
					"object menu empty or invalid." );
				dropdownView.isVisible = false;
				dropdownView.isEnabled = false;
				return dropdownView;
			}
			addListToDropdown( dropdownView,
				_prepareListOptions( editor.sourceElement.objectMenu ) );
			defineButton( dropdownView.buttonView, objectLinkIcon,
				editor.objects.tooltips.getT( PISAOBJECT ) );
			addClasses( dropdownView, [ 'ck-font-family-dropdown', 'psa-scrollable-dropdown' ] );
			closeDropdownOnBlur( dropdownView );
			addPanelViewHeightHandler( editor, dropdownView );
			dropdownView.bind( 'isEnabled' ).to( command );
			// dropdownView.buttonView.on( "execute", () => {
			// 	editor.lastSelection = new Selection( editor.model.document.selection, 0 );
			// } );

			dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, value, oldValue ) => {
				if ( !value || oldValue ) return;
				if ( !isInsidePlaceholder( editor ) ) return;
				eventInfo.stop();
				dropdownView.panelView.set( "isVisible", false );
				console.warn( "Could not open dropdown: position in placeholder." );
			}, { priority: 999 } );

			dropdownView.on( "execute", evt => {
				editor.executeIf( evt.source.commandName, {
					key: evt.source.commandParam,
					selection: editor.objects.selection.lastInModel
				}, false );
				// editor.editing.view.focus();
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, PISAOBJECT );

			return dropdownView;
		} );
	}
}

function _prepareListOptions( objectMenu ) {
	const items = new Collection();
	if ( !isValidObjectMenu( objectMenu ) ) {
		return items;
	}
	for ( let value of Object.values( objectMenu ) ) {
		if ( !value || !value.ttl || !value.key ) continue;
		items.add( {
			type: 'button',
			model: new Model( {
				commandName: PISAOBJECT,
				commandParam: value.key,
				label: value.ttl,
				withText: true
			} )
		} );
	}
	return items;
}

function _refreshDropdownVisibility( dropdownView, editor ) {
	const switchVisibility = editor.commands.get( CHANGE_TOOLBAR );
	if ( !switchVisibility || typeof switchVisibility != "object" ) return;

	editor.once( "ready", () => {
		if ( hasValidObjectMenu( editor ) ) return;
		editor.executeIf( SWITCH_VISIBILITY, { view: dropdownView, visible: false }, true );
		dropdownView.isEnabled = false;
	} );

	const command = editor.commands.get( CHANGE_TOOLBAR );
	if ( !command || typeof command != "object" ) return;

	command.on( "execute", () => {
		if ( hasValidObjectMenu( editor ) ) return;
		editor.executeIf( SWITCH_VISIBILITY, { view: dropdownView, visible: false }, true );
	} );
}

export function hasValidObjectMenu( editor ) {
	return !!editor && !!editor.sourceElement &&
		isValidObjectMenu( editor.sourceElement.objectMenu );
}

function isValidObjectMenu( objectMenu ) {
	return !!objectMenu && getObjectProto( objectMenu ) == "Array" && objectMenu.length > 0;
}
