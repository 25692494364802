import Validator from '../pisautils/validator';
import EventHelper from '../pisautils/eventhelper';
// import HtmHelper from '../pisautils/htmhelper';
import AttachmentObject from '../pisautils/attachmentobject';
import KeyupFocusManager from '../pisaselection/keyupfocusmanager';
import StringHelper from '../pisautils/stringhelper';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import { BUTTONS, roundUpValue } from './pisafontsizeui';
import { getLiveAttributeValue, getLiveNodeStyle } from '../utils';
import { UP_KEYWORDS, DOWN_KEYWORDS } from '../pisaselection/keyupfocusmanager';

export default class FontSizeFocusManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		new KeyupFocusManager( hostPlugin );
	}

	_afterCompactDropdownRender( dropdownView ) {
		this.addDropDownFunctionality( dropdownView );
		return this.addTabindexAndKeyupListener( {
			dropdownView: dropdownView,
			keyupMethodName: "onCompactKeyUp",
			list: BUTTONS
		} );
	}

	onCompactKeyUp( dropdownView, evt ) {
		if ( !Validator.isObject( dropdownView ) ||
			!( evt instanceof KeyboardEvent ) || evt.ctrlKey || evt.shiftKey )
			return false;
		if ( this.keyIs( evt, "Enter" ) )
			return this.processCompactEnter( dropdownView, evt );
		if ( UP_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowUp( dropdownView, evt );
		if ( DOWN_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowDown( dropdownView, evt );
		if ( this.keyIs( evt, "Escape" ) )
			return this.onEscape( dropdownView, evt );
		if ( Validator.isPrintableKey( evt.key ) &&
			Validator.isStringNumber( evt.key ) )
			return this.processCompactKeyUp( dropdownView, evt );
		return false;
	}

	_afterFullDropdownRender( dropdownView ) {
		this.addDropDownFunctionality( dropdownView );
		return this.addTabindexAndKeyupListener( {
			dropdownView: dropdownView,
			keyupMethodName: "onFullKeyUp",
			list: BUTTONS
		} );
	}

	onFullKeyUp( dropdownView, evt ) {
		if ( !this.isValidDropdownAndEvent( dropdownView, evt ) || evt.shiftKey ) {
			this.focusIfChildIsFocused( dropdownView );
			return false;
		}
		if ( UP_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowUp( dropdownView, evt );
		if ( DOWN_KEYWORDS.some( key => this.keyIs( evt, key ) ) )
			return this.onArrowDown( dropdownView, evt );
		if ( this.keyIs( evt, "Escape" ) )
			return this.onEscape( dropdownView, evt );
		const currentValue = dropdownView.isBeingEdited ?
			dropdownView.buttonView.label : "";
		if ( this.keyIs( evt, "Enter" ) )
			return this.onEnter( currentValue, dropdownView, evt );
		if ( this.keyIs( evt, "Backspace" ) )
			return this.onBackspace( currentValue, dropdownView, evt );
		if ( Validator.isPrintableKey( evt.key ) &&
			Validator.isStringNumber( evt.key ) )
			return this.onDigitKeyUp( currentValue, dropdownView, evt );
		this.focusIfChildIsFocused( dropdownView );
		return false;
	}

	processCompactEnter( dropdownView, evt ) {
		this.onEnter( " ", dropdownView, evt );
	}

	onEnter( currentValue, dropdownView, evt ) {
		EventHelper.stop( evt );
		if ( !Validator.isObject( dropdownView ) ) return false;
		if (
			// Validator.isString( currentValue ) &&
			Validator.isObject( dropdownView.activeButton ) &&
			Validator.isFunction( dropdownView.activeButton.fire ) )
			dropdownView.activeButton.fire( "execute" );
		this.updateAll();
		dropdownView.isOpen = false;
		dropdownView.element.blur();
		return true;
	}

	onEscape( dropdownView, evt ) {
		EventHelper.stop( evt );
		this.updateAll();
		dropdownView.isOpen = false;
		dropdownView.element.blur();
		return true;
	}

	updateAll( onReady = false ) {
		this.updateFull( onReady );
		this.updateCompact();
	}

	updateFull( onReady = false ) {
		if ( !Validator.isObject( this.views ) ||
			!Validator.isArray( this.views.full ) ) return false;
		for ( let dropdownView of this.views.full ) {
			if ( !Validator.isObject( dropdownView ) ||
				!Validator.isFunction( dropdownView.fullUpdate ) ) continue;
			dropdownView.fullUpdate( onReady );
		}
		return true;
	}

	updateCompact() {
		if ( !Validator.isObject( this.views ) ||
			!Validator.isArray( this.views.compact ) ) return false;
		for ( let dropdownView of this.views.compact ) {
			if ( !Validator.isObject( dropdownView ) ||
				!Validator.isFunction( dropdownView.fullUpdate ) ) continue;
			dropdownView.fullUpdate();
		}
		return true;
	}

	processCompactKeyUp( dropdownView, evt ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isArray( dropdownView.actualList, true ) ||
			!( evt instanceof KeyboardEvent ) ) return false;
		const matchingSize = dropdownView.actualList.find( size =>
			Validator.isString( size ) && size.startsWith( evt.key ) );
		return this.highlightListBasedOnOption( dropdownView, matchingSize );
	}

	onArrowUp( dropdownView, evt ) {
		return this.highlightNeighbour( dropdownView, evt, true );
	}

	onArrowDown( dropdownView, evt ) {
		return this.highlightNeighbour( dropdownView, evt, false );
	}

	highlightNeighbour( dropdownView, evt, upperNeighbour = true ) {
		EventHelper.stop( evt );
		const neighbourButton = this.getNeighbourToSelectedButton( dropdownView, !!upperNeighbour );
		if ( !Validator.isObject( neighbourButton ) )
			return this.scrollAndFocus( dropdownView, false );
		const newLabel = String( StringHelper.lengthToRoundNumber( neighbourButton.label ) );
		dropdownView.buttonView.label = newLabel;
		dropdownView.isBeingEdited = false;
		this.highlightListBasedOnOption( dropdownView, newLabel );
		this.focus( dropdownView );
		return true;
	}

	onBackspace( currentValue, dropdownView, evt ) {
		EventHelper.stop( evt );
		if ( !Validator.isString( currentValue ) ) return false;
		currentValue = currentValue.substring( 0, currentValue.length - 1 );
		if ( !Validator.isString( currentValue ) )
			dropdownView.buttonView.label = "";
		else this.findAndHighlightOption( dropdownView, currentValue );
		return true;
	}

	onDigitKeyUp( currentValue, dropdownView, evt ) {
		if ( !Validator.isObject( dropdownView ) ||
			typeof currentValue != "string" ) return false;
		EventHelper.stop( evt );
		if ( currentValue.length > 1 ) return false;
		currentValue += evt.key;
		this.findAndHighlightOption( dropdownView, currentValue );
		return true;
	}

	findAndHighlightOption( dropdownView, sizePrefix ) {
		if ( !Validator.isString( sizePrefix ) ||
			!Validator.isObject( dropdownView ) ||
			!Validator.isArray( dropdownView.actualList, true ) ) return false;
		const matchingSize = dropdownView.actualList.find( size =>
			Validator.isString( size ) && size.startsWith( sizePrefix ) );
		if ( !Validator.isString( matchingSize ) ) return false;
		dropdownView.buttonView.label = sizePrefix;
		dropdownView.isBeingEdited = true;
		return this.highlightListBasedOnOption( dropdownView, matchingSize );
	}

	highlightListBasedOnOption( dropdownView, matchingSize ) {
		this.highlightDropdownListButton( dropdownView, matchingSize );
		return this.scrollToSelectedButton( dropdownView );
	}

	highlightDropdownListButton( dropdownView, matchingSize ) {
		if ( !Validator.isObject( dropdownView ) ) return false;
		const numericSize = StringHelper.lengthToRoundNumber( matchingSize );
		if ( !Validator.isNumber( numericSize ) ) return true;
		const roundUpSize = String( numericSize );
		const activeButton = this.activateDropdownListButton( dropdownView, roundUpSize );
		if ( !Validator.isObject( activeButton ) ) return false;
		dropdownView.activeButton = activeButton;
		dropdownView.matchingSize = roundUpSize;
		return true;
	}

	activateDropdownListButton( dropdownView, value = void 0 ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isFunction( dropdownView.activateDropdownListButton ) )
			return void 0;
		return dropdownView.activateDropdownListButton( value );
	}

	getFirstButtonFromListItem( listItem ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isFunction( dropdownView.getFirstButtonFromListItem ) )
			return void 0;
		return dropdownView.getFirstButtonFromListItem();
	}

	getDropDownListViewItems( dropdownView ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isFunction( dropdownView.getListItemViews ) ) return void 0;
		return dropdownView.getListItemViews();
	}

	scrollToSelectedButton( dropdownView ) {
		if ( !Validator.isObjectPath( dropdownView, "dropdownView.panelView" ) ||
			!Validator.isObject( dropdownView.activeButton ) ||
			!( dropdownView.activeButton.element instanceof HTMLElement ) ||
			!( dropdownView.panelView.element instanceof HTMLElement ) ) return false;
		const buttonElement = dropdownView.activeButton.element;
		const buttonRect = buttonElement.getBoundingClientRect();
		if ( !( buttonRect instanceof DOMRect ) ) return false;
		const panelElement = dropdownView.panelView.element;
		const panelRect = panelElement.getBoundingClientRect();
		if ( !( panelRect instanceof DOMRect ) ) return false;
		panelElement.scrollTop += buttonRect.top - panelRect.top;
		return true;
	}

	_addFocusKeyupReactions( dropdownView ) {
		if ( !Validator.isObjectPath( dropdownView, "dropdownView.panelView" ) ||
			!Validator.isFunction( dropdownView.panelView.on ) ) return false;

		if ( !Validator.isObject( this.editor.objects ) )
			this.editor.objects = {};
		if ( !Validator.isObject( this.editor.objects.dropdowns ) )
			this.editor.objects.dropdowns = {};
		if ( !Validator.isObject( this.editor.objects.dropdowns.fontSize ) )
			this.editor.objects.dropdowns.fontSize = { isOpen: false };

		// on close
		dropdownView.panelView.on( "change:isVisible", ( eventInfo, propertyName,
			isVisible, wasVisibleBefore ) => {
			if ( isVisible && !wasVisibleBefore ) return;
			// console.log( "font size close" );
			this.resetFlags( dropdownView );
			this.updateAll();
			if ( !dropdownView.fontFamilyOpen ) {
				GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( isVisible );
				this.blur( dropdownView );
			}
			dropdownView.fontSizeOpen = false;
		}, { priority: Number.MAX_SAFE_INTEGER } );

		// on open
		dropdownView.panelView.on( "change:isVisible", ( eventInfo, propertyName,
			isVisible, wasVisibleBefore ) => {
			if ( !isVisible || wasVisibleBefore ) return;
			// console.log( "font size open" );
			this.resetFlags( dropdownView );
			GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( isVisible );
			this.focus( dropdownView );
			this.getSelectedButtonAndSetActive( dropdownView );
			this.scrollToSelectedButton( dropdownView );
			dropdownView.fontSizeOpen = true;
		}, { priority: Number.MIN_SAFE_INTEGER } );

		return true;
	}

	update( dropdownView, onReady = false ) {
		if ( !Validator.isObject( dropdownView ) ||
			!Validator.isFunction( dropdownView.fullUpdate ) ) return void 0;
		return dropdownView.fullUpdate( onReady );
	}

	addDropDownFunctionality( dropdownView ) {
		this.setBasicDropdownFunctionality( dropdownView );

		if ( !Validator.isObject( dropdownView ) ) return false;
		const editor = this.editor;

		dropdownView.fullUpdate = ( onReady = false ) => {
			dropdownView.updateToLiveSize( onReady );
			return Validator.isObject( dropdownView.activateDropdownListButton() );
		}

		dropdownView.getLiveSize = ( onReady = false ) => {
			let fontSize;
			if ( Validator.isObjectPath( editor, "editor.objects.selection" ) &&
				Validator.isFunction( editor.objects.selection.getLiveValue ) )
				fontSize = editor.objects.selection.getLiveValue( "fontSize" );
			const liveFontSize = fontSize ||
				( onReady ? getLiveAttributeValue( editor, "fontSize" ) : void 0 ) ||
				getLiveNodeStyle().fontSize;
			if ( !liveFontSize ) return void 0;
			return roundUpValue( liveFontSize );
		}

		dropdownView.updateToLiveSize = ( onReady = false ) => {
			// editor.editing.view.focus();
			const liveFontSize = dropdownView.getLiveSize( onReady );
			if ( !liveFontSize ) return;
			dropdownView.buttonView.label = liveFontSize;
		}

		dropdownView.activateDropdownListButton = ( value = void 0 ) => {
			const activeValue = Validator.isValidNumber( value ) ||
				Validator.isString( value ) ? StringHelper.roundUpNumericLength( value ) :
				dropdownView.getLiveSize();
			return dropdownView.selectAndGetActiveButton( activeValue );
		};

		return true;
	}

}
