import Command from '@ckeditor/ckeditor5-core/src/command';
import first from '@ckeditor/ckeditor5-utils/src/first';

export const INDENT = 'indent';

export default class IndentCommand extends Command {

	refresh() {
		const firstBlock = first( this.editor.model.document.selection.getSelectedBlocks() );
		this.isEnabled = !!firstBlock && this._canBeIndented( firstBlock );
		this.value = ( this.isEnabled && firstBlock.hasAttribute( INDENT ) ) ? firstBlock.getAttribute( INDENT ) : '0pt';
	}

	execute( options = {} ) {
		const editor = this.editor;

		editor.model.change( writer => {
			const blocks = Array.from( editor.model.document.selection.getSelectedBlocks() )
				.filter( block => this._canBeIndented( block ) );
			setIndentOnSelection( editor, blocks, writer, options.value );
		} );
	}

	_canBeIndented( block ) {
		return this.editor.model.schema.checkAttribute( block, INDENT );
	}
}

function removeIndentFromSelection( blocks, writer ) {
	for ( const block of blocks ) {
		writer.removeAttribute( INDENT, block );
	}
}

function setIndentOnSelection( editor, blocks, writer, plusOrMinus ) {
	const maxIndent = editor.config.get( INDENT + '.options' )[
		editor.config.get( INDENT + '.options' ).length - 1 ];
	const minIndent = editor.config.get( INDENT + '.options' )[ 0 ];
	blocks.forEach( block => {
		let currentIndent = block.getAttribute( INDENT ) || "0pt";
		const removeIndent = ( currentIndent === maxIndent && plusOrMinus == "p" ) ||
			( currentIndent === minIndent && plusOrMinus == "m" ) || !plusOrMinus;
		currentIndent != maxIndent && plusOrMinus == "p" ?
			currentIndent = String( parseInt( currentIndent
				.replace( /[\.\,]+\w*/g, "" ).replace( /\D+/g, "" ) ) + 36 )
			.concat( "pt" ) : void 0;
		currentIndent != minIndent && plusOrMinus == "m" ?
			currentIndent = String( parseInt( currentIndent
				.replace( /[\.\,]+\w*/g, "" ).replace( /\D+/g, "" ) ) - 36 )
			.concat( "pt" ) : void 0;
		!removeIndent ? writer.setAttribute( INDENT, currentIndent, block ) : void 0;
	} );
}
