export const red = "B71C1C,C62828,D32F2F,E53935,F44336,EF5350,E57373,EF9A9A,";
export const pink = "880E4F,AD1457,C2185B,D81B60,E91E63,EC407A,F06292,F48FB1";
export const purple = "4A148C,6A1B9A,7B1FA2,8E24AA,9C27B0,AB47BC,BA68C8,CE93D8,";
export const deepPurple = "";
export const indigo = "";
export const blue = "0D47A1,1565C0,1976D2,1E88E5,2196F3,42A5F5,64B5F6,90CAF9,";
export const lightBlue = "";
export const cyan = "006064,00838F,0097A7,00ACC1,00BCD4,26C6DA,4DD0E1,80DEEA,";
export const teal = "";
export const green = "1B5E20,2E7D32,388E3C,43A047,4CAF50,66BB6A,81C784,A5D6A7,";
export const lightGreen = "";
export const lime = "";
export const yellow = "F57F17,F9A825,FBC02D,FDD835,FFEB3B,FFEE58,FFF176,FFF59D,";
export const amber = "";
export const orange = "E65100,EF6C00,F57C00,FB8C00,FF9800,FFA726,FFB74D,FFCC80,";
export const deepOrange = "";
export const brown = "3E2723,4E342E,5D4037,6D4C41,795548,8D6E63,A1887F,BCAAA4,";
export const blueGray = "";
export const gray = "000000,606060,808080,a0a0a0,c0c0c0,d0d0d0,e0e0e0,ffffff,";
export const touchColors = "D32F2F,F57C00,FBC02D,1976D2,0097A7,388E3C,7B1FA2,ffffff,000000";
export const bckgColors = "D8101F,EB7D2B,F9E91C,5AC0EF,359BB3,8ABC40,9A75B1,ffffff,000000";

export const ckeColorList = colorsStringToList( gray + brown + red + orange +
	yellow + green + cyan + blue + purple + pink );

export const touchColorList = colorsStringToList( touchColors );

export const bckgColorList = colorsStringToList( bckgColors );

function colorsStringToList( colorsString ) {
	if ( !colorsString || typeof colorsString != "string" || colorsString.indexOf( "," ) < 0 ) {
		return [];
	}
	colorsString = formatColorsString( colorsString );
	let colorsList = colorsString.split( "," );
	for ( var i = 0; i < colorsList.length; i++ ) {
		colorsList[ i ] = colorsList[ i ].replace( /[^#\w\d]+/g, "" );
		if ( colorsList[ i ].indexOf( "#" ) < 0 ) {
			colorsList[ i ] = "#" + colorsList[ i ];
		}
		if ( colorsList[ i ].length != 4 && colorsList[ i ].length != 7 ) {
			colorsList.splice( i, 1 );
		}
	}
	return colorsList;
}

function formatColorsString( colorsString ) {
	if ( !colorsString || typeof colorsString != "string" || colorsString.indexOf( "," ) < 0 ) {
		return "";
	}
	colorsString = colorsString.replace( /[^#,\w\d]+/g, "" ).replace( /,{2,}/g, "," ).replace( /#{2,}/g, "#" );
	if ( colorsString.charAt( 0 ) == "," ) {
		colorsString = colorsString.substring( 1, colorsString.length - 1 );
	}
	if ( colorsString.charAt( colorsString.length - 1 ) == "," ) {
		colorsString = colorsString.substring( 0, colorsString.length - 2 );
	}
	if ( colorsString.indexOf( "#" ) < 0 ) {
		colorsString = "#" + colorsString.replace( /,/g, ",#" );
	}
	return colorsString;
}
