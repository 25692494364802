import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaSpacingCommand from './pisaspacingcommand';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
// import EmptyView from '../pisadropdown/emptyview';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { addClasses, defineButton, setButton, closeDropdownOnBlur, getGlobalLiveValue } from '../utils';
import { generateNumberInput } from '../pisatablecell/pisacellui';
import { PISASPACING } from './pisaspacingediting';
import { spacingIcon } from '../icons';
import { addPanelViewHeightHandler } from '../pisafontfamily/pisafontfamilyui';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const BUTTONS = [ "1", "1.25", "1.5", "2", "2.5", "3" ];

export default class PisaSpacingUI extends Plugin {

	init() {
		const editor = this.editor;

		editor.commands.add( PISASPACING, new PisaSpacingCommand( editor ) );

		const command = editor.commands.get( PISASPACING );

		editor.ui.componentFactory.add( PISASPACING, locale => {
			const dropdownView = createDropdown( locale );
			dropdownView.set( 'panelPosition', 'se' );
			defineButton( dropdownView.buttonView, spacingIcon,
				editor.objects.tooltips.getT( PISASPACING ) );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			dropdownView.bind( 'isEnabled' ).to( command );
			closeDropdownOnBlur( dropdownView );
			addPanelViewHeightHandler( editor, dropdownView );

			dropdownView.buttonView.on( "execute", () => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
			} );

			let numberInput = generateNumberInput( editor, {
				addTextInput: true,
				max: 10,
				command: PISASPACING,
				inputKey: "value",
				options: { source: "range" },
				inputCss: [ "" ]
			} );

			editor.once( "ready", () => {
				numberInput.element.value = "1";
			} );

			editor.editing.view.document.on( 'selectionChangeDone', () => {
				let liveSpacing = getGlobalLiveValue( editor, PISASPACING ) || "1";
				numberInput.element.value = liveSpacing;
			} );

			dropdownView.panelView.children.add( numberInput );

			Array.from( BUTTONS ).forEach( button => {
				let buttonView = setButton( "", String( "× " ).concat( button.replace( "em", "" ) ),
					PISASPACING, { value: button }, editor );
				buttonView.on( "execute", () => {
					numberInput.element.value = button;
				} );
				dropdownView.panelView.children.add( buttonView );
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, PISASPACING );

			return dropdownView;
		} );
	}

}

// function addAutofocus( dropdownView, labeledInput ) {
// 	dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, value, oldValue ) => {
// 		if ( !value ) return;
// 		labeledInput.inputView.element.autofocus = true;
// 	} );
// }
//
// function createGridView( buttonsList, command, editor ) {
// 	let divGridView = new EmptyView( [ 'psa-dropdown-list-container' ] );
// 	addButtonsToGrid( buttonsList, divGridView, command, editor );
// 	return divGridView;
// }
//
// function addButtonsToGrid( list, gridView, command, editor ) {
// 	for ( let button of list ) {
// 		let childView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
// 		let buttonView = setButton( "", "× " + button.replace("em", ""), command, { value: button }, editor );
// 		childView.items.add( buttonView );
// 		gridView.items.add( childView );
// 	}
// }
//
// function updateInputOnButtonClick( labeledInput, gridView ) {
// 	let range = null;
// 	let input = null;
// 	labeledInput.items._items.forEach( item => {
// 		range = item.element && item.element.type == "range" ? item : null;
// 		input = item.element && item.element.type == "number" ? item : null;
// 	} );
// 	gridView.items._items.forEach( childView => {
// 		childView.items._items.forEach( buttonView => {
// 			let value = buttonView.label || buttonView.tooltip || buttonView._tooltipString;
// 			value = value.replace(/[^\d\.]+/g, "");
// 			buttonView.on( "execute", () => {
// 				range && range.element ? range.element.value = value : void 0;
// 				input && input.element ? input.element.value = value : void 0;
// 			} );
// 		} );
// 	} );
// }
