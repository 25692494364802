import Command from '@ckeditor/ckeditor5-core/src/command';
import { INSERT_IMAGE } from './pisaimageui';
import { fire } from '../utils';

export default class PisaInsertImageCommand extends Command {

	constructor( editor ) {
		super( editor, INSERT_IMAGE );
	}

	execute( options = {} ) {
		const editor = this.editor;
		// options.event = "pisaInsertImage";
		fire( editor, INSERT_IMAGE, options );
		// editor.executeIf( "pisaFire", options );
	}
}
