import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LabeledInputView from '@ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import EmptyView from '../pisadropdown/emptyview';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
// import LabelView from '@ckeditor/ckeditor5-ui/src/label/labelview';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { addClasses, closeDropdownOnBlur, createButton, defineButton, setButton, setInputForm, bindInputToCommand, createLabel, getFocusElement } from '../utils';
import { addAutofocus } from './utils';
import { PISA_RESIZE_IMAGE } from './pisaimageresizecommand';
import PisaResizeImageCommand from './pisaimageresizecommand';
import { resizeIcon, increaseImageIcon, decreaseImageIcon, deleteImageIcon } from '../icons';
import EmptyInputView from '../pisainput/pisainputview';
import { addPanelViewHeightHandler } from '../pisafontfamily/pisafontfamilyui';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const INCREASE_IMAGE = "pisaIncreaseImage";
export const DECREASE_IMAGE = "pisaDecreaseImage";
export const DELETE_IMAGE = "pisaDeleteImage";
const BUTTONS = [ "33%", "50%", "66%", "75%", "125%", "132%", "150%", "200%", "300%" ];

export default class PisaImageResize extends Plugin {

	init() {
		const editor = this.editor;
		const command = editor.commands.get( "pisaInsertImage" );
		let balloonPanel = editor.plugins._plugins.get( "PisaPanelBalloons" );

		editor.commands.add( PISA_RESIZE_IMAGE, new PisaResizeImageCommand( editor ) );

		editor.ui.componentFactory.add( PISA_RESIZE_IMAGE, locale => {

			const dropdownView = createDropdown( locale );
			dropdownView.set( 'panelPosition', 'se' );
			const inputForm = createInput( editor );
			defineButton( dropdownView.buttonView, resizeIcon,
				editor.objects.tooltips.getT( PISA_RESIZE_IMAGE ) );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			addAutofocus( dropdownView, inputForm, editor );
			closeDropdownOnBlur( dropdownView );
			addPanelViewHeightHandler( editor, dropdownView );

			dropdownView.panelView.children.add( inputForm, 0 );

			dropdownView.buttonView.on( "execute", () => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
			} );

			Array.from( BUTTONS ).forEach( button => {
				let buttonView = setButton( "", button, PISA_RESIZE_IMAGE, { value: button }, editor );
				dropdownView.panelView.children.add( buttonView );
			} );

			if ( !!command && typeof command == "object" ) dropdownView.bind( 'isEnabled' ).to( command );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, PISA_RESIZE_IMAGE );

			return dropdownView;
		} );

		editor.ui.componentFactory.add( INCREASE_IMAGE, locale => {
			let increaseImageButton = createButton( increaseImageIcon,
				editor.objects.tooltips.getT( INCREASE_IMAGE ), editor.locale );
			increaseImageButton.on( "execute", () => {
				// editor.lastSelection = new Selection( editor.model.document.selection, 0 );
				editor.executeIf( PISA_RESIZE_IMAGE, { value: "105%" }, false );
				// editor.model.document.selection._setTo( editor.lastSelection );
			} );
			if ( !balloonPanel ) return increaseImageButton;
			increaseImageButton.on( "execute", () => {
				balloonPanel.displayOnLastPosition( "image" );
			}, { priority: "lowest" } );

			editor.objects.focus._addExecuteFocus( increaseImageButton );

			return increaseImageButton;
		} );

		editor.ui.componentFactory.add( DECREASE_IMAGE, locale => {
			let decreaseImageButton = createButton( decreaseImageIcon,
				editor.objects.tooltips.getT( DECREASE_IMAGE ), editor.locale );
			decreaseImageButton.on( "execute", () => {
				// editor.lastSelection = new Selection( editor.model.document.selection, 0 );
				editor.executeIf( PISA_RESIZE_IMAGE, { value: "95%" }, false );
				// editor.model.document.selection._setTo( editor.lastSelection );
			} );
			if ( !balloonPanel ) return decreaseImageButton;
			decreaseImageButton.on( "execute", () => {
				balloonPanel.displayOnLastPosition( "image" );
			}, { priority: "lowest" } );

			editor.objects.focus._addExecuteFocus( decreaseImageButton );

			return decreaseImageButton;
		} );

		editor.ui.componentFactory.add( DELETE_IMAGE, locale => {
			let deleteImageButton = createButton( deleteImageIcon,
				editor.objects.tooltips.getT( DELETE_IMAGE ), editor.locale );
			deleteImageButton.on( "execute", () => {
				editor.model.change( writer => {
					writer.remove( editor.model.document.selection._selection.getFirstRange() );
				} );
				editor.balloons.hideAll();
			} );

			editor.objects.focus._addExecuteFocus( deleteImageButton );

			return deleteImageButton;
		} );
	}

}

function createInput( editor ) {
	return setInputForm( editor, {
		placeholder: '10%',
		// placeholder: 'Percent (40%, 160%)',
		// cssClasses: [ 'pisa-input' ],
		command: PISA_RESIZE_IMAGE,
		inputKey: "value"
	} );
}

function createGridView( buttonsList, editor ) {
	let divGridView = new EmptyView( [ 'psa-dropdown-list-container' ] );
	addButtonsToGrid( buttonsList, divGridView, editor, PISA_RESIZE_IMAGE );
	return divGridView;
}

function addButtonsToGrid( list, gridView, editor, command ) {
	list.forEach( button => {
		let childView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
		let buttonView = setButton( "", button, command, { value: button }, editor );
		childView.items.add( buttonView );
		gridView.items.add( childView );
	} );
}
