import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/toolbar.css';
import { arrowDownIcon, moreToolbarIcon, lessToolbarIcon, threeDots } from '../icons';

export const PISA_TOOLBAR = "pisaToolbar";
const STATES = [ "unknown", "full", "compact", "hidden" ];
const TOOLBAR_EVENT = "pisaToolbarChanged";

export const SHOW_MORE_TOOLBAR_OPTIONS = "pisaShowMoreToolbar";
export const SHOW_LESS_TOOLBAR_OPTIONS = "pisaShowLessToolbar";

export default class PisaToolbar extends Plugin {

	static get pluginName() {
		return 'PisaToolbar';
	}

	static get requires() {
		return [];
	}

	init( defaultState ) {
		let editor = this.editor;
		editor.objects = editor.objects || {};
		editor.objects.toolbar = this;

		this._state = STATES[ 0 ];
		this.changeButtons = [];
		this.tooltips = {
			showMore: editor.objects.tooltips.getT( SHOW_MORE_TOOLBAR_OPTIONS ),
			showLess: editor.objects.tooltips.getT( SHOW_LESS_TOOLBAR_OPTIONS )
		}
	}

	_changeStateTo( newValue ) {
		if ( !newValue || typeof newValue != "string" ||
			STATES.indexOf( newValue ) < 1 ) return void 0;
		if ( this._state == newValue ) return false;
		this._state = newValue;
		return true;
	}

	set state( newValue ) {
		console.warn( "The toolbar state can not be directly set." );
	}

	get state() {
		return this._state;
	}

	setFull() {
		this._changeStateTo( STATES[ 1 ] );
	}

	get isFull() {
		return this.state == STATES[ 1 ];
	}

	setCompact() {
		this._changeStateTo( STATES[ 2 ] );
	}

	get isCompact() {
		return this.state == STATES[ 2 ];
	}

	setHidden() {
		this._changeStateTo( STATES[ 3 ] );
	}

	get isHidden() {
		return this.state == STATES[ 3 ];
	}

	hide() {
		if ( this.isHidden ) return;
		if ( !this._hide() ) return;
		this.setHidden();
	}

	_hide() {
		return this.hideToolbar();
	}

	showCompact() {
		if ( this.isCompact ) return;
		if ( !this._showCompact() ) return;
		this.setCompact();
	}

	_showCompact() {
		const toolbarItems = this.toolbarItems;
		if ( !toolbarItems ) return false;
		// make toolbar element visible
		this.showToolbar();
		const index = this._indexOfGreatestXElement;
		if ( typeof index != "number" ) return false;
		// show everything before index (end-exclusive)
		for ( let i = 0; i < index; i++ ) {
			this._removeStyling( toolbarItems[ i ] );
			// this._showView( toolbarItems[ i ] );
		}
		// hide everything after index (start-inclusive)
		for ( let i = index; i < toolbarItems.length; i++ ) {
			this._hideView( toolbarItems[ i ] );
		}
		// show or hide change button
		if ( !this._allButtonsShown ) this.showChangeButton();
		// if ( index == this.toolbarItems.length - 1 )
		// 	this.showFull();
		// !this._allButtonsShown ? this.hideChangeButton() : this.showChangeButton();
		return true;
	}

	showFull() {
		if ( this.isFull ) return;
		if ( !this._showFull() ) return;
		this.setFull();
	}

	_showFull() {
		const toolbarItems = this.toolbarItems;
		if ( !toolbarItems ) return false;
		// make toolbar element visible
		this.showToolbar();
		// show everything
		for ( let i = 0; i < toolbarItems.length; i++ ) {
			this._removeStyling( toolbarItems[ i ] );
			// this._showView( toolbarItems[ i ] );
		}
		this._allButtonsShown ? this.hideChangeButton() : this.showChangeButton();
		return true;
	}

	get toolbar() {
		const editor = this.editor;
		if ( !editor.ui || typeof editor.ui != "object" || !editor.ui.view ||
			typeof editor.ui.view != "object" || !editor.ui.view.toolbar ||
			typeof editor.ui.view.toolbar != "object" ) return void 0;
		return editor.ui.view.toolbar;
	}

	get toolbarElement() {
		const toolbar = this.toolbar;
		if ( !toolbar || typeof toolbar != "object" || !toolbar.element ||
			!( toolbar.element instanceof HTMLElement ) ) return void 0;
		return toolbar.element;
	}

	get toolbarItems() {
		const editor = this.editor;
		if ( !editor.ui || typeof editor.ui != "object" || !editor.ui.view ||
			typeof editor.ui.view != "object" || !editor.ui.view.toolbar ||
			typeof editor.ui.view.toolbar != "object" ||
			!editor.ui.view.toolbar.items ||
			typeof editor.ui.view.toolbar.items != "object" ||
			!( editor.ui.view.toolbar.items._items instanceof Array ) ||
			editor.ui.view.toolbar.items._items.length < 1 ) return void 0;
		return editor.ui.view.toolbar.items._items;
	}

	get toolbarChangeButton() {
		const toolbarItems = this.toolbarItems;
		if ( !toolbarItems ) return void 0;
		let changeButton = toolbarItems[ toolbarItems.length - 1 ];
		return !!changeButton && typeof changeButton == "object" ?
			changeButton : void 0;
	}

	get lastIndexOnFirstLine() {
		const toolbarItems = this._visibleToolbarItems;
		if ( !toolbarItems ) return void 0;
		const toolbarElement = this.toolbarElement;
		if ( !toolbarElement ) return void 0;
		const changeButton = this.toolbarChangeButton;
		if ( !changeButton ) return void 0;
		const workingWidth = toolbarElement.clientWidth - changeButton.element.offsetWidth;
		if ( typeof workingWidth != "number" || workingWidth < 1 ) return void 0;
		let summarWidth = 0;
		for ( let i = 0; i < toolbarItems.length - 1; i++ ) {
			summarWidth += toolbarItems[ i ].element.offsetWidth + 4;
			// TODO check if it´s i or i - 1?
			if ( summarWidth >= workingWidth ) return i;
		}
		return ( toolbarItems.length - 1 );
	}

	get _indexOfGreatestXElement() {
		const toolbarItems = this.toolbarItems;
		if ( !toolbarItems ) return void 0;
		let greatestIndex = 0;
		let greatestX = 0;
		for ( let i = 0; i < toolbarItems.length; i++ ) {
			let x = this.getToolbarItemOffsetLeft( toolbarItems[ i ] );
			if ( typeof x != "number" || x == greatestX || x <= 0 ) continue;
			// break to make sure it takes the element from first line
			if ( x < greatestX && x > 0 ) break;
			greatestX = x;
			greatestIndex = i;
		}
		// separators are not wide enough to be replaced by the change button
		if ( toolbarItems[ greatestIndex ].element.className
			.indexOf( "ck-toolbar__separator" ) < 0 ) return greatestIndex;
		if ( greatestIndex <= 0 ) return 0;
		greatestIndex--;
		while ( greatestIndex > 0 &&
			this._isDomNodeHidden( toolbarItems[ greatestIndex ].element ) ) {
			greatestIndex--;
		}
		return greatestIndex;
	}

	getToolbarItemOffsetLeft( item ) {
		if ( !item || typeof item != "object" ) return void 0;
		return this._getNodeLeftOrX( item.element );
	}

	_getNodeLeftOrX( htmlNodeElement ) {
		if ( !htmlNodeElement || !( htmlNodeElement instanceof HTMLElement ) ||
			typeof htmlNodeElement.getBoundingClientRect != "function" ) return void 0;
		let rect = htmlNodeElement.getBoundingClientRect();
		if ( !rect || typeof rect != "object" ||
			( typeof rect.x != "number" && typeof rect.left != "number" ) ) return void 0;
		return typeof rect.x == "number" ? rect.x : rect.left > 0 ? rect.left : 0;
	}

	_showView( view ) {
		view.isVisible = true;
		if ( !view.element ) return false;
		if ( view.element.className.indexOf( "disabled" ) >= 0 &&
			view.label != "Undo" && view.label != "Redo" ) return false;
		view.element && view.element.nodeName && view.element.nodeName == "BUTTON" ?
			view.element.setAttribute( "style", "visibility:visible;display:inline-flex;" ) :
			view.element.setAttribute( "style", "visibility:visible;display:inline-block;" );
		view.element.removeAttribute( "invisible" );
		return true;
	}

	_removeStyling( view ) {
		view.isVisible = true;
		if ( !view.element ) return false;
		if ( view.element.className.indexOf( "disabled" ) >= 0 &&
			view.label != "Undo" && view.label != "Redo" ) return false;
		view.element.removeAttribute( "style" );
		view.element.removeAttribute( "invisible" );
		return true;
	}

	_hideView( view ) {
		view.isVisible = false;
		if ( !view.element ) return false;
		view.element.setAttribute( "style", "visibility:hidden;display:none;" );
		view.element.invisible = true;
		return true;
	}

	showToolbar() {
		let toolbar = this.toolbar;
		if ( !toolbar || toolbar.isVisible ) return false;
		this._showToolbarElement();
		return true;
	}

	_showToolbarElement() {
		let toolbar = this.toolbar;
		if ( !toolbar ) return false;
		let toolbarElement = this.toolbarElement;
		if ( !toolbarElement ) return false;
		toolbar.isVisible = true;
		toolbarElement.setAttribute( "style", "" );
		toolbarElement.removeAttribute( "style" );
		toolbarElement.invisible = false;
		toolbarElement.removeAttribute( "invisible" );
		return true;
	}

	hideToolbar() {
		let toolbar = this.toolbar;
		if ( !toolbar ) return false;
		// if ( !toolbar || !toolbar.isVisible ) return false;
		this._hideToolbarElement();
		return true;
	}

	_hideToolbarElement() {
		let toolbar = this.toolbar;
		if ( !toolbar ) return false;
		let toolbarElement = this.toolbarElement;
		if ( !toolbarElement ) return false;
		toolbar.isVisible = false;
		toolbarElement.setAttribute( "style", "visibility:hidden;display:none;" );
		toolbarElement.invisible = true;
		return true;
	}

	refresh() {
		if ( this.isHidden ) return;
		if ( this.isFull ) {
			this._allButtonsShown ? this.hideChangeButton() : this.showChangeButton();
		}
		if ( !this.isCompact ) return
		this._showFull();
		this._showCompact();
		this.editor.fire( "pisaToolbarRefresh" );
	}

	fireChange() {
		this.editor.executeIf( "pisaFire", { event: TOOLBAR_EVENT } );
	}

	toggle() {
		if ( this.isHidden ) return;
		let toolbar = this.toolbar;
		if ( toolbar && toolbar.isVisible == false ) return;
		if ( this.isCompact ) {
			this.showFull();
			return;
		}
		if ( this.isFull ) {
			this.showCompact();
			return;
		}
		const toolbarItems = this.toolbarItems;
		if ( toolbarItems[ toolbarItems.length - 2 ].isVisible ) {
			this.showCompact();
			return;
		}
		this.showFull();
	}

	toggleButton() {
		this.changeButtons.forEach( button => {
			this._changeButtonTo( button, button.tooltip == this.tooltips.showLess );
		} );
	}

	syncButtonWithState() {
		this.changeButtons.forEach( button => {
			this._changeButtonTo( button, this.isCompact );
		} );
	}

	_changeButtonTo( button, toCompact = true ) {
		if ( !button || typeof button != "object" ) return;
		if ( button.icon )
			button.icon = toCompact ? threeDots : lessToolbarIcon;
		if ( button.tooltip )
			button.tooltip = toCompact ? this.tooltips.showMore : this.tooltips.showLess;
	}

	syncToolbarWithState() {
		if ( this.isHidden ) {
			this._hide();
			return;
		};
		let toolbar = this.toolbar;
		if ( toolbar && toolbar.isVisible == false ) {
			this.hide();
			return;
		};
		if ( this.isCompact ) {
			this._showFull();
			this._showCompact();
			return;
		}
		if ( this.isFull ) {
			this._showFull();
			return;
		}
		const toolbarItems = this.toolbarItems;
		if ( toolbarItems[ toolbarItems.length - 2 ].isVisible ) {
			this._showFull();
			this.showCompact();
			return;
		}
		this.showFull();
	}

	hideChangeButton() {
		this.changeButtons.forEach( button => {
			this._hideView( button );
		} );
	}

	showChangeButton() {
		this.changeButtons.forEach( button => {
			this._removeStyling( button )
			// this._showView( button );
		} );
	}

	get _visibleToolbarItems() {
		const toolbarItems = this.toolbarItems;
		if ( !toolbarItems || !( toolbarItems instanceof Array ) ) return [];
		let visibleItems = [ ...toolbarItems.filter(
			item => !this._isDomNodeHidden( item.element ) ) ];
		return visibleItems;
	}

	_isDomNodeHidden( domElement ) {
		if ( !( domElement instanceof HTMLElement ) ) return false;
		// if ( domElement.offsetParent === null ) return true;
		const style = window.getComputedStyle( domElement );
		if ( !style || typeof style != "object" ) return false;
		if ( style.display == "none" ) return true;
		if ( style.visibility == "hidden" ) return true;
		return false;
	}

	get _allButtonsShown() {
		if ( this.isHidden ) return false;
		let farestIndex = this._indexOfGreatestXElement;
		if ( typeof farestIndex != "number" ) return false;
		let allItems = this.toolbarItems;
		if ( !allItems || !( allItems instanceof Array ) ) return false;
		return farestIndex + 3 >= allItems.length;
	}

}
