import Warner from '../pisautils/warner';

const LOG_CHANGES = false;
const ADD_TIMESTAMP = true;
const ADD_COUNTER = false;
const TRACE = false;

export default class PlaceholdersUI {

	constructor( parent ) {

		parent.placeholderSelected = false;
		parent.placeholderDeleted = false;
		parent.selectAll = false;
		parent.mouseIsDown = false;

		this.counter = 0;

		parent.setPlaceholderSelected = ( newValue, returnValue = void 0 ) => {
			newValue = !!newValue;
			parent.placeholderSelected = newValue;
			this._log( `Placeholder selected: ${ newValue }` );
			return returnValue;
		};

		parent.setPlaceholderDeleted = ( newValue, returnValue = void 0 ) => {
			newValue = !!newValue;
			parent.placeholderDeleted = newValue;
			this._log( `Placeholder deleted: ${ newValue }` );
			return returnValue;
		};

		parent.setMouseIsDown = ( newValue, returnValue = void 0 ) => {
			newValue = !!newValue;
			parent.mouseIsDown = newValue;
			this._log( `Mouse is down: ${ newValue }` );
			return returnValue;
		};

		parent.setSelectAll = ( newValue, returnValue = void 0 ) => {
			newValue = !!newValue;
			parent.selectAll = newValue;
			this._log( `Select all: ${ newValue }` );
			// if ( LOG_CHANGES )
			// 	console.log( ( ( ADD_COUNTER ? String( this.counter++ ) + ": " :
			// 			ADD_TIMESTAMP ? String( Date.now() ) + " " : "" ) +
			// 		`Select all: ${ newValue }` ) );
			return returnValue;
		};

		parent.cleanup = ( returnValue = void 0, newPlaceholderSelectedValue = false,
			newPlaceholderDeletedValue = false, newSelectAllValue = false ) => {
			if ( typeof newPlaceholderSelectedValue == "boolean" )
				parent.setPlaceholderSelected( newPlaceholderSelectedValue );
			if ( typeof newPlaceholderDeletedValue == "boolean" )
				parent.setPlaceholderDeleted( newPlaceholderDeletedValue );
			if ( typeof newSelectAllValue == "boolean" )
				parent.setSelectAll( newSelectAllValue );
			return returnValue;
		};

	}

	_log( text, trace = TRACE ) {
		return Warner.outputIf( LOG_CHANGES, void 0, {
			trace: trace,
			collapse: false,
			text: !ADD_COUNTER ? text : String( this.counter++ ) + ":\n" + text,
			pluginName: "PisaPlaceholderUI",
			color: 5,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

}
