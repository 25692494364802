import LinkCommand from '@ckeditor/ckeditor5-link/src/linkcommand';
import Validator from '../pisautils/validator';
import SelectionHelper from '../pisautils/selectionhelper';
import { IMAGE_CLICKED } from '../pisaimage/pisaimageui';

export const LINK_HREF_ATTRIBUTE = "linkHref";

export default class PisaExtendedLinkCommand extends LinkCommand {

	/**
	 * @observable
	 * @readonly
	 * @override
	 */
	constructor( editor ) {
		super( editor );
		editor.on( IMAGE_CLICKED, ( eventInfo, options ) => {
			this.isEnabled = true;
		}, { priority: Number.MIN_SAFE_INTEGER } );
	}

	/**
	 * @inheritDoc
	 * @see {@link module:@ckeditor/ckeditor5-link/src/linkcommand} LinkCommand
	 * @override
	 * made sure when only an image is selected the link command is enabled
	 */
	refresh() {
		const editor = this.editor;
		const linkHref = Validator
			.isObjectPath( editor, "editor.model.document.selection" ) ?
			editor.model.document.selection.getAttribute( LINK_HREF_ATTRIBUTE ) :
			void 0;
		if ( this.isObjectLink( linkHref ) && this.refreshOnObjectLink( linkHref ) ) return;
		const pisaImage = getSelectedPisaImage( editor );
		if ( !Validator.isObject( pisaImage ) ) return super.refresh();
		this.isEnabled = true;
		const imageHref = pisaImage._attrs.get( LINK_HREF_ATTRIBUTE );
		if ( !Validator.isString( imageHref ) ) {
			this.value = void 0;
			return;
		}
		if ( this.isObjectLink( imageHref ) &&
			this.refreshOnObjectLink( imageHref ) ) return;
		this.value = imageHref;
	}

	refreshOnObjectLink( linkHref ) {
		const editor = this.editor;
		this.isEnabled = true;
		const selection =
			Validator.isObjectPath( editor, "editor.model.document" ) ?
			editor.model.document.selection : void 0;
		if ( !SelectionHelper.couldBeSelection( selection ) ) return false;
		const textNode = this._getTextNode( selection.anchor, linkHref ) ||
			this._getTextNode( selection.focus, linkHref );
		if ( !textNode ) return false;
		this.value = this._collectFullValue( textNode );
		return true;
	}

	_getTextNode( position, linkHref ) {
		if ( !SelectionHelper.isPosition( position ) ) return void 0;
		if ( Validator.couldBe( position.textNode, "Text" ) )
			return position.textNode;
		if ( !Validator.isString( linkHref ) ) return void 0;
		if ( Validator.couldBe( position.nodeBefore, "Text" ) &&
			position.nodeBefore._attrs.get( LINK_HREF_ATTRIBUTE ) === linkHref )
			return position.nodeBefore;
		if ( Validator.couldBe( position.nodeAfter, "Text" ) &&
			position.nodeAfter._attrs.get( LINK_HREF_ATTRIBUTE ) === linkHref )
			return position.nodeAfter;
		return void 0;
	}

	_collectFullValue( originalTextNode ) {
		if ( !Validator.couldBe( originalTextNode, "Text" ) ) return "";
		const currentTextNodeHref =
			originalTextNode._attrs.get( LINK_HREF_ATTRIBUTE );
		let preliminaryValue = originalTextNode._data;
		let textNode = originalTextNode;
		while ( Validator.couldBe( textNode.previousSibling, "Text" ) ) {
			textNode = textNode.previousSibling;
			if ( !Validator.isString( textNode._data ) ) break;
			if ( textNode._attrs.get( LINK_HREF_ATTRIBUTE ) != currentTextNodeHref )
				break;
			preliminaryValue = textNode._data + preliminaryValue;
		}
		textNode = originalTextNode;
		while ( Validator.couldBe( textNode.nextSibling, "Text" ) ) {
			textNode = textNode.nextSibling;
			if ( !Validator.isString( textNode._data ) ) break;
			if ( textNode._attrs.get( LINK_HREF_ATTRIBUTE ) != currentTextNodeHref )
				break;
			preliminaryValue = preliminaryValue + textNode._data;
		}
		return preliminaryValue;
	}

	isObjectLink( linkHref ) {
		if ( !Validator.isObjectPath( this.editor, "editor.objects.linkBalloon" ) )
			return false;
		return this.editor.objects.linkBalloon.isObjectLink( linkHref );
	}

}

export function onlyPisaImageSelected( editor ) {
	const pisaImage = getSelectedPisaImage( editor );
	return Validator.isObject( pisaImage );
}

export function getSelectedPisaImage( editor ) {
	if ( !Validator.isObjectPath( editor, "editor.model.document.selection" ) )
		return false;
	const currentSelection = editor.model.document.selection;
	if ( currentSelection.isCollapsed ) return void 0;
	const selectionBackward =
		SelectionHelper.isSelectionBackward( currentSelection );
	const startExtremity = selectionBackward ? "focus" : "anchor";
	const startElement =
		Validator.isObjectPath( currentSelection,
			`currentSelection.${ startExtremity }.nodeAfter` ) &&
		currentSelection[ startExtremity ].nodeAfter.name == "pisaImage" ?
		currentSelection[ startExtremity ].nodeAfter : void 0;
	if ( !Validator.isObject( startElement ) ) return void 0;
	const endExtremity = selectionBackward ? "anchor" : "focus";
	const endElement =
		Validator.isObjectPath( currentSelection,
			`currentSelection.${ endExtremity }.nodeBefore` ) &&
		currentSelection[ endExtremity ].nodeBefore.name == "pisaImage" ?
		currentSelection[ endExtremity ].nodeBefore : void 0;
	return startElement === endElement ? startElement : void 0;
}
