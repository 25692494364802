import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import EmptyView from '../pisadropdown/emptyview';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { defineButton, createInputForm, htmlToDocumentFragment, closeDropdownOnBlur, addClasses, createTextButton } from '../utils';
import randomIcon from '../../theme/icons/0-plain-text.svg'

export const SET_DATA = "pisaSetData";
export const DATA_KEYWORD_INPUT = "pisaDataKeywordInput";

export default class PisaSetData extends Plugin {

	init() {
		const editor = this.editor;

		this.editor.ui.componentFactory.add( SET_DATA, locale => {

			const dropdownView = createDropdown( locale );
			const inputForm = createInputForm( editor,
				editor.objects.tooltips.getT( DATA_KEYWORD_INPUT ), "", [ 'pisa-input' ] );
			const gridView = createGridView( inputForm, editor );

			dropdownView.panelView.children.add( inputForm, 0 );
			dropdownView.panelView.children.add( gridView, 1 );

			defineButton( dropdownView.buttonView, randomIcon,
				editor.objects.tooltips.getT( SET_DATA ) );
			addClasses( dropdownView, [ 'pisa-hidden' ] );
			closeDropdownOnBlur( dropdownView );

			return dropdownView;
		} );
	}
}

function createGridView( inputForm, editor ) {
	let divGridView = new EmptyView( [ 'psa-dropdown-list-container' ] );
	divGridView.items.add( createSetButton( inputForm, editor ) );
	divGridView.items.add( createRefreshButton( inputForm, editor ) );
	divGridView.items.add( createGetHtmlButton( editor ) );
	return divGridView;
}

function createSetButton( inputForm, editor ) {
	let setView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let setButton = createTextButton( "set", editor.locale );
	setButton.on( "execute", () => {
		let data = inputForm.inputView.element.value;
		if ( data != "" ) {
			// data = htmlToDocumentFragment( data, editor );
			// editor.execute( "pisaDeleteAll" );
			// editor.model.insertContent( data );
			editor.setData( data );
		}
	} );
	setView.items.add( setButton );
	return setView;
}

function createRefreshButton( inputForm, editor ) {
	let refreshView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let refreshButton = createTextButton( "refresh", editor.locale );
	refreshButton.on( "execute", () => {
		inputForm.inputView.element.value = "";
	} );
	refreshView.items.add( refreshButton );
	return refreshView;
}

function createGetHtmlButton( editor ) {
	let getHtmlView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let getHtmlButton = createTextButton( "get", editor.locale );
	getHtmlButton.on( "execute", () => {
		console.log( editor.getData() );
	} );
	getHtmlView.items.add( getHtmlButton );
	return getHtmlView;
}
