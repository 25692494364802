
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';

export const MARK = 'pisaMark';
const markTag = "mark";

export default class PisaMarkEditing extends Plugin {

	init() {
		const editor = this.editor;

    editor.model.schema.extend( '$text', { allowAttributes: MARK } );

    editor.conversion.attributeToElement( {
      model: MARK,
      view: markTag
    } );

    editor.commands.add( MARK, new AttributeCommand( editor, MARK ) );
  }

}
