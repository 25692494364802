import DomConverter from '@ckeditor/ckeditor5-engine/src/view/domconverter';
import Validator from '../pisautils/validator';

export default class PisaDomConverter extends DomConverter {

	_getNodeAncestors( node, options = { includeSelf: false, parentFirst: false } ) {
		if ( !Validator.isObject( node ) || !Validator.isObject( options ) ) return [];
		let parent = options.includeSelf ? node : node.parent;
		if ( !Validator.isObject( parent ) ) return [];
		const ancestors = [];
		while ( Validator.isObject( parent ) ) {
			ancestors[ options.parentFirst ? 'push' : 'unshift' ]( parent );
			parent = Validator.isObject( parent.parent ) ? parent.parent : void 0;
		}
		return ancestors;
	}

	/**
	 * @override
	 */
	_processDataFromViewText( node ) {
		let data = node.data;

		if ( this._getNodeAncestors( node )
			.some( parent => this.preElements.includes( parent.name ) ) )
			return data;

		if ( data.charAt( 0 ) == ' ' ) {
			const prevNode = this._getTouchingViewTextNode( node, false );
			const prevEndsWithSpace = prevNode && this._nodeEndsWithSpace( prevNode );
			if ( prevEndsWithSpace || !prevNode )
				data = '\u00A0' + data.substr( 1 );
		}

		if ( data.charAt( data.length - 1 ) == ' ' ) {
			const nextNode = this._getTouchingViewTextNode( node, true );
			if ( data.charAt( data.length - 2 ) == ' ' ||
				!nextNode || nextNode.data.charAt( 0 ) == ' ' )
				data = data.substr( 0, data.length - 1 ) + '\u00A0';
		}

		return data.replace( / {2}/g, ' \u00A0' );
	}

}
