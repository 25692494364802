/**
 * PiSA sales global functions
 */
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { setSelectionOnElement } from '../utils';
import { findMatchingImage } from '../pisaimage/pisaimageediting';
import { IMAGE_CLICKED } from '../pisaimage/pisaimageui';
import { updateNodeListeners} from '../pisaimageresize/utils';

export default class PisaGlobals extends Plugin {

	init() {
		// we're loaded for every instance but we have to do our job only once :-)
		if ( window.pisasales ) {
			if ( !window.pisasales.cke ) {
				window.pisasales.cke = {};
			}
			if ( !window.pisasales.cke.images ||
				!window.pisasales.cke.images.mouseover ||
				!window.pisasales.cke.images.mouseout ||
				!window.pisasales.cke.images.click ) {
				const cke = window.pisasales.cke;
				this._iniImg( cke );
			}
			if ( !window.pisasales.isPsaObjLink ) {
				window.pisasales.isPsaObjLink = _isPsaObjLink;
			}
			if ( !window.pisasales.cke.updateNodeListeners ) {
				window.pisasales.cke.updateNodeListeners = updateNodeListeners;
			}
		}
	}

	_iniImg( cke ) {
		cke.images = {};
		cke.images.mouseover = function( node ) {
			_mouseoverImg( node );
		}
		cke.images.mouseout = function( node ) {
			_mouseoutImg( node );
		}
		cke.images.click = function( node, id, idw ) {
			_clickImg( node, id, idw );
		}
	}
}

function _mouseoverImg( node ) {
	if ( node.wasClicked ) {
		return;
	}
	let styleBefore = node.getAttribute( "style" );
	let newStyle = getStyleWithoutBorder( styleBefore ) +
		"border: 5px solid var(--ck-color-widget-hover-border);";
	node.setAttribute( "style", newStyle );
	// node.style.border = "5px solid var(--ck-color-widget-hover-border)";
}

function _mouseoutImg( node ) {
	if ( node.wasClicked ) {
		return;
	}
	let styleBefore = node.getAttribute( "style" );
	let newStyle = getStyleWithoutBorder( styleBefore ) +
		"border: 5px solid transparent;";
	node.setAttribute( "style", newStyle );
	// node.style.border = "5px solid transparent";
}

function _clickImg( node, id, idw ) {
	const editor = pisasales.getCke5Editor( idw );
	if ( !editor ) return;
	let styleBefore = node.getAttribute( "style" );
	let newStyle = getStyleWithoutBorder( styleBefore ) +
		"border: 5px solid var(--ck-color-base-active-focus);";
	node.setAttribute( "style", newStyle );
	// node.style.border = "5px solid var(--ck-color-base-active-focus)";
	node.wasClicked = true;
	let refreshNode = () => {
		node.wasClicked = false;
		let prevoiusStyle = node.getAttribute( "style" );
		let updatedStyle = getStyleWithoutBorder( prevoiusStyle ) +
			"border: 5px solid transparent;";
		node.setAttribute( "style", updatedStyle );
		// node.style.border = "5px solid transparent";
	};
	editor.editing.view.document.on( 'click', ( evt, data ) => {
		if ( data && data.domTarget == node ) {
			return;
		}
		refreshNode();
	} );
	editor.editing.view.document.on( 'blur', ( evt, data ) => {
		// console.groupCollapsed( "pisa globals blur" );
		// console.log( evt );
		// console.log( data );
		// console.log( editor.objects.focus );
		// console.groupEnd();
		refreshNode();
	} );
	let editableElement = editor.ui.getEditableElement();
	if ( editableElement ) {
		let refreshAndForget = () => {
			refreshNode();
			editableElement.removeEventListener( "scroll", refreshAndForget );
		}
		editableElement.addEventListener( "scroll", refreshAndForget );
	};
	editor.objects.images.updateMap();
	let imageElement = editor.objects.images.map.get( id ) ||
		findMatchingImage( editor, node );
	if ( !imageElement ) {
		return;
	}
	setSelectionOnElement( editor, imageElement, true, true, false );
	editor.model.change( writer => { writer.setSelection( imageElement, "on" ); } );
	editor.fire( IMAGE_CLICKED, {
		imageElement: imageElement,
		imageId: id,
		node: node,
		fromLinkPlugin: false
	} );
}

/**
 * link check function
 * @param {String} href the link to be checked
 * @returns {Boolean} true if the specified link is PiSA object link; false otherwise
 */
function _isPsaObjLink( href ) {
	return href && href.includes( '/psaobj' ) && href.includes( 'rpsids=' ) && href.includes( 'psacbk=' );
}

function getStyleWithoutBorder( styleString ) {
	if ( styleString.indexOf( "border:" ) < 0 ) return styleString;
	let styleBeforeBorder = styleString.substring( 0, styleString.indexOf( "border:" ) );
	let styleAfterBorder = styleString.substring(
		styleString.indexOf( "border:" ) + "border:".length );
	styleAfterBorder = styleAfterBorder.indexOf( ";" ) < 0 ? "" :
		styleAfterBorder.substring( styleAfterBorder.indexOf( ";" ) + 1 );
	while ( styleBeforeBorder.endsWith( ";" ) ) {
		styleBeforeBorder = styleBeforeBorder.substring( 0, styleBeforeBorder.length - 1 );
	}
	while ( styleAfterBorder.startsWith( ";" ) ) {
		styleAfterBorder = styleAfterBorder.substring( 1 );
	}
	let styleWithoutBorder = !isValidString( styleBeforeBorder ) ?
		( !isValidString( styleAfterBorder ) ? "" : styleAfterBorder ) :
		!isValidString( styleAfterBorder ) ? styleBeforeBorder + ";" :
		styleBeforeBorder + ";" + styleAfterBorder;
	if ( styleWithoutBorder.length > 0 && !styleWithoutBorder.endsWith( ";" ) )
		styleWithoutBorder = styleWithoutBorder + ";";
	return styleWithoutBorder;
}

function isValidString( value ) {
	return !!value && typeof value == "string" && value.length > 0;
}
