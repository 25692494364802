import Command from '@ckeditor/ckeditor5-core/src/command';
import { MARK } from './pisamarkediting';
import { getRange } from '../utils';
import Validator from '../pisautils/validator';

export const PISA_MARK_MATCHES = "pisaMarkMatches";

export default class MarkMatchesCommand extends Command {

	constructor( editor ) {
		super( editor, PISA_MARK_MATCHES );
	}

	execute( options = {} ) {
		const editor = this.editor;
		if ( !Validator.isString( options.keyword ) ) return;
		markMatches( editor, options.keyword.toLowerCase(), options.batch );
	}

}

export function markMatches( editor, keyword, batch = void 0 ) {
	if ( !Validator.isObject( batch ) ) batch = editor.model.createBatch();
	let main = editor.data.model.document.getRoot( "main" );
	markChildren( editor, main, [], keyword, batch );
}

export function markChildren( editor, element, position, searchKeyword, batch ) {
	let rowIndex = element.index;
	let positionPath = !Validator.isNumber( rowIndex ) ? [] :
		position.concat( [ rowIndex ] );
	if ( !element._children || element._children.length <= 0 ) return;
	let initialNodes = [ ...element._children._nodes ];
	for ( let childNode of initialNodes ) {
		if ( !Validator.is( childNode, "Text" ) ) {
			markChildren( editor, childNode, positionPath, searchKeyword, batch );
			continue;
		}
		if ( childNode.hasAttribute( MARK ) ) continue;
		markInTextElement( {
			editor: editor,
			textData: childNode.data.toLowerCase(),
			positionPath: positionPath,
			searchKeyword: searchKeyword,
			batch: batch
		} );
	}
}

function markInTextElement( {
	editor,
	textData = "",
	positionPath,
	searchKeyword = "",
	batch
} ) {
	if ( !Validator.isString( searchKeyword ) ||
		textData.indexOf( searchKeyword ) < 0 ) return;
	let occurenceList = [ ...textData.matchAll(
		new RegExp( `(?:${ searchKeyword })+`, "gi" ) ) ];
	if ( occurenceList.length <= 0 ) return;
	let matchRanges = [];
	occurenceList.forEach( occurence => {
		let range = getOccurenceRange( editor, occurence, positionPath );
		if ( Validator.couldBe( range, "Range" ) )
			matchRanges.push( range );
	} );
	if ( matchRanges.length <= 0 ) return;
	editor.model.enqueueChange( batch, writer => {
		matchRanges.forEach( ( range, rangeIndex ) => {
			writer.setAttribute( MARK, true, range );
		} );
	} );
}

function getOccurenceRange( editor, occurence, positionPath ) {
	let matchText = occurence[ 0 ];
	let matchIndex = occurence.index;
	let startPositionPath = positionPath.concat( matchIndex );
	let endPositionPath = positionPath.concat( matchIndex + matchText.length );
	return editor.objects.position
		._pathsToRange( startPositionPath, endPositionPath, true );
}
