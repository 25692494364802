import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export default class PisaConverter extends Plugin {

	init() {
		const editor = this.editor;
		const conversion = editor.conversion;

		conversion.for( 'upcast' ).elementToElement( {
			model: 'paragraph',
			view: 'div'
		}, { priority: 'highest' } );

		conversion.for( 'downcast' ).elementToElement( {
			model: 'paragraph',
			view: {
				name: 'div',
				style: 'inherit'
			}
		}, { priority: 'highest' } );
	}

}
