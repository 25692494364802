import './theme/messagebox.css';
import MessageBoxOverlayView from './messageboxoverlayview';
import MessageBoxView from './messageboxview';
import HourglassContainerView from './hourglasscontainerview';
import AnimatedSquareView from './animatedsquareview';
import { /*addClasses,*/ addViewChildren } from '../utils';

export function createWaitingMessageBoxWithText( text ) {
	let messageBoxOverlay = new MessageBoxOverlayView();
	let whiteMessageBox = new MessageBoxView();
	let hourglassContainer = new HourglassContainerView();
	let bigSquare = new AnimatedSquareView( [ "hourglass-big-square" ] );
	let mediumSquare = new AnimatedSquareView( [ "hourglass-medium-square" ] );
	let smallSquare = new AnimatedSquareView( [ "hourglass-small-square" ] );
	let textContainer = void 0;
	addViewChildren( hourglassContainer, {
		0: bigSquare,
		1: mediumSquare,
		2: smallSquare
	} );
	addViewChildren( whiteMessageBox, {
		0: hourglassContainer
		// 0: hourglassContainer,
		// 1: textContainer
	} );
	addViewChildren( messageBoxOverlay, {
		0: whiteMessageBox
	} );
	return messageBoxOverlay;
}

export function createVanillaWaitingMessageBox( text, mini = false, micro = false ) {
	let messageBoxOverlay = document.createElement( "DIV" );
	messageBoxOverlay.classList.add( "cke5-message-box-overlay" );
	messageBoxOverlay.classList.add( "cke5-message-box-overlay-grey" );
	let whiteMessageBox = document.createElement( "DIV" );
	whiteMessageBox.classList.add( "cke5-message-box" );
	whiteMessageBox.classList.add( "cke5-message-box-white" );
	if ( mini ) whiteMessageBox.classList.add( "cke5-mini-message-box" );
	else if ( micro ) whiteMessageBox.classList.add( "cke5-micro-message-box" );
	let hourglassContainer = document.createElement( "DIV" );
	hourglassContainer.classList.add( "hourglass-squares-container" );
	let textContainer = document.createElement( "DIV" );
	textContainer.innerHTML = text;
	let bigSquare = document.createElement( "DIV" );
	bigSquare.classList.add( "hourglass-square" );
	bigSquare.classList.add( "hourglass-big-square" );
	let mediumSquare = document.createElement( "DIV" );
	mediumSquare.classList.add( "hourglass-square" );
	mediumSquare.classList.add( "hourglass-medium-square" );
	let smallSquare = document.createElement( "DIV" );
	smallSquare.classList.add( "hourglass-square" );
	smallSquare.classList.add( "hourglass-small-square" );
	hourglassContainer.appendChild( bigSquare );
	hourglassContainer.appendChild( mediumSquare );
	hourglassContainer.appendChild( smallSquare );
	whiteMessageBox.appendChild( hourglassContainer );
	whiteMessageBox.appendChild( textContainer );
	messageBoxOverlay.appendChild( whiteMessageBox );
	return messageBoxOverlay;
}
