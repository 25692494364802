import Command from '@ckeditor/ckeditor5-core/src/command';
import { PLACEHOLDER } from './pisaplaceholderui';
import { setPlaceholderText, activatePlaceholder, isInline } from './utils';
import { insertPlaceholderLines, insertDefinitionContainer } from './insertplaceholdercommand';
import { areLiveSelectionRangesValid, handleInvalidSelectionRanges, removeLastUndoSteps } from '../utils';
import { setSelectionAfterNode } from './pisaplaceholderui';

export default class ShowPlaceholderCommand extends Command {

	constructor( editor ) {
		super( editor, PLACEHOLDER );
		editor.model.document.on( 'change:data', ( eventInfo, batch ) => {
			editor.notifyDirty = !( editor.objects.placeholders.lastChangeIsToggle &&
				batch == editor.objects.placeholders.lastBatch );
		}, { priority: Number.MAX_SAFE_INTEGER } );
	}

	refresh() {
		const editor = this.editor;
		this.isEnabled = editor.objects && editor.objects.placeholders &&
			editor.objects.placeholders.map && editor.objects.placeholders.map.size != 0;
	}

	execute( options = {} ) {
		options.show = String( options.show ) != "undefined" ?
			String( options.show ) : String( options.value );
		if ( options.show != "true" && options.show != "false" ) return;
		const editor = this.editor;
		if ( !areLiveSelectionRangesValid( editor ) &&
			!setSelectionAfterNode( editor ) &&
			!handleInvalidSelectionRanges( editor ) ) {
			editor.objects.placeholders.renewBatch();
			removeLastUndoSteps( editor );
			return;
		};
		if ( !editor.objects || !editor.objects.placeholders ||
			!editor.objects.placeholders.map ) return;
		editor.objects.placeholders.lastChangeIsToggle = true;
		options.show == "true" ? valuesToTitles( editor ) : titlesToValues( editor );
		editor.objects.placeholders.lastChangeIsToggle = false;
		editor.objects.placeholders.renewBatch();
		// editor.objects.placeholders.lastBatch = editor.model.createBatch();
		removeLastUndoSteps( editor );
	}

}

function valuesToTitles( editor ) {
	editor.objects.placeholders.titlesShown = true;
	editor.objects.placeholders.map.forEach( ( properties, title ) => {
		properties.elementGroups || properties.singleLine == false ?
			multilineValueToTitle( editor, title, properties ) :
			inlineValueToTitle( editor, title, properties );
	} );
}

function titlesToValues( editor ) {
	editor.objects.placeholders.titlesShown = false;
	let dataProcessor = editor.data.processor || editor.getPsaDP();
	let isValidDataProcessor = !!dataProcessor &&
		typeof dataProcessor == "object" &&
		typeof dataProcessor._decode64 == "function" &&
		typeof dataProcessor._isHtmStr == "function" &&
		typeof dataProcessor.strToViewFrag == "function";
	editor.objects.placeholders.map.forEach( ( properties, title ) => {
		// asking for properties.elementGroups doesn't make sense, because we are in
		// "titles" mode if we call this function, and in "titles" mode elementGroups
		// is empty or doesn't exist, because multiline placeholders are represented
		// by dfn tags inside divs and registered in properties.elements, just like
		// all inline placeholders
		if ( properties.elementGroups || properties.singleLine == false ) {
			titleToMultilineValue( editor, title, properties );
			properties.singleLine = false; // just making sure
		} else if ( isValidDataProcessor ) {
			verifyAndInsert( editor, dataProcessor, title, properties );
		} else {
			titleToInlineValue( editor, title, properties );
			properties.singleLine = true; // just making sure
		}
	} );
}

function multilineValueToTitle( editor, title, properties ) {
	if ( !properties.elementGroups ) return;
	properties.elementGroups.forEach( group => {
		replaceGroup( editor, group, title, properties.unicodeValue );
	} );
	properties.singleLine = false; // still false
	delete properties.elementGroups;
}

function titleToMultilineValue( editor, title, properties ) {
	properties.elements.forEach( element => {
		placeholderToLines( editor, element, title, properties.unicodeValue );
	} );
}

export function placeholderToLines( editor, multilinePlhElement, title, unicodeValue ) {
	if ( !isInPlaceholderContainer( multilinePlhElement ) ) return;
	let selection = editor.model.change( writer => {
		return writer.createSelection( multilinePlhElement.parent, "before" );
	} );
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		writer.remove( multilinePlhElement.parent );
	} );
	insertPlaceholderLines( editor, title, unicodeValue, selection );
}

function inlineValueToTitle( editor, title, properties ) {
	properties.elements.forEach( element => {
		setPlaceholderText( editor, element, title );
		activatePlaceholder( editor, element );
	} );
}

function titleToInlineValue( editor, title, properties ) {
	let value = properties.unicodeValue;
	properties.elements.forEach( element => {
		setPlaceholderText( editor, element, value );
		activatePlaceholder( editor, element, false );
	} );
}

export function replaceGroup( editor, group, title, value ) {
	if ( !group || !group.elementsList || group.elementsList.length <= 0 ) return;
	// let selection = editor.model.change( writer => {
	// 	return group.elementsList[ 0 ].previousSibling ?
	// 		writer.createSelection( group.elementsList[ 0 ].previousSibling, "on" ) :
	// 		writer.createSelection( group.elementsList[ 0 ], "before" );
	// } );
	// editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
	// 	group.elementsList.forEach( element => {
	// 		writer.remove( element );
	// 	} );
	// } );
	let selection = deleteGroupAndGetSelection( editor, group );
	insertDefinitionContainer( editor, title, value, selection );
}

export function replaceGroupWithGroup( editor, group, title, value ) {
	let selection = deleteGroupAndGetSelection( editor, group );
	insertPlaceholderLines( editor, title, value, selection );
}

export function deleteGroupAndGetSelection( editor, group ) {
	if ( !group || !group.elementsList || group.elementsList.length <= 0 ) return;
	let selection = editor.model.change( writer => {
		return group.elementsList[ 0 ].previousSibling ?
			writer.createSelection( group.elementsList[ 0 ].previousSibling, "on" ) :
			writer.createSelection( group.elementsList[ 0 ], "before" );
	} );
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		group.elementsList.forEach( element => {
			writer.remove( element );
		} );
	} );
	return selection;
}

function isInPlaceholderContainer( element ) {
	return element && element.parent && element.parent.name == "paragraph" &&
		( element.parent._attrs.get( "isPlaceholderContainer" ) == "true" ||
			element.parent._attrs.get( "isPlaceholderContainer" ) == true );
}

function verifyAndInsert( editor, dataProcessor, title, properties ) {
	let value = properties.unicodeValue ||
		dataProcessor._decode64( properties.base64Value );
	if ( isValueInline( dataProcessor, value ) ) {
		titleToInlineValue( editor, title, properties );
		properties.singleLine = true;
		return;
	}
	console.warn( `The placeholder "{ ${ title } }" is multi-line, but was registered` +
		` as single-line. The properties of the placeholder will be updated.` );
	properties.singleLine = false;
	titleToMultilineValue( editor, title, properties );
}

function isValueInline( dataProcessor, value ) {
	let isSingleLine = true;
	let viewFrag = void 0;
	try {
		viewFrag = dataProcessor.strToViewFrag( value,
			!dataProcessor._isHtmStr( value ) );
		if ( !viewFrag ) {
			return isSingleLine;
		}
		isSingleLine = isInline( viewFrag );
	} catch ( e ) {
		isSingleLine = true;
	}
	return isSingleLine;
}
