
import Command from '@ckeditor/ckeditor5-core/src/command';
import { EDITTABLE } from './pisatableui';

export default class PisaTableCommand extends Command {

	constructor( editor ) {
		super( editor, EDITTABLE );
	}

}
