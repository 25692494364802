import AttachmentObject from '../pisautils/attachmentobject';
import Validator from '../pisautils/validator';

export default class WatchdogInstancesManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "isOnlyEditorInstance",
			getCallback: () => {
				const editorInstances = hostPlugin.ckEdt5Instances;
				if ( !editorInstances ) return true;
				return editorInstances.length <= 1;
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "ckEdt5Registry",
			getCallback: () => {
				if ( !Validator.isObjectPath( window, "window.pisasales" ) ||
					!Validator.isFunction( window.pisasales.getCke5ObjReg ) )
					return void 0;
				const editorRegistry = window.pisasales.getCke5ObjReg();
				if ( !Validator.is( editorRegistry, "ObjReg" ) ) return void 0;
				return editorRegistry;
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "ckEdt5Instances",
			getCallback: () => {
				const editorRegistry = hostPlugin.ckEdt5Registry;
				if ( !Validator.is( editorRegistry, "ObjReg" ) ) return void 0;
				if ( !Validator.isMap( editorRegistry._objReg, true ) ) return void 0;
				const iterator = editorRegistry._objReg.values();
				if ( !Validator.isIterable( iterator ) ) return void 0;
				const editorInstances = [ ...iterator ];
				if ( !Validator.isInteger( editorInstances.length ) ) return void 0;
				return editorInstances;
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "CkEdt5",
			getCallback: () => {
				return hostPlugin.getCkEdt5ById( hostPlugin.wdgId );
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "isDirty",
			getCallback: () => {
				const ckEdt5 = hostPlugin.CkEdt5;
				if ( !Validator.is( ckEdt5, "CkEdt5" ) ) return void 0;
				return ckEdt5.isDirty || ckEdt5.dirtyState;
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "dirtyEditorWatchdog",
			getCallback: () => {
				if ( hostPlugin.isDirty ) return hostPlugin;
				const editorInstances = hostPlugin.ckEdt5Instances;
				if ( !Validator.isArray( editorInstances, true ) ) return void 0;
				const dirtyCkEdt5 = editorInstances.find( ckEdt5 => (
					Validator.is( ckEdt5, "CkEdt5" ) &&
					( ckEdt5.isDirty || ckEdt5.dirtyState ) &&
					Validator.is( ckEdt5.watchdog, "PisaEditorWatchdog" )
				) );
				return Validator.isObject( dirtyCkEdt5 ) ?
					dirtyCkEdt5.watchdog : void 0;
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "lastFocusedWatchdog",
			getCallback: () => {
				if ( !Validator.isObjectPath( window.pisasales, "pisasales.cke" ) )
					return void 0;
				const id = window.pisasales.cke.lastFocusedEditorId;
				if ( Validator.isString( id ) && id === hostPlugin.wdgId )
					return hostPlugin;
				return hostPlugin.getWatchdogByWdgId( id );
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "allWatchdogs",
			getCallback: () => {
				const ckEdt5Instances = hostPlugin.ckEdt5Instances;
				if ( !Validator.isIterable( ckEdt5Instances ) ) return [];
				const watchdogs = new Set();
				for ( let ckEdt5 of ckEdt5Instances ) {
					if ( !Validator.isObject( ckEdt5 ) ||
						!Validator.is( ckEdt5.watchdog, "PisaEditorWatchdog" ) ) continue;
					watchdogs.add( ckEdt5.watchdog );
				}
				return [ ...watchdogs ];
			},
			setCallback: ( newValue ) => {}
		} );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "watchdogTotal",
			getCallback: () => {
				return hostPlugin.allWatchdogs.length;
			},
			setCallback: ( newValue ) => {}
		} );

	}

	getCkEdt5ById( wdgId ) {
		if ( !Validator.isString( wdgId ) ) return void 0;
		const editorRegistry = this.ckEdt5Registry;
		if ( !Validator.isObject( editorRegistry ) ||
			!Validator.isFunction( editorRegistry.getItem ) ) return void 0;
		const ckEdt5 = editorRegistry.getItem( wdgId );
		return Validator.is( ckEdt5, "CkEdt5" ) ? ckEdt5 : void 0;
	}

	getWatchdogByWdgId( wdgId ) {
		const ckEdt5 = this.getCkEdt5ById( wdgId );
		if ( !Validator.is( ckEdt5, "CkEdt5" ) ) return void 0;
		const watchdog = ckEdt5.watchdog;
		return Validator.is( watchdog, "PisaEditorWatchdog" ) ? watchdog : void 0;
	}

	blurAll() {
		const editorRegistry = this.ckEdt5Registry;
		const ckEdt5Values = Validator.isObject( editorRegistry ) &&
			Validator.isFunction( editorRegistry.getValues ) ?
			editorRegistry.getValues() : this.ckEdt5Instances
		if ( !Validator.isIterable( ckEdt5Values ) ) return false;
		for ( let ckEdt5 of ckEdt5Values ) {
			if ( !Validator.isObject( ckEdt5 ) ) continue;
			const watchdog = ckEdt5.watchdog;
			if ( !Validator.isObject( watchdog ) ) continue;
			watchdog.blur();
		}
		return true;
	}

}
