import LinkBalloon from './linkballoon';
import PisaLinkFormView from '../pisalinkformview';
import Validator from '../../pisautils/validator';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';

export default class LinkFormBalloon extends LinkBalloon {

	constructor( editor ) {
		super( editor, "form" );
	}

	init() {
		super.init();
		this.addFormView();
	}

	addFormView() {
		const manualDecorators = this.manualDecorators;
		if ( !manualDecorators ) return this._log( `Could not add a new form view` +
			` to the link ${ this.name } balloon because the link commands is` +
			` missing valid manual decorators.` );
		const editor = this.editor;
		this.formView = new PisaLinkFormView( editor.locale, manualDecorators, editor );
		const linkCommand = editor.commands._commands.get( 'link' );
		this.formView.urlInputView.bind( 'value' ).to( linkCommand, 'value' );
		// Form elements should be read-only when corresponding commands are disabled.
		this.formView.urlInputView.bind( 'isReadOnly' ).to( linkCommand, 'isEnabled', value => !value );
		this.formView.saveButtonView.bind( 'isEnabled' ).to( linkCommand );
		// this.addScrollListener();
		// this.addRightClickListener();
		clickOutsideHandler( {
			emitter: this.formView,
			activator: () => this.panel.isVisible,
			contextElements: [ this.panel.element ],
			callback: () => this.onOutsideClick()
		} );
		this.addView( this.formView );
		delete this.addFormView;
	}

	onOutsideClick() {
		if ( this.isInsideSameLink ) return;
		this.hide();
	}

}
