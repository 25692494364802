import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { setButton } from '../utils';
import { REMOVEFORMAT } from './pisaremoveformatcommand';
import RemoveFormatCommand from './pisaremoveformatcommand';
import { removeFormatIcon } from '../icons';

export default class RemoveFormat extends Plugin {

	init() {
		const editor = this.editor;

		editor.commands.add( REMOVEFORMAT, new RemoveFormatCommand( editor ) );

		editor.ui.componentFactory.add( REMOVEFORMAT, locale => {
			return setButton( removeFormatIcon,
				editor.objects.tooltips.getT( REMOVEFORMAT ), REMOVEFORMAT, "", editor );
		} );
	}
}
