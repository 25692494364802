import AttachmentObject from '../pisautils/attachmentobject';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';
import EventHelper from '../pisautils/eventhelper';

export default class WatchdogListeners extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
	}

	addKeystrokeListener() {
		let editableElement = this.editableElement;
		if ( !Validator.isObject( editableElement ) ) return void 0;
		let self = this;
		let neglectKeydown = ( evt ) => {
			if ( EventHelper.keyIs( evt, "Control" ) ) return;
			if ( EventHelper.ctrlPressed( evt ) ) {
				if ( EventHelper.keyIs( evt, "a" ) ) return;
				if ( EventHelper.keyIs( evt, "c" ) ) return;
			}
			EventHelper.stop( evt );
		}
		editableElement.addEventListener( "keydown", neglectKeydown );
	}

	addLinkClickListener() {
		let editableElement = this.editableElement;
		if ( !HtmHelper.isNode( editableElement ) ) return void 0;
		let self = this;
		let onClick = ( evt ) => {
			if ( !HtmHelper.isEvent( evt ) ) return;
			let aTag = Validator.isArray( evt.path ) ?
				evt.path.find( htmElement => htmElement.tagName == "A" &&
					Validator.isString( htmElement.href ) ) : void 0;
			if ( !aTag && HtmHelper.isNode( evt.target ) ) {
				let potentialATag = HtmHelper
					.getParentNodeOfTag( evt.target, "A", "DIV" );
				if ( !!potentialATag ) aTag = potentialATag;
			}
			if ( !aTag && HtmHelper.isNode( evt.srcElement ) ) {
				let potentialATag = HtmHelper
					.getParentNodeOfTag( evt.srcElement, "A", "DIV" );
				if ( !!potentialATag ) aTag = potentialATag;
			}
			if ( !aTag && HtmHelper.isNode( evt.explicitOriginalTarget ) ) {
				let potentialATag = HtmHelper
					.getParentNodeOfTag( evt.explicitOriginalTarget, "A", "DIV" );
				if ( !!potentialATag ) aTag = potentialATag;
			}
			if ( !aTag && HtmHelper.isNode( evt.relatedTarget ) ) {
				let potentialATag = HtmHelper
					.getParentNodeOfTag( evt.relatedTarget, "A", "DIV" );
				if ( !!potentialATag ) aTag = potentialATag;
			}
			if ( !aTag && HtmHelper.isNode( evt.currentTarget ) ) {
				let potentialATag = HtmHelper
					.getParentNodeOfTag( evt.currentTarget, "A", "DIV" );
				if ( !!potentialATag ) aTag = potentialATag;
			}
			if ( !HtmHelper.isNode( aTag ) ) return;
			let href = aTag.href;
			if ( !Validator.isObject( pisasales ) ||
				!Validator.isFunction( pisasales.isPsaObjLink ) ||
				!pisasales.isPsaObjLink( href ) ) {
				window.open( href, 'blank' );
				return;
			}
			if ( Validator.isString( self.wdgId ) &&
				Validator.isFunction( pisasales.psaObjClicked ) ) {
				pisasales.psaObjClicked( self.wdgId, href );
				return;
			}
			if ( !Validator.isObject( self.editor ) ||
				!Validator.isFunction( self.editor.fire ) ) {
				window.open( href, 'blank' );
				return;
			}
			self.editor.fire(
				"pisaObjectLink", { href: href, key: href, value: href } );
			EventHelper.stop( evt );
		}
		editableElement.addEventListener( "click", onClick );
	}

}
