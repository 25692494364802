import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaContinueListCommand from './pisacontinuelistcommand';
import PisaStartListCommand from './pisastartlistcommand';
// import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';

export const START_LIST = "pisaStartList";
export const CONTINUE_LIST = "pisaContinueList";
export const LIST_VALUE = "value";

export default class PisaListEditing extends Plugin {

	constructor( editor ) {
		super( editor );

		editor.config.define( LIST_VALUE, {} );
	}

	init() {
		const editor = this.editor;
		const schema = editor.model.schema;
		const conversion = editor.conversion;

		// const modelToViewUpcastWriter = new UpcastWriter();

		schema.extend( "listItem", { allowAttributes: LIST_VALUE } );

		conversion.for( 'downcast' ).attributeToAttribute( {
			model: {
				name: "listItem",
				key: LIST_VALUE
			},
			view: LIST_VALUE
		} );

		conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "li",
				key: LIST_VALUE
			},
			model: LIST_VALUE
		} );

		// conversion.for( 'upcast' ).elementToElement( {
		// 	view: "li",
		// 	model: ( viewElement, modelWriter ) => {
		// 		let value = viewElement._attrs.get( LIST_VALUE );
		// 		let listItem = modelWriter.createElement( "listItem" );;
		// 		if ( value ) {
		// 			modelToViewUpcastWriter.setAttribute( LIST_VALUE, "0", viewElement );
		// 			modelToViewUpcastWriter.removeAttribute( LIST_VALUE, viewElement );
		// 			modelToViewUpcastWriter.setAttribute( LIST_VALUE, value, viewElement );
		// 			modelWriter.setAttribute( LIST_VALUE, "0", listItem );
		// 			modelWriter.removeAttribute( LIST_VALUE, listItem );
		// 			modelWriter.setAttribute( LIST_VALUE, value, listItem );
		// 		}
		// 		return listItem;
		// 	}
		// } );

		// conversion.for( 'downcast' ).elementToElement( {
		// 	model: "listItem",
		// 	view: ( modelElement, viewWriter ) => {
		// 		let value = modelElement.getAttribute( LIST_VALUE ) ||
		// 			modelElement._attrs.get( LIST_VALUE );
		// 		let li = viewWriter.createEmptyElement( "li" );
		// 		if ( value ) {
		// 			editor.model.change( writer => {
		// 				writer.setAttribute( LIST_VALUE, "0", modelElement );
		// 				writer.removeAttribute( LIST_VALUE, modelElement );
		// 				writer.setAttribute( LIST_VALUE, value, modelElement );
		// 			} );
		// 			viewWriter.setAttribute( LIST_VALUE, "0", li );
		// 			viewWriter.removeAttribute( LIST_VALUE, li );
		// 			viewWriter.setAttribute( LIST_VALUE, value, li );
		// 		}
		// 		return li;
		// 	}
		// } );

		editor.commands.add( CONTINUE_LIST, new PisaContinueListCommand( editor ) );
		editor.commands.add( START_LIST, new PisaStartListCommand( editor ) );
	}
}
