import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

const CONTAINER = "placeholderContainer";

export default class PisaPlaceholderContainerEditing extends Plugin {

	init() {
		const editor = this.editor;

		let definitionAttributes = new Map();
		definitionAttributes.set( "data-title", "placeholderName" );
		definitionAttributes.set( "data-value", "placeholderValue" );
		definitionAttributes.set( "contenteditable", "contentEditable" );
		definitionAttributes.set( "data-group-index", "placeholderGroupIndex" );

		editor.model.schema.register( CONTAINER, {
			allowIn: [ "$root", "tableCell", "$clipboardHolder" ],
			isBlock: true,
			allowAttributes: Array.from( definitionAttributes.values() )
		} );

		editor.model.schema.extend( "$text", {
			allowIn: CONTAINER
		} );

		editor.model.schema.extend( "pisaImage", {
			allowIn: CONTAINER
		} );

		editor.model.schema.extend( "pisaPlaceholder", {
			allowIn: CONTAINER
		} );

		editor.conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: "div",
				"data-title": /.+/,
				"data-value": /.+/
			},
			model: CONTAINER,
			converterPriority: 'highest'
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: "p",
				"data-title": /.+/,
				"data-value": /.+/
			},
			model: CONTAINER,
			converterPriority: 'highest'
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.conversion.for( 'downcast' ).elementToElement( {
			model: CONTAINER,
			view: "div",
			// view: ( modelElement, viewWriter ) => {
			// 	console.log( CONTAINER );
			// 	return viewWriter.createContainerElement( "div" );
			// },
			converterPriority: 'highest'
		}, { priority: Number.MAX_SAFE_INTEGER } );

		definitionAttributes.forEach( ( modelName, viewName ) => {
			editor.conversion.for( 'downcast' ).attributeToAttribute( {
				model: {
					name: "placeholderContainer",
					key: modelName
				},
				view: viewName
			} );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: "p",
					key: viewName
				},
				model: modelName
			} );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: "div",
					key: viewName
				},
				model: modelName
			} );
		} );

	}

}
