import FunctionHelper from '../pisautils/functionhelper';
import { cloneDeep } from 'lodash-es';

// from http://unicode.e-workers.de/unicode3.php
const ZERO_WIDTH_UNICODE_HTML_ENTITIES = [ "&#x200B;", "&#8203;", "&#8288;", "&#x2060;",
	"&zwnj;", "&#8204;", "&#x200C;", "&zwj;", "&#8205;", "&#x200D;", "&#8291;",
	"&#8206;", "&#x200E;", "&#8207;", "&#x200F;", "&#8232;", "&#x2028;", "&#8233;",
	"&#x2029;", "&#8234;", "&#x202A;", "&#8235;", "&#x202B;", "&#8236;", "&#x202C;",
	"&#8237;", "&#x202D;", "&#8238;", "&#x202E;", "&#8289;", "&#x2061;", "&#8290;",
	"&#x2062;", "&#8291;", "&#x2063;", "&#8298;", "&#x206A;", "&#8299;", "&#x206B;",
	"&#8300;", "&#x206C;", "&#8301;", "&#x206D;", "&#8302;", "&#x206E;", "&#8303;",
	"&#x206F;", "&lrm;", "&rlm;", "&shy;"
]

export default class Validator {

	static is( inputParameter, className ) {
		if ( !Validator.isString( className ) ) return false;
		if ( !Validator.isObject( inputParameter ) ) return false;
		return Object.getPrototypeOf( inputParameter ).constructor.name == className;
	}

	static couldBe( inputParameter, className ) {
		if ( !Validator.isString( className ) ) return false;
		if ( !Validator.isObject( inputParameter ) ) return false;
		return Object.getPrototypeOf( inputParameter ).constructor.name
			.indexOf( className ) >= 0;
	}

	static getProtoNameIf( inputParameter ) {
		if ( inputParameter === null ) return "null";
		if ( inputParameter === void 0 ) return "undefined";
		return Object.getPrototypeOf( inputParameter ).constructor.name ||
			inputParameter.constructor.name;
	}

	/**
	 * contrary to #isElementObject, #isElement (this function) is inclusive
	 */
	static isElement( inputParameter, elementName ) {
		const isElement = Validator.is( inputParameter, "Element" );
		if ( !Validator.isString( elementName ) ) return isElement;
		if ( !isElement ) return false;
		return inputParameter.name == elementName;
	}

	/**
	 * contrary to #isElement, #isElementObject (this function) is exclusive
	 */
	static isElementObject( inputParameter, elementName ) {
		if ( !Validator.isString( elementName ) ) return false;
		if ( !Validator.is( inputParameter, "Element" ) ) return false;
		return inputParameter.name === elementName;
	}

	static couldBeElement( inputParameter, elementName ) {
		const couldBeElement = Validator.couldBe( inputParameter, "Element" );
		if ( !Validator.isString( elementName ) ) return couldBeElement;
		if ( !couldBeElement ) return false;
		return inputParameter.name == elementName;
	}

	static isObject( inputParameter ) {
		return !!inputParameter && typeof inputParameter == "object";
	}

	static areObjects( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return true;
	}

	static isString( inputParameter ) {
		return !!inputParameter &&
			typeof inputParameter == "string" && inputParameter.length > 0;
	}

	static areStrings( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isString( inputParameter ) ) return false;
		}
		return true;
	}

	static isStringAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isString( lastParameter );
	}

	static isStringAtPathEnd( parentObject, pathString = "" ) {
		if ( !Validator.isString( pathString ) )
			return Validator.isString( parentObject );
		if ( !Validator.isObject( parentObject ) ) return false;
		pathString = pathString.replace( /\.+/g, "." );
		while ( pathString.startsWith( "." ) ) {
			pathString = pathString.substring( 1 );
		}
		while ( pathString.endsWith( "." ) ) {
			pathString = pathString.substring( 0, pathString.length - 1 );
		}
		if ( !Validator.isString( pathString ) )
			return Validator.isString( parentObject );
		if ( pathString.indexOf( "." ) < 0 )
			return Validator.isString( parentObject ) ||
				Validator.isString( parentObject[ pathString ] );
		let pathWithoutLastElement = pathString.substring( 0, pathString.lastIndexOf( "." ) );
		if ( !Validator.isObjectPath( parentObject, pathWithoutLastElement ) ) return false;
		let lastObject = Validator.getObjectFromParentAndPath( parentObject,
			pathWithoutLastElement );
		if ( !Validator.isObject( lastObject ) ) return false;
		let lastElement = pathString.substring( pathString.lastIndexOf( "." ) + 1 );
		return Validator.isString( lastObject[ lastElement ] );
	}

	static getObjectFromParentAndPath( parentObject, pathString = "" ) {
		if ( !Validator.isObject( parentObject ) ) return void 0;
		if ( !Validator.isString( pathString ) ||
			!Validator.isFunction( pathString.split ) ) return parentObject;
		let pathArray = pathString.split( "." );
		if ( pathArray.length > 0 ) pathArray.shift();
		let current = parentObject;
		for ( let childLevelName of pathArray ) {
			if ( !Validator.isObject( current[ childLevelName ] ) ) break; // TODO return void 0?
			current = current[ childLevelName ];
		}
		return current;
	}

	static isNumber( inputParameter ) {
		return typeof inputParameter == "number";
	}

	static isValidNumber( inputParameter, canBeZero = true ) {
		if ( typeof inputParameter != "number" ) return false;
		if ( inputParameter === 0 ) return !!canBeZero;
		return inputParameter / inputParameter === 1;
	}

	static isInteger( inputParameter ) {
		return Validator.isNumber( inputParameter ) &&
			Number.isInteger( inputParameter );
	}

	static isPositiveNumber( inputParameter, canBeZero = true ) {
		return Validator.isNumber( inputParameter ) &&
			( !canBeZero ? inputParameter > 0 : inputParameter >= 0 );
	}

	static isPositiveInteger( inputParameter, canBeZero = true ) {
		return Validator.isInteger( inputParameter ) &&
			Validator.isPositiveNumber( inputParameter, canBeZero );
		// return Validator.isNumber( inputParameter ) &&
		// 	Math.abs( inputParameter ) == inputParameter &&
		// 	Math.round( inputParameter ) == inputParameter;
	}

	static areNumbers( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isNumber( inputParameter ) ) return false;
		}
		return true;
	}

	static isNumberAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isNumber( lastParameter );
	}

	static isBoolean( inputParameter ) {
		return typeof inputParameter == "boolean";
	}

	static areBooleans( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isBoolean( inputParameter ) ) return false;
		}
		return true;
	}

	static isBooleanAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isBoolean( lastParameter );
	}

	static isFunction( inputParameter ) {
		return !!inputParameter && typeof inputParameter == "function";
	}

	static areFunctions( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isFunction( inputParameter ) ) return false;
		}
		return true;
	}

	static isFunctionAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isFunction( lastParameter );
	}

	static isIterable( inputParameter ) {
		if ( !Validator.isObject( inputParameter ) &&
			!Validator.isString( inputParameter ) ) return false;
		return Validator.isFunction( inputParameter[ Symbol.iterator ] );
	}

	static isArray( inputParameter, testLength = false ) {
		if ( !( inputParameter instanceof Array ) ) return false;
		if ( Validator.isBoolean( testLength ) )
			return !testLength ? true : inputParameter.length > 0;
		if ( !Validator.isPositiveInteger( testLength, true ) ) return true;
		return inputParameter.length === testLength;
		// return !( inputParameter instanceof Array ) ? false : !testLength ? true :
		// 	inputParameter.length > 0;
	}

	static areArrays( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isArray( inputParameter ) ) return false;
		}
		return true;
	}

	static isArrayAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isArray( lastParameter );
	}

	static arraysEqual( firstArray, secondArray ) {
		if ( !Validator.isArray( firstArray ) ||
			!Validator.isArray( secondArray ) ) return false;
		if ( firstArray.length != secondArray.length ) return false;
		return JSON.stringify( firstArray ) === JSON.stringify( secondArray );
	}

	static isMap( inputParameter, testSize = false ) {
		return !( inputParameter instanceof Map ) ? false : !testSize ? true :
			inputParameter.size > 0;
	}

	static areMaps( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		for ( let inputParameter of args ) {
			if ( !Validator.isMap( inputParameter ) ) return false;
		}
		return true;
	}

	static isMapAtEnd( inputParameters ) {
		let args = Array.prototype.slice.call( arguments, 0 );
		let lastParameter = args.pop();
		for ( let inputParameter of args ) {
			if ( !Validator.isObject( inputParameter ) ) return false;
		}
		return Validator.isMap( lastParameter );
	}

	static isSet( inputParameter, testSize = false ) {
		return !( inputParameter instanceof Set ) ? false : !testSize ? true :
			inputParameter.size > 0;
	}

	static _isObjectHierarchy( parentObject, levelsArray = [] ) {
		if ( !Validator.isObject( parentObject ) ) return false;
		let currentlyValidating = parentObject;
		for ( let childLevelName of levelsArray ) {
			currentlyValidating = currentlyValidating[ childLevelName ];
			if ( !Validator.isObject( currentlyValidating ) ) return false;
		}
		return true;
	}

	static isObjectPath( parentObject, pathString = "" ) {
		if ( !Validator.isFunction( pathString.split ) ) return false;
		let pathArray = pathString.split( "." );
		if ( pathArray.length > 0 ) pathArray.shift();
		return Validator._isObjectHierarchy( parentObject, pathArray );
	}

	static getObjectFromHierarchy( parentObject, pathString = "", validateEachLevel = false ) {
		if ( !Validator.isFunction( pathString.split ) ) return void 0;
		let pathArray = pathString.split( "." );
		if ( pathArray.length > 0 ) pathArray.shift();
		return Validator._getObjectFromHierarchy( parentObject, pathArray, validateEachLevel );
	}

	static _getObjectFromHierarchy( parentObject, pathArray = [], validateEachLevel = false ) {
		if ( !Validator.isObject( parentObject ) ) return void 0;
		let finalObject = parentObject;
		for ( let childLevelName of pathArray ) {
			finalObject = finalObject[ childLevelName ];
			if ( !!validateEachLevel && !Validator.isObject( finalObject ) )
				return void 0;
		}
		return finalObject;
	}

	static isFunctionPath( parentObject, pathString ) {
		if ( !Validator.isString( pathString ) )
			return Validator.isFunction( pathString ) ||
				Validator.isFunction( parentObject );
		if ( pathString.indexOf( "." ) < 0 )
			return Validator.isObject( parentObject ) &&
				Validator.isFunction( parentObject[ pathString ] );
		let pathArray = pathString.split( "." );
		const functionName = pathArray.splice( -1, 1 );
		if ( pathArray.length > 0 ) pathArray.shift();
		if ( !Validator._isObjectHierarchy( parentObject, pathArray ) ) return false;
		const finalObject = Validator._getObjectFromHierarchy( parentObject, pathArray );
		return Validator.isObject( finalObject ) &&
			Validator.isFunction( finalObject[ functionName ] );
	}

	static isMapPath( parentObject, pathString ) {
		if ( !Validator.isObjectPath( parentObject, pathString ) ) return false;
		const endObject = Validator.getObjectFromHierarchy( parentObject, pathString );
		return Validator.isMap( endObject );
	}

	static isArrayPath( parentObject, pathString, testLength = false ) {
		if ( !Validator.isObjectPath( parentObject, pathString ) ) return false;
		const endObject = Validator.getObjectFromHierarchy( parentObject, pathString );
		return Validator.isArray( endObject, testLength );
	}

	static _getFirstMatchArr( source, regExp ) {
		if ( !Validator.isString( source ) ) return void 0;
		if ( Validator.isString( regExp ) ) regExp = new RegExp( regExp, "g" );
		if ( !( regExp instanceof RegExp ) ) return void 0;
		const matches = [ ...source.matchAll( regExp ) ];
		if ( !Validator.isArray( matches, true ) ) return void 0;
		const firstMatch = matches[ 0 ];
		if ( !Validator.isArray( firstMatch, true ) ) return void 0;
		return firstMatch;
	}

	static firstMatchValue( source, regExp ) {
		const firstMatchArr = Validator._getFirstMatchArr( source, regExp );
		if ( !Validator.isArray( firstMatchArr, true ) ) return void 0;
		const value = firstMatchArr[ 0 ];
		return Validator.isString( value ) ? value : void 0;
	}

	static firstMatchIndex( source, regExp ) {
		const firstMatch = Validator._getFirstMatchArr( source, regExp );
		if ( !Validator.isArray( firstMatch, true ) ) return -1;
		const index = firstMatch.index;
		return Validator.isPositiveInteger( index ) ? index : -1;
	}

	static isExactMatch( source, regularExpression ) {
		if ( !Validator.isString( source ) ||
			!( regularExpression instanceof RegExp ) ) return false;
		const matches = source.match( regularExpression );
		return Validator.isArray( matches, 1 ) && matches[ 0 ] === source;
	}

	static isGeneralExactMatch( source, regExpString, flags = void 0 ) {
		if ( !Validator.isString( regExpString ) ) return false;
		const expressionFlags = Validator.isString( flags ) ? flags : "g";
		return Validator.isExactMatch( source, new RegExp( regExpString, flags ) );
	}

	static isPrintableKey( inputParameter ) {
		// all printable keys like numbers and letters have key.length === 1
		return Validator.isString( inputParameter ) && inputParameter.length === 1;
	}

	static isStringNumber( inputParameter, digitQuantity = 1 ) {
		if ( !Validator.isPositiveNumber( digitQuantity ) ) digitQuantity = 1;
		return Validator.isGeneralExactMatch( inputParameter, `[\\d]{${ digitQuantity }}` );
	}

	static isStringLetter( inputParameter, characterQuantity = 1 ) {
		if ( !Validator.isPositiveNumber( characterQuantity ) ) characterQuantity = 1;
		return Validator.isGeneralExactMatch( inputParameter, `[a-zA-Z]{${ characterQuantity }}` );
	}

	static getDescriptor( parentObject, propertyName ) {
		if ( !Validator.isString( propertyName ) ) return void 0;
		if ( !Validator.isObject( parentObject ) ) return void 0;
		return Object.getOwnPropertyDescriptor( parentObject, propertyName );
	}

	static hasSetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return false;
		return Validator.isFunction( descriptor.set );
	}

	static getSetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return void 0;
		return Validator.isFunction( descriptor.set ) ? descriptor.set : void 0;
	}

	static hasGetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return false;
		return Validator.isFunction( descriptor.get );
	}

	static getGetter( parentObject, propertyName ) {
		let descriptor = Validator.getDescriptor( parentObject, propertyName );
		if ( !Validator.isObject( descriptor ) ) return void 0;
		return Validator.isFunction( descriptor.get ) ? descriptor.get : void 0;
	}

	static removeSetterAndSet( parentObject, propertyName, value ) {
		if ( !Validator.isString( propertyName ) ) return false;
		if ( !Validator.isObject( parentObject ) ) return false;
		// const hasSetter = Validator.hasSetter( parentObject, propertyName );
		// const hasGetter = Validator.hasGetter( parentObject, propertyName );
		// if ( hasSetter != hasGetter )
		// Object.defineProperty( parentObject, propertyName, { configurable: true } );
		// Object.defineProperty( parentObject, propertyName, { set: void 0, get: void 0 } );
		if ( Validator.hasSetter( parentObject, propertyName ) )
			delete parentObject[ propertyName ];
		parentObject[ propertyName ] = value;
		return true;
	}

	// static define( { parentObject, propertyName, setter, getter, value, configurable, writable })

	static isTextOrInlineViewElement( inputParameter ) {
		return Validator.is( inputParameter, "Text" ) ||
			Validator.isInlineViewElement( inputParameter );
	}

	static isInlineViewElement( inputParameter ) {
		return Validator.is( inputParameter, "Element" ) && [ "span", "i", "strong", "b", "code", "a", "s", "u", "sub", "sup" ]
			.indexOf( inputParameter.name ) >= 0;
	}

	static addLazySetter( {
		parentObject,
		propertyName,
		callbackBeforeSetting,
		argumentsArr = [],
		addNewValueToArgumentsList = true,
		scope = null
	} ) {
		let success = false;
		if ( !Validator.isObject( parentObject ) ) return success;
		if ( !Validator.isString( propertyName ) ) return success;
		Object.defineProperty( parentObject, propertyName, {
			set: ( newValue ) => {
				if ( Validator.isFunction( callbackBeforeSetting ) ) {
					if ( !!addNewValueToArgumentsList ) argumentsArr.push( newValue );
					FunctionHelper.exec( callbackBeforeSetting, argumentsArr, scope );
				}
				delete parentObject[ propertyName ];
				parentObject[ propertyName ] = newValue;
			},
			get: () => { return void 0; },
			configurable: true
		} );
		success = true;
		return success;
	}

	static clone( inputParameter ) {
		return Object.assign( {}, inputParameter );
	}

	static deepClone( inputParameter ) {
		if ( !Validator.isObject( inputParameter ) ) return inputParameter;
		let clone = void 0;
		let success = false;

		try {
			eval( "clone = { ...inputParameter }" );
			success = true;
		} catch ( err ) {
			success = false;
			clone = void 0;
		}
		if ( success && Validator.isObject( clone ) ) return clone;

		try {
			eval( "clone = Object.assign( {}, inputParameter )" );
			success = true;
		} catch ( err ) {
			success = false;
			clone = void 0;
		}
		if ( success && Validator.isObject( clone ) ) return clone;

		if ( Validator.isFunction( cloneDeep ) ) {
			try {
				clone = cloneDeep( inputParameter );
				success = true;
			} catch ( err ) {
				success = false;
				clone = void 0;
			}
			if ( success && Validator.isObject( clone ) ) return clone;
		}

		if ( Validator.isFunction( _ ) && Validator.isFunction( _.cloneDeep ) ) {
			try {
				clone = _.cloneDeep( inputParameter );
				success = true;
			} catch ( err ) {
				success = false;
				clone = void 0;
			}
			if ( success && Validator.isObject( clone ) ) return clone;
		}

		return void 0;

	}

	static removeHiddenCharacters( inputParameter ) {
		inputParameter = Validator.removeNonPrintableAsciiChars( inputParameter );
		inputParameter = Validator.removeZeroWidthUnicodeHtmlEntities( inputParameter );
		return inputParameter;
	}

	static removeNonPrintableAsciiChars( inputParameter ) {
		if ( !Validator.isString( inputParameter ) ) return "";
		// C0 unicode control codes except HT and LF
		inputParameter = inputParameter.replace( /[\u{0000}-\u{0008}]/gu, "" );
		inputParameter = inputParameter.replace( /[\u{000B}-\u{001F}]/gu, "" );
		// unicode delete
		inputParameter = inputParameter.replace( /\u007F/gu, "" );
		// C1 unicode control characters
		inputParameter = inputParameter.replace( /[\u{0080}-\u{009F}]/gu, "" );
		// unicode soft hypen
		inputParameter = inputParameter.replace( /\u00AD/gu, "" );
		// unicode invisible spaces and marks
		inputParameter = inputParameter.replace( /[\u{200B}-\u{200F}]/gu, "" );
		// unicode embedding and formatting
		inputParameter = inputParameter.replace( /[\u{202A}-\u{202E}]/gu, "" );
		// u+206x
		inputParameter = inputParameter.replace( /[\u{2060}-\u{206F}]/gu, "" );
		return inputParameter;
	}

	static removeZeroWidthUnicodeHtmlEntities( inputParameter ) {
		if ( !Validator.isString( inputParameter ) ) return "";
		ZERO_WIDTH_UNICODE_HTML_ENTITIES.forEach( unicodeHtmlEntity => {
			let regularExpression = new RegExp( unicodeHtmlEntity, "gi" );
			inputParameter = inputParameter.replace( regularExpression, "" );
		} );
		return inputParameter;
	}

}
