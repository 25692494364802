import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ModelSelection from '@ckeditor/ckeditor5-engine/src/model/selection';
import ViewSelection from '@ckeditor/ckeditor5-engine/src/view/selection';
import ModelPosition from '@ckeditor/ckeditor5-engine/src/model/position';
import { getMainRoot, getObjectProto, isValidModelPosition, removeLastUndoSteps, findElementInSelection } from '../utils';
import PisaWordBreak from './pisawordbreak';
import { isInsideTable } from '../pisatable/pisadecreasemargincommand';
import UndoEditing from '@ckeditor/ckeditor5-undo/src/undoediting';
import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';
import FunctionHelper from '../pisautils/functionhelper';

const LOG_CHANGES = false;
const ADD_TIMESTAMP = true;
const ADD_COUNTER = true;
const COMPACT_MODE = true;
const LOG_CURRENT_STATS = true;

// should "Tab" be on the list?
// "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight" are all already firing "selectionChangeDone"
const SELECTION_CHANGING_KEYS = [ "Enter", "Delete", "Backspace", " ", "Space" ];

export default class PisaSelection extends Plugin {

	static get pluginName() {
		return 'PisaSelection';
	}

	static get requires() {
		return [ PisaWordBreak ];
	}

	init() {
		const editor = this.editor;
		editor.objects = editor.objects || {};
		editor.objects.selection = this;
		this.undo = {};
		this.undo.editing = editor.plugins._plugins.get( UndoEditing );
		this.undo.undoCommand = editor.commands._commands.get( "undo" );
		this.undo.redoCommand = editor.commands._commands.get( "redo" );
		this.undo.clearNextBatch = false;
		this.undo.doNotClearNextBatch = () => {
			this.undo.clearNextBatch = false;
		}
		// this.focusOnEditor = false;
		this.last = {
			model: null,
			view: null,
			paths: {
				anchor: null,
				focus: null
			}
		};
		// when the objects.selection is frozen, it does not update together with the
		// live/document selection or on keydown & -up, a.k.a. does not update at all
		this.frozen = false;
		this.nullified = false;
		this.firstFocusRange = null;
		this.lastFocusPosition = null;
		this.fakeFocusRange = null;
		const docFrag = editor.data.processor.htmToDocFrag(
			'<span style="background-color:transparent;">&#8203;</span>' );
		this.emptyTextSpan = docFrag._children._nodes[ 0 ]._children._nodes[ 0 ];
		this.lastBatch = null;
		this.counter = 0;

		this.listenTo( editor.model, 'applyOperation', ( evt, args ) => {
			if ( !this.undo.clearNextBatch ) return;
			if ( !( args instanceof Array ) || args.length < 1 ) return;
			const operation = args[ 0 ];
			if ( !operation || typeof operation != "object" ||
				!operation.isDocumentOperation || typeof operation.batch != "object" ) return;
			let batch = operation.batch;
			if ( !( batch.operations instanceof Array ) || batch.operations.length < 1 )
				return this.undo.doNotClearNextBatch();
			batch.operations = batch.operations.slice( batch.operations.length - 1 );
			this.undo.doNotClearNextBatch();
		}, { priority: Number.MAX_SAFE_INTEGER } );

		// update on any document operation
		editor.model.document.on( 'change:data', ( eventInfo, batch ) => {
			// if ( !this.focusOnEditor ) return;
			if ( this.frozen ) return;
			// if ( !this.isFocused ) return;
			if ( !batch || getObjectProto( batch ).indexOf( "Batch" ) < 0 ||
				!( batch.operations instanceof Array ) || batch.operations.length < 1 ||
				batch.operations.every( operation => !operation.isDocumentOperation ) ) return;
			this.update();
		}, { priority: Number.MAX_SAFE_INTEGER } );

		// TODO when the model/content is changed through the writer, "selectionChangeDone"
		// is not fired
		editor.editing.view.document.on( 'selectionChangeDone', ( eventInfo, data ) => {
			// if ( !this.focusOnEditor ) return;
			if ( this.frozen ) return;
			// if ( !this.isFocused ) return;
			// priority goes as follows
			// 1. data.newSelection
			// 2. data.domSelection
			// 3. live "window document" selection
			// 4. editor.model.document.selection
			if ( this._isValidObjOrPath( data, "data.newSelection" ) &&
				this.exists( data.newSelection ) ) {
				this.setModelToCloned( data.newSelection );
				return this.updateSelected( false, true, true );
				finalSelection = data.newSelection;
			}
			if ( this._isValidObjOrPath( data, "data.domSelection" ) ) {
				let viewSelection = this._domSelectionToView( data.domSelection );
				let modelRange = viewSelection && typeof viewSelection == "object" ?
					this._viewSelectionToModelRange( viewSelection ) : void 0;
				let modelSelection = modelRange && typeof modelRange == "object" ?
					editor.model.change( writer => {
						return writer.createSelection( modelRange );
					} ) : void 0;
				if ( this.setAndUpdate( modelSelection ) ) return;
			}
			let liveRange = this._liveSelectionToModelRange();
			let liveSelection = liveRange && typeof liveRange == "object" ?
				editor.model.change( writer => {
					return writer.createSelection( liveRange );
				} ) : void 0;
			if ( this.setAndUpdate( liveSelection ) ) return;
			let currentSelection = editor.model.document.selection;
			if ( this.setAndUpdate( currentSelection ) ) return;
			console.warn( "Selection update did not happen because none of the " +
				"provided selections is valid." );
			console.log( data.newSelection );
			// the higher the number, the higher the priority, and it makes sure it is
			// handled before listeners with string priorities like "highest", "low", etc.
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.editing.view.document.on( 'selectionChangeDone', () => {
			let blocks = this.lastBlocks;
			this.lastBatch = null;
			if ( editor.data.processor.isPlain() ) return;
			let shouldRemove = false;
			// this makes sure empty paragraph elements have a zero-width space inside,
			// so that they can also have styling/formatting
			// the only problem is, that these styles might be conserved on plain text
			blocks.forEach( element => {
				if ( this._isValidObjOrPath( element, "element.parent" ) &&
					element.name == "paragraph" && element.childCount === 0 ) {
					this.lastBatch = this.lastBatch || editor.model.createBatch();
					editor.model.enqueueChange( this.lastBatch, writer => {
						// writer.insertElement( "wordBreak", element, "end" );
						writer.insert( this.emptyTextSpan, element, "end" );
						shouldRemove = true;
						// writer.insertElement( "wordBreak", element, "end" );
					} );
				}
			} );
			if ( shouldRemove ) removeLastUndoSteps( editor );
			this.lastBatch = null;
		} );

		editor.model.document.on( 'change:data', ( eventInfo, batch ) => {
			let placeholderCommand = editor.commands.get( "pisaPlaceholder" );
			editor.notifyDirty = placeholderCommand && editor.notifyDirty == false ?
				false : batch != this.lastBatch;
		} );

		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			// if ( !this.focusOnEditor ) return;
			if ( this.frozen ) return;
			// if ( !this.isFocused ) return;
			this._updateBasedOnKey( data );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.editing.view.document.on( 'keyup', ( eventInfo, data ) => {
			// if ( !this.focusOnEditor ) return;
			if ( this.frozen ) return;
			// if ( !this.isFocused ) return;
			this._updateBasedOnKey( data );
		}, { priority: 0 } );

		this.listenTo( editor.model, 'applyOperation', ( evt, args ) => {
			const operation = args[ 0 ];
			if ( !operation.isDocumentOperation || !operation.sourcePosition ||
				isValidModelPosition( operation.sourcePosition ) ) return;
			evt.stop();
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.on( 'focusChanged', ( evt, par ) => {
			// this.focusOnEditor = par.gainedFocus;
			// this.focusOnEditor ? console.log( "gained focus" ) : console.log( "lost focus" );
			if ( par.gainedFocus ) {
				if ( Validator.isObjectPath( window.pisasales, "pisasales.cke" ) )
					window.pisasales.cke.lastFocusedEditorId = editor.wdgId;
				this._updateFirstFocusRange();
			} else this._updateFakeFocusRange();
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.on( "change:isReadOnly", ( eventInfo, name, newValue, oldValue ) => {
			if ( !newValue || oldValue ) {
				// readOnly to not readOnly
				this.reviveAndRestore();
				console.warn( "CK Editor 5 object selection is revived. " );
			} else {
				// not readOnly to readOnly
				this.freeze();
				console.warn( "CK Editor 5 object selection is frozen. " );
			}
		}, { priority: Number.MIN_SAFE_INTEGER } );
	}

	get isFocused() {
		return !Validator.isObjectPath( this, "this.editor.objects.focus" ) ? true :
			this.editor.objects.focus.isFocused;
	}

	get objectsPosition() {
		return !Validator.isObjectPath( this, "this.editor.objects.position" ) ?
			void 0 : this.editor.objects.position;
	}

	isSelection( selection ) {
		return getObjectProto( selection ).indexOf( "Selection" ) >= 0 &&
			this._isValidObjOrPath( selection );
	}

	exists( selection ) {
		if ( !this._isValidObjOrPath( this,
				"this.editor.objects.position" ) || !this.isSelection( selection ) )
			return false;
		let positionUtils = this.editor.objects.position;
		if ( !positionUtils.exists( selection.anchor ) ||
			!positionUtils.exists( selection.focus ) ) return false;
		if ( !selection._ranges || !( selection._ranges instanceof Array ) ||
			selection._ranges.length < 1 ) return false;
		let exists = true;
		for ( let range of selection._ranges ) {
			if ( positionUtils.exists( range.start ) &&
				positionUtils.exists( range.end ) ) continue;
			exists = false;
			break;
		}
		return exists;
	}

	clone( selection, withAttributes = true ) {
		if ( !this.isSelection( selection ) ) return void 0;
		let clonedSelection = this.editor.model.change( writer => {
			return writer.createSelection( selection );
		} );
		if ( !withAttributes ) return clonedSelection;
		let attributes = this._copySelectionAttributes( selection );
		clonedSelection._selection ? clonedSelection._selection._attrs = map :
			clonedSelection._attrs = map;
		return clonedSelection;
	}

	duplicate( selection, withAttributes = true ) {
		if ( !this.isSelection( selection ) ) return void 0;
		let duplicatedSelection = this.editor.model.createSelection( selection );
		if ( !withAttributes ) return duplicatedSelection;
		let attributes = this._copySelectionAttributes( selection );
		duplicatedSelection._selection ? duplicatedSelection._selection._attrs = map :
			duplicatedSelection._attrs = map;
		return duplicatedSelection;
	}

	setAndUpdate( modelSelection ) {
		if ( !this.setModelToIfExists( modelSelection ) ) return false;
		this.updateSelected( false, true, true );
		return true;
	}

	setModelToIfExists( selection ) {
		if ( !this.exists( selection ) ) return false;
		return this.setModelTo( selection );
	}

	setModelTo( selection ) {
		if ( typeof this.last != "object" ) return false;
		if ( !this.isSelection( selection ) ) return false;
		this._log( "Set last model selection ( #setModelTo )." );
		this.last.model = selection;
		this.nullified = false;
		return true;
	}

	setModelToCloned( selection, withAttributes = true ) {
		let clonedSelection = this.clone( selection, withAttributes );
		this.setModelTo( clonedSelection );
	}

	_updateFirstFocusRange() {
		try {
			this.firstFocusRange = this.viewTo( null, { to: "range" } );
			this._log( "Updated first focus range ( #_updateFirstFocusRange )." );
		} catch ( e ) {
			this.firstFocusRange = void 0;
			console.warn( "Couldn't update first focus range. Error:" );
			console.warn( e );
		}
	}

	_updateLastFocusPosition() {
		this.lastFocusPosition = this.viewTo( null, { to: "position", start: false } );
		this._log( "Set last focus position ( #_updateLastFocusPosition )." );
	}

	_setToDomSelection() {
		this._setTo( this._liveSelectionToModelRange() );
	}

	_updateBasedOnKey( data ) {
		if ( !data || !data.domEvent || !data.domEvent.key ) return;
		let key = data.domEvent.key;
		let code = data.domEvent.code;
		// all printable keys like numbers and letters have key.length == 1
		if ( key.length != 1 && SELECTION_CHANGING_KEYS.indexOf( key ) < 0 &&
			SELECTION_CHANGING_KEYS.indexOf( code ) < 0 ) return;
		this.update();
	}

	_copyCurrentModelSelection( withAttributes = true ) {
		let editor = this.editor;
		let modelSelection = new ModelSelection( editor.model.document.selection, 0 );
		if ( !withAttributes ) return modelSelection;
		let map = this._copyCurrentAttributes();
		// let map = new Map();
		// editor.model.document.selection._selection._attrs.forEach( ( attributeValue, attribute ) => {
		// 	map.set( attribute, attributeValue );
		// } );
		modelSelection._selection ? modelSelection._selection._attrs = map :
			modelSelection._attrs = map;
		return modelSelection;
	}

	_copyCurrentViewSelection() {
		let editor = this.editor;
		let viewSelection = new ViewSelection( editor.editing.view.document.selection, 0 );
		return viewSelection;
	}

	_copyCurrentAttributes( asMap = true ) {
		let map = new Map();
		let obj = {};
		this.editor.model.document.selection._selection._attrs.forEach( ( attributeValue, attribute ) => {
			asMap ? map.set( attribute, attributeValue ) : obj[ attribute ] = attributeValue;
		} );
		return asMap ? map : obj;
	}

	_copySelectionAttributes( selection, asMap = true ) {
		let map = new Map();
		let obj = {};
		if ( !this.isSelection( selection ) ) return asMap ? map : obj;
		let attributes = !!selection._attrs && selection._attrs instanceof Map ?
			selection._attrs : this.isSelection( selection._selection ) &&
			!!selection._selection._attrs &&
			selection._selection._attrs instanceof Map ?
			selection._selection._attrs : new Map();
		attributes.forEach( ( attributeValue, attribute ) => {
			asMap ? map.set( attribute, attributeValue ) : obj[ attribute ] = attributeValue;
		} );
		return asMap ? map : obj;
	}

	getLiveValue( attribute ) {
		if ( !Validator.isString( attribute ) ) return void 0;
		const editor = this.editor;
		if ( !Validator.isObjectPath( editor, "editor.model.document.selection._selection" ) ||
			!Validator.isMap( editor.model.document.selection._selection._attrs, true ) )
			return void 0;
		const selectionAttributes = editor.model.document.selection._selection._attrs;
		return selectionAttributes.has( attribute ) ?
			selectionAttributes.get( attribute ) : void 0;
	}

	getPreciseLiveValue( attribute ) {
		if ( !Validator.isString( attribute ) ) return void 0;
		const editor = this.editor;
		if ( !Validator.isObjectPath( editor, "editor.model.document.selection._selection" ) ||
			!Validator.isArray( editor.model.document.selection._selection._ranges, true ) )
			return void 0;
		const mainRange = editor.model.document.selection._selection._ranges[ 0 ];
		if ( !Validator.couldBe( mainRange, "Range" ) ) return void 0;
		const isCollapsed = this.isCollapsed;
		const isBackward = editor.objects.position
			._isFirstPositionAfterSecond( mainRange.start, mainRange.end );
		if ( !isCollapsed && !Validator.isBoolean( isBackward ) ) return void 0;
		const position = isCollapsed ?
			( Validator.couldBe( mainRange.start, "Position" ) ? mainRange.start : mainRange.end ) :
			isBackward ?
			( Validator.couldBe( mainRange.end, "Position" ) ? mainRange.end : void 0 ) :
			( Validator.couldBe( mainRange.start, "Position" ) ? mainRange.start : void 0 );
		if ( !Validator.couldBe( position, "Position" ) ) return void 0;
		let node = position.textNode;
		if ( !Validator.couldBe( node, "Text" ) )
			node = isCollapsed ? position.nodeBefore : position.nodeAfter;
		if ( !Validator.couldBe( node, "Text" ) ||
			!Validator.isMap( node._attrs, true ) ) return void 0;
		return node._attrs.has( attribute ) ? node._attrs.get( attribute ) : void 0;
	}

	_setCurrentAttributes( attributesMap, selection = null ) {
		if ( !attributesMap || !( attributesMap instanceof Map ) || attributesMap.size < 1 ) {
			console.warn( "Could not set attributes on selection. Invalid attributes map." );
			return;
		}
		const editor = this.editor;
		if ( !selection || getObjectProto( selection ).indexOf( "Selection" ) < 0 ) {
			selection = editor.model.document.selection;
		}
		attributesMap.forEach( ( value, key ) => {
			selection._setAttribute( key, value );
		} );
	}

	update() {
		if ( this.frozen ) return;
		// if ( !this.isFocused ) return;
		this.updateSelected( true, true, true );
		// this.updateInModel();
		// this.updateInView();
		// this.updateLastPaths();
		this.nullified = false;
	}

	updateSelected( inModel = false, inView = false, paths = false ) {
		if ( this.frozen ) return;
		// if ( !this.isFocused ) return;
		if ( typeof inModel == "boolean" && inModel ) {
			this.updateInModel();
			this.nullified = false;
		}
		if ( typeof inView == "boolean" && inView ) this.updateInView();
		if ( typeof paths == "boolean" && paths ) this.updateLastPaths();
	}

	freeze() {
		this.frozen = true;
		this._log( "Froze editor objects selection ( #freeze )." );
		// console.debug( "Editor.objects.selection is frozen and will not update " +
		// 	"anymore untill it's revived." );
	}

	revive() {
		this.frozen = false;
		this._log( "Revived editor objects selection ( #revive )." );
		// console.debug( "Editor.objects.selection is revived and will update regularly." );
	}

	reviveAndRestore() {
		this.revive();
		if ( !this.restoreSelection() ) {
			console.warn( "Could not restore CK Editor 5 selection after revival." );
		}
	}

	harmonize( force = false ) {
		// TODO does same as _restoreLastSelection
		if ( force ) {
			return this._forceSetTo( this.last.model );
		}
		return this._setTo( this.last.model );
	}

	harmonizeIf( force = false ) {
		if ( this.nullified ) return;
		if ( !this.last || typeof this.last != "object" ||
			!this.last.model || typeof this.last.model != "object" ||
			!( this.last.model._ranges instanceof Array ) || this.last.model._ranges.length < 1 ||
			!this.editor.objects.position.exists( this.last.model._ranges[ 0 ].start ) ||
			!this.editor.objects.position.exists( this.last.model._ranges[ 0 ].end ) ||
			!this.editor.objects.position.exists( this.last.model.anchor ) ||
			!this.editor.objects.position.exists( this.last.model.focus ) ) return;
		return this.harmonize( force );
	}

	_forceHarmonize() {
		return this.harmonizeIf( true );
	}

	_forceSynchronize() {
		if ( this.nullified ) return;
		if ( !this.last || typeof this.last != "object" || !this.last.paths ||
			typeof this.last.paths != "object" ) return;
		if ( ( !this.last.paths.anchor || typeof this.last.paths.anchor != "object" ) &&
			( !this.last.paths.focus || typeof this.last.paths.focus != "object" ) ) return;
		let isSelectionBackward = !!this.editor.objects.position
			._isFirstArrayAfterSecond( this.last.paths.anchor, this.last.paths.focus );
		return this._forceSetTo( {
			start: isSelectionBackward ? this.last.paths.focus : this.last.paths.anchor,
			end: isSelectionBackward ? this.last.paths.anchor : this.last.paths.focus
		} );
	}

	nullify() {
		if ( !this.last || typeof this.last != "object" || !this.last.model ||
			typeof this.last.model != "object" || !this.last.model.setTo ||
			typeof this.last.model.setTo != "function" ) return;
		this.last.model.setTo( null );
		this.nullified = true;
		this._log( "Nullified last model selection ( #nullify )." );
	}

	_nullifyLive() {
		if ( typeof this.editor != "object" || typeof this.editor.model != "object" ||
			typeof this.editor.model.document != "object" || typeof this.editor.model.document.selection != "object" ||
			typeof this.editor.model.document.selection._setTo != "function" ) return;
		this.editor.model.document.selection._setTo( null );
		this._log( "Nullified live document selection ( #nullifyLive )." );
	}

	updateInModel( withAttributes = true ) {
		if ( typeof this.last != "object" ) return;
		this.last.model = this._copyCurrentModelSelection( !!withAttributes );
		this.nullified = false;
		this._log( "Set last model selection ( #updateInModel )." );
	}

	updateInView() {
		if ( typeof this.last != "object" ) return;
		this.last.view = this._copyCurrentViewSelection();
		// this._log( "Set last view position ( #updateInView )." );
	}

	updateLastPaths() {
		this.updateLastAnchorPath();
		this.updateLastFocusPath();
	}

	updateLastAnchorPath() {
		if ( !this.last || typeof this.last != "object" || !this.last.paths ||
			typeof this.last.paths != "object" ) return;
		const editor = this.editor;
		if ( !editor.model || typeof editor.model != "object" || !editor.model.document ||
			typeof editor.model.document != "object" || !editor.model.document.selection ||
			typeof editor.model.document.selection != "object" ) return;
		let anchor = editor.model.document.selection.anchor;
		if ( !anchor || getObjectProto( anchor ).indexOf( "Position" ) < 0 ) {
			anchor = editor.model.document.selection._ranges instanceof Array &&
				editor.model.document.selection._ranges.length > 0 &&
				!!editor.model.document.selection._ranges[ 0 ] &&
				typeof editor.model.document.selection._ranges[ 0 ] == "object" ?
				editor.model.document.selection._ranges[ 0 ].start : null;
			if ( !anchor || getObjectProto( anchor ).indexOf( "Position" ) < 0 ) return;
		}
		if ( !editor.objects.position._pathExists( anchor.path ) ) return;
		this.last.paths.anchor = [ ...anchor.path ];
		// this._log( "Set last anchor path ( #updateLastAnchorPath )." );
	}

	updateLastFocusPath() {
		if ( !this.last || typeof this.last != "object" || !this.last.paths ||
			typeof this.last.paths != "object" ) return;
		const editor = this.editor;
		if ( !editor.model || typeof editor.model != "object" || !editor.model.document ||
			typeof editor.model.document != "object" || !editor.model.document.selection ||
			typeof editor.model.document.selection != "object" ) return;
		let focus = editor.model.document.selection.focus;
		if ( !focus || getObjectProto( focus ).indexOf( "Position" ) < 0 ) {
			focus = editor.model.document.selection._ranges instanceof Array &&
				editor.model.document.selection._ranges.length > 0 &&
				!!editor.model.document.selection._ranges[ 0 ] &&
				typeof editor.model.document.selection._ranges[ 0 ] == "object" ?
				editor.model.document.selection._ranges[ 0 ].end : null;
			if ( !focus || getObjectProto( focus ).indexOf( "Position" ) < 0 ) return;
		}
		if ( !editor.objects.position._pathExists( focus.path ) ) return;
		this.last.paths.focus = [ ...focus.path ];
		// this._log( "Set last focus path ( #updateLastFocusPath )." );
	}

	// TODO redundant code
	getLastInModel() {
		return this.last.model || this.editor.model.document.selection;
	}

	get lastInModel() {
		return this.last ? this.last.model : this.editor.model.document.selection;
	}

	// TODO redundant code
	getLastInView() {
		return this.last.view || this.editor.editing.view.document.selection;
	}

	get lastInView() {
		return this.last ? this.last.view : this.editor.editing.view.document.selection;
	}

	get lastBlocks() {
		let lastSelection = this.lastInModel;
		if ( !this._isValidObjOrPath( lastSelection ) ||
			typeof lastSelection.getSelectedBlocks != "function" ) return [];
		let selectedBlocks = [];
		try {
			selectedBlocks = [ ...lastSelection.getSelectedBlocks() ];
		} catch ( e ) {
			console.warn( "Couldn't get last selected blocks. Error:" );
			console.warn( e );
			selectedBlocks = [];
		}
		return selectedBlocks;
	}

	duplicateLastInModel() {
		return this.editor.model.createSelection( this.last.model );
	}

	getLastPosition( inModel = true, anchor = true ) {
		let selection = inModel ? this.getLastInModel() : this.getLastInView();
		return anchor ? ( selection.anchor || ( selection._ranges instanceof Array &&
				selection._ranges.length > 0 && !!selection._ranges[ 0 ] ?
				selection._ranges[ 0 ].start : null ) ) :
			( selection.focus || ( selection._ranges instanceof Array &&
				selection._ranges.length > 0 && !!selection._ranges[ 0 ] ?
				selection._ranges[ 0 ].end : null ) );
	}

	getLastPath( anchor = true ) {
		return this.getLastPosition( true, anchor ).path;
	}

	getLastAttributes() {
		return this.getLastInModel()._attrs;
	}

	_getMainRoot() {
		return getMainRoot( this.editor );
	}

	_pathToPosition( path ) {
		return new ModelPosition( this._getMainRoot(), path );
	}

	_isBackward( selection ) {
		return this.editor.objects.position._isSelectionBackward( selection );
	}

	get backward() {
		return this._isBackward( this.last.model );
	}

	get originalBackward() {
		return this._isBackward( this.editor.model.document.selection );
	}

	get isInsideTable() {
		if ( !this._isValidObjOrPath( this.editor,
				"editor.model.document.selection" ) ) return false;
		let selection = this.editor.model.document.selection;
		if ( !selection || !this._isValidObjOrPath( selection,
				"selection.anchor.parent" ) ||
			!this._isValidObjOrPath( selection, "selection.focus.parent" ) ) return false;
		return isInsideTable( selection.anchor.parent ) &&
			isInsideTable( selection.focus.parent );
	}

	get isCollapsed() {
		if ( !this._isValidObjOrPath( this.editor,
				"editor.model.document.selection" ) ) return false;
		return this.editor.model.document.selection.isCollapsed;
	}

	_forceSetLastSelection( newSelection, inModel = true ) {
		inModel ?
			// TODO model selection set this way does not take the attributes
			this.last.model = new ModelSelection( newSelection, 0 ) :
			this.last.view = new ViewSelection( newSelection, 0 );
		if ( inModel )
			this._log( "Forcingly set last model position ( #_forceSetLastSelection )." );
	}

	_setTo( selectable, placeOrOffset = "on", batch = null ) {
		if ( !selectable ) return false;
		let self = this;
		let editor = this.editor;
		let selectableProto = getObjectProto( selectable );
		if ( !batch || getObjectProto( batch ) != "Batch" )
			batch = editor.model.createBatch();
		if ( selectableProto.indexOf( "Selection" ) >= 0 ||
			selectableProto.indexOf( "Range" ) >= 0 ||
			selectableProto.indexOf( "Position" ) >= 0 ) {
			editor.model.enqueueChange( batch, writer => writer.setSelection( selectable ) );
			self.update();
			return true;
		}
		if ( selectableProto == "Element" ) {
			if ( typeof placeOrOffset != "string" ) placeOrOffset = "on";
			editor.model.enqueueChange( batch, writer => writer.setSelection( selectable, placeOrOffset ) );
			self.update();
			return true;
		}
		if ( selectableProto == "Object" && selectable.start instanceof Array &&
			selectable.end instanceof Array ) {
			let start = selectable.start.filter( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number );
			let end = selectable.end.filter( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number );
			if ( editor.objects.position._pathExists( start ) ) {
				let root = self._getMainRoot();
				if ( editor.objects.position._pathExists( end ) ) {
					editor.model.enqueueChange( batch, writer => {
						let startPosition = writer.createPositionFromPath( root, start );
						let endPosition = writer.createPositionFromPath( root, end );
						let range = writer.createRange( startPosition, endPosition );
						writer.setSelection( range );
					} );
					self.update();
					return true;
				}
				editor.model.enqueueChange( batch, writer => {
					let startPosition = writer.createPositionFromPath( root, start );
					let range = writer.createRange( startPosition, startPosition );
					writer.setSelection( range );
				} );
				self.update();
				return true;
			} else if ( editor.objects.position._pathExists( end ) ) {
				let root = self._getMainRoot();
				editor.model.enqueueChange( batch, writer => {
					let endPosition = writer.createPositionFromPath( root, end );
					let range = writer.createRange( endPosition, endPosition );
					writer.setSelection( range );
				} );
				self.update();
				return true;
			}
		}
		if ( selectableProto == "Array" && selectable.length > 0 ) {
			let path = selectable.filter( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number );
			if ( path.length > 0 ) {
				let root = self._getMainRoot();
				editor.model.enqueueChange( batch, writer => {
					let position = writer.createPositionFromPath( root, path );
					writer.setSelection( position );
				} );
				self.update();
				return true;
			}
		}
		console.warn( `Selection not set on object of prototype ${ selectableProto }.` );
		return false;
	}

	_forceSetTo( selectable, placeOrOffset = "on" ) {
		// TODO redundant code
		if ( !selectable ) return false;
		let self = this;
		const editor = this.editor;
		let documentSelection = editor.model.document.selection;
		let selectableProto = getObjectProto( selectable );
		if ( selectableProto.indexOf( "Selection" ) >= 0 ||
			selectableProto.indexOf( "Range" ) >= 0 ||
			selectableProto.indexOf( "Position" ) >= 0 ) {
			documentSelection._setTo( selectable );
			self.update();
			return true;
		}
		if ( selectableProto == "Element" ) {
			if ( typeof placeOrOffset != "string" ) placeOrOffset = "on";
			documentSelection._setTo( selectable, placeOrOffset );
			self.update();
			return true;
		}
		if ( selectableProto == "Object" && selectable.start instanceof Array &&
			selectable.end instanceof Array ) {
			let start = selectable.start.filter( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number );
			let end = selectable.end.filter( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number );
			if ( editor.objects.position._pathExists( start ) ) {
				let root = self._getMainRoot();
				let startPosition = editor.model.createPositionFromPath( root, start );
				if ( editor.objects.position._pathExists( end ) ) {
					let endPosition = editor.model.createPositionFromPath( root, end );
					let range = editor.model.createRange( startPosition, endPosition );
					documentSelection._setTo( range );
					self.update();
					return true;
				}
				documentSelection._setTo( startPosition );
				self.update();
				return true;
			} else if ( editor.objects.position._pathExists( end ) ) {
				let root = self._getMainRoot();
				let endPosition = editor.model.createPositionFromPath( root, end );
				documentSelection._setTo( endPosition );
				self.update();
				return true;
			}
		}
		if ( selectableProto == "Array" && selectable.length > 0 ) {
			let path = selectable.filter( element => typeof element == "number" );
			if ( path.length > 0 ) {
				let root = self._getMainRoot();
				let position = editor.model.createPositionFromPath( root, path );
				documentSelection._setTo( position );
				self.update();
				return true;
			}
		}
		console.warn( `Selection not forcingly set on object of prototype ${ selectableProto }.` );
		return false;
	}

	_moveToEnd( force = false ) {
		let root = this._getMainRoot();
		if ( !root ) return;
		let lastIndex = root._children._nodes.length - 1;
		let lastRow = root._children._nodes[ lastIndex ];
		let offset = !!lastRow && typeof lastRow == "object" ? ( lastRow.maxOffset || 0 ) : 0;
		force ? this._forceSetTo( [ lastIndex, offset ] ) : this._setTo( [ lastIndex, offset ] );
	}

	_restoreLastSelection( batch = null ) {
		// TODO does same as harmonize
		if ( !this.last || !this.last.model ) return;
		// let self = this;
		this._setTo( this.last.model );
		// let editor = this.editor;
		// if ( !batch || getObjectProto( batch ) != "Batch" )
		// 	batch = editor.model.createBatch();
		// editor.model.enqueueChange( batch, writer => {
		// 	writer.setSelection( self.last.model );
		// } );
	}

	_doWithSelectionRestoration( functionObject, parameterList = [], thisInstance = null, restore = true ) {
		const editor = this.editor;
		if ( !editor || typeof editor != "object" ) return;
		const dataProcessor = editor.data.processor || editor.getPsaDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) return;
		this.freeze();
		dataProcessor._executeFunction( functionObject, parameterList, thisInstance );
		if ( restore ) this.restoreSelection();
		this.revive();
	}

	restoreSelection() {
		let success = true;
		const editor = this.editor;
		if ( !editor || typeof editor != "object" ) return;
		const dataProcessor = editor.data.processor || editor.getPsaDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) return;
		let synchronizeSelection = () => {
			return this._forceSynchronize();
		}
		let harmonizeSelection = () => {
			return this._forceHarmonize();
		}
		if ( !dataProcessor._tryCatch( synchronizeSelection ) ) {
			success = false;
			if ( dataProcessor._tryCatch( harmonizeSelection ) ) {
				success = true;
			}
		}
		return success;
	}

	_setToFirstFocusRange() {
		if ( !this.firstFocusRange ) return;
		let objectsPosition = this.objectsPosition;
		if ( !Validator.isObject( objectsPosition ) ||
			!Validator.isFunction( objectsPosition.rangeExists ) ) return;
		if ( !objectsPosition.rangeExists( this.firstFocusRange ) ) return;
		FunctionHelper.tryCatch( {
			functionObject: () => { this._setTo( this.firstFocusRange ); },
			logError: true,
			additionalMessage: "Could not set selection to first focus range.",
			rethrowError: true,
			wdgId: this.editor.wdgId
		} );
		this.firstFocusRange = void 0;
		this._log( "Selection set to first focus range & first focus range nullified ( #_setToFirstFocusRange )." );
	}

	_forceSetFirstFocusRange( startPath, endPath, verify = true ) {
		this.firstFocusRange = this.editor.objects.position._pathsToRange(
			startPath, endPath, verify );
		this._log( "First focus range forcingly set ( #_forceSetFirstFocusRange )." );
	}

	_setToFakeFocusRange() {
		if ( !this.fakeFocusRange ) return;
		let objectsPosition = this.objectsPosition;
		if ( !Validator.isObject( objectsPosition ) ||
			!Validator.isFunction( objectsPosition.rangeExists ) ) return;
		if ( !objectsPosition.rangeExists( this.fakeFocusRange ) ) return;
		FunctionHelper.tryCatch( {
			functionObject: () => { this._setTo( this.fakeFocusRange ); },
			logError: true,
			additionalMessage: "Could not set selection to fake focus range.",
			rethrowError: true,
			wdgId: this.editor.wdgId
		} );
		this.fakeFocusRange = void 0;
		this._log( "Selection set to fake focus range & fake focus range nullified ( #_setToFakeFocusRange )." );
	}

	_updateFakeFocusRange() {
		let range = Validator.isObjectPath( this, "this.last.model" ) &&
			Validator.isFunction( this.last.model.getFirstRange ) ?
			this.last.model.getFirstRange() : void 0;
		if ( !Validator.couldBe( range, "Range" ) )
			range = Validator.isObjectPath( this, "this.editor.model.document.selection" ) &&
			Validator.isFunction( this.editor.model.document.selection.getFirstRange ) ?
			this.editor.model.document.selection.getFirstRange() : void 0;
		if ( !Validator.couldBe( range, "Range" ) ) return;
		this.fakeFocusRange = range.clone();
	}

	_setFakeFocusRange( startPath, endPath, verify = true ) {
		this.fakeFocusRange = this.editor.objects.position._pathsToRange(
			startPath, endPath, verify );
		this._log( "Fake focus range set ( #_setFakeFocusRange )." );
	}

	_setToLastFocusPosition() {
		if ( !this.lastFocusPosition ) return;
		let objectsPosition = this.objectsPosition;
		if ( !Validator.isObject( objectsPosition ) ||
			!Validator.isFunction( objectsPosition.exists ) ) return;
		if ( !objectsPosition.exists( this.lastFocusPosition ) ) return;
		FunctionHelper.tryCatch( {
			functionObject: () => { this._setTo( this.lastFocusPosition ); },
			logError: true,
			additionalMessage: "Could not set selection to last focus position.",
			rethrowError: true,
			wdgId: this.editor.wdgId
		} );
		this.lastFocusPosition = void 0;
		this._log( "Selection set to last focus position & last focus position nullified ( #_setToLastFocusPosition )." );
	}

	_domSelectionToView( domSelection ) {
		if ( !( domSelection instanceof Selection ) ) return;
		const editor = this.editor;
		if ( !this._isValidObjOrPath( editor, "editor.editing.view.domConverter" ) ) return;
		if ( !this._isValidFunction( editor.editing.view.domConverter.domSelectionToView ) ) return;
		return editor.editing.view.domConverter.domSelectionToView( domSelection );
	}

	_liveSelectionToView() {
		return this._domSelectionToView( window.getSelection() );
	}

	_viewRangeToModel( viewRange ) {
		if ( !this._isValidObjOrPath( viewRange ) ) return;
		const editor = this.editor;
		if ( !this._isValidObjOrPath( editor, "editor.editing.mapper" ) ) return;
		if ( !this._isValidFunction( editor.editing.mapper.toModelRange ) ) return;
		return editor.editing.mapper.toModelRange( viewRange );
	}

	_viewPositionToModel( viewPosition ) {
		if ( !this._isValidObjOrPath( viewPosition ) ) return;
		const editor = this.editor;
		if ( !this._isValidObjOrPath( editor, "editor.editing.mapper" ) ) return;
		if ( !this._isValidFunction( editor.editing.mapper.toModelPosition ) ) return;
		return editor.editing.mapper.toModelPosition( viewPosition );
	}

	_viewSelectionToViewRange( viewSelection ) {
		if ( !this._isValidObjOrPath( viewSelection ) ) return;
		const editor = this.editor;
		if ( !this._isValidArray( viewSelection._ranges, 1 ) ) return;
		const viewRange = viewSelection._ranges[ 0 ];
		return this._isValidObjOrPath( viewRange ) ? viewRange : void 0;
	}

	_liveSelectionToViewRange() {
		return this._viewSelectionToViewRange( this._liveSelectionToView() );
	}

	_viewSelectionToModelRange( viewSelection ) {
		return this._viewRangeToModel( this._viewSelectionToViewRange( viewSelection ) );
	}

	_liveSelectionToModelRange() {
		return this._viewSelectionToModelRange( this._liveSelectionToView() );
	}

	_viewRangeToViewPosition( viewRange, start = true ) {
		if ( !this._isValidObjOrPath( viewRange ) ) return;
		const editor = this.editor;
		return !!start && this._isValidObjOrPath( viewRange, "viewRange.start" ) ?
			viewRange.start : !start && this._isValidObjOrPath( viewRange, "viewRange.end" ) ?
			viewRange.end : void 0;
	}

	_viewRangeToModelPosition( viewRange, start = true ) {
		return this._viewPositionToModel(
			this._viewRangeToViewPosition( viewRange, start ) );
	}

	_viewSelectionToViewPosition( viewSelection, start = true ) {
		return this._viewRangeToViewPosition(
			this._viewSelectionToViewRange( viewSelection ), start );
	}

	_viewSelectionToModelPosition( viewSelection, start = true ) {
		return this._viewPositionToModel(
			this._viewSelectionToViewPosition( viewSelection, start ) );
	}

	_liveSelectionToViewPosition( start = true ) {
		return this._viewSelectionToViewPosition( this._liveSelectionToView(), start );
	}

	_liveSelectionToModelPosition( start = true ) {
		return this._viewPositionToModel(
			this._liveSelectionToViewPosition( start ) );
	}

	/**
	 * converts a view element to a view or model element
	 * scenarios:
	 * 1. view selection -> view range
	 * 2. view selection -> model range
	 * 3. view selection -> view position
	 * 4. view selection -> model position
	 * 5. view range -> model range
	 * 6. view range -> view position
	 * 7. view range -> model position
	 * 8. view position -> model position
	 * 9. live dom selection to view range or position
	 * or to model range or position
	 * for the case 9. first @param viewElement
	 * needs to be null or undefined
	 * @example viewTo( viewSelection, { to: "position", view: false } )
	 * converts the given view selection to a model position
	 * @example viewTo( null, { to: "range", view: true } )
	 * converts the live DOM selection to view range
	 */
	viewTo( viewElement, options ) {
		if ( !this._isValidObjOrPath( viewElement ) ) {
			viewElement = this._liveSelectionToView();
		}
		if ( !this._isValidObjOrPath( viewElement ) ||
			!this._isValidObjOrPath( options ) ||
			typeof options.to != "string" ) return;
		const to = options.to.toLowerCase();
		if ( to.indexOf( "position" ) < 0 && to.indexOf( "range" ) < 0 ) return;
		let viewProto = getObjectProto( viewElement );
		if ( viewProto.indexOf( "Selection" ) >= 0 ) {
			return to.indexOf( "position" ) >= 0 ?
				( !!options.view ?
					this._viewSelectionToViewPosition( viewElement, options.start != false ) :
					this._viewSelectionToModelPosition( viewElement, options.start != false ) ) :
				( !!options.view ?
					this._viewSelectionToViewRange( viewElement ) :
					this._viewSelectionToModelRange( viewElement ) );
		}
		if ( viewProto.indexOf( "Range" ) >= 0 ) {
			return to.indexOf( "position" ) >= 0 ?
				( !!options.view ?
					this._viewRangeToViewPosition( viewElement, options.start != false ) :
					this._viewRangeToModelPosition( viewElement, options.start != false ) ) :
				( !!options.view ? viewElement :
					this._viewRangeToModel( viewElement ) );
		}
		if ( viewProto.indexOf( "Position" ) >= 0 && to.indexOf( "position" ) >= 0 ) {
			!!options.view ? viewElement : this._viewPositionToModel( viewElement );
		}
		return void 0;
	}

	_isValidObjOrPath( obj, strPath = "", inclInstance = false ) {
		const editor = this.editor;
		if ( !editor || typeof editor != "object" || !editor.data ||
			typeof editor.data != "object" || !editor.data.processor ||
			typeof editor.data.processor != "object" ) return;
		const dataProcessor = editor.data.processor;
		return strPath.length > 0 ?
			editor.data.processor._isValidObjPath( obj, strPath, inclInstance ) :
			editor.data.processor._isValidObj( obj, inclInstance );
	}

	_isValidFunction( func ) {
		return !!func && typeof func == "function" && ( func instanceof Function );
	}

	_isValidArray( arr, minLength = 1 ) {
		return arr && typeof arr == "object" && ( arr instanceof Array ) &&
			arr.length >= minLength;
	}

	getClonedDomRange() {
		if ( !this._isValidObjOrPath( window, "window.document" ) ||
			!this._isValidFunction( window.document.getSelection ) ) return void 0;
		let selection = window.document.getSelection();
		if ( !( selection instanceof Selection ) ||
			!this._isValidObjOrPath( selection ) || selection.rangeCount != 1 ||
			!this._isValidFunction( selection.getRangeAt ) ) return void 0;
		let range = selection.getRangeAt( 0 );
		if ( !( range instanceof Range ) ||
			!this._isValidFunction( range.cloneRange ) ) return void 0;
		return range.cloneRange();
	}

	findElement( elementName, selection = null ) {
		if ( !elementName || typeof elementName != "string" ||
			elementName.length < 1 ) return void 0;
		if ( !this._isValidObjOrPath( this,
				"this.editor.model.schema._sourceDefinitions." + elementName ) ) return void 0;
		const editor = this.editor;
		if ( getObjectProto( selection ).indexOf( "Selection" ) < 0 )
			selection = this.last.model;
		let element = findElementInSelection( this.editor, selection, elementName );
		return !!element && typeof element == "object" ? element : void 0;
	}

	_log( text, trace = false ) {
		if ( !LOG_CHANGES ) return;
		if ( !COMPACT_MODE ) {
			text = "CKE5:\neditor.objects.selection\n %c " + text;
			if ( ADD_TIMESTAMP ) text = "[ " + Warner.getCurrentTime() + " ] " + text;
			if ( ADD_COUNTER ) text = String( this.counter++ ) + ": " + text;
		}
		let style = COMPACT_MODE ? " " : "color: #4db6ac";
		console.groupCollapsed( text, style );
		if ( LOG_CURRENT_STATS ) {
			console.log( "this.editor.model.document.selection:" );
			console.log( this.editor.model.document.selection );
			console.log( "this.last.model:" );
			console.log( this.last.model );
			console.log( "lastFocusPosition:" );
			console.log( this.lastFocusPosition );
			console.log( "firstFocusRange:" );
			console.log( this.firstFocusRange );
			console.log( "fakeFocusRange:" );
			console.log( this.fakeFocusRange );
		}
		console.trace( "Call stack:" );
		console.groupEnd();
	}

	_clearUndoBatchRegistry() {
		if ( !this.undo || typeof this.undo != "object" || !this.undo.editing ||
			typeof this.undo.editing != "object" ||
			!( this.undo.editing._batchRegistry instanceof WeakSet ) ) return;
		this.undo.editing._batchRegistry = new WeakSet();
		this.undo.clearNextBatch = true;
	}

	_clearUndoStack() {
		if ( !this.undo || typeof this.undo != "object" || !this.undo.undoCommand ||
			typeof this.undo.undoCommand != "object" ||
			typeof this.undo.undoCommand.clearStack != "function" ) return;
		this.undo.undoCommand.clearStack();
		this._clearUndoBatchRegistry();
	}

	_clearRedoStack() {
		if ( !this.undo || typeof this.undo != "object" || !this.undo.undoCommand ||
			typeof this.undo.redoCommand != "object" ||
			typeof this.undo.redoCommand.clearStack != "function" ) return;
		this.undo.redoCommand.clearStack();
		this._clearUndoBatchRegistry();
	}

}
