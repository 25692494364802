import * as buttons from '../commandnames';
import * as tooltips from '../tooltipnames';
import Warner from '../pisautils/warner';

const ADD_TIMESTAMP = true;
const ADD_COUNTER = true;
const LOG_CHANGES = true;

export const LANGUAGES = {
	EN: "en",
	DE: "de",
	FR: "fr"
}

class TooltipManager {

	constructor( parentObject ) {
		let self = this;
		parentObject.tooltips = this;
		this.counter = 0;
		this.languages = [];
		Object.entries( LANGUAGES ).forEach( entry => {
			if ( !( entry instanceof Array ) || entry.length < 2 ) return;
			this.languages.push( entry[ 1 ] );
		} );
		this.buttons = [];
		const registerEntry = ( entry ) => {
			if ( !( entry instanceof Array ) || entry.length < 2 ) return;
			if ( !( this.buttons instanceof Array ) )
				return this._log( `Could not register tooltip or button name in the` +
					` tooltip manager's button list, because the list is invalid.` );
			if ( this.buttons.indexOf( entry[ 1 ] ) >= 0 )
				return this._log( `Tooltip or button with name ${ entry[1] } was` +
					` already registered in to the tooltip manager's button list.` );
			this.buttons.push( entry[ 1 ] );
		};
		Object.entries( buttons ).forEach( entry => { registerEntry( entry ); } );
		Object.entries( tooltips ).forEach( entry => { registerEntry( entry ); } );
		this.buttonsMap = new Map();
		this.languagesMap = new Map();
		this._defaultLanguage = this.languages instanceof Array &&
			this.languages.length > 0 ?
			this.languages[ 0 ] : "en";
		// this.current = {};
		// this.buttons.forEach( button => {
		// 	Object.defineProperty( self.current, button, {
		// 		get: () => { return self.getT( button ); },
		// 		set: ( value ) => {
		// 			self._log( `Not allowed to directly change property "${ button }"` +
		// 				` to the value "${ value }".` );
		// 		}
		// 	} );
		// } );
	}

	set language( value ) {
		if ( !this._isValidString( value ) ) return false;
		if ( !( this.languages instanceof Array ) ) return false;
		if ( this.languages.length < 1 ) return false;
		if ( this.languages.indexOf( value ) < 0 ) return false;
		this._defaultLanguage = value;
		return true;
	}

	get language() {
		return this._defaultLanguage;
	}

	_isValidString( value ) {
		return !!value && typeof value == "string" && value.length > 0;
	}

	_canBeATooltip( value ) {
		return this._isValidString( value );
	}

	_canBeALanguage( value ) {
		return this._isValidString( value );
	}

	_canBeAButton( value ) {
		return this._isValidString( value );
	}

	_isValidLanguage( language ) {
		return this.languages instanceof Array && this._canBeALanguage( language ) &&
			this.languages.indexOf( language ) >= 0;
	}

	_isValidButton( button ) {
		return this.buttons instanceof Array && this._canBeAButton( button ) &&
			this.buttons.indexOf( button ) >= 0;
	}

	addLanguage( language ) {
		if ( !( this.languages instanceof Array ) )
			return this._log( 'Could not add another language to an invalid tooltip' +
				' manager language list.', false );
		if ( !this._canBeALanguage( language ) )
			return this._log( 'Could not add an invalid language to the tooltip' +
				' manager.', false );
		if ( this.languages.indexOf( language ) >= 0 )
			return this._log( `The language "${ language }" already exists in the` +
				` tooltip manager language list.`, false );
		this.languages.push( language );
		return true;
	}

	parseLanguage( infoObject, language ) {
		if ( !this._isValidLanguage( language ) )
			return this._log( `Could not add buttons translation configuration for` +
				` an invalid language`, void 0 );
		if ( !infoObject || typeof infoObject != "object" )
			return this._log( `Could not add invalid buttons translation` +
				` configuration for the language "${ language }".`, void 0 );
		let self = this;
		Object.entries( infoObject ).forEach( entry => {
			if ( !( entry instanceof Array ) || entry.length != 2 )
				return self._log( `Could not add invalid buttons translation` +
					` configuration entry for the language "${ language }".`, void 0 );
			self.add( entry[ 0 ], language, entry[ 1 ] );
		} );
	}

	_verify( button, language, tooltip, map ) {
		if ( !( map instanceof Map ) )
			return this._log(
				'Could not add or get a tooltip to an invalid map.', false );
		if ( !this._canBeAButton( button ) )
			return this._log(
				'Could not add or get a tooltip to an invalid button.', false );
		if ( !( this.buttons instanceof Array ) )
			return this._log( `Could not aadd or get a tooltip for the button` +
				` "${ button }". It could not be identified whether the button` +
				` "${ button }" exists inside the editor because of invalid buttons` +
				` list.`, fasle );
		if ( this.buttons.indexOf( button ) < 0 )
			return this._log( `Could not add or get a tooltip for the button` +
				` "${ button }", because the button "${ button }" is not present in` +
				` the tooltip manager's buttons list.`, false );
		if ( !this._canBeALanguage( language ) )
			return this._log( `Could not add or get a tooltip for the button` +
				` "${ button }" in an invalid language.`, false );
		if ( !( this.languages instanceof Array ) )
			return this._log( `Could not add or get a tooltip for the button` +
				` "${ button }" and the language "${ language }". It could not be` +
				` identified whether the language "${ language }" is supported by the` +
				` tooltip manager because of invalid languages list.`, false );
		if ( this.languages.indexOf( language ) < 0 )
			return this._log( `Could not add or get a tooltip for the button` +
				` "${ button }", because the language "${ language }" is not` +
				` supported by the tooltip manager and is not present in the tooltip` +
				` manager's language list.\nSupported languages include:\n` +
				this._getSupportedLanguages(), false );
		if ( !this._canBeATooltip( tooltip ) )
			return this._log( `Could not add or get an invalid tooltip for the` +
				` button "${ button }" and the language "${ language }".`, false );
		return true;
	}

	add( button, language, tooltip, changeIfAlreadyExists = true ) {
		if ( !this._addToButtons(
				button, language, tooltip, changeIfAlreadyExists ) ) return false;
		return this._addToLanguages(
			button, language, tooltip, changeIfAlreadyExists );
	}

	_addToButtons( button, language, tooltip, changeIfAlreadyExists = true ) {
		if ( !this._verify( button, language, tooltip, this.buttonsMap ) ) return false;
		let entry = this.buttonsMap.get( button );
		if ( !entry || typeof entry != "object" ) entry = {};
		if ( this._isValidString( entry[ language ] ) ) {
			if ( !changeIfAlreadyExists )
				return this._log( `The tooltip for the button "${ button }" in the` +
					` language "${ language }" already exists with the value` +
					` "${ entry[ language ] }" and will not be changed to` +
					` "${ tooltip }".`, false );
			this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" already exists and will be changed from` +
				` "${ entry[ language ] }" to "${ tooltip }".` );
		}
		entry[ language ] = tooltip;
		this.buttonsMap.set( button, entry );
		return true;
	}

	_addToLanguages( button, language, tooltip, changeIfAlreadyExists = true ) {
		if ( !this._verify( button, language, tooltip, this.languagesMap ) ) return false;
		let entry = this.languagesMap.get( language );
		if ( !entry || typeof entry != "object" ) entry = {};
		if ( this._isValidString( entry[ button ] ) ) {
			if ( !changeIfAlreadyExists )
				return this._log( `The tooltip for the button "${ button }" in the` +
					` language "${ language }" already exists with the value` +
					` "${ entry[ button ] }" and will not be changed to "${ tooltip }".`, false );
			this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" already exists and will be changed from` +
				` "${ entry[ button ] }" to "${ tooltip }".` );
		}
		entry[ button ] = tooltip;
		this.languagesMap.set( language, entry );
		return true;
	}

	getT( button, language = null ) {
		if ( !this._canBeALanguage( language ) ) language = this.language;
		let tooltip = this._getFromButtons( button, language );
		if ( this._isValidString( tooltip ) ) return tooltip;
		tooltip = this._getFromLanguages( button, language );
		return this._isValidString( tooltip ) ? tooltip : void 0;
	}

	_getCurrentFromButtons( button ) {
		return this._getFromButtons( button, this.language );
	}

	_getFromButtons( button, language ) {
		if ( !this._verify( button, language, "irrelevant value", this.buttonsMap ) )
			return void 0;
		let entry = this.buttonsMap.get( button );
		if ( !entry || typeof entry != "object" )
			return this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" could not be found, because the button` +
				` "${ button }" was not registered in the buttons map.`, void 0 );
		let tooltip = entry[ language ];
		if ( !this._isValidString( tooltip ) )
			return this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" could not be found, because the language` +
				` "${ language }" was not registered for the button "${ button }"` +
				` in the buttons map.`, void 0 );
		return tooltip;
	}

	_getCurrentFromLanguages( button ) {
		return this._getFromLanguages( button, this.language );
	}

	_getFromLanguages( button, language ) {
		if ( !this._verify( button, language, "irrelevant value", this.languagesMap ) )
			return void 0;
		let entry = this.languagesMap.get( language );
		if ( !entry || typeof entry != "object" )
			return this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" could not be found, because the language` +
				` "${ language }" was not registered in the languages map.`, void 0 );
		let tooltip = entry[ button ];
		if ( !this._isValidString( tooltip ) )
			return this._log( `The tooltip for the button "${ button }" in the language` +
				` "${ language }" could not be found, because the button` +
				` "${ button }" was not registered for the language` +
				` "${ language }" in the languages map.`, void 0 );
		return tooltip;
	}

	_getSupportedLanguages() {
		let languages = "";
		if ( !( this.languages instanceof Array ) ) return languages;
		this.languages.forEach( language => {
			languages = languages + ', "' + language + '"';
		} );
		while ( languages.startsWith( "," ) || languages.startsWith( " " ) ) {
			languages = languages.substring( 1 );
		}
		while ( languages.endsWith( "," ) || languages.endsWith( " " ) ) {
			languages = languages.substring( 0, languages.length - 1 );
		}
		return languages;
	}

	_log( text, returnValue, trace = true ) {
		return Warner.outputIf( LOG_CHANGES, returnValue, {
			trace: trace,
			collapse: true,
			text: text,
			pluginName: "TooltipManager",
			color: "#079992",
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

}

export function attachTooltipManager( editor, overwrite = false ) {
	if ( !editor || typeof editor != "object" ) return false;
	editor.objects = editor.objects || {};
	if ( editor.objects.tooltips &&
		typeof editor.objects.tooltips == "object" ) {
		console.warn( `A tooltip manager was already attached to the editor.` +
			` The tooltip manager will not be overwriten, unless specified so.` +
			` Current specification: overwrite = ${ overwrite }` );
		if ( !overwrite ) return false;
	}
	new TooltipManager( editor.objects );
	return true;
}
