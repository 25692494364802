import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaBasics from '../pisabasics/pisabasics';
import { createButton, getSelectionHtml } from '../utils';
import { cutIcon } from '../icons';
import { isPositionInPlaceholder } from '../pisaplaceholder/pisaplaceholderui';

export const CUT = "pisaCut";

export default class PisaCut extends Plugin {

	static get requires() {
		return [ PisaBasics ];
	}

	init() {
		let editor = this.editor;
		editor.objects.lastCopied = editor.objects.lastCopied || "";
		editor.ui.componentFactory.add( CUT, locale => {
			let cutButton = createButton( cutIcon,
				editor.objects.tooltips.getT( CUT ), locale );
			cutButton.on( "execute", () => {
				let position = ( !window.pisasales || !window.pisasales.isTouch ) &&
					editor.objects && editor.objects.selection ?
					editor.objects.selection.getLastPosition() : null;
				// if we can't find position through object.selection, it means we are in TOUCH
				if ( position && isPositionInPlaceholder( position ) ) return;
				let selectedContent = getSelectionHtml();
				if ( !selectedContent || !selectedContent.plain ||
					selectedContent.plain.length <= 0 ) return;
				editor.objects.lastCopied = selectedContent;
				window.document.execCommand( "cut" );
				editor.balloons.hideAll();
			} );

			editor.objects.focus._addExecuteFocus( cutButton );

			return cutButton;
		} );
	}

}
