import Validator from '../pisautils/validator';
import AttachmentObject from '../pisautils/attachmentobject';

export default class FocusManager extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		Object.defineProperty( hostPlugin, "viewFocusObserver", {
			get: () => {
				if ( !Validator.isObjectPath( hostPlugin.editor, "editor.editing.view" ) ||
					!Validator.isMap( hostPlugin.editor.editing.view._observers ) )
					return void 0;
				const registeredObservers = [
					...hostPlugin.editor.editing.view._observers.values()
				];
				const viewFocusObserver = registeredObservers.find( observer =>
					Validator.is( observer, "FocusObserver" ) );
				return viewFocusObserver;
			},
			set: ( newValue ) => {}
		} );

		Object.defineProperty( hostPlugin, "editorFocusTracker", {
			get: () => {
				if ( !Validator.isObjectPath( hostPlugin.editor, "editor.ui" ) ||
					!Validator.is( hostPlugin.editor.ui.focusTracker, "FocusTracker" ) )
					return void 0;
				return hostPlugin.editor.ui.focusTracker;
			},
			set: ( newValue ) => {}
		} );
	}

}
