
import Command from '@ckeditor/ckeditor5-core/src/command';
import first from '@ckeditor/ckeditor5-utils/src/first';

export const SPELLCHECK = 'pisaSpellcheck';
export const spellcheckTag = "spellcheck";

export default class PisaSpellcheckCommand extends Command {

  constructor( editor ) {
		super( editor, SPELLCHECK );
    this.value = false;
    this.isEnabled = true;
    this._setSpellCheck(editor, false);
	}

	execute( options = {} ) {
    const editor = this.editor;
    if ( typeof options.init === 'boolean' ) {
      this.value = options.init;
    } else {
      this.value = !this.value;
    }
    this._setSpellCheck(this.editor, this.value);
    editor.fire("pisaToggleSpellcheck", this.value);
	}

  _setSpellCheck(editor, on) {
    let editableElement = editor.ui.getEditableElement();
    if ( editableElement ) {
		editableElement.setAttribute("spellcheck", on ? "true" : "false");
		if ( editableElement.parentElement ) {
			editableElement.parentElement.setAttribute("spellcheck", on ? "true" : "false");
		}
    }
  }

}
