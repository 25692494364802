
import Command from '@ckeditor/ckeditor5-core/src/command';
import { getFocusElement, findParentElement, setAttributeOnElement, setAttributeToUndefined, saveLastRange, setSelection, findTableCells, getElementFullSelection } from '../utils';
import { isInsideTable } from '../pisatable/pisadecreasemargincommand';
import { TABLE_CELL } from './pisacellcommand';
import { CELL_PADDING, CELL_BORDER_WIDTH, CELL_BORDER_COLOR, CELL_BORDER_STYLE, CELL_BACKGROUND_COLOR } from './pisacellstyleediting';

export const CELL_STYLE = "pisaCellStyle";

export default class PisaCellStyleCommand extends Command {

  constructor( editor ) {
		super( editor, CELL_STYLE );
	}

  refresh() {
    let selection = this.editor.model.document.selection;
    let selectionHasTable = isInsideTable( selection.anchor.parent );
    if ( !selectionHasTable && selection.anchor.parent.name == "$root" && selection.anchor.path.length == 1 ) {
      let index = selection.anchor.path[0];
      let element = selection.anchor.parent._children._nodes[index];
      selectionHasTable = isInsideTable( element );
    }
    this.isEnabled = selectionHasTable;
  }

  execute( options = {} ) {
    const editor = this.editor;
    let range = saveLastRange( editor );
    if ( options.borderWidth || options.borderWidth == false ) {
      options.border = options.border ? options.border : {};
      options.border.width = options.borderWidth;
    }
    if ( options.borderColor || options.borderColor == false ) {
      options.border = options.border ? options.border : {};
      options.border.color = options.borderColor;
    }
    if ( options.padding || options.border || options.background ||  options.padding == false || options.background == false ) {
      let currentElement = options.focusElement || ( options.source == "button" ? editor.focusElement : null ) || getFocusElement( editor );
      let selection = options.lastSelection || ( options.source == "button" ? editor.lastSelection : null ) || editor.model.document.selection;
      let tableCell = findParentElement( editor, currentElement, TABLE_CELL );
      if ( !tableCell && currentElement.name && currentElement.name == "$root" ) {
        changeWholeTable( editor, options, selection );
      }
      setOptionsOnCell( tableCell, options, editor );
    }
    setSelection( editor, range );
  }
}

function shouldBeRemoved( value ) {
  return ( value == "none" || value == false || value == "false" );
}

function setOrRemove( editor, element, attribute, value ) {
  if ( value || value == false ) {
    if ( shouldBeRemoved( value ) ) {
      setAttributeToUndefined( editor, element, attribute );
    } else {
      value = ( value.indexOf( "px" ) < 0 && ( attribute == CELL_PADDING || attribute == CELL_BORDER_WIDTH ) ) ? value + "px" : value;
      setAttributeOnElement( editor, element, attribute, value );
    }
  }
}

function setOptionsOnCell( tableCell, options, editor ) {
  if ( tableCell ) {
    setOrRemove( editor, tableCell, CELL_PADDING, options.padding );
    setOrRemove( editor, tableCell, CELL_BACKGROUND_COLOR, options.background );
    if ( options.border ) {
      setOrRemove( editor, tableCell, CELL_BORDER_WIDTH, options.border.width );
      setOrRemove( editor, tableCell, CELL_BORDER_COLOR, options.border.color );
    }
  }
  if ( tableCell && options.border && ( options.border.width || options.border.color ) ) {
    if ( options.border.width && options.border.color && shouldBeRemoved( options.border.width ) && shouldBeRemoved( options.border.color ) ) {
      setAttributeToUndefined( editor, tableCell, CELL_BORDER_STYLE );
    } else {
      setAttributeOnElement( editor, tableCell, CELL_BORDER_STYLE, "solid" );
    }
  }
}

function changeWholeTable( editor, options, selection ) {
  let tableCells = findTableCells( editor, selection );
  if ( tableCells && tableCells.length > 0 ) {
    editor.tableCells = tableCells;
  } else {
    tableCells = editor.tableCells;
  }
  for ( let tableCell of tableCells ) {
    setOptionsOnCell( tableCell, options, editor );
  }
}
