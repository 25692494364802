
import Command from '@ckeditor/ckeditor5-core/src/command';
import Position from '@ckeditor/ckeditor5-engine/src/model/position.js';
import { positionAtRoot } from '../utils';

export const SELECTALL = 'pisaSelectAll';

export default class SelectAllCommand extends Command {

  constructor( editor ) {
		super( editor, SELECTALL );
	}

  execute( options = {} ) {

    const editor = this.editor;

    let selection = editor.model.document.selection;
    let startPosition = positionAtRoot( editor );
    let endPosition = positionAtRoot( editor, true );
    let lastRow = selection.anchor.root.childCount; // test if endPosition.path[0] - 1 = lastRow or = lastRow - 1 ???
    let lastChild = selection.anchor.root._children._nodes[lastRow - 1];

    let fullRange = selection.getFirstRange();
    fullRange.start.path[0] = startPosition.path[0];
    fullRange.start.path[1] = 0;
    fullRange.end.path[0] = endPosition.path[0] - 1;
    fullRange.end.path[1] = lastChild.maxOffset;

    if ( fullRange.start.path.length > 2 ) {
      let extraItems = fullRange.start.path.length - 2;
      fullRange.start.path.splice( 2, extraItems );
    }

    if ( fullRange.end.path.length > 2 ) {
      let extraItems = fullRange.end.path.length - 2;
      fullRange.end.path.splice( 2, extraItems );
    }

    selection._setTo(fullRange);

  }
}
