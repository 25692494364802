import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/emoji.css';
import EmptyView from '../pisadropdown/emptyview';
import View from '@ckeditor/ckeditor5-ui/src/view';
import LabelView from '@ckeditor/ckeditor5-ui/src/label/labelview';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import {
	defineButton,
	addClasses,
	addViewDomListener,
	updateLastSelection,
	setToLastSelection,
	closeDropdownOnBlur,
	makeInvisible,
	createButton,
	addViewChildren
} from '../utils';
import { emojiIcon, peopleIcon, foodIcon, natureIcon, objectsIcon, placesIcon, symbolsIcon } from '../icons';
import { isInsidePlaceholder } from '../pisaplaceholder/pisaplaceholderui';

export const EMOJI = "pisaEmoji";

const SMILEYS = "😀 😁 😂 🤣 😃 😄 😅 😆 😉 😊 😋 😎 😍 😘 🥰 😗 😙 😚 🙂 🤗 🤩 🤔 🤨 😐 😑 😶 🙄 😏 😣 😥 😮 🤐 😯 😪 😫 😴 😌 😛 😜 😝 🤤 😒 😓 😔 😕 🙃 🤑 😲 ☹️ 🙁 😖 😞 😟 😤 😢 😭 😦 😧 😨 😩 🤯 😬 😰 😱 🥵 🥶 😳 🤪 😵 😡 😠 🤬 😷 🤒 🤕 🤢 🤮 🤧 😇 🤠 🤡 🥳 🥴 🥺 🤥 🤫 🤭 🧐 🤓 😈 👿 👹 👺 💀 👻 👽 🤖 💩 😺 😸 😹 😻 😼 😽 🙀 😿 😾";
const PEOPLE = "👶 👧 🧒 👦 👩 🧑 👨 👵 🧓 👴 👲 👳‍ 👳‍ 🧕 🧔 👱‍ 👱‍ 👨‍ 👩‍ 👨‍ 👩‍ 👨‍ 👩‍ 👨‍ 👩‍ 🦸‍ 🦸‍ 🦹‍ 🦹‍ 👮‍ 👮‍ 👷‍ 👷‍ 💂‍ 💂‍ 🕵️‍ 🕵️‍ 👩‍⚕️ 👨‍⚕️ 👩‍🌾 👨‍🌾 👩‍🍳 👨‍🍳 👩‍🎓 👨‍🎓 👩‍🎤 👨‍🎤 👩‍🏫 👨‍🏫 👩‍🏭 👨‍🏭 👩‍💻 👨‍💻 👩‍💼 👨‍💼 👩‍🔧 👨‍🔧 👩‍🔬 👨‍🔬 👩‍🎨 👨‍🎨 👩‍🚒 👨‍🚒 👩‍✈️ 👨‍✈️ 👩‍🚀 👨‍🚀 👩‍⚖️ 👨‍⚖️ 👰 🤵 👸 🤴 🤶 🎅 🧙‍ 🧙‍ 🧝‍ 🧝‍ 🧛‍ 🧛‍ 🧟‍ 🧟‍ 🧞‍ 🧞‍ 🧜‍ 🧜‍ 🧚‍ 🧚‍ 👼 🤰 🤱 🙇‍ 🙇‍ 💁‍ 💁‍ 🙅‍ 🙅‍ 🙆‍ 🙆‍ 🙋‍ 🙋‍ 🤦‍ 🤦‍ 🤷‍ 🤷‍ 🙎‍ 🙎‍ 🙍‍ 🙍‍ 💇‍ 💇‍ 💆‍ 💆‍ 🧖‍ 🧖‍ 💅 🤳 💃 🕺 👯‍ 👯‍ 🕴 🚶‍ 🚶‍ 🏃‍ 🏃‍ 👫 👭 👬 💑 👩‍❤️‍👩 👨‍❤️‍👨 💏 👩‍❤️‍💋‍👩 👨‍❤️‍💋‍👨 👪 👨‍👩‍👧 👨‍👩‍👧‍👦 👨‍👩‍👦‍👦 👨‍👩‍👧‍👧 👩‍👩‍👦 👩‍👩‍👧 👩‍👩‍👧‍👦 👩‍👩‍👦‍👦 👩‍👩‍👧‍👧 👨‍👨‍👦 👨‍👨‍👧 👨‍👨‍👧‍👦 👨‍👨‍👦‍👦 👨‍👨‍👧‍👧 👩‍👦 👩‍👧 👩‍👧‍👦 👩‍👦‍👦 👩‍👧‍👧 👨‍👦 👨‍👧 👨‍👧‍👦 👨‍👦‍👦 👨‍👧‍👧 🤲 👐 🙌 👏 🤝 👍 👎 👊 ✊ 🤛 🤜 🤞 ✌️ 🤟 🤘 👌 👈 👉 👆 👇 ☝️ ✋ 🤚 🖐 🖖 👋 🤙 💪 🦵 🦶 🖕 ✍️ 🙏 💍 💄 💋 👄 👅 👂 👃 👣 👁 👀 🧠 🦴 🦷 🗣 👤 👥"
const NATURE = "🐶 🐱 🐭 🐹 🐰 🦊 🦝 🐻 🐼 🦘 🦡 🐨 🐯 🦁 🐮 🐷 🐽 🐸 🐵 🙈 🙉 🙊 🐒 🐔 🐧 🐦 🐤 🐣 🐥 🦆 🦢 🦅 🦉 🦚 🦜 🦇 🐺 🐗 🐴 🦄 🐝 🐛 🦋 🐌 🐚 🐞 🐜 🦗 🕷 🕸 🦂 🦟 🦠 🐢 🐍 🦎 🦖 🦕 🐙 🦑 🦐 🦀 🐡 🐠 🐟 🐬 🐳 🐋 🦈 🐊 🐅 🐆 🦓 🦍 🐘 🦏 🦛 🐪 🐫 🦙 🦒 🐃 🐂 🐄 🐎 🐖 🐏 🐑 🐐 🦌 🐕 🐩 🐈 🐓 🦃 🕊 🐇 🐁 🐀 🐿 🦔 🐾 🐉 🐲 🌵 🎄 🌲 🌳 🌴 🌱 🌿 ☘️ 🍀 🎍 🎋 🍃 🍂 🍁 🍄 🌾 💐 🌷 🌹 🥀 🌺 🌸 🌼 🌻 🌞 🌝 🌛 🌜 🌚 🌕 🌖 🌗 🌘 🌑 🌒 🌓 🌔 🌙 🌎 🌍 🌏 💫 ⭐️ 🌟 ✨ ⚡️ ☄️ 💥 🔥 🌪 🌈 ☀️ 🌤 ⛅️ 🌥 ☁️ 🌦 🌧 ⛈ 🌩 🌨 ❄️ ☃️ ⛄️ 🌬 💨 💧 💦 ☔️ ☂️ 🌊 🌫";
const FOOD = "🍏 🍎 🍐 🍊 🍋 🍌 🍉 🍇 🍓 🍈 🍒 🍑 🍍 🥭 🥥 🥝 🍅 🍆 🥑 🥦 🥒 🥬 🌶 🌽 🥕 🥔 🍠 🥐 🍞 🥖 🥨 🥯 🧀 🥚 🍳 🥞 🥓 🥩 🍗 🍖 🌭 🍔 🍟 🍕 🥪 🥙 🌮 🌯 🥗 🥘 🥫 🍝 🍜 🍲 🍛 🍣 🍱 🥟 🍤 🍙 🍚 🍘 🍥 🥮 🥠 🍢 🍡 🍧 🍨 🍦 🥧 🍰 🎂 🍮 🍭 🍬 🍫 🍿 🧂 🍩 🍪 🌰 🥜 🍯 🥛 🍼 ☕️ 🍵 🥤 🍶 🍺 🍻 🥂 🍷 🥃 🍸 🍹 🍾 🥄 🍴 🍽 🥣 🥡 🥢";
const OBJECTS = "🧥 👚 👕 👖 👔 👗 👙 👘 👠 👡 👢 👞 👟 🥾 🥿 🧦 🧤 🧣 🎩 🧢 👒 🎓 ⛑ 👑 👝 👛 👜 💼 🎒 👓 🕶 🥽 🥼 🌂 🧵 🧶 ⌚️ 📱 📲 💻 ⌨️ 🖥 🖨 🖱 🖲 🕹 🗜 💽 💾 💿 📀 📼 📷 📸 📹 🎥 📽 🎞 📞 ☎️ 📟 📠 📺 📻 🎙 🎚 🎛 ⏱ ⏲ ⏰ 🕰 ⌛️ ⏳ 📡 🔋 🔌 💡 🔦 🕯 🗑 🛢 💸 💵 💴 💶 💷 💰 💳 🧾 💎 ⚖️ 🔧 🔨 ⚒ 🛠 ⛏ 🔩 ⚙️ ⛓ 🔫 💣 🔪 🗡 ⚔️ 🛡 🚬 ⚰️ ⚱️ 🏺 🧭 🧱 🔮 🧿 🧸 📿 💈 ⚗️ 🔭 🧰 🧲 🧪 🧫 🧬 🧯 🔬 🕳 💊 💉 🌡 🚽 🚰 🚿 🛁 🛀 🛀🏻 🛀🏼 🛀🏽 🛀🏾 🛀🏿 🧴 🧵 🧶 🧷 🧹 🧺 🧻 🧼 🧽 🛎 🔑 🗝 🚪 🛋 🛏 🛌 🖼 🛍 🧳 🛒 🎁 🎈 🎏 🎀 🎊 🎉 🧨 🎎 🏮 🎐 🧧 ✉️ 📩 📨 📧 💌 📥 📤 📦 🏷 📪 📫 📬 📭 📮 📯 📜 📃 📄 📑 📊 📈 📉 🗒 🗓 📆 📅 📇 🗃 🗳 🗄 📋 📁 📂 🗂 🗞 📰 📓 📔 📒 📕 📗 📘 📙 📚 📖 🔖 🔗 📎 🖇 📐 📏 📌 📍 ✂️ 🖊 🖋 ✒️ 🖌 🖍 📝 ✏️ 🔍 🔎 🔏 🔐 🔒 🔓";
const PLACES = "🚗 🚕 🚙 🚌 🚎 🏎 🚓 🚑 🚒 🚐 🚚 🚛 🚜 🛴 🚲 🛵 🏍 🚨 🚔 🚍 🚘 🚖 🚡 🚠 🚟 🚃 🚋 🚞 🚝 🚄 🚅 🚈 🚂 🚆 🚇 🚊 🚉 ✈️ 🛫 🛬 🛩 💺 🛰 🚀 🛸 🚁 🛶 ⛵️ 🚤 🛥 🛳 ⛴ 🚢 ⚓️ ⛽️ 🚧 🚦 🚥 🚏 🗺 🗿 🗽 🗼 🏰 🏯 🏟 🎡 🎢 🎠 ⛲️ ⛱ 🏖 🏝 🏜 🌋 ⛰ 🏔 🗻 🏕 ⛺️ 🏠 🏡 🏘 🏚 🏗 🏭 🏢 🏬 🏣 🏤 🏥 🏦 🏨 🏪 🏫 🏩 💒 🏛 ⛪️ 🕌 🕍 🕋 ⛩ 🛤 🛣 🗾 🎑 🏞 🌅 🌄 🌠 🎇 🎆 🌇 🌆 🏙 🌃 🌌 🌉 🌁";
const SYMBOLS = "❤️ 🧡 💛 💚 💙 💜 🖤 💔 ❣️ 💕 💞 💓 💗 💖 💘 💝 💟 ☮️ ✝️ ☪️ 🕉 ☸️ ✡️ 🔯 🕎 ☯️ ☦️ 🛐 ⛎ ♈️ ♉️ ♊️ ♋️ ♌️ ♍️ ♎️ ♏️ ♐️ ♑️ ♒️ ♓️ 🆔 ⚛️ 🉑 ☢️ ☣️ 📴 📳 🈶 🈚️ 🈸 🈺 🈷️ ✴️ 🆚 💮 🉐 ㊙️ ㊗️ 🈴 🈵 🈹 🈲 🅰️ 🅱️ 🆎 🆑 🅾️ 🆘 ❌ ⭕️ 🛑 ⛔️ 📛 🚫 💯 💢 ♨️ 🚷 🚯 🚳 🚱 🔞 📵 🚭 ❗️ ❕ ❓ ❔ ‼️ ⁉️ 🔅 🔆 〽️ ⚠️ 🚸 🔱 ⚜️ 🔰 ♻️ ✅ 🈯️ 💹 ❇️ ✳️ ❎ 🌐 💠 Ⓜ️ 🌀 💤 🏧 🚾 ♿️ 🅿️ 🈳 🈂️ 🛂 🛃 🛄 🛅 🚹 🚺 🚼 🚻 🚮 🎦 📶 🈁 🔣 ℹ️ 🔤 🔡 🔠 🆖 🆗 🆙 🆒 🆕 🆓 0️⃣ 1️⃣ 2️⃣ 3️⃣ 4️⃣ 5️⃣ 6️⃣ 7️⃣ 8️⃣ 9️⃣ 🔟 🔢 #️⃣ *️⃣ ⏏️ ▶️ ⏸ ⏯ ⏹ ⏺ ⏭ ⏮ ⏩ ⏪ ⏫ ⏬ ◀️ 🔼 🔽 ➡️ ⬅️ ⬆️ ⬇️ ↗️ ↘️ ↙️ ↖️ ↕️ ↔️ ↪️ ↩️ ⤴️ ⤵️ 🔀 🔁 🔂 🔄 🔃 🎵 🎶 ➕ ➖ ➗ ✖️ ♾ 💲 💱 ™️ ©️ ®️ 〰️ ➰ ➿ 🔚 🔙 🔛 🔝 🔜 ✔️ ☑️ 🔘 ⚪️ ⚫️ 🔴 🔵 🔺 🔻 🔸 🔹 🔶 🔷 🔳 🔲 ▪️ ▫️ ◾️ ◽️ ◼️ ◻️ ⬛️ ⬜️ 🔈 🔇 🔉 🔊 🔔 🔕 📣 📢 👁‍🗨 💬 💭 🗯 ♠️ ♣️ ♥️ ♦️ 🃏 🎴 🀄️ 🕐 🕑 🕒 🕓 🕔 🕕 🕖 🕗 🕘 🕙 🕚 🕛 🕜 🕝 🕞 🕟 🕠 🕡 🕢 🕣 🕤 🕥 🕦 🕧";

export const SMILEYS_EMOJIS = "pisaSmileysEmojis";
export const PEOPLE_EMOJIS = "pisaPeopleEmojis";
export const NATURE_EMOJIS = "pisaNatureEmojis";
export const FOOD_EMOJIS = "pisaFoodEmojis";
export const OBJECTS_EMOJIS = "pisaObjectsEmojis";
export const PLACES_EMOJIS = "pisaPlacesEmojis";
export const SYMBOLS_EMOJIS = "pisaSymbolsEmojis";

export default class PisaEmoji extends Plugin {

	init() {
		const editor = this.editor;
		const command = editor.commands._commands.get( "enter" );

		editor.ui.componentFactory.add( EMOJI, locale => {
			const dropdownView = createDropdown( locale );
			defineButton( dropdownView.buttonView, emojiIcon,
				editor.objects.tooltips.getT( EMOJI ) );
			// addClasses( dropdownView.panelView );
			closeDropdownOnBlur( dropdownView );
			dropdownView.bind( 'isEnabled' ).to( command );
			dropdownView.buttonView.isToggleable = false;

			dropdownView.buttonView.on( "execute", ( eventInfo ) => {
				// updateLastSelection( editor );
				editor.objects.selection.update();
			} );

			dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, value, oldValue ) => {
				if ( !value || oldValue ) return;
				if ( !isInsidePlaceholder( editor ) ) return;
				eventInfo.stop();
				dropdownView.panelView.set( "isVisible", false );
				console.warn( "Could not open dropdown: position in placeholder." );
			}, { priority: 999 } );

			let onEmojiClick = ( evt ) => {
				let node = evt.target || evt.srcElement || evt.path[ 0 ] || evt.toElement;
				editor.objects.selection._setToDomSelection();
				let lastPath = [ ...editor.objects.selection.last.model.anchor.path ];
				if ( isInsidePlaceholder( editor ) ) return;
				let attributes = editor.objects.selection._copyCurrentAttributes( false );
				let textNode = editor.model.change( writer => {
					return writer.createText( node.innerText, attributes );
				} );
				editor.model.insertContent( textNode );
				// dropdownView.isOpen = false;
				editor.objects.focus.doFocus();
				// editor.objects.selection._setToDomSelection();
				evt.preventDefault();
				evt.stopImmediatePropagation();
				evt.stopPropagation();
				if ( lastPath.length <= 0 ) return;
				lastPath[ lastPath.length - 1 ] = lastPath[ lastPath.length - 1 ] + 2;
				if ( !editor.objects.position._pathExists( lastPath ) ) return;
				editor.objects.selection._setTo( lastPath );
				// let position = editor.objects.position._pathToPosition( lastPath );
				// if ( !editor.objects.position.exists( position ) ) return;
				// editor.model.change( writer => writer.setSelection( position ) );
			}

			let emojiView = new EmptyView( [ 'scrollbar-250' ] );
			let smileysGrid = createEmojiGrid( editor, SMILEYS,
				editor.objects.tooltips.getT( SMILEYS_EMOJIS ), onEmojiClick );
			let peopleGrid = createEmojiGrid( editor, PEOPLE,
				editor.objects.tooltips.getT( PEOPLE_EMOJIS ), onEmojiClick );
			let natureGrid = createEmojiGrid( editor, NATURE,
				editor.objects.tooltips.getT( NATURE_EMOJIS ), onEmojiClick );
			let foodGrid = createEmojiGrid( editor, FOOD,
				editor.objects.tooltips.getT( FOOD_EMOJIS ), onEmojiClick );
			let objectsGrid = createEmojiGrid( editor, OBJECTS,
				editor.objects.tooltips.getT( OBJECTS_EMOJIS ), onEmojiClick );
			let placesGrid = createEmojiGrid( editor, PLACES,
				editor.objects.tooltips.getT( PLACES_EMOJIS ), onEmojiClick );
			let symbolsGrid = createEmojiGrid( editor, SYMBOLS,
				editor.objects.tooltips.getT( SYMBOLS_EMOJIS ), onEmojiClick );
			addViewChildren( emojiView, {
				0: smileysGrid,
				1: peopleGrid,
				2: natureGrid,
				3: foodGrid,
				4: objectsGrid,
				5: placesGrid,
				6: symbolsGrid
			}, true );

			let buttonsGrid = new EmptyView( [ 'pisa-emoji-grid' ] );
			let smileysButton = createTopButton( editor, emojiView, buttonsGrid,
				smileysGrid, editor.objects.tooltips.getT( SMILEYS_EMOJIS ), emojiIcon );
			let peopleButton = createTopButton( editor, emojiView, buttonsGrid,
				peopleGrid, editor.objects.tooltips.getT( PEOPLE_EMOJIS ), peopleIcon );
			let natureButton = createTopButton( editor, emojiView, buttonsGrid,
				natureGrid, editor.objects.tooltips.getT( NATURE_EMOJIS ), natureIcon );
			let foodButton = createTopButton( editor, emojiView, buttonsGrid,
				foodGrid, editor.objects.tooltips.getT( FOOD_EMOJIS ), foodIcon );
			let objectsButton = createTopButton( editor, emojiView, buttonsGrid,
				objectsGrid, editor.objects.tooltips.getT( OBJECTS_EMOJIS ), objectsIcon );
			let placesButton = createTopButton( editor, emojiView, buttonsGrid,
				placesGrid, editor.objects.tooltips.getT( PLACES_EMOJIS ), placesIcon );
			let symbolsButton = createTopButton( editor, emojiView, buttonsGrid,
				symbolsGrid, editor.objects.tooltips.getT( SYMBOLS_EMOJIS ), symbolsIcon );
			addViewChildren( buttonsGrid, {
				0: smileysButton,
				1: peopleButton,
				2: natureButton,
				3: foodButton,
				4: objectsButton,
				5: placesButton,
				6: symbolsButton
			}, true );

			dropdownView.panelView.children.add( buttonsGrid, 0 );
			dropdownView.panelView.children.add( emojiView, 1 );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, EMOJI );

			return dropdownView;
		} );
	}

}

function createTopButton( editor, emojiView, parentView, connectedGrid, tooltip, icon = null ) {
	let topButton = icon ? createButton( icon, tooltip, editor.locale ) :
		createEmojiView( tooltip, [ "pisa-emoji" ] );
	topButton.on( "execute", () => {
		hideAll( editor, emojiView );
		makeVisible( connectedGrid );
		parentView.items._items.forEach( item => {
			item.isOn = false;
		} );
		icon ? topButton.isOn = true : void 0;
	} );
	return topButton;
}

function createEmojiGrid( editor, emojiList, listName, clickCallback ) {
	let emojiGrid = new EmptyView( [ 'pisa-emoji-grid' ] );
	let gridLabel = new LabelView( editor.locale );
	gridLabel.text = listName;
	emojiList.split( " " ).forEach( emoji => {
		let emojiView = createEmojiView( emoji, [ "pisa-emoji" ] );
		addViewDomListener( emojiView, "click", clickCallback );
		emojiGrid.items.add( emojiView );
	} );
	let parentView = new EmptyView();
	parentView.items.add( gridLabel, 0 );
	parentView.items.add( emojiGrid, 1 );
	return parentView;
}

function createEmojiView( unicodeValue, cssClasses = null ) {
	const emojiView = new View();
	emojiView.setTemplate( {
		tag: 'span',
		children: [ { text: unicodeValue } ]
	} );
	cssClasses ? addClasses( emojiView, cssClasses ) : void 0;
	return emojiView;
}

/**
 * @deprecated
 */
function setEmojiView( editor, emojiView ) {
	// bindClickToExecute( emojiView );
	emojiView.on( "execute", () => {
		setToLastSelection( editor );
		editor.model.change( writer => {
			let textNode = writer.createText( emojiView.element.innerText );
			editor.model.insertContent( textNode );
		} );
		updateLastSelection( editor );
	} );
}

function makeVisible( view ) {
	view.element.setAttribute( "style", "visibility:visible;" );
	view.element.removeAttribute( "invisible" );
}

function hideAll( editor, parentView ) {
	parentView.items._items.forEach( item => {
		makeInvisible( editor, item );
	} );
}
