/**
 * PiSA sales data processor for CK Editor 5
 */
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor';
import DataController from '@ckeditor/ckeditor5-engine/src/controller/datacontroller';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import {
	positionAtRoot,
	getObjectProto,
	getRootElement,
	areLiveSelectionRangesValid,
	handleInvalidSelectionRanges
} from '../plugins/utils';
import { isPositionInPlaceholder, setSelectionAfterNode } from '../plugins/pisaplaceholder/pisaplaceholderui';
import { mapToObject } from '../plugins/pisaimageresize/utils';
// import { isBlockFiller, isInlineFiller } from '@ckeditor/ckeditor5-engine/src/view/filler';
import ViewElement from '@ckeditor/ckeditor5-engine/src/view/element';
import ViewText from '@ckeditor/ckeditor5-engine/src/view/text';
import ViewDocumentFragment from '@ckeditor/ckeditor5-engine/src/view/documentfragment';
import isText from '@ckeditor/ckeditor5-utils/src/dom/istext';
import { provideWithTooltips } from '../plugins/pisatooltips/tooltipprovider';
import HtmHelper from '../plugins/pisautils/htmhelper';
import Validator from '../plugins/pisautils/validator';
import SelectionHelper from '../plugins/pisautils/selectionhelper';
import Warner from '../plugins/pisautils/warner';
import PisaDomConverter from '../plugins/pisaconversion/pisadomconverter';

/**
 * search & replace constants to escape HTML code
 */
const RGX_OPN = /</g;
const RGX_CLS = />/g;
const RPL_OPN = '&lt;';
const RPL_CLS = '&gt;';

const FORCE_REBUILD = '0815'; // change this to force a rebuild...

const EMPTY_TABLE_ROW_REGEX = "<tr[^<>]*>[^<>]*<\/tr>";

// from http://unicode.e-workers.de/unicode3.php
const ZERO_WIDTH_CHARS = [ "&#x200B;", "&#8203;", "&#8288;", "&#x2060;",
	"&zwnj;", "&#8204;", "&#x200C;", "&zwj;", "&#8205;", "&#x200D;", "&#8291;",
	"&#8206;", "&#x200E;", "&#8207;", "&#x200F;", "&#8232;", "&#x2028;", "&#8233;",
	"&#x2029;", "&#8234;", "&#x202A;", "&#8235;", "&#x202B;", "&#8236;", "&#x202C;",
	"&#8237;", "&#x202D;", "&#8238;", "&#x202E;", "&#8289;", "&#x2061;", "&#8290;",
	"&#x2062;", "&#8291;", "&#x2063;", "&#8298;", "&#x206A;", "&#8299;", "&#x206B;",
	"&#8300;", "&#x206C;", "&#8301;", "&#x206D;", "&#8302;", "&#x206E;", "&#8303;",
	"&#x206F;", "&lrm;", "&rlm;", "&shy;"
]

const ZERO_WIDTH_UNICODE = []

export const MAKE_EDITOR_READONLY_EVENT = "makeEditorReadonly";
export const HANDLE_ERROR_EVENT = "watchdogError";
export const HANDLE_MESSAGE = "watchdogMessage";

/**
 * PiSA sales data processor
 * @implements module:engine/dataprocessor/dataprocessor~DataProcessor
 */
export default class PisaDP {

	/**
	 * constructs a new instance
	 * @param {Object} op original data processor
	 */
	constructor( op, editor ) {
		/**
		 * HTML data processor used to process HTML
		 *
		 * @private
		 * @member {module:engine/dataprocessor/htmldataprocessor~HtmlDataProcessor}
		 */
		this._htmlDP = op || editor.data.processor || new HtmlDataProcessor();
		this._domConverter = new PisaDomConverter();
		const self = this;
		this._htmlDP.toData = ( viewFragment ) => {
			this.editor.fire( HANDLE_MESSAGE, {
				message: "The HTML data processor is converting a view fragment into a DOM fragment. #<toData>"
			} );
			// Convert view DocumentFragment to DOM DocumentFragment.
			const domFragment = self._domConverter.viewToDom( viewFragment, document );
			// Convert DOM DocumentFragment to HTML output.
			return this._htmlDP._htmlWriter.getHtml( domFragment );
		}
		if ( !Validator.isObjectPath( editor, "editor.editing.view.domConverter" ) )
			editor.fire( HANDLE_MESSAGE, {
				message: `Could not set the method #<_processDataFromViewText> for the` +
					` view DOM converter when initalising the data processor.`
			} );
		else editor.editing.view.domConverter._processDataFromViewText = ( node ) => {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "The view DOM converter is processing data form view text." +
			// 		" #<_processDataFromViewText>"
			// } );
			return self._domConverter._processDataFromViewText( node );
		};
		this.editor = editor;
		/** "plain text" flag */
		this._plain = false;
		/** "in conversion (HTML --> plain text)" flag */
		this._incnv = false;

		this.imgTemp = {
			htm: null //,
			// count: 0,
			// map: new Map()
		}

		this.isHtmlAllowed = true; //default

		let brk = {};
		brk.div = true;
		brk.p = true;
		brk.li = true;
		brk.tr = true;
		brk.ol = false;
		brk.ul = false;
		brk.font = false;
		brk.span = false;
		this._brktag = Object.freeze( brk );
		let language = !!editor.locale && typeof editor.locale == "object" &&
			!!editor.locale.uiLanguage && typeof editor.locale.uiLanguage == "string" &&
			editor.locale.uiLanguage.length > 0 ? editor.locale.uiLanguage : "en";
		provideWithTooltips( editor, editor.locale.uiLanguage );
	}

	destroy() {
		delete this._brktag;
		delete this._htmlDP;
		delete this.editor;
	}

	set htmlAvailable( isHtmlAllowed ) {
		this.isHtmlAllowed = !!isHtmlAllowed;
	}

	get htmlAvailable() {
		return !!this.isHtmlAllowed;
	}

	get editorReady() {
		const editor = this.editor;
		return Validator.isObject( editor ) && editor.state == "ready";
	}

	/**
	 * Converts the provided HTML markup or plain text string to view tree.
	 *
	 * @param {String} data The data string.
	 * @returns {module:engine/view/documentfragment~DocumentFragment} The converted view element.
	 */
	toView( data ) {
		if ( this._plain ) {
			// render plain text
			return this._renderText( data );
		} else {
			// render HTML using the default data processor
			return this._htmlDP.toView( data );
		}
	}

	/**
	 * Converts the provided {@link module:engine/view/documentfragment~DocumentFragment} to data format &mdash; in this
	 * case to either HTML markup or to a plain text string.
	 *
	 * @param {module:engine/view/documentfragment~DocumentFragment} docFragment the document fragment
	 * @returns {String} resulting text string.
	 */
	toData( docFragment ) {
		// retrieve plain text
		if ( this._plain ) return this._getText( docFragment );
		// retrieve HTML
		let dataFragment;
		let success = true;
		const message = "Could not convert document fragment to data. #<toData>";
		try {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "Converting document fragment to data. #<toData>"
			// } );
			dataFragment = this._htmlDP.toData( docFragment );
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, { error: err, message: message } );
			err.wdgId = this.editor.wdgId;
			success = false;
		}
		if ( !success ) return "";
		if ( !dataFragment ) {
			// this.editor.fire( HANDLE_MESSAGE, { message: message } );
			return "";
		}
		let htmPage;
		const htmPageMessage = "Could not convert data fragment to HTM page. #<_toHtmlPage>";
		try {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "Converting data fragment to HTM page. #<_toHtmlPage>"
			// } );
			htmPage = this._toHtmlPage( dataFragment );
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, {
			// 	error: err,
			// 	message: htmPageMessage
			// } );
			err.wdgId = this.editor.wdgId;
			success = false;
		}
		if ( !success ) return "";
		if ( !htmPage ) {
			// this.editor.fire( HANDLE_MESSAGE, { message: htmPageMessage } );
			return "";
		}
		return htmPage;
	}

	modelToView( modelElementOrFragment ) {
		let dataController;
		let success = true;
		const dataControllerMessage = "Could not perform model to view conversion" +
			" due to missing data controller.";
		try {
			dataController = this.getDataController();
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, {
			// 	error: err,
			// 	message: dataControllerMessage
			// } );
			err.wdgId = this.editor.wdgId;
			console.warn( dataControllerMessage );
			console.warn( err );
			success = false;
		}
		if ( !success ) return void 0;
		if ( !dataController ) {
			// this.editor.fire( HANDLE_MESSAGE, { message: dataControllerMessage } );
			console.warn( dataControllerMessage );
			return void 0;
		}
		let view;
		const viewMessage = "The (native) data controller could not perform model" +
			" to view conversion."
		try {
			view = dataController.toView( modelElementOrFragment );
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, { error: err, message: viewMessage } );
			err.wdgId = this.editor.wdgId;
			console.warn( viewMessage );
			console.warn( err );
			success = false;
		}
		if ( !success ) return void 0;
		if ( !view ) {
			// this.editor.fire( HANDLE_MESSAGE, { message: viewMessage } );
			console.warn( viewMessage );
			return void 0;
		}
		return view;
	}

	modelToHtmStr( modelElementOrFragment, restoreHtmChars = false ) {
		let viewFragment = this.modelToView( modelElementOrFragment );
		let htmStr = this._htmlDP.toData( viewFragment );
		return restoreHtmChars ? this._restoreEscapedHtm( htmStr ) : htmStr;
	}

	/**
	 * a dummy method :-)
	 */
	getBuildTag() {
		return FORCE_REBUILD;
	}

	getDataController() {
		const editor = this.editor;
		if ( editor && editor.data && editor.data ) return editor.data;
		return null;
	}

	/**
	 * returns the "plain text" flag
	 * @returns {Boolean} true if plain text mode is active; false if HTML mode is active
	 */
	isPlain() {
		return this._plain;
	}

	/**
	 * sets the "plain text" flag
	 * @param {Boolean} ptx plain text flag
	 * @param {Editor} editor the editor instance
	 */
	setPlainText( ptx ) {
		if ( !ptx && !this.htmlAvailable && this.editorReady ) {
			Warner.output( {
				trace: true,
				collapse: true,
				error: true,
				color: "#e74c3c",
				errorText: `The attempt to set the editor data processor to HTML mode` +
					` failed. There is a conflict between existing and supported HTML format.`,
				text: `The editor data processor plain flag will not be set to` +
					` "false", because that would mean that the editor goes into HTML` +
					` mode. The "isHtmlAllowed" (also "htmlAvailable") flag is set to` +
					` "false" and does not allow any HTML content. `,
				addTimestamp: true,
				addCounter: false
			} );
			return false;
		}
		return this._setPlainText( ptx );
	}

	/**
	 * sets the "plain text" flag
	 * @param {Boolean} isTextPlain plain text flag
	 */
	_setPlainText( isTextPlain ) {
		const editor = this.editor;
		const previousPlainValue = !!this._plain;
		this._plain = !!isTextPlain;
		if ( previousPlainValue !== this._plain && editor ) {
			// raise event
			editor.fire( 'psaTextMode', this._plain );
		}
		return true;
	}

	/**
	 * returns the "in conversion" flag
	 * @returns {Boolean} true if a HTML to plain text conversion is currently processed; false otherwise
	 */
	isInCnv() {
		return this._incnv;
	}

	/**
	 * sets the  "in conversion" flag
	 * @param {Boolean} cnv the new value of the "in conversion" flag
	 */
	setInCnv( cnv ) {
		this._incnv = cnv || false;
	}

	setContent( content ) {
		// make sure the content is valid
		if ( typeof content != "string" ) {
			console.warn( "CK Editor 5 data could not be set because the provided " +
				"content does not have the required format." );
			return false;
		}
		// "clean" the content before insertion
		let cleanContent = this._getTextWithCleanATags( content );
		cleanContent = this._fillEmptyTableRows( content );
		cleanContent = this._handleWhitespaces( cleanContent );

		if ( !this._isHtmStr( cleanContent ) || this.isPlain() ) {
			// notify all listeners, insert the content, notify again
			this.editor.fire( "beforeSetContent" );
			this.editor.setData( cleanContent );
			this.editor.fire( "editorContentSet" );
			return true;
		}

		let documentFragment = void 0;
		try {
			documentFragment = this.htmToDocFrag( cleanContent );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( "CK Editor 5 data could not be set because the provided " +
				"content could not be converted to a document fragment. Error:" );
			console.warn( err );
			documentFragment = void 0;
		}
		// if ( !documentFragment ) return false;
		if ( !documentFragment )
			return this.fireSetToReadonly( cleanContent, false );
		let processedContent = void 0;
		try {
			processedContent = this.modelToHtmStr( documentFragment );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( "CK Editor 5 data could not be set because the provided " +
				"content could not be converted from a document fragment to a " +
				"HTM string. Error:" );
			console.warn( err );
			processedContent = void 0;
		}
		// if ( typeof processedContent != "string" ) return false;
		if ( typeof processedContent != "string" )
			return this.fireSetToReadonly( cleanContent, false );

		let success = true;
		// notify all listeners, insert the content, notify again
		try {
			this.editor.fire( "beforeSetContent" );
			this.editor.setData( processedContent );
			this.editor.fire( "editorContentSet" );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( err );
			success = false;
			this.fireSetToReadonly( processedContent );
		}
		return success;
	}

	fireSetToReadonly( finalContent, returnValue ) {
		if ( !Validator.isObject( this.editor ) ||
			!Validator.isFunction( this.editor.fire ) ) return returnValue;
		let options = {};
		if ( Validator.isString( finalContent ) ) options.content = finalContent;
		this.editor.fire( MAKE_EDITOR_READONLY_EVENT, options );
		return returnValue;
	}

	insertText( txt, htm, _asis ) {
		Warner.trace( `The method "insertText" of the editor data processor was` +
			` called. Parameters:\ntxt: "${ txt }"\nhtm: ${ htm }\n_asis: ${ _asis }`,
			"PisaDP", 1, null, true, true, true );
		const editor = this.editor;
		let lastCopied = editor.objects ? editor.objects.lastCopied : "";
		// prove if the insertion matches the last thing copied from the editor and
		// if it does, insert that thing to avoid problems with spacing ( only for plain text )
		let frg = !htm && lastCopied && lastCopied.plain &&
			lastCopied.plain.replace( /\s+/g, "" ) == txt.replace( /\s+/g, "" ) ?
			lastCopied.plain : txt;
		// if we are not in plain text mode and there is a html version of the plain text, take it
		!this.isPlain() && !!lastCopied && typeof lastCopied == "object" &&
			frg == lastCopied.plain && lastCopied.html &&
			( frg = lastCopied.html, htm = true );
		// we got HTML text to be inserted
		if ( htm && this.isPlain() && !this.setPlainText( false ) )
			return Warner.trace( `Text insert operation failed because of` +
				` incompatible HTML/Plain text formats.`, "PisaDP", 1, false, true,
				true, true );
		// htm && this.isPlain() && this.setPlainText( false );
		frg = !htm && !_asis && frg.endsWith( '\n' ) ?
			// in this very case we should not fully trim the string at all but just
			// remove one trailing new line, if one...
			frg.substr( 0, frg.length - 1 ) : frg;
		// we got plain text --> create HTML fragment
		frg = !htm ? this._textStrToHtml( frg ) : this._getTextWithCleanATags( frg );
		if ( !frg || frg.length <= 0 ) return;
		// ok, insert HTML fragment
		this._insertHtmStr( frg );
	}

	/**
	 * converts a HTML String Snippet and inserts it at/in current/live/last editor selection
	 * The conversion goes like this:
	 * ( initial ) HTML String Snippet -> DocumentFragment
	 * The insertion goes like this:
	 * try to insert using the model Writer, if failed insert with the standard model method
	 * @param {String} htmStr HTML String Snippet to be inserted
	 * @param {module @ckeditor/ckeditor5-core/src/editor/editor~Editor} editor the
	 * editor instance (where the HTML String Snippet should be inserted)
	 * @param {Boolean} [byModel = false] indicates if the insertion should be done
	 * straight away with the default model method instead of using the Writer.
	 */
	_insertHtmStr( htmStr, byModel = false ) {
		if ( !htmStr || typeof htmStr != "string" ) return;
		// if ( this._isEditorEmpty() ) {
		// 	this.editor.data.set( htmStr );
		// 	return;
		// }
		this._insertDocFrag( this.htmToDocFrag( htmStr ), byModel );
	}

	/**
	 * converts a HTML String Snippet into a model DocumentFragment
	 * @param {String} htm HTML String Snippet to be converted
	 * @return {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * model DocumentFragment if conversion successfull, null otherwise.
	 * The error thrown due to invalid Position can only be fixed internally, that's
	 * why it is inside a try catch block. However, the error sets the editor into
	 * a paralized state so furter editing is not possible.
	 */
	htmToDocFrag( htm ) {
		if ( typeof htm != "string" ) return;
		let modelFragment = void 0;
		let viewFragment = void 0;
		try {
			viewFragment = this._htmlDP.toView( htm );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( "Couldn't convert htm String to Document fragment." +
				"Htm to view conversion failed. Error:" );
			console.warn( err );
			viewFragment = void 0;
		}
		if ( !viewFragment ) return void 0;
		// viewFragment = this.removeBlockElementHierarchy( viewFragment );
		try {
			modelFragment = this.editor.data.toModel( viewFragment );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( "Couldn't convert htm String to Document fragment." +
				"View to model conversion failed. Error:" );
			console.warn( err );
			modelFragment = void 0;
		}
		if ( modelFragment ) return modelFragment;
		try {
			modelFragment = this.editor.data.parse( htm );
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( "Couldn't convert htm String to Document fragment. " +
				"Parsing failed. Error:" );
			console.warn( err );
			modelFragment = void 0;
		}
		return modelFragment;
	}

	removeBlockElementHierarchy( viewFragment ) {
		if ( !Validator.is( viewFragment, "DocumentFragment" ) ||
			!Validator.isArray( viewFragment._children, true ) ) return viewFragment;
		for ( let childIndex = 0; childIndex < viewFragment._children.length; childIndex++ ) {
			const child = viewFragment._children[ childIndex ];
			if ( !Validator.is( child, "Element" ) || child.name != "div" ||
				!Validator.isArray( child._children, 1 ) ) continue;
			const onlyChild = child._children[ 0 ];
			if ( !Validator.is( onlyChild, "Element" ) ||
				onlyChild.name != "blockquote" ) continue;
			onlyChild.parent = viewFragment;
			viewFragment._children[ childIndex ] = onlyChild;
		}
		return viewFragment;
	}

	/**
	 * converts a plain text String Snippet into a model DocumentFragment
	 * @param {String} plainTxt text to be converted
	 * @return {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * model DocumentFragment.
	 */
	plainTxtToDocFrag( plainTxt ) {
		if ( typeof plainTxt != "string" ) return;
		let htmStr = this._textStrToHtml( plainTxt );
		return this.htmToDocFrag( htmStr );
	}

	/**
	 * converts a string Snippet into a model DocumentFragment
	 * @param {String} str string to be converted
	 * @param {Boolean} isPlain (optional) indicates whether or not the passed
	 * string ontains html tags; if not specified, the dataProcessor will take
	 * care of recognizing if the string contains html or not;
	 * @return {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * model DocumentFragment.
	 */
	strToDocFrag( str, isPlain = null ) {
		if ( typeof str != "string" ) return;
		if ( typeof isPlain != "boolean" )
			isPlain = this._isHtmStr( str );
		return isPlain ? this.plainTxtToDocFrag( str ) : this.htmToDocFrag( str );
	}

	/**
	 * converts a HTML String Snippet into a view DocumentFragment
	 * @param {String} htmStr HTML String Snippet to be converted
	 * @return {module @ckeditor/ckeditor5-engine/src/view/documentfragment~DocumentFragment}
	 * view DocumentFragment.
	 */
	htmStrToViewFrag( htmStr ) {
		if ( typeof htmStr != "string" ) return;
		return this._htmlDP.toView( htmStr );
	}

	/**
	 * converts a plain text String Snippet into a view DocumentFragment
	 * @param {String} plainTxt text to be converted
	 * @return {module @ckeditor/ckeditor5-engine/src/view/documentfragment~DocumentFragment}
	 * view DocumentFragment.
	 */
	plainTxtToViewFrag( plainTxt ) {
		if ( typeof plainTxt != "string" ) return;
		let htmStr = this._textStrToHtml( plainTxt );
		return this.htmStrToViewFrag( htmStr );
	}

	/**
	 * converts a string Snippet into a view DocumentFragment
	 * @param {String} str string to be converted
	 * @param {Boolean} isPlain (optional) indicates whether or not the passed
	 * string ontains html tags; if not specified, the dataProcessor will take
	 * care of recognizing if the string contains html or not;
	 * @return {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * view DocumentFragment.
	 */
	strToViewFrag( str, isPlain = null ) {
		if ( typeof str != "string" ) return;
		if ( typeof isPlain != "boolean" )
			isPlain = !this._isHtmStr( str );
		return isPlain ? this.plainTxtToViewFrag( str ) : this.htmStrToViewFrag( str );
	}

	/**
	 * inserts a DocumentFragment at/in current/live/last editor selection
	 * The insertion goes like this:
	 * try to insert using the model Writer, if failed insert with the standard model method
	 * @param {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * docFrag DocumentFragment to be inserted
	 * @param {module @ckeditor/ckeditor5-core/src/editor/editor~Editor} editor the
	 * editor instance (where the HTML String Snippet should be inserted)
	 * @param {Boolean} [byModel = false] indicates if the insertion should be done with the
	 * default model method instead of using the Writer. Not recommended to use when the insertion
	 * is done inside existing html structure, because the default method does not check if
	 * $block elements are inserted inside $block elements (for example paragraphs inside paragraphs).
	 */
	_insertDocFrag( docFrag, byModel = false ) {
		const editor = this.editor;
		if ( this._isEditorEmpty() ) {
			try {
				editor.objects.selection._forceSetTo( [ 0 ] );
			} catch ( err ) {
				err.wdgId = this.editor.wdgId;
				console.warn( err );
			}
		}
		if ( !this._isValidLiveSelection() ) {
			console.warn( "Could not insert document fragment." );
			return;
		}
		if ( byModel ) {
			editor.model.insertContent( docFrag );
			return;
		}
		if ( this.insertDocFrag( docFrag ) ) return;
		editor.model.insertContent( docFrag );
	}

	/**
	 * inserts a DocumentFragment at/in current/live/last editor selection using
	 * the model Writer
	 * @param {module @ckeditor/ckeditor5-core/src/editor/editor~Editor} editor the
	 * editor instance (where the HTML String Snippet should be inserted)
	 * @param {module @ckeditor/ckeditor5-engine/src/model/documentfragment~DocumentFragment}
	 * frg DocumentFragment to be inserted
	 * @return {Boolean} the successfulness of the insertion; true if insertion
	 * was successful, false if insertion failed;
	 */
	insertDocFrag( frg ) {
		const editor = this.editor;
		if ( getObjectProto( frg ) != "DocumentFragment" ) {
			console.warn( "Could not perform writer.insert(); " +
				"The content to be inserted is not a DocumentFragment." );
			return false;
		}
		// if it is only 1 paragraph, let the method in model handle this, because
		// it might be just a fragment of a paragraph (for example, 1 word)
		// we could do that ( editor.model.insertContent( frg ) ) here actually and return true
		if ( frg && frg._children && frg._children._nodes &&
			frg._children._nodes.length <= 1 ) return false;
		let fragmentLinesQuantity = frg && frg._children && frg._children._nodes ?
			Math.max( frg._children._nodes.length, 1 ) : 1;
		let selection = editor.model.document.selection;
		if ( !selection ) {
			console.warn( "Could not insert DocumentFragment using writer.insert(); " +
				"Editor's selection is invalid ( editor.model.document.selection )." );
			return false;
		}
		let range = selection && selection._ranges && selection._ranges.length > 0 ?
			selection._ranges[ 0 ] : null;
		if ( !range ) {
			console.warn( "Could not insert DocumentFragment using writer.insert(); " +
				"Editor's selection ranges are invalid." );
			return false;
		};
		let position = range.start && getObjectProto( range.start ) == "Position" ?
			range.start : ( range.end && getObjectProto( range.end ) == "Position" ?
				range.end : null );
		if ( !position ) {
			console.warn( "Could not insert DocumentFragment using writer.insert(); " +
				"Editor's selection positions are invalid." );
			return false;
		};

		position = this.calibratePosition( position, frg );

		// see where the selection (cursor) should be placed based on the current
		// selection and the number of lines of the document fragment to be
		// inserted; the actual creation of a position happens inside the change
		// block after the actual insertion of the document fragment, so that
		// the position actually exists inside the document
		let newPath = !!position && typeof position == "object" &&
			position.path instanceof Array ? [ ...position.path ] : [];
		![ 1, 4 ].includes( newPath.length ) ? newPath = void 0 :
			newPath[ newPath.length - 1 ] = newPath.slice( -1 ).pop() + fragmentLinesQuantity;

		let successful = false;
		editor.model.change( writer => {
			try {
				writer.insert( frg, position );
				// make sure selection is after insertion
				let newPosition = editor.objects.position._pathToPosition( newPath );

				newPosition ? writer.setSelection( newPosition ) :
					editor.objects.position.selectionBackward() ?
					writer.setSelection( editor.model.document.selection.anchor ) :
					writer.setSelection( editor.model.document.selection.focus );
				// update the selection in objects
				editor.objects.selection.update();
				successful = true;
			} catch ( err ) {
				err.wdgId = this.editor.wdgId;
				console.warn( "Could not insert DocumentFragment using writer.insert(); Error:" );
				console.warn( err );
			}
		} );
		return successful;
	}

	insertImage( args ) {
		Warner.trace( `The method "insertImage" of the editor data processor was` +
			` called`, "PisaDP", 1, null, true, true, true );
		const editor = this.editor;
		// if ( !this.isValidImageDescription( args.alt ) ) {
		// 	console.warn( `Image with description "${args.alt}" and source "${args.url}" ` +
		// 		`could not be inserted due to invalid description.` );
		// 	return;
		// }
		let selection = editor.objects.selection.lastInModel;
		if ( !SelectionHelper.selectionExists( editor, selection ) ) {
			editor.objects.selection.update();
			selection = editor.objects.selection.lastInModel;
			if ( !SelectionHelper.selectionExists( editor, selection ) ) {
				Warner.nameTrace( `The method "insertImage" will not insert any image,` +
					` because the current selection is invalid.` );
				return;
			}
		}
		if ( isPositionInPlaceholder( selection.anchor ) ||
			isPositionInPlaceholder( selection.focus ) ) {
			console.warn( "Could not insert image at current position. " +
				"Position is in placeholder." );
			this.deleteDropParameters();
			return;
		}
		if ( this.isPlain() && !this.setPlainText( false ) )
			return Warner.trace( `Image insert operation failed because of` +
				` incompatible HTML/Plain text formats.`, "PisaDP", 1, false, true,
				true, true );
		// this.isPlain() ? this.setPlainText( false ) : void 0; // switch to HTML mode if in plain text
		let picture = editor.model.change( writer => {
			let pisaImage = writer.createElement( "pisaImage", this.getImageAttributes( args ) );
			writer.setAttribute( "border", "5px solid transparent", pisaImage );
			writer.setAttribute( "id", HtmHelper.generateImageId(), pisaImage );
			editor.objects.images.map.set( pisaImage.getAttribute( "id" ), pisaImage );
			return pisaImage;
		} );
		let range = selection && typeof selection == "object" ?
			selection.getFirstRange() : void 0;
		if ( range && typeof range == "object" && !this.isParentRoot( range.start ) ) {
			editor.model.change( writer => writer.insert( picture, range.start ) );
			this.normalizeCountersAndDeleteParameters();
			return;
		}
		if ( !range || typeof range != "object" || !range.start ||
			typeof range.start != "object" ) {
			editor.model.insertContent( picture );
			this.normalizeCountersAndDeleteParameters();
			return;
		}
		editor.model.change( writer => {
			let paragraph = writer.createElement( "paragraph" );
			writer.insert( paragraph, range.start );
			let newRange = writer.createRangeIn( paragraph );
			writer.insert( picture, newRange.start );
		} );
		this.normalizeCountersAndDeleteParameters();
	}

	isParentRoot( position ) {
		return position && position.parent &&
			( position.parent.name == "$root" || !position.parent.parent );
	}

	isParentOutside( position ) {
		return position && position.parent && position.parent.name == "div";
	}

	isParentCell( position ) {
		return position && position.parent && position.parent.name == "tableCell";
	}

	getParentName( objectElement ) {
		if ( !objectElement || !objectElement.parent || !objectElement.parent.name ) return "";
		return objectElement.parent.name;
	}

	hasElementsWithName( docFrag, name, checkChildren = false ) {
		if ( !docFrag || !docFrag._children || !docFrag._children._nodes ||
			docFrag._children._nodes.length <= 0 ) return false;
		for ( let node of docFrag._children._nodes ) {
			if ( node.name == name ) return true;
			if ( !checkChildren ) continue;
			let hasElements = this.hasElementsWithName( node, name, true );
			if ( hasElements ) return true;
		}
		return false;
	}

	hasOffset( position ) {
		return position && ( position.offset || position.offset == 0 );
	}

	deleteDropParameters() {
		const editor = this.editor;
		delete editor.lastPosition;
		if ( !editor.counters ) return;
		delete editor.counters.insertCount;
		delete editor.counters.dropCount;
	}

	normalizeCountersAndDeleteParameters() {
		const editor = this.editor;
		if ( editor.counters && typeof editor.counters == "object" &&
			typeof editor.counters.insertCount == "number" )
			editor.counters.insertCount++;
		if ( editor.counters.insertCount != editor.counters.dropCount ) return;
		this.deleteDropParameters();
	}

	getImageAttributes( args ) {
		let attributes = {
			src: args.url,
			alt: args.alt
		};
		attributes[ "data-original-source" ] = args.url;
		let dimensions = this.calibrateDimensions( args.width, args.height, 30, 1000 );
		dimensions.width ? attributes.width = dimensions.width : void 0;
		dimensions.height ? attributes.height = dimensions.height : void 0;
		return attributes;
	}

	/**
	 * @deprecated
	 * proves a string parameter to be the exact match of a given regex; used to
	 * make sure images' "alt" attribute was correspondin to a "pisa generated"
	 * alt attribute;
	 * used in:
	 * @see PisaDP#insertImage - used to prevent insertion of images with invalid
	 * alt attribute;
	 * @see PisaImageEditing#init - used during both upcast and downcast element to
	 * element conversion to make sure image view or model elements with invalid
	 * descriptions are not converted;
	 * @param {string} description the image description to be tested for regex conformity
	 * @param {RegExp} [regex=/(PSA_ABI_)?[A-F0-9]{32}/] regular expression to test
	 * the description
	 * @returns {boolean} true if description is an exact match of the regular expression,
	 * false otherwise
	 */
	isValidImageDescription( description, regex = /(PSA_ABI_)?[A-F0-9]{32}/ ) {
		return true;
		if ( !description || typeof description != "string" ) return false;
		let matches = description.match( regex );
		return matches && matches.length > 0 && description == matches[ 0 ];
	}

	calibrateDimensions( width, height, min, max ) {
		let result = {};
		width = this.validateDimension( width, min, max );
		height = this.validateDimension( height, min, max );
		if ( !width && !height ) return result;
		if ( height && ( !width || width == max || width == min ) ) {
			result.height = this.numberToLength( height );
			return result;
		}
		if ( width && ( !height || height == max || height == min ) ) {
			result.width = this.numberToLength( width );
			return result;
		}
		result.height = this.numberToLength( height );
		result.width = this.numberToLength( width );
		return result;
	}

	validateDimension( dmn, min = 30, max = 1000 ) {
		dmn = this.lengthToNumber( dmn );
		if ( !dmn ) return null;
		dmn = dmn < min ? min : ( dmn > max ? max : dmn );
		return dmn;
	}

	lengthToNumber( value ) {
		if ( !value ) return value;
		return Number( value.toString().replace( /[^\d\.\,]+/g, "" ).replace( /[\.\,]+/g, "." ) );
	}

	numberToLength( value, unit = "px" ) {
		if ( !value ) return value;
		return String( value ) + unit;
	}

	normalizePosition( position, writer ) {
		const editor = this.editor;
		if ( this.isParentRoot( position ) || this.isParentCell( position ) ) {
			return this.positionInParagraph( position, writer );
		}
		if ( this.isParentOutside( position ) ) {
			return this.positionInParagraph( positionAtRoot( editor ), writer );
		}
		if ( !this.hasOffset( position ) ) return positon;
		if ( position.parent && position.parent.name == "paragraph" ) {
			return writer.createPositionAt( position.parent, 0 );
		}
		position = !!writer.createPostitionBefore && !!position.parent ?
			writer.createPostitionBefore( position.parent ) :
			( !!position.parent ? editor.model.createPostitionBefore( position.parent ) : position );
		return this.positionInParagraph( position, writer );
	}

	calibratePosition( position, fragment ) {
		// check if you are trying to insert paragraph inside paragraph (which is not allowed)
		if ( this.getParentName( position ) != "paragraph" ||
			!this.hasElementsWithName( fragment, "paragraph" ) ) return position;
		console.warn( "Trying to insert paragraph inside paragraph (not allowed). " +
			"Position will be changed to match rules." );
		const editor = this.editor;
		// paragraph is a $block element and can be only inserted inside $root or tableCell
		// so we have to find the "closest" parent of curent position that is one of those
		let paragraphContainerParent =
			editor.objects.position._getParagraphContainerParent( position );
		// if parent could not be found, insert text at the beginning of the document
		if ( !paragraphContainerParent ) return editor.objects.position.atRoot;

		let isAtLineEnd = editor.objects.position._isAtParentEnd( position );
		let isInsideEmptyLine = editor.objects.position._isPositionParentEmpty( position );
		let isInsideLastLine = editor.objects.position._isLastChild( position.parent );

		let path = paragraphContainerParent.getPath();
		let updatedPositionPath = [ ...position.path.slice( 0, path.length + 1 ) ];
		let updatedPosition = editor.objects.position._pathToPosition( updatedPositionPath );
		if ( !isAtLineEnd || isInsideEmptyLine ) return updatedPosition;
		// from this point on the insertion will always happen after the
		// current item
		if ( isInsideLastLine ) {
			console.warn( "Position is being set at the very end of the document in the " +
				"CK Editor 5, so an empty paragraph will be created for this purposes." );
			return this.editor.model.change( writer => {
				let paragraphElement = writer.createElement( "paragraph" );
				writer.insert( paragraphElement, position.parent, "after" );
				// please make sure that the new position is created before the new
				// paragraph, otherwise there is a risk of inserting an extra whitespace
				// (if the position is created after the paragraph)
				return writer.createPositionAt( paragraphElement, "before" );
			} );
		}
		// from this point on we are sure we are not in the last line and there is
		// another item after the current one at the same level (next sibling)
		let newLastIndex = updatedPosition.path.slice( -1 ).pop();
		if ( typeof newLastIndex != "number" ) return updatedPosition;
		newLastIndex++;
		updatedPositionPath[ updatedPositionPath.length - 1 ] = newLastIndex;
		updatedPosition = editor.objects.position._pathToPosition( updatedPositionPath );
		return updatedPosition;
	}

	positionInParagraph( position, writer ) {
		if ( position && position.parent && position.parent.name == "paragraph" ) return position;
		let paragraph = writer.createElement( "paragraph" );
		writer.insert( paragraph, position );
		let range = writer.createRangeIn( paragraph );
		return range.start;
	}

	/**
	 * escapes (quotes) HTML code so that it will be displayed as literal text
	 * @param {String} s the string to be processed
	 */
	_escapeHtml( s ) {
		if ( !s || s.length <= 0 ) {
			return "";
		}
		return String( s ).replace( /</g, '&lt;' ).replace( />/g, '&gt;' )
			.replace( /^\s/, '&nbsp;' ).replace( /\s$/, '&nbsp;' )
			.replace( /\s\s/g, ' &nbsp;' );
	}

	_removeHtmlTags( text ) {
		return this._restoreEscapedHtm( String( text ).replace( /<[^>]+>/g, "" ) );
		// return String( text ).replace( /<[^>]+>/g, "" ).replace( /&amp;/g, "&" )
		// 	.replace( /&lt;/g, "<" ).replace( /&gt;/g, ">" ).replace( /&nbsp;/g, " " );
	}

	_restoreEscapedHtm( text ) {
		return String( text ).replace( /&amp;/g, "&" )
			.replace( /&lt;/g, "<" ).replace( /&gt;/g, ">" ).replace( /&nbsp;/g, " " );
	}

	_restoreAmpersands( text ) {
		if ( !text || typeof text != "string" || text.length < 1 ) return "";
		while ( text.indexOf( "&amp;" ) >= 0 ) {
			text = text.replace( /&amp;/g, "&" );
		}
		return text;
	}

	_isHtmStr( text ) {
		if ( typeof text != "string" ) return false;
		if ( text.indexOf( "<" ) < 0 || text.indexOf( ">" ) < 0 ) return false;
		if ( text.indexOf( "<html>" ) >= 0 && text.indexOf( "</html>" ) >= 0 ) return true;
		if ( text.indexOf( "<body>" ) >= 0 && text.indexOf( "</body>" ) >= 0 ) return true;
		const HTML_TAGS_REGEX = /\<[\/]?[a-zA-Z]+[0-9]?[^>]*\>/;
		let matches = text.match( HTML_TAGS_REGEX );
		return matches && matches.length > 0;
	}

	isFromExcel( text ) {
		if ( !this._isHtmStr( text ) ||
			text.toLowerCase().indexOf( "excel" ) < 0 ) return false;
		return text.indexOf( 'xmlns:x="urn:schemas-microsoft-com:office:excel"' ) > 0 ||
			!!text.match( /<meta[^>]+content=Excel\.Sheet/g ) ||
			!!text.match( /<meta[^>]+content="Microsoft Excel 15"/g );
	}

	_hasImgTags( text ) {
		if ( !this._isHtmStr( text ) ) return false;
		const IMG_TAGS_REGEX = /\<img[^\>]+src\=\"[^\>\"]+\"[^\>]*>/;
		let matches = text.match( IMG_TAGS_REGEX );
		return matches && matches.length > 0;
	}

	_getTextWithCleanATags( text ) {
		if ( !this._isHtmStr( text ) ) return text;
		let aTags = this._getATags( text );
		if ( !aTags || !( aTags instanceof Array ) || aTags.length < 1 ) return text;
		aTags.forEach( aTag => {
			// the following code automatically assumes the a tag has only one href
			// attribute
			let href = this.getTagAttr( aTag, "a", "href" );
			let newATag = aTag.replace( href, this._restoreAmpersands( href ) );
			text = text.replace( aTag, newATag );
		} );
		return text;
	}

	_getATags( text ) {
		if ( !this._isHtmStr( text ) ) return void 0;
		const regex = new RegExp( `\<a[^\>]+href\=\"[^\>\"]+\"[^\>]*\>`, "gi" );
		return text.match( regex );
	}

	_getImgTags( text ) {
		if ( !this._isHtmStr( text ) ) return void 0;
		// const IMG_TAGS_REGEX = /\<img[^\>]+src\=\"[^\>\"]+\"[^\>]*>/;
		const regex = new RegExp( `\<img[^\>]+src\=\"[^\>\"]+\"[^\>]*\>`, "gi" );
		return text.match( regex );
	}

	getCleanImgTags( text ) {
		if ( !this._isHtmStr( text ) ) return void 0;
		let tags = this._getImgTags( text );
		let imgMap = new Map();
		if ( !tags || !( tags instanceof Array ) || tags.length < 1 ) return imgMap;
		let filesOnly = true;
		tags.forEach( img => {
			let key = img.replace( /\s+/g, " " );
			let source = this.getImgAttr( key, "src" );
			let isFile = source.startsWith( "file://" );
			let isData = source.startsWith( "data/image" ) ||
				source.startsWith( "data:image" );
			let value = {
				src: source,
				alt: this.getImgAttr( key, "alt" ),
				isFile: isFile,
				isData: isData
			}
			if ( !isFile && !isData ) filesOnly = false;
			imgMap.set( key, value );
		} );
		imgMap.set( "filesOnly", filesOnly );
		return imgMap;
	}

	_fillEmptyTableRows( text ) {
		if ( !this._isHtmStr( text ) ) return text;
		let emptyRowRegex = new RegExp( EMPTY_TABLE_ROW_REGEX, "gi" );
		let matches = text.match( emptyRowRegex );
		if ( !Validator.isArray( matches ) || matches.length < 1 ) return text;
		matches.forEach( ( match, matchIndex ) => {
			if ( !Validator.isString( match ) ) return;
			let oldRow = match;
			if ( match.indexOf( ">" ) == match.length - 1 ) return;
			let openingTag = match.substring( 0, match.indexOf( ">" ) + 1 );
			let content = match.substring( openingTag.length );
			if ( content.indexOf( "</tr>" ) < 0 ) return;
			content = content.substring( 0, content.lastIndexOf( "</tr>" ) );
			let newRow = openingTag + "<td>" + content + "</td></tr>";
			text = text.replace( oldRow, newRow );
		} );
		return text;
	}

	/**
	 * creates a HTML fragment from plain text
	 * @param {String} text text to be converted
	 * @param {Boolean} trm flag whether to trim the text
	 * @returns {String} the HTML text created from plain text
	 */
	_createHtmlFrag( text, trm ) {
		// we create some simple HTML from the plain text
		const eft = trm ? ( text || '' ).trimRight() : ( text || '' ); // Edge has no trimEnd() (not yet :-) )
		let html = '';
		eft.split( '\n' ).forEach( line => {
			html += !trm && line.length == 0 ? '<div><br></div>' :
				( '<div>' + this._escapeHtml( line ) + '</div>' );
		} );
		return html;
	}

	/**
	 * creates a HTML string snippet from plain text string without
	 * removing any new-lines or spaces
	 * @see { _createHtmlFrag } - similar function with similar purpose
	 * @param {String} text text to be converted
	 * @returns {String} the HTML text created from plain text
	 */
	_textStrToHtml( text ) {
		let htmStr = "";
		if ( typeof text != "string" ) {
			console.warn( "Non-string parameter will be converted to String to create HTML." );
		}
		String( text ).split( "\n" ).forEach( ( line, id, arr ) => {
			// if the last two lines are empty, insert only one instead of two
			if ( id == arr.length - 1 && line.length <= 0 &&
				id > 0 && arr[ id - 1 ].length <= 0 ) return;
			// if it is an empty line, add a zero-width space
			// else make sure all empty spaces will not get lost
			line = line.length <= 0 ? "" : this._htmlToUnicode( line ).replace( /^\s/, '&nbsp;' )
				.replace( /\s$/, '&nbsp;' ).replace( /\s\s/g, ' &nbsp;' );
			// put the content inside a <div> tag and add it to the end html snippet
			htmStr += "<div>" + line + "</div>";
		} );
		return htmStr;
	}

	_htmlToUnicode( text ) {
		if ( typeof text != "string" ) {
			console.warn( "Trying to convert to unicode a non-string parameter. Operation aborted." );
			return "";
		}
		return text.replace( /&/g, "&amp;" ).replace( /</g, '&lt;' ).replace( />/g, '&gt;' );
	}

	/**
	 * converts a plain text and inserts it at/in current/live/last editor selection
	 * The conversion goes like this:
	 * ( initial ) Plain text String -> HTML String Snippet -> DocumentFragment
	 * does not take care of "cleaning" the initial plain text to remove all
	 * reminiscences of html tags
	 * @param {String} text plain text to be inserted
	 * @param {module @ckeditor/ckeditor5-core/src/editor/editor~Editor} editor the
	 * editor instance (where plain text should be inserted)
	 * @param {Boolean} [byModel = false] indicates if the insertion should be done
	 * straight away with the default model method instead of using the Writer.
	 */
	_insertPlainText( text, byModel = false ) {
		this._insertHtmStr( this._textStrToHtml( text ), byModel );
	}

	/**
	 * renders plain text
	 * @param {String} text the plain text to be rendered
	 */
	_renderText( text ) {
		// we create some simple HTML from the plain text and let the default HTML processor parse this...
		const html = this._createHtmlFrag( text, true );
		return this._htmlDP.toView( html );
	}

	/**
	 * processes a DOM node during plain text conversion
	 * @param {Object} opt options object
	 */
	_processNode( opt ) {
		const node = opt.node;
		if ( node.is( 'element' ) ) {
			this._processElement( opt );
		} else if ( node.is( 'text' ) ) {
			// any prefix?
			if ( this._incnv && opt.list ) {
				// list item - check for ordered or unordered list and add some prefix if appropriate
				const pxf = opt.list === 1 ? ' * ' : ( ' ' + opt.lix + '. ' );
				opt.txt += pxf;
			}
			opt.txt += node.data;
		}
	}

	/**
	 * processes a HTML element during plain text conversion
	 * @param {Object} opt options object
	 */
	_processElement( opt ) {
		const elm = opt.node;
		const cnt = elm.childCount;
		const list = opt.list;
		const lix = opt.lix;
		try {
			let in_list = false;
			if ( this._incnv ) {
				if ( 'ul' === elm.name ) {
					opt.list = 1;
					in_list = true;
				} else if ( 'ol' === elm.name ) {
					opt.list = 2;
					in_list = true;
				}
			}
			for ( let i = 0; i < cnt; ++i ) {
				const chl = elm.getChild( i );
				opt.node = chl;
				if ( in_list ) {
					opt.lix = i + 1;
				}
				this._processNode( opt );
			}
			if ( elm.name && this._brktag[ elm.name ] ) {
				opt.txt += '\n';
			}
		} finally {
			opt.lix = lix;
			opt.list = list;
		}
	}

	/**
	 * returns the plain text contents
	 * @param {module:engine/view/documentfragment~DocumentFragment} docFragment the document fragment
	 * @returns {String} the plain text contents
	 */
	_getText( docFragment ) {
		let opt = {
			txt: '',
			node: null,
			par: null,
			list: 0,
			lix: 0
		};
		const cnt = docFragment.childCount;
		for ( let i = 0; i < cnt; ++i ) {
			const chl = docFragment.getChild( i );
			opt.node = chl;
			this._processNode( opt );
		}
		return opt.txt;
	}

	/**
	 * creates a full HTML document from a HTML fragment
	 * @param {String} htmlSnippet the HTML fragment / snipped
	 * @returns {String} the HTML text
	 */
	_toHtmlPage( htmlSnippet ) {
		if ( htmlSnippet.indexOf( "<html>" ) >= 0 &&
			htmlSnippet.indexOf( "<body>" ) >= 0 &&
			htmlSnippet.indexOf( "</body>" ) >= 0 &&
			htmlSnippet.indexOf( "</html>" ) >= 0 ) {
			return htmlSnippet;
		}
		let result;
		result = this._putInsideTag( htmlSnippet, "body" );
		result = this._putInsideTag( result, "html" );
		result = Validator.removeHiddenCharacters( result );
		// result = this._removeNonPrintableAsciiChars( result );
		// result = this._removeZeroWidthSpaces( result );
		return result;
	}

	/**
	 * puts text content inside a HTML tag ("wraps" the text)
	 * @param {String} content the content to be wrapped
	 * @param {String} tag the HTML tag name
	 * @returns {String} the wrappend content as string
	 */
	_putInsideTag( content, tag ) {
		if ( !content || !tag || typeof content !== "string" || typeof tag !== "string" ) {
			return "";
		}
		tag = tag.replace( /\W+/g, '' );
		return "<" + tag + ">" + content + "</" + tag + ">";
	}

	_removeZeroWidthSpaces( content ) {
		return Validator.removeZeroWidthUnicodeHtmlEntities( content );

		if ( typeof content != "string" || content.length < 1 ) return "";
		ZERO_WIDTH_CHARS.forEach( charStr => {
			let regExp = new RegExp( charStr, "gi" );
			content = content.replace( regExp, "" );
		} );
		return content;
	}

	_removeNonPrintableAsciiChars( content ) {
		return Validator.removeNonPrintableAsciiChars( content );

		if ( typeof content != "string" || content.length < 1 ) return "";
		// C0 unicode control codes except HT and LF
		content = content.replace( /[\u{0000}-\u{0008}]/gu, "" );
		content = content.replace( /[\u{000B}-\u{001F}]/gu, "" );
		// unicode delete
		content = content.replace( /\u007F/gu, "" );
		// C1 unicode control characters
		content = content.replace( /[\u{0080}-\u{009F}]/gu, "" );
		// unicode soft hypen
		content = content.replace( /\u00AD/gu, "" );
		// unicode invisible spaces and marks
		content = content.replace( /[\u{200B}-\u{200F}]/gu, "" );
		// unicode embedding and formatting
		content = content.replace( /[\u{202A}-\u{202E}]/gu, "" );
		// u+206x
		content = content.replace( /[\u{2060}-\u{206F}]/gu, "" );
		return content;
	}

	_getRoot() {
		return getRootElement( this.editor );
	}

	getCleanData() {
		// this.editor.fire( HANDLE_MESSAGE, {
		// 	message: "Editor's clean data is requested. #<getCleanData>"
		// } );
		let data = this._getData() || this.editor.getData();
		data = this._removeImageMouseListeners( data );
		data = this._setTableStyles( data );
		data = this._handleWhitespaces( data );
		data = this._handleEmptyContainers( data );
		return data;
	}

	_getData() {
		let rootElement;
		let success = true;
		const noRootMessage = `Could not get editor data. Unable to find "root" element.`;
		try {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "Editor's root element is requested. #<_getRoot>"
			// } );
			rootElement = this._getRoot();
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, { error: err, message: noRootMessage } );
			err.wdgId = this.editor.wdgId;
			console.warn( noRootMessage + ` Error:` );
			console.warn( err );
			success = false;
		}
		if ( !success ) return "";
		if ( !rootElement ) {
			// this.editor.fire( HANDLE_MESSAGE, { message: noRootMessage } );
			console.warn( noRootMessage );
			return "";
		}
		let viewFragment;
		try {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "Editor's model fragment is converted to a view fragment" +
			// 		" during data request. #<modelToView>"
			// } );
			viewFragment = this.modelToView( rootElement );
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, {
			// 	error: err,
			// 	message: `Could not get editor data. Invalid view fragment.`
			// } );
			err.wdgId = this.editor.wdgId;
			success = false;
			console.warn( `Could not get editor data. Invalid view fragment. Error:` );
			console.warn( err );
		}
		if ( !success ) return "";
		if ( !viewFragment ) {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: `Could not get editor data. Invalid view fragment.`
			// } );
			console.warn( `Could not get editor data. Invalid view fragment.` );
			return "";
		}

		// return this.toData( viewFragment );

		let dataFragment;
		try {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: "Editor's view fragment is converted to a data fragment" +
			// 		" during data request. #<toData>"
			// } );
			dataFragment = this.toData( viewFragment );
		} catch ( err ) {
			// this.editor.fire( HANDLE_ERROR_EVENT, {
			// 	error: err,
			// 	message: `Could not get editor data. Invalid data fragment.`
			// } );
			err.wdgId = this.editor.wdgId;
			console.warn( `Could not get editor data. Invalid data fragment.` );
			console.warn( err );
			success = false;
		}
		if ( !success ) return "";
		if ( !dataFragment ) {
			// this.editor.fire( HANDLE_MESSAGE, {
			// 	message: `Could not get editor data. Invalid data fragment.`
			// } );
			console.warn( `Could not get editor data. Invalid data fragment.` );
			return "";
		}
		return dataFragment;

		// let data = "";
		// try {
		// 	data = this.docFragmentToData( viewFragment );
		// } catch ( e ) {
		// 	console.warn( "The CK5 Editor could not convert the view document fragment" +
		// 		" into string data with the data processor. The conversion with the HTML" +
		// 		" data processor with be used instead. Error:" );
		// 	console.warn( e );
		// 	data = this.toData( viewFragment );
		// }
		//
		// return this._removeZeroWidthSpaces(
		// 	this._removeNonPrintableAsciiChars( data ) );
	}

	_modelToView( model ) {
		if ( !model || typeof model != "object" ) return;
		return this.editor.data.toView( model );
	}

	_viewToData( view ) {
		if ( !view || typeof view != "object" ) return;
		return this._htmlDP.toData( view );
	}

	_modelToData( model ) {
		return this._viewToData( this._modelToView( model ) );
	}

	_rootToView() {
		const root = this._getValidMainRoot();
		return this._modelToView( root );
	}

	_rootToData() {
		const root = this._getValidMainRoot();
		return this._modelToData( root );
	}

	_breaksToBlockTags( htmStr ) {
		if ( typeof htmStr != "string" ) return "";
		let blockTagName = htmStr.indexOf( "<p>" ) >= 0 && htmStr.indexOf( "</p>" ) >= 0 &&
			htmStr.indexOf( "<div>" ) < 0 && htmStr.indexOf( "</div>" ) < 0 ? "p" : "div";
		return htmStr.replace( /<\/*br\/*>/g, ` < /${ blockTagName }><${ blockTagName }>` )
			.replace( /<div><\/div>/g, "<div><br></div>" ).replace( /<p><\/p>/g, "<p><br></p>" );
	}

	_rootToCleanData() {
		return this._breaksToBlockTags( this._rootToData() );
	}

	_rootToCleanView() {
		return this._htmlDP.toView( this._rootToCleanData() );
	}

	_rootToCleanText() {
		return this._getText( this._rootToCleanView() );
	}

	_isValidLiveSelection() {
		const editor = this.editor;
		if ( areLiveSelectionRangesValid( editor ) ||
			setSelectionAfterNode( editor ) ||
			handleInvalidSelectionRanges( editor ) ) return true;
		console.warn( "Editor's live/document selection is invalid." );
		return false;
	}

	_isEditorEmpty() {
		const editor = this.editor;
		const mainRoot = this._getValidMainRoot();
		if ( !mainRoot || mainRoot._children._nodes.length <= 0 ) return false;
		if ( mainRoot._children._nodes.length > 1 ) return false;
		let onlyChild = mainRoot._children._nodes[ 0 ];
		if ( !onlyChild || typeof onlyChild != "object" || onlyChild.name != "paragraph" ) return false;
		if ( !onlyChild._children || typeof onlyChild._children != "object" ||
			!( onlyChild._children._nodes instanceof Array ) ) return false;
		if ( onlyChild._children._nodes.length == 0 ) return true;
		return false;
	}

	_getValidMainRoot() {
		const editor = this.editor;
		const mainRoot = editor.model.document.getRoot();
		if ( !mainRoot || mainRoot.name != "$root" || mainRoot.rootName != "main" ) {
			console.warn( "Could not get Editor's main root." );
			return null;
		}
		if ( !mainRoot._children || typeof mainRoot._children != "object" ||
			!( mainRoot._children._nodes instanceof Array ) ) {
			console.warn( "Editor's main root does not have children." );
			return null;
		}
		return mainRoot;
	}

	_getRootChild( index = 0 ) {
		if ( typeof index != "number" ) return null;
		index = Math.abs( Math.round( index ) );
		const mainRoot = this._getValidMainRoot();
		if ( !mainRoot || mainRoot._children._nodes.length < index + 1 ) return null;
		return mainRoot._children._nodes[ index ];
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	// Solution#4 (chosen because of small amount of code)
	_encode64( unicodeString ) {
		return btoa( encodeURIComponent( unicodeString ).replace( /%([0-9A-F]{2})/g,
			( match, firstPosition ) => {
				return String.fromCharCode( '0x' + firstPosition );
			} ) );
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	// Solution#4 (chosen because of small amount of code)
	_decode64( encodedString ) {
		return decodeURIComponent( atob( encodedString ).split( "" ).map(
			( character ) => {
				return '%' + ( '00' + character.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
			} ).join( "" ) );
	}

	_isEncoded64( encodedString ) {
		if ( typeof encodedString != "string" ) return false;
		if ( encodedString.match( /(\s)/g ) && encodedString.match( /(\s)/g ).length > 0 ) return false;
		let isEncoded = false;
		try {
			this._decode64( encodedString );
			isEncoded = true;
		} catch ( e ) {};
		return isEncoded;
	}

	_mapToObject( map ) {
		return mapToObject( map );
	}

	_executeFunction( functionObject, parameterList = [], thisInstance = null ) {
		if ( typeof functionObject != "function" ||
			!( functionObject instanceof Function ) ||
			!( parameterList instanceof Array ) ) return;
		let functionString = "functionObject.call(thisInstance,";
		let i = 0;
		while ( i < parameterList.length ) {
			functionString += `parameterList[ ${ i++ } ],`;
		}
		functionString = functionString.endsWith( "," ) ?
			functionString.slice( 0, -1 ) + ")" : functionString + ")";
		return eval( functionString );
	}

	_tryCatch( functionObject, parameterList = [], thisInstance = null ) {
		let success = false;
		try {
			let result = this._executeFunction( functionObject, parameterList, thisInstance );
			success = result != false;
		} catch ( err ) {
			err.wdgId = this.editor.wdgId;
			console.warn( `Couldn't execute the function "${ functionObject.name }". Error:` );
			console.warn( err );
			success = false;
		}
		return success;
	}

	_isValidObj( obj, includeInstance = false ) {
		return !!obj && typeof obj == "object" && ( !includeInstance ? true :
			( obj instanceof Object ) );
	}

	_isValidObjHierarchy( parentObject, levelsArray = [], includeInstance = false ) {
		if ( !this._isValidObj( parentObject, includeInstance ) ) return false;
		let currentlyValidating = parentObject;
		for ( let childLevelName of levelsArray ) {
			currentlyValidating = currentlyValidating[ childLevelName ];
			if ( !this._isValidObj( currentlyValidating, includeInstance ) ) return false;
		}
		return true;
	}

	_isValidObjPath( parentObject, pathString = "", includeInstance = false ) {
		let pathArray = pathString.split( "." );
		if ( pathArray.length > 0 ) pathArray.shift();
		return this._isValidObjHierarchy( parentObject, pathArray, includeInstance );
	}

	get domConverter() {
		return this._isValidObjPath( this, "this._htmlDP._domConverter" ) ?
			this._htmlDP._domConverter : void 0;
	}

	// domToView( domNode, options = {} ) {
	// 	const domConverter = this.domConverter;
	// 	if ( !domConverter ) {
	// 		console.warn( "Could not find the dom converter for the HTML data " +
	// 			"processor of CK5 Editor. DOM to View conversion failed." );
	// 		return null;
	// 	}
	// 	if ( options.canBeBlockFiller != false &&
	// 		isBlockFiller( domNode, domConverter.blockFiller ) ) {
	// 		return null;
	// 	}
	//
	// 	const uiElement = domConverter.getParentUIElement( domNode, domConverter._domToViewMapping );
	//
	// 	if ( uiElement ) {
	// 		return uiElement;
	// 	}
	//
	// 	if ( isText( domNode ) ) {
	// 		if ( isInlineFiller( domNode ) ) {
	// 			return null;
	// 		} else {
	// 			const textData = domConverter._processDataFromDomText( domNode );
	//
	// 			return textData === '' ? null : new ViewText( textData );
	// 		}
	// 	} else if ( domConverter.isComment( domNode ) ) {
	// 		return null;
	// 	} else {
	// 		if ( domConverter.mapDomToView( domNode ) ) {
	// 			return domConverter.mapDomToView( domNode );
	// 		}
	//
	// 		let viewElement;
	//
	// 		if ( domConverter.isDocumentFragment( domNode ) ) {
	// 			// Create view document fragment.
	// 			viewElement = new ViewDocumentFragment();
	//
	// 			if ( options.bind ) {
	// 				domConverter.bindDocumentFragments( domNode, viewElement );
	// 			}
	// 		} else {
	// 			// Create view element.
	// 			const viewName = options.keepOriginalCase ? domNode.tagName : domNode.tagName.toLowerCase();
	// 			viewElement = new ViewElement( viewName );
	//
	// 			if ( options.bind ) {
	// 				domConverter.bindElements( domNode, viewElement );
	// 			}
	//
	// 			// Copy element's attributes.
	// 			const attrs = domNode.attributes;
	//
	// 			for ( let i = attrs.length - 1; i >= 0; i-- ) {
	// 				viewElement._setAttribute( attrs[ i ].name, attrs[ i ].value );
	// 			}
	// 		}
	//
	// 		if ( options.withChildren || options.withChildren === undefined ) {
	// 			for ( const child of this.domChildrenToView( domNode, options ) ) {
	// 				viewElement._appendChild( child );
	// 			}
	// 		}
	//
	// 		return viewElement;
	// 	}
	// }
	//
	// * domChildrenToView( domElement, options = {} ) {
	// 	for ( let i = 0; i < domElement.childNodes.length; i++ ) {
	// 		const domChild = domElement.childNodes[ i ];
	// 		if ( domElement.childNodes.length > 1 ) options.canBeBlockFiller == false;
	// 		const viewChild = this.domToView( domChild, options );
	//
	// 		if ( viewChild !== null ) {
	// 			yield viewChild;
	// 		}
	// 	}
	// }

	// viewFragmentToData( viewFragment ) {
	// 	// Convert view DocumentFragment to DOM DocumentFragment.
	// 	const domFragment = this.viewToDom( viewFragment, document );
	//
	// 	// Convert DOM DocumentFragment to HTML output.
	// 	return this._htmlDP._htmlWriter.getHtml( domFragment );
	// }

	// docFragmentToData( docFragment ) {
	// 	if ( this._plain ) {
	// 		// retrieve plain text
	// 		return this._getText( docFragment );
	// 	} else {
	// 		// retrieve HTML
	// 		return this._toHtmlPage( this.viewFragmentToData( docFragment ) );
	// 	}
	// }

	_setImgTempHtm( htm ) {
		if ( typeof htm != "string" ) return;
		this.imgTemp.htm = htm;
	}

	// _setImgTempCount( count ) {
	// 	if ( typeof count != "number" ) return;
	// 	this.imgTemp.count = count;
	// }
	//
	// _setImgTempMap( map ) {
	// 	if ( !( map instanceof Map ) ) return;
	// 	this.imgTemp.map = map;
	// }

	setImgTemp( htm /*, count, map*/ ) {
		htm = htm.replace( /\s+/g, " " );
		this._setImgTempHtm( htm );
		// this._setImgTempCount( count );
		// this._setImgTempMap( map );
	}

	insertImageHtm( images ) {
		if ( !images || typeof images != "object" ) return;
		let txt = this.imgTemp.htm;
		if ( !txt || typeof txt != "string" ) return;
		txt = txt.replace( /\s+/g, " " );
		for ( let imagePropArr of Object.entries( images ) ) {
			let newImgTag = this.getImgWithAltAttr( imagePropArr[ 0 ], imagePropArr[ 1 ] );
			if ( txt.indexOf( imagePropArr[ 0 ] ) < 0 ) {
				// can't find image, search by src attribute
				console.warn( `Image tag "${ imagePropArr[ 0 ] }" was not found in text.` );
				continue;
			}
			txt = txt.replace( imagePropArr[ 0 ], newImgTag );
		}
		if ( this.isPlain() && !this.setPlainText( false ) )
			return Warner.trace( `Image html insert operation failed because of` +
				` incompatible HTML/Plain text formats.`, "PisaDP", 1, false, true,
				true, true );
		// this.setPlainText( false );
		this.insertText( txt, true );
		// this._insertHtmStr( txt );
		this.imgTemp.htm = null;;
	}

	getImgWithAltAttr( imgTagStr, newAltVal ) {
		if ( typeof newAltVal != "string" || typeof imgTagStr != "string" ||
			!imgTagStr.startsWith( "<img " ) || imgTagStr.length < 5 ) return "";
		let newTag = imgTagStr.replace( /alt="[^"]*"/g, "" )
			.replace( /\salt\s/g, "" ).replace( /\salt\>/g, ">" );
		return newTag.slice( 0, 5 ) + 'alt="' + newAltVal + '" ' +
			newTag.slice( 5 );
	}

	getImgAttr( imgTagStr, attrName ) {
		return this.getTagAttr( imgTagStr, "img", attrName );

		if ( typeof attrName != "string" || typeof imgTagStr != "string" ||
			!imgTagStr.startsWith( "<img " ) || imgTagStr.length < 5 ||
			imgTagStr.indexOf( attrName + '=' ) < 0 ) return "";
		imgTagStr = imgTagStr.replace( /\s+/g, " " );
		let result = imgTagStr.slice( imgTagStr.indexOf( attrName + '=' ) +
			attrName.length + '='.length );
		if ( result.startsWith( '"' ) ) {
			result = result.slice( 1 );
			return result.indexOf( '"' ) < 0 ? result :
				result.slice( 0, result.indexOf( '"' ) );
		}
		return result.indexOf( " " ) >= 0 ? result.slice( 0, result.indexOf( ' ' ) ) :
			result.indexOf( ">" ) >= 0 ? result.slice( 0, result.indexOf( '>' ) ) : result;
	}

	getTagAttr( tagStr, tagName, attrName ) {
		if ( typeof attrName != "string" || typeof tagName != "string" ||
			typeof tagStr != "string" ) return "";
		tagStr = tagStr.replace( /\s+/g, " " );
		tagName = tagName.replace( /\s+/g, "" );
		attrName = attrName.replace( /\s+/g, "" );
		if ( tagStr.length < 1 || tagName.length < 1 || attrName < 1 ||
			!tagStr.startsWith( "<" + tagName + " " ) ||
			tagStr.charAt( tagStr.length - 1 ) != ">" ||
			tagStr.indexOf( attrName + '=' ) < 0 ) return "";
		let result = tagStr.slice( tagStr.indexOf( attrName + '=' ) +
			attrName.length + '='.length );
		if ( result.startsWith( '"' ) ) {
			result = result.slice( 1 );
			return result.indexOf( '"' ) < 0 ? result :
				result.slice( 0, result.indexOf( '"' ) );
		}
		return result.indexOf( " " ) >= 0 ? result.slice( 0, result.indexOf( ' ' ) ) :
			result.indexOf( ">" ) >= 0 ? result.slice( 0, result.indexOf( '>' ) ) : result;
	}

	_handleWhitespaces( sourceText ) {
		if ( typeof sourceText != "string" || sourceText.length < 1 ) return "";
		if ( !this._isHtmStr( sourceText ) ) return sourceText;
		// if a non-breaking space is (the only thing) present between two tags in
		// form of the html entity "&nbsp;" and the second is not a closing DIV tag,
		// replace it with the "&#32;" html entity (which corresponds to a normal
		// space) to make sure it is compatible with the data.set
		let changedText = sourceText.replace( />&nbsp;<(?!\/div>)/g, ">&#32;<" );
		return changedText;
	}

	_handleEmptyContainers( sourceText ) {
		if ( typeof sourceText != "string" || sourceText.length < 1 ) return "";
		if ( !this._isHtmStr( sourceText ) ) return sourceText;
		// make sure DIVs always have something inside, so that they are shown
		let changedText = sourceText.replace( /<div><\/div>/g, "<div>&nbsp;<\/div>" );
		return changedText;
	}

	_setTableStyles( sourceText ) {
		if ( typeof sourceText != "string" || sourceText.length < 1 ) return "";
		if ( !this._isHtmStr( sourceText ) ) return sourceText;
		let changedText = sourceText.replace( /<table>/g, '<table style="border-collapse:collapse;">' );
		return changedText;
	}

	_removeImageMouseListeners( sourceText ) {
		if ( !Validator.isString( sourceText ) ) return "";
		if ( !this._isHtmStr( sourceText ) ) return sourceText;
		for ( let mouseListener of [ "onmouseover", "onmouseout", "onclick" ] ) {
			const regularExpression = new RegExp( `${ mouseListener }="[^"]+"`, "g" );
			sourceText = sourceText.replace( regularExpression, "" );
		}
		return sourceText;
	}

}
