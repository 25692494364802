import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FontFamilyCommand from '@ckeditor/ckeditor5-font/src/fontfamily/fontfamilycommand';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import {
	addClasses,
	addViewChildren,
	closeDropdownOnBlur,
	getLiveAttributeValue,
	getLiveNodeStyle,
	createTextButton,
	updateLastSelection,
	addDropdownCloseListener,
	getEditableElement
} from '../utils';
import { fontFamilyIcon } from '../icons';
import { FONT_FAMILY } from './pisafontfamilyediting';
import ListView from '@ckeditor/ckeditor5-ui/src/list/listview';
import ListItemView from '@ckeditor/ckeditor5-ui/src/list/listitemview';
import EmptyView from '../pisadropdown/emptyview';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import Validator from '../pisautils/validator';
import FontFamilyFocusManager from './fontfamilyfocusmanager';

export const FONTS = [ 'Default',
	'Arial, Helvetica, sans-serif',
	'Calibri, Candara, Arial, sans-serif',
	'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
	'Courier New, Courier, monospace',
	'Georgia, Utopia, Palatino, Times, serif',
	'Lucida Sans Unicode, Lucida Grande, sans-serif',
	'Monospace',
	'Segoe UI, Frutiger, Arial, sans-serif',
	'Tahoma, Arial, Helvetica, sans-serif',
	'Times New Roman, Times, serif',
	'Verdana, Geneva, sans-serif'
];
export const SHOW_MORE_FONTS = "pisaShowMoreFonts";
const COMPACT_FONT_FAMILY = "fontFamilyCompact";
const CLASS_NAME = "pisa-compressed-dropdown";
const SCROLL_CLASS_NAME = "psa-scroll";

export default class FontFamilyUI extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		new FontFamilyFocusManager( this );

		const editor = this.editor;

		this.views = { full: [], compact: [] };

		editor.commands.add( FONT_FAMILY, new FontFamilyCommand( editor ) );

		editor.once( "ready", () => {
			this._addSelectionChangeListener();
			if ( Validator.isObject( window.pisasales ) && !window.pisasales.isTouch )
				this._updateToLiveFont( true );
		} );

		// if ( Validator.isObject( window.pisasales ) && !window.pisasales.isTouch )
		// 	editor.on( "editorContentSet", () => {
		// 		this._updateToLiveFont();
		// 	}, { priority: Number.MIN_SAFE_INTEGER } );

		editor.ui.componentFactory.add( FONT_FAMILY, locale => {
			let dropdownView = setFontDropdown( editor );
			dropdownView.hasLabel = false;

			if ( Validator.isObject( window.pisasales ) && !window.pisasales.isTouch )
				checkIfHasLabel( dropdownView, editor );

			dropdownView.buttonView.set( {
				withText: true,
				tooltip: editor.objects.tooltips.getT( FONT_FAMILY ),
				label: editor.objects.tooltips.getT( FONT_FAMILY )
			} );

			this._addViewExecuteListener( dropdownView );
			this._addFocusKeyupReactions( dropdownView );
			addPanelViewHeightHandler( editor, dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, FONT_FAMILY );
			this.views.full.push( dropdownView );
			dropdownView.on( "render", ( eventInfo ) => {
				this._afterFullDropdownRender( dropdownView );
				// this._updateToLiveFont();
			}, { priority: Number.MIN_SAFE_INTEGER } );
			return dropdownView;
		} );

		editor.ui.componentFactory.add( COMPACT_FONT_FAMILY, locale => {
			let compactDropdownView = setFontDropdown( editor );
			compactDropdownView.buttonView.set( {
				icon: fontFamilyIcon,
				withText: false,
				tooltip: editor.objects.tooltips.getT( FONT_FAMILY )
			} );
			addDropdownCloseListener( editor, compactDropdownView );
			this._addViewExecuteListener( compactDropdownView );
			this._addFocusKeyupReactions( compactDropdownView );
			addPanelViewHeightHandler( editor, compactDropdownView );
			editor.objects.focus._addExecuteFocus( compactDropdownView );
			editor.objects.focus._addExecuteFocus( compactDropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( compactDropdownView.buttonView, COMPACT_FONT_FAMILY );
			this.views.compact.push( compactDropdownView );
			compactDropdownView.on( "render", ( eventInfo ) => {
				this._afterCompactDropdownRender( compactDropdownView );
			}, { priority: Number.MIN_SAFE_INTEGER } );
			return compactDropdownView;
		} );
	}

	destroy() {
		super.destroy();
		delete this.editor;
		delete this.views.full;
		delete this.views.compact;
		delete this.views;
	}

	_addSelectionChangeListener() {
		const editor = this.editor;
		editor.editing.view.document.on( "selectionChangeDone", () => {
			this._updateToLiveFont();
		} );
	}

	_updateToLiveFont( onReady = false ) {
		const editor = this.editor;
		let editorFont;
		if ( Validator.isObjectPath( editor, "editor.objects.selection" ) &&
			Validator.isFunction( editor.objects.selection.getLiveValue ) )
			editorFont = editor.objects.selection.getPreciseLiveValue( FONT_FAMILY );
		if ( onReady && !Validator.isString( editorFont ) )
			editorFont = getLiveAttributeValue( editor, FONT_FAMILY );
		let liveFontFamily = editorFont || getLiveNodeStyle().fontFamily;
		if ( !Validator.isString( liveFontFamily ) ) return;
		let fontFamily = clearFontFamily( liveFontFamily );
		this._updateAllButtonsAndLabels( editorFont, fontFamily );
	}

	_addViewExecuteListener( view ) {
		const editor = this.editor;
		view.on( "execute", ( evt, data ) => {
			editor.executeIf( FONT_FAMILY, { value: data.font } );
			let editorFont = data && typeof data == "object" && data.font &&
				typeof data.font == "string" ? data.font.split( "," )[ 0 ] : undefined;
			let fontFamily = editorFont || getLiveNodeStyle().fontFamily;
			fontFamily = clearFontFamily( fontFamily );
			this._updateAllButtonsAndLabels( editorFont, fontFamily );
		} );
	}

	_updateAllButtonsAndLabels( editorFont, fontFamily, updateMainLabel = true ) {
		const editor = this.editor;
		for ( let compactView of this.views.compact ) {
			let buttonList = getButtonList( compactView, editor.data.processor );
			if ( !buttonList || typeof buttonList != "object" ) continue;
			compactView.activeButton = highlightFontButton( buttonList, editorFont, fontFamily );
		}

		for ( let fullView of this.views.full ) {
			if ( updateMainLabel ) fullView.buttonView.label = fontFamily;
			let buttonList = getButtonList( fullView, editor.data.processor );
			if ( buttonList && typeof buttonList == "object" ) {
				fullView.activeButton = highlightFontButton( buttonList, editorFont, fontFamily );
			}
			if ( fullView && fullView.hasLabel &&
				editor.data.processor._isValidObjPath( fullView,
					"fullView.buttonView.children._items" ) &&
				fullView.buttonView.children._items instanceof Array &&
				fullView.buttonView.children._items.length > 1 &&
				editor.data.processor._isValidObjPath(
					fullView.buttonView.children._items[ 1 ],
					"item.element.style" ) )
				fullView.buttonView.children._items[ 1 ].element.style.fontFamily = fontFamily;
		}
	}
}

function getButtonList( dropdownView, dataProcessor ) {
	if ( !dataProcessor._isValidObjPath( dropdownView,
			"dropdownView.panelView.children._items" ) ) return;
	let items = dropdownView.panelView.children._items;
	if ( !( items instanceof Array ) || items.length < 1 ) return;
	if ( !dataProcessor._isValidObjPath( items[ 0 ],
			"items[ 0 ].items._items" ) ) return;
	let listSiblings = items[ 0 ].items._items;
	if ( !( listSiblings instanceof Array ) || listSiblings.length < 1 ) return;
	return listSiblings[ 0 ];
}

function setFontDropdown( editor ) {
	let dropdownView = createFontDropdown( editor );
	dropdownView = addChildrenToFontDropdown( dropdownView, editor );
	addUpdateSelectionListener( editor, dropdownView.buttonView );
	return dropdownView;
}

function createFontDropdown( editor ) {
	const dropdownView = createDropdown( editor.locale );
	dropdownView.set( 'panelPosition', 'se' );
	const command = editor.commands.get( FONT_FAMILY );
	addClasses( dropdownView, [ 'ck-font-family-dropdown' ] );
	closeDropdownOnBlur( dropdownView );
	dropdownView.bind( 'isEnabled' ).to( command );
	saveView( editor, dropdownView );
	dropdownView.name = FONT_FAMILY;
	return dropdownView;
}

function addToList(list, set, fn) {
	const base = fn.split(',')[0];
	if ( !set.has(base) ) {
		set.add(base)
		list.push(fn);
	}
}

function addChildrenToFontDropdown( dropdownView, editor ) {
	const dfc = getDeviceFontCount();
	const use_all = dfc <= 14; 
	const fonts = [];
	if ( use_all && (dfc > 0) ) {
		const dfl = getDeviceFontsList();
		const set = new Set();
		FONTS.forEach((f) => addToList(fonts, set, f));
		dfl.forEach((f) => addToList(fonts, set, f));
		fonts.sort((s1, s2) => {
			if ( s1 === s2 ) {
				return 0;
			} else {
				if ( s1 === 'Default' ) {
					return -1;
				} else if ( s2 === 'Default' ) {
					return 1;
				}
				return (s1 < s2) ? -1 : 1;
			}
		});
	} else {
		fonts.push(...FONTS);
	}

	let listView = createListView( editor, fonts );

	listView.delegate( 'execute' ).to( dropdownView );
	addViewChildren( dropdownView.panelView, { 0: listView } );
	if ( use_all || !isLocalFontList() ) {
		return dropdownView;
	}
	let button = createTextButton(editor.objects.tooltips.getT( SHOW_MORE_FONTS ), editor.locale );
	let extendedList = null;
	let list = [];
	let count = 0;
	addClasses( button, [ "pisa-one-line-center-button" ] );
	addViewChildren( dropdownView.panelView, { 0: button } );
	button.on( "execute", () => {
		if ( count == 0 ) {
			list = getDeviceFontsList();
			extendedList = createListView( editor, list );
			extendedList.delegate( 'execute' ).to( dropdownView );
			saveView( editor, extendedList );
		}
		if ( count % 2 == 0 ) {
			dropdownView.panelView.children.remove( listView );
			addViewChildren( dropdownView.panelView, { 0: extendedList }, true );
			dropdownView.actualList = [ ...list ];
			button.label = button.tooltip = "Show default fonts";
			handleDropdownHeight( editor, dropdownView );
		} else {
			dropdownView.panelView.children.remove( extendedList );
			addViewChildren( dropdownView.panelView, { 0: listView }, true );
			dropdownView.actualList = [ ...FONTS ];
			button.label = button.tooltip = "Show more fonts";
		}
		count++;
	} );
	return dropdownView;
}

function createListView( editor, fontList ) {
	const divContainer = new EmptyView();
	saveView( editor, divContainer );
	isLocalFontList() && addClasses( divContainer, [ SCROLL_CLASS_NAME ] );
	const listView = new ListView( editor.locale );
	saveView( editor, listView );
	addFontListToView( editor, listView, fontList );
	listView.delegate( "execute" ).to( divContainer );
	addViewChildren( divContainer, { 0: listView } );
	return divContainer;
}

function addFontListToView( editor, listView, fontList ) {
	fontList.forEach( font => {
		const listItemView = new ListItemView( editor.locale );
		saveView( editor, listItemView );
		let button = createFontButton( font, editor.locale );
		saveView( editor, button );
		font == "Default" ? font = undefined : void 0;
		button.on( "execute", () => {
			listView.fire( "execute", { font: font } );
		} );
		addViewChildren( listItemView, { 0: button } );
		addViewChildren( listView, { 0: listItemView } );
	} );
}

function createFontButton( font, locale ) {
	let fontName = font.split( ',' )[ 0 ];
	let button = createTextButton( fontName, locale );
	button.set( 'labelStyle', `font-family:${ font };` );
	fontName == "Default" ? font = undefined : void 0;
	button.font = font;
	return button;
}

function highlightFontButton( list, editorFont, liveFont ) {
	let activeButton;
	list.items._items.forEach( listItem => {
		let button = listItem.children._items[ 0 ];
		!button.font && !editorFont ? button.isOn = true : void 0;
		button.isOn = ( !button.font && !editorFont ) || button.font == liveFont ||
			( button.font && button.font.split( "," )[ 0 ] == liveFont );
		if ( button.isOn && button.font ) activeButton = button;
	} );
	return activeButton;
}

function saveView( editor, view ) {
	editor.objects.uiObj.push( view );
}

function getDeviceFontsList() {
	if ( !isLocalFontList() ) {
		return [];
	}
	let localFontList = window.localStorage.fontList.split( ";" );
	if ( !window.localStorage.newFontListStyle ) {
		localFontList.forEach( ( value, index, list ) => {
			list[ index ] = value.replace( /\/.+/g, "" );
		} );
	}
	localFontList = localFontList.filter( font => !!font );
	return localFontList;
}

function getDeviceFontsLength() {
	return window && window.localStorage && window.localStorage.fontList ? (window.localStorage.fontList.length || 0) : 0;
}

function isLocalFontList() {
	return getDeviceFontsLength() > 0;
}

function getDeviceFontCount() {
	if ( !isLocalFontList() ) {
		return 0;
	}
	const list = window.localStorage.fontList.split( ";" );
	return list.length;
}

function addUpdateSelectionListener( editor, buttonView ) {
	buttonView.on( "execute", () => {
		updateLastSelection( editor );
	} );
}

function checkIfHasLabel( dropdownView, editor ) {
	editor.once( "ready", () => {
		editor.editing.view.focus();
		dropdownView.hasLabel = doesHaveLabel( dropdownView );
		let liveFontFamily = clearFontFamily( getLiveNodeStyle().fontFamily );
		if ( !liveFontFamily ) return;
		dropdownView.buttonView.label = liveFontFamily;
		if ( !dropdownView.hasLabel ) return;
		dropdownView.buttonView.children._items[ 1 ].element.style.fontFamily = liveFontFamily;
	} );

	editor.on( "editorContentSet", () => {
		const editableElement = editor.ui && typeof editor.ui == "object" ? editor.ui.getEditableElement() : null;
		if ( !( editableElement instanceof HTMLElement ) ) {
			return;
		}
		let liveFontFamily = clearFontFamily(getComputedStyle( editableElement ).fontFamily );
		if ( !liveFontFamily || typeof liveFontFamily != "string" || liveFontFamily.length < 1 ) {
			return;
		}
		dropdownView.buttonView.label = liveFontFamily;
		if ( !doesHaveLabel( dropdownView ) ) {
			return;
		}
		dropdownView.buttonView.children._items[ 1 ].element.style.fontFamily = liveFontFamily;
	} );
}

function doesHaveLabel( dropdownView ) {
	return !( !dropdownView.buttonView.children ||
		!dropdownView.buttonView.children._items ||
		dropdownView.buttonView.children._items.length < 2 ||
		!dropdownView.buttonView.children._items[ 1 ].element );
}

function clearFontFamily( fontFamilyName ) {
	return String( fontFamilyName ).replace( /\,.+/, "" ).replace( /[^\d\s\w]+/g, "" );
}

function removeStyling( dropdownView ) {
	if ( !dropdownView || typeof dropdownView != "object" ||
		!( dropdownView.element instanceof HTMLElement ) ) return;
	dropdownView.element.classList.remove( CLASS_NAME );
	if ( !dropdownView.panelView || typeof dropdownView.panelView != "object" ||
		!( dropdownView.panelView.element instanceof HTMLElement ) ) return;
	dropdownView.panelView.element.setAttribute( "style", "" );
	dropdownView.panelView.element.removeAttribute( "style" );
}

export function addPanelViewHeightHandler( editor, dropdownView ) {
	if ( !Validator.isObject( editor ) ||
		!Validator.isObjectPath( dropdownView, "dropdownView.panelView" ) ||
		!Validator.isObject( dropdownView.buttonView ) ) return;
	// dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, newValue, oldValue ) => {
	// 	if ( !newValue || oldValue ) return;
	// 	console.log( dropdownView.panelView.element.getBoundingClientRect() );
	// }, { priority: Number.NEGATIVE_INFINITY } );
	dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, newValue, oldValue ) => {
		if ( !newValue || oldValue ) return removeStyling( dropdownView );
		handleDropdownHeight( editor, dropdownView );
	}, { priority: Number.POSITIVE_INFINITY } );
}

function handleDropdownHeight( editor, dropdownView ) {
	if ( !( dropdownView.panelView.element instanceof HTMLElement ) ) return;
	if ( !( dropdownView.buttonView.element instanceof HTMLElement ) ) return;
	// if ( !newValue || oldValue ) return removeStyling( dropdownView );
	const element = getEditableElement( editor );
	if ( !( element instanceof HTMLElement ) )
		return removeStyling( dropdownView );
	const editorRect = element.getBoundingClientRect();
	const buttonRect = dropdownView.buttonView.element.getBoundingClientRect();
	if ( !( editorRect instanceof DOMRect ) ||
		!( buttonRect instanceof DOMRect ) ) return removeStyling( dropdownView );
	let resultHeight = getResultHeight( editorRect, buttonRect );
	if ( !Validator.isPositiveNumber( resultHeight, false ) )
		return removeStyling( dropdownView );

	if ( resultHeight > 477 ) {
		resultHeight = 477;
		if ( dropdownView.name == FONT_FAMILY ) return removeStyling( dropdownView );
	}

	const panelElement = dropdownView.panelView.element;

	if ( dropdownView.element.className.split( " " )
		.indexOf( "psa-scrollable-dropdown" ) >= 0 )
		dropdownView.element.classList.remove( "psa-scrollable-dropdown" );

	if ( panelElement.firstElementChild instanceof HTMLElement &&
		panelElement.firstElementChild.className.split( " " )
		.indexOf( SCROLL_CLASS_NAME ) >= 0 )
		panelElement.firstElementChild.classList.remove( SCROLL_CLASS_NAME );

	if ( panelElement.firstChild instanceof HTMLElement &&
		panelElement.firstChild.className.split( " " )
		.indexOf( SCROLL_CLASS_NAME ) >= 0 )
		panelElement.firstChild.classList.remove( SCROLL_CLASS_NAME );

	panelElement.setAttribute( "style", `max-height:${ resultHeight }px !important;` );

	if ( dropdownView.element.className.split( " " )
		.indexOf( CLASS_NAME ) >= 0 ) return;

	dropdownView.element.classList.add( CLASS_NAME );
}

function getResultHeight( editorRect, buttonRect ) {
	if ( !editorRect || typeof editorRect != "object" ||
		!buttonRect || typeof buttonRect != "object" ) return void 0;
	const buttonBottom = getBottom( buttonRect );
	const editorBottom = getBottom( editorRect );
	return typeof buttonBottom == "number" &&
		typeof editorBottom == "number" && editorBottom > buttonBottom ?
		editorBottom - buttonBottom : getHeight( editorRect );
}

function getBottom( rect ) {
	if ( !Validator.isObject( rect ) ) return void 0;
	return Validator.isPositiveNumber( rect.bottom, false ) ? rect.bottom :
		!Validator.isNumber( rect.height ) || rect.height <= 0 ? void 0 :
		Validator.isNumber( rect.top ) ? rect.top + rect.height :
		Validator.isNumber( rect.y ) ? rect.y + rect.height : void 0;
}

function getTop( rect ) {
	if ( !rect || typeof rect != "object" ) return void 0;
	return typeof rect.top == "number" && rect.top > 0 ? rect.top :
		typeof rect.y == "number" && rect.y > 0 ? rect.y :
		typeof rect.bottom == "number" && rect.bottom > 0 &&
		typeof rect.height == "number" && rect.height > 0 &&
		rect.bottom - rect.height > 0 ? rect.bottom - rect.height : void 0;
}

function getHeight( rect ) {
	if ( !rect || typeof rect != "object" ) return void 0;
	typeof rect.height == "number" ? rect.height :
		typeof rect.bottom != "number" ? void 0 :
		typeof rect.top == "number" ? rect.bottom - rect.top :
		typeof rect.y == "number" ? rect.bottom - rect.y : void 0
}
