import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import FontColorCommand from './pisacolorcommand';
import { ckeColorList } from './pisacolorutils';
import { addUpcastConversion, addUpcastContainerConversion } from '../utils';

export const FONT_COLOR = 'fontColor';
export const FONT_COLOR_ATTRIBUTE = 'color';

export default class FontColorEditing extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		const editor = this.editor;
		editor.model.schema.extend( '$text', { allowAttributes: FONT_COLOR } );
		addDowncastConversion( editor );
		addUpcastConversion( editor, FONT_COLOR_ATTRIBUTE, FONT_COLOR, [ "span", "a" ] );
		addUpcastContainerConversion( editor, FONT_COLOR_ATTRIBUTE, FONT_COLOR );

		// editor.commands.add( FONT_COLOR, new FontColorCommand( editor ) );
	}
}

function addDowncastConversion( editor ) {
	editor.conversion.for( 'downcast' ).attributeToElement( {
		model: {
			key: FONT_COLOR,
			name: '$text'
		},
		view: ( modelAttributeValue, viewWriter ) => {
			return viewWriter.createAttributeElement( 'span', {
				style: FONT_COLOR_ATTRIBUTE.concat( ":" ).concat( modelAttributeValue )
			} );
		}
	} );
}
