import { attachTooltipManager } from "../pisatooltips/tooltipmanager";
import { LANGUAGES as lang } from "../pisatooltips/tooltipmanager";
import * as en from '../tooltiptranslations/en.json';
import * as de from '../tooltiptranslations/de.json';
import * as fr from '../tooltiptranslations/fr.json';

export function provideWithTooltips( editor, language ) {

	if ( !attachTooltipManager( editor ) ) return false;

	const tooltips = editor.objects.tooltips;

	tooltips.language =
		tooltips.languages.indexOf( language ) < 0 ? "en" : language;

	if ( !!en ) tooltips.parseLanguage( en.default, lang.EN );
	if ( !!de ) tooltips.parseLanguage( de.default, lang.DE );
	if ( !!fr ) tooltips.parseLanguage( fr.default, lang.FR );

	return;
}
