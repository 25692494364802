import { DEFAULT_COLOR } from './pisafontcolor/pisacolortouchui';
import {
	INCREASE_TABLE_MARGIN,
	DECREASE_TABLE_MARGIN,
	PLACE_TABLE,
	EDIT_TABLE_ROW,
	EDIT_TABLE_COLUMN,
	EDIT_TABLE_AND_CELL,
	EDIT_TABLE_ONLY,
	EDIT_CELL_ONLY,
	INSERT_TABLE_ROW_ABOVE,
	INSERT_TABLE_ROW_BELOW,
	REMOVE_TABLE_ROW,
	SET_TABLE_ROW_HEADER,
	INSERT_TABLE_COLUMN_LEFT,
	INSERT_TABLE_COLUMN_RIGHT,
	REMOVE_TABLE_COLUMN,
	SET_TABLE_COLUMN_HEADER,
	SPLIT_TABLE_CELL_VERTICALLY,
	SPLIT_TABLE_CELL_HORIZONTALLY,
	MERGE_TABLE_CELL_RIGHT,
	MERGE_TABLE_CELL_LEFT,
	MERGE_TABLE_CELL_DOWN,
	MERGE_TABLE_CELL_UP,
	MAKE_TABLE_INLINE,
	MAKE_TABLE_BLOCK
} from './pisatable/pisatableui';
import {
	TABLE_CELL_WIDTH,
	TABLE_CELL_HEIGHT,
	TABLE_CELL_PADDING,
	TABLE_CELL_BORDER_WIDTH,
	TABLE_CELL_BORDER_COLOR,
	TABLE_CELL_BACKGROUND_COLOR,
	PISA_DEFAULT
} from './pisatablecell/pisacellui';
import { SEARCH_KEYWORD_INPUT } from './pisasearch/pisasearch';
import {
	SMILEYS_EMOJIS,
	PEOPLE_EMOJIS,
	NATURE_EMOJIS,
	FOOD_EMOJIS,
	OBJECTS_EMOJIS,
	PLACES_EMOJIS,
	SYMBOLS_EMOJIS
} from './pisaemoji/pisaemoji';
import { SWITCH_TO_PLAIN, SWITCH_TO_HTML } from './pisaplaintext/pisaplaintext';
import { PLACEHOLDER_NAMES, PLACEHOLDER_VALUES } from './pisaplaceholder/pisaplaceholderui';
import { INSERT_PLACEHOLDER } from './pisaplaceholder/insertplaceholdercommand';
import { REFRESH_PLACEHOLDER } from './pisaplaceholder/refreshplaceholdercommand';
import { SHOW_MORE_TOOLBAR_OPTIONS, SHOW_LESS_TOOLBAR_OPTIONS } from './pisatoolbar/pisatoolbar';
// import { SAVE_LINK, CANCEL_LINK_CREATION, PISA_LINK_URL } from './iconsui/linkformview';
import { SAVE_LINK, CANCEL_LINK_CREATION, PISA_LINK_URL } from './pisalink/pisalinkformview';
// import { PISA_UNLINK, PISA_EDIT_LINK, PISA_OPEN_LINK, PISA_LINK_NO_URL } from './iconsui/linkactionsview';
import { PISA_UNLINK, PISA_EDIT_LINK, PISA_OPEN_LINK, PISA_LINK_NO_URL } from './pisalink/pisalinkactionsview';
import { DATA_KEYWORD_INPUT } from './pisasetdata/pisasetdata';
import { VERTICAL_ALIGN } from './pisaimageposition/pisaimagepositionediting';
import { SHOW_MORE_FONTS } from './pisafontfamily/pisafontfamilyui';
import { PISA_LOCK_WARNING, PISA_RSTARTED_WARNING, PISA_RESTART_MESSAGE } from './pisawatchdog/pisaeditorwatchdog';
import { PISA_OBJECT_LINK_PREVIEW_PEFIX, PISA_MAIL_LINK_PREVIEW_PEFIX } from './pisalink/pisalinkediting';
import { DISABLE_PLACEHOLDER_EDITING, ENABLE_PLACEHOLDER_EDITING } from './pisaplaceholder/pisaplaceholdervaluesui';

export const defaultFontColor = DEFAULT_COLOR;
export const changeTableDisplay = PLACE_TABLE;
export const editTableRow = EDIT_TABLE_ROW;
export const editTableColumn = EDIT_TABLE_COLUMN;
export const editTableAndCell = EDIT_TABLE_AND_CELL;
export const editTableOnly = EDIT_TABLE_ONLY;
export const editCellOnly = EDIT_CELL_ONLY;
export const insertRowAbove = INSERT_TABLE_ROW_ABOVE;
export const insertRowBelow = INSERT_TABLE_ROW_BELOW;
export const removeRow = REMOVE_TABLE_ROW;
export const setRowAsHeader = SET_TABLE_ROW_HEADER;
export const insertColumnLeft = INSERT_TABLE_COLUMN_LEFT;
export const insertColumnRight = INSERT_TABLE_COLUMN_RIGHT;
export const removeColumn = REMOVE_TABLE_COLUMN;
export const setColumnAsHeader = SET_TABLE_COLUMN_HEADER;
export const moveTableToRight = INCREASE_TABLE_MARGIN;
export const moveTableToLeft = DECREASE_TABLE_MARGIN;
export const makeTableInline = MAKE_TABLE_INLINE;
export const makeTableBlock = MAKE_TABLE_BLOCK;
export const splitCellVertically = SPLIT_TABLE_CELL_VERTICALLY;
export const splitCellHorizontally = SPLIT_TABLE_CELL_HORIZONTALLY;
export const mergeCellRight = MERGE_TABLE_CELL_RIGHT;
export const mergeCellLeft = MERGE_TABLE_CELL_LEFT;
export const mergeCellUp = MERGE_TABLE_CELL_UP;
export const mergeCellDown = MERGE_TABLE_CELL_DOWN;
export const tableCellWidth = TABLE_CELL_WIDTH;
export const tableCellHeight = TABLE_CELL_HEIGHT;
export const tableCellPadding = TABLE_CELL_PADDING;
export const tableCellBorderWidth = TABLE_CELL_BORDER_WIDTH;
export const tableCellBorderColor = TABLE_CELL_BORDER_COLOR;
export const tableCellBackgroundColor = TABLE_CELL_BACKGROUND_COLOR;
export const pisaDefault = PISA_DEFAULT;
export const insertSearchKeyword = SEARCH_KEYWORD_INPUT;
export const smileysEmoji = SMILEYS_EMOJIS;
export const peopleEmoji = PEOPLE_EMOJIS;
export const natureEmoji = NATURE_EMOJIS;
export const foodEmoji = FOOD_EMOJIS;
export const objectsEmoji = OBJECTS_EMOJIS;
export const placesEmoji = PLACES_EMOJIS;
export const symbolsEmoji = SYMBOLS_EMOJIS;
export const switchToPlainText = SWITCH_TO_PLAIN;
export const switchToHtml = SWITCH_TO_HTML;
export const showPlaceholderNames = PLACEHOLDER_NAMES;
export const showPlaceholderValues = PLACEHOLDER_VALUES;
export const insertPlaceholder = INSERT_PLACEHOLDER;
export const refreshPlaceholder = REFRESH_PLACEHOLDER;
export const expandToolbar = SHOW_MORE_TOOLBAR_OPTIONS;
export const reduceToolbar = SHOW_LESS_TOOLBAR_OPTIONS;
export const saveLink = SAVE_LINK;
export const cancelLink = CANCEL_LINK_CREATION;
export const pisaLinkUrl = PISA_LINK_URL;
export const unlink = PISA_UNLINK;
export const editLink = PISA_EDIT_LINK;
export const openLink = PISA_OPEN_LINK;
export const linkWithoutUrl = PISA_LINK_NO_URL;
export const setDataInput = DATA_KEYWORD_INPUT;
export const alignImage = VERTICAL_ALIGN;
export const showMoreFonts = SHOW_MORE_FONTS;
export const lockWarning = PISA_LOCK_WARNING;
export const restartedWarning = PISA_RSTARTED_WARNING;
export const restartMessage = PISA_RESTART_MESSAGE;
export const pisaObjectLinkPreviewPrefix = PISA_OBJECT_LINK_PREVIEW_PEFIX;
export const pisaMailLinkPreviewPrefix = PISA_MAIL_LINK_PREVIEW_PEFIX;
export const enablePlaceholderEditing = ENABLE_PLACEHOLDER_EDITING;
export const disablePlaceholderEditing = DISABLE_PLACEHOLDER_EDITING;
