import { getElementNode, findElementInSelection, setSelectionOnElement } from '../utils';
import { setModelImageElementWidth, setModelImageElementHeight } from '../pisaimage/pisaimageediting';
import PisaUtil from '../../pisaext/pisautil';

export function getStats( editor, selection = "" ) {
	selection = selection || editor.lastSelection || editor.model.document.selection;
	if ( isSelectionImage( selection ) ) {
		return getImageSelectionAttributes( editor, selection );
	}
	let imageElement = findElementInSelection( editor, selection, "pisaImage" ) ||
		findElementWithImage( editor, selection );
	if ( !imageElement ) {
		console.warn( "Could not find image element in selection. Resize/move/delete aborted." );
		editor.balloons.hide( "image" );
		return;
	}
	setSelectionOnElement( editor, imageElement, true, true, false );
	return getImageSelectionAttributes( editor, editor.model.document.selection );
}

function getImageSelectionAttributes( editor, imageSelection ) {
	return getImageRangeAttributes( editor, imageSelection._ranges[ 0 ] );
}

function getImageRangeAttributes( editor, imageRange ) {
	let node = imageRange.start.nodeAfter.name == "pisaImage" ?
		imageRange.start.nodeAfter : imageRange.end.nodeBefore;
	return {
		range: imageRange,
		node: node,
		attributes: mapToObject( calibrateAttributes( editor, node ) )
	}
}

function calibrateAttributes( editor, node, attrMap = null, dimension = "px" ) {
	attrMap = attrMap || node._attrs;
	let width = attrMap.get( "height" ) || attrMap.get( "width" ) ? null :
		getNodeWidth( editor, node );
	width ? attrMap.set( "width", String( width ).concat( dimension ) ) : void 0;
	let height = attrMap.get( "height" ) || attrMap.get( "width" ) ? null :
		getNodeHeight( editor, node );
	// TODO see if numberToLength could be used instead of concat
	height ? attrMap.set( "height", String( height ).concat( dimension ) ) : void 0;
	return attrMap;
}

export function changeImageStats( editor, userInput, params ) {
	userInput = params.withRatio ? userInput : validateInput( userInput );
	userInput = params.withRatio ? userInput : userInput.replace( /[%]+/g, "" ) * 0.01;
	if ( !userInput ) return;
	params.attributes = calcWidthAndHeight( params.attributes, userInput, params.withRatio );
	if ( params.node ) {
		setWidthAndHeight( editor, params.node, params.attributes.width, params.attributes.height, params.attributes.id );
		return;
	}
	// TODO if params.range
}

function calcWidthAndHeight( attributes, multiplier, withRatio = false ) {
	multiplier = lengthToNumber( multiplier );
	if ( withRatio ) {
		let ratio = lengthToNumber( attributes.width ) / lengthToNumber( attributes.height );
		attributes.width && ratio ?
			attributes.width = numberToLength( multiplier * lengthToNumber( ratio ) ) : void 0;
		attributes.height ?
			objAttributes.height = numberToLength( multiplier ) : void 0;
		return attributes;
	}
	let width = attributes.width ? lengthToNumber( attributes.width ) * multiplier : null;
	let height = attributes.height ? lengthToNumber( attributes.height ) * multiplier : null;
	width && width > 5 && width < 2000 && ( !height || ( height > 5 && height < 2000 ) ) ?
		attributes.width = numberToLength( width ) : void 0;
	height && height > 5 && height < 2000 && ( !width || ( width > 5 && width < 2000 ) ) ?
		attributes.height = numberToLength( height ) : void 0;
	return attributes;
}

function setWidthAndHeight( editor, nodeElement, width, height, id ) {
	if ( !editor || !nodeElement || !id ) return;
	editor.model.change( writer => {
		if ( height ) setModelImageElementHeight( nodeElement, writer, height );
		if ( width ) setModelImageElementWidth( nodeElement, writer, width );
		writer.setAttribute( "border",
			"5px solid var(--ck-color-base-active-focus)", nodeElement );
	} );
	setListenersByWriter( editor, nodeElement, id );
	updateNodeListeners( editor.wdgId, id );
	setSelectionOnElement( editor, nodeElement, true, true, false );
}

export function setListenersByWriter( editor, nodeElement, nodeID ) {
	if ( !editor || !nodeElement || !nodeID ) {
		return;
	}
	const idw = editor.wdgId;
	updateNodeListeners(idw, nodeID);
}

export function updateNodeListeners( idw, nodeID ) {
	const node = window.document.getElementById( nodeID );
	if ( !node || (node.tagName !== "IMG") ) {
		return;
	}
	if ( node.__psa_lsr_set ) {
		return;
	}
	// always drop attribute based listeners
	node.removeAttribute( "onMouseOver" );
	node.removeAttribute( "onClick" );
	node.removeAttribute( "onMouseOut" );
	// add regular listeners
	node.addEventListener('mouseover', (e) => {
		pisasales.cke.images.mouseover(node);
	});
	node.addEventListener('mouseout', (e) => {
		pisasales.cke.images.mouseout(node);
	})
	node.addEventListener('click', (e) => {
		pisasales.cke.images.click(node, nodeID, idw);
	});
	node.__psa_lsr_set = true;
}

function lengthToNumber( value ) {
	if ( !value ) return value;
	return Number( value.toString().replace( /[^\d\.\,]+/g, "" ).replace( /[\.\,]+/g, "." ) );
}

function numberToLength( value, unit = "px" ) {
	if ( !value ) return value;
	return `${ value }` + unit;
}

function getNodeWidth( editor, nodeElement ) {
	let node = getElementNode( editor, nodeElement );
	return node ? ( node.naturalWidth ? node.naturalWidth :
		( node.width ? node.width : ( node.getBoundingClientRect() ?
			( node.getBoundingClientRect().width ? node.getBoundingClientRect().width :
				null ) : null ) ) ) : null;
}

function getNodeHeight( editor, nodeElement ) {
	let node = getElementNode( editor, nodeElement );
	return node ? ( node.naturalHeight ? node.naturalHeight :
		( node.height ? node.height : ( node.getBoundingClientRect() ?
			( node.getBoundingClientRect().height ? node.getBoundingClientRect().height :
				null ) : null ) ) ) : null;
}

export function mapToObject( map ) {
	const object = {};
	map.forEach( ( value, key ) => {
		object[ key ] = value;
	} );
	return object;
}

function validateInput( input ) {
	if ( !input ) return "";
	input = input.replace( /[^(\d|,|\.|%)]+/g, "" ).replace( /[,]+/g, "." ).replace( /[\.]+/g, "." ).replace( /[%]+/g, "%" );
	while ( input.startsWith( "." ) || input.startsWith( "%" ) ) {
		input = input.substring( 1 );
	}
	while ( input.endsWith( "." ) ) {
		input = input.substring( 0, input.length - 1 );
	}
	if ( !input.endsWith( "%" ) ) return "";
	return input;
}

export function isSelectionImage( selection ) {
	// selection = selection._selection || selection;
	if ( !( selection && selection._ranges && selection._ranges.length == 1 ) ) return false;
	let range = selection._ranges[ 0 ];
	if ( !( range.start && range.start.path && range.end && range.end.path && range.start.path.length == range.end.path.length ) ) return false;
	if ( range.start.path[ range.start.path.length - 1 ] + 1 != range.end.path[ range.end.path.length - 1 ] ) return false;
	if ( !( selection.anchor && selection.anchor.parent && selection.anchor.parent._children &&
			selection.anchor.parent._children._nodes && selection.anchor.parent._children._nodes.length > 0 ) ) return false;
	if ( range.start.nodeAfter && range.start.nodeAfter.name == "pisaImage" ) return true;
	if ( range.end.nodeBefore && range.end.nodeBefore.name == "pisaImage" ) return true;
	return false;
}

export function addAutofocus( dropdownView, inputForm, editor ) {
	dropdownView.panelView.on( "change:isVisible", ( eventInfo, name, value, oldValue ) => {
		if ( value ) {
			inputForm.inputView.element.autofocus = true;
			let id = inputForm.inputView.element.id;
			dropdownView.panelView.element.focus();
			dropdownView.panelView.children._items[ 0 ].element.focus();
			inputForm.inputView.element.focus();
			inputForm.inputView.element.select();
			inputForm.element.focus();
			window.document.getElementById( id ).focus();
		}
	} );
}

function findElementWithImage( editor, selection = null ) {
	let ranges = getValidRanges( editor, selection );
	if ( !ranges ) return null;
	let parentElement = ranges.start.parent.parent;
	let startIndex = ranges.start.parent.index;
	let endIndex = ranges.end.parent.index < startIndex ? startIndex : ranges.end.parent.index;
	for ( let i = startIndex; i <= endIndex; i++ ) {
		let element = parentElement._children._nodes[ i ];
		if ( element && element.name == "pisaImage" ) {
			return element;
		}
	}
	return null;
}

function getValidRanges( editor, selection = null, lastSelection = false ) {
	selection = selection || editor.model.document.selection;
	if ( !selection._ranges || !selection._ranges[ 0 ] ) return null;
	let ranges = selection._ranges[ 0 ];
	if ( !ranges || !ranges.start || !ranges.end || !ranges.start.parent ||
		!ranges.end.parent || !ranges.start.parent.parent ||
		ranges.start.parent.parent != ranges.end.parent.parent ) {
		if ( lastSelection || !editor.lastSelection ) return null;
		return getValidRanges( editor, editor.lastSelection, true );
	}
	return ranges;
}
