import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import PisaLinkBalloons from './balloon/pisalinkballoons';
import { INSERT_OBJECT_LINK } from '../pisaobjectlink/pisainsertobjectlinkcommand';
import PisaInsertObjectLinkCommand from '../pisaobjectlink/pisainsertobjectlinkcommand';
import PluginUtils from '../pisautils/pluginutils';
import Validator from '../pisautils/validator';
import { linkIcon } from '../icons';
import { onDomEvent } from '../utils';
import './theme/link.css';

export const LINK_BUTTON_CLICKED_EVENT = "linkButtonClicked";
export const PISA_IMAGE_LINK = "pisaImageLink";

const LOG_CHANGES = true;
const LINK_KEYSTROKE = 'Ctrl+K';

export default class PisaLinkUI extends Plugin {

	static get requires() {
		return [ PisaLinkBalloons ];
	}

	init() {
		const editor = this.editor;
		new PluginUtils( {
			hostPlugin: this,
			logChanges: LOG_CHANGES,
			logOutputColor: "#736598"
		} );

		editor.commands.add( INSERT_OBJECT_LINK, new PisaInsertObjectLinkCommand( editor ) );
		editor.editing.view.addObserver( ClickObserver ); // what for?

		this._createToolbarLinkButton();
		this._createImageLinkButton();
		this._addDomClickListener();
	}

	showUI() {
		return this.editor.objects.linkBalloon.show();
	}

	_createToolbarLinkButton() {
		const editor = this.editor;
		const linkCommand = editor.commands._commands.get( 'link' );

		editor.keystrokes.set( LINK_KEYSTROKE, ( keyEvtData, cancel ) => {
			// Prevent focusing the search bar in FF and opening new tab in Edge. #153, #154.
			cancel();
			if ( !linkCommand.isEnabled ) return;
			this.showUI();
			editor.fire( LINK_BUTTON_CLICKED_EVENT );
		} );

		editor.ui.componentFactory.add( 'link', locale => {
			const button = new ButtonView( locale );
			button.isEnabled = true;
			button.label = editor.objects.tooltips.getT( 'link' );
			button.icon = linkIcon;
			// button.keystroke = LINK_KEYSTROKE;
			button.tooltip = true;
			// Bind button to the command.
			button.bind( 'isOn', 'isEnabled' ).to( linkCommand, 'value', 'isEnabled' );
			button.on( "execute", ( eventInfo ) => {
				this.showUI();
				editor.fire( LINK_BUTTON_CLICKED_EVENT );
			} );
			return button;
		} );

		delete this._createToolbarLinkButton
	}

	_createImageLinkButton() {
		const editor = this.editor;
		const linkCommand = editor.commands.get( 'link' );
		editor.ui.componentFactory.add( PISA_IMAGE_LINK, locale => {
			const button = new ButtonView( locale );

			button.isEnabled = true;
			button.label = editor.objects.tooltips.getT( PISA_IMAGE_LINK );
			button.icon = linkIcon; //TODO CHANGE
			button.tooltip = true;

			button.bind( 'isOn', 'isEnabled' ).to( linkCommand, 'value', 'isEnabled' );

			button.on( "execute", ( eventInfo ) => {
				this.showUI();
				editor.fire( LINK_BUTTON_CLICKED_EVENT );
			} );

			return button;
		} );

		delete this._createImageLinkButton;
	}

	_addDomClickListener() {
		const editor = this.editor;

		const preventLinkClickDefaultEvent = ( evt ) => {
			if ( !Validator.isObject( editor, "editor.model.document.selection" ) ||
				!editor.model.document.selection.isCollapsed ||
				!Validator.isArrayAtEnd( evt, "evt.path" ) ) return;
			let aTags = evt.path.filter( parent => parent.tagName == "A" );
			if ( aTags.length > 0 ) {
				evt.preventDefault();
				evt.stopPropagation();
				return;
			}
			let selection = window.getSelection();
			let node = selection.anchorNode || selection.baseNode ||
				selection.focusNode || selection.extentNode;
			if ( !Validator.isObject( node ) ) return;
			node = Validator.couldBe( node, "Text" ) ? node.parentNode : node;
			while ( node && node.parentNode && node.tagName &&
				node.tagName != "DIV" && node.tagName != "A" ) {
				node = node.parentNode;
			}
			if ( node.tagName != "A" ) return;
			evt.preventDefault();
			evt.stopPropagation();
		}

		onDomEvent( editor, "click", preventLinkClickDefaultEvent );

		delete this._addDomClickListener;
	}

}
