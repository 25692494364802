import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import EmptyView from '../pisadropdown/emptyview';
import FontColorCommand from './pisacolorcommand';
// import { createColorDropdown } from '../../touch_plugins/fontcolor/fontcolorui';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { addColorGrid } from '../pisadropdown/addcolorgrid';
import { updateFillColor, highlightActiveColor } from './pisacolorui';
import { defineButton, closeDropdownOnBlur, setButton, addClasses, createHexInput, bindInputToCommand, addViewChildren } from '../utils';
import { fontColorIcon, removeColorIcon } from '../icons';
import { touchColorList, bckgColorList } from '../pisacolorscheme';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const FONT_COLOR_TOUCH = "fontColorTouch";
const FONT_COLOR = "fontColor";
export const DEFAULT_COLOR = "pisaDefaultColor";

export default class FontColorTouchUI extends Plugin {
	init() {
		const editor = this.editor;
		if ( !editor.commands.get( FONT_COLOR ) )
			editor.commands.add( FONT_COLOR, new FontColorCommand( editor ) );
		editor.ui.componentFactory.add( FONT_COLOR_TOUCH, locale => {
			return createColorDropdown( editor, FONT_COLOR, fontColorIcon,
				editor.objects.tooltips.getT( FONT_COLOR_TOUCH ) );
		} );
	}
}

export function createColorDropdown( editor, commandName, icon, label ) {
	//TODO color inputs are not mutually coordinated (balloon and toolbar)
	let mapper = editor.editing.mapper;
	const dropdownView = createDropdown( editor.locale );
	defineButton( dropdownView.buttonView, icon, label );
	const command = editor.commands.get( commandName );
	dropdownView.bind( 'isEnabled' ).to( command );
	dropdownView.buttonView.iconView.fillColor = "#c0c0c0";
	editor.objects.focus._addExecuteFocus( dropdownView );
	editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
	GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, commandName );

	let lineGridView = new EmptyView( [ 'pisa-grid-line-21' ] );

	let autoButton = setButton( removeColorIcon, editor.objects.tooltips.getT( DEFAULT_COLOR ), commandName, { value: undefined }, editor );
	let textInput = createHexInput( true, editor, [ "pisa-color-hex", "pisa-place-center" ] );

	autoButton.iconView.fillColor = "#909090";

	autoButton.on( "execute", () => {
		textInput.element.value = "";
		dropdownView.isOpen = false;
		autoButton.iconView.fillColor = "#909090";
		dropdownView.buttonView.iconView.fillColor = "#c0c0c0";
	} );

	textInput.on( "input", ( eventInfo ) => {
		let value = textInput.element.value;

		if ( typeof value != "string" ) return;
		value = value.replace( /[^\#a-fA-F0-9]+/g, "" );
		if ( value.length > 0 && !value.startsWith( "#" ) ) value = "#" + value;
		if ( [ 0, 4, 7 ].indexOf( value.length ) < 0 ) return;

		let selection = editor.objects.selection.lastInModel;
		let viewRange = editor.editing.mapper.toViewRange( selection._ranges[ 0 ] );

		editor.model.change( writer => writer.setSelection( selection ) );
		// editor.model.document.selection._selection.setTo( selection );
		// editor.editing.view.document.selection._selection.setTo( viewRange );

		if ( value.length < 1 ) {
			dropdownView.buttonView.iconView.fillColor = "#c0c0c0";
			textInput.element.style.color = "#bbb";
			autoButton.iconView.fillColor = "#909090";
			autoButton.isOn = true;
			editor.execute( commandName, { value: undefined } );
		} else {
			let isDark = isDarkEnough( value );
			dropdownView.buttonView.iconView.fillColor = value;
			textInput.element.style.color = isDark ? value : "#bbb";
			autoButton.iconView.fillColor = isDark ? value : "#909090";
			autoButton.isOn = false;
			editor.execute( commandName, { value: value } );
		}

		editor.model.change( writer => writer.setSelection( selection ) );
		// editor.model.document.selection._selection.setTo( selection );
		// editor.editing.view.document.selection._selection.setTo( viewRange );
	} );

	// bindInputToCommand( textInput, commandName, {}, "value", editor );
	addViewChildren( lineGridView, { 0: textInput, 1: autoButton } );

	addColorGrid( dropdownView, {
		colorList: commandName == FONT_COLOR ? touchColorList : bckgColorList,
		gridStyles: "touch-dropdown-parent",
		divStyles: "touch-dropdown-child",
		buttonStyles: "touch-color-button"
	} );

	addViewChildren( dropdownView.panelView, { 1: lineGridView } );

	closeDropdownOnBlur( dropdownView );

	editor.editing.view.document.on( 'selectionChangeDone', () => {
		let currentColor = updateFillColor( editor, dropdownView.buttonView.iconView, commandName, "#c0c0c0", "", autoButton, textInput );
		autoButton.iconView.fillColor = currentColor || "#909090";
		highlightActiveColor( dropdownView.panelView.children._items[ 0 ], currentColor );
	} );

	dropdownView.on( "execute", ( evt, data ) => {
		dropdownView.buttonView.iconView.fillColor = data.color;
		autoButton.iconView.fillColor = data.color;
		autoButton.isOn = false;
		textInput.element.value = data.color;
		textInput.element.style.color = data.color;
		editor.execute( commandName, { value: data.color } );
		editor.editing.view.focus();
	} );

	return dropdownView;
}

function isDarkEnough( color ) {
	let r, g, b, hsp;

	color = +( "0x" + color.slice( 1 ).replace( color.length < 5 && /./g, '$&$&' ) );

	r = color >> 16;
	g = color >> 8 & 255;
	b = color & 255;

	hsp = Math.sqrt(
		0.299 * ( r * r ) +
		0.587 * ( g * g ) +
		0.114 * ( b * b )
	);
	return hsp < 200;
}
