import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import BackgroundColorCommand from './pisabckgcommand';
import { createColorDropdown } from '../pisafontcolor/pisacolortouchui';
// import { createColorDropdown } from '../../touch_plugins/fontcolor/fontcolorui';
import { backgroundIcon } from '../icons';

export const FONT_BACKGROUND_TOUCH = "fontBackgroundTouch";
const BACKGROUND_COLOR = "backgroundColor";

export default class BackgroundColorTouchUI extends Plugin {
	init() {
		const editor = this.editor;
		if ( !editor.commands.get( BACKGROUND_COLOR ) )
			editor.commands.add( BACKGROUND_COLOR, new BackgroundColorCommand( editor ) );
		editor.ui.componentFactory.add( FONT_BACKGROUND_TOUCH, locale => {
			return createColorDropdown( editor, BACKGROUND_COLOR, backgroundIcon,
				editor.objects.tooltips.getT( FONT_BACKGROUND_TOUCH ) );
		} );
	}
}
