import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';
import { stopEvent, onDomEvent } from '../../utils';
import EventHelper from '../../pisautils/eventhelper';
import FunctionHelper from '../../pisautils/functionhelper';
import { keyIs } from '../pisaplaceholderui';

export default class PlaceholderEventManager extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		hostPlugin.editor.editing.view.document.on( "cut", ( eventInfo, data ) => {
			return hostPlugin._handleCut( eventInfo, data );
		}, hostPlugin.priority.thirdMax );

		hostPlugin.editor.editing.view.document.on( "paste", ( eventInfo, data ) => {
			return hostPlugin._handlePaste( eventInfo, data );
		}, hostPlugin.priority.thirdMax );

		this._addLinkHrefListener( hostPlugin );
	}

	_addLinkHrefListener( hostPlugin ) {
		hostPlugin.editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:linkHref:$text`,
				( eventInfo, data, conversionApi ) => {
					hostPlugin._onLinkHref( eventInfo, data, conversionApi );
				}, hostPlugin.priority.thirdMax );
		} );
	}

	// ---Utils-------------------------------------------------------------------

	stopEditorEvent( eventInfo, data ) {
		stopEvent( eventInfo, data );
	}

	stopDomEvent( evt ) {
		EventHelper.stop( evt );
	}

	keyIs( dataObject, keyOrCodeName ) {
		return keyIs( dataObject, keyOrCodeName );
	}

	keyIsOneOf( dataObject, keyOrCodeNameList ) {
		if ( Validator.isString( keyOrCodeNameList ) )
			return this.keyIs( dataObject, keyOrCodeNameList );
		if ( !Validator.isArray( keyOrCodeNameList ) ) return false;
		keyOrCodeNameList = keyOrCodeNameList.filter( keyOrCodeName =>
			Validator.isString( keyOrCodeName ) );
		if ( keyOrCodeNameList.length < 1 ) return false;
		for ( let keyOrCodeName of keyOrCodeNameList )
			if ( this.keyIs( dataObject, keyOrCodeName ) ) return true;
		return false;
	}

	_shiftPressed( dataObject ) {
		return Validator.isObjectPath( dataObject, "dataObject.domEvent" ) &&
			Validator.isBoolean( dataObject.domEvent.shiftKey ) &&
			dataObject.domEvent.shiftKey;
	}

	_controlPressed( dataObject ) {
		return Validator.isObjectPath( dataObject, "dataObject.domEvent" ) &&
			Validator.isBoolean( dataObject.domEvent.ctrlKey ) &&
			dataObject.domEvent.ctrlKey;
	}

	// ---DOM Events--------------------------------------------------------------

	handleMouseDown( evt ) {
		// ...
	}

	handleMouseUp( evt ) {
		// ...
	}

	// ---Editor Events-----------------------------------------------------------

	onKeyup( eventInfo, data ) {
		// ...
	}

	onKeydown( eventInfo, data, listenerPriority = Number.NEGATIVE_INFINITY ) {
		if ( !this.changesAllowed ) return;
		if ( this.keyIs( data, "Control" ) || this.keyIs( data, "Shift" ) ) return;
		if ( this._handleTwoStepCaret( eventInfo, data ) ) return;
		if ( this.keyIs( data, "Backspace" ) ) return this._handleBackspace( eventInfo, data );
		if ( this.keyIs( data, "Delete" ) ) return this._handleDelete( eventInfo, data );
		if ( this.keyIs( data, "Enter" ) ) return this._handleEnter( eventInfo, data );
		if ( this._controlPressed( data ) ) {
			if ( this.keyIsOneOf( data, [ "s", "p", "w", "f", "y", "z", "b", "i", "u", "k" ] ) ) return;
			if ( this._shiftPressed( data ) &&
				this.keyIsOneOf( data, [ "x" ] ) ) return;
		}
		if ( this.keyIs( data, "x" ) && this._controlPressed( data ) )
			return this._handleCut( eventInfo, data );
		if ( this.keyIs( data, "v" ) && this._controlPressed( data ) )
			return this._handlePaste( eventInfo, data );
		if ( Validator.isObjectPath( data, "data.domEvent" ) &&
			Validator.isString( data.domEvent.key ) &&
			data.domEvent.key.length == 1 )
			return this._handlePrintableKey( eventInfo, data );
	}

	onSelectionChangeDone( eventInfo, data ) {
		if ( !this.changesAllowed ) return;
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) || selection.isCollapsed ) return;
		if ( this.ignoreNextSelectionChange )
			return this.ignoreNextSelectionChange = false;

		const positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );

		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ) return;
		// does not start nor end in placeholder => do nothing
		if ( !positions.start.in.inline && !positions.end.in.inline ) return;
		// starts in plh, but not at plh start => do nothing
		if ( positions.start.in.inline && !positions.start.atStartOf.inline ) return;
		// ends in plh, but not at plh end => do nothing
		if ( positions.end.in.inline && !positions.end.atEndOf.inline ) return;

		const editor = this.editor;

		if ( positions.start.atStartOf.inline && positions.end.atEndOf.inline ) {
			if ( positions.start.position.parent != positions.end.position.parent ) return;
			// move both
			editor.model.change( writer => {
				let startPosition = writer.createPositionBefore( positions.start.position.parent );
				if ( !this._positionExists( startPosition ) ) return;
				let endPosition = writer.createPositionAfter( positions.end.position.parent );
				if ( !this._positionExists( endPosition ) ) return;
				let range = writer.createRange( startPosition, endPosition );
				writer.setSelection( range );
			} );
			this.ignoreNextSelectionChange = true;
			return editor.objects.selection.update();
		}

		if ( positions.start.atStartOf.inline ) {
			if ( !positions.end.after.inline ) return;
			if ( positions.start.position.parent !=
				this.getInlinePlaceholderBefore( positions.end.position ) ) return;
			// move start
			editor.model.change( writer => {
				let startPosition = writer.createPositionBefore( positions.start.position.parent );
				if ( !this._positionExists( startPosition ) ) return;
				let range = writer.createRange( startPosition, positions.end.position );
				writer.setSelection( range );
			} );
			this.ignoreNextSelectionChange = true;
			return editor.objects.selection.update();
		}

		if ( !positions.start.before.inline ) return;
		if ( positions.end.position.parent !=
			this.getInlinePlaceholderAfter( positions.start.position ) ) return;
		// move end
		editor.model.change( writer => {
			let endPosition = writer.createPositionAfter( positions.end.position.parent );
			if ( !this._positionExists( endPosition ) ) return;
			let range = writer.createRange( positions.start.position, endPosition );
			writer.setSelection( range );
		} );
		this.ignoreNextSelectionChange = true;
		return editor.objects.selection.update();
	}

	_onLinkHref( eventInfo, data, conversionApi ) {
		if ( !this.react ) return;
		let selection = this.currentSelection;

		// collapsed selection will trigger text insertion and does not need to be
		// handled separately
		if ( !Validator.isObject( selection ) || selection.isCollapsed ) return;

		// console.log( "linkHref" );

		// const positions = this.getPositionToPlaceholderComplexPlacement( {
		// 	start: selection.anchor,
		// 	end: selection.focus
		// } );
		//
		// if ( !Validator.isObject( positions.start.position ) ||
		// 	!Validator.isObject( positions.end.position ) ) return;
		//
		// const advancedPlacement = this.getAdvancedPositionToPlaceholderPlacement( {
		// 	positions: positions
		// } );

	}

	// ---Helpers-----------------------------------------------------------------

	_handleBackspace( eventInfo, data ) {
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return false;
		const editor = this.editor;
		if ( selection.isCollapsed )
			return !this.endsAfterInlinePlaceholder( selection.anchor ) ? true :
				this._moveSelectionToInlinePlaceholderEnd( selection.anchor );
		this._handleRemovalWithExpandedSelection( {
			eventInfo: eventInfo,
			data: data,
			selection: selection
		} );
		return true;
	}

	_handleDelete( eventInfo, data ) {
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return false;
		if ( selection.isCollapsed )
			return !this.startsBeforeInlinePlaceholder( selection.anchor ) ? true :
				this._moveSelectionToInlinePlaceholderStart( selection.anchor );
		this._handleRemovalWithExpandedSelection( {
			eventInfo: eventInfo,
			data: data,
			selection: selection
		} );
		return true;
	}

	_handleEnter( eventInfo, data ) {
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return false;

		if ( this.someSelectionInMultilinePlaceholder )
			return this._handleEnterInMultilinePlaceholder( eventInfo, data );

		if ( !selection.isCollapsed )
			return this._handleRemovalWithExpandedSelection( {
				eventInfo: eventInfo,
				data: data,
				selection: selection,
				stopEvent: false
			} );

		this._handleEnterWithCollapsedSelection( eventInfo, data, selection );
	}

	_handleEnterWithCollapsedSelection( eventInfo, data, selection ) {
		let positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );
		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ) return;
		if ( !Validator.arraysEqual( positions.start.position.path,
				positions.end.position.path ) )
			return this._log( "When handling enter for a collapsed selection," +
				" the position placement analysis detected 2 different positions for" +
				" the start and end of the selection." );
		if ( positions.start.before.inline || positions.end.after.inline ) return;

		const editor = this.editor;

		if ( positions.start.atStartOf.inline ) {
			// collapsed selection at start of inline plh, inside plh =>
			// move selection before plh and let the event propagate
			editor.model.change( writer => {
				let startPosition = writer
					.createPositionBefore( positions.start.position.parent );
				if ( !this._positionExists( startPosition ) ) return;
				let range = writer.createRange( startPosition );
				writer.setSelection( range );
			} );
			return;
		}

		if ( positions.end.atEndOf.inline ) {
			// collapsed selection at end of inline plh, inside plh =>
			// move selection after plh and let the event propagate
			editor.model.change( writer => {
				let endPosition = writer
					.createPositionAfter( positions.end.position.parent );
				if ( !this._positionExists( endPosition ) ) return;
				let range = writer.createRange( endPosition );
				writer.setSelection( range );
			} );
			return;
		}

		if ( positions.start.in.inline ) {
			// collapsed selection at end of inline plh, inside plh =>
			// remove plh and let the event propagate
			this.removeInlinePlaceholder( positions.end.position.parent );
			return;
		}
	}

	_handleCut( eventInfo, data ) {
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return false;
		if ( selection.isCollapsed ) return true;
		// TODO do something with the copying to clipboard
		this._handleRemovalWithExpandedSelection( {
			eventInfo: eventInfo,
			data: data,
			selection: selection,
			callbackBeforeHandling: ( needsSpecialHandling ) => {
				if ( !needsSpecialHandling ) return;
				window.document.execCommand( "copy" );
			}
		} );
		return true;
	}

	_handlePaste( eventInfo, data ) {
		// console.log( "paste" );
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return false;
		if ( selection.isCollapsed ) return true;
		this._handleRemovalWithExpandedSelection( {
			eventInfo: eventInfo,
			data: data,
			selection: selection,
			stopEvent: false
		} );
		return true;
	}

	_handlePrintableKey( eventInfo, data ) {
		// if ( !this.react || !this.someSelectionInPlaceholder )
		// 	return this.resetKeydownOnSelection();
		if ( !this.react ) return this.resetKeydownOnSelection();
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return this.resetKeydownOnSelection();
		if ( selection.isCollapsed )
			return this._handlePrintableKeyWithCollapsedSelection( eventInfo, data, selection );
		let batchUsed = void 0;
		let needsHandling = this._handleRemovalWithExpandedSelection( {
			eventInfo: eventInfo,
			data: data,
			selection: selection,
			stopEvent: true,
			callbackBeforeHandling: ( needsSpecialHandling, batch ) => {
				if ( !needsSpecialHandling ) return;
				batchUsed = batch;
				// console.log( "Printable key in placeholder" );
			}
		} );
		if ( !needsHandling ) return this.setKeydownOnSelection();
		this.resetKeydownOnSelection();
		const editor = this.editor;
		if ( !Validator.isObject( batchUsed ) )
			batchUsed = editor.model.createBatch();
		editor.model.enqueueChange( batchUsed, writer => {
			let textContent = data.domEvent.key;
			textContent = this._shiftPressed( data ) ? textContent.toUpperCase() :
				textContent.toLowerCase();
			let attributes = this.objectsSelection._copyCurrentAttributes( false );
			writer.insertText( textContent, attributes,
				editor.model.document.selection.anchor );
		} );
	}

	_handlePrintableKeyWithCollapsedSelection( eventInfo, data, selection ) {

		let positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );

		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ||
			positions.noPlaceholderNearby ) return this.resetKeydownOnSelection();

		const editor = this.editor;

		// this one is handled correctly by _afterChanges
		if ( positions.end.atEndOf.inline ) return;

		if ( positions.start.atStartOf.inline ) {
			editor.model.change( writer => {
				let finalPosition = writer.createPositionBefore( positions.start.position.parent );
				this._setSelectionToPositionIf( finalPosition, writer );
			} );
			return this.keyAtPlaceholderStart = data.domEvent.key;
		}
		// return this.doWithLastBatchRemoval( () => {
		// 	this.removeInlinePlaceholder( positions.start.position.parent );
		// 	this.resetKeydownOnSelection();
		// } );

		if ( !positions.start.before.inline && !positions.end.after.inline )
			return this.resetKeydownOnSelection();

		// aren't they actually the same since the selection is collapsed?
		let finalPosition = positions.start.before.inline ?
			positions.start.position : positions.end.position;

		let textContent = data.domEvent.key;
		textContent = this._shiftPressed( data ) ? textContent.toUpperCase() :
			textContent.toLowerCase();
		editor.model.change( writer => {
			let attributes = this.objectsSelection._copyCurrentAttributes( false );
			writer.insertText( textContent, attributes, finalPosition );
		} );
		this.stopEditorEvent( eventInfo, data );

		// do we need to set it since we stopped the event?
		return this.resetKeydownOnSelection();
	}

	_handleTwoStepCaret( eventInfo, data ) {
		let success = true;
		let fail = false;
		let currentSelection = this.currentSelection;
		if ( !Validator.isObject( currentSelection ) ||
			!currentSelection.isCollapsed ) return fail;
		// handle Arrow left
		let editor = this.editor;
		let position = currentSelection.anchor || currentSelection.focus;
		if ( keyIs( data, "ArrowLeft" ) ) {
			if ( this._shiftPressed( data ) ) return success;
			if ( this.endsAfterInlinePlaceholder( position ) ) {
				return this._moveSelectionToInlinePlaceholderEnd( position, eventInfo, data );
			} else if ( this.startsInInlinePlaceholder( position ) ) {
				let inlinePlaceholder = Validator.isElement( position.parent, "pisaPlaceholder" ) ?
					position.parent : void 0;
				if ( !Validator.isObject( inlinePlaceholder ) ) return success;
				let newPosition = editor.model.change( writer => {
					return writer.createPositionBefore( inlinePlaceholder );
				} );
				if ( !this._positionExists( newPosition ) ) return success;
				this.stopEditorEvent( eventInfo, data );
				editor.model.change( writer => {
					writer.setSelection( newPosition );
				} );
			} else if ( this.isInInlinePlaceholder( position ) &&
				position.path[ position.path.length - 1 ] == 1 ) {
				let inlinePlaceholder = Validator.isElement( position.parent, "pisaPlaceholder" ) ?
					position.parent : void 0;
				if ( !Validator.isObject( inlinePlaceholder ) ) return success;
				let newPosition = editor.model.change( writer => {
					return writer.createPositionAt( inlinePlaceholder, 0 );
				} );
				if ( !this._positionExists( newPosition ) ) return success;
				this.stopEditorEvent( eventInfo, data );
				editor.model.change( writer => {
					writer.setSelection( newPosition );
				} );
			} else if ( this._positionExists( position ) &&
				position.path[ position.path.length - 1 ] >= 1 &&
				!this.isInInlinePlaceholder( position ) ) {
				let newPath = [ ...position.path ];
				newPath[ newPath.length - 1 ] = newPath[ newPath.length - 1 ] - 1;
				let newPosition = editor.model.change( writer => {
					if ( !Validator.isObjectPath( editor, "editor.model.document" ) ||
						!Validator.isFunction( editor.model.document.getRoot ) ) return void 0;
					return writer.createPositionFromPath( editor.model.document.getRoot(), newPath );
				} );
				if ( !this.endsAfterInlinePlaceholder( newPosition ) ) return success;
				this.stopEditorEvent( eventInfo, data );
				editor.model.change( writer => {
					writer.setSelection( newPosition );
				} );
			}
			return success;
		}
		// handle Arrow right
		if ( !keyIs( data, "ArrowRight" ) ) return fail;
		if ( this._shiftPressed( data ) ) return success;
		if ( this.startsBeforeInlinePlaceholder( position ) ) {
			return this._moveSelectionToInlinePlaceholderStart( position, eventInfo, data );
		} else if ( this.endsInInlinePlaceholder( position ) ) {
			let inlinePlaceholder = Validator.isElement( position.parent, "pisaPlaceholder" ) ?
				position.parent : void 0;
			if ( !Validator.isObject( inlinePlaceholder ) ) return success;
			let newPosition = editor.model.change( writer => {
				return writer.createPositionAfter( inlinePlaceholder );
			} );
			if ( !this._positionExists( newPosition ) ) return success;
			this.stopEditorEvent( eventInfo, data );
			editor.model.change( writer => {
				writer.setSelection( newPosition );
			} );
		}
		return success;
	}

	_handleRemovalWithExpandedSelection( {
		eventInfo,
		data,
		selection,
		stopEvent = true,
		callbackBeforeHandling = void 0
	} ) {
		let neededHandling = false;
		const positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );
		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ) return neededHandling;
		const advancedPlacement = this.getAdvancedPositionToPlaceholderPlacement( {
			positions: positions
		} );
		if ( !advancedPlacement.onlyStartIsInside &&
			!advancedPlacement.onlyEndIsInside &&
			!advancedPlacement.startsAndEndsInDifferentPlaceholders )
			return neededHandling;
		neededHandling = true;
		const editor = this.editor;
		let batch = editor.model.createBatch();
		if ( Validator.isFunction( callbackBeforeHandling ) )
			FunctionHelper.exec( callbackBeforeHandling, [ neededHandling, batch ] );
		if ( advancedPlacement.onlyStartIsInside ) {
			let placeholder = positions.start.position.parent;
			editor.model.enqueueChange( batch, writer => {
				this._removeRangeAfterPlaceholder( placeholder, writer, positions );
				this._removeRangeAtPlaceholderEnd( placeholder, writer, positions );
				let finalPosition = this._getFinalPosition( positions, placeholder, writer );
				this.removeInlinePlaceholder( placeholder, batch, writer );
				this._setSelectionToPositionIf( finalPosition, writer );
			} );
			return !stopEvent ? neededHandling :
				( this.stopEditorEvent( eventInfo, data ) || neededHandling );
		} else if ( advancedPlacement.onlyEndIsInside ) {
			let placeholder = positions.end.position.parent;
			editor.model.enqueueChange( batch, writer => {
				this._removeRangeAtPlaceholderStart( placeholder, writer, positions );
				this._removeRangeBeforePlaceholder( placeholder, writer, positions );
				this.removeInlinePlaceholder( placeholder, batch, writer );
				this._setSelectionToPositionIf( positions.start.position, writer );
			} );
			return !stopEvent ? neededHandling :
				( this.stopEditorEvent( eventInfo, data ) || neededHandling );
		} else {
			let startPlaceholder = positions.start.position.parent;
			let endPlaceholder = positions.end.position.parent;
			editor.model.enqueueChange( batch, writer => {
				this._removeRangeAtPlaceholderStart( endPlaceholder, writer, positions );
				this._removeRangeBetweenPlaceholders( startPlaceholder, endPlaceholder, writer );
				this._removeRangeAtPlaceholderEnd( startPlaceholder, writer, positions );
				this.removeInlinePlaceholder( endPlaceholder, batch, writer );
				let finalPosition = this._getFinalPosition( positions, startPlaceholder, writer );
				this.removeInlinePlaceholder( startPlaceholder, batch, writer );
				this._setSelectionToPositionIf( finalPosition, writer );
			} );
			return !stopEvent ? neededHandling :
				( this.stopEditorEvent( eventInfo, data ) || neededHandling );
		}
		return neededHandling;
	}

	_handleEnterInMultilinePlaceholder( eventInfo, data ) {
		if ( !this.changesAllowed ) return this._cleanUp();
		this.lastInsert = {
			eventInfo: eventInfo,
			data: data,
			key: Validator.isObjectPath( data, "data.domEvent" ) &&
				Validator.isString( data.domEvent.key ) ? data.domEvent.key : void 0,
			code: Validator.isObjectPath( data, "data.domEvent" ) &&
				Validator.isString( data.domEvent.code ) ? data.domEvent.code : void 0
		};
		return;
		const editor = this.editor;
		const blocks = [ ...editor.model.document.selection.getSelectedBlocks() ];
		let batch = this.newBatch;
		editor.model.enqueueChange( batch, writer => {
			for ( let block of blocks )
				this.removeMultilinePlaceholder( block, batch, writer );
		} );
		this._cleanUp();
		// do not stop event from propagating
	}

}

// const ATTRIBUTES_TO_MIRROR = [ "stopEditorEvent", "stopDomEvent", "keyIs",
// 	"_shiftPressed", "_controlPressed", "handleMouseDown", "handleMouseUp",
// 	"onKeyup", "onKeydown", "onSelectionChangeDone", "_handleBackspace",
// 	"_handleDelete", "_handleEnter", "_handleCut", "_handlePaste",
// 	"_handlePrintableKey", "_handleTwoStepCaret",
// 	"_handleRemovalWithExpandedSelection", "keyIsOneOf",
// 	"_handleEnterWithCollapsedSelection", "_onLinkHref",
// 	"_handlePrintableKeyWithCollapsedSelection"
// 	// "handleDomPaste"
// ];
