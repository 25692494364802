import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Validator from '../pisautils/validator';
import { PLACEHOLDER } from './pisaplaceholderui';
import { placeholderToLines, replaceGroup, replaceGroupWithGroup } from './showplaceholdercommand';
import { placeholderEntriesToMap, setPlaceholderText } from './utils';
import { registerMultilinePlaceholder } from './insertplaceholdercommand';
import { PLACEHOLDER_NAME, PLACEHOLDER_VALUE } from './pisadefinitionediting';
import { PLACEHOLDER_NAMES, PLACEHOLDER_VALUES } from './pisaplaceholderui';

const LINE_NR = "placeholderLineNumber";

export default class Placeholders extends Plugin {

	static get pluginName() {
		return 'Placeholders';
	}

	init() {
		let editor = this.editor;
		editor.objects = editor.objects || {};
		editor.objects.placeholders = this;
		const VALUES_TOOLTIP = editor.objects.tooltips.getT( PLACEHOLDER_VALUES )
		const NAMES_TOOLTIP = editor.objects.tooltips.getT( PLACEHOLDER_NAMES )

		this.ui = {};
		this.map = new Map();
		this.titlesShown = false;
		this.lastChangeIsToggle = false;
		this.temp = [];
		this.htmlInlineTemp = [];
		this.groupTemp = new Map();
		this.ui.toggleButtons = [];
		this.lastBatch = editor.model.createBatch();

		this.ui._setToggleButton = ( titlesMode, toggleButton ) => {
			titlesMode = !!titlesMode;
			if ( titlesMode ) {
				if ( !toggleButton.isOn ) toggleButton.isOn = true;
				if ( toggleButton.tooltip != VALUES_TOOLTIP )
					toggleButton.tooltip = VALUES_TOOLTIP;
				return;
			}
			if ( toggleButton.isOn ) toggleButton.isOn = false;
			if ( toggleButton.tooltip != NAMES_TOOLTIP )
				toggleButton.tooltip = NAMES_TOOLTIP;
		};

		this.ui._setAllToggleButtons = ( titlesMode ) => {
			this.ui.toggleButtons.forEach( toggleButton => {
				this.ui._setToggleButton( titlesMode, toggleButton );
			} );
		};

		this.ui._harmonizeToggleButton = ( toggleButton ) => {
			this.ui._setToggleButton(
				this.titlesShown, toggleButton );
		};

		this.ui.harmonizeAllToggleButtons = () => {
			this.ui.toggleButtons.forEach( toggleButton => {
				this.ui._harmonizeToggleButton( toggleButton );
			} );
		};
	}

	toMap( entries ) {
		return placeholderEntriesToMap( this.editor, entries );
	}

	getGroupIndex() {
		return Number( Date.now() );
	}

	renewBatch() {
		this.lastBatch = this.editor.model.createBatch();
	}

	refreshToggle() {
		let command = this.editor.commands.get( PLACEHOLDER );
		command && command.refresh();
	}

	refreshInsert() {
		let command = this.editor.commands.get( "insertPlaceholder" );
		command && command.refresh();
	}

	clearTemp() {
		if ( this.titlesShown || !this.temp ||
			this.temp.length <= 0 ) return;
		let dataProcessor = this.editor.data.processor || this.editor.getPsaDP();
		this.temp.forEach( element => {
			let title = element._attrs.get( PLACEHOLDER_NAME );
			let value = dataProcessor._decode64( element._attrs.get( PLACEHOLDER_VALUE ) );
			placeholderToLines( this.editor, element, title, value );
		} );
		this.temp = [];
		this.editor.commands.get( "undo" ).clearStack();
		this.renewBatch();
	}

	clearGroupTemp() {
		if ( !this.titlesShown ||
			typeof this.groupTemp != "object" ||
			this.groupTemp.size <= 0 ) return;
		let dataProcessor = this.editor.data.processor || this.editor.getPsaDP();
		this.groupTemp.forEach( ( group, groupIndex ) => {
			let element = group[ 0 ];
			let title = element._attrs.get( "placeholderName" );
			let value = dataProcessor._decode64( element._attrs.get( "placeholderValue" ) );
			replaceGroup( this.editor, { elementsList: group }, title, value );
		} );
		this.groupTemp = new Map();
		this.editor.commands.get( "undo" ).clearStack();
		this.renewBatch();
	}

	clearHtmlInlineTemp() {
		if ( this.titlesShown || !this.htmlInlineTemp ||
			this.htmlInlineTemp.length <= 0 ) return;
		let dataProcessor = this.editor.data.processor || this.editor.getPsaDP();

		this.htmlInlineTemp.forEach( element => {
			let placeholderValue = element._attrs.get( PLACEHOLDER_VALUE ) || "";
			let value = dataProcessor._isEncoded64( placeholderValue ) ?
				dataProcessor._decode64( placeholderValue ) : placeholderValue;
			setPlaceholderText( this.editor, element, value );
		} );
		this.htmlInlineTemp = [];
		this.editor.commands.get( "undo" ).clearStack();
		this.renewBatch();
	}

	clearPlaceholderReferences() {
		this.map = new Map();
		this.temp = [];
		this.htmlInlineTemp = [];
		this.groupTemp = new Map();
		this.renewBatch();
	}

	requestBlobTranslation() {
		let values = { entries: [] };
		let imageRegex = new RegExp( '<img[^>]*src="pscblb:[^"]+"[^>]*>', "gi" );
		this.map.forEach( ( value, placeholderTitle ) => {
			if ( !value.unicodeValue || typeof value.unicodeValue != "string" ) return;
			let blobImages = value.unicodeValue.match( imageRegex );
			if ( !blobImages || !( blobImages instanceof Array ) ||
				blobImages.length < 1 ) return;
			values.entries.push( { key: placeholderTitle, value: value.unicodeValue } );
		} );
		if ( values.entries.length > 0 )
			this.editor.fire( "requestPlaceholderRefresh", values );
	}

	assureSingleLineValidity() {
		if ( !this.titlesShown ) return;
		this.map.forEach( ( value, placeholderTitle ) => {
			if ( value.singleLine == false ) return;
			// console.log( value );
		} );
	}

	assurePlaceholderIntegrity() {
		if ( this.titlesShown ) return;
		this.map.forEach( ( value, placeholderTitle ) => {
			if ( value && value.elementGroups instanceof Array &&
				value.elementGroups.length > 0 ) {
				value.elementGroups.forEach( group => {
					this.refreshGroup( group );
				} );
			}
		} );
	}

	refreshGroup( group ) {
		if ( group.elementsList.length < 1 ) return;
		let elementLineNumber = Number( group.elementsList[ 0 ]._attrs.get( LINE_NR ) );
		if ( !elementLineNumber ) return;
		if ( elementLineNumber == group.elementsList.length &&
			elementLineNumber == group.lineCount ) return;
		let different = false;
		group.elementsList.forEach( element => {
			if ( element._attrs.get( LINE_NR ) != elementLineNumber ) different = true;
		} );
		if ( different ) {
			//...
			return;
		}
		if ( elementLineNumber == group.elementsList.length ) {
			group.lineCount = elementLineNumber;
			return;
		}
		if ( elementLineNumber < group.elementsList.length ) {
			//...
			return;
		}
		let titleAndValue = this._getTitleAndValue( group.elementsList[ 0 ] );
		if ( !titleAndValue ) return;
		replaceGroupWithGroup( this.editor, group, titleAndValue.title, titleAndValue.value );
		this._clearGroup( group );
	}

	_clearGroup( group ) {
		group.elementsList = [];
		group.groupIndex = void 0;
		group.lineCount = 0;
	}

	_getTitleAndValue( element ) {
		let dataProcessor = this.editor.data.processor || this.editor.getPsaDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) return;
		return {
			title: element._attrs.get( "placeholderName" ),
			value: dataProcessor._decode64( element._attrs.get( "placeholderValue" ) )
		}
	}

	removeReference( placeholderElement ) {
		if ( !Validator.isObject( placeholderElement ) ) return false;
		return placeholderElement.name == PLACEHOLDER ?
			this._removeInline( placeholderElement ) :
			this._removeMultiline( placeholderElement );
	}

	_removeInline( placeholderElement ) {
		if ( !Validator.isObject( placeholderElement ) ||
			placeholderElement.name != PLACEHOLDER ) return false;
		let title = placeholderElement.getAttribute( PLACEHOLDER_NAME ) ||
			( Validator.isMap( placeholderElement._attrs ) ?
				placeholderElement._attrs.get( PLACEHOLDER_NAME ) : void 0 ) || void 0;
		if ( !Validator.isString( title ) ) return false;
		let entry = this.map.get( title );
		if ( !Validator.isObject( entry ) || !Validator.isArray( entry.elements ) ) return false;
		let foundAndDeleted = false;
		for ( let i = 0; i < entry.elements.length; i++ ) {
			if ( entry.elements[ i ] !== placeholderElement ) continue;
			entry.elements[ i ] = void 0;
			entry.elements.splice( i, 1 );
			foundAndDeleted = true;
			break;
		}
		if ( entry.elements.length <= 0 )
			this.map.delete( title );
		return foundAndDeleted;
	}

	_removeMultiline( placeholderElement ) {
		if ( !Validator.isObject( placeholderElement ) ||
			placeholderElement.name != "paragraph" ) return;
		const title = placeholderElement._attrs.get( "placeholderName" );
		if ( !Validator.isString( title ) ) return;
		const groupIndex = placeholderElement._attrs.get( "groupIndex" );
		let numberIndex, stringIndex = void 0;
		if ( !Validator.isString( groupIndex ) &&
			!Validator.isPositiveInteger( groupIndex ) ) return;
		if ( Validator.isString( groupIndex ) ) {
			stringIndex = groupIndex;
			numberIndex = Number( groupIndex );
		} else {
			numberIndex = groupIndex;
			stringIndex = String( groupIndex );
		}
		let entry = this.map.get( title );
		if ( !Validator.isObject( entry ) ) return;
		if ( Validator.isArray( entry.elementGroups ) && entry.elementGroups.length > 0 )
			for ( let i = 0; i < entry.elementGroups.length; i++ ) {
				const group = entry.elementGroups[ i ];
				if ( !Validator.isObject( group ) ) continue;
				if ( group.groupIndex != numberIndex &&
					group.groupIndex != stringIndex ) continue;
				entry.elementGroups[ i ] = void 0;
				entry.elementGroups.splice( i, 1 );
				i--; // or should we break?
			}
	}

	registerMultilinePlaceholder( {
		key,
		unicodeValue,
		base64Value,
		documentFragment,
		placeholderLines
	} ) {
		if ( !Validator.isString( key ) ) return false;
		const editor = this.editor;
		const dataProcessor = Validator.isObjectPath( editor, "editor.data.processor" ) ?
			editor.data.processor : void 0;
		if ( !Validator.isObject( dataProcessor ) ) return false;
		if ( !Validator.isString( unicodeValue ) ) {
			if ( !Validator.isString( base64Value ) ||
				!Validator.isFunction( dataProcessor._decode64 ) ) return false;
			unicodeValue = dataProcessor._decode64( base64Value );
		}
		if ( !Validator.isString( base64Value ) ) {
			if ( !Validator.isFunction( dataProcessor._encode64 ) ) return false;
			base64Value = dataProcessor._encode64( unicodeValue );
		}
		let docFrag = void 0;
		if ( !Validator.isObjectPath( documentFragment, "documentFragment._children._nodes" ) ) {
			if ( !Validator.isArray( placeholderLines ) ) return false;
			docFrag = { _children: { _nodes: placeholderLines } }
		} else {
			docFrag = documentFragment;
		}
		registerMultilinePlaceholder( this.editor, key, unicodeValue, base64Value, docFrag );
		return true;
	}
}
