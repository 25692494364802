import Command from '@ckeditor/ckeditor5-core/src/command';
import { setPlaceholderText, isSingleLine, isInline } from './utils';
import {
	insertPlaceholderLines,
	getPosition,
	insertDfnAtPosition,
	insertDefinitionContainer,
	EMPTY_PLACEHODER_VALUE
} from './insertplaceholdercommand';
// import { setSelectionAfterNode } from './pisaplaceholderui';
import { /*areLiveSelectionRangesValid, handleInvalidSelectionRanges,*/ removeLastUndoSteps } from '../utils';
export const REFRESH_PLACEHOLDER = "refreshPlaceholder";

export default class RefreshPlaceholderCommand extends Command {

	constructor( editor ) {
		super( editor, REFRESH_PLACEHOLDER );
	}

	execute( options = {} ) {
		// TODO when refresh happens in titles mode, if inline-to-multiline or
		// multiline-to-inline it is not shown right
		const editor = this.editor;

		const placeholderUIPlugin = editor.plugins._plugins.get( "PisaPlaceholderUI" );
		const initialEditingEnabledValue = placeholderUIPlugin &&
			typeof placeholderUIPlugin == "object" ?
			placeholderUIPlugin.editingEnabled : false;
		if ( !!initialEditingEnabledValue )
			placeholderUIPlugin.editingEnabled = false;

		let title = options.title || options.value;
		if ( typeof title != "string" || !editor.objects || !editor.objects.placeholders ||
			// editor.objects.placeholders.titlesShown ||
			!editor.objects.placeholders.map ||
			typeof editor.objects.placeholders.map.get( title ) != "object" ) return;

		let valueObject = editor.objects.placeholders.map.get( title );
		let dataProcessor = editor.data.processor || editor.getPsaDP();
		if ( !dataProcessor ) return;
		if ( valueObject.unicodeValue == "" ) {
			valueObject.unicodeValue = EMPTY_PLACEHODER_VALUE;
			// valueObject.base64Value = dataProcessor._encode64( EMPTY_PLACEHODER_VALUE );
			// if ( editor.objects.placeholders.titlesShown ) {
			// 	editor.objects.placeholders.map.set( title, valueObject );
			// 	return;
			// }
		}
		let oldValInline = valueObject.singleLine != false && valueObject.singleLine != "false" &&
			( typeof valueObject.elementGroups != "object" || valueObject.elementGroups.length <= 0 );
		let viewFrag = dataProcessor.strToViewFrag( valueObject.unicodeValue );
		// let docFrag = dataProcessor.htmToDocFrag( dataProcessor._isHtmStr( valueObject.unicodeValue ) ?
		// 	valueObject.unicodeValue : dataProcessor._textStrToHtml( valueObject.unicodeValue ) );
		if ( !viewFrag ) return;

		// let newValInline = isSingleLine( docFrag );
		let newValInline = isInline( viewFrag );

		editor.notifyDirty = false;
		editor.objects.placeholders.lastChangeIsToggle = true;

		oldValInline ? ( newValInline ? inlineToInline( editor, valueObject ) :
				inlineToMultiline( editor, title, valueObject ) ) :
			( newValInline ? multilineToInline( editor, title, valueObject ) :
				multilineToMultiline( editor, title, valueObject ) );

		editor.notifyDirty = true;
		editor.objects.placeholders.lastChangeIsToggle = false;
		removeLastUndoSteps( editor );

		editor.objects.selection.update();
		editor.objects.placeholders.renewBatch();
		editor.objects.placeholders.refreshToggle();

		if ( !!initialEditingEnabledValue )
			placeholderUIPlugin.editingEnabled = true;

	}

}

function inlineToInline( editor, value ) {
	if ( !editor || !value || !value.elements ) return;
	value.elements.forEach( plhElement => {
		if ( !plhElement || plhElement.name != "pisaPlaceholder" ) return;
		editor.objects.placeholders.titlesShown ? void 0 :
			setPlaceholderText( editor, plhElement, value.unicodeValue );
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			writer.setAttribute( "data-value", value.base64Value, plhElement );
		} );
	} );
}

function inlineToMultiline( editor, title, value ) {
	if ( !editor || !value || !value.elements ) return;
	value.elements.forEach( plhElement => {
		let selection = editor.model.change( writer => {
			return writer.createSelection( plhElement.parent, "after" );
		} );
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			writer.remove( plhElement );
		} );
		if ( !selection ) return;
		editor.objects.placeholders.titlesShown ?
			insertDefinitionContainer( editor, title, value.unicodeValue, selection ) :
			insertPlaceholderLines( editor, title, value.unicodeValue, selection );
	} );
	value.singleLine = false;
	if ( !editor.objects.placeholders.titlesShown ) {
		value.elements = [];
		value.occurences = value.elementGroups ? value.elementGroups.length : value.occurences;
	} else {
		value.elementGroups = [];
		value.occurences = value.elements ? value.elements.length : value.occurences;
	}
}

function multilineToInline( editor, title, value ) {
	if ( !editor || !title || !value ) return;
	if ( value.elements && editor.objects.placeholders.titlesShown ) {
		multilineToInlineTitle( editor, title, value );
	}
	if ( value.elementGroups && !editor.objects.placeholders.titlesShown ) {
		multilineToInlineValue( editor, title, value );
	}
	let dataProcessor = editor.data.processor || editor.getPsaDP();
	delete value.elementGroups;
	value.singleLine = true;
	value.base64Value = dataProcessor._encode64( value.unicodeValue );
	value.occurences = value.elements.length;
}

function multilineToInlineTitle( editor, title, value ) {
	if ( !editor || !title || !value || !value.elements ||
		!editor.objects.placeholders.titlesShown ) return;
	for ( let element of value.elements ) {
		if ( element.name != "pisaPlaceholder" || typeof element.parent != "object" ||
			element.parent.name != "paragraph" || typeof element.parent._attrs != "object" ||
			String( element.parent._attrs.get( "isPlaceholderContainer" ) ) != "true" ) continue;
		let paragraph = element.parent;
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			writer.removeAttribute( "isPlaceholderContainer", paragraph );
			writer.remove( element );
			let range = writer.createRangeIn( paragraph );
			insertDfnAtPosition( editor, range.start, {
				title: title,
				value: value.unicodeValue,
				singleLine: true
			} );
		} );
	}
}

function multilineToInlineValue( editor, title, value ) {
	if ( !editor || !title || !value || !value.elementGroups ||
		editor.objects.placeholders.titlesShown ) return;
	for ( let group of value.elementGroups ) {
		let selection = removeGroupAndGetSelection( editor, group );
		if ( !selection ) continue;
		let insertPosition = getPosition( selection );
		if ( !insertPosition ) continue;
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			let paragraph = writer.createElement( "paragraph" );
			writer.insert( paragraph, insertPosition );
			let range = writer.createRangeIn( paragraph );
			insertDfnAtPosition( editor, range.start, {
				title: title,
				value: value.unicodeValue,
				singleLine: true
			} );
		} );
	}
}

function multilineToMultiline( editor, title, value ) {
	if ( !editor || !title || !value || !value.elementGroups ) return;
	value.elementGroups.forEach( group => {
		let selection = removeGroupAndGetSelection( editor, group );
		if ( !selection ) return;
		insertPlaceholderLines( editor, title, value.unicodeValue, selection );
	} );
}

function removeGroupAndGetSelection( editor, group ) {
	if ( !group.elementsList || group.elementsList.length <= 0 ) return;
	let firstLine = group.elementsList[ 0 ];
	if ( firstLine.name != "paragraph" || !firstLine._attrs ||
		typeof firstLine._attrs.get( "placeholderName" ) != "string" ) return;
	let selection = editor.model.change( writer => {
		return writer.createSelection( firstLine, "before" );
	} );
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		group.elementsList.forEach( line => {
			writer.remove( line );
		} );
	} );
	return selection;
}
