import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaFire from '../pisafire/pisafire';
// import LinkEditing from '../iconsui/linkediting';
// import LinkEditing from '@ckeditor/ckeditor5-link/src/linkediting';
import PisaExtendedLinkEditing from '../pisalink/pisaextendedlinkediting';
import { onDomEvent, fire, stopEvent, checkSelection, getSelectionRange, getMainRoot } from '../utils';
import { isInsidePlaceholder, keyIs } from '../pisaplaceholder/pisaplaceholderui';
import Warner from '../pisautils/warner';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

const EVENT_NAME = "focusChanged"
const CLASNAME = "ck-editor";
const EDITOR_CLASSNAMES = [ CLASNAME, "ck-input", "ck-button" ];
const DROPDOWN_CLASSNAME = "ck-dropdown__panel";
const TAB_SPACE = "&emsp;";
const MAX = Number.MAX_SAFE_INTEGER;
const MIN = Number.MIN_SAFE_INTEGER;
const ESCAPED_ATTRIBUTES = [ "linkHref", "pisaPlaceholder" ];

const PROTO_REGEX = "(?:(?:ftp|https?):\\/{2})?";
// const SUB_DOMAIN_REGEX = "([a-zA-Z\\d][a-zA-Z\\d\\-]{0,61}[a-zA-Z\\d]\\.){1,4}";
const SUB_DOMAIN_REGEX = "(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9]\\.){1,3}";
const TOP_LEVEL_DOMAIN_REGEX = "[a-zA-Z]{2,62}";
const PORT_NUMBER_REGEX = "(?:\\:\\d+)?";
const PATH_REGEX = "(?:\\/[a-zA-Z0-9\\$\\-\\_\\+\\!\\*\\'\\(\\)\\,\\.\\%\\#\\:\\/\\?\\=\\&]+)*[\\/]?";

// const MAIL_PREFIX_REGEX = "[a-zA-Z\\d]([a-zA-Z\\d]*[\\!\\#\\$\\%\\&\\'\\*\\+\\-\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~\\.]?)*[a-zA-Z\\d]\\@";
const MAIL_PREFIX_REGEX = "(?:[a-zA-Z0-9](?:[\\!\\#\\$\\%\\&\\'\\*\\+\\-\\/\\=\\?\\^\\_\\`\\{\\|\\}\\~\\.]?[a-zA-Z0-9])+){1,21}\\@";
// const MAIL_ADRESS_REGEX = "\\w+([\\.-]?\\w+)*\\@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+";
const MAIL_ADRESS_REGEX = MAIL_PREFIX_REGEX + SUB_DOMAIN_REGEX + TOP_LEVEL_DOMAIN_REGEX;

const URL_REGEX = PROTO_REGEX + SUB_DOMAIN_REGEX +
	TOP_LEVEL_DOMAIN_REGEX + PORT_NUMBER_REGEX + PATH_REGEX;

export default class PisaListeners extends Plugin {

	static get requires() {
		// return [ PisaFire, LinkEditing ];
		return [ PisaFire, PisaExtendedLinkEditing ];
	}

	init() {
		const editor = this.editor;
		editor.objects = editor.objects || {};

		this.selectionCollapsed = void 0;
		this.lastLinkRange = void 0;
		this.autoLinkOn = true;

		addExecuteIfFunction( editor );

		// TODO find a way to prevent "Dead" printable keys ( such as "Backquote" )
		// are inserted in placeholders; "compositionstart" and "compositionupdate"
		// could not be captured (neither as editor nor as native dom events);

		// let onCompositionStart = ( evt ) => {
		// 	console.log( evt );
		// }
		//
		// editor.editing.view.document.on( "keydown", ( eventInfo, data ) => {
		// 	if ( !isInsidePlaceholder( editor ) || !data || typeof data != "object" ||
		// 		!data.domEvent || !( data.domEvent instanceof Event ) ||
		// 		!data.domEvent.key || !data.domEvent.code ||
		// 		data.domEvent.key != "Dead" || data.domEvent.code != "Dead" ) return;
		// 	stopEvent( eventInfo, data );
		// }, { priority: MAX } );
		//
		// editor.editing.view.document.on( "compositionstart", ( eventInfo, data ) => {
		// 	console.log( eventInfo );
		// 	console.log( data );
		// }, { priority: MAX } );
		//
		// onDomEvent( editor, "compositionstart", onCompositionStart );

		this._addFocusListeners();
		if ( window.pisasales && typeof window.pisasales == "object" &&
			typeof window.pisasales.isTouch == "boolean" && !!window.pisasales.isTouch ) return;

		this._addTabListeners();
		this._addEnterListeners();
		this._addUndoRedoListeners();
		this._addLinkCommandListeners();
		this._addTypingBeforeTableListeners();
		this._addAutoLinkListener();
		this._addAutoUnlinkListener();
		this._addInsertKeystrokeListener();
		this._addRemoveExternalTooltipsListener();
	}

	_addFocusListeners() {
		const editor = this.editor;
		editor.objects = editor.objects || {};
		editor.objects.focus = {
			isFocused: void 0,
			doFocus: () => {
				if ( editor.objects.focus.isFocused ) return;
				editor.objects.focus._forceFocus();
			},
			_forceFocus: () => {
				editor.editing.view.focus();
			},
			_addExecuteFocus: ( view ) => {
				view.on( "execute", ( eventInfo, data ) => {
					editor.objects.focus.doFocus();
				}, { priority: Number.MAX_SAFE_INTEGER } );
			},
			_blur: () => {
				if ( !Validator.isObjectPath( editor, "editor.ui.view.editable" ) )
					return false;
				const editableElement = editor.ui.view.editable.element;
				if ( !HtmHelper.isHtmlElement( editableElement ) ) return false;
				editableElement.blur();
				window.document.body.focus();
				return true;
			},
			_blurFocus: () => {
				editor.objects.focus._blur();
				editor.objects.focus._forceFocus();
			},
			_addExecuteBlurFocus: ( view ) => {
				view.on( "execute", ( eventInfo, data ) => {
					editor.objects.focus._blurFocus();
				}, { priority: Number.MAX_SAFE_INTEGER } );
			},
			lastTimestamp: Date.now(),
			getDateTime: () => {
				return "[ " + Warner.getCurrentTime() + " ]";
			},
			logStatus: () => {
				console.log( `[ ${ Warner.getCurrentTime() } ] editor is focused:` +
					` ${ editor.objects.focus.isFocused }` );
			},
			getFocusHistory: () => {
				if ( !Validator.isObjectPath( window.pisasales,
						"window.pisasales.cliCbkWdg" ) ) return void 0;
				return pisasales.cliCbkWdg.focusHistory;
			}
		};

		let notifyFocusChange = ( evt ) => {
			if ( this._areBothTargetsInsideEditor( evt ) ) return;
			let gainedFocus = evt.type == "focus";
			let now = Date.now();
			if ( editor.objects.focus.isFocused == gainedFocus &&
				now - editor.objects.focus.lastTimestamp < 2000 ) {
				console.warn( `CK Editor 5 was stopped from trying to fire a ` +
					`${ evt.type } event multiple times in the last 2 seconds.` );
				editor.objects.focus.lastTimestamp = now;
				return;
			}
			editor.objects.focus.isFocused = gainedFocus;
			editor.objects.focus.lastTimestamp = now;
			if ( gainedFocus &&
				Validator.isObjectPath( window.pisasales, "pisasales.cke" ) )
				window.pisasales.cke.lastFocusedEditorId = editor.wdgId;
			editor.fire( EVENT_NAME, { gainedFocus: gainedFocus } );
			// editor.objects.focus.logStatus();
			// console.log( editor.model.document.selection.anchor.path );
			// if ( Validator.isObjectPath( editor, "editor.objects.selection.last.model.anchor.path" ) )
			// 	console.log( editor.objects.selection.last.model.anchor.path );
			// TODO it is unclear if using the editor.fire directly instead of the
			// fire function is a better idea, due to the potential loss of selection;
			// fire( editor, EVENT_NAME, { gainedFocus: gainedFocus } );
		}

		let onEditorFocusChange = ( eventInfo, data ) => {
			if ( !data || typeof data != "object" || !data.domEvent ||
				!( data.domEvent instanceof Event ) ) return;
			notifyFocusChange( data.domEvent );
		}

		editor.editing.view.document.on( 'blur',
			onEditorFocusChange, { priority: MAX } );

		editor.editing.view.document.on( 'focus',
			onEditorFocusChange, { priority: MAX } );

		// onDomEvent( editor, "focus", notifyFocusChange );
		// onDomEvent( editor, "blur", notifyFocusChange );
		delete this._addFocusListeners;
	}

	_addTabListeners() {
		const editor = this.editor;

		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( isInsidePlaceholder( editor ) || !data || typeof data != "object" ||
				!data.domEvent || !( data.domEvent instanceof Event ) ||
				!data.domEvent.key || !data.domEvent.code ||
				data.domEvent.key != "Tab" || data.domEvent.code != "Tab" ) return;
			insertTabSpace( editor, true );
			stopEvent( eventInfo, data );
		}, { priority: MAX } );

		delete this._addTabListeners;
	}

	_addEnterListeners() {
		const editor = this.editor;
		this.lastAttributes = new Map();

		editor.editing.view.document.on( "enter", () => {
			if ( !checkSelection( editor ) ) return;
			this.lastAttributes = editor.objects.selection._copyCurrentAttributes();
			if ( !( this.lastAttributes instanceof Map ) ||
				this.lastAttributes.size < 1 ) return;
			ESCAPED_ATTRIBUTES.forEach( attribute => {
				this.lastAttributes.delete( attribute );
			} );
		}, { priority: MAX } );

		editor.editing.view.document.on( "enter", () => {
			if ( !this.lastAttributes || !checkSelection( editor ) ) return;
			editor.objects.selection._setCurrentAttributes( this.lastAttributes );
			this.lastAttributes = new Map();
		}, { priority: MIN } );

		delete this._addEnterListeners;
	}

	_addUndoRedoListeners() {
		const editor = this.editor;
		let undo = editor.commands._commands.get( "undo" );
		let redo = editor.commands._commands.get( "redo" );
		if ( undo )
			editor.objects.focus._addExecuteFocus( undo );
		if ( redo )
			editor.objects.focus._addExecuteFocus( redo );

		delete this._addUndoRedoListeners;
	}

	_addLinkCommandListeners() {
		const editor = this.editor;
		let link = editor.commands._commands.get( "link" );
		if ( !link || typeof link != "object" ) return;
		link.on( "execute", ( eventInfo, data ) => {
			this.selectionCollapsed = !!editor.model.document.selection.isCollapsed;
		}, { priority: Number.MAX_SAFE_INTEGER } );
		link.on( "execute", ( eventInfo, data ) => {
			let selection = editor.model.document.selection;
			let isBackward = editor.objects.selection.originalBackward;
			let position = isBackward ? selection.anchor : selection.focus;
			if ( !position || typeof position != "object" || !position.nodeBefore ||
				typeof position.nodeBefore != "object" ||
				typeof position.nodeBefore._data != "string" ) return;
			if ( !editor.objects.position.exists( position ) ) {
				console.warn( "Link is set on selection with non-existent focus position." +
					" Selection will not be set after." );
				return;
			};
			let attributes = position.nodeBefore._attrs instanceof Map ?
				editor.data.processor._mapToObject( position.nodeBefore._attrs ) :
				position.isAtEnd ? {} :
				editor.objects.selection._copyCurrentAttributes( false );
			delete attributes.linkHref;
			let fail = false;
			try {
				let batch = editor.model.createBatch();
				editor.model.enqueueChange( batch, writer => {
					if ( this.selectionCollapsed == false && position.isAtEnd == false ) {
						writer.setSelection( position.nodeBefore, "after" );
					} else {
						// let space = writer.createText( " ", {} );
						if ( position.isAtEnd && isBackward ) attributes = {};
						let space = writer.createText( " ", attributes );
						writer.insert( space, position );
						if ( space.previousSibling )
							writer.setSelection( space, "after" );
					}
				} );
			} catch ( error ) {
				// console.warn( "Failed to set selection after link. Error:" );
				// console.warn( error );
				fail = true; // TODO should this be removed, since we are throwind an error anyways?
				error.wdgId = editor.wdgId;
				throw error;
			}
			if ( fail ) {
				link.refresh();
				return;
			}
			editor.model.document.selection._selection.removeAttribute( "linkHref" );
			editor.objects.selection.update();
			this.selectionCollapsed = void 0;
		}, { priority: Number.MIN_SAFE_INTEGER } );

		delete this._addLinkCommandListeners;
	}

	_addTypingBeforeTableListeners() {
		let editor = this.editor;
		let keydownHandler = ( evt ) => {
			if ( !isAtStartBeforeTable( editor ) ) return;
			let table = getFirstElelement( editor );
			editor.model.change( writer => {
				let paragraph = writer.createElement( "paragraph" );
				writer.insert( paragraph, table, "before" );
				writer.setSelection( paragraph, "in" );
			} );
		}
		onDomEvent( this.editor, "keydown", keydownHandler );

		delete this._addTypingBeforeTableListeners;
	}

	/** Stop "Insert" key event from propagating in order to avoid changing
	 * browser's insert mode into overtype/overwrite/typeover;
	 * "Insert" keyCode is 45;
	 */
	_addInsertKeystrokeListener() {
		let editor = this.editor;
		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( !keyIs( data, "Insert" ) ) return;
			stopEvent( eventInfo, data );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		delete this._addInsertKeystrokeListener;
	}

	_addRemoveExternalTooltipsListener() {
		this.editor.once( "ready", () => {
			if ( !Validator.isObjectPath( this.editor, "editor.ui.element" ) ) return;
			const editorElement = this.editor.ui.element;
			if ( !( editorElement instanceof HTMLElement ) ) return;
			editorElement.addEventListener( "mouseenter", ( evt ) => {
				GlobalFunctionExecutor.nullifyCurrentTooltipTarget();
			} );
		} );

		delete this._addRemoveExternalTooltipsListener;
	}

	/**
	 * the methods removes an automatically generated hyperlink in case
	 * this method sets a keydown listener that
	 */
	_addAutoUnlinkListener() {
		let editor = this.editor;
		let dataProcessor = editor.data.processor || editor.getPsaDP();
		editor.editing.view.document.on( 'keydown', ( eventInfo, data ) => {
			if ( !this.autoLinkOn ) return;
			if ( !dataProcessor || typeof dataProcessor != "object" ||
				typeof dataProcessor.isPlain != "function" || dataProcessor.isPlain() ||
				typeof dataProcessor._tryCatch != "function" ) {
				this.lastLinkRange = void 0;
				return;
			}
			if ( !keyIs( data, "Backspace" ) ) {
				this.lastLinkRange = void 0;
				return;
			}
			if ( !this.lastLinkRange || typeof this.lastLinkRange != "object" ) return;
			let removeLink = () => {
				editor.model.change( writer => {
					writer.removeAttribute( "linkHref", this.lastLinkRange );
				} );
			}
			if ( !dataProcessor._tryCatch( removeLink, [] ) ) {
				console.warn( "Could not remove automatically generated hyperlink on " +
					"backspace in CK Editor 5." );
				return;
			}
			stopEvent( eventInfo, data );
			this.lastLinkRange = void 0;
		}, { priority: Number.POSITIVE_INFINITY } );

		delete this._addAutoUnlinkListener;
	}

	_addAutoLinkListener() {
		// TODO add auto-unlink listener on backspace before link?
		// TODO if placeholder
		let editor = this.editor;
		let dataProcessor = editor.data.processor || editor.getPsaDP();
		let linkRegex = new RegExp( URL_REGEX, "gi" );
		let mailRegex = new RegExp( MAIL_ADRESS_REGEX, "gi" );
		editor.editing.view.document.on( 'selectionChangeDone', ( eventInfo, data ) => {
			if ( !this.autoLinkOn ) return;
			this.lastLinkRange = void 0;
		} );
		editor.editing.view.document.on( 'keyup', ( eventInfo, data ) => {
			if ( !this.autoLinkOn ) return;
			this.lastLinkRange = void 0;
			if ( !dataProcessor || typeof dataProcessor != "object" ||
				typeof dataProcessor.isPlain != "function" || dataProcessor.isPlain() ) {
				return;
			}

			let isEnter = keyIs( data, "Enter" );
			if ( !isEnter && !keyIs( data, "Space" ) && !keyIs( data, " " ) &&
				!keyIs( data, "Tab" ) ) return;

			let currentPosition = this._getPosition( isEnter );
			if ( !currentPosition || typeof currentPosition != "object" ) return;

			let linkText = this._getLinkText( currentPosition, !isEnter );
			if ( !linkText || typeof linkText != "string" ) return;
			// prove if there is an e-mail first, since the regular expression for url
			// is more inclusive
			if ( this._handleMatches( linkText, mailRegex, currentPosition, true, !isEnter ) ) return;
			this._handleMatches( linkText, linkRegex, currentPosition, false, !isEnter );
		}, { priority: 0 } );

		delete this._addAutoLinkListener;
	}

	_handleMatches( text, regex, position, addMailto = false, spaceAtEnd = true ) {
		if ( !text || typeof text != "string" || !( regex instanceof RegExp ) )
			return false;
		if ( !position || typeof position != "object" ||
			!( position.path instanceof Array ) ) return false;
		if ( text.indexOf( "." ) < 0 ) return false;
		if ( addMailto && text.indexOf( "@" ) < 0 ) return false;
		// TODO find another way, because this way will determine the TLD in urls
		// that end with .html or .php or .asp etc. wrongly
		let topLevelDomain = text.substring( text.lastIndexOf( "." ) + 1 );
		if ( topLevelDomain.indexOf( "/" ) >= 0 )
			topLevelDomain = topLevelDomain.substring( 0, topLevelDomain.indexOf( "/" ) );
		if ( topLevelDomain.length < 2 ||
			topLevelDomain.replace( /[a-zA_Z]+/g, "" ).length > 0 ) return false;
		if ( addMailto ) {
			let domainName = text.substring( text.lastIndexOf( "@" ) + 1, text.lastIndexOf( "." ) );
			if ( domainName.length < 2 || domainName.replace( /[a-zA_Z0-9\.\-]+/g, "" ).length > 0 ) return false;
		}
		let editor = this.editor;
		let matches = text.match( regex );
		if ( !matches || !( matches instanceof Array ) || matches.length < 1 )
			return false;
		let lastMatch = matches[ matches.length - 1 ];
		if ( !text.endsWith( lastMatch ) ) return false;
		let path = [ ...position.path ];
		path.pop();
		let endIndex = spaceAtEnd ? position.offset - 1 : position.offset;
		let startIndex = endIndex - lastMatch.length;
		let range = editor.objects.position
			._pathsToRange( path.concat( [ startIndex ] ), path.concat( [ endIndex ] ) );
		if ( !range || typeof range != "object" ) return false;
		editor.model.change( writer => {
			writer.setAttribute( "linkHref",
				( addMailto ? "mailto:" + lastMatch : lastMatch.startsWith( "http" ) ||
					lastMatch.startsWith( "ftp://" ) ? lastMatch :
					"http://" + lastMatch ), range );
		} );
		this.lastLinkRange = range;
		return true;
	}

	_getLinkText( currentPosition, spaceAtEnd = true ) {
		if ( !currentPosition || typeof currentPosition != "object" ) return void 0;
		let textNode = currentPosition.textNode;
		if ( !textNode || typeof textNode != "object" || typeof textNode == "string" ||
			typeof textNode._data != "string" ) textNode = currentPosition.nodeBefore;
		if ( !textNode || typeof textNode != "object" || typeof textNode == "string" ||
			typeof textNode._data != "string" ) return void 0;
		if ( textNode._attrs instanceof Map &&
			typeof textNode._attrs.get( "linkHref" ) == "string" ) return void 0;
		return typeof currentPosition.offset == "number" &&
			typeof textNode.startOffset == "number" &&
			currentPosition.offset - textNode.startOffset > 0 &&
			currentPosition.offset - textNode.startOffset <= textNode._data.length ?
			String( textNode._data )
			.substring( 0, ( spaceAtEnd ?
				currentPosition.offset - textNode.startOffset - 1 :
				currentPosition.offset - textNode.startOffset ) ) :
			String( textNode._data );
	}

	_getPosition( isEnter ) {
		const editor = this.editor;
		let selection = editor.model.document.selection;
		if ( !selection.isCollapsed ) return void 0;
		let currentPosition = editor.objects.selection.getLastPosition( true, false );
		if ( !editor.objects.position.exists( currentPosition ) ) {
			currentPosition = editor.objects.selection.getLastPosition( true, true );
			if ( !editor.objects.position.exists( currentPosition ) ) return void 0;
		}
		if ( !isEnter ) return currentPosition;
		currentPosition = this._getPreviousElementPosition( currentPosition );
		return !!currentPosition ? currentPosition : void 0;
	}

	_getPreviousElementPosition( currentPosition ) {
		if ( !currentPosition || typeof currentPosition != "object" ||
			!currentPosition.parent || typeof currentPosition.parent != "object" ||
			( currentPosition.parent.name != "paragraph" &&
				currentPosition.parent.name != "listItem" ) ) return void 0;
		let paragraph = currentPosition.parent.previousSibling;
		if ( !paragraph || typeof paragraph != "object" ||
			( paragraph.name != "paragraph" && paragraph.name != "listItem" ) ||
			typeof paragraph.childCount != "number" || paragraph.childCount < 1 ||
			typeof paragraph.maxOffset != "number" ) return void 0;
		let textNode = paragraph;
		while ( typeof textNode._children == "object" &&
			textNode._children._nodes instanceof Array &&
			textNode._children._nodes.length > 0 && typeof textNode._data != "string" ) {
			textNode = textNode._children._nodes[ textNode._children._nodes.length - 1 ];
		}
		if ( typeof textNode._data != "string" || textNode._data.length < 1 )
			return void 0;
		let path = [ ...currentPosition.path ];
		path.pop();
		if ( path.length < 1 ) return void 0;
		let lastIndex = path[ path.length - 1 ];
		if ( lastIndex < 1 ) return void 0;
		path[ path.length - 1 ] = lastIndex - 1;
		path.push( paragraph.maxOffset );
		const editor = this.editor;
		let endPosition = editor.objects.position._pathToPosition( path );
		return !!endPosition && typeof endPosition == "object" ?
			endPosition : void 0;
	}

	_areBothTargetsInsideEditor( evt ) {
		if ( !evt || !evt.target || !evt.relatedTarget ) return false;
		if ( !( evt.target instanceof HTMLElement ) ) return false;
		if ( !( evt.relatedTarget instanceof HTMLElement ) ) return false;
		if ( typeof evt.target.className != "string" ||
			!this.hasCkClassName( evt.target ) ) return false;
		if ( evt.relatedTarget.parentElement instanceof HTMLElement &&
			evt.relatedTarget.parentElement.parentElement instanceof HTMLElement &&
			typeof evt.relatedTarget.parentElement.parentElement.className == "string" &&
			evt.relatedTarget.parentElement.parentElement.className
			.indexOf( DROPDOWN_CLASSNAME ) >= 0 ) return true;
		if ( typeof evt.relatedTarget.className != "string" ||
			!this.hasCkClassName( evt.relatedTarget ) ) return false;
		if ( evt.target === evt.relatedTarget ) return true;
		let editorElement = evt.path instanceof Array ? evt.path.find( element =>
			element.tagName == "DIV" && element.className.indexOf( CLASNAME ) > 0 ) : null;
		return evt.relatedTarget.contains( evt.target ) ||
			evt.target.contains( evt.relatedTarget ) ||
			( evt.target instanceof HTMLTableCellElement &&
				evt.relatedTarget instanceof HTMLTableCellElement &&
				evt.target.parentElement instanceof HTMLElement &&
				evt.relatedTarget.parentElement instanceof HTMLElement &&
				evt.target.parentElement.parentElement ==
				evt.relatedTarget.parentElement.parentElement ) ||
			( editorElement instanceof HTMLElement &&
				evt.target instanceof HTMLTableCellElement &&
				evt.relatedTarget instanceof HTMLTableCellElement &&
				editorElement.contains( evt.target ) &&
				editorElement.contains( evt.relatedTarget ) ) ||
			evt.target instanceof HTMLInputElement ||
			evt.relatedTarget instanceof HTMLInputElement ||
			evt.target instanceof HTMLAnchorElement ||
			evt.relatedTarget instanceof HTMLAnchorElement;
	}

	hasCkClassName( target ) {
		let hasEditorClassName = false;
		const fullTargetClassName = target.className;
		for ( let className of EDITOR_CLASSNAMES ) {
			if ( fullTargetClassName.indexOf( className ) < 0 ) continue;
			hasEditorClassName = true;
			break;
		}
		return hasEditorClassName;
	}

}

export function insertTabSpace( editor, copyAttributes = false ) {
	const dataProcessor = editor.data.processor || editor.getPsaDP();
	let attributes = copyAttributes ? dataProcessor._mapToObject(
		editor.objects.selection._copyCurrentAttributes() ) : {};
	if ( !dataProcessor.htmlAvailable ) {
		return insertNumberOfSpaces( editor, 4 );
	}
	let paragraph = editor.model.change( writer => {
		let text = writer.createText( TAB_SPACE, attributes );
		let paragraphElement = writer.createElement( "paragraph" );
		writer.insert( text, paragraphElement, 0 );
		return paragraphElement;
	} );
	let htmText = dataProcessor.modelToHtmStr( paragraph, true );
	dataProcessor.insertText( htmText, true, true );
}

function insertNumberOfSpaces( editor, numberOfSpaces = 4 ) {
	if ( !Validator.isObject( editor ) ) return false;
	const dataProcessor = editor.data.processor || editor.getPsaDP();
	if ( !Validator.isObject( dataProcessor ) ) return false;
	if ( !Validator.isPositiveInteger( numberOfSpaces, false ) ) numberOfSpaces = 4;
	let spacesText = "";
	let spacesAdded = 0;
	while ( spacesAdded < numberOfSpaces ) {
		spacesText += " ";
		spacesAdded++;
	}
	dataProcessor.insertText( spacesText, false, true );
	return true;
}

export function addExecuteIfFunction( editor ) {
	editor.executeIf = ( commandName, options = {}, force = true ) => {
		if ( typeof commandName != "string" || commandName.length < 1 ) {
			console.warn( "CKE5 could not execute command with invalid name." );
			return;
		}
		let command = editor.commands.get( commandName );
		if ( !command || typeof command != "object" ) {
			console.warn( `Couldn't execute CKE5 command with the name "${ commandName }". ` +
				`No such command was registered.` );
			return;
		}
		let wasEnabled = command.isEnabled;
		if ( !force && !wasEnabled ) {
			console.warn( `CKE5 command "${ commandName }" is disabled and will not` +
				` be executed.` );
			return;
		}
		if ( !wasEnabled ) {
			// console.warn( `CKE5 command "${ commandName }" was in a disabled state. ` +
			// 	`Command will be enabled so it can be executed, then disabled again.` );
			command.set( 'isEnabled', true );
		}
		if ( !( options instanceof Object ) ) {
			console.warn( `CKE5 command "${ commandName }" will be executed with invalid options, ` +
				`because the passed options are not of prototype Object.` );
			options = {};
		}
		editor.execute( commandName, options );
		if ( !wasEnabled ) command.set( 'isEnabled', false );
	}
}

function isFirstArrayAfter( firstArray, secondArray ) {
	if ( !( firstArray instanceof Array ) || firstArray.length < 1 ||
		!( secondArray instanceof Array ) || secondArray.length < 1 ) return void 0;
	let isAfter = false;
	for ( let i = 0; i < firstArray.length; i++ ) {
		if ( secondArray.length - 1 < i || typeof firstArray[ i ] != "number" ||
			typeof secondArray[ i ] != "number" ) break;
		if ( firstArray[ i ] <= secondArray[ i ] ) continue;
		isAfter = true;
		break;
	}
	return isAfter;
}

function isAtStartBeforeTable( editor ) {
	let ranges = getSelectionRange( editor.model.document.selection );
	if ( !ranges || !ranges.start || !ranges.start.path || ranges.start.path.length != 1 || ranges.start.path[ 0 ] != 0 ||
		!editor.model.document.selection.isCollapsed ) return false;
	let rootElement = getMainRoot( editor );
	if ( !rootElement || !rootElement._children || !rootElement._children._nodes ) return false;
	let first = rootElement._children._nodes[ 0 ];
	return first && first.name == "table";
}

function getFirstElelement( editor ) {
	let rootElement = getMainRoot( editor );
	return rootElement._children._nodes[ 0 ];
}
