import Command from '@ckeditor/ckeditor5-core/src/command';
import { PLACE_TABLE } from './pisatableui';
import { selectionHasTable } from './pisadecreasemargincommand';

export default class PisaPlaceTableCommand extends Command {

	refresh() {
		this.isEnabled = selectionHasTable( this.editor );
	}

	execute( options = {} ) {
		const editor = this.editor;

		const placementValues = editor.config._config[ PLACE_TABLE ].options;
		if ( !( placementValues instanceof Array ) ) return;
		let inlineValue = placementValues.find( value =>
			typeof value == "string" && value.indexOf( "inline" ) >= 0 );
		let blockValue = placementValues.find( value =>
			typeof value == "string" && value.indexOf( "inline" ) < 0 );

		let table = editor.objects.selection.findElement( "table" );
		if ( !table || typeof table != "object" ) return;

		let tableRange = editor.objects.position.getElementFullRange( table );
		if ( !tableRange || typeof tableRange != "object" ) return;

		let changeToInline = void 0;
		if ( !options || typeof options != "object" ||
			( typeof options.inline != "boolean" && typeof options.block != "boolean" ) ||
			( options.inline == false && options.block == false ) ) {
			// if there is no value at all or the "block" value, make it inline
			changeToInline = table.getAttribute( PLACE_TABLE ) != inlineValue;
		} else {
			changeToInline = options.inline == true || options.block == false;
		}

		let newValue = changeToInline ? inlineValue : blockValue;
		if ( !newValue || typeof newValue != "string" || newValue.length < 1 ) return;

		let setPlacementByWriter = ( placementOption ) => {
			editor.model.change( writer => {
				writer.setAttribute( PLACE_TABLE, placementOption, tableRange );
			} );
		};
		if ( !editor.data.processor._tryCatch( setPlacementByWriter, [ newValue ] ) )
			console.warn( `Could not change table display option in CK Editor 5 to` +
				` "${ newValue}"` );
	}

}

export class PisaPlaceTableInlineCommand extends Command {

	refresh() {
		this.isEnabled = selectionHasTable( this.editor );
	}

	execute() {
		const editor = this.editor;
		editor.executeIf( PLACE_TABLE, { inline: true } );
	}

}

export class PisaPlaceTableBlockCommand extends Command {

	refresh() {
		this.isEnabled = selectionHasTable( this.editor );
	}

	execute() {
		const editor = this.editor;
		editor.executeIf( PLACE_TABLE, { block: true } );
	}

}
