import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import PisaSpacingCommand from './pisaspacingcommand';
import { upcastStyleTagAttr, downcastStyleTagAttr } from '../utils';

export const PISASPACING = 'pisaSpacing';
export const SPACING_ATTRIBUTE = 'line-height';

export default class PisaSpacingEditing extends Plugin {

	constructor( editor ) {
		super( editor );
	}

	init() {
		const editor = this.editor;

		// editor.model.schema.extend( '$block', { allowAttributes: PISASPACING } );

		editor.model.schema.extend( 'paragraph', { allowAttributes: PISASPACING } );
		editor.model.schema.extend( 'listItem', { allowAttributes: PISASPACING } );

		downcastStyleTagAttr( editor, 'paragraph', PISASPACING, SPACING_ATTRIBUTE );
		downcastStyleTagAttr( editor, 'listItem', PISASPACING, SPACING_ATTRIBUTE );
		upcastStyleTagAttr( editor, "p", SPACING_ATTRIBUTE, PISASPACING );
		upcastStyleTagAttr( editor, "div", SPACING_ATTRIBUTE, PISASPACING );
		upcastStyleTagAttr( editor, "li", SPACING_ATTRIBUTE, PISASPACING );

		// editor.commands.add( PISASPACING , new PisaSpacingCommand( editor ) );
	}
}
