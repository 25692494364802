import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FontSizeCommand from '@ckeditor/ckeditor5-font/src/fontsize/fontsizecommand';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
// import { addGridToDropdown } from '../pisadropdown/addgridtodropdown';
import { closeDropdownOnBlur, getLiveAttributeValue, addClasses, getLiveNodeStyle, addDropdownCloseListener } from '../utils';
import EmptyView from '../pisadropdown/emptyview';
import { fontSizeIcon /*, plusIcon, minusIcon, defaultIcon */ } from '../icons';
import { addPanelViewHeightHandler } from '../pisafontfamily/pisafontfamilyui';
import Validator from '../pisautils/validator';
import StringHelper from '../pisautils/stringhelper';

import ListView from '@ckeditor/ckeditor5-ui/src/list/listview';
import ListItemView from '@ckeditor/ckeditor5-ui/src/list/listitemview';
import ListSeparatorView from '@ckeditor/ckeditor5-ui/src/list/listseparatorview';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import SwitchButtonView from '@ckeditor/ckeditor5-ui/src/button/switchbuttonview';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

import FontSizeFocusManager from './fontsizefocusmanager';

export const BUTTONS = [ "8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "16pt",
	"18pt", "20pt", "22pt", "24pt", "26pt", "28pt", "32pt", "36pt", "40pt",
	"44pt", "48pt"
];

const FONT_SIZE = 'fontSize';

export default class FontSizeUI extends Plugin {

	init() {
		new FontSizeFocusManager( this );

		const editor = this.editor;

		this.views = { full: [], compact: [] };

		editor.commands.add( FONT_SIZE, new FontSizeCommand( editor ) );
		const command = editor.commands.get( 'fontSize' );

		const dropdownView = createDropdown( editor.locale );
		dropdownView.set( 'panelPosition', 'se' );
		dropdownView.bind( 'isEnabled' ).to( command );
		addClasses( dropdownView, [ "psa-scrollable-dropdown", "psa-font-size" ] );
		closeDropdownOnBlur( dropdownView );
		dropdownView.buttonView.set( {
			withText: true,
			tooltip: editor.objects.tooltips.getT( FONT_SIZE ),
			label: editor.objects.tooltips.getT( FONT_SIZE )
		} );

		if ( Validator.isObject( window.pisasales ) && !window.pisasales.isTouch ) {
			editor.once( "ready", () => {
				this.updateAll( true );
			}, { priority: Number.MIN_SAFE_INTEGER } );
			editor.on( "editorContentSet", () => {
				this.updateAll( true );
			}, { priority: Number.MIN_SAFE_INTEGER } );
		}

		editor.ui.componentFactory.add( FONT_SIZE, locale => {
			let listView = createListView( editor.locale, _prepareListOptions( command ) );
			dropdownView.panelView.children.add( listView );
			dropdownView.actualList = [ ...BUTTONS ];
			listView.items.delegate( 'execute' ).to( dropdownView );

			dropdownView.buttonView.on( "execute", ( eventInfo ) => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
			} );

			this._addFocusKeyupReactions( dropdownView );

			addPanelViewHeightHandler( editor, dropdownView );

			editor.on( "editorContentSet", () => {
				let editableElement = editor.ui && typeof editor.ui == "object" ?
					editor.ui.getEditableElement() : null;
				if ( !( editableElement instanceof HTMLElement ) ) return;
				let liveFontSize = getComputedStyle( editableElement ).fontSize;
				if ( !liveFontSize || typeof liveFontSize != "string" ||
					liveFontSize.length < 1 ) return;
				dropdownView.buttonView.label = roundUpValue( liveFontSize );
			} );

			editor.editing.view.document.on( 'selectionChangeDone', () => {
				dropdownView.fullUpdate();
			} );

			this.listenTo( dropdownView, 'execute', evt => {
				editor.executeIf( evt.source.commandName, { value: evt.source.commandParam } );
				let fontSize = evt.source.commandParam || getLiveNodeStyle().fontSize;
				dropdownView.buttonView.label = roundUpValue( fontSize );
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, FONT_SIZE );

			this.views.full.push( dropdownView );

			dropdownView.on( "render", ( eventInfo ) => {
				this._afterFullDropdownRender( dropdownView );
			}, { priority: Number.MIN_SAFE_INTEGER } );

			return dropdownView;
		} );

		editor.ui.componentFactory.add( "fontSizeCompact", locale => {
			const compactDropdownView = createDropdown( locale );
			compactDropdownView.actualList = [ ...BUTTONS ];
			addClasses( compactDropdownView, [ "psa-scrollable-dropdown", "psa-font-size" ] );
			closeDropdownOnBlur( compactDropdownView );
			compactDropdownView.bind( 'isEnabled' ).to( command );
			compactDropdownView.buttonView.set( {
				icon: fontSizeIcon,
				withText: false,
				tooltip: editor.objects.tooltips.getT( FONT_SIZE )
			} );

			editor.editing.view.document.on( 'selectionChangeDone', () => {
				compactDropdownView.fullUpdate();
			} );

			addDropdownCloseListener( editor, compactDropdownView );
			addPanelViewHeightHandler( editor, compactDropdownView );
			this._addFocusKeyupReactions( compactDropdownView );

			let listView = createListView( editor.locale, _prepareListOptions( command ) );
			compactDropdownView.panelView.children.add( listView );
			listView.items.delegate( 'execute' ).to( compactDropdownView );

			compactDropdownView.buttonView.on( "execute", ( eventInfo ) => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
			} );

			compactDropdownView.on( "execute", evt => {
				editor.executeIf( evt.source.commandName, { value: evt.source.commandParam }, false );
				let fontSize = evt.source.commandParam || getLiveNodeStyle().fontSize;
				dropdownView.buttonView.label = valueToPoints( fontSize );
			} );

			editor.objects.focus._addExecuteFocus( compactDropdownView );
			editor.objects.focus._addExecuteFocus( compactDropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( compactDropdownView.buttonView, "fontSizeCompact" );

			this.views.compact.push( compactDropdownView );

			compactDropdownView.on( "render", ( eventInfo ) => {
				this._afterCompactDropdownRender( compactDropdownView );
			}, { priority: Number.MIN_SAFE_INTEGER } );

			return compactDropdownView;
		} );
	}

}

function _prepareListOptions( command ) {
	let options = BUTTONS.map( getOptionDefinition );
	const itemDefinitions = new Collection();

	for ( const option of options ) {
		const def = {
			type: 'button',
			model: new Model( {
				commandName: 'fontSize',
				commandParam: option.model,
				label: option.title.replace( "pt", "" ),
				class: 'ck-fontsize-option',
				withText: true
			} )
		};

		if ( option.view && option.view.styles && option.view.styles[ 'font-size' ] ) {
			def.model.set( 'labelStyle', `font-size:${ option.view.styles[ 'font-size' ] };` );
		}

		def.model.bind( 'isOn' ).to( command, 'value', value => value === option.model );
		itemDefinitions.add( def );
	}

	return itemDefinitions;
}

function valueToPoints( value ) {
	if ( !value || typeof value != "string" || value.length < 1 ) return "";
	if ( value.indexOf( "pt" ) >= 0 ) return String( Math.round( lengthToNumber( value ) ) );
	if ( value.indexOf( "px" ) >= 0 ) return String( Math.round( lengthToNumber( value ) * 0.75 ) );
	return value;
}

export function roundUpValue( value ) {
	if ( !value || typeof value != "string" || value.length < 1 ) return "";
	if ( value.indexOf( "pt" ) >= 0 ) return String( Math.round( lengthToNumber( value ) ) );
	if ( value.indexOf( "px" ) >= 0 ) return String( Math.round( lengthToNumber( value ) * 0.75 ) );
	return value;
}

function lengthToNumber( value ) {
	return StringHelper.lengthToNumber( value );
	// if ( !value ) return value;
	// return Number( value.toString().replace( /[^\d\,\.]+/, "" )
	// 	.replace( /[\.\,]+/, "." ).replace( /[\.]+/, "." ) );
}

function getOptionDefinition( option ) {
	return {
		title: option,
		model: option,
		view: {
			name: 'span',
			styles: {
				'font-size': `${ option }`
			},
			priority: 5
		}
	};
}

function createListView( locale, items ) {
	const listView = new ListView( locale );

	listView.items.bindTo( items ).using( ( { type, model } ) => {
		if ( type === 'separator' ) return new ListSeparatorView( locale );
		if ( type != 'button' && type != 'switchbutton' ) return;
		const listItemView = new ListItemView( locale );
		let buttonView = type === 'button' ? new ButtonView( locale ) : new SwitchButtonView( locale );
		buttonView.bind( ...Object.keys( model ) ).to( model );
		buttonView.delegate( 'execute' ).to( listItemView );
		buttonView.render();
		buttonView.element.style.minHeight = model.commandParam;
		listItemView.children.add( buttonView );

		return listItemView;
	} );

	return listView;
}
