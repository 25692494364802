
import Command from '@ckeditor/ckeditor5-core/src/command';
import first from '@ckeditor/ckeditor5-utils/src/first';
import { PISASPACING } from './pisaspacingediting';
import { BUTTONS } from './pisaspacingui';

export default class PisaSpacingCommand extends Command {

  constructor( editor ) {
		super( editor, PISASPACING );
	}

	refresh() {
		const firstBlock = first( this.editor.model.document.selection.getSelectedBlocks() );
		this.isEnabled = !!firstBlock && this._canHaveSpacing( firstBlock );
		this.value = this.isEnabled && firstBlock.hasAttribute( PISASPACING )
    ? firstBlock.getAttribute( PISASPACING ) : BUTTONS[0];
	}

	execute( options = {} ) {
		const editor = this.editor;
    let selection = ( options.source == "range" ) ? editor.lastSelection : editor.model.document.selection;
    options.value = validateValue( options.value );
		editor.model.change( writer => {
      Array.from( selection.getSelectedBlocks() )
      .filter( block => this._canHaveSpacing( block ) )
      .forEach( block => {
        writer.setAttribute( PISASPACING, options.value, block );
      } );
		} );
	}

	_canHaveSpacing( block ) {
		return this.editor.model.schema.checkAttribute( block, PISASPACING );
	}
}

function validateValue( value ) {
  if ( value.indexOf( "%" ) >= 0 ) {
    value = value.replace( /[^\d\.\,]+/g, "" ).replace(/\,+/g, ".");
    value = value ? String( 0.01 * Number( value ) ) : value;
    return value;
  }
  value == "normal" || value == "initial" ? value = "1" : void 0;
  return value.replace( /[^\d\.\,]+/g, "" );
}
