import BasicWatchdog from './basicwatchdog';
import { throttle } from 'lodash-es';
import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';
import HtmHelper from '../pisautils/htmhelper';
import EventHelper from '../pisautils/eventhelper';
import WatchdogHelpers from './watchdoghelpers';
import WatchdogContentManager from './watchdogcontentmanager';
import WatchdogInstancesManager from './watchdoginstancesmanager';
import WatchdogUiManager from './watchdoguimanager';
import WatchdogListeners from './watchdoglisteners';

const LOG_CHANGES = true;
const ADD_TIMESTAMP = true;
const ADD_COUNTER = true;
const MAX_RESTART_NUMBER = 2;

export const PISA_LOCK_WARNING = "pisaLockWarning";
export const PISA_RSTARTED_WARNING = "pisaRestartedWarning";
export const PISA_RESTART_MESSAGE = "pisaRestartMessage";

export default class PisaEditorWatchdog extends BasicWatchdog {

	get className() {
		return "PisaEditorWatchdog";
	}

	constructor( args, onReady, Editor, watchdogConfig = {} ) {
		super( args, onReady, Editor, watchdogConfig );
		new WatchdogHelpers( this );
		new WatchdogContentManager( this );
		new WatchdogUiManager( this );
		new WatchdogInstancesManager( this );
		new WatchdogListeners( this );
	}

	/**
	 * @override
	 */
	_startErrorHandling() {
		super._startErrorHandling();
		this._addCaptureErrorHandling();
		this._setWindowOnErrorFunction();
		this._changeRwtErrorHandling();
		this._changeRwtMessageProcessorErrorHandling();
	}

	/**
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/watchdog~_handleError}
	 * @override
	 */
	_handleError( error, evt ) {
		this._updateWidgetId();

		this._setErrorCheckedByList( error );

		// if an error has an widget ID it means that it was captured before, the
		// widget ID was added and it was thrown again; the widget id was added in
		// order to ensure that this watchdog instance captures the error, not
		// another one
		const errorHasSameWidgetId = Validator.isString( error.wdgId ) &&
			error.wdgId === this.wdgId;

		const editableElement = Validator.isObjectPath( this, "this.editor.ui" ) &&
			Validator.isFunction( this.editor.ui.getEditableElement ) ?
			this.editor.ui.getEditableElement() : void 0;

		const activeElement = window.document.activeElement;

		const isEditableElementFocused = HtmHelper.isElement( editableElement ) &&
			editableElement.classList.contains( "ck-focused" );

		// sometimes the errors may come from input elements inside balloons or
		// inside toolbar dropdowns, in which case the active element will be the
		// input element
		const isActiveElementInsideEditor = HtmHelper.isElement( activeElement ) &&
			( activeElement == editableElement ||
				activeElement.classList.contains( "ck-input" ) );

		let isErrorComingFromThisEditor = errorHasSameWidgetId ||
			this._isErrorComingFromThisItem( error ) || this.isOnlyEditorInstance;

		const isLastWatchdogToDoTheJob = Validator.isObject( error ) &&
			Validator.isSet( error.alreadyCheckedBy ) &&
			error.alreadyCheckedBy.size >= this.watchdogTotal - 1;

		const isCkError = ( Validator.isFunction( error.is ) && error.is( "CKEditorError" ) ) ||
			( error instanceof ErrorEvent && Validator.isString( error.message ) &&
				error.message.indexOf( "CKEditorError" ) >= 0 );

		if ( isCkError && !isErrorComingFromThisEditor &&
			isEditableElementFocused && isActiveElementInsideEditor )
			isErrorComingFromThisEditor = true;

		if ( !isErrorComingFromThisEditor && isLastWatchdogToDoTheJob )
			isErrorComingFromThisEditor = true;

		const lastFocusedWatchdog = this.lastFocusedWatchdog;

		const shouldAnotherWatchdogHandleThisError = error.wdgId !== this.wdgId &&
			Validator.isObject( this.getWatchdogByWdgId( error.wdgId ) ) &&
			Validator.isSet( error.alreadyCheckedBy ) &&
			!error.alreadyCheckedBy.has( error.wdgId );

		if ( !isErrorComingFromThisEditor && lastFocusedWatchdog === this &&
			lastFocusedWatchdog.wdgId === this.wdgId &&
			!shouldAnotherWatchdogHandleThisError )
			isErrorComingFromThisEditor = true;

		if ( Validator.isString( this.wdgId ) && Validator.isObject( error ) &&
			Validator.isSet( error.alreadyCheckedBy ) )
			error.alreadyCheckedBy.add( this.wdgId );

		if ( ( isCkError && !isErrorComingFromThisEditor ) ||
			shouldAnotherWatchdogHandleThisError )
			return this.log( `The editor watchdog for the editor with the widget` +
				` ID "${ this.wdgId }" detected a CKEditorError that belongs to` +
				` another editor and will not react to it, handle it or stop it.` );

		if ( ( !isCkError || !Validator.isString( error.wdgId ) ) &&
			!isErrorComingFromThisEditor ) {
			if ( Validator.isObject( lastFocusedWatchdog ) &&
				// if the current editor is not dirty, let the focused editor's watchdog
				// handle the error; if the current editor is dirty, only let the
				// focused editor's watchdog handle the error if the focused editor is
				// also dirty
				( !this.isDirty || lastFocusedWatchdog.isDirty ) &&
				this.letAnotherWatchdogHandleTheError( lastFocusedWatchdog, error, evt ) )
				return;
			if ( !this.isDirty &&
				this.letAnotherWatchdogHandleTheError( this.dirtyEditorWatchdog, error, evt ) )
				return;
		}

		EventHelper.stop( evt );

		let shouldReact = this.restartCounter < MAX_RESTART_NUMBER &&
			( !Validator.isArray( this.crashes ) || this.crashes.length < 10 );

		if ( !shouldReact ) this._setToPermanentlyCrashed();

		if ( shouldReact && this.stopHandling == true ) shouldReact = false;

		this.error( error, `Editor watchdog for the editor with the widget ID` +
			` "${ this.wdgId }" detected an error ${ shouldReact ? "and" : "but" }` +
			` will${ shouldReact ? "" : " not" } react to it` +
			` ${ shouldReact ? "and" : "or" } handle it. The error event will be` +
			` stopped from further propagation.` );

		if ( !shouldReact ) return;

		if ( Validator.isObject( evt ) ) this.crashes.push( {
			message: error.message,
			stack: error.stack,
			filename: evt.filename,
			lineno: evt.lineno,
			colno: evt.colno,
			date: this._now()
		} );

		this.state = 'crashed';
		this._fire( 'stateChange' );
		this._restart();
	}

	_setErrorCheckedByList( error ) {
		if ( !Validator.isObject( error ) ||
			Validator.isSet( error.alreadyCheckedBy ) ) return error;
		error.alreadyCheckedBy = new Set();
		return error;
	}

	/**
	 * @fires restart
	 * @see {@link module:@ckeditor/ckeditor5-watchdog/src/editorwatchdog~_restart}
	 * @override
	 * @returns {Promise}
	 */
	_restart() {
		this._editingEnabled = false;
		this.closeAllBalloons();
		this._rememberPlainTextState();
		this.removeToolbarBlocker();
		this.removeTopRightIcon();
		this.hideMessageBox();
		this.showMessageBox();
		this._updateWidgetId();
		this.log( `The editor watchdog for the editor with the widget ID` +
			` "${ this.wdgId }" is being restarted for the` +
			` ${ ++this.restartCounter } time.` );
		this.stopHandling = true;
		this._updateLastContent();
		this._notifyAboutContentChanges();
		// this._removeEditorData();
		this._destroyPsaExt();
		this._destroyDocumentView();
		this._removeSourceElementReference();
		this.destroy();
		this.onready( false, void 0, this.args );
		return Promise.resolve()
			.then( () => {
				if ( typeof this._elementOrData === 'string' )
					return this.create( this._data, this._config, this._config.context );

				const updatedConfig = Object.assign( {}, this._config, {
					initialData: this._data
				} );
				return this.create( this._elementOrData, updatedConfig, updatedConfig.context );
			} )
			.then( () => {
				this._fire( 'restart' );
			} )
			.then( () => {
				this.stopHandling = false;
				this.onready( true, this, { nfo: this.args, blockSetCtt: true } );
			} )
			.catch( err => {
				this.error( err, `An error occured after the watchdog restarted` +
					` the editor with the widget ID "${ this.wdgId }", before the old` +
					` content was restored.` );
				this._setToPermanentlyCrashed();
			} )
			.then( () => {
				this._restorePlainTextState();
			} )
			.catch( err => {
				this.error( err, `An error occured after or during plain text/html` +
					` state restoration in context of the restart of the editor with` +
					` the widget ID "${ this.wdgId }" performed by the watchdog.` );
				this._setToPermanentlyCrashed();
			} )
			.then( () => {
				this._setContentAfterRefresh();
			} )
			.catch( err => {
				this.error( err, `An error occured after or during content restoration` +
					` in context of the restart of the editor with the widget ID` +
					` "${ this.wdgId }" performed by the watchdog.` );
				this._setToPermanentlyCrashed();
			} )
			.then( () => {
				this.addMessageBoxKeyBlocker();
			} )
			.catch( err => {
				this.error( err, `An error occured when trying to block editing` +
					` while the message box is shown during the restart of the editor` +
					` with the widget ID "${ this.wdgId }" performed by the watchdog.` );
			} )
			.then( () => {
				let self = this;
				setTimeout( () => {
					self.hideMessageBox();
					if ( this.state != 'crashedPermanently' ) {
						self._editingEnabled = true;
						self.showTopRightIcon();
					} else {
						self._editingEnabled = false;
					}
				}, 4000 );
				if ( this.state != 'crashedPermanently' )
					this.log( `The watchdog for the editor with the widget ID` +
						` "${ this.wdgId }" successfully restarted the editor for the` +
						` ${ this.restartCounter } time.` );
			} );
	}

	_setToPermanentlyCrashed() {
		// TODO stop image drop events (internal)
		// TODO disable disable buttons
		// this.editor.executeIf( "pisaDisableButtons" );
		this._editingEnabled = false;
		this.stopHandling = true;
		this._updateLastContent();
		this._notifyAboutContentChanges();
		this._destroyDocumentView();
		Validator.removeSetterAndSet( this.editor, "isReadOnly", true );
		Validator.removeSetterAndSet( this, "state", "crashedPermanently" );
		Validator.removeSetterAndSet( this.editor, "state", "crashedPermanently" );
		this._fire( 'stateChange' );
		this.hideAndFreezeBalloons();
		this._disableContentEditing();
		this.removeTopRightIcon();
		this.showTopRightIcon( true );
		if ( !this.hideToolbar() ) {
			this.showToolbarBlocker();
			this.addToolbarRefreshListener();
		}
		this.addKeystrokeListener();
		this.addLinkClickListener();
		this.log( `The watchdog for the editor with the widget ID` +
			` "${ this.wdgId }" detected more than ${ this.crashes.length }` +
			` crashes. The editor will not be restarted anymore and will change` +
			` its state to "${ ( Validator.isObject( this.editor ) ?
				this.editor.state : "???" )}". The watchdog state will be` +
			` changed to "${ this.state }".` );
	}

	_updateWidgetId() {
		if ( !Validator.isObject( this.editor ) ||
			!Validator.isString( this.editor.wdgId ) ) return;
		this.wdgId = this.editor.wdgId;
	}

	focus() {
		if ( !Validator.isObjectPath( this, "this.editor.editing.view" ) ) return false;
		if ( !Validator.isFunction( this.editor.editing.view.focus ) ) return false;
		this.editor.editing.view.focus();
		return true;
	}

	blur() {
		const editableElement = this.editableElement;
		if ( !( editableElement instanceof HTMLElement ) ||
			!Validator.isFunction( editableElement.blur ) ) return false;
		editableElement.blur();
		return true;
	}

	restoreSelection() {
		if ( !Validator.isObjectPath( this, "this.editor.objects.selection" ) ) return false;
		if ( !Validator.isFunction( this.editor.objects.selection.restoreSelection ) ) return false;
		this.editor.objects.selection.restoreSelection();
		return true;
	}

	_copySelected() {
		window.document.execCommand( "copy" );
	}

	copyAll() {
		this._selectEditorContent();
		this._copySelected();
	}

	_addWidgetIdTemporarySetter( editor ) {
		Validator.addLazySetter( {
			parentObject: editor,
			propertyName: "wdgId",
			callbackBeforeSetting: ( newWidgetIdValue ) => {
				this.wdgId = newWidgetIdValue;
				Warner.output( {
					color: "#6c7a89",
					addTimestamp: true,
					text: `The Editor inside the container with the ID` +
						` "${ this.args.container.id }" will be assigned the widget` +
						` ID "${ newWidgetIdValue }" (object property "wdgId").` +
						` Further widget ID assignments for this editor (after the` +
						` current/present/this one) will not be documented.`
				} );
			}
		} );
	}

	_fakeError() {
		this.editor.model.change( writer => {
			writer.createPositionAfter( this.editor.model.document.getRoot() );
		} );
	}

	log( logText, returnValue, trace = true ) {
		return Warner.outputIf( LOG_CHANGES, returnValue, {
			trace: trace,
			collapse: true,
			text: logText,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: 4,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

	error( error, logText, returnValue, trace = true ) {
		return Warner.outputIf( LOG_CHANGES, returnValue, {
			error: true,
			errorText: error,
			collapse: true,
			text: logText,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: 4,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

}
