import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FontColorCommand from './pisacolorcommand';
import EmptyView from '../pisadropdown/emptyview';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { addColorGrid } from '../pisadropdown/addcolorgrid';
import {
	getLiveAttributeValue,
	addClasses,
	defineButton,
	setColorInput,
	setButton,
	createHexInput,
	bindInputToCommand,
	addViewChildren,
	closeDropdownOnBlur,
	addDropdownCloseListener
} from '../utils';
import { fontColorIcon } from '../icons';

const FONT_COLOR = "fontColor";

export default class FontColorUI extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( FONT_COLOR, new FontColorCommand( editor ) );
		editor.ui.componentFactory.add( FONT_COLOR, locale => {
			return createColorDropdown( editor, FONT_COLOR, fontColorIcon,
				editor.objects.tooltips.getT( FONT_COLOR ) );
		} );
	}

}

export function createColorDropdown( editor, commandName, icon, label ) {
	const dropdownView = createDropdown( editor.locale );
	defineButton( dropdownView.buttonView, icon, label );
	const command = editor.commands.get( commandName );
	dropdownView.bind( 'isEnabled' ).to( command );
	dropdownView.buttonView.iconView.fillColor = "#c0c0c0";
	editor.objects.focus._addExecuteFocus( dropdownView );
	editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
	GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, commandName );

	let lineGridView = new EmptyView( [ 'pisa-grid-line', 'pisa-no-padding-bottom' ] );
	let colorInput = generateColorInput( editor, commandName );
	let autoButton = generateAutoButton( editor, commandName );
	let textInput = generateTextInput( editor, commandName, [ "pisa-hex", "pisa-place-center" ] );
	addInputListeners( dropdownView, colorInput, autoButton, textInput );
	addViewChildren( lineGridView, { 0: colorInput, 1: autoButton, 2: textInput } );

	addViewChildren( dropdownView.panelView, { 0: lineGridView } );
	addColorGrid( dropdownView );
	closeDropdownOnBlur( dropdownView );
	addDropdownCloseListener( editor, dropdownView );

	editor.editing.view.document.on( 'selectionChangeDone', () => {
		updateFillColor( editor, dropdownView.buttonView.iconView,
			commandName, "#c0c0c0", colorInput, autoButton, textInput );
		highlightActiveColor( dropdownView.panelView.children._items[ 1 ], textInput.element.value );
	} );

	dropdownView.on( "execute", ( evt, data ) => {
		autoButton.isOn = false;
		dropdownView.buttonView.iconView.fillColor = data.color;
		colorInput.element.value = data.color;
		textInput.element.value = data.color;
		editor.executeIf( commandName, { value: data.color } );
		editor.objects.focus.doFocus();
	} );

	editor.objects.focus._addExecuteFocus( dropdownView );

	return dropdownView;
}

export function generateColorInput( editor, commandName ) {
	let colorInput = setColorInput( editor, [], commandName, {}, "value" );
	addClasses( colorInput, [ 'pisa-place-center' ] );
	editor.objects.focus._addExecuteFocus( colorInput );
	return colorInput;
}

function generateAutoButton( editor, commandName ) {
	let autoButton = setButton( "", "Automatic", commandName, { value: undefined }, editor );
	addClasses( autoButton, [ 'pisa-place-center' ] );
	editor.objects.focus._addExecuteFocus( autoButton );
	return autoButton;
}

function generateTextInput( editor, commandName, cssClasses = [] ) {
	let textInput = createHexInput( true, editor, cssClasses );
	bindInputToCommand( textInput, commandName, {}, "value", editor );
	editor.objects.focus._addExecuteFocus( textInput );
	return textInput;
}

function addInputListeners( dropdownView, colorInput, autoButton, textInput ) {
	colorInput.on( "input", () => {
		autoButton.isOn = false;
		dropdownView.buttonView.iconView.fillColor = colorInput.element.value;
		textInput.element.value = colorInput.element.value;
	} );

	textInput.on( "input", () => {
		autoButton.isOn = false;
		dropdownView.buttonView.iconView.fillColor = textInput.element.value;
		colorInput.element.value = textInput.element.value;
	} );

	autoButton.on( "execute", () => {
		autoButton.isOn = true;
		dropdownView.buttonView.iconView.fillColor = "#c0c0c0";
		textInput.element.value = "";
		colorInput.element.value = "#111111";
	} );
}

export function highlightActiveColor( colorGrid, activeColor ) {
	colorGrid.items._items.forEach( colorDiv => {
		let button = colorDiv.items._items[ 0 ];
		button.isOn = button.color == activeColor;
	} );
}

export function updateFillColor( editor, iconView, attributeName,
	defaultFill = "inherit", colorInput = "", autoButton = "", textInput = "" ) {
	let targetAttribute = getLiveAttributeValue( editor, attributeName );
	if ( targetAttribute ) {
		iconView.fillColor = targetAttribute;
		if ( autoButton ) autoButton.isOn = false;
		if ( colorInput ) colorInput.element.value = targetAttribute;
		if ( textInput ) {
			textInput.element.value = targetAttribute;
			textInput.element.style.color = targetAttribute;
		}
	} else {
		iconView.fillColor = defaultFill;
		if ( autoButton ) autoButton.isOn = true;
		if ( colorInput ) colorInput.element.value = "#111111";
		if ( textInput ) {
			textInput.element.value = "";
			textInput.element.style.color = "#111111";
		}
	}
	return targetAttribute;
}
