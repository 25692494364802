import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LinkFormBalloon from './linkformballoon';
import LinkActionsBalloon from './linkactionsballoon';
import { LOG_COLOR } from './linkballoon';
import PluginUtils from '../../pisautils/pluginutils';
import { onDomEvent } from '../../utils';
import Validator from '../../pisautils/validator';
import GlobalFunctionExecutor from '../../pisautils/globalfunctionexecutor';

export default class PisaLinkBalloons extends Plugin {

	constructor( editor ) {
		super( editor );
		new PluginUtils( {
			hostPlugin: this,
			logOutputColor: LOG_COLOR,
			errorOutputColor: LOG_COLOR
		} );
		this.lastHref = void 0;
	}

	init() {
		const editor = this.editor;
		this.actionsBalloon = new LinkActionsBalloon( editor );
		this.actionsBalloon.init();
		this.formBalloon = new LinkFormBalloon( editor );
		this.formBalloon.init();
		if ( !Validator.isObject( editor.objects ) )
			editor.objects = {};
		editor.objects.linkBalloon = this;
		this._addClickATagFunctionality();
		this.addListeners();
	}

	freeze() {
		this.actionsBalloon.freeze();
		this.formBalloon.freeze();
	}

	revive() {
		this.actionsBalloon.revive();
		this.formBalloon.revive();
	}

	get isVisible() {
		return !!this.visibleBalloon
	}

	get visibleBalloon() {
		if ( Validator.isObject( this.actionsBalloon ) &&
			this.actionsBalloon.isVisible ) return this.actionsBalloon;
		if ( Validator.isObject( this.formBalloon ) &&
			this.formBalloon.isVisible ) return this.formBalloon;
		return void 0;
	}

	get linkElementId() {
		const visibleBalloon = this.visibleBalloon;
		if ( !visibleBalloon ) return void 0;
		return visibleBalloon.linkElementId;
	}

	get isInsideSameLink() {
		const balloonLinkId = this.linkElementId;
		if ( !Validator.isString( balloonLinkId ) ) return false;
		const selectedLinkElement = this.selectedLinkElement;
		if ( !Validator.isObject( selectedLinkElement ) ) return false;
		return selectedLinkElement.linkElementId == balloonLinkId;
	}

	get isInsideLink() {
		if ( Validator.isObject( this.actionsBalloon ) &&
			"isInsideLink" in this.actionsBalloon )
			return this.actionsBalloon.isInsideLink;
		if ( Validator.isObject( this.formBalloon ) &&
			"isInsideLink" in this.formBalloon )
			return this.formBalloon.isInsideLink;
		const selectedLinkElement = this.selectedLinkElement;
		return Validator.isObject( selectedLinkElement );
	}

	get selectedLinkElement() {
		if ( Validator.isObject( this.actionsBalloon ) &&
			"selectedLinkElement" in this.actionsBalloon )
			return this.actionsBalloon.selectedLinkElement;
		if ( Validator.isObject( this.formBalloon ) &&
			"selectedLinkElement" in this.formBalloon )
			return this.formBalloon.selectedLinkElement;
		return void 0;
	}

	get isPisaObjektLink() {
		const selectedLinkElement = this.selectedLinkElement;
		return this.isViewObjectLinkElement( this.selectedLinkElement );
	}

	isViewObjectLinkElement( viewLinkElement ) {
		if ( !Validator.isObject( viewLinkElement ) ||
			!Validator.isMap( viewLinkElement._attrs ) ) return false;
		return this.isObjectLink( viewLinkElement._attrs.get( "href" ) );
	}

	isObjectLink( linkHref ) {
		if ( !Validator.isString( linkHref ) ) return false;
		if ( Validator.isObject( window.pisasales ) &&
			Validator.isFunction( window.pisasales.isPsaObjLink ) )
			return window.pisasales.isPsaObjLink( linkHref );
		return linkHref.includes( '/psaobj' );
	}

	addListeners() {
		const editor = this.editor;

		// TODO the click outside handler is quicker than this click event
		editor.editing.view.document.on( "click", ( eventInfo, data ) => {
			const link = this.selectedLinkElement;
			if ( !Validator.isObject( link ) ) return this.hide();
			const imageBalloon =
				Validator.isObjectPath( editor, "editor.balloons.balloons.image" ) ?
				editor.balloons.balloons.image : void 0;
			if ( imageBalloon && imageBalloon.isVisible ) return this.hide();
			this.toggle( link );
		}, { priority: this.priority.fifthMax } );

		editor.on( "focusChanged", ( eventInfo, data ) => {
			if ( !Validator.isObject( data ) || !!data.gainedFocus ) return;
			this.hide();
		} );

		this.listenTo( this.actionsBalloon.actionsView, "edit", ( eventInfo, data ) => {
			this.formBalloon.linkElementId = this.actionsBalloon.linkElementId;
			this.formBalloon.show();
		} );

		this.listenTo( this.actionsBalloon.actionsView, "unlink", ( eventInfo, data ) => {
			editor.executeIf( "unlink" );
			this.hide();
		} );

		// Execute link command after clicking the "Save" button.
		this.listenTo( this.formBalloon.formView, 'submit', ( eventInfo, data ) => {
			editor.execute( 'link',
				this.formBalloon.formView.urlInputView.inputView.element.value,
				this.formBalloon.formView.getDecoratorSwitchesState() );
			this.formBalloon.nullifyLinkId();
			this.formBalloon.hide();
			this.actionsBalloon.displayOn( this.selectedLinkElement );
		} );

		// Hide the panel after clicking the "Cancel" button.
		this.listenTo( this.formBalloon.formView, 'cancel', ( eventInfo, data ) => {
			this.formBalloon.hide();
		} );

		this.actionsBalloon.panel
			.once( "linkActionsBalloonShown", ( eventInfo, data ) => {
				this.actionsBalloon.actionsView.previewButtonView.element
					.addEventListener( "click", this.clickATag );
			}, { priority: this.priority.fourthMax } );

		this.actionsBalloon.panel
			.on( "linkActionsBalloonShown", ( eventInfo, data ) => {
				const linkElement = this.selectedLinkElement;
				const isPisaObjektLink = this.isViewObjectLinkElement( linkElement );
				this.actionsBalloon.actionsView.editButtonView.isEnabled = !isPisaObjektLink;
				this.actionsBalloon.actionsView.editButtonView.isVisible = !isPisaObjektLink;
				this.lastHref = linkElement._attrs.get( "href" );

				this.actionsBalloon.actionsView.previewButtonView.element.onclick =
					isPisaObjektLink || window.pisasales.isTouch ?
					() => { return false; } : "";

				// this.actionsBalloon.actionsView.previewButtonView.element
				// .addEventListener( "click", this.clickATag );

				GlobalFunctionExecutor.closeExternalMenus();
				GlobalFunctionExecutor.nullifyCurrentTooltipTarget();
				this.formBalloon.hide();
				this.hideOtherBalloons();
			}, { priority: this.priority.fifthMax } );

		this.formBalloon.panel
			.on( "change:isVisible", ( evt, name, newValue, oldValue ) => {
				if ( oldValue || !newValue ) return;
				const linkElement = this.selectedLinkElement;
				const isPisaObjektLink = this.isViewObjectLinkElement( linkElement );
				if ( isPisaObjektLink ) {
					this.formBalloon.nullifyLinkId();
					this.formBalloon.hide();
					this.actionsBalloon.setLinkId( linkElement );
					this.actionsBalloon.linkElementId = linkElement.linkElementId;
					return this.actionsBalloon.show();
				}
				GlobalFunctionExecutor.closeExternalMenus();
				GlobalFunctionExecutor.nullifyCurrentTooltipTarget();
				this.actionsBalloon.hide();
				this.hideOtherBalloons();
			}, { priority: this.priority.fifthMax } );

		const hide = () => {
			this.hide();
		}

		editor.once( "ready", () => {
			editor.on( "editorContentSet", ( eventInfo, data ) => {
				this.hide();
			}, { priority: this.priority.fifthMin } );
			const toolbarElement =
				Validator.isObjectPath( editor, "editor.objects.toolbar" ) ?
				editor.objects.toolbar.toolbarElement : void 0;
			if ( !( toolbarElement instanceof HTMLElement ) ) return;
			toolbarElement.addEventListener( "mouseup", hide );
		}, { priority: Number.MIN_SAFE_INTEGER } );

		onDomEvent( editor, "scroll", hide );
		onDomEvent( editor, "contextmenu", hide );
		onDomEvent( editor, "keydown", hide );
		onDomEvent( editor, "wheel", hide );

		// editor.commands.get( "pisaPlainText" ).on( "execute", () => {
		// 	this.hide();
		// } );

		delete this.addListeners;
	}

	_addClickATagFunctionality() {
		this.clickATag = ( evt ) => {
			this.hide();
			if ( Validator.isObject( window.pisasales ) && window.pisasales.isTouch )
				return this.editor.executeIf( "pisaFire", {
					event: this.isObjectLink( this.lastHref ) ?
						"pisaObjectLink" : "pisaEditorLink",
					options: { href: String( this.lastHref ) }
				} );

			if ( !this.isObjectLink( this.lastHref ) ) return;
			evt.preventDefault();
			evt.stopPropagation();
			if ( Validator.isObject( window.pisasales ) &&
				Validator.isFunction( window.pisasales.psaObjClicked ) )
				window.pisasales.psaObjClicked( this.editor.wdgId, this.lastHref );
		};

		delete this._addClickATagFunctionality;
	}

	hide() {
		this.formBalloon.nullifyLinkId();
		this.actionsBalloon.nullifyLinkId();
		this.actionsBalloon.hide();
		this.formBalloon.hide();
	}

	show() {
		const linkElement = this.selectedLinkElement;
		return this.showOn( linkElement );
	}

	showOn( linkElement ) {
		if ( Validator.isObject( linkElement ) )
			return this.actionsBalloon.displayOn( linkElement );
		this.formBalloon.nullifyLinkId();
		return this.formBalloon.show();
	}

	toggle( linkElement ) {
		if ( !Validator.isObject( linkElement ) )
			return this._log( `Could not toggle between the two link balloons on an` +
				` invalid link element.`, false );
		const currentLinkId = linkElement.linkElementId;
		if ( this.actionsBalloon.panel.isVisible ) {
			const currentFormId = this.actionsBalloon.linkElementId;
			if ( currentFormId != currentLinkId ||
				!Validator.isString( currentLinkId ) )
				return this.actionsBalloon.displayOn( linkElement );
			this.actionsBalloon.hide();
			return this.formBalloon.displayOn( linkElement );
		}
		this.formBalloon.hide();
		return this.actionsBalloon.displayOn( linkElement );
	}

	hideOtherBalloons() {
		if ( !Validator.isObjectPath( this.editor, "editor.balloons" ) ) return false;
		if ( !Validator.isFunction( this.editor.balloons.hideAll ) ) return false;
		this.editor.balloons.hideAll();
		return true;
	}

}
