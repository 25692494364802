import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import PisaMoveImageCommand from './pisaimagemovecommand';
import { MOVE_IMAGE } from './pisaimagemovecommand';
import { createButton } from '../utils';
import { moveImageIcon } from '../icons';

export default class PisaImageMoveUI extends Plugin {

	init() {
		const editor = this.editor;
		const command = editor.commands.get( "pisaInsertImage" );

		editor.commands.add( MOVE_IMAGE, new PisaMoveImageCommand( editor ) );

		editor.ui.componentFactory.add( MOVE_IMAGE, locale => {
			let moveImageButton = createButton( moveImageIcon,
				editor.objects.tooltips.getT( MOVE_IMAGE ), editor.locale );
			if ( !!command && typeof command == "object" )
				moveImageButton.bind( 'isEnabled' ).to( command );
			moveImageButton.on( "execute", () => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
				( editor.objects.images && !editor.objects.images.lockCopy ) || !editor.objects.images ?
					editor.executeIf( MOVE_IMAGE, { selection: editor.lastSelection, lastSelection: true }, false ) : void 0;
				// FIXME the lockCopy property avoids inserting more than one image after
				// clicking inside the editor, however it inserts the first image the move
				// button was clicked for in case the user clicks on different images and
				// move buttons without clicking inside the editor
				editor.objects.images ? editor.objects.images.lockCopy = true : void 0;
			} );
			editor.objects.focus._addExecuteFocus( moveImageButton );
			return moveImageButton;
		} );
	}
}
