import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';

export default class PlaceholderPositionHelper extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		Object.defineProperty( hostPlugin, "currentSelection", {
			get: () => {
				if ( Validator.isObjectPath( hostPlugin,
						"hostPlugin.editor.model.document.selection" ) )
					return hostPlugin.editor.model.document.selection;
				if ( Validator.isObjectPath( hostPlugin,
						"hostPlugin.objectsSelection.lastInModel" ) )
					return hostPlugin.objectsSelection.lastInModel;
				return void 0;
			},
			set: ( newValue ) => {
				hostPlugin._logAsUnmodifiable( "currentSelection", newValue );
			}
		} );

		Object.defineProperty( hostPlugin, "fullSelectionInPlaceholder", {
			get: () => {
				let selection = hostPlugin.currentSelection;
				if ( !Validator.isObject( selection ) ) return void 0;
				return hostPlugin.isFullSelectionInPlaceholder( selection );
			},
			set: ( newValue ) => {
				hostPlugin._logAsUnmodifiable( "fullSelectionInPlaceholder", newValue );
			}
		} );

		Object.defineProperty( hostPlugin, "someSelectionInPlaceholder", {
			get: () => {
				let selection = hostPlugin.currentSelection;
				if ( !Validator.isObject( selection ) ) return void 0;
				return hostPlugin.isSomeSelectionInPlaceholder( selection );
			},
			set: ( newValue ) => {
				hostPlugin._logAsUnmodifiable( "someSelectionInPlaceholder", newValue );
			}
		} );

		Object.defineProperty( hostPlugin, "someSelectionInMultilinePlaceholder", {
			get: () => {
				let selection = hostPlugin.currentSelection;
				if ( !Validator.isObject( selection ) ) return void 0;
				return hostPlugin.isSomeSelectionInMultilinePlaceholder( selection );
			},
			set: ( newValue ) => {
				hostPlugin._logAsUnmodifiable( "someSelectionInMultilinePlaceholder", newValue );
			}
		} );

	}

	_positionExists( position ) {
		let objectsPosition = this.objectsPosition;
		if ( !Validator.isObject( objectsPosition ) ||
			!Validator.isFunction( objectsPosition.exists ) ) return false;
		return objectsPosition.exists( position );
	}

	_setSelectionToPosition( position ) {
		if ( !this._positionExists( position ) ) return false;
		this.editor.model.change( writer => writer.setSelection( position ) );
		return true;
	}

	_setSelectionToPositionIf( position, writer ) {
		if ( !this._positionExists( position ) ) return false;
		let range = writer.createRange( position );
		writer.setSelection( range );
		return true;
	}

	getPositionsToPlaceholderPlacementRelation( {
		start,
		end,
		inlineOnly = true,
		bothPositionsShouldBeValid = false
	} ) {
		let result = {
			start: {
				position: void 0,
				in: { inline: void 0, multiline: void 0 },
				before: { inline: void 0, multiline: void 0 },
				atStartOf: { inline: void 0, multiline: void 0 }
			},
			end: {
				position: void 0,
				in: { inline: void 0, multiline: void 0 },
				after: { inline: void 0, multiline: void 0 },
				atEndOf: { inline: void 0, multiline: void 0 },
			},
			noPlaceholderNearby: void 0
		};
		if ( !this._positionExists( start ) ) {
			if ( !!bothPositionsShouldBeValid ||
				!this._positionExists( end ) ) return result;
			start = end;
		}
		if ( !this._positionExists( end ) ) {
			if ( !!bothPositionsShouldBeValid ) return result;
			end = start;
		}

		if ( this.objectsPosition._isFirstPositionAfterSecond( end, start ) ) {
			result.start.position = start;
			result.end.position = end;
		} else {
			result.start.position = end;
			result.end.position = start;
		}

		result.start.atStartOf.inline = this
			.startsInInlinePlaceholder( result.start.position );
		result.start.before.inline = this
			.startsBeforeInlinePlaceholder( result.start.position );
		result.start.in.inline = this
			.isInInlinePlaceholder( result.start.position );

		result.end.atEndOf.inline = this
			.endsInInlinePlaceholder( result.end.position );
		result.end.after.inline = this
			.endsAfterInlinePlaceholder( result.end.position );
		result.end.in.inline = this
			.isInInlinePlaceholder( result.end.position );

		result.noPlaceholderNearby = !result.start.in.inline &&
			!result.start.before.inline && !result.end.in.inline &&
			!result.end.after.inline;

		// this._log( JSON.stringify( result.start.position ) + "\n" +
		// 	JSON.stringify( result.end.position ) );

		if ( inlineOnly ) return result;

		// TODO for multiline

		return result;
	}

	getAdvancedPositionToPlaceholderPlacement( {
		positions = void 0,
		start,
		end,
		inlineOnly = true,
		bothPositionsShouldBeValid = false
	} ) {
		if ( !Validator.isObject( positions ) )
			positions = this.getPositionsToPlaceholderPlacementRelation( {
				start: start,
				end: end,
				inlineOnly: inlineOnly,
				bothPositionsShouldBeValid: bothPositionsShouldBeValid
			} );

		const onlyStartIsInside = positions.start.in.inline &&
			( !positions.end.in.inline && !positions.end.after.inline );

		const onlyEndIsInside = !onlyStartIsInside && positions.end.in.inline &&
			( !positions.start.in.inline && !positions.start.before.inline );

		const startsAndEndsInDifferentPlaceholders = !onlyStartIsInside &&
			!onlyEndIsInside &&
			positions.start.in.inline && positions.end.in.inline &&
			positions.start.position.parent != positions.end.position.parent;

		const startsInsideButNotAtStart = positions.start.in.inline &&
			!positions.start.atStartOf.inline &&
			!positions.start.before.inline;

		const endsInsideButNotAtEnd = positions.end.in.inline &&
			!positions.end.atEndOf.inline &&
			!positions.end.after.inline;

		const result = {
			onlyStartIsInside: onlyStartIsInside,
			onlyEndIsInside: onlyEndIsInside,
			startsAndEndsInDifferentPlaceholders: startsAndEndsInDifferentPlaceholders,
			startsInsideButNotAtStart: startsInsideButNotAtStart,
			endsInsideButNotAtEnd: endsInsideButNotAtEnd
		};

		// this._log( JSON.stringify( result ) );

		return result;
	}

	getPositionToPlaceholderComplexPlacement( {
		positions = void 0,
		start,
		end,
		inlineOnly = true,
		bothPositionsShouldBeValid = false
	} ) {
		if ( !Validator.isObject( positions ) )
			positions = this.getPositionsToPlaceholderPlacementRelation( {
				start: start,
				end: end,
				inlineOnly: inlineOnly,
				bothPositionsShouldBeValid: bothPositionsShouldBeValid
			} );

		if ( !Validator.isObject( positions.start.position ) ||
			!Validator.isObject( positions.end.position ) ) return positions;

		if ( positions.start.in.inline ) {
			positions.start.placeholder =
				this.getInlinePlaceholderParent( positions.start.position.parent );
		} else if ( positions.start.before.inline ) {
			positions.start.placeholder =
				this.getInlinePlaceholderBefore( positions.start.position );
		}

		if ( positions.end.in.inline ) {
			positions.end.placeholder =
				this.getInlinePlaceholderParent( positions.end.position.parent );
		} else if ( positions.end.after.inline ) {
			positions.end.placeholder =
				this.getInlinePlaceholderAfter( positions.end.position );
		}

		return positions;
	}

	isFullSelectionInPlaceholder( selection ) {
		return this.isAnchorInPlaceholder( selection ) &&
			this.isFocusInPlaceholder( selection );
	}

	isFullSelectionInMultilinePlaceholder( selection ) {
		return this.isAnchorInMultilinePlaceholder( selection ) &&
			this.isFocusInMultilinePlaceholder( selection );
	}

	isSomeSelectionInPlaceholder( selection ) {
		return this.isAnchorInPlaceholder( selection ) ||
			this.isFocusInPlaceholder( selection );
	}

	isSomeSelectionInMultilinePlaceholder( selection ) {
		return this.isAnchorInMultilinePlaceholder( selection ) ||
			this.isFocusInMultilinePlaceholder( selection );
	}

	onlyAnchorInPlaceholder( selection ) {
		return this.isAnchorInPlaceholder( selection ) &&
			!this.isFocusInPlaceholder( selection );
	}

	onlyFocusInPlaceholder( selection ) {
		return !this.isAnchorInPlaceholder( selection ) &&
			this.isFocusInPlaceholder( selection );
	}

	isAnchorInPlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInPlaceholder( selection.anchor );
	}

	isAnchorInInlinePlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInInlinePlaceholder( selection.anchor );
	}

	isAnchorInMultilinePlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInMultilinePlaceholder( selection.anchor );
	}

	isFocusInPlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInPlaceholder( selection.focus );
	}

	isFocusInInlinePlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInInlinePlaceholder( selection.focus );
	}

	isFocusInMultilinePlaceholder( selection ) {
		if ( !Validator.isObject( selection ) ) return false;
		return this.isInMultilinePlaceholder( selection.focus );
	}

	isInPlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.isInInlinePlaceholder( position ) ||
			this.isInMultilinePlaceholder( position );
	}

	isInInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.isElementInInlinePlaceholder( position.parent );
	}

	isElementInInlinePlaceholder( element ) {
		let inlinePlaceholder = this.getInlinePlaceholderParent( element );
		return Validator.isObject( inlinePlaceholder );
	}

	getInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return void 0;
		return this.getInlinePlaceholderParent( position.parent );
	}

	getInlinePlaceholderParent( element ) {
		if ( !Validator.isObject( element ) ) return void 0;
		let counter = 0;
		let potentialInlinePlaceholder = element;
		while ( counter < 6 && Validator.isObject( potentialInlinePlaceholder ) &&
			Validator.isObject( potentialInlinePlaceholder.parent ) &&
			!this.isInlinePlaceholder( potentialInlinePlaceholder ) ) {
			potentialInlinePlaceholder = potentialInlinePlaceholder.parent;
			counter++;
		}
		return this.isInlinePlaceholder( potentialInlinePlaceholder ) ?
			potentialInlinePlaceholder : void 0;
	}

	isInlinePlaceholder( element ) {
		return Validator.isElement( element, "pisaPlaceholder" ) ||
			( Validator.isObject( element ) && Validator.isMap( element._attrs ) &&
				Validator.isString( element._attrs.get( "data-value" ) ) );
	}

	isInMultilinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.isElementInMultilinePlaceholder( position.parent );
	}

	getMultilinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return void 0;
		return this.getMultilinePlaceholderParent( position.parent );
	}

	isElementInMultilinePlaceholder( element ) {
		let multilinePlaceholder = this.getMultilinePlaceholderParent( element );
		return Validator.isObject( multilinePlaceholder );
	}

	getMultilinePlaceholderParent( element ) {
		if ( !Validator.isObject( element ) ) return void 0;
		let counter = 0;
		let potentialMultilinePlaceholder = element;
		while ( counter < 6 && Validator.isObject( potentialMultilinePlaceholder ) &&
			Validator.isObject( potentialMultilinePlaceholder.parent ) &&
			!this.isMultilinePlaceholder( potentialMultilinePlaceholder ) ) {
			potentialMultilinePlaceholder = potentialMultilinePlaceholder.parent;
			counter++;
		}
		return Validator.isObject( potentialMultilinePlaceholder ) &&
			!!potentialMultilinePlaceholder.isMultilinePlaceholder ?
			potentialMultilinePlaceholder : void 0;
	}

	isMultilinePlaceholder( element ) {
		if ( !Validator.isElement( element, "paragraph" ) ||
			!Validator.isMap( element._attrs ) ) return false;
		if ( ![ "placeholderName", "placeholderValue" ]
			.every( value => Validator.isString( element._attrs.get( value ) ) ) ) return false;
		if ( ![ "placeholderChildIndex", "groupIndex", "placeholderLineNumber" ]
			.every( value => Validator.isString( element._attrs.get( value ) ) ||
				Validator.isPositiveInteger( element._attrs.get( value ) ) ) )
			return this._log( `A multiline placeholder that has the attributes` +
				` ${ "placeholderName" } and ${ "placeholderValue" }, but does not` +
				` have one or all of the attributes ${ "placeholderChildIndex" }` +
				` or/and ${ "groupIndex" } or/and ${ "placeholderLineNumber" } was` +
				` detected.`, false );
		// if ( ![ "isPlaceholderContainer", "contentEditable" ]
		// 	.every( value => Validator.isString( element._attrs.get( value ) ) ||
		// 		Validator.isBoolean( element._attrs.get( value ) ) ) )
		// 	return this._log( `A multiline placeholder that has the attributes` +
		// 		` ${ "placeholderName" }, ${ "placeholderValue" },` +
		// 		` ${"placeholderChildIndex"}, ${"groupIndex"} and` +
		// 		` ${"placeholderLineNumber"}, but does not have one or all of the` +
		// 		` attributes ${ "isPlaceholderContainer" } or/and` +
		// 		` ${ "contentEditable" } was detected.`, false );
		element.isMultilinePlaceholder = true;
		return true;
	}

	startsInPlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.startsInInlinePlaceholder( position ) ||
			this.startsInMultilinePlaceholder( position );
	}

	startsBeforePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.startsBeforeInlinePlaceholder( position ) ||
			this.startsBeforeMultilinePlaceholder( position );
	}

	startsInInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		if ( !Validator.isElement( position.parent, "pisaPlaceholder" ) ) return false;
		if ( Validator.isBoolean( position.isAtStart ) &&
			!!position.isAtStart ) return true;
		return position.offset == 0;
	}

	startsBeforeInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		if ( Validator.isElement( position.nodeAfter, "pisaPlaceholder" ) )
			return true;
		if ( !Validator.is( position.textNode, "Text" ) ) return false;
		if ( position.textNode.endOffset != position.offset ||
			!Validator.isInteger( position.offset ) ) return false;
		return Validator
			.isElement( position.textNode.nextSibling, "pisaPlaceholder" );
	}

	isAtMultilinePlaceholderLineStart( position ) {
		if ( !this.isInMultilinePlaceholder( position ) ) return false;
		return !!position.isAtStart && position.index == 0 &&
			position.path[ position.path.length - 1 ] == 0;
	}

	isFirstMultilinePlaceholderLine( element ) {
		if ( !this.isMultilinePlaceholder( element ) ) return false;
		let childIndex = element._attrs.get( "placeholderChildIndex" );
		return childIndex == 1 || childIndex == "1";
	}

	isInFirstMultilinePlaceholderLine( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.isFirstMultilinePlaceholderLine( position.parent );
	}

	startsInMultilinePlaceholder( position ) {
		if ( !this.isInFirstMultilinePlaceholderLine( position ) ) return false;
		return !!position.isAtStart && position.index == 0 &&
			position.path[ position.path.length - 1 ] == 0;
	}

	startsBeforeMultilinePlaceholder( position ) {
		return this.startsInMultilinePlaceholder( position );
	}

	endsInPlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.endsInInlinePlaceholder( position ) ||
			this.endsInMultilinePlaceholder( position );
	}

	endsAfterPlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		return this.endsAfterInlinePlaceholder( position ) ||
			this.endsAfterMultilinePlaceholder( position );
	}

	endsInInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		if ( !Validator.isElement( position.parent, "pisaPlaceholder" ) )
			return false;
		if ( Validator.isBoolean( position.isAtEnd ) && !!position.isAtEnd )
			return true;
		return position.offset == position.parent.maxOffset &&
			Validator.isPositiveInteger( position.offset );
	}

	endsAfterInlinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		if ( Validator.isElement( position.nodeBefore, "pisaPlaceholder" ) )
			return true;
		if ( !Validator.is( position.textNode, "Text" ) ) return false;
		if ( position.textNode.startOffset != position.offset ||
			!Validator.isInteger( position.offset ) ) return false;
		return Validator
			.isElement( position.textNode.previousSibling, "pisaPlaceholder" );
	}

	endsInMultilinePlaceholder( position ) {
		if ( !this._positionExists( position ) ) return false;
		// this._log( "endsInMultilinePlaceholder" );
	}

	endsAfterMultilinePlaceholder( position ) {
		return false;
		if ( !this._positionExists( position ) ) return false;
		// this._log( "endsAfterMultilinePlaceholder" );
	}

	getInlinePlaceholderBefore( position ) {
		if ( !this._positionExists( position ) ) return void 0;
		return Validator.isElement( position.nodeBefore, "pisaPlaceholder" ) ?
			position.nodeBefore :
			Validator.isObjectPath( position, "position.textNode.previousSibling" ) &&
			Validator.isElement( position.textNode.previousSibling, "pisaPlaceholder" ) ?
			position.textNode.previousSibling : void 0;
	}

	getInlinePlaceholderAfter( position ) {
		if ( !this._positionExists( position ) ) return void 0;
		return Validator.isElement( position.nodeAfter, "pisaPlaceholder" ) ?
			position.nodeAfter :
			Validator.isObjectPath( position, "position.textNode.nextSibling" ) &&
			Validator.isElement( position.textNode.nextSibling, "pisaPlaceholder" ) ?
			position.textNode.nextSibling : void 0;
	}

	cloneEarliestPositionPath() {
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) ) return void 0;
		let positionToClone = this.objectsPosition._isSelectionBackward( selection ) ?
			selection.focus : selection.anchor;
		return [ ...positionToClone.path ];
	}

	// ---------------------------------------------------------------------------

	_getFinalPosition( positions, placeholder, writer ) {
		if ( !Validator.isObject( placeholder.nextSibling ) ||
			!Validator.isObjectPath( positions, "positions.start.position.path" ) ||
			!Validator.isArray( positions.start.position.path ) ||
			positions.start.position.path.length < 1 ) return void 0;
		let pathInsidePlaceholder = [ ...positions.start.position.path ];
		let startPositionOffsetInPlaceholder =
			pathInsidePlaceholder[ pathInsidePlaceholder.length - 1 ];
		let finalPosition = writer.createPositionBefore( placeholder.nextSibling );
		if ( !this._positionExists( finalPosition ) ) return void 0;
		let path = finalPosition.path;
		if ( !Validator.isArray( path ) || path.length < 1 ) return;
		path[ path.length - 1 ] = path[ path.length - 1 ] +
			startPositionOffsetInPlaceholder - 1;
		return this.objectsPosition._pathToPosition( path, false );
	}

	_removeRangeAfterPlaceholder( placeholder, writer, positions ) {
		let positionAfterPlaceholder = writer.createPositionAfter( placeholder );
		if ( !this._positionExists( positionAfterPlaceholder ) ) return;
		let rangeToBeRemoved = writer
			.createRange( positionAfterPlaceholder, positions.end.position );
		writer.remove( rangeToBeRemoved );
	}

	_removeRangeBeforePlaceholder( placeholder, writer, positions ) {
		let positionBeforePlaceholder = writer.createPositionBefore( placeholder );
		if ( !this._positionExists( positionBeforePlaceholder ) ) return;
		let rangeToBeRemoved = writer
			.createRange( positions.start.position, positionBeforePlaceholder );
		writer.remove( rangeToBeRemoved );
	}

	_removeRangeBetweenPlaceholders( startPlaceholder, endPlaceholder, writer ) {
		let positionAfterPlaceholder = writer.createPositionAfter( startPlaceholder );
		if ( !this._positionExists( positionAfterPlaceholder ) ) return;
		let positionBeforePlaceholder = writer.createPositionBefore( endPlaceholder );
		if ( !this._positionExists( positionBeforePlaceholder ) ) return;
		let rangeToBeRemoved = writer
			.createRange( positionAfterPlaceholder, positionBeforePlaceholder );
		writer.remove( rangeToBeRemoved );
	}

	_removeRangeAtPlaceholderEnd( placeholder, writer, positions ) {
		let positionAtPlaceholderEnd = writer.createPositionAt( placeholder, "end" );
		if ( !this._positionExists( positionAtPlaceholderEnd ) ) return;
		let rangeToBeRemoved = writer
			.createRange( positions.start.position, positionAtPlaceholderEnd );
		writer.remove( rangeToBeRemoved );
	}

	_removeRangeAtPlaceholderStart( placeholder, writer, positions ) {
		let positionAtPlaceholderStart = writer.createPositionAt( placeholder, 0 );
		if ( !this._positionExists( positionAtPlaceholderStart ) ) return;
		let rangeToBeRemoved = writer
			.createRange( positionAtPlaceholderStart, positions.end.position );
		writer.remove( rangeToBeRemoved );
	}

	// ---------------------------------------------------------------------------

	_moveSelectionToInlinePlaceholderMargin( {
		position,
		eventInfo,
		data,
		atStart = false
	} ) {
		if ( !Validator.isObject( position ) ) return false;
		let inlinePlaceholder = !atStart ?
			this.getInlinePlaceholderBefore( position ) :
			this.getInlinePlaceholderAfter( position );
		if ( !this._setSelectionAtInlinePlaceholderMargin(
				inlinePlaceholder, !!atStart ) ) return false;
		if ( Validator.isObject( eventInfo ) && Validator.isObject( data ) )
			this.stopEditorEvent( eventInfo, data );
		return true;
	}

	_moveSelectionToInlinePlaceholderEnd( position, eventInfo, data ) {
		return this._moveSelectionToInlinePlaceholderMargin( {
			position: position,
			eventInfo: eventInfo,
			data: data,
			atStart: false
		} );
	}

	_moveSelectionToInlinePlaceholderStart( position, eventInfo, data ) {
		return this._moveSelectionToInlinePlaceholderMargin( {
			position: position,
			eventInfo: eventInfo,
			data: data,
			atStart: true
		} );
	}

	_setSelectionAtInlinePlaceholderMargin( inlinePlaceholder, atStart = false ) {
		return !atStart ? this._setSelectionAtInlinePlaceholderEnd( inlinePlaceholder ) :
			this._setSelectionAtInlinePlaceholderStart( inlinePlaceholder );
	}

	_setSelectionAtInlinePlaceholderStart( inlinePlaceholder ) {
		if ( !Validator.isObject( inlinePlaceholder ) ) return false;
		let newPosition = this._getPositionAtInlinePlaceholderStart( inlinePlaceholder );
		return this._setSelectionToPosition( newPosition );
	}

	_setSelectionAtInlinePlaceholderEnd( inlinePlaceholder ) {
		if ( !Validator.isObject( inlinePlaceholder ) ) return false;
		let newPosition = this._getPositionAtInlinePlaceholderEnd( inlinePlaceholder );
		return this._setSelectionToPosition( newPosition );
	}

	_getPositionAtInlinePlaceholderMargin( inlinePlaceholder, atStart = true ) {
		if ( !Validator.isObject( inlinePlaceholder ) ) return void 0;
		return this.editor.model.change( writer => {
			return writer.createPositionAt( inlinePlaceholder, ( !atStart ? "end" : 0 ) );
		} );
	}

	_getPositionAtInlinePlaceholderStart( inlinePlaceholder ) {
		return this._getPositionAtInlinePlaceholderMargin( inlinePlaceholder, true );
	}

	_getPositionAtInlinePlaceholderEnd( inlinePlaceholder ) {
		return this._getPositionAtInlinePlaceholderMargin( inlinePlaceholder, false );
	}

	// ---------------------------------------------------------------------------

	_getPlaceholderParent( element ) {
		let parentsCount = 0;
		while ( parentsCount < 10 && Validator.isObject( element ) &&
			element.name != "div" && element.name != "dfn" &&
			Validator.isObject( element.parent ) ) {
			element = element.parent;
			parentsCount++;
		}
		return Validator.couldBe( element, "Element" ) && [ "div", "dfn" ]
			.indexOf( element.name ) >= 0 ? element : void 0;
	}

	// ---------------------------------------------------------------------------

}

// const ATTRIBUTES_TO_MIRROR = [ "_positionExists",
// 	"getPositionsToPlaceholderPlacementRelation", "isFullSelectionInPlaceholder",
// 	"isSomeSelectionInPlaceholder", "onlyAnchorInPlaceholder",
// 	"onlyFocusInPlaceholder", "isAnchorInPlaceholder",
// 	"isAnchorInInlinePlaceholder", "isAnchorInMultilinePlaceholder",
// 	"isFocusInPlaceholder", "isFocusInInlinePlaceholder",
// 	"isFocusInMultilinePlaceholder", "isInPlaceholder", "isInInlinePlaceholder",
// 	"isElementInInlinePlaceholder", "getInlinePlaceholder",
// 	"getInlinePlaceholderParent", "isInlinePlaceholder",
// 	"isInMultilinePlaceholder", "startsInPlaceholder", "startsBeforePlaceholder",
// 	"startsInInlinePlaceholder", "startsBeforeInlinePlaceholder",
// 	"startsInMultilinePlaceholder", "startsBeforeMultilinePlaceholder",
// 	"endsInPlaceholder", "endsAfterPlaceholder", "endsInInlinePlaceholder",
// 	"endsAfterInlinePlaceholder", "endsInMultilinePlaceholder",
// 	"endsAfterMultilinePlaceholder", "getInlinePlaceholderBefore",
// 	"getInlinePlaceholderAfter", "_setSelectionToPosition",
// 	"_setSelectionToPositionIf", "cloneEarliestPositionPath", "_getFinalPosition",
// 	"_removeRangeAfterPlaceholder", "_removeRangeBeforePlaceholder",
// 	"_removeRangeBetweenPlaceholders", "_removeRangeAtPlaceholderEnd",
// 	"_removeRangeAtPlaceholderStart", "_moveSelectionToInlinePlaceholderMargin",
// 	"_moveSelectionToInlinePlaceholderEnd",
// 	"_moveSelectionToInlinePlaceholderStart",
// 	"_setSelectionAtInlinePlaceholderMargin",
// 	"_setSelectionAtInlinePlaceholderStart",
// 	"_setSelectionAtInlinePlaceholderEnd",
// 	"_getPositionAtInlinePlaceholderMargin",
// 	"_getPositionAtInlinePlaceholderStart",
// 	"_getPositionAtInlinePlaceholderEnd",
// 	"_getPlaceholderParent",
// 	"getAdvancedPositionToPlaceholderPlacement",
// 	"getPositionToPlaceholderComplexPlacement"
// ];
