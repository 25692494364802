import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import DowncastWriter from '@ckeditor/ckeditor5-engine/src/view/downcastwriter';
import { registerMultilinePlaceholder } from './insertplaceholdercommand';
// import PisaPlaceholderContainerEditing from './pisaplaceholdercontainerediting';

export default class PisaBlockDefinitionEditing extends Plugin {

	// static get requires() {
	// 	return [ PisaPlaceholderContainerEditing ];
	// }

	init() {
		const editor = this.editor;
		const upcastWriter = new UpcastWriter();
		const dataProcessor = editor.data.processor || editor.getPsaDP();

		let definitionAttributes = new Map();
		definitionAttributes.set( "data-placeholder-container", "isPlaceholderContainer" );
		definitionAttributes.set( "data-title", "placeholderName" );
		definitionAttributes.set( "data-value", "placeholderValue" );
		definitionAttributes.set( "data-placeholder-lines", "placeholderLineNumber" );
		definitionAttributes.set( "data-current-index", "placeholderChildIndex" );
		// definitionAttributes.set( "contenteditable", "contentEditable" );
		definitionAttributes.set( "data-group-index", "groupIndex" );

		editor.model.schema.extend( 'paragraph', {
			allowAttributes: Array.from( definitionAttributes.values() )
		} );

		editor.conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: "div",
				attributes: {
					"data-group-index": /[0-9]{13}/,
					"data-title": /.+/,
					"data-value": /.+/
				}
			},
			model: ( viewElement, modelWriter ) => {
				if ( !viewElement || !viewElement._attrs || viewElement._attrs.length < 3 ) {
					console.warn( "Placeolder DIV does not have necessary attributes and " +
						"can not be converted." );
					return;
				}
				editor.objects.placeholders.titlesShown &&
					clearPlaceholder( upcastWriter, viewElement );
			},
			converterPriority: 'highest'
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.conversion.for( 'downcast' ).elementToElement( {
			model: "paragraph",
			view: ( modelElement, viewWriter ) => {
				if ( !modelElement || typeof modelElement._attrs != "object" ||
					!modelElement._attrs.get( "placeholderName" ) || !modelElement._attrs.get( "placeholderValue" ) ||
					!modelElement._attrs.get( "groupIndex" ) ) return;
				if ( editor.objects.placeholders.titlesShown ) {
					registerPlhInGroupTemp( editor, modelElement );
					return;
				}
				let title = modelElement._attrs.get( "placeholderName" ) || "";
				let groupIndex = modelElement._attrs.get( "groupIndex" ) || "";
				let value = editor.objects.placeholders.map.get( title );
				if ( typeof value != "object" ) {
					let base64Value = modelElement._attrs.get( "placeholderValue" );
					let unicodeValue = dataProcessor._decode64( base64Value );
					let docFrag = {
						_children: {
							_nodes: [ modelElement ]
						}
					};
					registerMultilinePlaceholder( editor, title, unicodeValue, base64Value, docFrag );
					return;
				}
				let currentGroup = undefined;
				if ( typeof value.elementGroups != "object" ) value.elementGroups = [];
				value.elementGroups.forEach( group => {
					group.groupIndex == groupIndex ? currentGroup = group : void 0;
					if ( typeof currentGroup == "object" ) return;
				} );
				if ( typeof currentGroup == "object" ) {
					currentGroup.elementsList = currentGroup.elementsList || [];
					currentGroup.elementsList.push( modelElement );
					return;
				}
				let lineCount = Number( modelElement._attrs.get( "placeholderLineNumber" ) ) || 1;
				value.elementGroups.push( {
					elementsList: [ modelElement ],
					groupIndex: groupIndex,
					lineCount: lineCount
				} );
			},
			converterPriority: 'highest'
		}, { priority: "highest" } );

		definitionAttributes.forEach( ( modelName, viewName ) => {
			editor.conversion.for( 'downcast' ).attributeToAttribute( {
				model: {
					name: "paragraph",
					key: modelName
				},
				view: viewName
			} );

			// editor.conversion.for( 'downcast' ).attributeToAttribute( {
			// 	model: {
			// 		name: "table",
			// 		key: modelName
			// 	},
			// 	view: viewName
			// } );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: "p",
					key: viewName
				},
				model: modelName
			} );

			editor.conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: "div",
					key: viewName
				},
				model: modelName
			} );

			// editor.conversion.for( 'upcast' ).attributeToAttribute( {
			// 	view: {
			// 		name: "table",
			// 		key: viewName
			// 	},
			// 	model: modelName
			// } );

		} );

		editor.conversion.for( 'downcast' ).attributeToAttribute( {
			model: {
				name: "paragraph",
				key: "isPlaceholderContainer"
			},
			view: "data-placeholder-container"
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "p",
				key: "data-placeholder-container"
			},
			model: "isPlaceholderContainer"
		} );

		editor.conversion.for( 'upcast' ).attributeToAttribute( {
			view: {
				name: "div",
				key: "data-placeholder-container"
			},
			model: "isPlaceholderContainer"
		} );

	}
}

function clearPlaceholder( upcastWriter, placeholderElement ) {
	upcastWriter.removeChildren( 0, placeholderElement._children.length, placeholderElement );
}

function registerPlhInGroupTemp( editor, plhElement ) {
	let groupIndex = plhElement._attrs.get( "groupIndex" ) || "";
	if ( !groupIndex ) return;
	let group = editor.objects.placeholders.groupTemp.get( groupIndex );
	if ( typeof group == "object" ) {
		group.push( plhElement );
		return;
	}
	let newGroup = [ plhElement ];
	editor.objects.placeholders.groupTemp.set( groupIndex, newGroup );
}

function expandElementGroups( elementGroups, groupIndex, viewElement ) {
	let currentGroup = getCurrentGroup( elementGroups, groupIndex );
	if ( typeof currentGroup == "object" ) {
		// is already registered, just add element
		currentGroup.push( viewElement );
		return;
	}
	createAndAddNewGroupToList( elementGroups, groupIndex, viewElement );
	// let newGroupData = {
	// 	lineCount: Number( viewElement._attrs.get( "data-placeholder-lines" ) ),
	// 	groupIndex: Number( groupIndex ),
	// 	elementsList: [ viewElement ]
	// }
	// elementGroups.push( newGroupData );
}

function getCurrentGroup( groupsList, index ) {
	let currentGroup = undefined;
	groupsList.forEach( groupData => {
		groupData.groupIndex == index ?
			currentGroup = groupData.elementsList : void 0;
		if ( typeof currentGroup == "object" ) return;
	} );
	return currentGroup;
}

function createAndAddNewGroupToList( groupsList, index, element ) {
	let newGroupData = {
		lineCount: Number( element._attrs.get( "data-placeholder-lines" ) ),
		groupIndex: Number( index ),
		elementsList: [ element ]
	}
	groupsList.push( newGroupData );
}
