// app.js

import * as buttons from './plugins/commandnames';
import './theme/pisa.css';

// import EditorWatchdog from '@ckeditor/ckeditor5-watchdog/src/editorwatchdog';
import Warner from './plugins/pisautils/warner';
import PisaEditorWatchdog from './plugins/pisawatchdog/pisaeditorwatchdog';
import PisaExt from './pisaext/pisaext';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Typing from '@ckeditor/ckeditor5-typing/src/typing';

import PisaBalloons from './plugins/pisapanelview/pisaballoons';
import AllConverters from './plugins/minimaleditor/allconverters';
import PisaObjects from './plugins/pisabasics/pisaobjects';
import PisaGlobals from './plugins/pisabasics/pisaglobals'; // for image balloon only
import PisaSelection from './plugins/pisaselection/pisaselection';
import PisaPosition from './plugins/pisaselection/pisaposition';
import PisaListeners from './plugins/pisaselection/pisalisteners';
import PisaFire from './plugins/pisafire/pisafire';
import PisaConverter from './plugins/pisaconversion/pisaconverter';

import Undo from '@ckeditor/ckeditor5-undo/src/undo';
// import Undo from './plugins/iconsui/undo';
import FontFamilyUI from './plugins/pisafontfamily/pisafontfamilyui';
import FontSizeUI from './plugins/pisafontsize/pisafontsizeui';
import BoldUI from './plugins/iconsui/boldui';
import ItalicUI from './plugins/iconsui/italicui';
import UnderlineUI from './plugins/iconsui/underlineui';
import StrikethroughUI from './plugins/iconsui/strikethroughui';
import FontColorUI from './plugins/pisafontcolor/pisacolorui';
import BackgroundColorUI from './plugins/pisabckgcolor/pisabckgui';
import PisaAlignmentUI from './plugins/pisaalignment/pisaalignmentui';
import PisaSpacingUI from './plugins/pisaspacing/pisaspacingui';
import ListUI from './plugins/iconsui/listui';
import PisaListUI from './plugins/pisalist/pisalistui';
import IndentUI from './plugins/pisaindent/pisaindentui';
import PisaObjectLink from './plugins/pisaobjectlink/pisaobjectlink';
// import LinkUI from './plugins/iconsui/linkui';
import PisaLinkUI from './plugins/pisalink/pisalinkui';
import PisaImageUI from './plugins/pisaimage/pisaimageui';
import PisaImageResize from './plugins/pisaimageresize/pisaimageresize';
import PisaImageMoveUI from './plugins/pisaimagemove/pisaimagemoveui';
import PisaFeaturesUI from './plugins/pisafeatures/pisafeaturesui';
import CopyFormat from './plugins/pisacopyformat/pisacopyformat';
import RemoveFormat from './plugins/pisaremoveformat/pisaremoveformat';
import PisaDeleteAll from './plugins/pisadeleteall/pisadeleteall.js';
import PisaInsertParagraphUI from './plugins/pisainsertparagraph/pisainsertparagraphui';
import Table from './plugins/iconsui/table';
import PisaTableUI from './plugins/pisatable/pisatableui';
import PisaCellUI from './plugins/pisatablecell/pisacellui';
import PisaSearch from './plugins/pisasearch/pisasearch';
import PisaEmoji from './plugins/pisaemoji/pisaemoji';
import PisaSpellcheckUI from './plugins/pisaspellcheck/pisaspellcheckui';
import PisaPrint from './plugins/pisaprint/pisaprint';
import PisaMaximize from './plugins/pisamaximize/pisamaximize';
import PisaPlainText from './plugins/pisaplaintext/pisaplaintext';
import PisaSetData from './plugins/pisasetdata/pisasetdata';
import PisaToolbar from './plugins/pisatoolbar/pisatoolbar';
import ChangeToolbar from './plugins/pisatoolbar/pisachangetoolbar';

import PisaPlaceholderUI from './plugins/pisaplaceholder/pisaplaceholderui';
import PisaList from './plugins/pisalist/pisalist';
import PisaImagePositionUI from './plugins/pisaimageposition/pisaimagepositionui';

import PisaBlockDefinitionEditing from './plugins/pisaplaceholder/pisablockdefinitionediting';
import PisaDefinitionEditing from './plugins/pisaplaceholder/pisadefinitionediting';
import PisaPlaceholderContainerEditing from './plugins/pisaplaceholder/pisaplaceholdercontainerediting';

import FontColorCompactUI from './plugins/pisafontcolor/pisacolorcompactui';
import BackgroundColorCompactUI from './plugins/pisabckgcolor/pisabckgcompactui';
import FontColorTouchUI from './plugins/pisafontcolor/pisacolortouchui';
import BackgroundColorTouchUI from './plugins/pisabckgcolor/pisabckgtouchui';

// import PisaLinkListener from './plugins/pisalink/pisalinklistener';
import PisaSpellcheckCommandInit from './plugins/pisaspellcheck/pisaspellcheckcommandinit';
import PisaPaste from './plugins/pisaclipboard/pisapaste';

const ALL_PLUGINS = [ buttons.undo, buttons.redo, '|',
	buttons.fontFamily, buttons.fontSize, '|',
	buttons.bold, buttons.italic, buttons.underline, buttons.strikethrough, '|',
	buttons.touchColorCompact, buttons.touchBackgroundCompact, buttons.alignment, buttons.lineSpacing, '|',
	buttons.numberedListDropdown, buttons.bulletedList, buttons.increaseIndent, buttons.decreaseIndent, '|',
	buttons.link, buttons.pisaObject, buttons.insertImage, buttons.resizeImage, buttons.moveImage, '|',
	buttons.features, buttons.copyFormat, buttons.removeFormat, buttons.deleteAll, buttons.addParagraph, '|',
	buttons.insertTable, buttons.editTable, buttons.tableCell, '|',
	buttons.search, buttons.emoji, buttons.spellcheck, buttons.printContent, '|',
	buttons.plainText, buttons.setData, buttons.changeToolbar, "balloons"
];

// const testConfig = [];

const SEPARATOR_INDEXES = [ 2, 5, 10, 15, 20, 26, 32, 36, 41, -1 ];

const PLUGINS_MAP = getPluginsMap();

const MINIMAL_CONFIG = [ buttons.undo, buttons.redo, '|',
	buttons.fontFamily, buttons.fontSize, '|',
	buttons.bold, buttons.italic, buttons.underline, buttons.strikethrough, '|',
	buttons.touchColorCompact, buttons.touchBackgroundCompact, '|',
	buttons.numberedListDropdown, buttons.bulletedList, buttons.increaseIndent, buttons.decreaseIndent, '|',
	buttons.link, buttons.pisaObject, buttons.insertImage, '|',
	buttons.copyFormat, buttons.plainText, '|', buttons.maximize
];

const BASIC_CONFIG = [ buttons.link ];

const PREDEFINED_STYLES = {
	basic: {
		withPlh: {
			list: BASIC_CONFIG.concat( [ "placeholderEditing" ] ),
			description: "basic configuration without toolbar, with placeholder editing."
		},
		noPlh: {
			list: BASIC_CONFIG,
			description: "basic configuration without toolbar, without placeholder editing."
		}
	},
	minimal: {
		withPlh: {
			list: MINIMAL_CONFIG.concat( [ "placeholderEditing" ] ),
			description: "minimal configuration with toolbar, with placeholder editing."
		},
		noPlh: {
			list: MINIMAL_CONFIG,
			description: "minimal configuration with toolbar, without placeholder editing."
		}
	},
	full: {
		withPlh: {
			list: getArrAfterInsertion( ALL_PLUGINS,
				[ '|', buttons.placeholder, '|' ], 3, true ),
			description: "full configuration with toolbar, with placeholders."
		},
		noPlh: {
			list: ALL_PLUGINS,
			description: "full configuration with toolbar, without placeholders."
		}
	}
}

// make sure that 'psasales' exists
if ( !window.pisasales ) {
	window.pisasales = {};
}

/**
 * factory function for a "classic" editor
 * @param {Object} args info object providing creation parameters; must contain
 * the property "container" that specifies the DIV which will host the editor
 * @param {Function} onready callback function that is called after creation succeeded or failed
 */
window.pisasales.createCke5Inst = function( args, onready ) {
	if ( !args || !args.container ) {
		onready( false, new Error( "Invalid create options!" ), args );
		return;
	}

	const hasToolbar = !!args.tlb || ( typeof args.tlb === 'undefined' );
	const buttonsAndPlugins =
		PREDEFINED_STYLES[ ( !args.basic ? "full" : hasToolbar ? "minimal" : "basic" ) ]
		[ ( !!args.plhAllowed ? "withPlh" : "noPlh" ) ];
	// const hasToolbar = false;
	// const buttonsAndPlugins = PREDEFINED_STYLES.basic.withPlh;
	const configuration = getConfiguration( buttonsAndPlugins.list, [], true );
	const showToolbar = configuration.toolbar.length < 1 ? false : hasToolbar;
	const language = args.language || "en";
	const isHtmlAllowed = typeof args.isHtmlAllowed == "boolean" ?
		args.isHtmlAllowed : true;
	let pisaExt = void 0;

	const watchdog = new PisaEditorWatchdog( args, onready );
	watchdog.setCreator( () => {
		return ClassicEditor
			.create( createEditorElement( args.container, args.objmnu ), {
				plugins: configuration.plugins,
				toolbar: configuration.toolbar,
				language: language
			} )
			.then( editor => {
				// attach the PiSA sales extensions
				watchdog.psaExt = new PisaExt( args.container, editor, showToolbar );
				// set the placeholder mode
				args.plhAllowed && setPlaceholderMode( editor, args.showPlhTitles );
				editor.data.processor.htmlAvailable = isHtmlAllowed;
				// manage toolbar
				!showToolbar ? editor.objects.toolbar.hide() :
					editor.objects.toolbar.showFull();
				editor.objects.toolbar.syncButtonWithState();
				editor.buttons = buttons;
				editor.fire( "editorContentSet" );
				Warner.output( {
					color: "#6c7a89",
					addTimestamp: true,
					text: `Editor inside the container with the id` +
						` "${ args.container.id }" initialized with configuration: ` +
						`${ buttonsAndPlugins.description }\nEditor language is "${ language }".`
				} );
				return editor;
			} )
			.catch( error => {
				Warner.output( {
					color: "#e74c3c",
					error: true,
					errorText: error,
					addTimestamp: true,
					addCounter: true,
					text: error.stack,
					pluginName: `"APP.JS" container ID "${ args.container.id }"`,
					collapse: true
				} );
				// FAILED! call the 'onready' function with error info
				onready( false, error, args );
			} );
	} );

	watchdog.create().then( () => {
		onready( true, watchdog, { nfo: args, blockSetCtt: false } );
		watchdog.editor.fire( "editorContentSet" );
	} );

};

export function hideToolbar( editor ) {
	editor.ui.view.toolbar.isVisible = false;
	editor.ui.view.toolbar.element.setAttribute( "style", "visibility:hidden;display:none;" );
	editor.ui.view.toolbar.element.invisible = true;
}

function setPlaceholderMode( editor, titlesShown = false ) {
	if ( !editor || !editor.objects || !editor.objects.placeholders ) return;
	editor.objects.placeholders.titlesShown = titlesShown;
	if ( !editor.objects.placeholders.ui ||
		typeof editor.objects.placeholders.ui.harmonizeAllToggleButtons != "function" ) return;
	editor.objects.placeholders.ui.harmonizeAllToggleButtons();
}

function getArrAfterInsertion( originalArr, newElementsArr, positionIndex, fromEnd = false ) {
	if ( !( newElementsArr instanceof Array ) ) newElementsArr = [ newElementsArr ];
	let newIndex = fromEnd ? originalArr.length - positionIndex : positionIndex;
	return [ ...originalArr ].slice( 0, newIndex )
		.concat( [ ...newElementsArr ] )
		.concat( [ ...originalArr ].slice( newIndex ) );
}

function getConfiguration( config, separatorConfig = [], separatorsInside = false ) {
	let plugins = [];
	let toolbar = [];
	if ( config.indexOf( "basic" ) < 0 ) config = [ "basic" ].concat( config );
	for ( let button of config ) {
		if ( separatorsInside && button == "|" ) {
			toolbar.push( button );
			continue;
		}
		const params = PLUGINS_MAP.get( button );
		if ( !params || typeof params != "object" ) continue;
		plugins = plugins.concat( params.plugins );
		if ( !params.toolbar ) continue;
		toolbar.push( button );
	}
	for ( let index of separatorConfig ) {
		let fromEnd = index < 0;
		index = Math.abs( index );
		if ( index > toolbar.length ) continue;
		toolbar = getArrAfterInsertion( toolbar, '|', index, fromEnd );
	}
	if ( config.length > 1 && separatorConfig.length < 1 && !separatorsInside ) {
		console.warn( 'The toolbar of the CK Editor 5 might be missing separators "|" between buttons.' );
	}
	plugins = removeDuplicates( plugins );
	return { plugins: plugins, toolbar: { items: toolbar, shouldNotGroupWhenFull: true } };
}

function createEditorElement( container, objectMenu ) {
	const editorPlaceholder = document.createElement( 'div' );
	editorPlaceholder.objectMenu = objectMenu;
	// editorPlaceholder.objectMenu = [ { key: "OBJ_TYP_ACT", ttl: "Aktivität" },
	// 	{ key: "OBJ_TYP_CON", ttl: "Kontakt" },
	// 	{ key: "OBJ_TYP_SVC", ttl: "Servicevorgang" },
	// 	{ key: "OBJ_TYP_SVA", ttl: "Serviceobjekt" },
	// 	{ key: "OBJ_TYP_DOC", ttl: "Dokument" },
	// 	{ key: "OBJ_TYP_FOL", ttl: "Projektmappe" },
	// 	{ key: "OBJ_TYP_CTR", ttl: "Vertrag" },
	// 	{ key: "OBJ_TYP_FAQ", ttl: "FAQ / Fehlercode" },
	// 	{ key: "OBJ_TYP_PRC", ttl: "Abrechnungsbogen" },
	// 	{ key: "OBJ_TYP_QST", ttl: "Fragebogen" },
	// 	{ key: "OBJ_TYP_RSC", ttl: "Ressource" }
	// ];
	container.appendChild( editorPlaceholder );
	return editorPlaceholder;
}

function isValidObjectMenu( objectMenu ) {
	return !!objectMenu && objectMenu instanceof Array && objectMenu.length > 0;
}

function getPluginsMap() {
	let map = new Map();

	setMap( map, "basic", [ Typing, Essentials, PisaObjects, PisaConverter, Paragraph,
		PasteFromOffice, PisaSelection, PisaPosition, PisaListeners, AllConverters,
		PisaLinkUI,
		// PisaLinkListener,
		PisaSpellcheckCommandInit, PisaToolbar, PisaPaste
	], [], false );
	setMap( map, "balloons", [ PisaBalloons, PisaGlobals, PisaList, PisaImagePositionUI ], [], false );
	setMap( map, "placeholderEditing", PisaPlaceholderUI, [], false );
	setMap( map, buttons.fontColorCompact, FontColorCompactUI );
	setMap( map, buttons.backgroundColorCompact, BackgroundColorCompactUI );
	setMap( map, buttons.touchColorCompact, FontColorTouchUI );
	setMap( map, buttons.touchBackgroundCompact, BackgroundColorTouchUI );
	setMap( map, buttons.undo, Undo, buttons.redo );
	setMap( map, buttons.redo, Undo, buttons.undo );
	setMap( map, buttons.fontFamily, FontFamilyUI );
	setMap( map, buttons.fontSize, FontSizeUI );
	setMap( map, buttons.bold, BoldUI );
	setMap( map, buttons.italic, ItalicUI );
	setMap( map, buttons.underline, UnderlineUI );
	setMap( map, buttons.strikethrough, StrikethroughUI );
	setMap( map, buttons.fontColor, FontColorUI );
	setMap( map, buttons.backgroundColor, BackgroundColorUI );
	setMap( map, buttons.alignment, PisaAlignmentUI );
	setMap( map, buttons.lineSpacing, PisaSpacingUI );
	setMap( map, buttons.numberedListDropdown, PisaListUI );
	setMap( map, buttons.numberedList, ListUI, buttons.bulletedList );
	setMap( map, buttons.bulletedList, ListUI, buttons.numberedList );
	setMap( map, buttons.increaseIndent, IndentUI, buttons.decreaseIndent );
	setMap( map, buttons.decreaseIndent, IndentUI, buttons.increaseIndent );
	setMap( map, buttons.link, PisaLinkUI );
	setMap( map, buttons.pisaObject, PisaObjectLink );
	setMap( map, buttons.insertImage, PisaImageUI );
	setMap( map, buttons.resizeImage, PisaImageResize );
	setMap( map, buttons.moveImage, PisaImageMoveUI );
	setMap( map, buttons.features, PisaFeaturesUI );
	setMap( map, buttons.copyFormat, CopyFormat );
	setMap( map, buttons.removeFormat, RemoveFormat );
	setMap( map, buttons.deleteAll, PisaDeleteAll );
	setMap( map, buttons.addParagraph, PisaInsertParagraphUI );
	setMap( map, buttons.insertTable, Table );
	setMap( map, buttons.editTable, PisaTableUI );
	setMap( map, buttons.tableCell, PisaCellUI );
	setMap( map, buttons.search, PisaSearch );
	setMap( map, buttons.emoji, PisaEmoji );
	setMap( map, buttons.spellcheck, PisaSpellcheckUI );
	setMap( map, buttons.printContent, PisaPrint );
	setMap( map, buttons.maximize, PisaMaximize );
	setMap( map, buttons.plainText, PisaPlainText );
	setMap( map, buttons.setData, PisaSetData );
	setMap( map, buttons.changeToolbar, ChangeToolbar );
	setMap( map, buttons.placeholder, PisaPlaceholderUI );
	// setMap( map, buttons.a, A, [] );
	return map;
}

function setMap( map, key, pluginNameOrList, samePlugin = [], hasToolbar = true ) {
	if ( !( samePlugin instanceof Array ) ) samePlugin = [ samePlugin ];
	if ( !( pluginNameOrList instanceof Array ) ) pluginNameOrList = [ pluginNameOrList ];
	map.set( key, { plugins: pluginNameOrList, toolbar: hasToolbar, samePlugin: samePlugin } );
}

function removeDuplicates( arr ) {
	for ( let i = 0; i < arr.length; i++ ) {
		for ( let k = 0; k < i; k++ ) {
			if ( String( arr[ i ] ).toLowerCase() == String( arr[ k ] ).toLowerCase() ) {
				arr.splice( i, 1 );
				i--;
				break;
			}
		}
	}
	return arr;
}
