import LinkEditing from '@ckeditor/ckeditor5-link/src/linkediting';
import PisaExtendedLinkCommand from './pisaextendedlinkcommand';
// import LinkCommand from '@ckeditor/ckeditor5-link/src/linkcommand';
import PisaExtendedUnlinkCommand from './pisaextendedunlinkcommand';
// import UnlinkCommand from '@ckeditor/ckeditor5-link/src/unlinkcommand';
import { createLinkElement, /*ensureSafeUrl,*/ getLocalizedDecorators, normalizeDecorators } from '@ckeditor/ckeditor5-link/src/utils';
import bindTwoStepCaretToAttribute from '@ckeditor/ckeditor5-engine/src/utils/bindtwostepcarettoattribute';

const DECORATOR_AUTOMATIC = 'automatic';
const DECORATOR_MANUAL = 'manual';

const ATTRIBUTE_WHITESPACES = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205f\u3000]/g; // eslint-disable-line no-control-regex
const SAFE_URL = /^(?:(?:https?|ftps?|mailto):|[^a-z]|[a-z+.-]+(?:[^a-z+.:-]|$))/i;

const PREFIXES_FOR_AUTO_ALLOWED_LINKS = [ "vmrc://", "file://", "circuit://", "tel:" ];

export default class PisaExtendedLinkEditing extends LinkEditing {

	/**
	 * @inheritDoc
	 * @see {@link module:@ckeditor/ckeditor5-link/src/linkediting} LinkEditing
	 * @override
	 * the pluginName getter will still return "LinkEditing" as plugin name ant it should be this way;
	 * @see {@link module:@ckeditor/ckeditor5-link/src/linkediting~pluginName} static pluginName getter
	 * !the only change in comparison to the original method is that the
	 * "ensureSafeUrl" method was replace by a custom one to ensure that some
	 * special URLs are not replaced by "#"
	 * "ensureSafeUrl" is also used by/in PisaLinkActionsView
	 * @see {@link module: ./pisalinkactionsview} PisaLinkActionsView
	 * @see {@link module:@ckeditor/ckeditor5-link/src/utils~ensureSafeUrl} original of ensureSafeUrl
	 */
	init() {
		const editor = this.editor;
		const locale = editor.locale;

		// Allow link attribute on all inline nodes.
		editor.model.schema.extend( '$text', { allowAttributes: 'linkHref' } );

		editor.conversion.for( 'dataDowncast' )
			.attributeToElement( { model: 'linkHref', view: createLinkElement } );

		editor.conversion.for( 'editingDowncast' )
			.attributeToElement( {
				model: 'linkHref',
				view: ( href, writer ) => {
					return createLinkElement( ensureSafeUrl( href ), writer );
				}
			} );

		editor.conversion.for( 'upcast' )
			.elementToAttribute( {
				view: {
					name: 'a',
					attributes: {
						href: true
					}
				},
				model: {
					key: 'linkHref',
					value: viewElement => viewElement.getAttribute( 'href' )
				}
			} );

		// Create linking commands.
		// editor.commands.add( 'link', new LinkCommand( editor ) );
		editor.commands.add( 'link', new PisaExtendedLinkCommand( editor ) );
		// editor.commands.add( 'unlink', new UnlinkCommand( editor ) );
		editor.commands.add( 'unlink', new PisaExtendedUnlinkCommand( editor ) );

		const linkDecorators = getLocalizedDecorators( editor.t, normalizeDecorators( editor.config.get( 'link.decorators' ) ) );

		this._enableAutomaticDecorators( linkDecorators.filter( item => item.mode === DECORATOR_AUTOMATIC ) );
		this._enableManualDecorators( linkDecorators.filter( item => item.mode === DECORATOR_MANUAL ) );

		// Enable two-step caret movement for `linkHref` attribute.
		bindTwoStepCaretToAttribute( {
			view: editor.editing.view,
			model: editor.model,
			emitter: this,
			attribute: 'linkHref',
			locale
		} );

		// Setup highlight over selected link.
		this._setupLinkHighlight();
	}

}

export function ensureSafeUrl( url ) {
	url = String( url );

	return isSafeUrl( url ) ? url : '#';
}

function isSafeUrl( url ) {
	const normalizedUrl = url.replace( ATTRIBUTE_WHITESPACES, '' );

	if ( normalizedUrl.match( SAFE_URL ) ) return true;

	for ( let prefix of PREFIXES_FOR_AUTO_ALLOWED_LINKS ) {
		if ( normalizedUrl.startsWith( prefix ) ) return true;
	}

	return false;
}
