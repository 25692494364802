import Command from '@ckeditor/ckeditor5-core/src/command';
import { getStats, updateNodeListeners } from '../pisaimageresize/utils.js';
import { lastValidSelection } from '../pisafire/pisafirecommand';
import { selectionToRange, setSelectionOnElement } from '../utils';
import { isInsidePlaceholder } from '../pisaplaceholder/pisaplaceholderui';
import HtmHelper from '../pisautils/htmhelper';

export const MOVE_IMAGE = 'pisaMoveImage';

export default class PisaMoveImageCommand extends Command {

	constructor( editor ) {
		super( editor, MOVE_IMAGE );
	}

	execute( options = {} ) {
		const editor = this.editor;

		let selection = lastValidSelection( editor, options );
		let stats = getStats( editor, selection );
		if ( !stats || !stats.range || !stats.attributes ) return;

		editor.editing.view.document.once( "selectionChangeDone", () => {
			if ( isInsidePlaceholder( editor ) ) {
				console.warn( "Could not move image. New selection/position is inside a placeholder." );
				editor.objects.images ? editor.objects.images.lockCopy = false : void 0;
				return;
			}
			if ( !editor || !editor.model || !editor.model.document || !editor.model.document.selection ) {
				console.warn( "Could not move image. New selection/position is invalid." );
				editor.objects.images ? editor.objects.images.lockCopy = false : void 0;
				return;
			}
			const range = selectionToRange( editor.model.document.selection ) ?
				selectionToRange( editor.model.document.selection ) : editor.model.document.selection.getFirstRange();
			let id = 0;
			editor.model.change( writer => {
				writer.remove( stats.range );
				let image = writer.createElement( "pisaImage", stats.attributes );
				writer.setAttribute( "id", HtmHelper.generateImageId(), image );
				id = image.getAttribute( "id" );
				editor.objects.images.map.set( id, image );
				writer.insert( image, range.start );
				writer.setAttribute( "border", "5px solid var(--ck-color-base-active-focus)", range );
				setSelectionOnElement( editor, image, true, true, false );
				writer.setSelection( image, "on" );
			} );
			updateNodeListeners( editor.wdgId, id );
			editor.objects.images ? editor.objects.images.lockCopy = false : void 0;
		} );
	}

}
