import Command from '@ckeditor/ckeditor5-core/src/command';
import { START_LIST } from './pisalistediting';
import { insertListItem, setValueOnListItem, addEnterListener, isSelectionListItem } from './pisacontinuelistcommand';;
const LIST_ITEM = "listItem";

export default class PisaStartListCommand extends Command {

	// refresh() {
	// 	this.isEnabled = isSelectionListItem( this.editor );
	// }

	execute() {
		const editor = this.editor;
		let selection = editor.model.document.selection;
		let element = selection.anchor.parent;
		let value = 1;
		if ( element.name != "$root" && element.name != LIST_ITEM ) {
			insertListItem( editor );
			addEnterListener( editor );
		} else if ( element.name == LIST_ITEM ) {
			setValueOnListItem( editor );
			addEnterListener( editor );
		}
	}

}
