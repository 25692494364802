import Command from '@ckeditor/ckeditor5-core/src/command';
import StringHelper from '../pisautils/stringhelper';
import Validator from '../pisautils/validator';

export const INSERT_OBJECT_LINK = 'insertPisaObject';

export default class PisaInsertObjectLinkCommand extends Command {

	constructor( editor ) {
		super( editor, INSERT_OBJECT_LINK );
		this.lastHref = "";
		this.lastValue = "";
	}

	refresh() {
		// TODO disable if already inside link
	}

	execute( options = {} ) {
		if ( !options || typeof options != "object" ) return;
		this.lastHref = getValidObjectHref( options.href ) ||
			getValidObjectHref( options.link ) || getValidObjectHref( options.url );
		if ( !this.lastHref ) {
			this.lastHref = "";
			return;
		}
		let editor = this.editor;
		let dataProcessor = editor.data.processor || editor.getPsaDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) return;
		if ( dataProcessor.isPlain() ) dataProcessor.setPlainText( false );
		this.lastValue = getValidLinkName( options.text ) ||
			getValidLinkName( options.value ) || getValidLinkName( options.name ) || this.lastHref;
		this.lastValue = StringHelper.trimStart( this.lastValue );
		this.lastValue = dataProcessor._restoreEscapedHtm( this.lastValue );
		// ampersands restoration should happen AFTER value assignment
		this.lastHref = dataProcessor._restoreAmpersands( this.lastHref );
		let currentPosition = editor.objects.selection.getLastPosition( true, false );
		if ( !editor.objects.position.exists( currentPosition ) )
			currentPosition = editor.objects.selection.getLastPosition( true, true );
		if ( !editor.objects.position.exists( currentPosition ) ) {
			console.warn( "Could not insert object link into CK Editor 5. " +
				"Reason: invalid last model position." );
			return;
		}
		let currentSelection = editor.objects.selection.last.model.isCollapsed ?
			void 0 : editor.objects.selection._copyCurrentModelSelection();
		let attributes = {};
		let assignAttributes = () => {
			attributes = editor.objects.selection._copyCurrentAttributes( false );
		};
		this._tryCatch( assignAttributes, [], "Could not copy selection attributes." );
		delete attributes.linkHref;
		let insertPisaObject = () => {
			editor.model.enqueueChange( editor.model.createBatch(), writer => {

				// try to create text
				let text = void 0;
				let setText = () => {
					text = writer.createText( this.lastValue,
						Object.assign( { linkHref: this.lastHref }, attributes ) );
				};
				if ( !this._tryCatch( setText, [], "Could not create link text." ) ) return;

				// try to insert text
				let insertTextAtPosition = () => {
					writer.insert( text, currentPosition );
				};
				let insertTextAtSelection = () => {
					if ( !currentSelection || typeof currentSelection != "object" ||
						!text || typeof text != "object" ) return;
					editor.model.insertContent( text, currentSelection, "on" );
				};
				if ( !currentSelection || typeof currentSelection != "object" ||
					!this._tryCatch( insertTextAtSelection, [],
						"Could not insert link text at selection." ) ) {
					if ( !this._tryCatch( insertTextAtPosition, [],
							"Could not insert link text at position." ) ) return;
				}

				// try to create space
				let space = void 0;
				let setSpace = () => { space = writer.createText( " ", attributes ); };
				if ( !this._tryCatch( setSpace, [],
						"Could not create space after link." ) ) return;

				// try to insert space
				let insertSpace = () => { writer.insert( space, text, "after" ); };
				if ( !this._tryCatch( insertSpace, [],
						"Could not insert space after link text." ) ) return;

				// try to set selection
				let setSelectionAfterSpace = () => {
					writer.setSelection( space, "after" );
				};
				let setSelectionOnTextParent = () => {
					let parentElement = !!text && typeof text == "object" && !!text.parent &&
						typeof text.parent == "object" && typeof text.parent.name == "string" ?
						text.parent : void 0;
					if ( !parentElement ) return;
					if ( typeof text.endOffset != "number" || typeof parentElement.maxOffset != "number" ||
						text.endOffset + 1 > parentElement.maxOffset ) return;
					writer.setSelection( parentElement, text.endOffset + 1 );
				};
				if ( !this._tryCatch( setSelectionAfterSpace, [],
						"Could not set selection after the space after link text using the space position." ) ) {
					if ( !this._tryCatch( setSelectionOnTextParent, [],
							"Could not set selection after the space after link text using the text offset." ) ) return;
				}
			} );
			editor.objects.selection.update();
			editor.objects.focus.doFocus();
		};
		this._tryCatch( insertPisaObject, [] );
		this.lastHref = "";
		this.lastValue = "";
	}

	_tryCatch( func, params = [], reason, inclThis = false ) {
		let dataProcessor = this.editor.data.processor || this.editor.getPsaDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) {
			this._logErr( e, "Invalid data processor." );
			return false;
		}
		let success = true;
		try {
			dataProcessor._executeFunction( func, params, inclThis ? this : null );
		} catch ( e ) {
			this._logErr( e, reason );
			success = false;
		}
		return success;
	}

	_logErr( err, reason = "" ) {
		if ( typeof reason != "string" ) reason = "";
		if ( reason.length > 0 && !reason.startsWith( "Reason:" ) &&
			!reason.startsWith( " Reason:" ) ) reason = " Reason: " + reason;
		console.warn( `Could not insert a PiSA Object Link with the name "${ this.lastValue }"` +
			` and the href "${ this.lastHref }" into CK Editor 5.` + reason + ` Error:` );
		console.warn( err );
	}

}

function getValidObjectHref( potentialHref ) {
	if ( !potentialHref || typeof potentialHref != "string" || potentialHref.length < 1 ) return void 0;
	if ( !pisasales || typeof pisasales != "object" ||
		typeof pisasales.isPsaObjLink != "function" ) return potentialHref;
	return pisasales.isPsaObjLink( potentialHref ) ? potentialHref : void 0;
}

function getValidLinkName( potentialName ) {
	return Validator.isString( potentialName ) ? potentialName : void 0;
}
