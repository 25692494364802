import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import BalloonPanelView from '@ckeditor/ckeditor5-ui/src/panel/balloon/balloonpanelview';
import {
	addClasses,
	makeVisible,
	makeInvisible,
	getLiveSelectionNode,
	getElementNode,
	stringToArray
} from '../utils';
import { getOptimalPosition } from '@ckeditor/ckeditor5-utils/src/dom/position';
import Rect from '@ckeditor/ckeditor5-utils/src/dom/rect';
// import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';

// const INSERT = [ "undo", "redo", "pisaClipboard", "pisaStartList", "pisaContinueList",
// 	"pisaInsertImage", "inserttable", "pisaobject", "pisaplaintext"
// ];
const INSERT = [];
const IMAGE = [ "pisaDecreaseImage", "pisaIncreaseImage", "pisaVerticalAlign",
	"pisaImageLink", "pisaResizeImage", "pisaMoveImage", "pisaDeleteImage"
];
const TEXT = [ "bold", "italic", "fontSizeCompact", "fontFamilyCompact",
	"fontColorTouch", "fontBackgroundTouch", "pisaStartList", "pisaContinueList",
	"link", "pisaClipboard"
];
const TABLE = [ "pisaCell", "pisaEditTable", "pisaInsertParagraphBefore", "pisaInsertParagraphAfter",
	"pisaDecreaseTableMargin", "pisaIncreaseTableMargin", "fontSizeCompact",
	"fontFamilyCompact", "fontColorTouch", "fontBackgroundTouch"
];

const ARROW_HEIGHT = 10;
const defaultLimiterElement = global.document.body;

export default class PisaPanelBalloons extends Plugin {

	static get pluginName() {
		return 'PisaPanelBalloons';
	}

	init() {
		let editor = this.editor;
		editor.balloons = this;
		const positions = BalloonPanelView.defaultPositions;
		this.frozen = false;
		this.positions = {};
		this.spacing = 0;
		this.positions.top = [
			positions.northWestArrowSouth,
			positions.northWestArrowSouthWest,
			positions.northWestArrowSouthEast,
			positions.southWestArrowNorth,
			positions.southWestArrowNorthWest,
			positions.southWestArrowNorthEast
		];
		this.positions.base = [
			positions.southEastArrowNorth,
			positions.southEastArrowNorthEast,
			positions.southEastArrowNorthWest,
			positions.northEastArrowSouth,
			positions.northEastArrowSouthEast,
			positions.northEastArrowSouthWest
		];
		this.positions.limiter = () => {
			return editor.ui.view.editable.element;
			const view = this.editor.editing.view;
			const viewDocument = view.document;
			const editableElement = viewDocument.selection.editableElement;
			if ( editableElement ) {
				return view.domConverter.mapViewToDom( editableElement );
			}
			if ( this.editor.ui && this.editor.ui.view && this.editor.ui.view.editable &&
				this.editor.ui.view.editable.element ) return this.editor.ui.view.editable.element;
			if ( this.editor.ui && this.editor.ui._editableElements &&
				this.editor.ui._editableElements.get( "main" ) ) return this.editor.ui._editableElements.get( "main" );
			return null;
		};
		this.components = {};
		this.components.insert = INSERT;
		this.components.text = TEXT;
		this.components.image = IMAGE;
		this.components.table = TABLE;
		this.balloons = {};
		this._addPanelView( "insert" );
		this._addPanelView( "text" );
		this._addPanelView( "image" );
		this._addPanelView( "table" );

		this.editor.on( "change:isReadOnly", ( eventInfo, name, newValue, oldValue ) => {
			if ( !newValue || oldValue ) return;
			this.hideAll();
		} );
	}

	_addPanelView( name ) {
		this.balloons[ name ] = new BalloonPanelView( this.editor.locale );
		addClasses( this.balloons[ name ], [ "pisa-balloon-panel" ] );
		this.editor.ui.view.body.add( this.balloons[ name ] );
		this.editor.ui.focusTracker.add( this.balloons[ name ].element );
	}

	_isBalloonValid( balloonName ) {
		balloonName = String( balloonName );
		if ( !this.balloons[ balloonName ] || !this.balloons[ balloonName ] instanceof BalloonPanelView ) {
			console.warn( "[".concat( balloonName ).concat( "] (name) is not a Balloon Panel View." ) );
			return false;
		}
		return true;
	}

	_isBalloonEmpty( balloonName ) {
		if ( !this.balloons[ balloonName ] || !this.balloons[ balloonName ].content ||
			!this.balloons[ balloonName ].content._items ) return true;
		if ( Array.from( this.balloons[ balloonName ].content._items ).length <= 0 ) return true;
		return false;
	}

	show( balloon, onTop = false ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		let positionData = this.getPositionData( onTop );
		if ( !positionData ) return;
		this.attachTo( balloon, positionData );
		// this.balloons[ balloon ].attachTo( this.getPositionData( onTop ) );
	}

	freeze() {
		this.frozen = true;
		console.warn( "Balloon panels in CK Editor 5 are now frozen. Balloon panels" +
			" will not be shown until the balloons are revived from their frozen state." );
	}

	revive() {
		this.frozen = false;
		console.warn( "Balloon panels in CK Editor 5 are now revived from their frozen state." );
	}

	get isFrozen() {
		if ( this.frozen == true ) {
			console.warn( "Balloon panel is frozen and will not be shown in the CK " +
				"editor 5. Revive the balloons to be able to show them." );
		}
		return this.frozen;
	}

	get readOnlyMode() {
		let readOnly = typeof this.editor.isReadOnly == "boolean" &&
			this.editor.isReadOnly;
		if ( readOnly ) {
			console.warn( "Balloon panel will not be shown in the CK editor 5 in " +
				"read only mode. Enable editing to be able to show the balloons." )
		}
		return readOnly;
	}

	showIf( balloon, onTop = false, basedOnViews = true, basedOnComponents = false ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		if ( this._isBalloonEmpty( balloon ) ) {
			console.warn( `The Balloon Panel View [${ balloon }] is empty and will not be shown.` );
			return;
		}
		if ( basedOnViews && this._areAllViewsDisabled( balloon ) ) {
			console.warn( `The Balloon Panel View [${ balloon }] will not be shown ` +
				`because all Views (buttons) inside are disabled.` );
			return;
		}
		if ( basedOnComponents && this._areAllComponentsDisabled( balloon ) ) {
			console.warn( `The Balloon Panel View [${ balloon }] will not be shown ` +
				`because all Components (buttons) inside are disabled.` );
			return;
		}
		this.show( balloon, onTop );
	}

	showIfAtTop( balloon ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.showIf( balloon, true );
	}

	showIfAtBase( balloon ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.showIf( balloon, false );
	}

	showAtTop( balloon ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.show( balloon, true );
	}

	showAtBase( balloon ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.show( balloon, false );
	}

	hide( balloon ) {
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		this.balloons[ balloon ].hide();
		if ( !this.balloons[ balloon ].element ) return;
		this.removeFlexCss( this.balloons[ balloon ].element );
	}

	removeFlexCss( balloonElement ) {
		balloonElement.style.maxWidth = "";
		balloonElement.style.display = "";
		balloonElement.style.flexWrap = "";
	}

	addFlexCss( balloonElement, width ) {
		balloonElement.style.maxWidth = `${ width }px`;
		balloonElement.style.display = "flex";
		balloonElement.style.flexWrap = "wrap";
	}

	hideAll() {
		this.hide( "insert" );
		this.hide( "text" );
		this.hide( "image" );
		this.hide( "table" );
	}

	_addView( view, balloon ) {
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		if ( !view ) {
			console.warn( "Invalid view will not be added to [".concat( balloon )
				.concat( "] (name) Balloon Panel View." ) );
			return;
		}
		this.balloons[ balloon ].content.add( view );
	}

	_addComponent( component, balloon ) {
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		component = String( component );
		if ( !this.editor.ui.componentFactory._components.get( component.toLowerCase() ) ) {
			console.warn( "Component [".concat( component )
				.concat( "] (name) does not exist." ) );
			return;
		}
		let view = this.editor.ui.componentFactory.create( component );
		if ( !view ) {
			console.warn( "Could not create View for component ["
				.concat( component ).concat( "] (name)." ) );
			return;
		}
		view.component = component;
		this._addView( view, balloon );
	}

	fill( balloonName, components = null ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		components = components || ( balloonName == "insert" ? this.components.insert : null ) ||
			( balloonName == "text" ? this.components.text : null ) ||
			( balloonName == "image" ? this.components.image : this.components.table );
		components = removeDuplicates( Array.from( stringToArray( components ) ) );
		if ( components.length <= 0 ) {
			console.warn( "The Balloon Panel View [".concat( balloonName )
				.concat( "] will not be filled. The component list has no items." ) );
			return;
		}
		components.forEach( component => {
			this._addComponent( component, balloonName );
		} );
	}

	fillInsert( components = null ) {
		components = components || this.components.insert;
		this.fill( "insert", this.components.insert );
	}

	fillText( components = null ) {
		components = components || this.components.text;
		this.fill( "text", this.components.text );
	}

	fillImage( components = null ) {
		components = components || this.components.image;
		this.fill( "image", this.components.image );
	}

	fillTable( components = null ) {
		components = components || this.components.table;
		this.fill( "table", this.components.table );
	}

	hideInactive( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		// Array.from( this.balloons[ balloonName ].content._items )
		let components = Array.from( this.balloons[ balloonName ].content._items );
		for ( let view of components ) {
			if ( isComponentEnabled( this.editor, view.component ) ) continue;
			makeInvisible( this.editor, view );
		}
	}

	isActive( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return false;
		return this.balloons[ balloonName ].isVisible || false;
	}

	getActive() {
		return this.balloons.insert.isVisible ? "insert" :
			( this.balloons.text.isVisible ? "text" :
				( this.balloons.image.isVisible ? "image" :
					( this.balloons.table.isVisible ? "table" : "" ) ) );
	}

	positionActive() {
		let activeBalloon = this.getActive();
		if ( !activeBalloon ) return;
		let onTop = activeBalloon != "insert";
		this.switchTo( activeBalloon, onTop );
	}

	showActive( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		let components = Array.from( this.balloons[ balloonName ].content._items );
		for ( let view of components ) {
			if ( !isComponentEnabled( this.editor, view.component ) ) continue;
			makeVisible( this.editor, view );
		}
	}

	showAllComponents( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		Array.from( this.balloons[ balloonName ].content._items ).forEach( view => {
			makeVisible( this.editor, view );
		} );
	}

	hideAllComponents( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		Array.from( this.balloons[ balloonName ].content._items ).forEach( view => {
			makeInvisible( this.editor, view );
		} );
	}

	update( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		Array.from( this.balloons[ balloonName ].content._items ).forEach( view => {
			isComponentEnabled( this.editor, view.component ) ?
				makeVisible( this.editor, view ) : makeInvisible( this.editor, view );
		} );
	}

	refresh( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		Array.from( this.balloons[ balloonName ].content._items ).forEach( view => {
			view.isEnabled || view.component == "undo" || view.component == "redo" ?
				makeVisible( this.editor, view ) : makeInvisible( this.editor, view );
		} );
	}

	refreshAndShow( balloonName, onTop = false ) {
		this.refresh( balloonName );
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.showIf( balloonName, onTop );
	}

	switchTo( balloonName, onTop = false ) {
		this.hideAll();
		if ( this.readOnlyMode || this.isFrozen ) return;
		this.refreshAndShow( balloonName, onTop );
	}

	/**
	 * simply changes the isVisible property of a BalloonPanelView to true, without
	 * changing the last position, updating the views (enabled or not) or closing
	 * other panels; it will be then shown where it was last shown
	 */
	displayOnLastPosition( balloonName ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		this.balloons[ balloonName ].isVisible = true;
	}

	_areAllComponentsDisabled( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return true;
		let components = Array.from( this.balloons[ balloonName ].content._items );
		if ( components.length <= 0 ) return true;
		for ( let view of components ) {
			if ( isComponentEnabled( this.editor, view.component ) ) return false;
		}
		return true;
	}

	_areAllViewsDisabled( balloonName ) {
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return true;
		let components = Array.from( this.balloons[ balloonName ].content._items );
		if ( components.length <= 0 ) return true;
		for ( let view of components ) {
			if ( view.isEnabled ) return false;
		}
		return true;
	}

	getPositionData( showAbove = false ) {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		let viewRange = viewDocument.selection.getFirstRange();
		// let target = view.domConverter.viewRangeToDom( viewRange );
		let target = null;
		try {
			target = view.domConverter.viewRangeToDom( viewRange );
		} catch ( e ) {
			console.warn( "Balloon position target not valid." );
			return null;
			// let element = ( viewRange && viewRange.start ? viewRange.start.nodeAfter : null )
			// || ( viewRange && viewRange.end ? viewRange.end.nodeBefore : null );
			// target = getElementNode( this.editor, element ) || getLiveSelectionNode()
			// || getLiveSelectionNode( false );
		}
		return this.createPositionData( target, showAbove );
	}

	getNodePositionData( node, showAbove = false ) {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		let domRange = document.createRange();
		domRange.setStart( node, 0 );
		domRange.setEnd( node, 1 );
		return this.createPositionData( domRange, showAbove );
	}

	createPositionData( range, showAbove = false ) {
		return {
			target: range,
			positions: showAbove ? this.positions.top : this.positions.base,
			limiter: this.positions.limiter
		};
	}

	_setArrow( balloon, show = false ) {
		balloon = String( balloon );
		if ( !this._isBalloonValid( balloon ) ) return;
		this.balloons[ balloon ].set( "withArrow", show );
	}

	hideArrow( balloon ) {
		this._setArrow( balloon, false );
	}

	showArrow( balloon ) {
		this._setArrow( balloon, true );
	}

	_getEditorDomRect() {
		if ( typeof this.editor.ui != "object" ||
			typeof this.editor.ui.view != "object" ||
			typeof this.editor.ui.view.editable != "object" ||
			!( this.editor.ui.view.editable.element instanceof HTMLElement ) ||
			typeof this.editor.ui.view.editable.element.getBoundingClientRect != "function" )
			return null;
		return this.editor.ui.view.editable.element.getBoundingClientRect();
	}

	vizualize( balloonName ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return;
		this._vizualize( this.balloons[ balloonName ] );
	}

	_vizualize( balloonPanel ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		balloonPanel.show();
		balloonPanel.isVisible = true;
		balloonPanel.set( "isVisible", true );
	}

	_showOnNode( balloonName, node ) {
		if ( this.readOnlyMode || this.isFrozen ) return false;
		balloonName = String( balloonName );
		if ( !this._isBalloonValid( balloonName ) ) return false;
		if ( !HtmHelper.isNode( node ) ) return false;
		let domRange = HtmHelper.createRangeOnNode( node );
		if ( !HtmHelper.isRange( domRange ) ) return false;
		let domRect = HtmHelper.getBoundingClientRect( node );
		if ( !HtmHelper.isDOMRect( domRect ) ) return false;
		let positionData = this.createPositionData( domRange, true );
		this.attachTo( balloonName, positionData, domRect );
		return true;
	}

	attachTo( balloon, options, nodeRect = null ) {
		if ( this.readOnlyMode || this.isFrozen ) return;
		let panel = this.balloons[ balloon ];
		this._vizualize( panel );

		const defaultPositions = BalloonPanelView.defaultPositions;
		const positionOptions = Object.assign( {}, {
			element: panel.element,
			positions: [
				defaultPositions.southArrowNorth,
				defaultPositions.southArrowNorthWest,
				defaultPositions.southArrowNorthEast,
				defaultPositions.northArrowSouth,
				defaultPositions.northArrowSouthWest,
				defaultPositions.northArrowSouthEast
			],
			limiter: defaultLimiterElement,
			fitInViewport: true
		}, options );

		let panelRect = panel.element.getBoundingClientRect();
		const targetRect = getDomSelectionRect();
		const editorRect = this._getEditorDomRect();
		if ( !editorRect ) {
			console.warn( "Could not get editor's editable element coordinates to place balloon panel." );
			assignDefaultPosition( panel, positionOptions );
			return;
		}

		if ( panelRect.width > editorRect.width ) {
			console.warn( "Balloon panel width is larger than editor width." );
			this.addFlexCss( panel.element, editorRect.width );
			panelRect = panel.element.getBoundingClientRect();
		} else if ( panelRect.width < editorRect.width ) {
			this.removeFlexCss( panel.element );
		}

		let optimalPosition = BalloonPanelView._getOptimalPosition( positionOptions );

		const left = calcLeft( {
			positionName: optimalPosition.name,
			defaultLeft: optimalPosition.left,
			editorLeft: editorRect.left,
			editorX: editorRect.x,
			editorRight: editorRect.right,
			editorWidth: editorRect.width,
			panelWidth: panelRect && typeof panelRect == "object" ? panelRect.width : 0,
			targetWidth: targetRect && typeof targetRect == "object" ? targetRect.width : 0,
			targetLeft: targetRect && typeof targetRect == "object" ? targetRect.left : 0,
			nodeWidth: nodeRect && typeof nodeRect == "object" ? nodeRect.width : 0
		} );

		const top = calcTop( {
			isAbove: isBalloonAbove( optimalPosition.name ),
			defaultTop: optimalPosition.top,
			editorTop: editorRect.top,
			editorY: editorRect.y,
			editorHeight: editorRect.height,
			editorBottom: editorRect.bottom,
			extraSpacing: this.spacing,
			panelHeight: panelRect.height
		} );

		const position = optimalPosition.name;

		Object.assign( panel, { top, left, position } );
	}

}

export function getDomSelectionRect() {
	const windowSelection = document.getSelection();
	return typeof windowSelection.rangeCount == "number" &&
		windowSelection.rangeCount > 0 && typeof windowSelection.getRangeAt == "function" ?
		new Rect( windowSelection.getRangeAt( 0 ) ) : null;
}

export function assignDefaultPosition( panel, positionOptions ) {
	const optimalPosition = BalloonPanelView._getOptimalPosition( positionOptions );
	const top = parseInt( Math.max( optimalPosition.top, 0 ) );
	const left = parseInt( Math.max( optimalPosition.left, 0 ) );
	const position = optimalPosition.name;
	Object.assign( panel, { top, left, position } );
}

export function calcTop( measurements ) {
	const defaultTop = measurements.defaultTop;
	const editorTop = measurements.editorTop || measurements.editorY;
	const editorY = measurements.editorY || measurements.editorTop;
	const editorHeight = measurements.editorHeight;
	const editorBottom = measurements.editorBottom;
	const extraSpacing = measurements.extraSpacing;
	const panelHeight = measurements.panelHeight;
	const scrollTop = window.pageYOffset || ( document.documentElement ?
		document.documentElement.scrollTop : null ) || 0;

	let top = parseInt( extraSpacing == 0 ? defaultTop :
		measurements.isAbove ? ( defaultTop - extraSpacing < editorTop + scrollTop ?
			editorTop + scrollTop : defaultTop - extraSpacing < editorY + scrollTop ?
			editorY + scrollTop : defaultTop - extraSpacing ) :
		( defaultTop + extraSpacing + panelHeight > editorBottom + scrollTop ?
			editorBottom - panelHeight + scrollTop :
			defaultTop + extraSpacing + panelHeight >
			editorY + editorHeight + scrollTop ?
			editorY + editorHeight - panelHeight + scrollTop :
			defaultTop + extraSpacing ) );

	top < editorTop + scrollTop ? top = editorTop + scrollTop :
		top < editorY + scrollTop ? top = editorY + scrollTop : void 0;

	top + panelHeight > editorTop + editorHeight + scrollTop ?
		top = editorTop + editorHeight + scrollTop - panelHeight :
		top + panelHeight > editorY + editorHeight + scrollTop ?
		top = editorY + editorHeight + scrollTop - panelHeight : void 0;

	return top < 0 ? 0 : top;
}

export function calcLeft( measurements ) {

	let defaultLeft = measurements.defaultLeft;
	const editorLeft = measurements.editorLeft || measurements.editorX;
	const editorX = measurements.editorX || measurements.editorLeft;
	const editorRight = measurements.editorRight;
	const editorWidth = measurements.editorWidth ||
		measurements.editorRight - measurements.editorLeft ||
		measurements.editorRight - measurements.editorX;
	const panelWidth = measurements.panelWidth;
	const targetWidth = measurements.targetWidth;
	const targetLeft = measurements.targetLeft;
	const nodeWidth = measurements.nodeWidth;

	const where = getHorizontalBalloonPosition( measurements.positionName );
	const isBefore = parseInt( defaultLeft + ( where == "left" ? 25 :
			where == "right" ? panelWidth - 25 : panelWidth * 0.5 ) ) <
		parseInt( targetLeft + targetWidth ) ? 1 : -1;

	defaultLeft += 0.5 * ( nodeWidth && nodeWidth < editorWidth ?
		nodeWidth : targetWidth * isBefore );

	return parseInt( defaultLeft < editorX ? editorX + 1 :
		defaultLeft < editorLeft ? editorLeft + 1 :
		defaultLeft + panelWidth > editorRight ?
		editorRight - panelWidth :
		defaultLeft + panelWidth > editorX + editorWidth ?
		editorX + editorWidth - panelWidth :
		defaultLeft );
}

export function isBalloonAbove( positionName ) {
	return typeof positionName == "string" &&
		positionName.indexOf( "s" ) > 0;
}

function getHorizontalBalloonPosition( positionName ) {
	return typeof positionName != "string" ? "" :
		positionName.endsWith( "e" ) ? "right" :
		positionName.endsWith( "w" ) ? "left" : "center"
}

export function isComponentEnabled( editor, component ) {
	component = String( component );
	if ( component == "undo" || component == "redo" ) return true;
	if ( editor && editor.commands && editor.commands.get( component ) && editor.commands.get( component ).isEnabled ) return true;
	component = component.toLowerCase();
	if ( !editor || !editor.ui || !editor.ui.componentFactory || !editor.ui.componentFactory._components ||
		!editor.ui.componentFactory._components.get( component ) || !editor.ui.componentFactory._components.get( component ).callback() ||
		!editor.ui.componentFactory._components.get( component ).callback().isEnabled ) {
		console.log( component.concat( " is disabled" ) );
		return false;
	}
	return true;
}

function isViewEnabled( editor, view ) {
	if ( !view ) return false;
	if ( view.isEnabled ) return true;
	if ( !view.component ) return false;
	if ( editor && editor.commands && editor.commands.get( view.component ) &&
		editor.commands.get( view.component ).isEnabled ) return true;
	return false;
}

function removeDuplicates( arr ) {
	for ( let i = 0; i < arr.length; i++ ) {
		for ( let k = 0; k < i; k++ ) {
			if ( String( arr[ i ] ).toLowerCase() == String( arr[ k ] ).toLowerCase() ) {
				arr.splice( i, 1 );
				i--;
				break;
			}
		}
	}
	return arr;
}

function getBalloonPosition( rects, onTop ) {
	const panelRect = rects.panel;
	const editorRect = rects.editor;
	const targetRect = rects.target;
	const nodeRect = rects.node;

	const scrollTop = window.pageYOffset || ( document.documentElement ?
		document.documentElement.scrollTop : null ) || 0;

	if ( nodeRect ) {
		return;
	}

	let horizontalArrowPosition = editorRect.left + panelRect.width * 0.5 >
		targetRect.left + targetRect.width * 0.5 ? "left" :
		editorRect.left + editorRect.width - panelRect.width * 0.5 <
		targetRect.left + targetRect.width * 0.5 ? "right" : "center";

	let actuallyOnTop = onTop && targetRect.top - ARROW_HEIGHT - panelRect.height <
		editorRect.top ? false :
		!onTop && targetRect.top + targetRect.height + ARROW_HEIGHT + panelRect.height >
		editorRect.top + editorRect.height ? true : onTop;

	let top = actuallyOnTop ? targetRect.top - ARROW_HEIGHT - panelRect.height :
		targetRect.top + targetRect.height + ARROW_HEIGHT;

	let left = horizontalArrowPosition == "left" ?
		targetRect.left + targetRect.width * 0.5 - 25 :
		horizontalArrowPosition == "right" ?
		targetRect.left + targetRect.width * 0.5 + 25 - panelRect.width :
		targetRect.left + targetRect.width * 0.5;

	left < editorRect.left ? left = editorRect.left : void 0;
	left + panelRect.width > editorRect.left + editorRect.width ?
		left = editorRect.left + editorRect.width - panelRect.width : void 0;

	let arrow = actuallyOnTop ? "arrow_s" : "arrow_n";
	arrow = horizontalArrowPosition == "left" ? arrow + "w" :
		horizontalArrowPosition == "right" ? arrow + "e" : arrow;

	return { top, left, arrow };
}
