import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Alignment from '../iconsui/alignment';
import Validator from '../pisautils/validator';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import {
	createButton,
	defineButton,
	addClasses,
	closeDropdownOnBlur,
	getLiveAttributeValue
} from '../utils';
import {
	alignLeftIcon,
	alignRightIcon,
	alignCenterIcon,
	alignJustifyIcon
} from '../icons';
import {
	alignmentLeft,
	alignmentRight,
	alignmentCenter,
	alignmentJustify
} from '../commandnames';

export const ALIGNMENT = "pisaAlignment";

export default class PisaAlignmentUI extends Plugin {

	static get requires() {
		return [ Alignment ];
	}

	init() {
		const editor = this.editor;
		const command = editor.commands.get( "bold" );

		editor.ui.componentFactory.add( ALIGNMENT, locale => {
			let dropdownView = createDropdown( editor.locale );
			dropdownView.set( 'panelPosition', 'se' );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			defineButton( dropdownView.buttonView, alignLeftIcon,
				editor.objects.tooltips.getT( ALIGNMENT ) );
			dropdownView.bind( 'isEnabled' ).to( command );

			closeDropdownOnBlur( dropdownView );
			this._addAlignmentComponent( alignmentLeft, alignLeftIcon, dropdownView );
			this._addAlignmentComponent( alignmentRight, alignRightIcon, dropdownView );
			this._addAlignmentComponent( alignmentCenter, alignCenterIcon, dropdownView );
			this._addAlignmentComponent( alignmentJustify, alignJustifyIcon, dropdownView );

			editor.editing.view.document.on( 'selectionChangeDone', () => {
				if ( !Validator.isObjectPath( editor,
						"editor.model.document.selection.anchor.parent" ) ||
					!Validator.isMap( editor.model.document.selection.anchor.parent._attrs ) ||
					!editor.model.document.selection.anchor.parent._attrs.get( "alignment" ) )
					return dropdownView.buttonView.icon = alignLeftIcon;
				let alignment = editor.model.document.selection.anchor.parent._attrs.get( "alignment" );
				dropdownView.buttonView.icon = alignment == "right" ? alignRightIcon :
					alignment == "center" ? alignCenterIcon : alignment == "justify" ?
					alignJustifyIcon : alignLeftIcon;
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, ALIGNMENT );

			return dropdownView;
		} );
	}

	_addAlignmentComponent( name, icon, dropdownView ) {
		let component = this.editor.ui.componentFactory.create( name );
		component.on( "execute", () => {
			dropdownView.buttonView.icon = icon;
			this.editor.objects.focus.doFocus();
		} );
		dropdownView.panelView.children.add( component );
	}
}
