import Validator from '../pisautils/validator';

import PlaceholderValuesUI from './pisaplaceholdervalues/placeholdervaluesui';
import PlaceholderPositionHelper from './pisaplaceholdervalues/placeholderpositionhelper';
import PlaceholderToTextTransformer from './pisaplaceholdervalues/placeholdertotexttransformer';
import PlaceholderViewHighlighter from './pisaplaceholdervalues/placeholderviewhighlighter';
import PlaceholderChangesRegistrator from './pisaplaceholdervalues/placeholderchangesregistrator';
import PlaceholderChangesHanlder from './pisaplaceholdervalues/placeholderchangeshandler';
import PlaceholderEventManager from './pisaplaceholdervalues/placeholdereventmanager';
import UndoStepsHandler from './pisaplaceholdervalues/undostepshandler';

export const TOGGLE_PLACEHOLDER_EDITING = "togglePlaceholderEditing";
export const ENABLE_PLACEHOLDER_EDITING = "enablePlaceholderEditing";
export const DISABLE_PLACEHOLDER_EDITING = "disablePlaceholderEditing";

export default class PisaPlaceholderValuesUI extends PlaceholderValuesUI {

	constructor( parent ) {

		// console.log( "PisaPlaceholderValuesUI" );

		super( parent );

		this.attachments = [];

		// new PlaceholderPositionHelper( this );
		// new PlaceholderToTextTransformer( this );
		// new PlaceholderViewHighlighter( this );
		// new PlaceholderChangesRegistrator( this );
		// new PlaceholderChangesHanlder( this );
		// new PlaceholderEventManager( this );
		// new UndoStepsHandler( this );

		this.attachments.push( new PlaceholderPositionHelper( this ) );
		this.attachments.push( new PlaceholderToTextTransformer( this ) );
		this.attachments.push( new PlaceholderViewHighlighter( this ) );
		this.attachments.push( new PlaceholderChangesRegistrator( this ) );
		this.attachments.push( new PlaceholderChangesHanlder( this ) );
		this.attachments.push( new PlaceholderEventManager( this ) );
		this.attachments.push( new UndoStepsHandler( this ) );

	}

	_sayHi() {
		this.attachments.forEach( attachment => {
			// attachment._sayHi();
			console.log( attachment );
		} );
	}

}
