
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaFire from '../../plugins/pisafire/pisafire';
import { PISA_FIRE } from '../../plugins/pisafire/pisafirecommand';
import { createButton, enableAndExecute } from '../../plugins/utils';
import { imageIcon } from '../../plugins/icons';

export const TOUCH_INSERT_IMAGE = "touchInsertImage";

export default class InsertImage extends Plugin {

  static get requires() {
		return [ PisaFire ];
	}

  init() {
    const editor = this.editor;
    const command = editor.commands.get( "bold" );

    editor.ui.componentFactory.add( TOUCH_INSERT_IMAGE, locale => {
      let imageButton = createButton( imageIcon, "Insert image", editor.locale );
      imageButton.bind( 'isEnabled' ).to( command );
      imageButton.on( "execute", () => {
        editor.balloons.hideAll();
        enableAndExecute( editor, PISA_FIRE, { event: TOUCH_INSERT_IMAGE } );
      } );
      return imageButton;
    } );
  }
}
