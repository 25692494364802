import { DELETEALL } from './pisadeleteall/pisadeleteall';
import { REMOVEFORMAT } from './pisaremoveformat/pisaremoveformatcommand';
import { COPYFORMAT } from './pisacopyformat/pisacopyformatcommand';
import { INDENT } from './pisaindent/pisaindentcommand';
import { BACKGROUND_COLOR } from './pisabckgcolor/pisabckgediting';
import { INSERT_IMAGE } from './pisaimage/pisaimageui';
import { EDITTABLE } from './pisatable/pisatableui';
import { PLAINTEXT } from './pisaplaintext/pisaplaintext';
import { PRINTCONTENT } from './pisaprint/pisaprint';
import { PISASPACING } from './pisaspacing/pisaspacingediting';
import { SPELLCHECK } from './pisaspellcheck/pisaspellcheckcommand';
import { SUBSCRIPT } from './pisasubscript/pisasubscriptediting';
import { SUPERSCRIPT } from './pisasuperscript/pisasuperscriptediting';
import { PISAOBJECT } from './pisaobjectlink/pisaobjectlinkcommand';
import { PISASEARCH } from './pisasearch/pisasearch';
import { REMOVE_ALL_MARKS } from './pisasearch/pisaremoveallmarkcommand';
import { PISA_RESIZE_IMAGE } from './pisaimageresize/pisaimageresizecommand';
import { CONTINUE_LIST } from './pisalist/pisalistediting';
import { START_LIST } from './pisalist/pisalistediting';
import { NUMBERED_LIST_DROPDOWN } from './pisalist/pisalistui';
import { CHANGE_TOOLBAR } from './pisatoolbar/pisachangetoolbarcommand';
import { PLACE_BUTTON } from './pisatoolbar/pisaplacebuttoncommand';
import { SWITCH_VISIBILITY } from './pisatoolbar/pisaswitchvisibilitycommand';
import { PISA_CELL } from './pisatablecell/pisacellcommand';
import { SET_DATA } from './pisasetdata/pisasetdata';
import { SELECTALL } from './pisadeleteall/pisaselectallcommand';
import { TOUCH_INSERT_IMAGE } from '../touch_plugins/insertimage/insertimage';
import { EMOJI } from './pisaemoji/pisaemoji';
import { MOVE_IMAGE } from './pisaimagemove/pisaimagemovecommand';
import { MAXIMIZE_EDITOR } from './pisamaximize/pisamaximize';
import { ALIGNMENT } from './pisaalignment/pisaalignmentui';
import { FEATURES } from './pisafeatures/pisafeaturesui';
import { COPY } from './pisaclipboard/pisacopy';
import { CUT } from './pisaclipboard/pisacut';
import { PASTE, CLIPBOARD_PASTE } from './pisaclipboard/pisapaste';
import { CLIPBOARD } from './pisaclipboard/pisaclipboardui';
import { INCREASE_IMAGE, DECREASE_IMAGE, DELETE_IMAGE } from './pisaimageresize/pisaimageresize';
import { PLACEHOLDER } from './pisaplaceholder/pisaplaceholderui';
import { DISABLEBUTTONS } from './pisaplaintext/pisadisablebuttonscommand';;
import { PISA_FIRE } from './pisafire/pisafirecommand';
import { PARAGRAPH_BEFORE, PARAGRAPH_AFTER, INSERT_PARAGRAPH } from './pisainsertparagraph/pisainsertparagraphui';
import { FONT_COLOR_COMPACT } from './pisafontcolor/pisacolorcompactui';
import { BACKGROUND_COLOR_COMPACT } from './pisabckgcolor/pisabckgcompactui';
import { FONT_COLOR_TOUCH } from './pisafontcolor/pisacolortouchui';
import { FONT_BACKGROUND_TOUCH } from './pisabckgcolor/pisabckgtouchui';
import { INSERT_OBJECT_LINK } from './pisaobjectlink/pisainsertobjectlinkcommand';
import { NUMBERED_LIST, BULLETED_LIST } from './iconsui/listui';
import { PISA_IMAGE_LINK } from './pisalink/pisalinkui';

export const deleteAll = DELETEALL;
export const removeFormat = REMOVEFORMAT;
export const copyFormat = COPYFORMAT;
export const backgroundColor = BACKGROUND_COLOR;
export const insertImage = INSERT_IMAGE;
export const increaseIndent = INDENT + ":p";
export const decreaseIndent = INDENT + ":m";
export const editTable = EDITTABLE;
export const plainText = PLAINTEXT;
export const printContent = PRINTCONTENT;
export const lineSpacing = PISASPACING;
export const spellcheck = SPELLCHECK;
export const subscript = SUBSCRIPT;
export const superscript = SUPERSCRIPT;
export const pisaObject = PISAOBJECT;
export const search = PISASEARCH;
export const resizeImage = PISA_RESIZE_IMAGE;
export const continueList = CONTINUE_LIST;
export const startList = START_LIST;
export const numberedListDropdown = NUMBERED_LIST_DROPDOWN;
export const changeToolbar = CHANGE_TOOLBAR;
export const placeButton = PLACE_BUTTON;
export const switchVisibility = SWITCH_VISIBILITY;
export const tableCell = PISA_CELL;
export const setData = SET_DATA;
export const selectAll = SELECTALL;
export const fontSize = "fontSize";
export const fontFamily = "fontFamily";
export const fontColor = "fontColor";
export const insertTable = "insertTable";
export const undo = "undo";
export const redo = "redo";
export const bold = "bold";
export const italic = "italic";
export const underline = "underline";
export const strikethrough = "strikethrough";
export const link = "link";
export const blockQuote = "blockQuote";
export const code = "code";
export const enter = "enter";
export const shiftEnter = "shiftEnter";
export const input = "input";
export const doDelete = "delete";
export const forwardDelete = "forwardDelete";
export const numberedList = NUMBERED_LIST;
export const bulletedList = BULLETED_LIST;
export const alignmentLeft = "alignment:left";
export const alignmentRight = "alignment:right";
export const alignmentCenter = "alignment:center";
export const alignmentJustify = "alignment:justify";
export const defaultAlignment = "alignment";
export const touchInsertImage = TOUCH_INSERT_IMAGE;
export const emoji = EMOJI;
export const moveImage = MOVE_IMAGE;
export const maximize = MAXIMIZE_EDITOR;
export const alignment = ALIGNMENT;
export const features = FEATURES;
export const paste = PASTE;
export const touchPaste = CLIPBOARD_PASTE;
export const copy = COPY;
export const cut = CUT;
export const clipboard = CLIPBOARD;
export const increaseImage = INCREASE_IMAGE;
export const decreaseImage = DECREASE_IMAGE;
export const deleteImage = DELETE_IMAGE;
export const placeholder = PLACEHOLDER;
export const disableButtons = DISABLEBUTTONS;
export const fire = PISA_FIRE;
export const removeAllMarks = REMOVE_ALL_MARKS;
export const addParagraph = INSERT_PARAGRAPH;
export const paragraphBefore = PARAGRAPH_BEFORE;
export const paragraphAfter = PARAGRAPH_AFTER;
export const fontColorCompact = FONT_COLOR_COMPACT;
export const backgroundColorCompact = BACKGROUND_COLOR_COMPACT;
export const touchColorCompact = FONT_COLOR_TOUCH;
export const touchBackgroundCompact = FONT_BACKGROUND_TOUCH;
export const insertPisaObject = INSERT_OBJECT_LINK;
export const pisaImageLink = PISA_IMAGE_LINK;
