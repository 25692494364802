import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import PisaBasics from '../pisabasics/pisabasics';
import Images from './images';
import { downcastStyleTagAttr, getObjectProto } from '../utils';
import HtmHelper from '../pisautils/htmhelper';
import './theme/pisaimage.css';
import Validator from '../pisautils/validator';

export const PISA_IMG_MODEL = "pisaImage";
export const WIDTH = "width";
export const HEIGHT = "height";
export const LENGTH_WITH_PRIORITY = WIDTH;
const MODEL_STYLE_WIDTH = "styleWidth";
const MODEL_STYLE_HEIGHT = "styleHeight";
const MIN_WIDTH = "min-width";
const MAX_WIDTH = "max-width";
const MIN_HEIGHT = "min-height";
const MAX_HEIGHT = "max-height";
const IMG_TAG = "img";
const SRC = "src";
const ORIGINAL_SOURCE = "data-original-source";
const ORIGINAL_DESCRIPTION = "data-original-alt";
const ON_MOUSE_OVER = "onMouseOver"
const ON_MOUSE_OUT = "onMouseOut";
const ON_CLICK = "onClick";
const MOUSE_OVER_FUNCTION = "pisasales.cke.images.mouseover(this);";
const MOUSE_OUT_FUNCTION = "pisasales.cke.images.mouseout(this);";
const ATTRIBUTES = [ 'alt', 'id', SRC, WIDTH, HEIGHT, 'class', 'style',
	ON_MOUSE_OVER, ON_MOUSE_OUT, ON_CLICK
];
const ADDITIONAL_ATTRIBUTES = [ "border", "linkHref", ORIGINAL_SOURCE,
	ORIGINAL_DESCRIPTION, MODEL_STYLE_HEIGHT, MODEL_STYLE_WIDTH, MIN_WIDTH,
	MAX_WIDTH, MIN_HEIGHT, MAX_HEIGHT
];

export default class PisaImageEditing extends Plugin {

	static get requires() {
		return [ PisaBasics, Images ];
	}

	init() {
		const upcastWriter = new UpcastWriter();
		const editor = this.editor;
		const conversion = editor.conversion;
		// const dataProcessor = editor.data.processor || editor.getPsaDP();

		editor.on( "beforeSetContent", () => {
			editor.objects.images.cleanMap();
		}, { priority: Number.MIN_SAFE_INTEGER } );

		editor.on( "editorContentSet", () => {
			editor.objects.images.setAllDimensions();
		}, { priority: Number.MIN_SAFE_INTEGER } );

		editor.model.schema.register( PISA_IMG_MODEL, {
			allowIn: [ "$block", "$clipboardHolder", "font", "blockQuote" ],
			// some of the attributes are not in the main list so they are
			// not being converted by "default converters" (see below)
			allowAttributes: ATTRIBUTES.concat( ADDITIONAL_ATTRIBUTES )
		} );

		// default converters only for attributes in the "main list"
		for ( let attribute of ATTRIBUTES ) {
			// "src" and "alt" attributes downcast is handled separately
			if ( attribute != SRC && attribute != "alt" )
				conversion.for( 'downcast' ).attributeToAttribute( {
					model: {
						name: PISA_IMG_MODEL,
						key: attribute
					},
					view: attribute
				} );

			// "width" and "height" attributes upcast is handled separately
			if ( attribute == WIDTH || attribute == HEIGHT ) continue;
			conversion.for( 'upcast' ).attributeToAttribute( {
				view: {
					name: IMG_TAG,
					key: attribute
				},
				model: attribute
			} );
		}

		addSpecificConverters( editor );

		conversion.for( 'upcast' ).elementToElement( {
			view: IMG_TAG,
			model: ( viewElement, modelWriter ) => {
				// if ( !imageDescriptionIsValid( dataProcessor, viewElement ) ) return;

				let wrapInParagraph = parentHasNoOtherTypeChildren( viewElement ) ||
					imageParentIsDocumentFragment( viewElement ) ||
					imageParentIsBlockQuote( viewElement );

				editor.objects.images.updateMap();

				const id = HtmHelper.generateImageId();
				upcastWriter.setAttribute( "id", id, viewElement );

				let width = viewElement.getAttribute( WIDTH ) || viewElement.getStyle( WIDTH );
				let height = viewElement.getAttribute( HEIGHT ) || viewElement.getStyle( HEIGHT );
				removeLengthWithSmallerPriority( viewElement, upcastWriter, width, height );

				// manage image source attributes, including "src" and "data-original-source"
				let source = viewElement.getAttribute( SRC );
				let alternativeSource = viewElement.getAttribute( ORIGINAL_SOURCE );
				if ( !isValidStrAttibute( alternativeSource ) ||
					!alternativeSource.startsWith( "http" ) )
					alternativeSource = void 0;
				if ( onlyFirstAttributeIsValid( source, alternativeSource ) ) {
					alternativeSource = source;
					upcastWriter.setAttribute( ORIGINAL_SOURCE, source, viewElement );
				}
				if ( onlyFirstAttributeIsValid( alternativeSource, source ) ) {
					upcastWriter.setAttribute( SRC, alternativeSource, viewElement );
				}

				// manage image description attributes, including "alt" and "data-original-alt"
				let description = viewElement.getAttribute( "alt" );
				let alternativeDescription = viewElement.getAttribute( ORIGINAL_DESCRIPTION );
				if ( !isValidStrAttibute( alternativeDescription ) )
					alternativeDescription = void 0;
				if ( onlyFirstAttributeIsValid( description, alternativeDescription ) ) {
					alternativeDescription = description;
					upcastWriter.setAttribute( ORIGINAL_DESCRIPTION, description, viewElement );
				}
				if ( onlyFirstAttributeIsValid( alternativeDescription, description ) ) {
					upcastWriter.setAttribute( "alt", alternativeDescription, viewElement );
				}

				// we finally create the image model element
				let image = modelWriter.createElement( PISA_IMG_MODEL );

				if ( viewElement.getAttribute( WIDTH ) ||
					viewElement.getStyle( WIDTH ) ) {
					setModelImageElementWidth( image, modelWriter, normalizeLength( width ) );
					// modelWriter.setAttribute( WIDTH, width, image );
				}
				if ( viewElement.getAttribute( HEIGHT ) ||
					viewElement.getStyle( HEIGHT ) ) {
					setModelImageElementHeight( image, modelWriter, normalizeLength( height ) );
					// modelWriter.setAttribute( HEIGHT, height, image );
				}

				// this sequence prioritizes the original source over the actual source
				// if ( alternativeSource ) {
				// 	modelWriter.setAttribute( ORIGINAL_SOURCE, alternativeSource, image );
				// 	modelWriter.setAttribute( SRC, alternativeSource, image );
				// }
				if ( source ) {
					modelWriter.setAttribute( SRC, source, image );
					alternativeSource ? modelWriter.setAttribute( ORIGINAL_SOURCE, alternativeSource, image ) :
						modelWriter.setAttribute( ORIGINAL_SOURCE, source, image );
				}

				if ( alternativeDescription ) {
					modelWriter.setAttribute( ORIGINAL_DESCRIPTION, alternativeDescription, image );
					isValidStrAttibute( description ) ?
						modelWriter.setAttribute( "alt", description, image ) :
						modelWriter.setAttribute( "alt", alternativeDescription, image );
				}

				modelWriter.setAttribute( "border", "5px solid transparent", image );
				modelWriter.setAttribute( "id", id, image );
				editor.objects.images.map.set( id, image );

				if ( !wrapInParagraph ) return image;

				const idw = this._getIdw();
				modelWriter.setAttribute( ON_MOUSE_OVER, MOUSE_OVER_FUNCTION, image );
				modelWriter.setAttribute( ON_CLICK, `pisasales.cke.images.click(this, "${
					viewElement.getAttribute( 'id' ) }", "${ idw }");`, image );
				modelWriter.setAttribute( ON_MOUSE_OUT, MOUSE_OUT_FUNCTION, image );
				let paragraph = modelWriter.createElement( "paragraph" );
				modelWriter.insert( image, paragraph, "end" );
				return paragraph;
			}
		} );

		conversion.for( 'downcast' ).elementToElement( {
			model: PISA_IMG_MODEL,
			view: ( modelElement, viewWriter ) => {
				// if ( !imageDescriptionIsValid( dataProcessor, modelElement ) ) return;

				const idw = this._getIdw();

				// this sequence prioritizes the original source over the actual source
				// let src = modelElement.getAttribute( ORIGINAL_SOURCE ) ||
				// 	modelElement._attrs.get( ORIGINAL_SOURCE ) ||
				// 	modelElement.getAttribute( SRC ) || modelElement._attrs.get( SRC );

				let src = modelElement.getAttribute( SRC ) ||
					modelElement._attrs.get( SRC ) ||
					modelElement.getAttribute( ORIGINAL_SOURCE ) ||
					modelElement._attrs.get( ORIGINAL_SOURCE );

				let alt = modelElement.getAttribute( "alt" ) ||
					modelElement._attrs.get( "alt" );
				if ( !isValidStrAttibute( alt ) )
					alt = modelElement.getAttribute( ORIGINAL_DESCRIPTION ) ||
					modelElement._attrs.get( ORIGINAL_DESCRIPTION );

				let width = modelElement.getAttribute( WIDTH ) ||
					modelElement._attrs.get( WIDTH );
				let height = modelElement.getAttribute( HEIGHT ) ||
					modelElement._attrs.get( HEIGHT );

				let img = viewWriter.createEmptyElement( IMG_TAG );

				if ( src ) viewWriter.setAttribute( SRC, src, img );
				if ( alt ) viewWriter.setAttribute( "alt", alt, img );

				if ( width && LENGTH_WITH_PRIORITY == WIDTH )
					viewWriter.setStyle( WIDTH, width, img );
				if ( height && LENGTH_WITH_PRIORITY == HEIGHT )
					viewWriter.setStyle( HEIGHT, height, img );

				viewWriter.setAttribute( ON_MOUSE_OVER, MOUSE_OVER_FUNCTION, img );
				viewWriter.setAttribute( ON_CLICK, `pisasales.cke.images.click(this, "${
						modelElement.getAttribute( 'id' ) }", "${ idw }");`, img );
				viewWriter.setAttribute( ON_MOUSE_OUT, MOUSE_OUT_FUNCTION, img );
				img.on( "change:isFocused", () => {
					console.log( "image became focused" );
				} );
				return img;
			}
		} );

		// editor.conversion.for( 'dataDowncast' ).attributeToElement( {
		// 	model: {
		// 		name: "pisaImage",
		// 		key: "linkHref"
		// 	},
		// 	view: ( modelElement, something, somethingElse ) => {
		// 		console.log( modelElement );
		// 		console.log( something );
		// 		console.log( somethingElse );
		// 		return null;
		// 	}
		// } );

	}

	destroy() {
		super.destroy();
		delete this.editor;
	}

	_getIdw() {
		return !!this.editor && typeof this.editor == "object" ?
			this.editor.wdgId : void 0;
	}
}


function addSpecificConverters( editor ) {
	//separate handling for specific attributes conversion
	const conversion = editor.conversion;
	// upcast the "border" attribute
	conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: IMG_TAG,
			key: "border"
		},
		model: "border"
	} );
	// downcast the "border" attribute
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, "border", "border" );

	// upcast the "data-original-source" attribute to "src" attribute
	// conversion.for( 'upcast' ).attributeToAttribute( {
	// 	view: {
	// 		name: IMG_TAG,
	// 		key: ORIGINAL_SOURCE
	// 	},
	// 	model: SRC
	// } );

	conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: IMG_TAG,
			key: ORIGINAL_SOURCE
		},
		model: ORIGINAL_SOURCE
	} );

	// downcast the "data-original-source" attribute
	conversion.for( 'downcast' ).attributeToAttribute( {
		model: {
			name: PISA_IMG_MODEL,
			key: ORIGINAL_SOURCE
		},
		view: ORIGINAL_SOURCE
	} );

	// // upcast the "data-original-alt" attribute to "alt" attribute
	// conversion.for( 'upcast' ).attributeToAttribute( {
	// 	view: {
	// 		name: IMG_TAG,
	// 		key: ORIGINAL_DESCRIPTION
	// 	},
	// 	model: "alt"
	// } );

	// downcast the "data-original-alt" attribute
	conversion.for( 'downcast' ).attributeToAttribute( {
		model: {
			name: PISA_IMG_MODEL,
			key: ORIGINAL_DESCRIPTION
		},
		view: ORIGINAL_DESCRIPTION
	} );

	// upcast "height" attribute
	upcastLength( editor, HEIGHT );
	// upcast "width" attribute
	upcastLength( editor, WIDTH );
	// downcast "styleHeight" into style="height:"
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MODEL_STYLE_HEIGHT, HEIGHT );
	// downcast "styleWidth" into style="width:"
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MODEL_STYLE_WIDTH, WIDTH );

	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MIN_WIDTH );
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MAX_WIDTH );
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MIN_HEIGHT );
	downcastStyleTagAttr( editor, PISA_IMG_MODEL, MAX_HEIGHT );
}

export function findMatchingImage( editor, node ) {
	let alt = node.getAttribute( "alt" );
	let src = node.getAttribute( SRC );
	let matchingElements = new Map();
	editor.objects.images.map.forEach( ( image, idKey, map ) => {
		image._attrs.get( "alt" ) == alt && image._attrs.get( SRC ) == src ?
			matchingElements.set( idKey, image ) : void 0;
	} );
	if ( matchingElements.size > 1 ) {
		console.warn( "Too many matches for the image with id [".concat( node.getAttribute( "id" ) )
			.concat( "]. None of them is saved under the right id / key under editor.objects.images." ) );
		return null;
	}
	if ( matchingElements.size <= 0 ) {
		console.warn( "No matches for the image with id [".concat( node.getAttribute( "id" ) )
			.concat( "] in editor.objects.images." ) );
		return null;
	}
	let imageElement = matchingElements.values().next().value;
	editor.objects.images.map.set( imageElement._attrs.get( "id" ), imageElement );
	editor.objects.images.map.delete( matchingElements.keys().next().value );
	// Number( imageElement._attrs.get( "id" ).replace( /\D+/g, "" ) ) > editor.counters.imageId ?
	// 	editor.counters.imageId = Number( imageElement._attrs.get( "id" ).replace( /\D+/g, "" ) ) + 1 : void 0;
	return imageElement;
}

function upcastLength( editor, viewAttribute = "", modelKey = "" ) {
	if ( !editor || ( !viewAttribute && !modelKey ) ) return;
	!viewAttribute ? viewAttribute = modelKey : void 0;
	!modelKey ? modelKey = viewAttribute : void 0;
	let styles = {};
	styles[ viewAttribute ] = new RegExp( "[\\S]+" );

	editor.conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: IMG_TAG,
			styles: styles
		},
		model: {
			key: modelKey,
			value: ( viewElement ) => {
				if ( !viewElement || typeof viewElement != "object" ) return null;
				let value = getStyleFromMap( viewElement._styles, viewAttribute ) ||
					getAttributeFromStyle( viewElement.getAttribute( "style" ), viewAttribute );
				if ( !value ) return null;
				value = normalizeLength( value );
				if ( viewAttribute == LENGTH_WITH_PRIORITY ) return value;
				// next it should be prevented that both width and height are set on an
				// image, so if it has both only the one with priority should be taken
				let otherAttribute = viewAttribute == HEIGHT ? WIDTH : HEIGHT;
				let otherValue = getStyleFromMap( viewElement._styles, otherAttribute ) ||
					getAttributeFromStyle( viewElement.getAttribute( "style" ), otherAttribute ) ||
					getStyleFromMap( viewElement._attrs, otherAttribute ) ||
					viewElement.getAttribute( otherAttribute );
				return otherValue ? null : value;
			}
		}
	} );

	editor.conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: IMG_TAG,
			key: viewAttribute
		},
		model: {
			key: modelKey,
			value: ( viewElement ) => {
				if ( !viewElement || typeof viewElement != "object" ) return null;
				let value = getStyleFromMap( viewElement._attrs, viewAttribute ) ||
					viewElement.getAttribute( viewAttribute );
				if ( !value ) return null;
				value = normalizeLength( value );
				if ( viewAttribute == LENGTH_WITH_PRIORITY ) return value;
				// next it should be prevented that both width and height are set on an
				// image, so if it has both only the one with priority should be taken
				let otherAttribute = viewAttribute == HEIGHT ? WIDTH : HEIGHT;
				let otherValue = getStyleFromMap( viewElement._attrs, otherAttribute ) ||
					viewElement.getAttribute( otherAttribute ) ||
					getStyleFromMap( viewElement._styles, otherAttribute ) ||
					getAttributeFromStyle( viewElement.getAttribute( "style" ), otherAttribute );
				return otherValue ? null : value;
			}
		}
	} );
}

function getStyleFromMap( stylesMap, attribute ) {
	if ( !( stylesMap instanceof Map ) || stylesMap.size < 1 ) return;
	if ( typeof attribute != "string" || attribute == "" ) return;
	let value = stylesMap.get( attribute );
	return ( typeof value != "string" || value == "" ) ? void 0 : value;
}

function getAttributeFromStyle( style, attribute ) {
	if ( typeof style != "string" || style == "" ) return;
	if ( typeof attribute != "string" || attribute == "" ) return;
	let index = style.indexOf( attribute );
	if ( index < 0 || index + attribute.length + 1 > style.length ) return;
	let value = style.slice( index + attribute.length );
	if ( value.startsWith( ":" ) ) value = value.slice( 1 );
	if ( value.indexOf( ";" ) > 0 ) value = value.slice( 0, value.indexOf( ";" ) );
	return value;
}

function cleanToDigits( value ) {
	if ( typeof value != "string" || value == "" ) return "";
	value = value.replace( /[\,]+/g, "." ).replace( /[^\d\.]+/g, "" )
		.replace( /[\.]+/g, "." );
	while ( value.startsWith( "." ) ) {
		value = value.slice( 1 );
	}
	while ( value.endsWith( "." ) ) {
		value = value.slice( 0, value.length - 1 );
	}
	let hasDigits = value.match( /\d/ );
	return hasDigits ? value : "";
}

function normalizeLength( lengthValue ) {
	let value = Number( cleanToDigits( lengthValue ) );
	value < 5 ? value = 5 : value > 1000 ? value = 1000 : void 0;
	return String( value ).concat( "px" );
}

function getElementDescription( imageElement ) {
	return imageElement._attrs && getObjectProto( imageElement._attrs ) == "Map" ?
		imageElement._attrs.get( "alt" ) : null;
}

function parentHasNoOtherTypeChildren( viewImageElement ) {
	if ( !viewImageElement || typeof viewImageElement != "object" ||
		!viewImageElement.parent || typeof viewImageElement.parent != "object" ||
		viewImageElement.parent.name != "td" ||
		!( viewImageElement.parent._children instanceof Array ) ) return false;
	let nonImageChildren = viewImageElement.parent._children.filter(
		child => ( child.name != "img" ) );
	return !nonImageChildren || !( nonImageChildren instanceof Array ) ||
		nonImageChildren.length < 1;
}

function imageParentIsDocumentFragment( viewImageElement ) {
	if ( !viewImageElement || typeof viewImageElement != "object" ||
		!viewImageElement.parent || typeof viewImageElement.parent != "object" ) return false;
	return getObjectProto( viewImageElement.parent ).indexOf( "DocumentFragment" ) >= 0;
}

function imageParentIsBlockQuote( viewImageElement ) {
	return Validator.isObjectPath( viewImageElement, "viewImageElement.parent" ) &&
		viewImageElement.parent.name == "blockquote";
}

function removeLengthWithSmallerPriority( viewElement, upcastWriter, width, height ) {
	if ( width && height && LENGTH_WITH_PRIORITY == WIDTH ) {
		removeViewImageElementHeight( upcastWriter, viewElement );
	}
	if ( width && height && LENGTH_WITH_PRIORITY == HEIGHT ) {
		removeViewImageElementWidth( upcastWriter, viewElement );
	}
}

function imageDescriptionIsValid( dataProcessor, element ) {
	if ( !element || typeof element != "object" || !dataProcessor ||
		typeof dataProcessor != "object" ) return false;
	let description = getElementDescription( element );
	if ( !dataProcessor ||
		typeof dataProcessor.isValidImageDescription != "function" ) return false;
	if ( dataProcessor.isValidImageDescription( description ) ) return true;
	console.warn( `Image has an invalid description.` );
	return false;
}

function onlyFirstAttributeIsValid( firstAttribute, secondAttribute ) {
	return isValidStrAttibute( firstAttribute ) &&
		!isValidStrAttibute( secondAttribute );
}

function isValidStrAttibute( attribute ) {
	return attribute && typeof attribute == "string" && attribute.length > 0;
}

function setAttributesOnModelElement(
	modelElement, modelWriter, attributes, value ) {
	if ( !isValidObject( modelElement ) || !isValidObject( modelWriter ) ) return;
	if ( !( attributes instanceof Array ) ) {
		if ( typeof attributes != "string" ) return;
		attributes = [ attributes ];
	}
	if ( typeof modelWriter.setAttribute != "function" ) return;
	attributes.forEach( modelAttribute => {
		modelWriter.setAttribute( modelAttribute, value, modelElement );
	} );
}

function removeAttributesOnModelElement( modelElement, modelWriter, attributes ) {
	if ( !isValidObject( modelElement ) || !isValidObject( modelWriter ) ) return;
	if ( !( attributes instanceof Array ) ) {
		if ( typeof attributes != "string" ) return;
		attributes = [ attributes ];
	}
	if ( typeof modelWriter.removeAttribute != "function" ) return;
	attributes.forEach( modelAttribute => {
		modelWriter.removeAttribute( modelAttribute, modelElement );
	} );
}

export function setModelImageElementHeight( modelImageElement, modelWriter, value ) {
	setAttributesOnModelElement( modelImageElement, modelWriter,
		[ HEIGHT, MODEL_STYLE_HEIGHT, MIN_HEIGHT, MAX_HEIGHT ], value );
}

export function removeModelImageElementHeight( modelImageElement, modelWriter ) {
	// set to undefined first
	setModelImageElementHeight( modelImageElement, modelWriter, void 0 );
	removeAttributesOnModelElement( modelImageElement, modelWriter,
		[ HEIGHT, MODEL_STYLE_HEIGHT, MIN_HEIGHT, MAX_HEIGHT ] );
}

export function setModelImageElementWidth( modelImageElement, modelWriter, value ) {
	setAttributesOnModelElement( modelImageElement, modelWriter,
		[ WIDTH, MODEL_STYLE_WIDTH, MIN_WIDTH, MAX_WIDTH ], value );
}

export function removeModelImageElementWidth( modelImageElement, modelWriter ) {
	// set to undefined first
	setModelImageElementWidth( modelImageElement, modelWriter, void 0 );
	removeAttributesOnModelElement( modelImageElement, modelWriter,
		[ WIDTH, MODEL_STYLE_WIDTH, MIN_WIDTH, MAX_WIDTH ] );
}

function removeViewImageElementAttributes(
	viewUpcastWriter, viewImageElement, attributes ) {
	if ( !isValidObject( viewUpcastWriter ) ||
		!isValidObject( viewImageElement ) ) return;
	if ( !( attributes instanceof Array ) ) {
		if ( typeof attributes != "string" ) return;
		attributes = [ attributes ];
	}
	if ( typeof viewUpcastWriter.removeAttribute != "function" ||
		typeof viewUpcastWriter.removeStyle != "function" ) return;
	attributes.forEach( viewAttribute => {
		viewUpcastWriter.removeAttribute( viewAttribute, viewImageElement );
		viewUpcastWriter.removeStyle( viewAttribute, viewImageElement );
	} );
}

export function removeViewImageElementWidth(
	viewUpcastWriter, viewImageElement ) {
	removeViewImageElementAttributes( viewUpcastWriter, viewImageElement,
		[ WIDTH, MIN_WIDTH, MAX_WIDTH ] );
}

export function removeViewImageElementHeight(
	viewUpcastWriter, viewImageElement ) {
	removeViewImageElementAttributes( viewUpcastWriter, viewImageElement,
		[ HEIGHT, MIN_HEIGHT, MAX_HEIGHT ] );
}

function isValidObject( value ) {
	return !!value && typeof value == "object";
}
