import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { defineButton, createInputForm, closeDropdownOnBlur } from '../utils';
import PisaMarkEditing from './pisamarkediting';
import MarkMatchesCommand from './pisamarkmatchescommand';
import { PISA_MARK_MATCHES, markMatches } from './pisamarkmatchescommand';
import RemoveAllMarkCommand from './pisaremoveallmarkcommand';
import { REMOVE_ALL_MARKS, removeAllMarks } from './pisaremoveallmarkcommand';
import { searchIcon } from '../icons';
import Validator from '../pisautils/validator';
import debounce from 'lodash-es/debounce';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';

export const PISASEARCH = 'pisaSearch';
export const SEARCH_KEYWORD_INPUT = "pisaSearchKeywordInput";

export default class PisaSearch extends Plugin {

	static get requires() {
		return [ PisaMarkEditing ];
	}

	init() {
		const editor = this.editor;
		this.searchInProgress = false;

		editor.commands.add( PISA_MARK_MATCHES, new MarkMatchesCommand( editor ) );
		editor.commands.add( REMOVE_ALL_MARKS, new RemoveAllMarkCommand( editor ) );

		const command = editor.commands.get( PISA_MARK_MATCHES );

		editor.ui.componentFactory.add( PISASEARCH, locale => {

			const onClickContent = [];
			const dropdownView = createDropdown( locale );
			const inputForm = createInputForm( editor,
				editor.objects.tooltips.getT( SEARCH_KEYWORD_INPUT ), "",
				[ 'pisa-input', 'pisa-input-bottom' ] );

			defineButton( dropdownView.buttonView, searchIcon,
				editor.objects.tooltips.getT( PISASEARCH ) );
			onClickContent.push( inputForm );
			addToolbarToDropdown( dropdownView, onClickContent );
			closeDropdownOnBlur( dropdownView );
			dropdownView.bind( 'isEnabled' ).to( command );

			let markMatches = ( eventInfo ) => {
				if ( this.searchInProgress ) return;
				this.searchStarted();
				let searchKeyword = inputForm.inputView.element.value;
				let batch = editor.model.createBatch();
				// removeAllMarks( editor, batch );
				// markMatches( editor, searchKeyword.toLowerCase(), batch );
				editor.executeIf( REMOVE_ALL_MARKS, { batch: batch }, false );
				if ( !Validator.isString( searchKeyword ) ) return this.searchEnded();
				editor.executeIf( PISA_MARK_MATCHES, {
					batch: batch,
					keyword: searchKeyword.toLowerCase()
				}, false );
				this.searchEnded();
			};

			inputForm.inputView.on( "input", debounce( markMatches, 500 ) );

			dropdownView.buttonView.on( 'open', () => {
				inputForm.inputView.element.autofocus = true;
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, PISASEARCH );

			return dropdownView;
		} );
	}

	searchStarted() {
		this.searchInProgress = true;
	}

	searchEnded() {
		this.searchInProgress = false;
	}

}
