import Command from '@ckeditor/ckeditor5-core/src/command';
import { setSelection } from '../utils';

export const PISA_FIRE = "pisaFire";

export default class PisaFireCommand extends Command {

	constructor( editor ) {
		super( editor, PISA_FIRE );
		this.set( 'isEnabled', true );
	}

	execute( options = {} ) {
		const editor = this.editor;
		if ( typeof options.event !== "string" ) {
			return;
		}
		let fireEvent = () => {
			editor.fire( options.event, isObject( options.options ) ? options.options : {} );
		};
		editor.objects.selection._doWithSelectionRestoration( fireEvent );
		// const selection = lastValidSelection( editor, options );
		// if ( selection ) {
		// 	setSelection( editor, selection.getFirstRange() );
		// }
	}

	refresh() {
		this.set( 'isEnabled', true );
	}
}

export function lastValidSelection( editor, options = {} ) {
	if ( !editor ) return null;
	return options.selection && isValid( options.selection ) ? options.selection :
		( options.lastSelection && isValid( editor.lastSelection ) ? editor.lastSelection :
			( isValid( editor.model.document.selection ) ? editor.model.document.selection :
				( isValid( editor.lastSelection ) ? editor.lastSelection : null ) ) );
}

function parentIsRoot( selection ) {
	return !( selection && selection.anchor && selection.anchor.parent && selection.anchor.parent.name != "$root" );
}

function isObject( variable ) {
	return variable && ( typeof variable == "object" || variable instanceof Object );
}

export function isValid( selection ) {
	return isObject( selection ) && !parentIsRoot( selection );
}
