import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import AttributeCommand from '@ckeditor/ckeditor5-basic-styles/src/attributecommand';

export const SUBSCRIPT = 'pisaSubscript';
const subTag = "sub";

export default class PisaSubscriptEditing extends Plugin {

	init() {
		const editor = this.editor;

		editor.model.schema.extend( '$text', { allowAttributes: SUBSCRIPT } );

		editor.conversion.attributeToElement( {
			model: SUBSCRIPT,
			view: subTag
		} );

		// editor.commands.add( SUBSCRIPT, new AttributeCommand( editor, SUBSCRIPT ) );
	}

}
