import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import PisaBasics from '../pisabasics/pisabasics';
import PisaInsertImageCommand from './pisaimagecommand';
import ImageClickHanlder from './pisaimageclickhandler';
import { createButton, offsetToPathPosition, onDomEvent, fire, stopEvent, changeCursor, setContentEditable } from '../utils';
import { imageIcon } from '../icons';
import { insertTextWithImages, isFilesOnlyText } from '../pisaclipboard/pisapaste';
import Validator from '../pisautils/validator';
import { LINK_BUTTON_CLICKED_EVENT } from '../pisalink/pisalinkui';

export const INSERT_IMAGE = "pisaInsertImage";
export const IMAGE_CLICKED = "imageClicked";

export default class PisaImageUI extends Plugin {

	static get requires() {
		return [ PisaBasics ];
	}

	constructor( editor ) {
		super( editor );
		new ImageClickHanlder( this );
	}

	// get editableElement() {
	// 	return !Validator.isObjectPath( this, "this.editor.ui.view.editable" ) ||
	// 		!( this.editor.ui.view.editable.element instanceof Element ) ? void 0 :
	// 		this.editor.ui.view.editable.element;
	// }

	init() {
		const editor = this.editor;
		const dataProcessor = editor.data.processor || editor.getPsaDP();
		this.counters = this.counters || {};
		this.counters.lastId = 0;
		this.contentEditable = true;

		editor.commands.add( INSERT_IMAGE, new PisaInsertImageCommand( editor ) );

		this._addImageClickListener();

		editor.editing.view.document.on( "drop", ( eventInfo, data ) => {
			if ( !data || typeof data != "object" || !data.dataTransfer ||
				typeof data.dataTransfer != "object" ) return;
			let dropText = getTrasferText( data.dataTransfer, dataProcessor.isPlain() );
			let images = getTrasferImages( data.dataTransfer, this.counters );
			if ( !dropText && !images ) return;
			stopEvent( eventInfo, data );
			let modelRange = data && typeof data == "object" && data.dropRange &&
				typeof data.dropRange == "object" ?
				editor.objects.selection._viewRangeToModel( data.dropRange ) : null;
			if ( !modelRange || typeof modelRange != "object" ) return;
			if ( !editor.objects.selection._forceSetTo( modelRange ) ) return;
			if ( images ) {
				editor.counters.dropCount = images.length;
				editor.counters.insertCount = 0;
				fire( editor, "pisaDropImage", { images: images } );
				if ( isFilesOnlyText( editor, dropText ) ) return;
			}
			if ( !dropText ) return;
			if ( insertTextWithImages( editor, dropText ) ) return;
			dataProcessor.insertText( dropText, dataProcessor._isHtmStr( dropText ), true );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.editing.view.document.on( "clipboardInput", ( eventInfo, data ) => {
			if ( !data || !data.dataTransfer || !data.dataTransfer.files ) return;
			let images = getImageObjects( data.dataTransfer.files, this.counters );
			if ( !images ) return;
			fire( editor, "pisaDropImage", { images: images } );
			stopEvent( eventInfo, data );
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.ui.componentFactory.add( INSERT_IMAGE, locale => {
			let imageButton = createButton( imageIcon,
				editor.objects.tooltips.getT( INSERT_IMAGE ), editor.locale );
			imageButton.bind( 'isEnabled' ).to( editor.commands.get( INSERT_IMAGE ) );
			imageButton.on( "execute", ( eventInfo ) => {
				fire( editor, INSERT_IMAGE );
			} );
			editor.objects.focus._addExecuteFocus( imageButton );
			return imageButton;
		} );
	}

}

function getImageFiles( fileList ) {
	if ( !fileList || fileList.length <= 0 ) return null;
	return Array.from( fileList ).filter( file => ( file && file.type &&
		file.type.startsWith( "image" ) ) );
}

export function getImageObjects( fileList, counter ) {
	const ID_PREFIX = 'pisa-url-image-';
	let imagesList = getImageFiles( fileList );
	if ( !imagesList || imagesList <= 0 ) return null;
	let imageObjects = [];
	imagesList.forEach( imageFile => {
		imageObjects.push( {
			id: ID_PREFIX.concat( String( counter.lastId++ ) ),
			file: imageFile
		} );
	} );
	return imageObjects;
}

function isNotInfoBrowser() {
	return pisasales && typeof pisasales == "object" && pisasales.Info &&
		typeof pisasales.Info == "object" &&
		pisasales.Info._isIE == false &&
		pisasales.Info._isEdge == false &&
		pisasales.Info._isFF == false;
}

function getTrasferImages( dataTransfer, counters ) {
	if ( !dataTransfer || typeof dataTransfer != "object" ) return void 0;
	let nativeDataTransfer = dataTransfer._native &&
		typeof dataTransfer._native == "object" ? dataTransfer._native : void 0;
	let images = void 0;
	if ( ( !images || !( images instanceof Array ) || images.length < 1 ) &&
		dataTransfer.files )
		images = getImageObjects( dataTransfer.files, counters );
	if ( ( !images || !( images instanceof Array ) || images.length < 1 ) &&
		nativeDataTransfer && typeof nativeDataTransfer == "object" &&
		nativeDataTransfer.files )
		images = getImageObjects( nativeDataTransfer.files, counters );
	if ( ( !images || !( images instanceof Array ) || images.length < 1 ) &&
		nativeDataTransfer && typeof nativeDataTransfer == "object" &&
		nativeDataTransfer.files )
		images = getImageObjects( nativeDataTransfer.items, counters );
	return images;
}

export function getTrasferText( dataTransfer, forcePlain = false ) {
	if ( !dataTransfer || typeof dataTransfer != "object" ) return "";
	let nativeDataTransfer = dataTransfer._native &&
		typeof dataTransfer._native == "object" ? dataTransfer._native : void 0;
	let dropText = "";
	if ( typeof dataTransfer.getData == "function" && !forcePlain )
		dropText = dataTransfer.getData( "text/html" ) || "";
	if ( ( !dropText || typeof dropText != "string" || dropText.length < 1 ) &&
		!forcePlain && typeof nativeDataTransfer == "object" &&
		nativeDataTransfer && typeof nativeDataTransfer.getData == "function" )
		dropText = nativeDataTransfer.getData( "text/html" ) || "";
	if ( ( !dropText || typeof dropText != "string" || dropText.length < 1 ) &&
		typeof dataTransfer.getData == "function" )
		dropText = dataTransfer.getData( "text/plain" ) ||
		dataTransfer.getData( "text" ) || "";
	if ( ( !dropText || typeof dropText != "string" || dropText.length < 1 ) &&
		typeof nativeDataTransfer == "object" &&
		nativeDataTransfer && typeof nativeDataTransfer.getData == "function" )
		dropText = nativeDataTransfer.getData( "text/plain" ) ||
		nativeDataTransfer.getData( "text" ) || "";
	return dropText;
}
