import { PLACEHOLDER } from './pisaplaceholderui';
import { mapToObject } from '../pisaimageresize/utils';
import { OPENING_BRAKET, CLOSING_BRACKET } from './pisadefinitionediting';

const CONTAINER_TAGS = [ "p", "div", "hr", "pre", "blockquote", "ol", "ul", "li",
	"dl", "dt", "dd", "figure", "figcaption", "iframe", "embed", "object", "param",
	"source", "canvas", "map", "area", "svg", "table", "tbody", "caption", "colgroup",
	"col", "tbody", "thead", "tfoot", "tr", "td", "th", "body", "section", "nav", "article",
	"aside", "h1", "h2", "h3", "h4", "h5", "h6", "header", "footer", "address", "main", "br"
];

export function setPlaceholderText( editor, element, value ) {
	if ( !editor || !element || element.name != PLACEHOLDER ) return;
	removeChildren( editor, element );
	if ( editor.objects.placeholders.titlesShown ) {
		insertPlaceholderTitleText( editor, element, value );
		return;
	}
	// let attributes = getAttributesMap( element );
	let dataProcessor = editor.data.processor || editor.getPsaDP();
	if ( !dataProcessor ) return;
	let docFrag = dataProcessor.htmToDocFrag( value );
	if ( typeof docFrag != "object" || typeof docFrag._children != "object" ||
		!( docFrag._children._nodes instanceof Array ) || docFrag._children._nodes.length <= 0 ||
		typeof docFrag._children._nodes[ 0 ]._children != "object" ) return;
	let textNodes = docFrag._children._nodes[ 0 ]._children._nodes;
	if ( !( textNodes instanceof Array ) ) return;
	// setAttributesOnText( editor, textNodes, attributes );
	insertTextNodesInPlaceholder( editor, element, textNodes );
}

function insertTextNodesInPlaceholder( editor, placeholder, textNodesList ) {
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		for ( let i = textNodesList.length - 1; i >= 0; i-- ) {
			writer.insert( textNodesList[ i ], placeholder, 0 );
		}
	} );
}

function setAttributesOnText( editor, textNodesList, attributes ) {
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		textNodesList.forEach( textNode => {
			attributes.forEach( ( attributeValue, attribute ) => {
				!textNode._attrs.get( attribute ) &&
					writer.setAttribute( attribute, attributeValue, textNode );
			} );
		} );
	} );
}

function getAttributesMap( placeholder ) {
	let attributes = placeholder.previousSibling && placeholder.previousSibling._data ?
		placeholder.previousSibling._attrs : placeholder.nextSibling && placeholder.nextSibling._data ?
		placeholder.nextSibling._attrs : new Map();
	attributes.delete( "linkHref" );
	return attributes;
}

function removeChildren( editor, element ) {
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		let range = writer.createRangeIn( element );
		writer.remove( range );
	} );
}

function insertPlaceholderTitleText( editor, placeholder, titleText ) {
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		let range = writer.createRangeIn( placeholder );
		writer.insertText( OPENING_BRAKET + titleText + CLOSING_BRACKET, {}, range.start );
	} );
}

export function setPlaceholderClass( editor, element, className = null ) {
	if ( !editor || !element || element.name != PLACEHOLDER ) return;
	if ( !className ) {
		editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
			writer.setAttribute( "class", "", element );
			writer.removeAttribute( "class", element );
		} );
		return;
	}
	editor.model.enqueueChange( editor.objects.placeholders.lastBatch, writer => {
		writer.setAttribute( "class", className, element );
	} );
}

export function activatePlaceholder( editor, element, active = true ) {
	active ? setPlaceholderClass( editor, element, "pisa-active-placeholder" ) :
		setPlaceholderClass( editor, element );
}

export function placeholderEntriesToMap( editor, objectsArray ) {
	let map = new Map();
	// let dataProcessor = editor.data.processor || editor.getPsaDP();
	objectsArray.forEach( entry => {
		// let modelDocumentFragment = dataProcessor.htmToDocFrag( entry.html ?
		// 	entry.value : dataProcessor._textStrToHtml( entry.value ) );
		map.set( entry.key, {
			unicodeValue: entry.value
			// base64Value: dataProcessor._encode64( unicodeValue )
			// wasHtml: entry.html,
			// singleLine: isSingleLine( modelDocumentFragment ),
			// model: modelDocumentFragment,
			// elements: []
		} );
	} );
	return map;
}

export function isSingleLine( modelDocFrag ) {
	if ( !modelDocFrag || !modelDocFrag._children ||
		!modelDocFrag._children._nodes ) return true;
	if ( modelDocFrag._children._nodes.length > 1 ) return false;
	if ( modelDocFrag._children._nodes.length < 1 ) return true;
	if ( modelDocFrag._children._nodes[ 0 ].name != "paragraph" ) return false;
	let paragraph = modelDocFrag._children._nodes[ 0 ];
	if ( !paragraph._children || !paragraph._children._nodes ) return true;
	let doesntHaveSoftBreaks = paragraph._children._nodes.every( childElement => {
		return childElement.name != "softBreak";
	} );
	return doesntHaveSoftBreaks;
}

export function isInline( viewDocFrag ) {
	if ( !viewDocFrag || typeof viewDocFrag._children != "object" ||
		viewDocFrag._children.length < 1 ) return true;
	let allChildrenInline = viewDocFrag._children.every( childElement => {
		return childElement.name ? CONTAINER_TAGS.indexOf( childElement.name ) < 0 : true;
	} );
	if ( allChildrenInline ) return true;
	if ( viewDocFrag._children.length > 1 ) return false;
	let element = viewDocFrag._children[ 0 ];
	if ( CONTAINER_TAGS.indexOf( element.name ) < 0 ) return true;
	if ( element.name != "div" && element.name != "p" ) return false;
	if ( typeof element._children != "object" && element._children.length < 1 ) return true;
	if ( element._children.length == 1 ) return isInline( element._children[ 0 ] );
	let doesntHaveContainerElements = element._children.every( childElement => {
		return childElement.name ? CONTAINER_TAGS.indexOf( childElement.name ) < 0 : true;
	} );
	return doesntHaveContainerElements;
}
