import './theme/redicon.css';
import EmptyView from '../pisadropdown/emptyview';
import IconView from '@ckeditor/ckeditor5-ui/src/icon/iconview';
import TooltipView from '@ckeditor/ckeditor5-ui/src/tooltip/tooltipview';
import HtmHelper from '../pisautils/htmhelper';
import Validator from '../pisautils/validator';
import { addViewChildren } from '../utils';

export default class RedIconView extends EmptyView {

	constructor( labelText, icon ) {

		super( [ "cke5-right-top-icon-container" ] );

		const bind = this.bindTemplate;

		this.set( 'isHovered' );
		this.set( 'isNotHovered' );

		this.extendTemplate( {
			on: {
				mouseenter: bind.to( 'isHovered' ),
				mouseleave: bind.to( 'isNotHovered' )
			}
		} );

		this.tooltipView = new TooltipView();
		this.tooltipView.text = labelText;

		this.iconView = new IconView();
		this.iconView.content = icon;
		this.iconView.template.attributes.class = [];

		addViewChildren( this, { 0: this.iconView, 1: this.tooltipView } );

		this.on( "isHovered", ( eventInfo, domEvent ) => {
			this.tooltipView.element.style =
				"visibility:visible;opacity:1;display:block;";
			this.tooltipView.isVisible = true;
		} );

		this.on( "isNotHovered", ( eventInfo, domEvent ) => {
			this.tooltipView.element.style = "";
			this.tooltipView.isVisible = false;
		} );
	}

	destroyTooltipView() {
		if ( !Validator.isObject( this.tooltipView ) ) return void 0;
		// this.deregisterChild( this.tooltipView );
		let tooltipView = this.tooltipView;
		this.tooltipView = void 0;
		delete this.tooltipView;
		this.removeFromItems( tooltipView );
		this.removeFromMap( tooltipView );
		if ( HtmHelper.isElement( tooltipView.element ) ) {
			if ( HtmHelper.isNode( this.element ) )
				this.element.removeChild( tooltipView.element );
			tooltipView.element.innerHTML = "";
			tooltipView.element.remove();
		}
		tooltipView.text = "";
		tooltipView.destroy();
		tooltipView = void 0;
	}

	destroyIconView() {
		if ( !Validator.isObject( this.iconView ) ) return void 0;
		// this.deregisterChild( this.iconView );
		let iconView = this.iconView;
		this.iconView = void 0;
		delete this.iconView;
		this.removeFromItems( iconView );
		this.removeFromMap( iconView );
		if ( HtmHelper.isElement( iconView.element ) ) {
			if ( HtmHelper.isNode( this.element ) )
				this.element.removeChild( iconView.element );
			iconView.element.innerHTML = "";
			iconView.element.remove();
		}
		iconView.content = "";
		iconView.destroy();
		iconView = void 0;
	}

	removeFromItems( possibleItem ) {
		if ( !Validator.isObjectPath( this, "this.items._items" ) ||
			!Validator.isArray( this.items._items ) ) return false;
		let removed = false;
		for ( let i = 0; i < this.items._items.length; i++ ) {
			if ( this.items._items[ i ] !== possibleItem ) continue;
			this.items._items.splice( i, 1 );
			removed = true;
			break;
		}
		return removed;
	}

	removeFromMap( possibleItem ) {
		if ( !Validator.isObjectPath( this, "this.items._itemMap" ) ||
			!Validator.isMap( this.items._itemMap ) ) return false;
		if ( !Validator.isObject( possibleItem ) ||
			!Validator.isString( possibleItem.viewUid ) ) return false;
		return this.items._itemMap.delete( possibleItem.viewUid );
	}

	destroyElement() {
		if ( !HtmHelper.isElement( this.element ) ) return void 0;
		this.element.innerHTML = "";
		this.element.remove();
	}

	destroy() {
		this.destroyTooltipView();
		this.destroyIconView();
		this.destroyElement();
		return this.originalDestroy();
	}

	originalDestroy() {
		return super.destroy();
	}

}
