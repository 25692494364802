import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/link.css';
import Validator from '../pisautils/validator';

export const PISA_OBJECT_LINK_PREVIEW_PEFIX = "pisaObjectLinkPreviewPrefix";
export const PISA_MAIL_LINK_PREVIEW_PEFIX = "pisaMailLinkPreviewPrefix";
const ADD_TOOLTIPS_TO_LINKS = true;
const LINK_TITLE_MODEL_ATTRIBUTE = 'pisaLinkTitle';
const LINK_TITLE_VIEW_ATTRIBUTE = 'title';
const A_TAG = "a";

export default class PisaLinkEditing extends Plugin {

	init() {

		if ( !ADD_TOOLTIPS_TO_LINKS ) return;

		this.objectLinkPrefix = this.editor.objects.tooltips
			.getT( PISA_OBJECT_LINK_PREVIEW_PEFIX );
		if ( !Validator.isString( this.objectLinkPrefix ) ) this.objectLinkPrefix =
			"PiSA-".concat( this.editor.objects.tooltips.language == "de" ? "Objekt" :
				this.editor.objects.tooltips.language == "fr" ? "Objet" : "Object" );

		this.mailToPrefix = this.editor.objects.tooltips
			.getT( PISA_MAIL_LINK_PREVIEW_PEFIX );
		if ( !Validator.isString( this.mailToPrefix ) ) this.mailToPrefix =
			this.editor.objects.tooltips.language == "de" ? "E-Mail senden an" :
			this.editor.objects.tooltips.language == "fr" ? "Envoyer un email à" :
			"Send email to";

		const editor = this.editor;

		editor.model.schema.extend( '$text', { allowAttributes: LINK_TITLE_MODEL_ATTRIBUTE } );

		editor.conversion.for( 'downcast' ).attributeToElement( {
			model: LINK_TITLE_MODEL_ATTRIBUTE,
			view: ( linkTitle, writer ) => {
				if ( !Validator.isString( linkTitle ) ) return;
				const linkTitleElement = writer
					.createAttributeElement( 'span', { title: linkTitle } );
				return linkTitleElement;
			}
		}, { priority: Number.MAX_SAFE_INTEGER } );

		editor.conversion.for( 'downcast' ).add( dispatcher => {
			dispatcher.on( `attribute:linkHref:$text`,
				( evt, data, conversionApi ) => {
					if ( !Validator.couldBe( data.item, "Text" ) ) return;
					let copyOfConversionApi = Validator.clone( conversionApi );
					let updatedNewValue = this._getValueForObjectLink( data );
					if ( updatedNewValue == data.attributeNewValue )
						updatedNewValue = this._getValueForMailTo( updatedNewValue );
					dispatcher.convertAttribute( data.range, LINK_TITLE_MODEL_ATTRIBUTE,
						data.attributeOldValue, updatedNewValue, conversionApi.writer );
					conversionApi.writer = copyOfConversionApi.writer;
					conversionApi.consumable = copyOfConversionApi.consumable;
				} );
		} );

		// editor.conversion.for( 'downcast' ).add( dispatcher => {
		// 	dispatcher.on( `attribute:linkHref:pisaImage`,
		// 		( evt, data, conversionApi ) => {
		// 			console.log( "href on image" );
		// 		} );
		// } );

	}

	_getValueForObjectLink( data ) {
		let updatedNewValue = !Validator.isObject( data ) ? null :
			data.attributeNewValue;
		if ( !this._isPisaObjLink( updatedNewValue ) ) return updatedNewValue;
		let linkText = Validator.couldBe( data.item, "Text" ) &&
			Validator.isString( data.item.data ) ? data.item.data :
			Validator.is( data.item.textNode, "Text" ) &&
			Validator.isString( data.item.textNode._data ) ?
			data.item.textNode._data : void 0;
		if ( !Validator.isString( linkText ) ) {
			let textNode = this._getTextNodeWithLinkFromRange( data.range );
			linkText = Validator.isObject( textNode ) &&
				Validator.isString( textNode._data ) ? textNode._data : void 0;
		}
		if ( !Validator.isString( linkText ) ) return updatedNewValue;
		return `${ this.objectLinkPrefix }: ${ linkText }`;
	}

	_getValueForMailTo( mailToHref ) {
		if ( !Validator.isString( mailToHref ) ||
			!mailToHref.startsWith( "mailto:" ) ) return mailToHref;
		let mailAdress = mailToHref.replace( "mailto:", "" );
		return `${ this.mailToPrefix }: ${ mailAdress }`;
	}

	_getTextNodeWithLinkFromRange( range ) {
		if ( !Validator.is( range, "Range" ) ) return;
		let startItem = this._isLinkText( range.start.nodeAfter ) ?
			range.start.nodeAfter :
			this._isLinkText( range.start.parent ) ?
			range.start.parent : void 0;
		let endItem = this._isLinkText( range.end.nodeBefore ) ?
			range.end.nodeBefore :
			this._isLinkText( range.end.parent ) ?
			range.end.parent : void 0;
		let linkText = !Validator.isObject( startItem ) ? endItem :
			!Validator.isObject( endItem ) ? startItem :
			startItem == endItem ? startItem : void 0;
		if ( Validator.isObject( linkText ) ) Object.assign( linkText, {
			range: range,
			href: linkText._attrs.get( "linkHref" )
		} );
		return linkText;
	}

	_isLinkText( textOrElementNode ) {
		return Validator.is( textOrElementNode, "Text" ) &&
			Validator.isMap( textOrElementNode._attrs ) &&
			Validator.isString( textOrElementNode._attrs.get( "linkHref" ) );
	}

	_isPisaObjLink( href ) {
		return Validator.isObject( pisasales ) &&
			Validator.isFunction( pisasales.isPsaObjLink ) ?
			pisasales.isPsaObjLink( href ) :
			!Validator.isString( href ) ? false :
			href.includes( '/psaobj' );
	}

}
