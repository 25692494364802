import Command from '@ckeditor/ckeditor5-core/src/command';
import {
	getFocusElement,
	findParentElement,
	setAttributeOnElement,
	setAttributeToUndefined,
	saveLastRange,
	setSelection,
	findTableCells,
	getElementFullSelection
} from '../utils';
import { isInsideTable } from '../pisatable/pisadecreasemargincommand';
// import { isSelectionTable } from '../pisapanelview/pisaselectionballoon'

export const CELL_WIDTH = "cellWidth";
export const CELL_HEIGHT = "cellHeight";
export const TABLE_CELL = "tableCell";
export const PISA_CELL = "pisaCell"

export default class PisaCellCommand extends Command {

	constructor( editor ) {
		super( editor, PISA_CELL );
	}

	refresh() {
		let selection = this.editor.model.document.selection;
		let selectionHasTable = isInsideTable( selection.anchor.parent );
		if ( !selectionHasTable && selection.anchor.parent.name == "$root" && selection.anchor.path.length == 1 ) {
			let index = selection.anchor.path[ 0 ];
			let element = selection.anchor.parent._children._nodes[ index ];
			selectionHasTable = isInsideTable( element );
		}
		// selectionHasTable = selectionHasTable ? selectionHasTable : isSelectionTable( this.editor, selection );
		this.isEnabled = selectionHasTable;
	}

	execute( options = {} ) {
		const editor = this.editor;
		let range = saveLastRange( editor );
		if ( options.width || options.height ) {
			let currentElement = options.focusElement || ( options.source == "button" ? editor.focusElement : null ) || getFocusElement( editor );
			let selection = options.lastSelection || ( options.source == "button" ? editor.lastSelection : null ) || editor.model.document.selection;
			let tableCell = findParentElement( editor, currentElement, TABLE_CELL );
			changeCell( tableCell, options, editor );
			if ( !tableCell ) {
				changeWholeTable( editor, options, selection );
			}
		}
		setSelection( editor, range );
	}
}

function changeCell( tableCell, options, editor ) {
	if ( tableCell && options.width ) {
		setWidth( editor, tableCell, options.width );
	}
	if ( tableCell && options.height ) {
		setHeight( editor, tableCell, options.height );
	}
}

function changeWholeTable( editor, options, selection ) {
	let tableCells = findTableCells( editor, selection );
	if ( tableCells && tableCells.length > 0 ) {
		editor.tableCells = tableCells;
	} else {
		tableCells = editor.tableCells;
	}
	for ( let tableCell of tableCells ) {
		changeCell( tableCell, options, editor );
	}
}

function setHeight( editor, tableCell, height ) {
	if ( height.indexOf( "px" ) < 0 ) {
		height = height + "px";
	}
	if ( tableCell.parent && tableCell.parent._children && tableCell.parent._children._nodes ) {
		for ( let cell of tableCell.parent._children._nodes ) {
			if ( height == "NaNpx" || height == "0px" ) {
				setAttributeToUndefined( editor, cell, CELL_HEIGHT );
			} else {
				setAttributeOnElement( editor, cell, CELL_HEIGHT, height );
			}
		}
	} else {
		if ( height == "NaNpx" || height == "0px" ) {
			setAttributeToUndefined( editor, tableCell, CELL_HEIGHT );
		} else {
			setAttributeOnElement( editor, tableCell, CELL_HEIGHT, height );
		}
	}
}

function setWidth( editor, tableCell, width ) {
	if ( typeof tableCell.index == "number" && tableCell.parent && tableCell.parent.parent &&
		tableCell.parent._children && tableCell.parent._children._nodes &&
		tableCell.parent.parent._children && tableCell.parent.parent._children._nodes ) {
		let i = tableCell.index;
		if ( width.indexOf( "px" ) < 0 ) {
			width = width + "px";
		}
		for ( let tableRow of tableCell.parent.parent._children._nodes ) {
			if ( tableRow._children && tableRow._children._nodes &&
				tableRow._children._nodes.length >= ( i + 1 ) ) {
				if ( tableRow._children._nodes[ i ].name == TABLE_CELL ) {
					if ( width == "NaNpx" || width == "0px" ) {
						setAttributeToUndefined( editor, tableRow._children._nodes[ i ], CELL_WIDTH );
					} else {
						setAttributeOnElement( editor, tableRow._children._nodes[ i ], CELL_WIDTH, width );
					}
				}
			}
		}
	} else {
		if ( width == "NaNpx" || width == "0px" ) {
			setAttributeToUndefined( editor, tableCell, CELL_WIDTH );
		} else {
			setAttributeOnElement( editor, tableCell, CELL_WIDTH, width );
		}
	}
}
