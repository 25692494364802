import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaBasics from '../pisabasics/pisabasics';
import { createButton, getSelectionHtml, onDomEvent } from '../utils';
import { copyIcon } from '../icons';

export const COPY = "pisaCopy";

export default class PisaCopy extends Plugin {

	static get requires() {
		return [ PisaBasics ];
	}

	init() {
		let editor = this.editor;
		editor.objects.lastCopied = editor.objects.lastCopied || "";
		editor.ui.componentFactory.add( COPY, locale => {
			let copyButton = createButton( copyIcon,
				editor.objects.tooltips.getT( COPY ), locale );
			copyButton.on( "execute", () => {
				this.updateLastCopied();
				window.document.execCommand( "copy" );
				editor.balloons.hideAll();
			} );

			editor.objects.focus._addExecuteFocus( copyButton );

			return copyButton;
		} );

		let self = this;
		let handleCopy = function( e ) {
			if ( !e.ctrlKey || ( e.code != "KeyC" && e.key != "c" && e.keyCode != 67 ) ) return;
			self.updateLastCopied();
		};
		onDomEvent( editor, "keydown", handleCopy );
	}

	updateLastCopied() {
		let selectedContent = getSelectionHtml();
		if ( !selectedContent || !selectedContent.plain ||
			selectedContent.plain.length <= 0 ) return;
		this.editor.objects.lastCopied = selectedContent;
		const blocks = Array.from( this.editor.model.document.selection.getSelectedBlocks() )
	}

}
