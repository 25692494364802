import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import EmptyView from './emptyview';
import { addClasses, addViewDomListener, getObjectProto, stringToArray } from '../utils';
import { ckeColorList } from '../pisacolorscheme';

/**
 * Creates a Color Grid View (EmptyView, div) with items as Empty Views (div), each
 * containing a ButtonView (button) and adds it to the Panel View of a Dropdown View
 * @param {module @ckeditor/ckeditor5-ui/src/dropdown//dropdownview} dropdownView
 * the dropdownView to whose Panel View the created Color Grid should be added
 * @param {Object} options color grid options
 * @param {Array.<String>} options.colorList list of colors wich will be converted
 * into buttons displayed in the color grid
 * @param {Array.<String>} [options.gridStyles = "[ 'psa-dropdown-container' ]"]
 * optional list of css classes to be added to the Color Grid View element (div
 * HTML Node); defaults to [ 'psa-dropdown-container' ]
 * @param {Array.<String>} [options.divStyles = "[ 'psa-dropdown-container-child' ]"]
 * optional list of css classes to be added to the child item views elements (div
 * HTML Nodes) that contain the color buttons; defaults to [ 'psa-dropdown-container-child' ]
 * @param {Array.<String>} [options.buttonStyles = "[ 'psa-dropdown-color-button' ]"]
 * optional list of css classes to be added to the ButtonViews elements (button
 * HTML Nodes) that are the colors themselves; defaults to [ 'psa-dropdown-color-button' ]
 * @param {function} [options.clickCallback] additional callback function to
 * be executed when a color button is clicked (doesn't override default behavior
 * of setting color inside editor)
 */
export function addColorGrid( dropdownView, options = {} ) {
	options = normalizeOptions( options );
	const divGridView = new EmptyView( options.gridStyles );
	options.colorList.forEach( color => {
		const divView = new EmptyView( options.divStyles );
		let buttonView = new ButtonView();
		addClasses( buttonView, options.buttonStyles );
		buttonView.set( 'labelStyle', `background-color:${ color };` );
		if ( color == "#ffffff" || color == "#fff" || color == "ffffff" || color == "fff" )
			addClasses( buttonView, [ "psa-white" ] );
		buttonView.color = color;
		buttonView.on( "execute", () => {
			dropdownView.fire( "execute", { color: color } );
		} );
		options.clickCallback && addViewDomListener( buttonView, "click", options.clickCallback );
		divView.items.add( buttonView );
		divGridView.items.add( divView );
	} );
	dropdownView.panelView.children.add( divGridView );
}

function normalizeOptions( options ) {
	if ( !options.colorList || getObjectProto( options.colorList ) != "Array" ||
		options.colorList.length <= 0 ) options.colorList = ckeColorList;
	options.gridStyles = normalizeArray( options.gridStyles, [ 'psa-dropdown-container' ] );
	options.divStyles = normalizeArray( options.divStyles, [ 'psa-dropdown-container-child' ] );
	options.buttonStyles = normalizeArray( options.buttonStyles, [ 'psa-dropdown-color-button' ] );
	options.clickCallback = options.clickCallback &&
		getObjectProto( options.clickCallback ) == "Function" ? options.clickCallback : null;
	return options;
}

function normalizeArray( initialObject, defaultValue ) {
	if ( initialObject && typeof initialObject == "string" )
		initialObject = stringToArray( initialObject );
	if ( !initialObject || getObjectProto( initialObject ) != "Array" ||
		initialObject.length <= 0 ) initialObject = defaultValue;
	return initialObject;
}
