import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/tablecell.css';
import PisaCellCommand from './pisacellcommand';
import PisaCellStyleCommand from './pisacellstylecommand';
import { PISA_CELL } from './pisacellcommand';
import { CELL_STYLE } from './pisacellstylecommand';
import EmptyView from '../pisadropdown/emptyview';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import {
	addClasses,
	defineButton,
	closeDropdownOnBlur,
	bindInputToCommand,
	createLabel,
	setColorInput,
	findParentElement,
	createNumberInput,
	createRangeInput,
	getFocusElement,
	setButton,
	createHexInput
} from '../utils';
import { tableMergeCellIcon } from '../icons';

export const TABLE_CELL_WIDTH = "pisaTableCellWidth";
export const TABLE_CELL_HEIGHT = "pisaTableCellHeight";
export const TABLE_CELL_PADDING = "pisaTableCellPadding";
export const TABLE_CELL_BORDER_WIDTH = "pisaTableCellBorderWidth";
export const TABLE_CELL_BORDER_COLOR = "pisaTableCellBorderColor";
export const TABLE_CELL_BACKGROUND_COLOR = "pisaTableCellBackgroundColor";
export const PISA_DEFAULT = "pisaDefault";

export default class PisaCellUI extends Plugin {

	init() {
		const editor = this.editor;
		editor.commands.add( PISA_CELL, new PisaCellCommand( editor ) );
		editor.commands.add( CELL_STYLE, new PisaCellStyleCommand( editor ) );
		const command = editor.commands.get( PISA_CELL );

		editor.ui.componentFactory.add( PISA_CELL, locale => {
			const dropdownView = createDropdown( locale );
			defineButton( dropdownView.buttonView, tableMergeCellIcon, editor.objects.tooltips.getT( PISA_CELL ) );
			addClasses( dropdownView.panelView, [ 'pisa-200' ] );
			dropdownView.bind( 'isEnabled' ).to( command );
			closeDropdownOnBlur( dropdownView );

			dropdownView.buttonView.on( "execute", () => {
				editor.lastSelection = editor.model.document.selection;
				editor.focusElement = getFocusElement( editor );
				editor.tableCells = [];
			} );

			const widthInput = rangeWithLabel( editor, {
				max: 800,
				min: 0,
				step: 10,
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_WIDTH ) }:`,
				command: PISA_CELL,
				inputKey: "width",
				addTextInput: true,
				placeholder: "px",
				rangeCss: [ "pisa-range" ],
				inputCss: [ "pisa-number" ],
				options: { source: "button" }
			} );

			const heightInput = rangeWithLabel( editor, {
				max: 800,
				min: 0,
				step: 10,
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_HEIGHT ) }:`,
				command: PISA_CELL,
				inputKey: "height",
				addTextInput: true,
				placeholder: "px",
				rangeCss: [ "pisa-range" ],
				inputCss: [ "pisa-number" ],
				options: { source: "button" }
			} );

			const paddingInput = rangeWithLabel( editor, {
				max: 50,
				min: 0,
				step: 2,
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_PADDING ) }:`,
				command: CELL_STYLE,
				inputKey: "padding",
				addTextInput: true,
				placeholder: "px",
				rangeCss: [ "pisa-range" ],
				inputCss: [ "pisa-number" ],
				options: { source: "button" }
			} );

			const borderWidthInput = rangeWithLabel( editor, {
				max: 20,
				min: 0,
				step: 1,
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_BORDER_WIDTH ) }:`,
				command: CELL_STYLE,
				inputKey: "borderWidth",
				addTextInput: true,
				placeholder: "px",
				rangeCss: [ "pisa-range" ],
				inputCss: [ "pisa-number" ],
				options: { source: "button" }
			} );

			const borderColorInput = colorWithLabel( editor, {
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_BORDER_COLOR ) }:`,
				command: CELL_STYLE,
				inputKey: "borderColor",
				addTextInput: true,
				addAutoButton: true,
				textCss: [ "pisa-hex" ],
				options: { source: "button" }
			} );

			const backgroundColorInput = colorWithLabel( editor, {
				label: `${ editor.objects.tooltips.getT( TABLE_CELL_BACKGROUND_COLOR ) }:`,
				command: CELL_STYLE,
				inputKey: "background",
				addTextInput: true,
				addAutoButton: true,
				textCss: [ "pisa-hex" ],
				options: { source: "button" }
			} );

			dropdownView.panelView.children.add( widthInput, 0 );
			dropdownView.panelView.children.add( heightInput, 1 );
			dropdownView.panelView.children.add( paddingInput, 2 );
			dropdownView.panelView.children.add( borderWidthInput, 3 );
			dropdownView.panelView.children.add( borderColorInput, 4 );
			dropdownView.panelView.children.add( backgroundColorInput, 5 );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, PISA_CELL );

			return dropdownView;
		} );
	}
}

export function rangeWithLabel( editor, parameters ) {
	if ( !editor || !parameters || !parameters.max || !parameters.step ) return null;
	parameters.parentCss = parameters.parentCss || [];
	parameters.rangeCss = parameters.rangeCss || [];
	parameters.inputCss = parameters.inputCss || [];
	let rangeParent = new EmptyView( parameters.parentCss );
	addLabel( editor, parameters.label, rangeParent );
	let rangeInput = createRange( editor, parameters );
	let numberInput = generateNumberInput( editor, parameters );
	if ( rangeInput && numberInput ) {
		rangeInput.on( "input", () => {
			numberInput.element.value = rangeInput.element.value;
		} );
		numberInput.on( "input", () => {
			rangeInput.element.value = numberInput.element.value;
		} );
		updateValueOnClick( editor, rangeInput, numberInput, null, parameters.inputKey );
	}
	rangeInput ? rangeParent.items.add( rangeInput ) : void 0;
	numberInput ? rangeParent.items.add( numberInput ) : void 0;
	return rangeParent;
}

function colorWithLabel( editor, parameters ) {
	let colorParent = new EmptyView( parameters.parentCss );
	addLabel( editor, parameters.label, colorParent );
	let colorInput = createColorInput( editor, parameters );
	let autoButton = createAutoButton( parameters.addAutoButton, parameters.inputKey, parameters.command, editor );
	let textInput = createHexInput( parameters.addTextInput, editor, parameters.textCss );
	textInput ? bindInputToCommand( textInput, parameters.command, parameters.options, parameters.inputKey, editor, "", true ) : void 0;
	addColorListeners( colorInput, textInput, autoButton );
	updateValueOnClick( editor, colorInput, textInput, autoButton, parameters.inputKey );
	colorInput ? colorParent.items.add( colorInput ) : void 0;
	textInput ? colorParent.items.add( textInput ) : void 0;
	autoButton ? colorParent.items.add( autoButton ) : void 0;
	return colorParent;
}

function addLabel( editor, label, parentView ) {
	label ? parentView.items.add( createLabel( editor.locale, label ) ) : void 0;
}

function createColorInput( editor, parameters ) {
	return setColorInput( editor, parameters.colorCss, parameters.command, parameters.options, parameters.inputKey );
}

function createAutoButton( doCreate, inputKey, command, editor ) {
	if ( doCreate != true && doCreate != "true" ) return null;
	let options = {};
	options[ inputKey ] = false;
	let autoButton = setButton( "", editor.objects.tooltips.getT( PISA_DEFAULT ),
		command, options, editor );
	addClasses( autoButton, [ 'pisa-place-center' ] );
	return autoButton;
}

function createRange( editor, parameters ) {
	let rangeInput = createRangeInput( editor, {
		max: parameters.max,
		min: parameters.min || 0,
		step: parameters.step,
		cssClasses: parameters.rangeCss
	} );
	parameters.inputKey = parameters.inputKey || "";
	parameters.command ? bindInputToCommand( rangeInput, parameters.command,
		parameters.options, parameters.inputKey, editor, "", true ) : void 0;
	return rangeInput;
}

export function generateNumberInput( editor, parameters ) {
	if ( parameters.addTextInput != true && parameters.addTextInput != "true" ) return null;
	const numberInput = createNumberInput( editor, {
		max: parameters.max,
		min: 0,
		step: 1,
		cssClasses: parameters.inputCss
	} );
	( numberInput && parameters.command ) ?
	bindInputToCommand( numberInput, parameters.command, parameters.options,
		parameters.inputKey, editor, "", true ): void 0;
	return numberInput ? numberInput : null;
}

function addColorListeners( colorInput, textInput, autoButton ) {
	if ( !colorInput || !textInput || !autoButton ) return;
	textInput.on( "input", () => {
		autoButton.isOn = false;
		let value = textInput.element.value.startsWith( "#" ) ?
			textInput.element.value : "#" + textInput.element.value;
		( value.replace( /\#[a-f0-9]{6}/gi, "" ) == "" ) ?
		colorInput.element.value = value: void 0;
	} );
	colorInput.on( "input", () => {
		autoButton.isOn = false;
		textInput.element.value = colorInput.element.value;
	} );
	autoButton.on( "execute", () => {
		autoButton.isOn = true;
		colorInput.element.value = "#111111";
		textInput.element.value = "";
	} );
}

function updateValueOnClick( editor, firstInput, secondInput, thirdInput = null, inputKey ) {
	editor.editing.view.document.on( "click", () => {
		let selection = editor.model.document.selection;
		let tableCell = findParentElement( editor, selection.anchor.parent, "tableCell" );
		if ( tableCell && tableCell._attrs && tableCell._attrs.get( attributeKeys[ inputKey ] ) ) {
			let value = tableCell._attrs.get( attributeKeys[ inputKey ] ).replace( "px", "" );
			firstInput.element.value = value;
			secondInput.element.value = value;
			thirdInput ? thirdInput.isOn = false : void 0;
		} else if ( tableCell && tableCell._attrs ) {
			if ( inputKey != "borderColor" && inputKey != "background" ) {
				firstInput.element.value = "0";
				secondInput.element.value = "";
			} else {
				firstInput.element.value = "#111111";
				secondInput.element.value = "";
				thirdInput ? thirdInput.isOn = true : void 0;
			}
		}
	} );
}

const attributeKeys = {
	width: "cellWidth",
	height: "cellHeight",
	padding: "padding",
	borderWidth: "border-width",
	borderColor: "border-color",
	background: "background-color"
}
