import Validator from './validator';

export default class EventHelper {

	static isDomEventObject( potentialDomEventObject ) {
		return potentialDomEventObject instanceof Event;
	}

	static isKeyboardEvent( domEventObject ) {
		return domEventObject instanceof KeyboardEvent;
	}

	static getEventType( domEventObject ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isString( domEventObject.type ) ) return void 0;
		return domEventObject.type;
	}

	static keyIs( domEventObject, keyName ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isString( keyName ) ) return false;
		if ( Validator.isString( domEventObject.key ) &&
			domEventObject.key == keyName ) return true;
		if ( Validator.isString( domEventObject.code ) &&
			domEventObject.code == keyName ) return true;
		return false;
	}

	static keyHas( domEventObject, keyName ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isString( keyName ) ) return false;
		if ( Validator.isString( domEventObject.key ) &&
			domEventObject.key.indexOf( keyName ) >= 0 ) return true;
		if ( Validator.isString( domEventObject.code ) &&
			domEventObject.code.indexOf( keyName ) >= 0 ) return true;
		return false;
	}

	static isKeyPrintable( domEventObject ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isString( domEventObject.key ) ) return false;
		return domEventObject.key.length == 1;
	}

	static ctrlPressed( domEventObject ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isBoolean( domEventObject.ctrlKey ) ) return false;
		return domEventObject.ctrlKey;
	}

	static shiftPressed( domEventObject ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isBoolean( domEventObject.shiftKey ) ) return false;
		return domEventObject.shiftKey;
	}

	static altPressed( domEventObject ) {
		if ( !Validator.isObject( domEventObject ) ||
			!Validator.isBoolean( domEventObject.altKey ) ) return false;
		return domEventObject.altKey;
	}

	static stop( domEventObject ) {
		if ( !EventHelper.isDomEventObject( domEventObject ) ) return false;
		domEventObject.cancelBubble = true;
		domEventObject.preventDefault();
		domEventObject.stopImmediatePropagation();
		domEventObject.stopPropagation();
		return true;
	}

	static bindByName( contextInstance, methodName ) {
		if ( !Validator.isString( methodName ) ||
			!Validator.isObject( contextInstance ) ||
			!( methodName in contextInstance ) ) return void 0;
		return EventHelper.bind( contextInstance, contextInstance[ methodName ] );
	}

	static bind( contextInstance, method ) {
		if ( !Validator.isFunction( method ) ||
			!Validator.isObject( contextInstance ) ) return void 0;
		return function() {
			return method.apply( contextInstance, arguments );
		};
	}

	static debounce( callbackFunction, debounceTimeoutMilliseconds, callImediately = false ) {
		if ( !Validator.isPositiveNumber( debounceTimeoutMilliseconds ) ||
			!Validator.isFunction( callbackFunction ) ) return callbackFunction;
		const doCallImadiately = !!callImediately;
		let timerFunction;
		return function() {
			let contextInstance = this;
			const args = arguments;
			const later = function() {
				timerFunction = void 0;
				if ( !doCallImadiately ) callbackFunction.apply( contextInstance, args );
			};
			const callNow = doCallImadiately && !timerFunction;
			clearTimeout( timerFunction );
			timerFunction = setTimeout( later, debounceTimeoutMilliseconds );
			if ( callNow ) callbackFunction.apply( contextInstance, args );
		};
	};

	static getCallbackMethodName( eventName ) {
		if ( !Validator.isString( eventName ) ) return void 0;
		return "on" + eventName.charAt( 0 ).toUpperCase() + eventName.slice( 1 ) +
			"Callback";
	}

	static addListener( {
		contextInstance,
		eventName,
		methodName,
		element = void 0,
		debounceTimeout
	} ) {
		if ( !Validator.isString( eventName ) || eventName.length < 2 ||
			!Validator.isObject( contextInstance ) ||
			!Validator.isString( methodName ) ||
			!Validator.isFunction( contextInstance[ methodName ] ) ) return false;
		if ( !( element instanceof HTMLElement ) ) {
			element = contextInstance.element;
			if ( !( element instanceof HTMLElement ) ) return false;
		}
		const callbackMethodName = EventHelper.getCallbackMethodName( eventName );
		if ( Validator.isFunction( contextInstance[ callbackMethodName ] ) ) return false;
		contextInstance[ callbackMethodName ] =
			EventHelper.bind( contextInstance, contextInstance[ methodName ] );
		if ( Validator.isPositiveInteger( debounceTimeout ) )
			contextInstance[ callbackMethodName ] =
			EventHelper.debounce( contextInstance[ callbackMethodName ], debounceTimeout );
		element.addEventListener( eventName, contextInstance[ callbackMethodName ] );
		return true;
	}

	static removeListener( contextInstance, eventName, element = void 0 ) {
		if ( !Validator.isObject( contextInstance ) ) return false;
		if ( !( element instanceof HTMLElement ) ) {
			if ( !contextInstance.isRendered ) return false;
			element = contextInstance.element;
		}
		const callbackMethodName = EventHelper
			.getCallbackMethodName( eventName );
		if ( !Validator.isString( callbackMethodName ) ||
			!Validator.isFunction( contextInstance[ callbackMethodName ] ) ) return false;
		element.removeEventListener( eventName, contextInstance[ callbackMethodName ] );
		contextInstance[ callbackMethodName ] = void 0;
		delete contextInstance[ callbackMethodName ];
		return true;
	}

}
