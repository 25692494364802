import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { isValidModelPosition, getObjectProto, isPositionParentText, getPathToElement, positionAtRoot } from '../utils';
import Validator from '../pisautils/validator';

export default class PisaPosition extends Plugin {

	init() {
		const editor = this.editor;
		editor.objects = editor.objects || {};
		editor.objects.position = this;
	}

	isValid( position ) {
		return isValidModelPosition( position );
	}

	isPosition( position ) {
		return getObjectProto( position ).indexOf( "Position" ) >= 0;
	}

	isParentText( position ) {
		return isPositionParentText( position );
	}

	get atRoot() {
		return positionAtRoot( this.editor );
	}

	exists( position ) {
		if ( !this.isPosition( position ) || !this.isValid( position ) ||
			this.isParentText( position ) ) return false;
		return this._pathExists( position.path );
	}

	get currentAnchor() {
		let selection = this.editor.model.document.selection;
		if ( !selection || typeof selection != "object" ) return;
		let anchor = selection.anchor;
		return this.isPosition( anchor ) ? anchor : void 0;
	}

	get currentFocus() {
		let selection = this.editor.model.document.selection;
		if ( !selection || typeof selection != "object" ) return;
		let focus = selection.focus;
		return this.isPosition( focus ) ? focus : void 0;
	}

	_pathExists( path ) {
		if ( !path || !( path instanceof Array ) || path.length < 1 ||
			!path.every( number => typeof number == "number" &&
				Math.abs( number ) == number && Math.round( number ) == number ) ) return false;
		const editor = this.editor;
		let root = editor.model.document.getRoot();
		let shortenedPath = path.length > 1 ? path.slice( 0, -1 ) : path;
		let node = null;
		try {
			node = root.getNodeByPath( shortenedPath );
		} catch ( e ) {}
		if ( !node || getObjectProto( node ).indexOf( "Element" ) < 0 ) return false;
		if ( path.length == 1 ) return true;
		return node.maxOffset >= path[ path.length - 1 ];
	}

	_pathToPosition( path, verify = true ) {
		if ( verify && !this._pathExists( path ) ) return;
		const editor = this.editor;
		let root = editor.model.document.getRoot();
		return editor.model.createPositionFromPath( root, path );
	}

	_positionsToRange( startPosition, endPosition = null, verify = true ) {
		if ( verify && ( !this.exists( startPosition ) || !this.exists( endPosition ) ) ) return;
		const editor = this.editor;
		return editor.model.createRange( startPosition, endPosition );
	}

	_pathsToRange( startPath, endPath = null, verify = true ) {
		let startPosition = this._pathToPosition( startPath, verify );
		let endPosition = !endPath ? startPosition :
			this._pathToPosition( endPath, verify );
		return !startPosition || !endPosition ? void 0 :
			this._positionsToRange( startPosition, endPosition, verify );
	}

	rangeExists( range ) {
		if ( !Validator.couldBe( range, "Range" ) ) return false;
		if ( !this.exists( range.start ) ) return false;
		return this.exists( range.end );
	}

	selectionBackward( original = true, objects = false, any = false ) {
		const editor = this.editor;
		let isOriginalBackward = this._isSelectionBackward( editor.model.document.selection );
		let isObjectsSelectionBackward = this._isSelectionBackward( editor.objects.selection.last.model );
		return any ? isOriginalBackward || isObjectsSelectionBackward : original ?
			( objects ? isOriginalBackward && isObjectsSelectionBackward : isOriginalBackward ) :
			objects ? isObjectsSelectionBackward : void 0;
	}

	_isSelectionBackward( selection ) {
		if ( getObjectProto( selection ).indexOf( "Selection" ) < 0 ) return void 0;
		if ( !selection.anchor || typeof selection.anchor != "object" || !selection.focus ||
			typeof selection.focus != "object" ) return void 0;
		return this._isFirstPositionAfterSecond( selection.anchor, selection.focus );
	}

	_isFirstPositionAfterSecond( firstPosition, secondPosition ) {
		if ( !this.isPosition( firstPosition ) ||
			!this.isPosition( secondPosition ) ) return void 0;
		return this._isFirstArrayAfterSecond( firstPosition.path, secondPosition.path );
	}

	_isFirstArrayAfterSecond( firstArray, secondArray ) {
		if ( !( firstArray instanceof Array ) || firstArray.length < 1 ||
			!( secondArray instanceof Array ) || secondArray.length < 1 ) return void 0;
		let isAfter = false;
		for ( let i = 0; i < firstArray.length; i++ ) {
			if ( secondArray.length - 1 < i || typeof firstArray[ i ] != "number" ||
				typeof secondArray[ i ] != "number" ) break;
			if ( firstArray[ i ] <= secondArray[ i ] ) continue;
			isAfter = true;
			break;
		}
		return isAfter;
	}

	_isAtParentEnd( position ) {
		if ( !this.exists( position ) ) return false;
		if ( !( position.path instanceof Array ) || position.path.length < 1 ) return false;
		if ( position.isAtEnd ) return true;
		let lastPathIndex = position.path.slice( -1 ).pop();
		// see if the end of the original path is at the very end of the parent
		// element by comparing it to the parent's maximal offset size
		return lastPathIndex == position.parent.maxOffset;
	}

	_isAtParentStart( position ) {
		if ( !this.exists( position ) ) return false;
		if ( !( position.path instanceof Array ) || position.path.length < 1 ) return false;
		if ( position.isAtStart ) return true;
		let lastPathIndex = position.path.slice( -1 ).pop();
		return lastPathIndex == 0;
	}

	_isPositionParentEmpty( position ) {
		if ( !this.exists( position ) ) return false;
		return ( this._isElementEmpty( position.parent ) );
	}

	_isElementEmpty( element ) {
		if ( getObjectProto( element ).indexOf( "Element" ) < 0 ) return false;
		// element is valid
		if ( element.childCount == 0 ) return true;
		// element has 1 or more children
		if ( element.maxOffset > 1 ) return false;
		// sum of offset sizes of all of element's children is 1 or 0
		if ( element.maxOffset == 0 ) return true;
		// sum of offset sizes of all of element's children is 1
		if ( element.childCount != 1 ) return false;
		// element has exactly 1 child with offset size 1
		// this next step is done because for everything except paragraphs the
		// following code is not valid
		if ( element.name != "paragraph" ) return false;
		// element is a paragraph with exactly 1 child with offset size 1
		let child = void 0;
		try {
			if ( typeof element.getChild == "function" )
				child = element.getChild( 0 );
		} catch ( e ) {
			child = void 0;
		}
		if ( !child || typeof child != "object" || typeof child.data != "string" ||
			!!child.name ) return false;
		// the child is a text node, becuase it has data and no name
		if ( child.data.length == 0 ) return true;
		// the text node data is not an empty string
		let dataProcessor = this.editor.data.processor || this.editor._getDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ) return false;
		// we would not be able to proceed with no data processor, now we can
		let data = child.data;
		if ( typeof dataProcessor._removeNonPrintableAsciiChars == "function" ) {
			data = dataProcessor._removeNonPrintableAsciiChars( data );
			// see if the data only contained non printable ascii chars
			if ( data.length == 0 ) return true;
		}
		if ( typeof dataProcessor._removeZeroWidthSpaces == "function" ) {
			// see if the data only contained zero width html entities
			data = dataProcessor._removeZeroWidthSpaces( data );
			if ( data.length == 0 ) return true;
		}
		// the data contains printable caracters, so the parent is not empty
		return false;
	}

	_isFirstChild( element ) {
		if ( getObjectProto( element ).indexOf( "Element" ) < 0 ||
			getObjectProto( element.parent ).indexOf( "Element" ) < 0 ) return false;
		return element.parent._children._nodes[ 0 ] == element;
	}

	_isLastChild( element ) {
		if ( getObjectProto( element ).indexOf( "Element" ) < 0 ||
			getObjectProto( element.parent ).indexOf( "Element" ) < 0 ) return false;
		return element.parent._children._nodes.slice( -1 ).pop() == element;
	}

	_isOnlyChild( element ) {
		return this._isFirstChild( element ) && this._isLastChild( element );
	}

	/**
	 * finds the closest paragraph element that contains the provided
	 * position (is seen as position's parent)
	 */
	_getParagraphParent( position ) {
		if ( !this.exists( position ) ) return void 0;
		let paragraphParent = position.parent;
		while ( paragraphParent.parent && paragraphParent.name != "$root" &&
			paragraphParent.name != "tableCell" && paragraphParent.name != "paragraph" ) {
			paragraphParent = paragraphParent.parent;
		}
		return paragraphParent.name != "paragraph" ? void 0 : paragraphParent;
	}

	/**
	 * finds the closest $root or tableCell element that contains the provided
	 * position (is seen as position's parent); so far only $root and tableCell
	 * elements can contain paragraph hasElements
	 */
	_getParagraphContainerParent( position ) {
		if ( !this.exists( position ) ) return void 0;
		let containerParent = position.parent;
		while ( containerParent.parent && containerParent.name != "$root" &&
			containerParent.name != "tableCell" ) {
			containerParent = containerParent.parent;
		}
		return containerParent.name != "$root" && containerParent.name != "tableCell" ?
			void 0 : containerParent;
	}

	getPathToElement( element ) {
		return getPathToElement( element );
	}

	_getElementPath( element, start = true ) {
		if ( !element || typeof element != "object" ||
			typeof element.index != "number" ) return void 0;
		let pathToElement = this.getPathToElement( element );
		if ( !( pathToElement instanceof Array ) ) return void 0;
		let lastIndex = start ? element.index : element.index + 1;
		pathToElement.push( lastIndex );
		return this._pathExists( pathToElement ) ? pathToElement : void 0;
	}

	getElementFullRange( element ) {
		let startElementPath = this._getElementPath( element, true );
		if ( !( startElementPath instanceof Array ) ) return void 0;
		let endElementPath = this._getElementPath( element, false );
		if ( !( endElementPath instanceof Array ) ) return void 0;
		return this._pathsToRange( startElementPath, endElementPath );
	}

	_isParagraphInRoot( element ) {
		if ( !element || typeof element != "object" ) return false;
		if ( element.name != "paragraph" ) return false;
		if ( !element.parent || typeof element.parent != "object" ) return false;
		return element.parent.name == "$root";
	}

	_isAtDocumentStart( position ) {
		if ( !this._isAtParentStart( position ) ) return false;
		if ( !position.parent || typeof position.parent != "object" ||
			!this._isFirstChild( position.parent ) ) return false;
		return this._isParagraphInRoot( position.parent );
	}

	get currentAnchorAtDocumentStart() {
		let anchorPosition = this.editor.model.document.selection.anchor;
		return this._isAtDocumentStart( anchorPosition );
	}

	get currentFocusAtDocumentStart() {
		let focusPosition = this.editor.model.document.selection.focus;
		return this._isAtDocumentStart( focusPosition );
	}

	get currentlyAtDocumentStart() {
		return this.currentAnchorAtDocumentStart || this.currentFocusAtDocumentStart;
	}

	_isAtDocumentEnd( position ) {
		if ( !this._isAtParentEnd( position ) ) return false;
		if ( !position.parent || typeof position.parent != "object" ||
			!this._isLastChild( position.parent ) ) return false;
		return this._isParagraphInRoot( position.parent );
	}

	get currentAnchorAtDocumentEnd() {
		let anchorPosition = this.editor.model.document.selection.anchor;
		return this._isAtDocumentEnd( anchorPosition );
	}

	get currentFocusAtDocumentEnd() {
		let focusPosition = this.editor.model.document.selection.focus;
		return this._isAtDocumentEnd( focusPosition );
	}

	get currentlyAtDocumentEnd() {
		return this.currentAnchorAtDocumentEnd || this.currentFocusAtDocumentEnd;
	}

	get fullDocumentSelected() {
		// TODO currently does not support tables at start or end
		return ( this.currentAnchorAtDocumentStart && this.currentFocusAtDocumentEnd ) ||
			( this.currentFocusAtDocumentStart && this.currentAnchorAtDocumentEnd );
	}

}
