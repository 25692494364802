/**
 * PiSA sales extensions for CK Editor 5
 */

// imports
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import PisaUtil from './pisautil.js';

/**
 * main extension class
 */
export default class PisaExt {

	/**
	 * constructs a new instance
	 * @param {Element} container DOM element that hosts the editor
	 * @param {ClassicEditor} editor the editor
	 * @param {Boolean} tlb toolbar flag
	 */
	constructor( container, editor, tlb ) {
		this.container = container;
		this.editor = editor;
		// add resize sensor
		const self = this;
		new ResizeSensor( self.container, function() {
			self._resizeEdt();
		} );
		// add extra method to force a resize
		editor.psaForceLayout = function() {
			self._resizeEdt();
		}
		editor.on( 'destroy', function() {
			self.destroy();
		} );
		// check toolbar settings
		if ( !tlb ) {
			// hide the toolbar element
			this.tlbVisible = false;
			let tbe = this._getTlbElm();
			if ( tbe ) {
				tbe.style.display = 'none';
			}
		} else {
			// the toolbar is visible
			this.tlbVisible = true;
		}
	}

	/**
	 * clean-up
	 */
	destroy() {
		if ( this.container ) {
			ResizeSensor.detach( this.container );
			this.container = null;
		}
		if ( !this.editor ) return;
		const editor = this.editor;
		delete editor.psaForceLayout;
		this.editor = null;
	}

	/**
	 * resizes the editor if the container element was resized
	 */
	_resizeEdt() {
		if ( this.container && this.editor ) {
			const uiv = this._getEdtUIView();
			if ( uiv ) {
				const edt = uiv.editable;
				if ( edt && edt.element ) {
					const cnr = this.container.getBoundingClientRect();
					const elm = edt.element.parentElement;
					const brd = PisaUtil.getCssDims( this.container, [ 'borderTopWidth', 'borderBottomWidth', 'paddingTop', 'paddingBottom' ] );
					const hgt = cnr.height - this._getTlbHgt() - brd;
					elm.style.height = '' + hgt + 'px';
				}
			}
			this._updateToolbar();
		}
	}

	_updateToolbar() {
		const editor = this.editor;
		editor.objects.toolbar.refresh();
		editor.fire( "pisaToolbarRefresh" );
		// if ( editor.ui.view.toolbar &&
		// 	editor.ui.view.toolbar.items._items ) {
		// 	let toolbarItems = editor.ui.view.toolbar.items._items;
		// 	if ( toolbarItems[ toolbarItems.length - 1 ] &&
		// 		toolbarItems[ toolbarItems.length - 1 ].tooltip &&
		// 		toolbarItems[ toolbarItems.length - 1 ].tooltip == "Show more options" ) {
		// 		editor.commands.get( "pisaSwitchVisibility" ).isEnabled = true;
		// 		editor.commands.get( "pisaPlacePlusButton" ).isEnabled = true;
		// 		for ( let item of toolbarItems ) {
		// 			editor.execute( "pisaSwitchVisibility", { view: item, visible: true } );
		// 		}
		// 		editor.execute( "pisaPlacePlusButton" );
		// 		editor.execute( "pisaSwitchVisibility", {
		// 			view: toolbarItems[ toolbarItems.length - 1 ],
		// 			visible: true
		// 		} );
		// 	}
		// }
	}

	/**
	 * returns the view element of the editor
	 * @returns the view element of the editor or null
	 */
	_getEdtUIView() {
		let uiv = null;
		if ( this.editor ) {
			const eui = this.editor.ui;
			if ( eui ) {
				uiv = eui.view;
			}
		}
		return uiv;
	}

	_getTlbElm() {
		let tbe = null;
		const uiv = this._getEdtUIView();
		if ( uiv ) {
			const tlb = uiv.toolbar;
			if ( tlb && tlb.element ) {
				tbe = tlb.element;
			}
		}
		return tbe;
	}

	/**
	 * retrieves the height of editor's toolbar
	 * @returns {Number} the height of editor's toolbar
	 */
	_getTlbHgt() {
		var hgt = 0;
		if ( this.tlbVisible ) {
			var tbe = this._getTlbElm();
			if ( tbe ) {
				var org = tbe.getBoundingClientRect().height + 1;
				hgt = Math.round( org );
				if ( hgt < org ) {
					hgt += 1;
				}
			}
		}
		return hgt;
	}
}
