import LinkBalloon from './linkballoon';
import PisaLinkActionsView from '../pisalinkactionsview';
import Validator from '../../pisautils/validator';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';

export default class LinkActionsBalloon extends LinkBalloon {

	constructor( editor ) {
		super( editor, "actions" );
	}

	init() {
		super.init();
		this.addActionsView();
	}

	addActionsView() {
		const editor = this.editor;
		this.actionsView = new PisaLinkActionsView( editor.locale, editor );
		const linkCommand = editor.commands._commands.get( 'link' );
		const unlinkCommand = editor.commands._commands.get( 'unlink' );
		this.actionsView.bind( 'href' ).to( linkCommand, 'value' );
		// actionsView.editButtonView.bind( 'isEnabled' ).to( linkCommand ); // TODO find out if still necessary
		this.actionsView.unlinkButtonView.bind( 'isEnabled' ).to( unlinkCommand );

		clickOutsideHandler( {
			emitter: this.actionsView,
			activator: () => this.panel.isVisible,
			contextElements: [ this.panel.element ],
			callback: () => this.onOutsideClick()
		} );
		this.addView( this.actionsView );
		delete this.addActionsView;
	}

	onOutsideClick() {
		if ( this.isInsideSameLink ) return;
		this.hide();
	}

	attachTo( options, nodeRect = null ) {
		const successfullyAttached = super.attachTo( options, nodeRect );
		if ( !successfullyAttached ) return successfullyAttached;
		this.panel.fire( "linkActionsBalloonShown", {
			options: options,
			nodeRect: nodeRect
		} );
		return successfullyAttached;
	}


}
