import Validator from './validator';
import { isValidModelPosition, isPositionParentText } from '../utils';

export default class SelectionHelper {

	static isSelection( inputParameter ) {
		return Validator.is( inputParameter, "Selection" );
	}

	static couldBeSelection( inputParameter ) {
		return Validator.couldBe( inputParameter, "Selection" );
	}

	static isRange( inputParameter ) {
		return Validator.is( inputParameter, "Range" );
	}

	// static couldBeRange( inputParameter ) {
	// 	return Validator.couldBe( inputParameter, "Range" );
	// }

	static isPosition( inputParameter ) {
		return Validator.is( inputParameter, "Position" );
	}

	// static couldBePosition( inputParameter ) {
	// 	return Validator.couldBe( inputParameter, "Position" );
	// }

	static isPath( inputParameter ) {
		return Validator.isArray( inputParameter ) && inputParameter.length >= 1 &&
			inputParameter.every( number => Validator.isPositiveInteger( number ) );
	}

	static isValidModelPosition( inputParameter ) {
		return isValidModelPosition( inputParameter );
	}

	static isPositionParentText( inputParameter ) {
		return isPositionParentText( inputParameter )
	}

	static selectionExists( editor, inputParameter ) {
		if ( !SelectionHelper.couldBeSelection( inputParameter ) ) return false;
		if ( !SelectionHelper.positionExists( editor, inputParameter.anchor ) ||
			!SelectionHelper.positionExists( editor, inputParameter.focus ) )
			return false;
		// a selection without ranges is considered non-existing
		if ( !Validator.isArray( inputParameter._ranges ) ||
			inputParameter._ranges.length < 1 ) return false;
		let allRangesExist = true;
		for ( let range of inputParameter._ranges ) {
			if ( SelectionHelper.rangeExists( editor, range ) ) continue;
			allRangesExist = false;
			break;
		}
		return allRangesExist;
	}

	static rangeExists( editor, inputParameter ) {
		return SelectionHelper.isRange( inputParameter ) &&
			SelectionHelper.positionExists( editor, inputParameter.start ) &&
			SelectionHelper.positionExists( editor, inputParameter.end );
	}

	static positionExists( editor, inputParameter ) {
		if ( !SelectionHelper.isPosition( inputParameter ) ||
			!SelectionHelper.isValidModelPosition( inputParameter ) ||
			SelectionHelper.isPositionParentText( inputParameter ) ) return false;
		return SelectionHelper.pathExists( editor, inputParameter.path );
	}

	/**
	 * basically a copy of the already existing function from
	 * @see {../pisaselection/pisaposition~PisaPosition~_pathExists}
	 */
	static pathExists( editor, path ) {
		if ( !SelectionHelper.isPath( path ) ) return false;
		if ( !Validator.isObjectPath( editor, "editor.model.document" ) ||
			!Validator.isFunction( editor.model.document.getRoot ) ) return false;
		const root = editor.model.document.getRoot();
		if ( !Validator.isObject( root ) ||
			!Validator.isFunction( root.getNodeByPath ) ) return false;
		let shortenedPath = path.length > 1 ? path.slice( 0, -1 ) : path;
		let node = void 0;
		try {
			node = root.getNodeByPath( shortenedPath );
		} catch ( err ) {
			// TODO warn
		}
		if ( !Validator.couldBe( node, "Element" ) ) return false;
		if ( path.length == 1 ) return true;
		return node.maxOffset >= path[ path.length - 1 ];
	}

	/**
	 * basically a copy of the already existing function from
	 * @see {../pisaselection/pisaposition~PisaPosition~_isSelectionBackward}
	 */
	static isSelectionBackward( selection ) {
		if ( !SelectionHelper.isSelection( selection ) ) return false;
		return SelectionHelper.isFirstPositionAfterSecond( selection.anchor,
			selection.focus );
	}

	/**
	 * basically a copy of the already existing function from
	 * @see {../pisaselection/pisaposition~PisaPosition~_isFirstPositionAfterSecond}
	 */
	static isFirstPositionAfterSecond( firstPosition, secondPosition ) {
		if ( !SelectionHelper.isPosition( firstPosition ) ||
			!SelectionHelper.isPosition( secondPosition ) ) return false;
		return SelectionHelper.isFirstArrayAfterSecond( firstPosition.path,
			secondPosition.path );
	}

	/**
	 * basically a copy of the already existing function from
	 * @see {../pisaselection/pisaposition~PisaPosition~_isFirstArrayAfterSecond}
	 */
	static isFirstArrayAfterSecond( firstArray, secondArray ) {
		if ( !( firstArray instanceof Array ) || firstArray.length < 1 ||
			!( secondArray instanceof Array ) || secondArray.length < 1 ) return false;
		let isAfter = false;
		for ( let i = 0; i < firstArray.length; i++ ) {
			if ( secondArray.length - 1 < i || typeof firstArray[ i ] != "number" ||
				typeof secondArray[ i ] != "number" ) break;
			if ( firstArray[ i ] <= secondArray[ i ] ) continue;
			isAfter = true;
			break;
		}
		return isAfter;
	}

	static getDocumentSelection( editor, testExistence = false ) {
		if ( !Validator.isObjectPath( editor, "editor.model.document.selection" ) ) return void 0;
		const selection = editor.model.document.selection;
		if ( testExistence )
			return SelectionHelper.selectionExists( selection ) ? selection : void 0;
		return SelectionHelper.couldBeSelection( selection ) ? selection : void 0;
	}

	static getDocumentRange( editor, testExistence = false ) {
		const selection = SelectionHelper.getDocumentSelection( editor, testExistence );
		if ( !Validator.isObject( selection ) ) return void 0;
		if ( !Validator.isArray( selection._ranges ) ||
			selection._ranges.length < 1 ) return void 0;
		let documentRange = void 0;
		for ( let range of selection._ranges ) {
			if ( !SelectionHelper.isRange( range ) ) continue;
			documentRange = range;
			break;
		}
		return documentRange;
	}

}
