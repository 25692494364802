import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaPrintCommand from './pisaprintcommand';
import PisaPrintListener from './pisaprintlistener';
import { setButton } from '../utils';
import { printIcon } from '../icons';

export const PRINTCONTENT = "pisaEditorPrint";

export default class PisaPrint extends Plugin {

	static get pluginName() {
		return 'PisaPrint';
	}

	static get requires() {
		return [ PisaPrintListener ];
	}

	init() {
		const editor = this.editor;

		editor.commands.add( PRINTCONTENT, new PisaPrintCommand( editor ) );

		editor.ui.componentFactory.add( PRINTCONTENT, locale => {
			return setButton( printIcon,
				editor.objects.tooltips.getT( PRINTCONTENT ), PRINTCONTENT, "", editor );
		} );
	}

}
