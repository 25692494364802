import Command from '@ckeditor/ckeditor5-core/src/command';
import { setAttributeOnElement, removeAttributeOnElement, getFocusElement, setAttributeToUndefined } from '../utils';
import { LIST_VALUE, CONTINUE_LIST } from './pisalistediting';
const LIST_ITEM = "listItem";

/**
 * Currently implemented mechanism to determine which value should be set:
 *
 *		1. Has this element a previously positioned sibling (on the same level) that
 *		is a numbered list item?
 *		|
 *		|---NO---->	1.1. set value to "1" (one); go to 2
 *		|
 *		|---YES--->	Is the found numbered element the DIRECT predecessor of the
 *								current element (the exact previous sibling element)?
 *								|
 *								|---YES--->	1.2. set value to null (remove value) and let html
 *								|						do the job (it shows automatically the next value);
 *								|						go to 2
 *								|---NO---->	1.3. get the found element's value, increase it by
 *														1 (one) and set it to the current element; go to 2
 *
 * Currently used way to set the value on the current element:
 *
 *		2. Is the current element a numbered list item?
 *		|
 *		|---YES--->	2.1. remove the "list item" property from the current element
 *		|						(currently necessary for value display on firefox)
 *		|						and
 *		|						2.2. make the element a list item
 *		|						and
 *		|						2.3. set the desired value on the list item element
 *		|---NO---->	do (go to) the steps 2.2 and 2.3; also valid if the element is an
 *								unnumbered list item
 *
 */
export default class PisaContinueListCommand extends Command {

	// refresh() {
	// 	this.isEnabled = isSelectionListItem( this.editor );
	// }

	constructor( editor ) {
		super( editor, CONTINUE_LIST );
		addEnterListener( editor );
	}

	execute( options = {} ) {
		const editor = this.editor;
		let selection = editor.model.document.selection;
		let element = selection.anchor.parent;
		if ( element.name == "$root" ) return;
		let elementIsNuberedListItem = isNumberedListItem( element );
		let previousListElement = this._findPreviousListElement( element );
		let value = !isNumberedListItem( previousListElement ) ? 1 :
			element.previousSibling == previousListElement ? null :
			1 + this._getListElementValue( previousListElement );
		// we have to remove the list item value, then set it again for firefox
		if ( elementIsNuberedListItem ) editor.executeIf( "numberedList", {}, "" );
		insertListItem( editor, value );
		// setValueOnListItem( editor, value );
	}

	_findPreviousListElement( element ) {
		let indent = getElementIndent( element );
		let listElement = element;
		while ( !!listElement.previousSibling &&
			typeof listElement.previousSibling == "object" ) {
			listElement = listElement.previousSibling;
			if ( isNumberedListItem( listElement ) &&
				hasSameIndent( listElement, indent ) ) break;
		}
		return isNumberedListItem( listElement ) &&
			hasSameIndent( listElement, indent ) && listElement != element ?
			listElement : void 0;
	}

	_getListElementValue( element ) {
		let i = 0;
		let indent = getElementIndent( element );
		while ( isNumberedListItem( element ) && !hasValidValue( element ) ) {
			i++;
			if ( !isNumberedListItem( element.previousSibling ) ||
				!hasSameIndent( element.previousSibling, indent ) ) {
				break;
			}
			element = element.previousSibling;
		}

		let listItemValue = getElementValue( element, true );
		if ( typeof listItemValue == "number" ) i += listItemValue;
		return i;
	}

}

export function insertListItem( editor, value = 1 ) {
	editor.executeIf( "numberedList", {}, "" );
	setValueOnListItem( editor, value );
}

export function setValueOnListItem( editor, value = 1 ) {
	let parent = getFocusElement( editor );
	if ( !isListItem( parent ) ) return;
	setAttributeOnElement( editor, parent, LIST_VALUE, value );
	setAttributeOnElement( editor, parent, "listType", "numbered" );
}

export function addEnterListener( editor ) {
	const viewDocument = editor.editing.view.document;
	viewDocument.on( 'enter', ( evt, data ) => {
		let listElement = getFocusElement( editor );
		if ( !isListItem( listElement ) ||
			!isListItem( listElement.previousSibling ) ) return;
		editor.model.change( writer => {
			writer.setAttribute( LIST_VALUE, null, listElement );
			writer.removeAttribute( LIST_VALUE, listElement )
		} );
	}, { priority: 'lowest' } );
}

export function isSelectionListItem( editor ) {
	if ( !editor.objects || typeof editor.objects != "object" ||
		!editor.objects.selection || typeof editor.objects.selection != "object" ||
		/**
		!editor.objects.selection.lastBlocks ||
		!( editor.objects.selection.lastBlocks instanceof Array ) ||
		editor.objects.selection.lastBlocks.length != 1 ||
		*/
		!editor.model || typeof editor.model != "object" ||
		!editor.model.document || typeof editor.model.document != "object" ||
		!editor.model.document.selection ||
		typeof editor.model.document.selection != "object" ) return false;
	let selection = editor.model.document.selection;
	if ( selection.anchor && typeof selection.anchor == "object" &&
		selection.anchor.parent && typeof selection.anchor.parent == "object" &&
		selection.anchor.parent.name == "listItem" ) return true;
	if ( selection.focus && typeof selection.focus == "object" &&
		selection.focus.parent && typeof selection.focus.parent == "object" &&
		selection.focus.parent.name == "listItem" ) return true;
	return false;
}

function getElementIndent( element ) {
	if ( !element || typeof element != "object" ||
		!( element._attrs instanceof Map ) ) return "0pt";
	let indent = element._attrs.get( "indent" );
	return !indent || typeof indent != "string" || indent.length < 1 ? "0pt" : indent;
}

function isInNumberedList( element ) {
	if ( !element || typeof element != "object" ||
		!( element._attrs instanceof Map ) ) return false;
	let listType = element._attrs.get( "listType" );
	return listType == "numbered";
}

function getElementValue( element, asNumber = false ) {
	if ( !element || typeof element != "object" ||
		!( element._attrs instanceof Map ) ) return asNumber ? void 0 : "";
	let value = element._attrs.get( "value" );
	if ( typeof value == "number" && value >= 0 ) return asNumber ? value : String( value );
	if ( !value || typeof value != "string" || value.length < 1 ) return asNumber ? void 0 : "";
	if ( !asNumber ) return value;
	let numberValue = Number( value );
	return numberValue >= 0 ? numberValue : void 0;
}

function hasValidValue( element ) {
	if ( !element || typeof element != "object" ) return false;
	let numberValue = getElementValue( element, true );
	return typeof numberValue == "number";
}

function isFirstElement( element ) {
	if ( !element || typeof element != "object" ) return false;
	let value = getElementValue( element );
	return value == "1";
}

function isListItem( element ) {
	if ( !element || typeof element != "object" ) return false;
	return element.name == LIST_ITEM;
}

function isNumberedListItem( element ) {
	return isListItem( element ) && isInNumberedList( element );
}

function hasSameIndent( element, indentValue ) {
	if ( !element || typeof element != "object" ) return false;
	return getElementIndent( element ) == indentValue;
}
