import Validator from './validator';
import Warner from './warner';

export default class GlobalFunctionExecutor {

	static closeExternalMenus() {
		if ( !Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( window.pisasales.ScrMen ) ||
			!Validator.isObject( window.pisasales.ScrMen.static ) ||
			!Validator.isFunction( window.pisasales.ScrMen.static.closeAllMenus ) )
			return false;
		window.pisasales.ScrMen.static.closeAllMenus();
		return true;
	}

	static closeMenusOnExecute( view, viewName ) {
		if ( !Validator.isObject( view ) ||
			!Validator.isFunction( view.on ) ) return false;
		view.on( "execute", ( eventInfo, data ) => {
			const menusClosed = GlobalFunctionExecutor.closeExternalMenus();
			if ( !!menusClosed ) return;
			let traceMessage = `Could not close all external menus when clicking` +
				` on a view's HTML element (on the view "execute" event).`;
			if ( Validator.isString( viewName ) )
				traceMessage += ` View name: "${ viewName }".`;
			Warner.trace( traceMessage, "GlobalFunctionExecutor", "#736598", void 0,
				true, false, true );
		}, { priority: Number.MAX_SAFE_INTEGER } );
		return true;
	}

	static getBlockScreenManager() {
		if ( !Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( window.pisasales.getBscMgr ) ) return void 0;
		return window.pisasales.getBscMgr();
	}

	static showHideBlockScreen( show = true ) {
		const blockScreenManager = GlobalFunctionExecutor.getBlockScreenManager();
		if ( !Validator.isObject( blockScreenManager ) ||
			!Validator.isFunction( blockScreenManager.shwBlkScr ) ) return false;
		blockScreenManager.shwBlkScr( { mod: "trn", keepHint: true, sbc: !!show } );
		return true;
	}

	static getTooltipManager() {
		if ( !Validator.isObjectPath( window.rwt, "rwt.widgets.util" ) ) return void 0;
		if ( !Validator.isFunction( window.rwt.widgets.util.ToolTipManager ) ) return void 0;
		if ( !Validator.isFunction( window.rwt.widgets.util.ToolTipManager.getInstance ) ) return void 0;
		return window.rwt.widgets.util.ToolTipManager.getInstance();
	}

	static nullifyCurrentTooltipTarget() {
		const tooltipManager = GlobalFunctionExecutor.getTooltipManager();
		if ( !Validator.isObject( tooltipManager ) ||
			!Validator.isFunction( tooltipManager.setCurrentToolTipTarget ) )
			return Warner.trace( `Could not remove RAP tooltip by nullifying it's` +
				` target.`, "GlobalFunctionExecutor", "#736598", false, true, false, true );
		tooltipManager.setCurrentToolTipTarget( null );
		return true;
	}

	static getWidgetTooltip() {
		if ( !Validator.isObjectPath( window.rwt, "rwt.widgets.base" ) ) return void 0;
		if ( !Validator.isFunction( window.rwt.widgets.base.WidgetToolTip ) ) return void 0;
		if ( !Validator.isFunction( window.rwt.widgets.base.WidgetToolTip.getInstance ) ) return void 0;
		return window.rwt.widgets.base.WidgetToolTip.getInstance();
	}

	static getKeyHandler() {
		return Validator.isObject( window.pisasales ) &&
			Validator.is( window.pisasales.keyHdl, "KeyHdl" ) ?
			window.pisasales.keyHdl : void 0;
	}

	static setGlobalKeyHandlingBlocked( newValue ) {
		const keyHandler = GlobalFunctionExecutor.getKeyHandler();
		if ( !Validator.isObject( keyHandler ) ) return false;
		keyHandler.blockGlobalKeyHandling( !!newValue );
		return true;
	}

	static blockGlobalKeyHandling() {
		return GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( true );
	}

	static allowGlobalKeyHandling() {
		return GlobalFunctionExecutor.setGlobalKeyHandlingBlocked( false );
	}

}
