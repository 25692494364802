import BlockQuote from '../iconsui/blockquote';
import PisaSubscript from '../pisasubscript/pisasubscript';
import PisaSuperscript from '../pisasuperscript/pisasuperscript';
import Code from '../iconsui/code';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { createButton, defineButton, addClasses, closeDropdownOnBlur, getLiveAttributeValue } from '../utils';
import { blockQuote, code, subscript, superscript } from '../commandnames';
import { quoteIcon } from '../icons';

export const FEATURES = "pisaFeatures";

export default class PisaFeaturesUI extends Plugin {

	static get requires() {
		return [ BlockQuote, PisaSubscript, PisaSuperscript, Code ];
	}

	init() {
		const editor = this.editor;
		const command = editor.commands.get( 'code' );

		editor.ui.componentFactory.add( FEATURES, locale => {
			let dropdownView = createDropdown( editor.locale );
			addClasses( dropdownView.panelView, [ "pisa-vertical-grid" ] );
			defineButton( dropdownView.buttonView, quoteIcon,
				editor.objects.tooltips.getT( FEATURES ) );
			dropdownView.bind( 'isEnabled' ).to( command );
			closeDropdownOnBlur( dropdownView );
			dropdownView.panelView.children.add( editor.ui.componentFactory.create( blockQuote ) );
			dropdownView.panelView.children.add( editor.ui.componentFactory.create( subscript ) );
			dropdownView.panelView.children.add( editor.ui.componentFactory.create( superscript ) );
			dropdownView.panelView.children.add( editor.ui.componentFactory.create( code ) );
			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, FEATURES );
			return dropdownView;
		} );
	}
}
