import Command from '@ckeditor/ckeditor5-core/src/command';
import { changeImageStats, getStats } from './utils.js';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';

export const PISA_RESIZE_IMAGE = 'pisaResizeImage';

export default class PisaResizeImageCommand extends Command {

	constructor( editor ) {
		super( editor, PISA_RESIZE_IMAGE );
	}

	execute( options = {} ) {
		const editor = this.editor;
		if ( !options || !options.value ) return;
		let stats = getStats( editor,
			( options.source == "range" ) ? editor.lastSelection : null );
		if ( !stats || ( !stats.range && !stats.node ) || !stats.attributes ) return;
		stats.withRatio = options.source == "range";
		changeImageStats( editor, options.value, stats );
		editor.objects && editor.objects.images ?
			editor.objects.images.updateAllListeners() : void 0;
		if ( !Validator.isObject( editor.balloons ) ||
			!Validator.isFunction( editor.balloons.isActive ) ||
			!Validator.isFunction( editor.balloons._showOnNode ) ||
			!editor.balloons.isActive( "image" ) ) return;
		let id = Validator.isObject( stats.attributes ) &&
			Validator.isString( stats.attributes.id ) ? stats.attributes.id :
			Validator.isObject( stats.node ) && Validator.isMap( stats.node._attrs ) ?
			stats.node._attrs.get( "id" ) : void 0;
		if ( !Validator.isString( id ) ) return;
		let imageNode = window.document.getElementById( id );
		if ( Validator.isFunction( editor.balloons.hideAll ) )
			editor.balloons.hideAll();
		editor.balloons._showOnNode( "image", imageNode );
	}
}
