import PluginUtils from '../../pisautils/pluginutils';
import Validator from '../../pisautils/validator';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';

const LOG_CHANGES = true;
const ADD_TIMESTAMP = true;
const ADD_COUNTER = true;
const LOG_OUTPUT_COLOR = "#26c281";

export default class PlaceholderValuesUI {

	constructor( parent ) {

		// console.log( "PlaceholderValuesUI" );

		new PluginUtils( {
			hostPlugin: this,
			logChanges: LOG_CHANGES,
			logOutputColor: LOG_OUTPUT_COLOR,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );

		Object.defineProperty( parent, "valuesEditing", {
			value: this,
			writable: false
		} );

		Object.defineProperty( this, "placeholdersUi", {
			value: parent,
			writable: false
		} );

		Object.defineProperty( this, "upcastWriter", {
			value: new UpcastWriter(),
			writable: false
		} );

		this.unmodifiableGetter( "editor", () => {
			return Validator.isObjectPath( this, "this.placeholdersUi.editor" ) ?
				this.placeholdersUi.editor : void 0;
		} );

		this.unmodifiableGetter( "wdgId", () => {
			return Validator.isObjectPath( this, "this.placeholdersUi.editor" ) &&
				Validator.isString( this.placeholdersUi.editor.wdgId ) ?
				this.placeholdersUi.editor.wdgId : void 0;
		} );

		this.unmodifiableGetter( "editingEnabled", () => {
			return Validator.isObjectPath( this, "this.placeholdersUi" ) &&
				Validator.isBoolean( this.placeholdersUi.editingEnabled ) ?
				this.placeholdersUi.editingEnabled : void 0;
		} );

		this.unmodifiableGetter( "changesAllowed", () => {
			return Validator.isObjectPath( this, "this.placeholdersUi" ) &&
				Validator.isBoolean( this.placeholdersUi.changesAllowed ) ?
				this.placeholdersUi.changesAllowed : void 0;
		} );

		this.unmodifiableGetter( "objectsPosition", () => {
			return Validator.isObjectPath( this, "this.editor.objects.position" ) ?
				this.editor.objects.position : void 0;
		} );

		this.unmodifiableGetter( "objectsSelection", () => {
			return Validator.isObjectPath( this, "this.editor.objects.selection" ) ?
				this.editor.objects.selection : void 0;
		} );

		this.unmodifiableGetter( "objectsPlaceholders", () => {
			return Validator.isObjectPath( this, "this.editor.objects.placeholders" ) ?
				this.editor.objects.placeholders : void 0;
		} );

		this.lastFocusedPlaceholder = void 0;
		this.lastInsert = void 0;
		this.registerOperations = false;
		this.keydownOnSelectionInPlaceholder = false;
		this.ignoreNextSelectionChange = false;
		this.keyAtPlaceholderStart = void 0;

		this.editor.on( "editorContentSet", () => {
			this.registerOperations = Validator.isString( this.editor.wdgId );
		}, { priority: Number.MIN_SAFE_INTEGER } );

		// console.log( "TODO: attribute operation on inline selection containing both inline & multiline plh" );
		// console.log( "TODO: when link on selection containing full inline plh, plh does not disappear" );
		// console.log( "TODO: Insert emoji in placeholder" );
		// console.log( "TODO: Add title attribute to multiline placeholder divs" );
		// console.log( "TODO: Remove batches attached to placeholder removal on toggle" );
		// console.log( "TODO: Find batch on document fragment insertion registration" );
		// console.log( "TODO: When placeholder is inside table, formatting (bold, italic etc.) disappears after toggle." );
		// console.log( "TODO: Find out if removing placeholders from the map when editing them does not cause problems when placeholders are being restaurated by undo." );
		// console.log( "TODO: Copy, Cut and Paste with balloon" );
		// console.log( "TODO: on backspace right after first character inside placeholder and removal of placeholder values, restore placeholder values" );
	}

	get react() {
		return !!this.changesAllowed && !!this.registerOperations &&
			!!Validator.isObject( this.editor ) && this.editor.state === "ready";
	}

	resetKeydownOnSelection() {
		this.keydownOnSelectionInPlaceholder = false;
	}

	setKeydownOnSelection() {
		this.keydownOnSelectionInPlaceholder = true;
	}

	cleanUp( returnValue ) {
		this._cleanUp();
		this.deactivateFocusedPlaceholder();
		return returnValue;
	}

	_cleanUp( returnValue ) {
		this.lastInsert = void 0;
		this.keyAtPlaceholderStart = void 0;
		return returnValue;
	}

}
