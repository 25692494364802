/**
 * PiSA objects plugin
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaDP from '../../pisaext/pisadp';

export default class PisaObjects extends Plugin {

	constructor( editor ) {
		super( editor );
		this.orgDP = null;
	}

	init() {
		// add our own properties
		const editor = this.editor;
		editor.counters = {};
		editor.objects = editor.objects || {};
		// add collection of UI objects
		editor.objects.uiObj = [];
		// hook in our data processor
		this._iniDP( editor );
	}

	destroy() {
		// drop everything that we've added
		const editor = this.editor;
		this._dropUI( editor );
		this._dropDP( editor );
		_dropAll( editor.counters );
		_dropAll( editor.objects );
		delete editor.objects;
		delete editor.counters;
		delete this.orgDP;
		super.destroy();
		delete this.editor;
	}

	_iniDP( editor ) {
		// hook in our data processor
		const op = editor.data.processor || null;
		this.orgDP = op;
		const dp = new PisaDP( op, editor )
		editor.data.processor = dp;
		if ( typeof this.editor.getPsaDP === 'undefined' ) {
			this.editor.getPsaDP = function() {
				return dp;
			}
		}
	}

	_dropDP( editor ) {
		// remove our data processor
		const dp = editor.data.processor;
		editor.data.processor = this.orgDP;
		delete editor.getPsaDP;
		// cleanup data processor
		dp.destroy();
	}

	_dropUI( editor ) {
		// destroy all known UI objects
		const uio = editor.objects.uiObj || [];
		uio.forEach( ( v, i, a ) => {
			if ( v && ( typeof v.destroy === 'function' ) ) {
				v.destroy();
			}
		} );
	}
}

function _dropAll( o ) {
	if ( ( o !== null ) && ( typeof o === 'object' ) ) {
		const keys = Object.getOwnPropertyNames( o );
		const arr = Array.isArray( o );
		keys.forEach( ( n, i, a ) => {
			if ( !arr ) {
				delete o[ n ];
			} else {
				_dropAll( o[ n ] );
			}
		} );
	}
}
