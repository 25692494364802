import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import View from '@ckeditor/ckeditor5-ui/src/view';
import BalloonPanelView from '@ckeditor/ckeditor5-ui/src/panel/balloon/balloonpanelview';
import { isLinkElement } from '@ckeditor/ckeditor5-link/src/utils';
import { addClasses, onDomEvent } from '../../utils';
import {
	assignDefaultPosition,
	getDomSelectionRect,
	isBalloonAbove,
	calcLeft,
	calcTop
} from '../../pisapanelview/pisapanelballoons';

import PluginUtils from '../../pisautils/pluginutils';
import Validator from '../../pisautils/validator';
import HtmHelper from '../../pisautils/htmhelper';

export const LOG_COLOR = "#a96dad";
export const LINK_BALLOON_ID_PREFIX = "pisa-link-balloon-id-";
const DEFAULT_LIMITER_ELEMENT = global.document.body;

export default class LinkBalloon extends Plugin {

	constructor( editor, name ) {
		super( editor );
		new PluginUtils( {
			hostPlugin: this,
			logOutputColor: LOG_COLOR,
			errorOutputColor: LOG_COLOR
		} );
		if ( Validator.isString( name ) )
			this.unmodifiableGetter( "name", () => { return name; } );
	}

	set isFrozen( newValue ) {
		if ( !Validator.isBoolean( newValue ) )
			return this._log( `Could not set the frozen state of the link` +
				` ${ this.name } balloon because the new value of the state is not of` +
				` type boolean.` );
		this._isFrozen = newValue;
	}

	get isFrozen() {
		return this._isFrozen;
	}

	freeze() {
		this._isFrozen = true;
		this._log( `The link ${ this.name } balloon in CK Editor 5 is now frozen.` +
			` The balloon panel will not be shown until the balloon is revived from` +
			` it's frozen state.` );
	}

	revive() {
		this._isFrozen = false;
		this._log( `The link ${ this.name } balloon in CK Editor 5 is now revived` +
			` from it's frozen state.` );
	}

	get readOnlyMode() {
		const readOnly = Validator.isObject( this.editor ) &&
			Validator.isBoolean( this.editor.isReadOnly ) && this.editor.isReadOnly;
		if ( readOnly ) this._log( `The link ${ this.name } will not be shown in` +
			` the CK Editor 5 in read only mode. Enable editing to be able to show` +
			` the balloon.` );
		return readOnly;
	}

	get editableElement() {
		return !Validator.isObjectPath( this, "this.editor.ui.view.editable" ) ||
			!( this.editor.ui.view.editable.element instanceof Element ) ? void 0 :
			this.editor.ui.view.editable.element;
	}

	get editorDomRect() {
		const editableElement = this.editableElement;
		if ( !( editableElement instanceof HTMLElement ) ) return void 0;
		return editableElement.getBoundingClientRect();
	}

	// get linkUiPlugin() {
	// 	return Validator.isObjectPath( this.editor, "editor.plugins" ) &&
	// 		Validator.isMap( this.editor.plugins._plugins ) ?
	// 		zhis.editor.plugins._plugins.get( "LinkUI" ) : void 0;
	// }

	get linkCommand() {
		if ( !Validator.isObjectPath( this.editor, "editor.commands._commands" ) )
			return void 0;
		return this.editor.commands._commands.get( "link" );
	}

	get manualDecorators() {
		const linkCommand = this.linkCommand;
		if ( !Validator.couldBe( linkCommand, "Command" ) ) return void 0;
		const manualDecorators = linkCommand.manualDecorators;
		return Validator.couldBe( manualDecorators, "Collection" ) ?
			manualDecorators : void 0;
	}

	init() {
		this._linkElementId = void 0;
		this._isFrozen = false;
		// Object.defineProperty( this, "linkUiPlugin", {
		// 	writable: false,
		// 	configurable: false,
		// 	value: Validator.isObjectPath( this.editor, "editor.plugins" ) &&
		// 		Validator.isMap( this.editor.plugins._plugins ) ?
		// 		this.editor.plugins._plugins.get( "LinkUI" ) : void 0
		// } );
		this.spacing = 0;
		this.initPanel();
		this.initPositions();
		this.editor.on( "change:isReadOnly", ( eventInfo, name, newValue, oldValue ) => {
			if ( !newValue || oldValue ) return;
			this.hide();
		} );
	}

	set linkElementId( newValue ) {
		if ( !Validator.isString( newValue ) && newValue !== void 0 )
			return this._log( `Could not set the ID of the last link element visited` +
				` by the ${ this.name } balloon, because the value to be set is not` +
				` a valid id value.`, false );
		this._linkElementId = newValue;
		return true;
	}

	get linkElementId() {
		return this._linkElementId;
	}

	nullifyLinkId() {
		this._linkElementId = void 0;
	}

	initPanel() {
		const editor = this.editor;
		this.panel = new BalloonPanelView( editor.locale );
		addClasses( this.panel, [ "pisa-balloon-panel" ] );
		editor.ui.view.body.add( this.panel );
		editor.ui.focusTracker.add( this.panel.element );
		delete this.initPanel;
	}

	initPositions() {
		const editor = this.editor;
		this.positions = {};
		const positions = BalloonPanelView.defaultPositions;
		this.positions.top = [
			positions.northWestArrowSouth,
			positions.northWestArrowSouthWest,
			positions.northWestArrowSouthEast,
			positions.southWestArrowNorth,
			positions.southWestArrowNorthWest,
			positions.southWestArrowNorthEast
		];
		this.positions.base = [
			positions.southEastArrowNorth,
			positions.southEastArrowNorthEast,
			positions.southEastArrowNorthWest,
			positions.northEastArrowSouth,
			positions.northEastArrowSouthEast,
			positions.northEastArrowSouthWest
		];
		this.positions.limiter = () => {
			return this.editableElement;
		};
		delete this.initPositions;
	}

	addView( view ) {
		if ( this.readOnlyMode || this.isFrozen ) return false;
		if ( !( this.panel instanceof BalloonPanelView ) )
			return this._log( `Could not add view to the link ${ this.name }` +
				` balloon panel because the panel does not exist.`, false );
		if ( !( view instanceof View ) )
			return this._log( `Could not add view to the link ${ this.name }` +
				` balloon panel because the view is invalid.`, false );
		this.panel.content.add( view );
		return true;
	}

	get isVisible() {
		return Validator.isObject( this.panel ) && this.panel.isVisible;
	}

	vizualize() {
		if ( this.readOnlyMode || this.isFrozen ) return false;
		if ( !( this.panel instanceof BalloonPanelView ) )
			return this._log( `Could not vizualize the link ${ this.name }` +
				` balloon panel because it does not exist.`, false );
		this._vizualize();
		return true;
	}

	_vizualize() {
		this.panel.show();
		this.panel.isVisible = true;
		this.panel.set( "isVisible", true );
	}

	hide() {
		if ( !( this.panel instanceof BalloonPanelView ) )
			return this._log( `Could not hide the link ${ this.name }` +
				` balloon panel because it does not exist.`, false );
		this._hide();
	}

	_hide() {
		this.panel.hide();
		this.panel.isVisible = false;
		this.panel.set( "isVisible", false );
		return this.removeFlexCss( this.panel.element );
	}

	displayOn( linkElement, showAbove = false ) {
		if ( !Validator.isObject( linkElement ) )
			return this._log( `Could not display the link ${ this.name } balloon on` +
				` an invalid link element.`, false );
		this.setLinkId( linkElement );
		return this.display( linkElement.linkElementId, showAbove );
	}

	display( linkElementId, showAbove = false ) {
		this.linkElementId = linkElementId;
		return this.show( showAbove );
	}

	show( showAbove = false ) {
		if ( this.readOnlyMode || this.isFrozen ) return false;
		if ( !( this.panel instanceof BalloonPanelView ) )
			return this._log( `Could not show the link ${ this.name }` +
				` balloon panel because it does not exist.`, false );
		// let positionData = this.getLinkPositionData( !!showAbove );
		let positionData = this.getPositionData( !!showAbove );
		if ( !positionData ) return false;
		return this.attachTo( positionData );
	}

	set withArrow( showArrow ) {
		if ( !Validator.isObject( this.panel ) ) return false;
		const doShowArrow = !!showArrow;
		this.panel.withArrow = doShowArrow;
		this.panel.set( "withArrow", doShowArrow );
		return true;
	}

	get isInsideLink() {
		const linkElement = this.selectedLinkElement;
		return Validator.isObject( linkElement );
	}

	get isInsideSameLink() {
		const linkElementId = this.linkElementId;
		if ( !Validator.isString( linkElementId ) ) return false;
		const linkElement = this.selectedLinkElement;
		if ( !Validator.isObject( linkElement ) ) return false;
		return linkElement.linkElementId === linkElementId;
	}

	get selectedLinkElement() {
		if ( !Validator.isObjectPath( this.editor,
				"editor.editing.view.document.selection" ) ) return void 0;
		const view = this.editor.editing.view;
		const selection = view.document.selection;

		if ( selection.isCollapsed )
			return this.findLinkElementAncestor( selection.getFirstPosition() );

		const range = selection.getFirstRange().getTrimmed();
		const startLink = this.findLinkElementAncestor( range.start );
		const endLink = this.findLinkElementAncestor( range.end );

		if ( !startLink || startLink != endLink ) return void 0;

		// the condition returns false when you select a small part of the link
		// instead of the whole link
		// if ( !view.createRangeIn( startLink ).getTrimmed().isEqual( range ) )
		// 	return void 0;

		this.setLinkId( startLink );

		return startLink;
	}

	setLinkId( link ) {
		if ( !Validator.isObject( link ) ) return link;
		// already has an ID
		if ( Validator.isString( link.linkElementId ) ) return link;
		link.linkElementId = HtmHelper.generateRandomString( LINK_BALLOON_ID_PREFIX );
		return link;
	}

	findLinkElementAncestor( position ) {
		return position.getAncestors().find( ancestor => isLinkElement( ancestor ) );
	}

	_positionDataFromViewRange( viewRange, showAbove = false ) {
		if ( !Validator.isObject( viewRange ) )
			return this._log( `Could not create link ${ this.name } balloon` +
				` position data from invalid view range.`, null );
		let target = null;
		try {
			target = this.editor.editing.view.domConverter.viewRangeToDom( viewRange );
		} catch ( err ) {
			return this._error( err, `Balloon position target not valid.`, null );
		}
		return this.createPositionData( target, showAbove );
	}

	createPositionData( range, showAbove = false ) {
		return {
			target: range,
			positions: showAbove ? this.positions.top : this.positions.base,
			limiter: this.positions.limiter
		};
	}

	getLinkPositionData( showAbove = false ) {
		const linkElement = this.selectedLinkElement;
		if ( !Validator.isObject( linkElement ) )
			return this.getPositionData( showAbove );
		const target = this.editor.editing.view.domConverter.mapViewToDom( linkElement );
		return this.createPositionData( target, showAbove );
		// const viewRange = this.editor.editing.view.createRangeOn( linkElement );
		// return this._positionDataFromViewRange( viewRange, showAbove );
	}

	getPositionData( showAbove = false ) {
		return this._positionDataFromViewRange(
			this.editor.editing.view.document.selection.getFirstRange(), showAbove );
	}

	getNodePositionData( node, showAbove = false ) {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		let domRange = document.createRange();
		domRange.setStart( node, 0 );
		domRange.setEnd( node, 1 );
		return this.createPositionData( domRange, showAbove );
	}

	addFlexCss( element, width ) {
		if ( !( element instanceof HTMLElement ) ) return false;
		if ( !Validator.isString( width ) && !Validator.isNumber( width ) )
			return false;
		element.style.maxWidth = `${ width }px`;
		element.style.display = "flex";
		element.style.flexWrap = "wrap";
		return true;
	}

	removeFlexCss( element ) {
		if ( !( element instanceof HTMLElement ) ) return false;
		element.style.maxWidth = "";
		element.style.display = "";
		element.style.flexWrap = "";
		return true;
	}

	addScrollListener() {
		const hide = () => {
			this.hide();
		}
		onDomEvent( this.editor, "scroll", hide );
	}

	addRightClickListener() {
		const hide = () => {
			this.hide();
		}
		onDomEvent( this.editor, "contextmenu", hide );
	}

	attachTo( options, nodeRect = null ) {
		if ( this.readOnlyMode || this.isFrozen ) return false;
		if ( !( this.panel instanceof BalloonPanelView ) )
			return this._log( `Could not attach the link ${ this.name }` +
				` balloon panel because it does not exist.`, false );
		this._vizualize();
		const panel = this.panel;

		const defaultPositions = BalloonPanelView.defaultPositions;
		const positionOptions = Object.assign( {}, {
			element: panel.element,
			positions: [
				defaultPositions.southArrowNorth,
				defaultPositions.southArrowNorthWest,
				defaultPositions.southArrowNorthEast,
				defaultPositions.northArrowSouth,
				defaultPositions.northArrowSouthWest,
				defaultPositions.northArrowSouthEast
			],
			limiter: DEFAULT_LIMITER_ELEMENT,
			fitInViewport: true
		}, options );

		let panelRect = panel.element.getBoundingClientRect();
		const targetRect = getDomSelectionRect();
		const editorRect = this.editorDomRect;
		if ( !editorRect ) {
			this._log( `Could not get editor's editable element coordinates needed` +
				` to place the link ${ this.name } balloon panel. Default coordinates` +
				` will be assigned.` );
			assignDefaultPosition( panel, positionOptions );
			return true;
		}

		if ( panelRect.width > editorRect.width ) {
			this._log( `Link ${ this.name } balloon panel width is larger than editor width.` );
			this.addFlexCss( panel.element, editorRect.width );
			panelRect = panel.element.getBoundingClientRect();
		} else if ( panelRect.width < editorRect.width ) {
			this.removeFlexCss( panel.element );
		}

		let optimalPosition = BalloonPanelView._getOptimalPosition( positionOptions );

		const left = calcLeft( {
			positionName: optimalPosition.name,
			defaultLeft: optimalPosition.left,
			editorLeft: editorRect.left,
			editorX: editorRect.x,
			editorRight: editorRect.right,
			editorWidth: editorRect.width,
			panelWidth: Validator.isObject( panelRect ) ? panelRect.width : 0,
			targetWidth: Validator.isObject( targetRect ) ? targetRect.width : 0,
			targetLeft: Validator.isObject( targetRect ) ? targetRect.left : 0,
			nodeWidth: Validator.isObject( nodeRect ) ? nodeRect.width : 0
		} );

		const top = calcTop( {
			isAbove: isBalloonAbove( optimalPosition.name ),
			defaultTop: optimalPosition.top,
			editorTop: editorRect.top,
			editorY: editorRect.y,
			editorHeight: editorRect.height,
			editorBottom: editorRect.bottom,
			extraSpacing: this.spacing,
			panelHeight: panelRect.height
		} );

		const position = optimalPosition.name;

		Object.assign( panel, { top, left, position } );
		return true;
	}

}
