import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { PISA_IMG_MODEL } from '../pisaimage/pisaimageediting';
import { downcastStyleTagAttr, upcastStyleTagAttr } from '../utils';
import PisaImageEditing from '../pisaimage/pisaimageediting';

export const VERTICAL_ALIGN = "pisaVerticalAlign";

export default class PisaImagePositionEditing extends Plugin {

	static get requires() {
		return [ PisaImageEditing ];
	}

	init() {
		const editor = this.editor;
		editor.model.schema.extend( PISA_IMG_MODEL, { allowAttributes: VERTICAL_ALIGN } );
		upcastStyleTagAttr( editor, "img", "vertical-align", VERTICAL_ALIGN );
		downcastStyleTagAttr( editor, PISA_IMG_MODEL, VERTICAL_ALIGN, "vertical-align" );
	}

}
