import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';
import AttachmentObject from './attachmentobject';

const PRIORITIZER = {
	max: { priority: Number.POSITIVE_INFINITY },
	secondMax: { priority: Number.MAX_VALUE },
	thirdMax: { priority: Number.MAX_SAFE_INTEGER },
	fourthMax: { priority: Number.MAX_SAFE_INTEGER - 1 },
	fifthMax: { priority: Number.MAX_SAFE_INTEGER - 2 },
	highest: { priority: "highest" },
	high: { priority: "high" },
	normal: { priority: "normal" },
	low: { priority: "low" },
	lowest: { priority: "lowest" },
	fifthMin: { priority: Number.MIN_SAFE_INTEGER + 3 },
	fourthMin: { priority: Number.MIN_SAFE_INTEGER + 2 },
	thirdMin: { priority: Number.MIN_SAFE_INTEGER + 1 },
	secondMin: { priority: Number.MIN_SAFE_INTEGER },
	min: { priority: Number.NEGATIVE_INFINITY }
};

export default class PluginUtils extends AttachmentObject {

	constructor( {
		hostPlugin,
		logChanges = true,
		addTimestamp = true,
		addCounter = true,
		traceLog = true,
		traceError = true,
		logOutputColor,
		errorOutputColor
	} ) {

		if ( !Validator.isObject( hostPlugin ) ) return;

		super( hostPlugin );

		logOutputColor = Validator.isString( logOutputColor ) ||
			Validator.isPositiveInteger( logOutputColor ) ? logOutputColor :
			Validator.isString( errorOutputColor ) ||
			Validator.isPositiveInteger( errorOutputColor ) ? errorOutputColor : 1;

		errorOutputColor = Validator.isString( errorOutputColor ) ||
			Validator.isPositiveInteger( errorOutputColor ) ? errorOutputColor :
			Validator.isString( logOutputColor ) ||
			Validator.isPositiveInteger( logOutputColor ) ? logOutputColor : 1;

		hostPlugin.unmodifiableGetter( "_logParameters", () => {
			return {
				hostPlugin: hostPlugin,
				logChanges: !!logChanges,
				addTimestamp: !!addTimestamp,
				addCounter: !!addCounter,
				traceLog: !!traceLog,
				traceError: !!traceError,
				logOutputColor: logOutputColor,
				errorOutputColor: errorOutputColor
			};
		} );

		let className = Object.getPrototypeOf( hostPlugin ).constructor.name ||
			hostPlugin.constructor.name;

		if ( Validator.isString( className ) )
			hostPlugin.unmodifiableGetter( "className", () => {
				return className;
			} );

		if ( Validator.isObject( hostPlugin.editor ) )
			hostPlugin.unmodifiableGetter( "wdgId", () => {
				return !Validator.isString( hostPlugin.editor.wdgId ) ? void 0 :
					hostPlugin.editor.wdgId;
			} );

		hostPlugin.unmodifiableGetter( "priority", () => {
			return PRIORITIZER;
		} );

	}

	unmodifiableGetter( propertyName, callback ) {
		this.addUnmodifiableGetter( {
			propertyName: propertyName,
			callback: callback
		} );
	}

	addUnmodifiableGetter( { propertyName, callback } ) {
		Object.defineProperty( this, propertyName, {
			get: () => {
				return callback();
			},
			set: ( newPropertyValue ) => {
				this._logAsUnmodifiable( propertyName, newPropertyValue );
			}
		} );
	}

	_logAsUnmodifiable( propertyName, imposedPropertyValue, returnValue,
		configurable = false, writable = false ) {
		return this._log( Warner.generateNonModifiableMessage( {
			propertyName: propertyName,
			imposedPropertyValue: imposedPropertyValue,
			actualPropertyValue: this[ propertyName ],
			objectClassName: this.className,
			configurable: configurable,
			writable: writable
		} ), returnValue );
	}

	_log( text, returnValue ) {
		return Warner.outputIf( this._logParameters.logChanges, returnValue, {
			trace: this._logParameters.traceLog,
			collapse: true,
			text: text,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: this._logParameters.logOutputColor,
			addTimestamp: this._logParameters.addTimestamp,
			addCounter: this._logParameters.addCounter
		} );
	}

	_error( error, text, returnValue ) {
		return Warner.outputIf( this._logParameters.logChanges, returnValue, {
			error: true,
			errorText: error,
			trace: this._logParameters.traceError,
			collapse: true,
			text: text,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: this._logParameters.errorOutputColor,
			addTimestamp: this._logParameters.addTimestamp,
			addCounter: this._logParameters.addCounter
		} );
	}

	_removeProperty( propertyName, doDestroy = true ) {
		if ( !Validator.isString( propertyName ) )
			return this._log( `Could not remove property with invalid property name.` );
		let propertyValue = this[ propertyName ];
		if ( !Validator.isObject( propertyValue ) ) return delete this[ propertyName ];
		this[ propertyName ] = void 0;
		delete this[ propertyName ];
		if ( doDestroy && Validator.isFunction( propertyValue.destroy ) )
			propertyValue.destroy();
		propertyValue = void 0;
	}

}
