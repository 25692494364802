import Command from '@ckeditor/ckeditor5-core/src/command';
import { MARK } from './pisamarkediting';
import Validator from '../pisautils/validator';

export const REMOVE_ALL_MARKS = "pisaRemoveAllMark";

export default class RemoveAllMarkCommand extends Command {

	constructor( editor ) {
		super( editor, REMOVE_ALL_MARKS );
	}

	execute( options = {} ) {
		const editor = this.editor;
		let batch = Validator.isObject( options.batch ) ?
			options.batch : editor.model.createBatch();
		removeAllMarks( editor, batch );
		removeAllMarks( editor, batch );
		removeAllMarks( editor, batch );
		// removeAllMarks( editor, batch );
	}
}

export function removeAllMarks( editor, batch = void 0 ) {
	if ( !Validator.isObject( batch ) ) batch = editor.model.createBatch();
	let main = editor.data.model.document.getRoot( "main" );
	editor.model.enqueueChange( batch, writer => {
		unmarkChildren( writer, main );
	} );
}

function unmarkChildren( writer, element ) {
	if ( Validator.is( element, "Text" ) ) {
		if ( element.hasAttribute( MARK ) )
			writer.removeAttribute( MARK, element );
		// removeMarkAttribute( writer, element );
		return;
	}
	if ( !element._children || element._children.length <= 0 ) return;
	for ( let nodeChildElement of element._children._nodes ) {
		unmarkChildren( writer, nodeChildElement );
	}
}

function removeMarkAttribute( writer, element ) {
	writer.setAttribute( MARK, false, element );
	writer.setAttribute( MARK, void 0, element );
	writer.removeAttribute( MARK, element );
	element._attrs.delete( MARK );
}
