import Validator from './validator';

const COLORS = [ "#f62459", "#a537fd", "#4d05e8", "#26a65b", "#f89406", "#ffb3cc" ];
const ADD_TIMESTAMP = true;

export default class Warner {

	static output( properties, returnValue ) {
		return Warner.outputIf( true, returnValue, properties );
	}

	static trace( text, pluginName = void 0, colorOrColorNumber, returnValue,
		addTimestamp = void 0, addCounter = void 0, collapse = void 0 ) {
		Warner._trace( text, pluginName, colorOrColorNumber, addTimestamp, addCounter,
			collapse );
		return returnValue;
	}

	static error( error, text, pluginName = void 0, colorOrColorNumber,
		returnValue, addTimestamp = void 0, addCounter = void 0, collapse = void 0 ) {
		Warner._error( error, text, pluginName, colorOrColorNumber,
			addTimestamp, addCounter, collapse );
		return returnValue;
	}

	static warn( text, pluginName = "", returnValue, addTimestamp = void 0 ) {
		Warner._warn( text, pluginName, addTimestamp );
		return returnValue;
	}

	static info( text, pluginName = void 0, colorOrColorNumber, returnValue,
		addTimestamp = void 0 ) {
		Warner._info( text, pluginName, colorOrColorNumber, addTimestamp );
		return returnValue;
	}

	static log( text, pluginName = void 0, colorOrColorNumber, returnValue,
		addTimestamp = void 0 ) {
		Warner._log( text, pluginName, colorOrColorNumber, addTimestamp );
		return returnValue;
	}

	static outputIf( outputAllowed, returnValue, properties ) {
		if ( !outputAllowed ) return returnValue;
		return !!properties.error ?
			Warner.errorIf( outputAllowed, returnValue, properties ) :
			!!properties.warn ?
			Warner.warnIf( outputAllowed, returnValue, properties ) :
			!!properties.trace ?
			Warner.traceIf( outputAllowed, returnValue, properties ) :
			!!properties.info ?
			Warner.infoIf( outputAllowed, returnValue, properties ) :
			Warner.logIf( outputAllowed, returnValue, properties );
	}

	static traceIf( traceAllowed, returnValue, properties ) {
		if ( !traceAllowed ) return returnValue;
		return Warner.trace( properties.text, properties.pluginName, properties.color,
			returnValue, properties.addTimestamp, properties.addCounter, properties.collapse );
	}

	static errorIf( traceAllowed, returnValue, properties ) {
		if ( !traceAllowed ) return returnValue;
		return Warner.error( properties.errorText, properties.text,
			properties.pluginName, properties.color, returnValue,
			properties.addTimestamp, properties.addCounter, properties.collapse );
	}

	static warnIf( warnAllowed, returnValue, properties ) {
		if ( !warnAllowed ) return returnValue;
		return Warner.warn( properties.text, properties.pluginName, returnValue,
			properties.addTimestamp );
	}

	static infoIf( infoAllowed, returnValue, properties ) {
		if ( !infoAllowed ) return returnValue;
		return Warner.info( properties.text, properties.pluginName, properties.color,
			returnValue, properties.addTimestamp );
	}

	static logIf( logAllowed, returnValue, properties ) {
		if ( !logAllowed ) return returnValue;
		return Warner.log( properties.text, properties.pluginName, properties.color,
			returnValue, properties.addTimestamp );
	}

	static _trace( text, pluginName = void 0, colorOrColorNumber,
		addTimestamp = void 0, addCounter = void 0, collapse = void 0 ) {
		let output = createOutput( text, "", addTimestamp, true );
		let style = createStyle( colorOrColorNumber );
		console.group( output, style );
		Warner.countWarningIf( addCounter, pluginName );
		if ( collapse ) {
			console.groupCollapsed( "%c Call Stack", style );
			console.trace( "%c ", style );
			console.groupEnd();
		} else {
			console.trace( "%c Call Stack", style );
		}
		console.groupEnd();
	}

	static _error( error, text, pluginName = void 0, colorOrColorNumber,
		addTimestamp = void 0, addCounter = void 0, collapse = void 0 ) {
		let output = createOutput( text, "", addTimestamp, true );
		let style = createStyle( colorOrColorNumber );
		console.group( output, style );
		console.groupCollapsed( "%cError", style );
		console.warn( error );
		console.groupEnd();
		Warner.countWarningIf( addCounter, pluginName );
		if ( collapse ) {
			console.groupCollapsed( "%cCall Stack", style );
			console.trace( "%c ", style );
			console.groupEnd();
		} else {
			console.trace( "%cCall Stack", style );
		}
		console.groupEnd();
	}

	static _warn( text, pluginName = "", addTimestamp = void 0 ) {
		let output = createOutput( text, pluginName, addTimestamp, false );
		console.warn( output );
	}

	static _info( text, pluginName = void 0, colorOrColorNumber,
		addTimestamp = void 0 ) {
		let output = createOutput( text, pluginName, addTimestamp, true );
		let style = createStyle( colorOrColorNumber );
		console.info( output, style );
	}

	static _log( text, pluginName = void 0, colorOrColorNumber,
		addTimestamp = void 0 ) {
		let output = createOutput( text, pluginName, addTimestamp, true );
		let style = createStyle( colorOrColorNumber );
		console.group( output, style );
		console.groupEnd();
		// console.log( output, style );
	}

	static nameTrace( logMessage ) {
		let fullFunctionName = Warner.getFullFunctionName( 1, "." );
		let message = "";
		if ( Validator.isString( fullFunctionName ) ) message += fullFunctionName;
		if ( Validator.isString( logMessage ) )
			message += !Validator.isString( message ) ? logMessage : "\n" + logMessage;
		Warner._trace( message, "", 1, true, false, true );
	}

	static getFullFunctionName( topEntriesToRemove = 0, separator = "#" ) {
		topEntriesToRemove = Validator.isInteger( topEntriesToRemove ) ?
			topEntriesToRemove + 2 : 2;
		let stack = Warner.getStack( topEntriesToRemove );
		if ( !Validator.isString( stack ) ) return void 0;
		if ( stack.startsWith( "at " ) ) {
			stack = stack.substring( 3 );
		}
		const spaceIndex = stack.indexOf( " " );
		if ( !Validator.isInteger( spaceIndex ) || spaceIndex < 0 ||
			stack.length - 1 < spaceIndex + 1 ) return stack;
		stack = stack.substring( 0, spaceIndex );
		const atIndex = stack.indexOf( "@" );
		if ( Validator.isInteger( atIndex ) && atIndex >= 0 &&
			stack.length - 1 >= atIndex + 1 ) stack = stack.substring( 0, atIndex );
		if ( Validator.isString( separator ) ) {
			stack = stack.replace( ".", separator );
		}
		return stack;
	}

	static getStack( topEntriesToRemove = 1 ) {
		const err = new Error();
		if ( !Validator.isObject( err ) ) return void 0;
		let stack = err.stack;
		if ( !Validator.isString( stack ) ) return void 0;
		if ( !Validator.isInteger( topEntriesToRemove ) )
			topEntriesToRemove = 1;
		if ( Validator.firstMatchIndex( stack, /\sat\s/g ) < 0 ) topEntriesToRemove--;
		while ( topEntriesToRemove > -1 ) {
			const firstNewLineIndex = stack.indexOf( "\n" );
			if ( !Validator.isInteger( firstNewLineIndex ) || firstNewLineIndex < 0 ||
				stack.length - 1 < firstNewLineIndex + 1 ) break;
			stack = stack.substring( firstNewLineIndex + 1 );
			topEntriesToRemove--;
		}
		while ( stack.length > 0 && !!stack.charAt( 0 ).match( /\s/gi ) )
			stack = stack.substring( 1 );
		return stack;
	}

	static getOutput( message, pluginName, addTimestamp = true ) {
		return createOutput( message, pluginName, addTimestamp, false );
	}

	static countWarningIf( countAllowed, label, returnValue ) {
		return !countAllowed ? returnValue :
			Warner.countWarning( label, returnValue );
	}

	static countWarning( label, returnValue ) {
		Warner._countWarning( label );
		return returnValue;
	}

	static _countWarning( label ) {
		Validator.isString( label ) ? console.count( label + " warning number" ) :
			console.count();
	}

	static countIf( countAllowed, label, returnValue ) {
		return !countAllowed ? returnValue : Warner.count( label, returnValue );
	}

	static count( label, returnValue ) {
		Warner._count( label );
		return returnValue;
	}

	static _count( label ) {
		Validator.isString( label ) ? console.count( label ) : console.count();
	}

	static getCurrentTime() {
		let fullDate = new Date();
		let hours = dateNumberToString( fullDate.getHours() );
		let minutes = dateNumberToString( fullDate.getMinutes() );
		let seconds = dateNumberToString( fullDate.getSeconds() );
		let milliseconds = dateNumberToString( fullDate.getMilliseconds(), true );
		return `${ hours }:${ minutes }:${ seconds }:${ milliseconds }`;
	}

	static generateNonModifiableMessage( {
		// the name of the unmodifiable property
		propertyName,
		// the imposed value of the unmodifiable property
		imposedPropertyValue,
		// whether or not the type of the imposed value for the unmodifiable
		// property should be mentioned
		includeImposedPropertyType = true,
		// the actual value of the unmodifiable property
		actualPropertyValue,
		// whether or not the actual value of the unmodifiable property should be
		// mentioned
		includeActualPropertyValue = true,
		// whether or not the type of the actual value of the unmodifiable property
		// should be mentioned
		includeActualPropertyType = true,
		// the class name (constructor name) of the parent object
		// containing/hosting/with the unmodifiable property
		objectClassName,
		// whether or not the class name (constructor name) of the parent object
		// containing/hosting/with the unmodifiable property should be mentioned
		includeClassName = true,
		// whether or not Object.defineProperty was used to define the unmodifiable
		// property
		wasDefinedWithObjectDefineProperty = true,
		// the value of the configurable flag inside Object.defineProperty
		configurable = false,
		// the value of the writable flag inside Object.defineProperty
		writable = false
	} ) {
		let imposedPropertyType = !includeImposedPropertyType ? void 0 :
			Validator.getProtoNameIf( imposedPropertyValue );
		if ( !!includeImposedPropertyType &&
			!Validator.isString( imposedPropertyType ) )
			imposedPropertyType = "<null> or <undefined>";
		let actualPropertyType = !includeActualPropertyValue ? void 0 :
			!includeActualPropertyType ? void 0 :
			Validator.getProtoNameIf( actualPropertyValue );
		if ( !!includeActualPropertyValue && !!includeActualPropertyType &&
			!Validator.isString( actualPropertyType ) )
			actualPropertyType = "<null> or <undefined>";
		return `Could not set the property "${ propertyName }" to the value` +
			` "${ imposedPropertyValue }"` +
			( !includeImposedPropertyType ? "" :
				` of type "${ imposedPropertyType }"` ) +
			( !includeClassName ? "" :
				` on the object of class "${ objectClassName}"` ) +
			( !includeActualPropertyValue ? "" :
				( ` with the actual value "${ actualPropertyValue }"` +
					( !includeActualPropertyType ? "" :
						` of type "${ actualPropertyType }"` ) ) ) +
			`, because the property can not be subjected to modification.` +
			( !wasDefinedWithObjectDefineProperty ? "" : ( ` The value is` +
				`${ ( !configurable ? " not" : "" ) } configurable` +
				` ${ ( !configurable ? ( !writable ? "nor" : "but it is" ) :
				!writable ? "but not" : "and" ) }` +
				` writable. The property was defined using <<Object.defineProperty()>>` +
				` and should not be modified. Learn more:\n` +
				` https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty` ) );
	}

}

function dateNumberToString( dateNumber, threeDigits = false ) {
	if ( !Validator.isPositiveInteger( dateNumber ) ) return "";
	let stringDateNumber = dateNumber.toString();
	while ( stringDateNumber.length < 2 ) {
		stringDateNumber = "0" + stringDateNumber;
	}
	while ( threeDigits && stringDateNumber.length < 3 ) {
		stringDateNumber = "0" + stringDateNumber;
	}
	return stringDateNumber;
}

function getText( text ) {
	return Validator.isString( text ) ? text : "";
}

function getPluginName( pluginName ) {
	return Validator.isString( pluginName ) ? pluginName : "";
}

function getColor( colorOrColorNumber ) {
	return Validator.isString( colorOrColorNumber ) ? colorOrColorNumber :
		Validator.isPositiveInteger( colorOrColorNumber ) &&
		colorOrColorNumber < COLORS.length ? COLORS[ colorOrColorNumber ] :
		COLORS[ 0 ];
}

function generateOutput( text, pluginName, addTimestamp, addStyle = true ) {
	let output = `CK Editor 5\n` +
		( pluginName.length > 0 ? `${ pluginName }\n` : "" ) +
		( !!addStyle ? "%c" : "" ) + text;
	if ( addTimestamp ) output = "[ " + Warner.getCurrentTime() + " ] " + output;
	return output;
}

function generateStyle( colorOrColorNumber ) {
	return `color: ${ colorOrColorNumber }`;
}

function createOutput( text, pluginName, addTimestamp, addStyle = true ) {
	text = getText( text );
	pluginName = getPluginName( pluginName );
	addTimestamp = getShouldAddTimestamp( addTimestamp );
	return generateOutput( text, pluginName, addTimestamp, addStyle );
}

function createStyle( colorOrColorNumber ) {
	colorOrColorNumber = getColor( colorOrColorNumber );
	return generateStyle( colorOrColorNumber );
}

function getShouldAddTimestamp( shoudlAddTimestamp ) {
	return Validator.isBoolean( shoudlAddTimestamp ) ? shoudlAddTimestamp :
		ADD_TIMESTAMP;
}
