import Command from '@ckeditor/ckeditor5-core/src/command';
import PisaBasics from '../pisabasics/pisabasics';
import { PLAINTEXT } from './pisaplaintext';
import { DISABLEBUTTONS } from './pisadisablebuttonscommand';
import { htmlToDocumentFragment } from '../utils';
import * as command from '../commandnames';
import { removeContent } from '../pisaremoveformat/pisaremoveformatcommand';
import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';

export default class PlainTextCommand extends Command {

	static get requires() {
		return [ PisaBasics ];
	}

	get dataProcessor() {
		return Validator.isObjectPath( this.editor, "editor.data.processor" ) ?
			this.editor.data.processor : Validator.isFunction( this.editor.getPsaDP ) ?
			this.editor.getPsaDP() : void 0;
	}

	constructor( editor ) {
		super( editor, PLAINTEXT );
		editor.objects = editor.objects || {};
		editor.objects.commands = {};
		editor.objects.commands.allwaysEnabled = getEnabledCommands();
		this._execmd = false;
		this.richContent = "";
		this.textContent = "";
		const self = this;
		editor.on( 'psaTextMode', ( e, plain ) => {
			self._setCmdStatus( editor, plain );
		} );
		// comment the following out in case html text restoration should not
		// happen after save

		// editor.on( "editorDataSaved", () => {
		// 	this.richContent = "";
		// 	this.textContent = "";
		// 	if ( !Validator.isObjectPath( editor, "editor.objects.plainText.batches" ) ) return;
		// 	editor.objects.plainText.batches = [];
		// } );
	}

	execute( options = {} ) {
		const editor = this.editor;

		// comment the following code out in order to generate an error when clicking
		// on the "plain text" toolbar button

		// editor.model.change( writer => {
		// 	writer.createPositionBefore( editor.model.document.getRoot() );
		// } );
		// return;

		const dp = this.dataProcessor;
		if ( !Validator.isObject( dp ) || !Validator.isFunction( dp.isPlain ) ) return;
		this._updateRichContent();
		if ( dp.isPlain() ) {
			if ( !dp.htmlAvailable ) return Warner.trace( `Editor` +
				` "${ PLAINTEXT }" command execution fail: the attempt to execute` +
				` the editor command "${ PLAINTEXT }" was unsuccessfull, because` +
				` the editor data processor does not support or allow the HTML mode.`,
				"PlainTextCommand", 1, false, true, true, true )
			// we're currently in "plain text" mode --> allow HTML and restore rich
			// content if it matches
			editor.model.enqueueChange(
				editor.objects.plainText.newToHtmlBatch, writer => {
					if ( !this._restoreRichContent( writer ) ) {
						let paragraph = writer.createElement( "paragraph" );
						let randomElement = dp._getRootChild();
						writer.insert( paragraph, randomElement, "before" );
						writer.remove( paragraph );
					}
					dp.setPlainText( false );
					editor.fire( 'psaCttChn', true );
				} );
			return this._updateTextContent();;
		}

		editor.model.enqueueChange(
			editor.objects.plainText.newToHtmlBatch, writer => {
				const ec = this._execmd;
				const cnv = dp.isInCnv();
				try {
					this._execmd = true;
					// if ( editor.objects && editor.objects.placeholders && editor.objects.placeholders.titlesShown ) {
					// 	editor.executeIf( "pisaPlaceholder", { show: false }, true );
					// }
					let cleanData = dp._rootToCleanText();
					dp.setInCnv( true );
					dp.setPlainText( true );
					removeContent( editor, writer );
					let paragraph = dp._getRootChild();
					dp._insertPlainText( cleanData );
					if ( paragraph ) writer.remove( paragraph );
				} finally {
					dp.setInCnv( cnv );
					this._execmd = ec;
				}
				this._setCmdStatus( editor, true );
			} );
		editor.fire( 'psaCttChn', true );
		this._updateTextContent();
	}

	_setCmdStatus( editor, ptx ) {
		if ( this._execmd ) return;
		if ( ptx ) editor.executeIf( DISABLEBUTTONS, {}, true );
		else enableAllowedCommands( editor );
	}

	_updateRichContent() {
		if ( this.dataProcessor.isPlain() ) return;
		this.richContent = this.editor.getData();
	}

	_updateTextContent() {
		if ( !this.dataProcessor.isPlain() ) return;
		this.textContent = this.editor.getData();
	}

	_restoreRichContent( writer ) {
		if ( !Validator.isString( this.richContent ) ) return false;
		let editor = this.editor;
		let dataProcessor = this.dataProcessor;
		// TODO avoid doing removeHtmlTags two times
		let currentData = dataProcessor._removeNonPrintableAsciiChars( editor.getData() );
		currentData = dataProcessor._removeZeroWidthSpaces( currentData );
		currentData = removeHtmlTags( removeHtmlTags( currentData ) )
			.trimRight().replace( /\s+/g, " " );
		let savedData = dataProcessor._removeNonPrintableAsciiChars( this.textContent );
		savedData = dataProcessor._removeZeroWidthSpaces( savedData );
		savedData = removeHtmlTags( savedData ).trimRight().replace( /\s+/g, " " );
		if ( currentData != savedData ) return false;
		removeContent( editor, writer );
		let paragraph = dataProcessor._getRootChild();
		dataProcessor._insertHtmStr( this.richContent )
		if ( paragraph ) writer.remove( paragraph );
		return true;

		if ( !editor.objects || !editor.objects.placeholders ) return;
		editor.objects.placeholders.clearTemp();
		editor.objects.placeholders.clearGroupTemp();
		editor.objects.placeholders.clearHtmlInlineTemp();
	}

}

export function removeHtmlTags( text ) {
	return text.replace( /<\/[^>]+>/g, "\n" ).replace( /<[^>]+>/g, "" ).replace( /&amp;/g, "&" )
		.replace( /&lt;/g, "<" ).replace( /&gt;/g, ">" ).replace( /&nbsp;/g, " " ).replace( /\n+/g, "\n" );
}

export function enableAllowedCommands( editor ) {
	const commands = editor.commands._commands;
	const allowedCommands = getAllowedCommands( editor );
	for ( const [ key, unused ] of commands.entries() ) {
		if ( allowedCommands.indexOf( key ) >= 0 ) continue;
		const cmd = editor.commands.get( key );
		cmd.off( "change:isEnabled" );
		cmd.set( "isEnabled", true );
	}
}

export function getAllowedCommands( editor ) {
	return editor && editor.objects && editor.objects.commands &&
		editor.objects.commands.allwaysEnabled && editor.objects.commands.allwaysEnabled.length > 0 ?
		editor.objects.commands.allwaysEnabled : getEnabledCommands();
}

export function getEnabledCommands() {
	const a = [];
	a.push( PLAINTEXT );
	a.push( command.fire );
	a.push( command.enter );
	a.push( command.shiftEnter );
	a.push( command.doDelete );
	a.push( command.forwardDelete );
	a.push( command.undo );
	a.push( command.redo );
	a.push( command.input );
	a.push( command.selectAll );
	a.push( command.deleteAll );
	a.push( command.changeToolbar );
	a.push( command.placeButton );
	a.push( command.disableButtons );
	a.push( command.switchVisibility );
	a.push( command.removeAllMarks );
	a.push( command.printContent );
	a.push( command.spellcheck );
	a.push( command.addParagraph );
	a.push( command.insertPisaObject );
	return a;
}
