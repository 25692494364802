import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import AlignmentEditing from '@ckeditor/ckeditor5-alignment/src/alignmentediting';;
import BoldEditing from '@ckeditor/ckeditor5-basic-styles/src/bold/boldediting';
import CodeEditing from '@ckeditor/ckeditor5-basic-styles/src/code/codeediting';
import ItalicEditing from '@ckeditor/ckeditor5-basic-styles/src/italic/italicediting';
import StrikethroughEditing from '@ckeditor/ckeditor5-basic-styles/src/strikethrough/strikethroughediting';
import UnderlineEditing from '@ckeditor/ckeditor5-basic-styles/src/underline/underlineediting';
import BlockQuoteEditing from '@ckeditor/ckeditor5-block-quote/src/blockquoteediting';
// import LinkEditing from '@ckeditor/ckeditor5-link/src/linkediting';
import PisaExtendedLinkEditing from '../pisalink/pisaextendedlinkediting';

// import LinkEditing from '../iconsui/linkediting';
// import TableEditing from '@ckeditor/ckeditor5-table/src/tableediting';
import Table from '../iconsui/table';
import PisaPrint from '../pisaprint/pisaprint';

import ListEditing from '../pisalist/listediting';
import PisaListEditing from '../pisalist/pisalistediting';
import BackgroundColorEditing from '../pisabckgcolor/pisabckgediting';
import FontColorEditing from '../pisafontcolor/pisacolorediting';
import FontFamilyEditing from '../pisafontfamily/pisafontfamilyediting';
import FontSizeEditing from '../pisafontsize/pisafontsizeediting';
import PisaFontTagEditing from '../pisafonttag/pisafonttagediting';
// import PisaMiniImageEditing from './pisaminiimageediting';
import PisaImageEditing from '../pisaimage/pisaimageediting';
import IndentEditing from '../pisaindent/pisaindentediting';
import PisaSpacingEditing from '../pisaspacing/pisaspacingediting';
import PisaSubscriptEditing from '../pisasubscript/pisasubscriptediting';
import PisaSuperscriptEditing from '../pisasuperscript/pisasuperscriptediting';
import PisaTableEditing from '../pisatable/pisatableediting';
import PisaCellEditing from '../pisatablecell/pisacellediting';
import PisaCellStyleEditing from '../pisatablecell/pisacellstyleediting';
import PisaEmptyParagraphEditing from '../pisacopyformat/pisaemptyparagraphediting';
import PisaLinkEditing from '../pisalink/pisalinkediting';

export default class AllConverters extends Plugin {

	static get requires() {
		return [
			AlignmentEditing, BoldEditing, CodeEditing, ItalicEditing, StrikethroughEditing,
			UnderlineEditing, BlockQuoteEditing, Table, //TableEditing,
			PisaExtendedLinkEditing, //LinkEditing,
			ListEditing, PisaListEditing, BackgroundColorEditing, FontColorEditing, FontFamilyEditing,
			FontSizeEditing, PisaFontTagEditing,
			// PisaMiniImageEditing,
			PisaImageEditing,
			IndentEditing,
			PisaSpacingEditing, PisaSubscriptEditing,
			PisaSuperscriptEditing, PisaTableEditing, PisaCellEditing, PisaCellStyleEditing,
			PisaEmptyParagraphEditing, PisaLinkEditing,
			PisaPrint
		];
	}

	static get pluginName() {
		return 'AllConverters';
	}
}
