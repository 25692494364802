import Command from '@ckeditor/ckeditor5-core/src/command';
import { INSERT_PARAGRAPH } from './pisainsertparagraphui';
import { getSelectionRange, getMainRoot, positionAtRoot } from '../utils';
import { isPositionInMultilinePlaceholder, getPlaceholderLineParent, isMultilinePlaceholder } from '../pisaplaceholder/pisaplaceholderui';

export default class InsertParagraphCommand extends Command {

	constructor( editor ) {
		super( editor, INSERT_PARAGRAPH );
	}

	execute( options = {} ) {
		const editor = this.editor;
		let where = getRequestedParagraphPosition( options );
		let rootElement = getMainRoot( editor );
		let selection = editor.objects.selection.last.model || currentSelection( editor, options );
		let ranges = getSelectionRange( selection );
		if ( !rootElement || !rootElement._children || !rootElement._children._nodes ) {
			insertAtTopOrBottom( editor, where );
			console.warn( `Could not execute command "${ INSERT_PARAGRAPH }": root element invalid. ` +
				`Inserting at the ` + ( where == "before" ? "top" : "bottom" ) + ` of the editing area.` );
			return;
		}
		if ( !selection ) {
			insertAtTopOrBottom( editor, where );
			console.warn( `Could not insert paragraph ${where}: invalid current selection. ` +
				`Inserting at the ` + ( where == "before" ? "top" : "bottom" ) + ` of the editing area.` );
			return;
		}
		if ( willBeInsertedInsidePlaceholder( ranges, where ) ) {
			console.warn( `Could not insert paragraph ${where}, because it will break placeholder structure.` );
			return;
		}
		let path = ranges ? ( where == "before" && ranges.start && ranges.start.path &&
				ranges.start.path.length > 0 ? ranges.start.path :
				( ranges.end && ranges.end.path && ranges.end.path.length > 0 ? ranges.end.path : "" ) ) :
			( selection.anchor && selection.anchor.path &&
				selection.anchor.path.length > 0 ? selection.anchor.path : "" );
		if ( path == "" ) {
			insertAtTopOrBottom( editor, where );
			console.warn( `Could not insert paragraph ${where}: invalid path. ` +
				`Inserting at the ` + ( where == "before" ? "top" : "bottom" ) + ` of the editing area.` );
			return;
		}
		// makes sure to insert something even if editing area is empty
		if ( rootElement._children._nodes.length == 0 ) {
			insertAtTopOrBottom( editor, "before" );
			return;
		}
		let firstLevelNodeIndex = path[ 0 ] < 0 ? 0 :
			( path[ 0 ] > rootElement._children._nodes.length - 1 ?
				rootElement._children._nodes.length - 1 : path[ 0 ] );
		// special case (due to table/figure selection through selection handler)
		path.length == 1 && firstLevelNodeIndex > 0 &&
			rootElement._children._nodes[ firstLevelNodeIndex - 1 ].name == "table" ?
			firstLevelNodeIndex-- : void 0;
		let firstLevelNode = rootElement._children._nodes[ firstLevelNodeIndex ];
		editor.model.change( writer => {
			const paragraph = writer.createElement( 'paragraph' );
			writer.insert( paragraph, firstLevelNode, where );
		} );
	}
}

function willBeInsertedInsidePlaceholder( ranges, where ) {
	let isStartInPlaceholder = ranges && ranges.start ?
		isPositionInMultilinePlaceholder( ranges.start ) : null;
	let isEndInPlaceholder = ranges && ranges.end ?
		isPositionInMultilinePlaceholder( ranges.end ) : null;
	if ( !isStartInPlaceholder && !isEndInPlaceholder ) return false;
	let startPlaceholderElement = isStartInPlaceholder ?
		getPlaceholderLineParent( ranges.start.parent ) : null;
	let endPlaceholderElement = isEndInPlaceholder ?
		getPlaceholderLineParent( ranges.end.parent ) : null;
	if ( where == "before" && startPlaceholderElement &&
		startPlaceholderElement.previousSibling &&
		isMultilinePlaceholder( startPlaceholderElement.previousSibling ) ) {
		return true;
	}
	if ( where == "after" && endPlaceholderElement &&
		endPlaceholderElement.nextSibling &&
		isMultilinePlaceholder( endPlaceholderElement.nextSibling ) ) {
		return true;
	}
	return false;
}

function getRequestedParagraphPosition( options ) {
	let value = options.value || options.position || options.where;
	if ( value ) value = value.toLowerCase();
	return value ? ( value == "before" || value == "start" || value == "above" ||
			value == "top" ? "before" : "after" ) :
		( options.before == true || options.start == true || options.above == true || options.top == true ||
			options.before == "true" || options.start == "true" || options.above == "true" || options.top == "true" ?
			"before" : "after" );
}

export function currentSelection( editor, options ) {
	return options.selection || ( editor.model && editor.model.document &&
		editor.model.document.selection ? editor.model.document.selection : null ) || editor.lastSelection;
}

function insertAtTopOrBottom( editor, where ) {
	let position = where == "before" ? positionAtRoot( editor ) : positionAtRoot( editor, true );
	editor.model.change( writer => {
		const paragraph = writer.createElement( 'paragraph' );
		writer.insert( paragraph, position );
	} );
}
