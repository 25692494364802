import Command from '@ckeditor/ckeditor5-core/src/command';
import Warner from '../pisautils/warner';
import Validator from '../pisautils/validator';
import {
	isPositionInPlaceholder,
	isInsidePlaceholder,
	isInlinePlaceholder,
	isMultilinePlaceholder,
	getAllPlaceholderLines
} from './pisaplaceholderui';

export const PLACEHOLDER_TO_TEXT = "pisaPlaceholderToText";
const LOG_CHANGES = false;
const ADD_TIMESTAMP = true;
const ADD_COUNTER = true;

export default class PlaceholderToTextCommand extends Command {

	constructor( editor ) {
		super( editor, PLACEHOLDER_TO_TEXT );
	}

	refresh() {
		//TODO
	}

	execute( options = {} ) {
		const editor = this.editor;
		if ( !Validator.isObject( options ) ) options = {};
		let placeholders = this._getPlaceholders( void 0 );
		if ( !Validator.isObject( placeholders ) )
			return this._log( `Could not execute command "${ this.commandName }".` +
				` No placeholder found.`, false );
		if ( Validator.isObject( placeholders.anchor ) ) {
			if ( !placeholders.anchor.multiline ) {
				this.removeInlinePlaceholder( placeholders.anchor.placeholder );
			} else {
				//TODO
			}
			if ( placeholders.same ) return;
		}
		if ( Validator.isObject( placeholders.focus ) ) {
			if ( !placeholders.focus.multiline ) {
				this.removeInlinePlaceholder( placeholders.focus.placeholder );
			} else {
				//TODO
			}
		}
	}

	removeInlinePlaceholder( placeholder ) {
		if ( !Validator.isObject( placeholder ) ) return false;
		const editor = this.editor;
		this.placeholdersObject.removeReference( placeholder );
		return editor.model.change( writer => {
			return inlinePlaceholderToText( writer, placeholder );
		} )
	}

	get placeholdersObject() {
		return this.editor.objects.placeholders;
		// return !Validator.isObjectPath( this, "this.editor.objects.placeholders" ) ||
		// 	!Validator.isMap( this.editor.objects.placeholders.map ) ? void 0 :
		// 	this.editor.objects.placeholders.map;
	}

	_getPlaceholders( placeholder ) {
		if ( Validator.isObject( placeholder ) ) return placeholder;
		const editor = this.editor;
		if ( !isInsidePlaceholder( editor ) )
			return this._log( `Could not execute command "${ this.commandName }".` +
				` No placeholder selected or passed to the "execute" function and` +
				` editor is outside of any placeholder.`, void 0 );
		let selection =
			Validator.isObjectPath( editor, "editor.objects.selection.lastInModel" ) ?
			editor.objects.selection.lastInModel :
			Validator.isObjectPath( editor, "editor.model.document.selection" ) ?
			editor.model.document.selection : void 0;
		if ( !Validator.isObject( selection ) )
			return this._log( `Could not execute command "${ this.commandName }".` +
				` No placeholder selected or passed to the "execute" function and` +
				` could not get editor's selection.`, void 0 );
		// get anchor
		let anchorParent =
			Validator.isObjectPath( selection, "selection.anchor.parent" ) &&
			this.positionExists( selection.anchor ) ? selection.anchor.parent : void 0;
		let placeholderAnchor = findPlaceholderParent( anchorParent );
		let anchorValid = Validator.isObject( placeholderAnchor );
		// get focus
		let focusParent =
			Validator.isObjectPath( selection, "selection.focus.parent" ) &&
			this.positionExists( selection.focus ) ? selection.focus.parent : void 0;
		let placeholderFocus = findPlaceholderParent( focusParent );
		let focusvalid = Validator.isObject( placeholderFocus );
		if ( !anchorValid && !focusvalid ) return void 0;
		let placeholders = {
			same: Validator.isBoolean( selection.isCollapsed ) && selection.isCollapsed
		};
		if ( anchorValid ) placeholders.anchor = {
			multiline: Validator.isArray( placeholderAnchor ),
			placeholder: placeholderAnchor
		};
		if ( focusvalid ) placeholders.focus = {
			multiline: Validator.isArray( placeholderFocus ),
			placeholder: placeholderFocus
		};
		return placeholders;
	}

	positionExists( position ) {
		if ( !Validator.isObjectPath( this, "this.editor.objects.position" ) ) return false;
		if ( !Validator.isFunction( this.editor.objects.position.exists ) ) return false;
		return this.editor.objects.position.exists( position );
	}

	get wdgId() {
		return Validator.isObject( this.editor ) &&
			Validator.isString( this.editor.wdgId ) ? this.editor.wdgId : void 0;
	}

	set wdgId( value ) {
		this._log( `Could not set property "wdgId" to value "${ value }".` +
			` Property can not be directly set.` );
	}

	get className() {
		return "PlaceholderToTextCommand";
	}

	set className( value ) {
		this._log( `Could not set property "className" to value "${ value }".` +
			` Property can not be directly set.` );
	}

	get commandName() {
		return PLACEHOLDER_TO_TEXT;
	}

	set commandName( value ) {
		this._log( `Could not set property "commandName" to value "${ value }".` +
			` Property can not be directly set.` );
	}

	_log( logText, returnValue, trace = true ) {
		return Warner.outputIf( LOG_CHANGES, returnValue, {
			trace: trace,
			collapse: true,
			text: logText,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: 2,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

	_error( error, logText, returnValue, trace = true ) {
		return Warner.outputIf( LOG_CHANGES, returnValue, {
			error: true,
			errorText: error,
			collapse: true,
			text: logText,
			pluginName: `${ this.className } widget ID "${ this.wdgId }"`,
			color: 2,
			addTimestamp: ADD_TIMESTAMP,
			addCounter: ADD_COUNTER
		} );
	}

}

function findPlaceholderParent( modelNodeElement ) {
	if ( !Validator.isObject( modelNodeElement ) ) return void 0;
	while ( modelNodeElement.name != "pisaPlaceholder" &&
		Validator.isObject( modelNodeElement.parent ) &&
		modelNodeElement.name != "paragraph" && modelNodeElement.name != "$root" ) {
		modelNodeElement = modelNodeElement.parent;
	}
	if ( isInlinePlaceholder( modelNodeElement ) ) return modelNodeElement;
	if ( !isMultilinePlaceholder( modelNodeElement ) ) return void 0;
	return getAllPlaceholderLines( modelNodeElement );
}

export function inlinePlaceholderToText( writer, placeholder ) {
	if ( !Validator.isObject( placeholder ) ) return false;
	let childNodes = Validator.isObjectPath( placeholder, "placeholder._children._nodes" ) &&
		Validator.isArray( placeholder._children._nodes ) ? placeholder._children._nodes : void 0;
	if ( !Validator.isObject( childNodes ) ) return false;
	let children = [ ...childNodes ];
	let positionBefore = writer.createPositionBefore( placeholder );
	for ( let i = children.length - 1; i >= 0; i-- ) {
		writer.insert( children[ i ], positionBefore );
	}
	writer.remove( placeholder );
	return true;
}
