import View from '@ckeditor/ckeditor5-ui/src/view';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import LinkFormView from '@ckeditor/ckeditor5-link/src/ui/linkformview';
import LabeledInputView from '@ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import checkIcon from '@ckeditor/ckeditor5-core/theme/icons/check.svg';
import cancelIcon from '@ckeditor/ckeditor5-core/theme/icons/cancel.svg';
import PluginUtils from '../pisautils/pluginutils';
import Validator from '../pisautils/validator';
import { LINK_BUTTON_CLICKED_EVENT } from './pisalinkui';

export const SAVE_LINK = "pisaSaveLink";
export const CANCEL_LINK_CREATION = "pisaCancelLinkCreation";
export const PISA_LINK_URL = "pisaLinkUrl";
const LOG_CHANGES = true;

export default class PisaLinkFormView extends LinkFormView {

	constructor( locale, manualDecorators = [], editor ) {
		super( locale, manualDecorators );

		this.editor = editor;

		new PluginUtils( {
			hostPlugin: this,
			logChanges: LOG_CHANGES,
			logOutputColor: "#736598"
		} );

		[ "children", "urlInputView", "saveButtonView", "cancelButtonView" ]
		.forEach( property => {
			this._removeProperty( property );
		} );

		/**
		 * @override
		 */
		this.urlInputView = this._doCreateUrlInput();

		/**
		 * @override
		 */
		this.saveButtonView = this
			._createNewButton( this.getTooltip( SAVE_LINK ), checkIcon, 'ck-button-save' );
		this.saveButtonView.type = 'submit';

		/**
		 * @override
		 */
		this.cancelButtonView = this
			._createNewButton( this.getTooltip( CANCEL_LINK_CREATION ), cancelIcon,
				'ck-button-cancel', 'cancel' );

		/**
		 * @override
		 */
		this.children = this._doCreateFormChildren( manualDecorators );

		const classList = [ 'ck', 'ck-link-form' ];

		if ( manualDecorators.length ) {
			classList.push( 'ck-link-form_layout-vertical' );
		}

		this.setTemplate( {
			tag: 'form',
			attributes: {
				class: classList,
				// https://github.com/ckeditor/ckeditor5-link/issues/90
				tabindex: '-1'
			},
			children: this.children
		} );
	}

	/**
	 * @override
	 */
	_createButton() {
		return {};
	}

	/**
	 * @override
	 */
	_createFormChildren() {
		return void 0;
	}

	/**
	 * @override
	 */
	_createUrlInput() {
		return {};
	}

	_doCreateUrlInput() {
		const t = this.locale.t;

		const labeledInput = new LabeledInputView( this.locale, InputTextView );

		labeledInput.label = this.getTooltip( PISA_LINK_URL );
		labeledInput.inputView.placeholder = 'https://example.com';

		return labeledInput;
	}

	_doCreateFormChildren( manualDecorators ) {
		const children = this.createCollection();

		children.add( this.urlInputView );

		if ( manualDecorators.length ) {
			const additionalButtonsView = new View();

			additionalButtonsView.setTemplate( {
				tag: 'ul',
				children: this._manualDecoratorSwitches.map( switchButton => ( {
					tag: 'li',
					children: [ switchButton ],
					attributes: {
						class: [
							'ck',
							'ck-list__item'
						]
					}
				} ) ),
				attributes: {
					class: [
						'ck',
						'ck-reset',
						'ck-list'
					]
				}
			} );
			children.add( additionalButtonsView );
		}

		children.add( this.saveButtonView );
		children.add( this.cancelButtonView );

		return children;
	}

	_createNewButton( label, icon, className, eventName ) {
		const button = new ButtonView( this.locale );

		button.set( {
			label,
			icon,
			tooltip: true
		} );

		button.extendTemplate( {
			attributes: {
				class: className
			}
		} );

		if ( eventName ) {
			button.delegate( 'execute' ).to( this, eventName );
		}

		button.on( "execute", ( eventInfo, data ) => {
			this.editor.fire( LINK_BUTTON_CLICKED_EVENT );
		} );

		return button;
	}

	get tooltipManager() {
		return Validator.isObjectPath( this, "this.editor.objects.tooltips" ) &&
			Validator.isFunction( this.editor.objects.tooltips.getT ) ?
			this.editor.objects.tooltips : void 0;
	}

	getTooltip( tooltipKeyword ) {
		if ( !Validator.isString( tooltipKeyword ) )
			return this._log( `Could not get tooltip for invalid keyword.`, "" );
		let tooltipManager = this.tooltipManager;
		if ( !Validator.isObject( tooltipManager ) )
			return this._log( `Could not get editor's own tooltip manager.`, "" );
		return tooltipManager.getT( tooltipKeyword );
	}
	//
	// _removeProperty( propertyName, doDestroy = true ) {
	// 	if ( !Validator.isString( propertyName ) )
	// 		return this._log( `Could not remove property with invalid property name.` );
	// 	let proertyValue = this[ propertyName ];
	// 	if ( !Validator.isObject( proertyValue ) )
	// 		return Object.defineProperty( this, propertyName, {
	// 			value: void 0,
	// 			writable: true
	// 		} );
	// 	this[ propertyName ] = void 0;
	// 	delete this[ propertyName ];
	// 	if ( doDestroy && Validator.isFunction( proertyValue.destroy ) )
	// 		proertyValue.destroy();
	// 	proertyValue = void 0;
	// }

}
