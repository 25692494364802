import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';

export const ATTRIBUTE_OPERATION = "AttributeOperation";
export const MOVE_OPERATION = "MoveOperation";

export default class PlaceholderChangesRegistrator extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		hostPlugin.editor.model.on( 'insertContent', ( eventInfo, args ) => {
			hostPlugin.onInsertContent( eventInfo, args );
		}, { priority: Number.MIN_SAFE_INTEGER } );

		hostPlugin.editor.model.on( 'applyOperation', ( eventInfo, args ) => {
			hostPlugin.onApplyOperation( eventInfo, args );
		} );

	}

	onInsertContent( eventInfo, args ) {
		if ( !this.react ) return this._cleanUp();
		// const selection = this.currentSelection;
		// if ( Validator.isObject( selection ) &&
		// 	selection.isCollapsed ) {
		// 	let positions = this.getPositionsToPlaceholderPlacementRelation( {
		// 		start: selection.anchor,
		// 		end: selection.focus
		// 	} );
		// 	if ( positions.start.atStartOf.inline && Validator.isObject( positions.start ) ) {
		// 		console.log( "starts at start of inline" );
		// 		// return this._setSelectionToPosition( positions.start );
		// 	}
		// 	if ( positions.end.after.inline && Validator.isObject( positions.end ) ) {
		// 		console.log( "ends after inline" );
		// 		// return this._setSelectionToPosition( positions.end );
		// 	}
		// }
		this.lastInsert = { eventInfo: eventInfo };
		if ( Validator.isArray( args ) )
			args.length == 2 ? this.lastInsert.args = args : args.length == 1 &&
			Validator.is( args[ 0 ], "DocumentFragment" ) ?
			this.lastInsert.documentFragment = args[ 0 ] : void 0;
		if ( Validator
			.isObjectPath( eventInfo, "eventInfo.source._currentWriter.batch" ) )
			this.lastInsert.batch = eventInfo.source._currentWriter.batch;
	}

	onApplyOperation( eventInfo, args ) {
		if ( !this.react ) return this._cleanUp();
		if ( !Validator.isArray( args ) || args.length != 1 ) return;
		if ( !this._isSingleDocumentOperationWithBatch( args[ 0 ] ) ) return;
		if ( this.keydownOnSelectionInPlaceholder ) return;
		if ( Validator.is( args[ 0 ], MOVE_OPERATION ) )
			return this._registerMoveOperation( eventInfo, args[ 0 ] );
		if ( Validator.is( args[ 0 ], ATTRIBUTE_OPERATION ) )
			return this._registerAttributeOperation( eventInfo, args[ 0 ] );
	}

	_registerAttributeOperation( eventInfo, operation ) {
		this.lastInsert = {
			eventInfo: eventInfo,
			operation: operation,
			operationType: ATTRIBUTE_OPERATION,
			batch: Validator.isObjectPath( operation, "operation.batch" ) ?
				operation.batch : void 0
		};
	}

	_registerInsertOperation( evt, operation ) {
		// TODO
	}

	_registerMoveOperation( eventInfo, operation ) {
		if ( operation.type != "remove" ) return;
		// if ( !Validator.isObjectPath( operation, "operation.sourcePosition" ) ) return;
		// if ( !this.isInPlaceholder( operation.sourcePosition ) ) return;
		let selection = this.currentSelection;
		if ( !Validator.isObject( selection ) || selection.isCollapsed )
			return this._doRegisterMoveOperation( eventInfo, operation );
		const positions = this.getPositionsToPlaceholderPlacementRelation( {
			start: selection.anchor,
			end: selection.focus
		} );
		const advancedPlacement = this.getAdvancedPositionToPlaceholderPlacement( {
			positions: positions
		} );
		if ( !advancedPlacement.onlyStartIsInside &&
			!advancedPlacement.onlyEndIsInside &&
			!advancedPlacement.startsAndEndsInDifferentPlaceholders )
			return this._doRegisterMoveOperation( eventInfo, operation );
		if ( advancedPlacement.onlyStartIsInside ) {
			// console.log( "only start inside" );
			return this.stopEditorEvent( eventInfo, {} );
		} else if ( advancedPlacement.onlyEndIsInside ) {
			// console.log( "only end inside" );
			return this.stopEditorEvent( eventInfo, {} );
		} else {
			// console.log( "start and end in different plh" );
			return this.stopEditorEvent( eventInfo, {} );
		}
		this._doRegisterMoveOperation( eventInfo, operation );
	}

	_doRegisterMoveOperation( eventInfo, operation ) {
		this.lastInsert = {
			eventInfo: eventInfo,
			operation: operation,
			operationType: MOVE_OPERATION,
			batch: Validator.isObjectPath( operation, "operation.batch" ) ?
				operation.batch : void 0
		};
	}

	_isSingleDocumentOperationWithBatch( documentOperation ) {
		return Validator.isObject( documentOperation ) &&
			!!documentOperation.isDocumentOperation &&
			Validator.is( documentOperation.batch, "Batch" ) &&
			Validator.isArray( documentOperation.batch.operations ) &&
			documentOperation.batch.operations.length === 1;
	}

}

// const ATTRIBUTES_TO_MIRROR = [ "onInsertContent", "onApplyOperation",
// 	"_registerAttributeOperation", "_registerInsertOperation",
// 	"_registerMoveOperation", "_doRegisterMoveOperation",
// 	"_isSingleDocumentOperationWithBatch"
// ];
