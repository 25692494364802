
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { PISA_FIRE } from './pisafirecommand';
import PisaFireCommand from './pisafirecommand';

export default class PisaFire extends Plugin {

  init() {
    const editor = this.editor;
    editor.commands.add( PISA_FIRE, new PisaFireCommand( editor ) );
  }

}
