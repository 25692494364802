import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import PisaImagePositionCommand from './pisaimagepositioncommand';
import PisaImagePositionEditing from './pisaimagepositionediting';
import { VERTICAL_ALIGN } from './pisaimagepositionediting';
import { createButton } from '../utils';
import { spacingIcon } from '../icons';

export default class PisaImagePositionUI extends Plugin {

	static get requires() {
		return [ PisaImagePositionEditing ];
	}

	init() {
		const editor = this.editor;
		const command = editor.commands.get( "pisaInsertImage" );

		editor.commands.add( VERTICAL_ALIGN, new PisaImagePositionCommand( editor ) );

		editor.ui.componentFactory.add( VERTICAL_ALIGN, locale => {
			let moveImageButton = createButton( spacingIcon,
				editor.objects.tooltips.getT( VERTICAL_ALIGN ), editor.locale );
			if ( !!command && typeof command == "object" )
				moveImageButton.bind( 'isEnabled' ).to( command );
			moveImageButton.on( "execute", () => {
				editor.lastSelection = new Selection( editor.model.document.selection, 0 );
				editor.executeIf( VERTICAL_ALIGN, { selection: editor.lastSelection, value: "top" }, false );
			} );
			editor.objects.focus._addExecuteFocus( moveImageButton );
			return moveImageButton;
		} );
	}
}
