import Command from '@ckeditor/ckeditor5-core/src/command';
import { getRange } from '../utils';
import { getPathToElement } from '../utils'
import { ALIGN_TABLE } from './pisatableui';
import { findElementInSelection } from '../utils';

export default class PisaAlignTableCommand extends Command {

	execute( options = {} ) {
		const editor = this.editor;
		let selection = editor.model.document.selection;
		let table = !!selection._ranges[ 0 ] ? findElementInSelection( editor, selection, "table" ) : "";
		if ( options.value && !!table ) {
			let tablePath = getPathToElement( table );
			let tableRange = getRange( editor, tablePath, table.index, table.index + 1 );
			let value = this._getMarginValue( options.value, table.getAttribute( ALIGN_TABLE ) );
			selection._setTo( tableRange );
			if ( value == "0pt" ) {
				editor.model.change( writer => {
					writer.removeAttribute( ALIGN_TABLE, selection._ranges[ 0 ] );
				} );
			} else if ( value != "m" && value != "p" ) {
				editor.model.change( writer => {
					writer.setAttribute( ALIGN_TABLE, value, selection._ranges[ 0 ] );
				} );
			}
		}
	}

	_getMarginValue( value, currentMargin = "0pt" ) {
		const editor = this.editor;
		let maxMargin = editor.config.get( `${ALIGN_TABLE}.options` )[ editor.config.get( `${ALIGN_TABLE}.options` ).length - 1 ];
		let minMargin = editor.config.get( `${ALIGN_TABLE}.options` )[ 0 ];
		if ( value == "p" && currentMargin != maxMargin ) {
			let intValue = parseInt( currentMargin.replace( /[\D]+/g, "" ) ) + 36;
			return intValue.toString() + 'pt';
		}
		if ( value == "m" && currentMargin != minMargin ) {
			let intValue = parseInt( currentMargin.replace( /[\D]+/g, "" ) ) - 36;
			return intValue.toString() + 'pt';
		}
		return value;
	}
}
