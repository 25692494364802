import './theme/square.css';
import EmptyView from '../pisadropdown/emptyview';

export default class AnimatedSquareView extends EmptyView {

	constructor( classesList = [] ) {
		super( classesList );
		this.extendTemplate( {
			attributes: {
				class: [ "hourglass-square" ]
			}
		} );
	}

	focus() {
		//TODO add override annotation
		return void 0;
	}

}
