
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaSuperscriptEditing from './pisasuperscriptediting';
import PisaSuperscriptUI from './pisasuperscriptui';

export default class PisaSuperscript extends Plugin {

	static get requires() {
		return [ PisaSuperscriptEditing, PisaSuperscriptUI ];
	}

	static get pluginName() {
		return 'PisaSuperscript';
	}
}
