import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/balloonpanel.css';
import PisaPanelBalloons from './pisapanelballoons';
import PisaClipboard from '../pisaclipboard/pisaclipboardui';
import { isSelectionImage } from '../pisaimageresize/utils';
import { onDomEvent, getLiveSelectionNode, getParentOfType } from '../utils';
import { isInsidePlaceholder } from '../pisaplaceholder/pisaplaceholderui';
import { isInsideTable } from '../pisatable/pisadecreasemargincommand';
import Validator from '../pisautils/validator';

export default class PisaBalloons extends Plugin {

	static get requires() {
		return [ PisaPanelBalloons, PisaClipboard ];
	}

	init() {
		const editor = this.editor;

		this.panel = editor.balloons;
		this.pisaCellComponent = null;

		editor.once( "ready", () => {
			this.panel.fillImage();
			this.panel.fillText();
			this.panel.fillTable();
			// this.panel.fillInsert();
			this.panel.hideArrow( "table" );
			this._addShowBalloonsListeners();
			this._addToolbarListener();
			// this._addPisaCellListener();
		} );

		this._addScrollListener();
		this._addRightClickListener();
		this._addSelectionHandlerListener();
		this._addKeyDownListener();

		editor.editing.view.document.on( 'click', ( evt, data ) => {
			const path = Validator.isObjectPath( data, "data.domEvent" ) &&
				Validator.isArray( data.domEvent.path, true ) ? data.domEvent.path : [];
			const isLink = ( path.length > 0 && path[ 0 ] instanceof HTMLElement &&
					path[ 0 ].tagName == "A" ) ||
				( path.length > 1 && path[ 1 ] instanceof HTMLElement &&
					path[ 1 ].tagName == "A" );
			const isImage = Validator.isObjectPath( data, "data.domTarget" ) &&
				data.domTarget.tagName == "IMG";
			const isInsideTable = path.length > 0 &&
				!!path.find( element => element.tagName == "TD" &&
					typeof element.className == "string" &&
					element.className.indexOf( "ck-editor" ) >= 0 );
			if ( !isLink ) this._hideLinkBalloon();
			if ( !isImage ) this.panel.hide( "image" );
			this.panel.hide( "insert" );
			if ( !isInsideTable ) this.panel.hide( "table" );
			if ( !editor.model.document.selection.isCollapsed ) return;
			this.panel.hide( "text" );
		}, { priority: "highest" } );

		editor.editing.view.document.on( 'selectionChangeDone', ( evt, name ) => {
			let selection = editor.model.document.selection;
			const isCollapsedInsideLink = selection.isCollapsed &&
				Validator.isMapPath( selection, "selection.anchor.textNode._attrs" ) &&
				Validator.isString( selection.anchor.textNode._attrs.get( "linkHref" ) );
			if ( isCollapsedInsideLink ) return;
			if ( isInsideTable( selection.anchor.parent ) &&
				!this.panel.balloons.image.isVisible &&
				!this.panel.balloons.text.isVisible &&
				!this.panel.balloons.insert.isVisible ) {
				let liveNode = getLiveSelectionNode();
				let parent = getParentOfType( liveNode, "FIGURE" );
				if ( parent ) {
					let figureRect = parent.getBoundingClientRect();
					this.panel.refresh( "table" );
					this.panel.attachTo( "table",
						this.panel.getNodePositionData( parent.lastElementChild, true ), figureRect );
				}
			}
			if ( selection.isCollapsed ) return;
			if ( isSelectionImage( selection ) )
				return this.panel.refreshAndShow( "image", true );
			if ( isSelectionTable( editor, selection ) )
				return this.panel.refreshAndShow( "table", true );
			this.panel.refreshAndShow( "text", true );
		}, { priority: "highest" } );

		editor.on( "focusChanged", ( eventInfo, data ) => {
			if ( !data || typeof data != "object" || data.gainedFocus ) return;
			// if ( this.isActiveElementInsideTextDropdown ) return;
			if ( editor.balloons.doNotHide ) return;
			this.panel.hideAll();
			this._hideLinkBalloon();
		} );

		editor.commands.get( "pisaPlainText" ).on( "execute", () => {
			this.panel.hideAll();
		} );
	}

	get isActiveElementInsideTextDropdown() {
		if ( !Validator.isObjectPath( this, "this.panel.balloons.text" ) )
			return false;
		const activeElement = window.document.activeElement;
		if ( !( activeElement instanceof HTMLElement ) ) return false;
		const textPanelElement = this.panel.balloons.text.element;
		if ( !( textPanelElement instanceof HTMLElement ) ) return false;
		let children = textPanelElement.getElementsByTagName( "*" );
		if ( !Validator.isIterable( children ) ) return false;
		children = [ ...children ];
		return children.indexOf( activeElement ) >= 0;
	}

	_hideLinkBalloon() {
		if ( Validator.isObjectPath( this.editor, "editor.objects.linkBalloon" ) )
			this.editor.objects.linkBalloon.hide();
	}

	_addRightClickListener() {
		let self = this;
		let showPasteMenu = function( evt ) {
			self.panel.hideAll();
			// evt.preventDefault();
			// evt.stopPropagation();
			// if ( evt && evt.srcElement && evt.srcElement.tagName == "IMG" ) return;
			// self.panel.refreshAndShow( "insert" );
		}
		onDomEvent( this.editor, "contextmenu", showPasteMenu );
	}

	_addToolbarListener() {
		this.editor.ui.view.toolbar.items._items.forEach( button => {
			button.buttonView ? button = button.buttonView : void 0;
			button.on( "execute", () => {
				this.panel.hideAll();
			} );
		} );
	}

	_addScrollListener() {
		const self = this;
		const hide = () => {
			self.panel.hideAll();
			self._hideLinkBalloon();
		}
		onDomEvent( this.editor, "scroll", hide );
	}

	_addSelectionHandlerListener() {
		let pisaBalloons = this;
		let selectionHandlerClick = ( evt ) => {
			let path = evt.path || ( evt.composedPath ? evt.composedPath() : [] );
			if ( !path || path.length <= 0 ||
				( path[ 0 ].nodeName != "path" && path[ 0 ].nodeName != "table" &&
					path[ 0 ].nodeName != "svg" && path[ 0 ].nodeName != "g" &&
					path[ 0 ].className.indexOf( "ck-widget__selection-handler" ) < 0 ) ) return;
			let figureParent = Array.from( path ).find( parent => (
				( parent.tagName == "FIGURE" || parent.nodeName == "FIGURE" ||
					parent.localName == "figure" ) && ( parent.classList &&
					parent.classList.length > 0 &&
					Array.from( parent.classList ).indexOf( "table" ) >= 0 ) ) );
			if ( !figureParent ) return;
			let figureRect = figureParent.getBoundingClientRect();
			pisaBalloons.panel.refresh( "table" );
			pisaBalloons.panel.attachTo( "table", pisaBalloons.panel.getPositionData( true ), figureRect );
			if ( !pisaBalloons.panel.balloons.table.isVisible ) {
				pisaBalloons.panel.refreshAndShow( "table", true );
			};
		}
		onDomEvent( this.editor, "click", selectionHandlerClick );
	}

	_addKeyDownListener() {
		let pisaBalloons = this;
		let keydownHandler = ( evt ) => {
			pisaBalloons.panel.hideAll();
			pisaBalloons._hideLinkBalloon();
		}
		onDomEvent( this.editor, "keydown", keydownHandler );
	}

	_addShowBalloonsListeners() {
		this.panel.balloons.insert.on( "change:isVisible", ( evt, name, newVal, oldVal ) => {
			if ( oldVal || !newVal ) return;
			if ( isInsidePlaceholder( this.editor ) ) this.panel.hide( "insert" );
			this._hideLinkBalloon();
			this.panel.hide( "text" );
			this.panel.hide( "image" );
			this.panel.hide( "table" );
		} );

		this.panel.balloons.text.on( "change:isVisible", ( evt, name, newVal, oldVal ) => {
			if ( oldVal || !newVal ) return;
			if ( isInsidePlaceholder( this.editor ) ) this.panel.hide( "text" );
			this._hideLinkBalloon();
			this.panel.hide( "insert" );
			this.panel.hide( "image" );
			this.panel.hide( "table" );
		} );

		this.panel.balloons.image.on( "change:isVisible", ( evt, name, newVal, oldVal ) => {
			if ( oldVal || !newVal ) return;
			if ( isInsidePlaceholder( this.editor ) ) this.panel.hide( "image" );
			this._hideLinkBalloon();
			this.panel.hide( "insert" );
			this.panel.hide( "text" );
			this.panel.hide( "table" );
			//TODO implement link on image and change/remove this part of the listener
			// this.editor.commands.get( "link" ).set( "isEnabled", false );
		} );

		this.panel.balloons.table.on( "change:isVisible", ( evt, name, newVal, oldVal ) => {
			if ( oldVal || !newVal ) return;
			// if ( isInsidePlaceholder( this.editor ) ) this.panel.hide( "table" );
			this._hideLinkBalloon();
			this.panel.hide( "insert" );
			this.panel.hide( "text" );
			this.panel.hide( "image" );
		} );

		// this._linkBalloon.view.on( "change:isVisible", ( evt, name, newVal, oldVal ) => {
		// 	if ( oldVal || !newVal ) return;
		// 	// if ( isInsidePlaceholder( this.editor ) ) this._hideLinkBalloon();
		// 	this.panel.hideAll();
		// }, { priority: "highest" } );
	}

	// _addPisaCellListener() {
	// 	// TODO find a "more beautiful" solution?
	// 	this.pisaCellComponent = this.panel.balloons.table.template &&
	// 		this.panel.balloons.table.template.children &&
	// 		this.panel.balloons.table.template.children.length > 0 &&
	// 		this.panel.balloons.table.template.children[ 0 ]._items &&
	// 		this.panel.balloons.table.template.children[ 0 ]._items.length > 0 &&
	// 		this.panel.balloons.table.template.children[ 0 ]._items[ 0 ].component == "pisaCell" ?
	// 		this.panel.balloons.table.template.children[ 0 ]._items[ 0 ] : null;
	// 	this.pisaCellComponent && this.pisaCellComponent.on( "change:isOpen", ( evt, data, newVal, oldVal ) => {
	// 		if ( newVal || !oldVal ) return;
	// 		this.panel.hide( "table" );
	// 	} );
	// }
}

export function isSelectionTable( editor, selection ) {
	if ( !( selection._selection && selection._selection._ranges && selection._selection._ranges.length == 1 ) ) return false;
	let range = selection._selection._ranges[ 0 ];
	if ( !( range.start && range.start.path && range.end && range.end.path ) ) return false;
	if ( range.start.path.length > 2 || range.end.path.length > 2 ) return false;
	if ( range.start.path[ 0 ] + 1 != range.end.path[ 0 ] && range.start.path[ 0 ] != range.end.path[ 0 ] ) return false;
	if ( range.start.nodeAfter && range.start.nodeAfter.name == "table" ) return true;
	if ( range.end.nodeBefore && range.end.nodeBefore.name == "table" ) return true;
	return false;
}
