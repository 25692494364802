import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import './theme/table.css';
import Position from '@ckeditor/ckeditor5-engine/src/model/position.js';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import GlobalFunctionExecutor from '../pisautils/globalfunctionexecutor';
import {
	addClasses,
	defineButton,
	setButton,
	makeVisible,
	makeInvisible,
	createButton,
	closeDropdownOnBlur,
	getObjectProto,
	positionAtRoot
} from '../utils';
import PisaTableCommand from './pisatablecommand';
import PisaTableEditing from './pisatableediting';
import PisaAlignTableCommand from './pisaaligntablecommand';
import PisaIncreaseMarginCommand from './pisaincreasemargincommand';
import PisaDecreaseMarginCommand from './pisadecreasemargincommand';
import PisaPlaceTableCommand, {
	PisaPlaceTableInlineCommand,
	PisaPlaceTableBlockCommand
} from './pisaplacetablecommand';

import EmptyView from '../pisadropdown/emptyview';
import {
	tableRowIcon,
	tableColumnIcon,
	tableIcon,
	tableMergeCellIcon,
	editTableIcon,
	indentLeft,
	indentRight
} from '../icons';

export const EDITTABLE = "pisaEditTable";
export const ALIGN_TABLE = "pisaAlignTable";
export const PLACE_TABLE = "pisaPlaceTable";
export const INCREASE_TABLE_MARGIN = "pisaIncreaseTableMargin";
export const DECREASE_TABLE_MARGIN = "pisaDecreaseTableMargin";
export const EDIT_TABLE_ROW = "pisaEditTableRow";
export const EDIT_TABLE_COLUMN = "pisaEditTableColumn";
export const EDIT_TABLE_AND_CELL = "pisaEditTableAndCell";
export const EDIT_TABLE_ONLY = "pisaEditTableOnly";
export const EDIT_CELL_ONLY = "pisaEditCellOnly";

export const INSERT_TABLE_ROW_ABOVE = "insertTableRowAbove";
export const INSERT_TABLE_ROW_BELOW = "insertTableRowBelow";
export const REMOVE_TABLE_ROW = "removeTableRow";
export const SET_TABLE_ROW_HEADER = "setTableRowHeader";
export const INSERT_TABLE_COLUMN_LEFT = "insertTableColumnLeft";
export const INSERT_TABLE_COLUMN_RIGHT = "insertTableColumnRight";
export const REMOVE_TABLE_COLUMN = "removeTableColumn";
export const SET_TABLE_COLUMN_HEADER = "setTableColumnHeader";
export const SPLIT_TABLE_CELL_VERTICALLY = "splitTableCellVertically";
export const SPLIT_TABLE_CELL_HORIZONTALLY = "splitTableCellHorizontally";
export const MERGE_TABLE_CELL_RIGHT = "mergeTableCellRight";
export const MERGE_TABLE_CELL_LEFT = "mergeTableCellLeft";
export const MERGE_TABLE_CELL_DOWN = "mergeTableCellDown";
export const MERGE_TABLE_CELL_UP = "mergeTableCellUp";
export const MAKE_TABLE_INLINE = "pisaPlaceTableInline";
export const MAKE_TABLE_BLOCK = "pisaPlaceTableBlock";

const ROW_COMMANDS = [ INSERT_TABLE_ROW_ABOVE, INSERT_TABLE_ROW_BELOW,
	REMOVE_TABLE_ROW, SET_TABLE_ROW_HEADER
];
const COLUMN_COMMANDS = [ INSERT_TABLE_COLUMN_LEFT, INSERT_TABLE_COLUMN_RIGHT,
	REMOVE_TABLE_COLUMN, SET_TABLE_COLUMN_HEADER
];
const TABLE_COMMANDS = [ INCREASE_TABLE_MARGIN, DECREASE_TABLE_MARGIN,
	PLACE_TABLE, MAKE_TABLE_INLINE, MAKE_TABLE_BLOCK
];
const CELL_COMMANDS = [ SPLIT_TABLE_CELL_VERTICALLY,
	SPLIT_TABLE_CELL_HORIZONTALLY, MERGE_TABLE_CELL_RIGHT,
	MERGE_TABLE_CELL_LEFT, MERGE_TABLE_CELL_DOWN, MERGE_TABLE_CELL_UP
];

export default class PisaTableUI extends Plugin {

	static get requires() {
		return [ PisaTableEditing ];
	}

	init() {
		const editor = this.editor;
		const viewDocument = editor.editing.view.document;
		let balloonPanel = editor.plugins._plugins.get( "PisaPanelBalloons" );

		editor.commands.add( EDITTABLE, new PisaTableCommand( editor ) );
		editor.commands.add( ALIGN_TABLE, new PisaAlignTableCommand( editor ) );
		editor.commands.add( INCREASE_TABLE_MARGIN, new PisaIncreaseMarginCommand( editor ) );
		editor.commands.add( DECREASE_TABLE_MARGIN, new PisaDecreaseMarginCommand( editor ) );
		editor.commands.add( PLACE_TABLE, new PisaPlaceTableCommand( editor ) );
		editor.commands.add( MAKE_TABLE_INLINE, new PisaPlaceTableInlineCommand( editor ) );
		editor.commands.add( MAKE_TABLE_BLOCK, new PisaPlaceTableBlockCommand( editor ) );

		const command = editor.commands.get( INCREASE_TABLE_MARGIN );
		const options = editor.config.get( `${ALIGN_TABLE}.options` );

		editor.ui.componentFactory.add( EDITTABLE, locale => {
			const dropdownView = createDropdown( locale );
			defineButton( dropdownView.buttonView, editTableIcon,
				editor.objects.tooltips.getT( EDITTABLE ) );
			addClasses( dropdownView, [ 'ck-font-family-dropdown' ] );
			dropdownView.bind( 'isEnabled' ).to( command );
			closeDropdownOnBlur( dropdownView );
			addListToDropdown( dropdownView, prepareListOptions( editor ) );

			const gridView = createGridView( editor, dropdownView.listView );
			dropdownView.panelView.children.add( gridView, 0 );
			hideListItems( editor, dropdownView.listView );

			this.listenTo( dropdownView, 'execute', evt => {
				editor.execute( evt.source.commandName );
				editor.editing.view.focus();
			} );

			editor.objects.focus._addExecuteFocus( dropdownView );
			editor.objects.focus._addExecuteFocus( dropdownView.buttonView );
			GlobalFunctionExecutor.closeMenusOnExecute( dropdownView.buttonView, EDITTABLE );

			return dropdownView;
		} );

		editor.ui.componentFactory.add( INCREASE_TABLE_MARGIN, locale => {
			let button = setButton( indentLeft,
				editor.objects.tooltips.getT( INCREASE_TABLE_MARGIN ),
				INCREASE_TABLE_MARGIN, "", editor );
			if ( !balloonPanel || typeof balloonPanel != "object" ) return button;
			button.on( "execute", () => {
				balloonPanel.positionActive();
			}, { priority: "lowest" } );

			editor.objects.focus._addExecuteFocus( button );

			return button;
		} );

		editor.ui.componentFactory.add( DECREASE_TABLE_MARGIN, locale => {
			let button = setButton( indentRight,
				editor.objects.tooltips.getT( DECREASE_TABLE_MARGIN ),
				DECREASE_TABLE_MARGIN, "", editor );
			if ( !balloonPanel || typeof balloonPanel != "object" ) return button;
			button.on( "execute", () => {
				balloonPanel.positionActive();
			}, { priority: "lowest" } );

			editor.objects.focus._addExecuteFocus( button );

			return button;
		} );

		addInsertTableListener( editor );
	}
}

function createGridView( editor, listView ) {
	let gridView = new EmptyView( [ 'psa-dropdown-list-container' ] );
	let rowView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let columnView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let tableView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let cellView = new EmptyView( [ 'psa-dropdown-list-container-child' ] );
	let rowButton = createButton( tableRowIcon,
		editor.objects.tooltips.getT( EDIT_TABLE_ROW ), editor.locale );
	let columnButton = createButton( tableColumnIcon,
		editor.objects.tooltips.getT( EDIT_TABLE_COLUMN ), editor.locale );
	let tableButton = createButton( tableIcon,
		editor.objects.tooltips.getT( EDIT_TABLE_ONLY ), editor.locale );
	let cellButton = createButton( tableMergeCellIcon,
		editor.objects.tooltips.getT( EDIT_CELL_ONLY ), editor.locale );
	rowButton.on( "execute", () => {
		firstIsOn( rowButton, columnButton, tableButton, cellButton );
		hideListItems( editor, listView );
		showListItems( editor, listView, ROW_COMMANDS );
	} );
	columnButton.on( "execute", () => {
		firstIsOn( columnButton, rowButton, tableButton, cellButton );
		hideListItems( editor, listView );
		showListItems( editor, listView, COLUMN_COMMANDS );
	} );
	tableButton.on( "execute", () => {
		firstIsOn( tableButton, rowButton, columnButton, cellButton );
		hideListItems( editor, listView );
		showListItems( editor, listView, TABLE_COMMANDS );
	} );
	cellButton.on( "execute", () => {
		firstIsOn( cellButton, rowButton, columnButton, tableButton );
		hideListItems( editor, listView );
		showListItems( editor, listView, CELL_COMMANDS );
	} );

	rowView.items.add( rowButton );
	columnView.items.add( columnButton );
	tableView.items.add( tableButton );
	cellView.items.add( cellButton );
	gridView.items.add( rowView );
	gridView.items.add( columnView );
	gridView.items.add( cellView );
	gridView.items.add( tableView );
	return gridView;
}

function hideListItems( editor, listView ) {
	for ( let item of listView.items._items ) {
		makeInvisible( editor, item );
	}
}

function firstIsOn( first, second, third, fourth ) {
	first.isOn = true;
	second.isOn = false;
	third.isOn = false;
	fourth.isOn = false;
}

function showListItems( editor, listView, commandsList = [] ) {
	for ( let item of listView.items._items ) {
		for ( let command of commandsList ) {
			if ( item.children && item.children._items && item.children._items.length > 0 &&
				item.children._items[ 0 ].commandName == command ) {
				makeVisible( editor, item );
				break;
			}
		}
	}
}

function getButton( editor, listView, commandName ) {
	for ( let item of listView.items._items ) {
		if ( item.children && item.children._items && item.children._items.length > 0 &&
			item.children._items[ 0 ].commandName == commandName ) {
			return item;
		}
	}
	return "";
}

function prepareListOptions( editor ) {
	const buttons = [];
	const itemDefinitions = new Collection();

	buttons.push( createTextButton( INCREASE_TABLE_MARGIN,
		editor.objects.tooltips.getT( INCREASE_TABLE_MARGIN ) ) );
	buttons.push( createTextButton( DECREASE_TABLE_MARGIN,
		editor.objects.tooltips.getT( DECREASE_TABLE_MARGIN ) ) );
	buttons.push( createTextButton( MAKE_TABLE_INLINE,
		editor.objects.tooltips.getT( MAKE_TABLE_INLINE ) ) );
	buttons.push( createTextButton( MAKE_TABLE_BLOCK,
		editor.objects.tooltips.getT( MAKE_TABLE_BLOCK ) ) );
	// buttons.push( createTextButton( PLACE_TABLE,
	// 	editor.objects.tooltips.getT( PLACE_TABLE ) ) );
	buttons.push( createTextButton( INSERT_TABLE_ROW_ABOVE,
		editor.objects.tooltips.getT( INSERT_TABLE_ROW_ABOVE ) ) );
	buttons.push( createTextButton( INSERT_TABLE_ROW_BELOW,
		editor.objects.tooltips.getT( INSERT_TABLE_ROW_BELOW ) ) );
	buttons.push( createTextButton( INSERT_TABLE_COLUMN_LEFT,
		editor.objects.tooltips.getT( INSERT_TABLE_COLUMN_LEFT ) ) );
	buttons.push( createTextButton( INSERT_TABLE_COLUMN_RIGHT,
		editor.objects.tooltips.getT( INSERT_TABLE_COLUMN_RIGHT ) ) );
	buttons.push( createTextButton( REMOVE_TABLE_ROW,
		editor.objects.tooltips.getT( REMOVE_TABLE_ROW ) ) );
	buttons.push( createTextButton( REMOVE_TABLE_COLUMN,
		editor.objects.tooltips.getT( REMOVE_TABLE_COLUMN ) ) );
	buttons.push( createTextButton( SPLIT_TABLE_CELL_VERTICALLY,
		editor.objects.tooltips.getT( SPLIT_TABLE_CELL_VERTICALLY ) ) );
	buttons.push( createTextButton( SPLIT_TABLE_CELL_HORIZONTALLY,
		editor.objects.tooltips.getT( SPLIT_TABLE_CELL_HORIZONTALLY ) ) );
	buttons.push( createTextButton( MERGE_TABLE_CELL_RIGHT,
		editor.objects.tooltips.getT( MERGE_TABLE_CELL_RIGHT ) ) );
	buttons.push( createTextButton( MERGE_TABLE_CELL_LEFT,
		editor.objects.tooltips.getT( MERGE_TABLE_CELL_LEFT ) ) );
	buttons.push( createTextButton( MERGE_TABLE_CELL_DOWN,
		editor.objects.tooltips.getT( MERGE_TABLE_CELL_DOWN ) ) );
	buttons.push( createTextButton( MERGE_TABLE_CELL_UP,
		editor.objects.tooltips.getT( MERGE_TABLE_CELL_UP ) ) );
	buttons.push( createTextButton( SET_TABLE_COLUMN_HEADER,
		editor.objects.tooltips.getT( SET_TABLE_COLUMN_HEADER ) ) );
	buttons.push( createTextButton( SET_TABLE_ROW_HEADER,
		editor.objects.tooltips.getT( SET_TABLE_ROW_HEADER ) ) );

	for ( let button of buttons ) {
		let cmd = editor.commands.get( button.model.commandName );
		if ( cmd ) {
			button.model.bind( 'isEnabled' ).to( cmd );
		} else {
			console.warn( 'Command \"' + button.model.commandName + '\" not available!' )
		}
		itemDefinitions.add( button );
	}
	return itemDefinitions;
}

function createTextButton( commandName, label ) {
	return {
		type: 'button',
		model: new Model( {
			commandName: commandName,
			label: label,
			withText: true
		} )
	};
}

function addInsertTableListener( editor ) {
	// this listener enables editing after a table by inserting an empty line
	// at the end of the document in case the last element is a table (aka a
	// table was inserted). It is (also without the listener) possible to insert
	// text after a table (by selecting it and pressing enter) but it is not
	// transparent to the user. "Lowest" priority insures it will be done after
	// the table itself is inserted by the command.
	let command = editor.commands.get( 'insertTable' );
	if ( !command ) return;
	command.on( "execute", ( eventInfo ) => {
		let root = editor.model.document.getRoot() || editor.model.document._getDefaultRoot() ||
			// TODO proove array before selecting first element
			edt.model.document.roots._items.filter( item => item.rootName == "main" )[ 0 ];
		if ( !root || !root._children || !root._children._nodes ||
			root._children._nodes.length <= 0 ) return;
		let lastChild = root._children._nodes[ root._children._nodes.length - 1 ];
		// is getObjectProto necessary?
		if ( !lastChild || lastChild.name != "table" || getObjectProto( lastChild ) != "Element" ) return;
		let position = positionAtRoot( editor, true );
		if ( !position ) return;
		editor.model.change( writer => {
			const paragraph = writer.createElement( 'paragraph' );
			writer.insert( paragraph, position );
		} );
	}, { priority: "lowest" } );
}
