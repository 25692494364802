import Command from '@ckeditor/ckeditor5-core/src/command';
import { SELECTALL } from './pisaselectallcommand';
import SelectAllCommand from './pisaselectallcommand';
import { DELETEALL } from './pisadeleteall';

export default class DeleteAllCommand extends Command {

	constructor( editor ) {
		super( editor, DELETEALL );
	}

	execute( options = {} ) {
		const editor = this.editor;
		editor.executeIf( SELECTALL );
		editor.executeIf( "delete" );
		if ( !editor.objects || !editor.objects.placeholders ||
			!editor.objects.placeholders.map ) return;
		editor.objects.placeholders.map = new Map();
		if ( !editor.objects.selection ) return;
		editor.objects.selection.update();
	}
}
