import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import IconView from '@ckeditor/ckeditor5-ui/src/icon/iconview';
import FontColorCommand from './pisacolorcommand';
import EmptyView from '../pisadropdown/emptyview';
import { addViewChildren, createColorInput, getLiveAttributeValue } from '../utils';
import { fontColorIcon } from '../icons';
export const FONT_COLOR_COMPACT = "fontColorCompact";
const FONT_COLOR = "fontColor";

export default class FontColorCompactUI extends Plugin {
	init() {
		const editor = this.editor;
		if ( !editor.commands.get( FONT_COLOR ) )
			editor.commands.add( FONT_COLOR, new FontColorCommand( editor ) );
		editor.ui.componentFactory.add( FONT_COLOR_COMPACT, locale => {
			return createCompactColorView( editor, FONT_COLOR, fontColorIcon,
				editor.objects.tooltips.getT( FONT_COLOR_COMPACT ) );
		} );
	}
}

export function createCompactColorView( editor, commandName, icon, label ) {
	// TODO add label
	let container = new EmptyView( [ "" ] );
	let colorInput = createColorInput( editor.locale, [ 'pisa-place-center' ] );
	let iconView = new IconView();
	iconView.set( "content", icon );

	colorInput.on( "input", () => {
		let color = String( colorInput.element.value );
		iconView.fillColor = color;
		editor.executeIf( commandName, { value: color } );
	}, { priority: "highest" } );

	editor.editing.view.document.on( 'selectionChangeDone', () => {
		let targetAttribute = getLiveAttributeValue( editor, commandName );
		colorInput.element.value = targetAttribute || "#111111";
		iconView.fillColor = targetAttribute || "#c0c0c0";
	}, { priority: "lowest" } );

	addViewChildren( container, { 0: iconView, 1: colorInput } );

	editor.objects.focus._addExecuteFocus( container );

	return container;
}
