import ALIGN_CENTER from '../theme/icons/align-center.svg';
import ALIGN_JUSTIFY from '../theme/icons/align-justify.svg';
import ALIGN_LEFT from '../theme/icons/align-left.svg';
import ALIGN_RIGHT from '../theme/icons/align-right.svg';
import BACKGROUND_COLOR from '../theme/icons/bg_color.svg';
import BLOCKQUOTE from '../theme/icons/quote.svg';
import BOLD from '../theme/icons/bold.svg';
import BULLETED_LIST from '../theme/icons/bulletedlist.svg';
import CODE from '../theme/icons/code.svg';
import CONTINUE_LIST from '../theme/icons/continue-list-numeration.svg';
import COPY_FORMAT from '../theme/icons/copy-format-3.svg';
import DELETE_ALL from '../theme/icons/delete-all.svg';
import EDIT_TABLE from '../theme/icons/edit-table.svg';
import FONT_COLOR from '../theme/icons/text-color.svg';
import FONT_FAMILY from '../theme/icons/font-family.svg';
import FONT_SIZE from '../theme/icons/font-size.svg';
import FONT_SIZE_MINUS from '../theme/icons/font-size-minus.svg';
import FONT_SIZE_PLUS from '../theme/icons/font-size-plus.svg';
import IMAGE from '../theme/icons/image.svg';
import IMAGE_RESIZE from '../theme/icons/resize-image-new.svg';
import INDENT_LEFT from '../theme/icons/indent-left.svg';
import INDENT_RIGHT from '../theme/icons/indent-right.svg';
import ITALIC from '../theme/icons/italic.svg';
import LESS_TOOLBAR from '../theme/icons/chevron-up-regular.svg';
import LINK from '../theme/icons/link.svg';
import MORE_TOOLBAR from '../theme/icons/chevron-down-regular.svg';
import THREE_DOTS from '../theme/icons/show-more.svg';
import NUMBERED_LIST from '../theme/icons/numberedlist.svg';
import OBJECT_LINK from '../theme/icons/link-object.svg';
import PLAIN_TEXT from '../theme/icons/plain-text.svg';
import PRINT from '../theme/icons/print-icon.svg';
import REMOVE_FORMAT from '../theme/icons/clear-formatting.svg';
import SEARCH from '../theme/icons/search.svg';
import SPACING from '../theme/icons/spacing-line.svg';
import SPELLCHECK from '../theme/icons/spellcheck.svg';
import START_LIST from '../theme/icons/start-list-numeration.svg';
import STRIKETHROUGH from '../theme/icons/strikethrough.svg';
import SUBSCRIPT from '../theme/icons/subscript.svg';
import SUPERSCRIPT from '../theme/icons/superscript.svg';
import TABLE from '../theme/icons/table.svg';
import TABLE_CELL from '../theme/icons/table-cell.svg';
import TABLE_COLUMN from '../theme/icons/table-column.svg';
import TABLE_ROW from '../theme/icons/table-row.svg';
import UNDERLINE from '../theme/icons/underline.svg';
import RICH_TEXT from '../theme/icons/rich-text.svg';
import EMOJI from '../theme/icons/smile-regular.svg';
import PEOPLE from '../theme/icons/child-regular.svg';
import FOOD from '../theme/icons/pizza-slice-regular.svg';
import NATURE from '../theme/icons/leaf-maple-regular.svg';
import OBJECTS from '../theme/icons/umbrella-regular.svg';
import PLACES from '../theme/icons/archway-regular.svg';
import SYMBOLS from '../theme/icons/heart-regular.svg';
import MOVE_IMAGE from '../theme/icons/images-regular.svg';
import INCREASE_IMAGE from '../theme/icons/expand-regular.svg';
import DECREASE_IMAGE from '../theme/icons/compress-regular.svg';
import DELETE_IMAGE from '../theme/icons/times-regular.svg';
import MAXIMIZE from '../theme/icons/expand-arrows-regular.svg';
import DEFAULT from '../theme/icons/eraser-regular.svg';
import COPY from '../theme/icons/copy-regular.svg';
import CUT from '../theme/icons/cut-regular.svg';
import PASTE from '../theme/icons/paste-regular.svg';
import CLIPBOARD from '../theme/icons/clipboard-regular.svg';
import ARROW_UP from '../theme/icons/arrow-up-regular.svg';
import ARROW_DOWN from '../theme/icons/arrow-down-regular.svg';
import PLACEHOLDER from '../theme/icons/rectangle-wide-regular.svg';
import ADD_PARAGRAPH from '../theme/icons/add-paragraph.svg';
import PARAGRAPH_ABOVE from '../theme/icons/paragraph-above.svg';
import PARAGRAPH_BELOW from '../theme/icons/paragraph-below.svg';
import NO_COLOR from '../theme/icons/tint-slash-duotone.svg';
import REFRESH from '../theme/icons/sync-regular.svg';
import EXCLAMATION_POINT from '../theme/icons/exclamation-circle-regular.svg';
import LOCKDOWN from '../theme/icons/lock-alt-regular.svg';
import EDIT_PLACEHOLDERS from '../theme/icons/money-check-edit-regular.svg';

export const alignLeftIcon = ALIGN_LEFT;
export const alignRightIcon = ALIGN_RIGHT;
export const alignCenterIcon = ALIGN_CENTER;
export const alignJustifyIcon = ALIGN_JUSTIFY;
export const quoteIcon = BLOCKQUOTE;
export const boldIcon = BOLD;
export const codeIcon = CODE;
export const italicIcon = ITALIC;
export const linkIcon = LINK;
export const numberedListIcon = NUMBERED_LIST;
export const bulletedListIcon = BULLETED_LIST;
export const strikethroughIcon = STRIKETHROUGH;
export const tableIcon = TABLE;
export const tableColumnIcon = TABLE_COLUMN;
export const tableRowIcon = TABLE_ROW;
export const tableMergeCellIcon = TABLE_CELL;
export const editTableIcon = EDIT_TABLE;
export const underlineIcon = UNDERLINE;
export const backgroundIcon = BACKGROUND_COLOR;
export const copyFormatIcon = COPY_FORMAT;
export const deleteAllIcon = DELETE_ALL;
export const fontColorIcon = FONT_COLOR;
export const fontFamilyIcon = FONT_FAMILY;
export const fontSizeIcon = FONT_SIZE;
export const plusIcon = FONT_SIZE_PLUS;
export const minusIcon = FONT_SIZE_MINUS;
export const imageIcon = IMAGE;
export const resizeIcon = IMAGE_RESIZE;
export const indentLeft = INDENT_LEFT;
export const indentRight = INDENT_RIGHT;
export const objectLinkIcon = OBJECT_LINK;
export const numberingIcon = CONTINUE_LIST;
export const startListIcon = START_LIST;
export const plainTextIcon = PLAIN_TEXT;
export const printIcon = PRINT;
export const removeFormatIcon = REMOVE_FORMAT;
export const searchIcon = SEARCH;
export const spacingIcon = SPACING;
export const spellcheckIcon = SPELLCHECK;
export const subscriptIcon = SUBSCRIPT;
export const superscriptIcon = SUPERSCRIPT;
export const moreToolbarIcon = MORE_TOOLBAR;
export const lessToolbarIcon = LESS_TOOLBAR;
export const richTextIcon = RICH_TEXT;
export const emojiIcon = EMOJI;
export const peopleIcon = PEOPLE;
export const foodIcon = FOOD;
export const natureIcon = NATURE;
export const objectsIcon = OBJECTS;
export const placesIcon = PLACES;
export const symbolsIcon = SYMBOLS;
export const moveImageIcon = MOVE_IMAGE;
export const increaseImageIcon = INCREASE_IMAGE;
export const decreaseImageIcon = DECREASE_IMAGE;
export const deleteImageIcon = DELETE_IMAGE;
export const maximizeIcon = MAXIMIZE;
export const defaultIcon = DEFAULT;
export const copyIcon = COPY;
export const cutIcon = CUT;
export const pasteIcon = PASTE;
export const clipboardIcon = CLIPBOARD;
export const arrowUpIcon = ARROW_UP;
export const arrowDownIcon = ARROW_DOWN;
export const placeholderIcon = PLACEHOLDER;
export const addParagraphIcon = ADD_PARAGRAPH;
export const addParagraphAbove = PARAGRAPH_ABOVE;
export const addParagraphBelow = PARAGRAPH_BELOW;
export const threeDots = THREE_DOTS;
export const removeColorIcon = NO_COLOR;
export const refreshIcon = REFRESH;
export const exclamationCircleIcon = EXCLAMATION_POINT;
export const lockEditingIcon = LOCKDOWN;
export const editPlaceholdersIcon = EDIT_PLACEHOLDERS;
