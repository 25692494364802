import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import LinkActionsView from '@ckeditor/ckeditor5-link/src/ui/linkactionsview';
import unlinkIcon from '@ckeditor/ckeditor5-link/theme/icons/unlink.svg';
import pencilIcon from '@ckeditor/ckeditor5-core/theme/icons/pencil.svg';
import PluginUtils from '../pisautils/pluginutils';
import Validator from '../pisautils/validator';
// import { ensureSafeUrl } from '@ckeditor/ckeditor5-link/src/utils';
import { ensureSafeUrl } from './pisaextendedlinkediting';
import { LINK_BUTTON_CLICKED_EVENT } from './pisalinkui';

export const PISA_UNLINK = "pisaUnlink";
export const PISA_EDIT_LINK = "pisaEditLink";
export const PISA_OPEN_LINK = "pisaOpenLink";
export const PISA_LINK_NO_URL = "pisaLinkWithNoUrl";
const LOG_CHANGES = true;

export default class PisaLinkActionsView extends LinkActionsView {

	constructor( locale, editor ) {
		super( locale );

		this.editor = editor;

		new PluginUtils( {
			hostPlugin: this,
			logChanges: LOG_CHANGES,
			logOutputColor: "#736598"
		} );

		[ "previewButtonView", "unlinkButtonView", "editButtonView" ]
		.forEach( property => {
			this._removeProperty( property );
		} );

		// let previewButtonView = this.previewButtonView;
		// this.previewButtonView = void 0;
		// delete this.previewButtonView;
		// if ( Validator.isFunction( previewButtonView.destroy ) )
		// 	previewButtonView.destroy();
		// previewButtonView = void 0;
		//
		// let unlinkButtonView = this.unlinkButtonView;
		// this.unlinkButtonView = void 0;
		// delete this.unlinkButtonView;
		// if ( Validator.isFunction( unlinkButtonView.destroy ) )
		// 	unlinkButtonView.destroy();
		// unlinkButtonView = void 0;
		//
		// let editButtonView = this.editButtonView;
		// this.editButtonView = void 0;
		// delete this.editButtonView;
		// if ( Validator.isFunction( editButtonView.destroy ) )
		// 	editButtonView.destroy();
		// editButtonView = void 0;

		/**
		 * @override
		 */
		this.previewButtonView = this._doCreatePreviewButton();

		/**
		 * @override
		 */
		this.unlinkButtonView = this
			._doCreateButton( this.getTooltip( PISA_UNLINK ), unlinkIcon, 'unlink' );

		/**
		 * @override
		 */
		this.editButtonView = this
			._doCreateButton( this.getTooltip( PISA_EDIT_LINK ), pencilIcon, 'edit' );

		this.setTemplate( {
			tag: 'div',

			attributes: {
				class: [
					'ck',
					'ck-link-actions',
				],

				// https://github.com/ckeditor/ckeditor5-link/issues/90
				tabindex: '-1'
			},

			children: [
				this.previewButtonView,
				this.editButtonView,
				this.unlinkButtonView
			]
		} );

	}

	/**
	 * @override
	 */
	_createButton() {
		return {};
	}

	_doCreateButton( label, icon, eventName ) {
		const button = new ButtonView( this.locale );

		button.set( {
			label,
			icon,
			tooltip: true
		} );

		button.delegate( 'execute' ).to( this, eventName );

		button.on( "execute", ( eventInfo, data ) => {
			this.editor.fire( LINK_BUTTON_CLICKED_EVENT );
		} );

		return button;
	}

	/**
	 * @override
	 */
	_createPreviewButton() {
		return {};
	}

	_doCreatePreviewButton() {
		const button = new ButtonView( this.locale );
		const bind = this.bindTemplate;
		const t = this.t;

		button.set( {
			withText: true,
			tooltip: this.getTooltip( PISA_OPEN_LINK )
		} );

		button.extendTemplate( {
			attributes: {
				class: [
					'ck',
					'ck-link-actions__preview'
				],
				href: bind.to( 'href', href => href && ensureSafeUrl( href ) ),
				target: '_blank'
			}
		} );

		button.bind( 'label' ).to( this, 'href', href => {
			return href || this.getTooltip( PISA_LINK_NO_URL );
		} );

		button.bind( 'isEnabled' ).to( this, 'href', href => !!href );

		button.template.tag = 'a';
		button.template.eventListeners = {};

		return button;
	}

	get tooltipManager() {
		return Validator.isObjectPath( this, "this.editor.objects.tooltips" ) &&
			Validator.isFunction( this.editor.objects.tooltips.getT ) ?
			this.editor.objects.tooltips : void 0;
	}

	getTooltip( tooltipKeyword ) {
		if ( !Validator.isString( tooltipKeyword ) )
			return this._log( `Could not get tooltip for invalid keyword.`, "" );
		let tooltipManager = this.tooltipManager;
		if ( !Validator.isObject( tooltipManager ) )
			return this._log( `Could not get editor's own tooltip manager.`, "" );
		return tooltipManager.getT( tooltipKeyword );
	}

}
