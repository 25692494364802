import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import LabelView from '@ckeditor/ckeditor5-ui/src/label/labelview';
import EmptyInputView from './pisainput/pisainputview';
import Position from '@ckeditor/ckeditor5-engine/src/model/position.js';
import Range from '@ckeditor/ckeditor5-engine/src/model/range';
import Selection from '@ckeditor/ckeditor5-engine/src/model/selection';
import LabeledInputView from '@ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor';
import UpcastWriter from '@ckeditor/ckeditor5-engine/src/view/upcastwriter';
import { SWITCH_VISIBILITY } from './pisatoolbar/pisaswitchvisibilitycommand';

export function makeVisible( editor, view ) {
	enableAndExecute( editor, SWITCH_VISIBILITY, { view: view, visible: true } );
}

export function makeInvisible( editor, view ) {
	enableAndExecute( editor, SWITCH_VISIBILITY, { view: view, visible: false } );
}

export function enableAndExecute( editor, commandName, options = {} ) {
	editor.executeIf( commandName, options, true );
	return;
	const command = editor.commands.get( commandName );
	if ( !command ) {
		console.warn( `Could not enable and execute command "${ commandName }". ` +
			`No such command found in editor.commands.` );
		return;
	}
	const wasEnabled = command.isEnabled;
	command.set( 'isEnabled', true );
	try {
		command.execute( options );
	} finally {
		if ( !wasEnabled ) {
			command.set( 'isEnabled', false );
		}
	}
}

export function fire( editor, eventName, options = {} ) {
	enableAndExecute( editor, "pisaFire", {
		event: eventName,
		options: options
	}, true );
}

export function bindClickToExecute( view ) {
	view.extendTemplate( {
		on: {
			click: view.bindTemplate.to( evt => {
				view.fire( 'execute' );
			} )
		}
	} );
}

export function getEditableElement( editor ) {
	if ( !editor || typeof editor != "object" || !editor.ui ||
		typeof editor.ui != "object" || !editor.ui.getEditableElement ||
		typeof editor.ui.getEditableElement != "function" ) return;
	let editableElement = editor.ui.getEditableElement( "main" ) ||
		editor.ui.getEditableElement();
	if ( !editableElement || !( editableElement instanceof HTMLElement ) ) return;
	return editableElement;
}

export function changeCursor( editor, cursorStyleName = "" ) {
	let editableElement = getEditableElement( editor );
	if ( !editableElement || !( editableElement instanceof HTMLElement ) ||
		!editableElement.style || typeof editableElement.style != "object" ) return;
	if ( typeof cursorStyleName != "string" ) cursorStyleName = "";
	editableElement.style.cursor = cursorStyleName;
}

export function setContentEditable( editor, editable = true ) {
	let editableElement = getEditableElement( editor );
	if ( !editableElement || !( editableElement instanceof HTMLElement ) ) return;
	editableElement.setAttribute( "contenteditable", !!editable );
}

export function isValidModelPosition( position ) {
	if ( !position ) return false;
	let value;
	try {
		value = "parent";
		value = position.parent || "parent";
		if ( value == "parent" ) throw {};
		value = "index";
		value = position.index;
		value = "isAtEnd";
		value = position.isAtEnd;
		value = "isAtStart";
		value = position.isAtStart;
		value = "nodeBefore";
		value = position.nodeBefore;
		value = "nodeAfter";
		value = position.nodeAfter;
	} catch ( err ) {
		console.warn( `Model position is invalid. Error thrown when trying to get "position.${ value }".` );
		value = "?";
	}
	return value != "?";
}

export function isPositionParentText( position ) {
	if ( !position ) return false;
	return position.parent && getObjectProto( position.parent ) == "Text";
}

export function positionOutsideOfText( editor, path ) {
	if ( !editor || !path || !( path instanceof Array ) ) return;
	let root = editor.model.document.getRoot();
	let newPath = [ ...path ];
	newPath.length > 5 ? newPath = newPath.slice( 0, 5 ) : newPath.length > 2 ?
		newPath = newPath.slice( 0, 2 ) : void 0;
	return new Position( root, newPath );
}

export function areLiveSelectionRangesValid( editor ) {
	if ( typeof editor != "object" || typeof editor.model != "object" ||
		typeof editor.model.document != "object" ||
		typeof editor.model.document.selection != "object" ||
		!( editor.model.document.selection._ranges instanceof Array ) ||
		editor.model.document.selection._ranges.length <= 0 ) return true;
	let ranges = editor.model.document.selection._ranges[ 0 ];
	if ( !ranges ) return true;
	return !( ( ranges.start && !isValidModelPosition( ranges.start ) ) ||
		( ranges.end && !isValidModelPosition( ranges.end ) ) );
}

export function handleInvalidSelectionRanges( editor, saved = true, writer = false ) {
	console.warn( "Live selection ranges are invalid. Trying to change live " +
		"selection to valid ranges." );
	let path = editor.model.document.selection._ranges[ 0 ].end.path;
	// TODO when position is inside textNode, path has 3 or 6 numbers and
	// usually the start position is before a character, while the end position
	// is after that same character, so it looks something like
	//			start: [ 0, 6, 0 ]
	//			end: [ 0, 6, 6 ]
	// which would mean that we have to insert AFTER that character, however the
	// slice method ensures that the insertion happens BEFORE the character,
	// which may lead to inconsistencies, such as inserting one character "earlier"
	// than where the caret is placed.
	path.length > 5 ? path = path.slice( 0, 5 ) : path.length > 2 ?
		path = path.slice( 0, 2 ) : void 0;
	let success = true;
	if ( saved ) {
		try {
			if ( !editor.objects.selection._setTo( path ) ) success = false;
		} catch ( e ) {
			console.warn( `Couldn't set editor objects selection to path [ ${ String( path ) } ]. Error:` );
			console.warn( e );
			success = false;
		}
	}
	if ( writer ) {
		try {
			let root = editor.model.document.getRoot();
			let position = editor.model.createPositionFromPath( root, path );
			editor.model.change( writer => {
				writer.setSelection( position );
			} );
		} catch ( e ) {
			console.warn( `Couldn't set live selection to path [ ${ String( path ) } ]. Error:` );
			console.warn( e );
			success = false;
		}
	}
	if ( success ) return true;
	if ( !areLiveSelectionRangesValid( editor ) ) {
		console.warn( "Live selection ranges are invalid even after changing. " +
			"Insert Placeholder Command will not be executed." );
	}
	return false;
}

export function onDomEvent( editor, eventName, callbackFunction ) {
	if ( !editor || !eventName || typeof eventName != "string" ||
		!callbackFunction || typeof callbackFunction != "function" ) return;
	editor.once( "ready", () => {
		let editableElement = editor.ui.getEditableElement();
		if ( !editableElement ) return;
		editableElement.addEventListener( eventName, callbackFunction );
	} );
}

export function addViewDomListener( view, eventName, callback ) {
	view.once( "render", () => {
		view.element.addEventListener( eventName, callback );
	} );
}

export function stopEvent( eventInfo, data ) {
	if ( data.domEvent ) {
		data.domEvent.preventDefault();
		data.domEvent.stopPropagation();
	}
	typeof data.preventDefault == "function" && data.preventDefault();
	typeof data.stopPropagation == "function" && data.stopPropagation();
	eventInfo && eventInfo.stop();
}

export function removeLastUndoSteps( editor, quantity = 1 ) {
	let undo = editor.commands.get( "undo" );
	if ( !undo || typeof quantity != "number" ) return;
	for ( let i = 0; i < quantity; i++ ) {
		if ( !undo._stack || undo._stack.length < 1 ) break;
		undo._stack.pop();
		undo._stack.length < 1 && undo.clearStack();
	}
}

export function createDocumentFragment( children ) {
	let upcastWriter = new UpcastWriter();
	return upcastWriter.createDocumentFragment( stringToArray( children ) );
}

export function addViewChildren( parentView, children, keysAreIndexes = false ) {
	if ( typeof children != "object" || ( !parentView.children && !parentView.items ) ) {
		return;
	}
	Object.entries( children ).forEach( entry => {
		if ( parentView.children ) {
			if ( keysAreIndexes ) {
				parentView.children.add( entry[ 1 ], entry[ 0 ] );
			} else {
				parentView.children.add( entry[ 1 ] );
			}
		} else if ( parentView.items ) {
			if ( keysAreIndexes ) {
				parentView.items.add( entry[ 1 ], entry[ 0 ] );
			} else {
				parentView.items.add( entry[ 1 ] );
			}
		}
	} );
}

export function getLiveSelectionNode( start = true ) {
	let selection = window.getSelection();
	if ( !selection ) return null;
	let node = start && start != "end" ? ( selection.anchorNode || selection.baseNode ) :
		( selection.focusNode || selection.extentNode );
	return node ? ( node.tagName ? node : ( node.parentNode ? node.parentNode : null ) ) : null;
}

export function getParentOfType( node, parentTagName ) {
	let parent = null;
	while ( !!node && node instanceof HTMLElement &&
		!( node instanceof HTMLBodyElement ) && node.parentElement ) {
		if ( !( node.parentElement instanceof HTMLElement ) ||
			typeof node.parentElement.tagName != "string" ||
			node.parentElement.tagName != parentTagName ) {
			node = node.parentElement;
			continue;
		}
		parent = node.parentElement;
		break;
	}
	return parent;
}

export function getLiveNodeStyle() {
	let node = getLiveSelectionNode() || getLiveSelectionNode( false );
	return getNodeStyle( node );
}

function getNodeStyle( node ) {
	while ( node && node.parentNode && !( node instanceof Element ) ) {
		node = node.parentNode;
	}
	return ( node instanceof Element ? window.getComputedStyle( node ) : null ) ||
		( node && node.parentNode && node.tagName != "HTML" ? getNodeStyle( node.parentNode ) : {} );
}

export function getSelectionNode( editor, selection = null ) {
	if ( !editor || ( !selection && ( !editor.model || !editor.model.document ||
			!editor.model.document.selection ) ) ) return null;
	selection = selection || editor.model.document.selection;
	if ( !selection.anchor || !selection.anchor.parent ) return null;
	return getElementNode( editor, selection.anchor.parent );
}

export function getElementNode( editor, element ) {
	const editableElement = editor && editor.ui ? editor.ui.getEditableElement() : null;
	if ( !editableElement || !element ) {
		return null;
	}
	return getNodeByPath( editableElement, element.getPath() );
}

export function getNodeByPath( parentNode, pathArray ) {
	if ( !pathArray || pathArray.length <= 0 || typeof pathArray != "object" ) return null;
	if ( !parentNode || typeof parentNode != "object" || !parentNode.childNodes ||
		parentNode.toString().toLowerCase().indexOf( "html" ) < 0 ) return null;
	let children = Array.from( parentNode.childNodes );
	parentNode.getElementsByTagName( "ol" ).length > 0 ?
		children = replaceNodeWithChildren( children, "ol" ) : void 0;
	parentNode.getElementsByTagName( "ul" ).length > 0 ?
		children = replaceNodeWithChildren( children, "ul" ) : void 0;
	if ( children.length - 1 < Number( pathArray[ 0 ] ) && pathArray.length > 1 ) return null;
	if ( children.length - 1 < Number( pathArray[ 0 ] ) && pathArray.length <= 1 ) return parentNode;
	let newParent = children[ Number( pathArray[ 0 ] ) ];
	if ( !newParent || typeof newParent.tagName != "string" ) return null;
	newParent.tagName.toLowerCase() == "figure" ?
		newParent = newParent.getElementsByTagName( "table" )[ 0 ].childNodes[ 0 ] : void 0;
	pathArray.shift();
	if ( pathArray.length <= 0 ) return newParent;
	return getNodeByPath( newParent, pathArray );
}

function replaceNodeWithChildren( nodesArray, nodeName ) {
	nodesArray = Array.from( nodesArray );
	for ( let i = 0; i < nodesArray.length; i++ ) {
		if ( nodesArray[ i ].tagName.toLowerCase() != nodeName ) continue;
		let items = Array.from( nodesArray[ i ].childNodes );
		nodesArray.splice( i, 1 );
		items.reverse();
		items.forEach( item => {
			nodesArray.splice( i, 0, item );
		} );
	}
	return nodesArray;
}

export function getModelElementByPath( parentElement, pathArray ) {
	if ( getObjectProto( parentElement ).indexOf( "Element" ) < 0 ||
		getObjectProto( pathArray ).indexOf( "Array" ) < 0 ||
		typeof parentElement._children != "object" ||
		typeof parentElement._children._nodes != "object" ||
		parentElement._children._nodes.length <= 0 || pathArray.length <= 0 ) return;
	let children = parentElement._children._nodes;
	let index = pathArray[ 0 ];
	if ( typeof index != "number" || children.length < index + 1 ) return;
	let childAtIndex = children[ index ];
	if ( pathArray.length == 1 ) return childAtIndex;
	let newPath = [ ...pathArray ];
	newPath.shift();
	return getModelElementByPath( childAtIndex, newPath );
}

export function upcastStyleTagAttr( editor, viewTag = "div", viewAttribute = "", modelKey = "" ) {
	if ( !editor || ( !viewAttribute && !modelKey ) ) return;
	!viewAttribute ? viewAttribute = modelKey : void 0;
	!modelKey ? modelKey = viewAttribute : void 0;
	let modelValueFunction = `viewElement => {` +
		`const regexp = /${ viewAttribute }:([^;]+);/;` +
		`const match = viewElement.getAttribute( "style" ).match( regexp );` +
		`return match && match.length > 0 ? match[0].replace( /${ viewAttribute }:/g, "").replace( /;/g, "") : null; }`;
	let styles = {};
	styles[ viewAttribute ] = new RegExp( "[\\S]+" );
	editor.conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: viewTag,
			styles: styles
		},
		model: {
			key: modelKey,
			value: eval( modelValueFunction.toString() )
		}
	} );
}

export function upcastStyleTagAttribute( editor, viewTag,
	viewAttribute = "", modelAttribute = "" ) {
	if ( !editor || typeof editor != "object" || !viewTag ||
		typeof viewTag != "string" || viewTag.length < 1 ) return;
	if ( ( !viewAttribute || typeof viewAttribute != "string" || viewAttribute.length < 1 ) &&
		( !modelAttribute || typeof modelAttribute != "string" || modelAttribute.length < 1 ) ) return;
	if ( !viewAttribute || typeof viewAttribute != "string" || viewAttribute.length < 1 )
		viewAttribute = modelAttribute;
	if ( !modelAttribute || typeof modelAttribute != "string" || modelAttribute.length < 1 )
		modelAttribute = viewAttribute;
	let styles = {};
	styles[ viewAttribute ] = new RegExp( "[\\S]+" );
	editor.conversion.for( 'upcast' ).attributeToAttribute( {
		view: {
			name: viewTag,
			styles: styles
		},
		model: {
			key: modelAttribute,
			value: viewElement => {
				let value = viewElement.getStyle( viewAttribute );
				if ( value ) return value;
				value = viewElement._styles && viewElement._styles instanceof Map &&
					viewElement._styles.size > 0 ?
					viewElement._styles.get( viewAttribute ) : value;
				if ( value ) return value;
				const regexp = new RegExp( `${ viewAttribute }:[^;]+;`, "g" );
				let style = viewElement.getAttribute( "style" );
				if ( !style || typeof style != "string" || style.length < 1 ) return null;
				const match = style.match( regexp );
				if ( !match || !( match instanceof Array ) || match.length < 1 ) return null;
				const replaceRegexp = new RegExp( `${ viewAttribute }:`, "g" );
				if ( match.length != 2 )
					return match[ 0 ].replace( replaceRegexp, "" ).replace( /;/g, "" );
				const additionalRegexp = new RegExp( `[^\-]${ viewAttribute }:[^;]+;`, "g" );
				const additionalMatch = style.match( additionalRegexp );
				if ( !additionalMatch || !( additionalMatch instanceof Array ) ||
					additionalMatch.length < 1 ) return match[ 0 ].replace( replaceRegexp, "" ).replace( /;/g, "" );
				value = additionalMatch[ 0 ].slice( 1 );
				return value.replace( replaceRegexp, "" ).replace( /;/g, "" );
			}
		}
	} );

}

export function viewElementFunction( attribute ) {
	return `viewElement => {` +
		`const regexp = new RegExp( String( "${ attribute }" ).concat(':[^;]+;') );` +
		`const replaceRegexp = new RegExp( String( "${ attribute }" ).concat(":"), "g" );` +
		`const match = viewElement.getAttribute( "style" ).match( regexp );` +
		`let value = viewElement.getStyle( "${ attribute }" ) ` +
		`|| ( viewElement._styles ? viewElement._styles.get( "${ attribute }" ) : null ) ` +
		`|| match[0].replace( replaceRegexp, "").replace( /;/g, "");` +
		`return value; }`;
}

export function downcastStyleTagAttr( editor, modelName = "paragraph", modelAttribute = "", viewAttribute = "" ) {
	if ( !editor || ( !modelAttribute && !viewAttribute ) ) return;
	!modelAttribute ? modelAttribute = viewAttribute : void 0;
	!viewAttribute ? viewAttribute = modelAttribute : void 0;
	let viewValueFunction = `modelAttributeValue => ( {` +
		`key: "style",` +
		`value: { "${ viewAttribute }": modelAttributeValue }` +
		`} )`;
	let config = {
		model: {
			name: modelName,
			key: modelAttribute
		},
		view: eval( viewValueFunction )
	};
	editor.conversion.for( 'downcast' ).attributeToAttribute( config );
}

export function addUpcastConversion( editor, viewAttribute, modelAttribute, tagsList = null ) {
	let htmlTags = tagsList || [ "span", "div", "p" ];
	let viewStyles = {};
	viewStyles[ viewAttribute ] = new RegExp( '\\s?[^;^\\s]+' );

	htmlTags.forEach( tag => {
		editor.conversion.for( 'upcast' ).elementToAttribute( {
			view: {
				name: tag,
				styles: viewStyles
			},
			model: {
				key: modelAttribute,
				value: eval( viewElementFunction( viewAttribute ) )
			}
		}, { priority: "high" } );
	} );
}

export function addUpcastContainerConversion( editor, viewAttribute, modelAttribute ) {
	let viewStyles = {};
	viewStyles[ viewAttribute ] = new RegExp( '\\s?[^;^\\s]+' );

	[ "div", "p" ].forEach( tag => {
		editor.conversion.for( 'upcast' ).elementToAttribute( {
			view: {
				name: tag,
				styles: viewStyles
			},
			model: {
				key: modelAttribute,
				value: viewElement => {
					const regexp = new RegExp( String( viewAttribute ).concat( ':[^;]+;' ) );
					const replaceRegexp = new RegExp( String( viewAttribute ).concat( ":" ), "g" );
					const match = viewElement.getAttribute( "style" ).match( regexp );
					let value = viewElement.getStyle( viewAttribute ) ||
						match[ 0 ].replace( replaceRegexp, "" ).replace( /;/g, "" );
					value = getComputedValue( viewElement, viewAttribute, value );
					return value;
				}
			}
		}, { priority: "lowest" } );
	} );
}

function getComputedValue( element, attribute, value = null ) {
	value = element && element._styles && element._styles instanceof Map ?
		( element._styles.get( attribute ) || value ) : value;
	if ( !element || !element._children ) return value;
	// do not force any attributes from the containers on links ( <a> tags )!
	// so if it has any a tags as children, the value will not be converted
	for ( let child of Array.from( element._children ) ) {
		if ( child.name == "a" ) {
			// null is the only thing that works as expected
			return null;
		}
	}
	// the attribute will be applied to all children, thats why it makes sense to
	// go deeper only if it has exactly 1 child
	if ( element._children.length != 1 ) return value;
	return getComputedValue( element._children[ 0 ], attribute, value );
}

export function checkSelection( editor ) {
	return !( !editor || !editor.model || !editor.model.document ||
		!editor.model.document.selection || !editor.model.document.selection._selection );
}

export function checkRange( editor, selection = null ) {
	if ( !selection && !checkSelection( editor ) ) return false;
	selection = selection ? ( selection._selection ? selection._selection : selection ) :
		editor.model.document.selection._selection;
	return !( !selection._ranges || selection._ranges.length <= 0 || !selection._ranges[ 0 ].start ||
		!selection._ranges[ 0 ].start.path || selection._ranges[ 0 ].start.path.length <= 0 ||
		!selection._ranges[ 0 ].end || !selection._ranges[ 0 ].end.path || selection._ranges[ 0 ].end.path.length <= 0 );
}

export function getLiveAttributeValue( editor, attribute ) {
	let element = getFocusElement( editor );
	let range = getLiveRange( editor );
	if ( range && range.start && range.start.path && element._children &&
		element._children._nodes && element._children._nodes.length > 0 ) {
		let startIndex = range.start.path[ range.start.path.length - 1 ];
		for ( let node of element._children._nodes ) {
			if ( startIndex >= node.startOffset && startIndex <= node.endOffset ) {
				if ( node._attrs ) {
					return node._attrs.get( attribute );
				}
				break;
			}
		}
	}
	return null;
}

export function getLiveElement( editor ) {
	let element = getFocusElement( editor );
	let range = getLiveRange( editor );
	if ( !range || !range.start || !range.start.path || !element._children ||
		!element._children._nodes || !element._children._nodes.length > 0 ) return null;
	let startIndex = range.start.path[ range.start.path.length - 1 ];
	for ( let node of element._children._nodes ) {
		if ( startIndex >= node.startOffset && startIndex <= node.endOffset ) return node;
	}
	return null;
}

export function getGlobalLiveValue( editor, attribute ) {
	return getGlobalValue( getLiveElement( editor ), attribute );
}

export function getGlobalValue( element, attribute ) {
	if ( element && element._attrs && element._attrs.get( attribute ) ) return element._attrs.get( attribute );
	if ( element && element.parent ) return getGlobalValue( element.parent, attribute );
	return null;
}

export function getSelectedContent() {
	return window.getSelection() ? window.getSelection().toString() :
		window.document.getSelection() ? window.document.getSelection().toString() : null;
	// return window.getSelection().toString();
}

export function getSelectionHtml() {
	let selection = window.getSelection() || window.document.getSelection();
	let content = {};
	content.plain = getSelectedContent();
	if ( !selection || !content.plain ) return;
	let nodeCount = content.plain.indexOf( "\n" ) < 0 ? 0 :
		( content.plain.indexOf( "\n" ) == content.plain.lastIndexOf( "\n" ) ? 1 : 2 );
	if ( nodeCount > 1 ) return content;
	if ( selection.anchorNode && selection.anchorNode.parentNode &&
		selection.anchorNode.parentNode.tagName == "SPAN" ) {
		let span = selection.anchorNode.parentNode.outerHTML.match( /<span[^>]*>/ );
		if ( span instanceof Array && span.length > 0 )
			content.html = span[ 0 ] + content.plain.split( "\n" )[ 0 ] + "</span>";
	}
	if ( nodeCount == 0 ) return content;
	content.html = content.html || "<span>" + content.plain.split( "\n" )[ 0 ] + "</span>";
	if ( selection.focusNode && selection.focusNode.parentNode &&
		selection.focusNode.parentNode.tagName == "SPAN" ) {
		let span = selection.focusNode.parentNode.outerHTML.match( /<span[^>]+>/ );
		content.html = content.html + "<br>" + span[ 0 ] + content.plain.split( "\n" )[ 1 ] + "</span>";
		return content;
	}
	content.html = content.html + "<br><span>" + content.plain.split( "\n" )[ 1 ] + "</span>";
	return content;
}

export function isLiveSelectionEmpty() {
	let selectedContent = getSelectedContent();
	return selectedContent == selectedContent.replace( /\S+/g, "" );
}

export function areSelectionsEqual( firstSelection, secondSelection ) {
	let firstRange = firstSelection._ranges[ 0 ];
	let secondRange = secondSelection._ranges[ 0 ];
	if ( firstRange.start.path.toString() != secondRange.start.path.toString() ) return false;
	if ( firstRange.end.path.toString() != secondRange.end.path.toString() ) return false;
	return true;
}

export function isSelectionValid( selection ) {
	if ( !selection || !selection._ranges || selection._ranges.length <= 0 ||
		!selection._ranges[ 0 ].start || !selection._ranges[ 0 ].end ||
		!selection._ranges[ 0 ].start.path || !selection._ranges[ 0 ].end.path ) return false;
	return true;
}

export function isSelectionEmpty( selection ) {
	if ( !isSelectionValid( selection ) ) return true;
	let startPath = selection._ranges[ 0 ].start.path;
	let endPath = selection._ranges[ 0 ].end.path;
	if ( startPath.toString() == endPath.toString() ) return true;
	// if ( startPath.length >= 2 && endPath.length >= 2
	// && startPath[ startPath.length - 2 ] + 1 == endPath[ endPath.length - 2 ]
	// && endPath[ endPath.length - 1 ].toString == "0" ) return true;
	return false;
}

export function getSelectionProps( editor ) {
	if ( !editor || !editor.model || !editor.model.document ||
		!editor.model.document.selection ||
		!isSelectionValid( editor.model.document.selection ) ) return;
	let path = editor.model.document.selection._ranges[ 0 ].start.path;
	let parent = editor.model.document.selection._ranges[ 0 ].start.parent;
	let last = path[ path.length - 1 ];
	if ( last > parent.maxOffset ) return;
	let target = null;
	for ( let child of parent._children._nodes ) {
		if ( child.endOffset >= last && child.startOffset <= last ) {
			target = child;
			break;
		}
	}
	if ( !target ) return;
	if ( !target._data && !target.data ) return { target: target };
	last = last - target.startOffset;
	let data = target._data || target.data;
	return {
		target: target,
		before: data[ last - 1 ],
		after: data[ last ]
	}
}

export function saveLastRange( editor ) {
	let selection = editor.model.document.selection;
	if ( selection.anchor.parent.name != "$root" ) {
		return selection.getFirstRange();
	}
	return null;
}

export function setSelection( editor, range ) {
	let selection = editor.model.document.selection;
	if ( range ) {
		selection._setTo( range );
	}
}

export function setSelectionOnElement( editor, element, save = true, model = true, view = true ) {
	if ( !element || !editor ) return;
	let modelRange = model ? editor.model.createRangeOn( element ) : null;
	let viewRange = view ? editor.editing.view.createRangeOn( element ) : null;
	model && modelRange ? editor.model.document.selection._selection.setTo( modelRange ) : void 0;
	view && viewRange ? editor.editing.view.document.selection._selection.setTo( viewRange ) : void 0;
	save && updateLastSelection( editor );
	// save ? editor.lastSelection = new Selection( editor.model.document.selection, 0 ) : void 0;
}

export function setSelectionInElement( editor, element, model = true, view = true ) {
	if ( !element || !editor ) return;
	let modelRange = model ? editor.model.createRangeIn( element ) : null;
	let viewRange = view ? editor.editing.view.createRangeIn( element ) : null;
	model && modelRange ? editor.model.document.selection._selection.setTo( modelRange ) : void 0;
	view && viewRange ? editor.editing.view.document.selection._selection.setTo( viewRange ) : void 0;
}

export function setModelSelection( rangeOrElement, editor ) {
	let range = getObjectProto( rangeOrElement ).indexOf( "Element" ) >= 0 ?
		editor.model.createRangeIn( rangeOrElement ) : rangeOrElement;
	editor.model.document.selection._selection.setTo( range );
}

export function setViewSelection( rangeOrElement, editor ) {
	let range = getObjectProto( rangeOrElement ).indexOf( "Element" ) >= 0 ?
		editor.editing.view.createRangeIn( rangeOrElement ) : rangeOrElement;
	editor.editing.view.document.selection._selection.setTo( range );
}

export function updateLastSelection( editor ) {
	editor.objects.selection.update();
	editor.lastSelection = new Selection( editor.model.document.selection, 0 );
	editor.lastSelection._selection ? editor.lastSelection._selection._attrs =
		editor.model.document.selection._selection._attrs :
		editor.lastSelection._attrs = editor.model.document.selection._selection._attrs;
}

export function setToLastSelection( editor ) {
	if ( !editor.lastSelection ) return;
	editor.model.document.selection._setTo( editor.lastSelection );
}

export function addClasses( view, cssClasses = [] ) {
	for ( let cssClass of cssClasses ) {
		view.extendTemplate( {
			attributes: {
				class: cssClass
			}
		} );
	}
}

export function createTextButton( tooltip, locale ) {
	let buttonView = new ButtonView( locale );
	buttonView.set( {
		withText: true,
		tooltip: tooltip,
		label: tooltip
	} );
	return buttonView;
}

export function createButton( icon, tooltip, locale ) {
	let buttonView = new ButtonView( locale );
	defineButton( buttonView, icon, tooltip );
	return buttonView;
}

export function bindButtonToCommand( buttonView, commandName, options = "", editor ) {
	let command = editor.commands.get( commandName );
	if ( typeof options == "object" ) {
		buttonView.bind( 'isEnabled' ).to( command );
		if ( options.value ) {
			buttonView.bind( 'isOn' ).to( command, 'value', value => value === options.value );
		}
		buttonView.on( 'execute', () => editor.execute( commandName, options ) );
	} else {
		buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );
		buttonView.on( 'execute', () => editor.execute( commandName ) );
	}
}

/**
 * creates a button with image or text and binds it to the specific command;
 * @param icon (optional) the icon of the button
 * @param {string} tooltip the name of the button in case it is a text button or
 * the "hover" name of the button with icon
 * @param {string} commandName the name of the command to be execute on button click
 * @param {object} options (optional) the options of the command execution, if there are any
 * @param editor editor instance
 * @return {module @ckeditor/ckeditor5-ui/src/button/buttonview~ButtonView} buttonView
 */
export function setButton( icon = "", tooltip, commandName, options = "", editor ) {
	let buttonView;
	if ( icon ) {
		buttonView = createButton( icon, tooltip, editor.locale );
	} else {
		buttonView = createTextButton( tooltip, editor.locale );
	}
	bindButtonToCommand( buttonView, commandName, options, editor );

	editor.objects.focus._addExecuteFocus( buttonView );

	return buttonView;
}

export function defineButton( buttonView, icon, tooltip ) {
	buttonView.set( {
		icon: icon,
		tooltip: tooltip
	} );
}

export function createColorInput( locale, cssClasses = [] ) {
	return new EmptyInputView( locale, {
		type: 'color',
		cssClasses: cssClasses
	} );
}

export function setColorInput( editor, cssClasses = [], commandName = "", options = {}, inputKey = "value" ) {
	let colorInput = createColorInput( editor.locale, cssClasses = [] );
	if ( commandName ) {
		bindInputToCommand( colorInput, commandName, options, inputKey, editor );
	}
	return colorInput;
}

export function createLabel( locale, text, id = "", cssClasses = "" ) {
	let labelView = new LabelView( locale );
	labelView.text = text;
	if ( id ) {
		labelView.for = id;
	}
	if ( cssClasses ) {
		addClasses( labelView, cssClasses );
	}
	return labelView;
}

export function createNumberInput( editor, parameters ) {
	if ( !editor || !parameters ) {
		return null;
	}
	let numberInput = new EmptyInputView( editor.locale, {
		type: 'number',
		max: parameters.max,
		min: parameters.min || 0,
		step: parameters.step || 1,
		placeholder: parameters.placeholder || "",
		cssClasses: parameters.cssClasses
	} );
	return numberInput;
}

export function createRangeInput( editor, parameters ) {
	if ( !editor || !parameters ) {
		return null;
	}
	let rangeInput = new EmptyInputView( editor.locale, {
		type: 'range',
		max: parameters.max,
		min: parameters.min || 0,
		step: parameters.step,
		cssClasses: parameters.cssClasses || []
	} );
	return rangeInput;
}

export function createTextInput( editor, parameters ) {
	if ( !editor || !parameters ) {
		return null;
	}
	let textInput = new EmptyInputView( editor.locale, {
		type: 'text',
		maxlength: parameters.maxlength,
		minlength: parameters.minlength || 0,
		size: parameters.size || parameters.maxlength,
		pattern: parameters.pattern || "/*+/gi",
		placeholder: parameters.placeholder,
		cssClasses: parameters.cssClasses || []
	} );
	return textInput;
}

export function createHexInput( doCreate, editor, cssClasses ) {
	if ( doCreate != true && doCreate != "true" ) return null;
	return createTextInput( editor, {
		maxlength: 7,
		minlength: 3,
		pattern: "^[\#]{0,1}[0-9a-fA-F]{3,6}$",
		placeholder: "HEX",
		cssClasses: cssClasses
	} );
}

export function createInputForm( editor, placeholder = "", label = "", cssClasses = [], parameters = "" ) {
	if ( typeof parameters == "string" ) {
		const t = editor.locale.t;
		const labeledInput = new LabeledInputView( editor.locale, InputTextView );
		if ( label ) {
			labeledInput.label = t( label );
		}
		if ( placeholder ) {
			labeledInput.inputView.placeholder = placeholder;
		}
		addClasses( labeledInput.inputView, cssClasses );
		return labeledInput;
	}
	if ( typeof parameters == "object" && parameters.type ) {
		const inputView = new EmptyInputView( editor.locale, parameters );
	}
	return new EmptyInputView( editor.locale, {
		type: "text",
		placeholder: placeholder,
		cssClasses: cssClasses
	} );
}

export function bindInputToCommand( inputView, commandName, options = {}, inputKey = "value", editor, addToInput = "", restoreSelection = false ) {
	inputView.on( "input", () => {
		let range = "";
		if ( restoreSelection ) {
			range = saveLastRange( editor );
		}
		options[ inputKey ] = inputView.element.value + addToInput;
		editor.execute( commandName, options );
		if ( restoreSelection && range ) {
			setSelection( editor, range );
			// focus;
		}
	} );
}

export function doWithSelectionRestore( editor, callback ) {
	range = saveLastRange( editor );
	callback();
	setSelection( editor, range );
}

/**
 * creates a labeled Input with an input form and binds it to the specific command;
 * @param editor editor instance
 * @param {object} properties different properties of the form
 * @param {string} properties.placeholder (optional) the placeholder for the input form
 * @param {string} properties.label (optional) the label for the input form
 * @param {Array.<String>} properties.cssClasses (optional) additional css classes for the input View
 * @param {string} properties.command (optional) the name of the command to be executed on input;
 * to create an input without binding it to a command, you could also use the "createInputForm" function
 * @param {object} properties.options (optional) the options of the command execution, if there are any
 * @param {string} properties.inputKey (optional) the key for the input value in
 * the "options" object (properties.options) for the command; defaults to "value"
 * @return {module @ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview~LabeledInputView} input
 */
export function setInputForm( editor, properties = "" ) {
	if ( editor && typeof properties == "object" && properties.command ) {
		let input = createInputForm( editor, properties.placeholder, properties.label, properties.cssClasses );
		bindInputToCommand( input.inputView, properties.command, properties.options, properties.inputKey, editor );
		return input;
	}
	if ( editor && typeof properties == "object" ) {
		return createInputForm( editor, properties.placeholder, properties.label, properties.cssClasses );
	}
	if ( editor ) {
		return createInputForm( editor );
	}
	return null;
}

/**
 * gets the range from the beginning to the end of a given element;
 * @param editor editor instance
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element
 * @return {module @ckeditor/ckeditor5-engine/src/model/range~Range}
 */
export function getElementFullRange( editor, element ) {
	return getElementRange( editor, getPathToElement( element ), element );
}

/**
 * gets the selection from the beginning to the end of a given element;
 * @param editor editor instance
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element
 * @return {module @ckeditor/ckeditor5-engine/src/model/selection~Selection}
 */
export function getElementFullSelection( editor, element ) {
	return new Selection( getElementFullRange( editor, element ) );
}

/**
 * @param editor editor instance
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element with attribute
 * @param {string} attribute the attribute that needs to be removed; from editor.ui.componentFactory._components
 */
export function removeAttributeOnElement( editor, element, attribute ) {
	let selection = editor.model.document.selection._selection;
	let range = getElementFullRange( editor, element );
	try {
		selection.setTo( range );
		if ( selection.hasAttribute( attribute ) ) {
			editor.model.change( writer => {
				writer.removeAttribute( attribute, range );
			} );
			selection.removeAttribute( attribute );
		}
	} catch ( e ) {
		// something went wrong :-)
		// console.error('Error in removeAttributeOnElement():' + e);
	}
}

export function setAttributeToUndefined( editor, element, attribute ) {
	setAttributeOnElement( editor, element, attribute, undefined );
}

/**
 * @param editor editor instance
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element to set the attribute on
 * @param {string} attribute the attribute thet needs to be set; from editor.ui.componentFactory._components
 * @param {string} value the value that should be given to the attribute
 */
export function setAttributeOnElement( editor, element, attribute, value ) {
	let selection = editor.model.document.selection._selection;
	let range = getElementFullRange( editor, element );
	selection.setTo( range );
	editor.model.change( writer => {
		writer.setAttribute( attribute, value, range );
	} );
	selection.setAttribute( attribute, value );
}

export function setAttributeOnRange( editor, range, attribute, value ) {
	let selection = editor.model.document.selection._selection;
	selection.setTo( range );
	editor.model.change( writer => {
		writer.setAttribute( attribute, value, range );
	} );
	selection.setAttribute( attribute, value );
}

/**
 * Gets the element that is currently under focus;
 * @param editor editor instance
 * @return {module @ckeditor/ckeditor5-engine/src/model/selection~Selection}
 */
export function getFocusElement( editor ) {
	let selection = editor.model.document.selection;
	if ( selection.anchor.parent ) {
		return selection.anchor.parent;
	}
	return null;
}

export function getLiveRange( editor ) {
	if ( !editor || !editor.model || !editor.model.document || !editor.model.document.selection ) return null;
	let selection = editor.model.document.selection;
	if ( selection && selection._selection && selection._selection._ranges &&
		selection._selection._ranges[ 0 ] ) {
		return selection._selection._ranges[ 0 ];
	}
	return null;
}

export function htmlToDocumentFragment( htmlString, editor ) {
	const htmlDP = new HtmlDataProcessor();
	const viewFragment = htmlDP.toView( htmlString );
	return editor.data.toModel( viewFragment );
}

export function htmlToElement( htmlString, editor ) {
	const documentFragment = htmlToDocumentFragment( htmlString, editor );
	if ( documentFragment._children && documentFragment._children._nodes && documentFragment._children._nodes.length > 0 ) {
		if ( documentFragment._children._nodes.length == 1 ) {
			return documentFragment._children._nodes[ 0 ];
		}
		return documentFragment._children._nodes;
	}
	return null;
}

/**
 *
 * Gets the range of an element when given path to it.
 * @param editor editor instance
 * @param {Array.<Number>} path like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element whose range should be found
 * @return {module @ckeditor/ckeditor5-engine/src/model/range~Range} range
 */
export function getElementRange( editor, path, element ) {
	let elementStart = Math.max( 0, element.startOffset );
	let elementEnd = Math.max( 0, element.endOffset );
	if ( elementEnd < elementStart ) {
		elementEnd = elementStart;
	}
	return getRange( editor, path, elementStart, elementEnd );
}

/**
 * Gets the absolute path to a given element.
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element whose absolute path should be found
 * @return {Array.<Number>} absolutePath like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 */
export function getPathToElement( element ) {
	let ancestors = element.getAncestors();
	let absolutePath = [];
	for ( let i = 1; i < ancestors.length; i++ ) {
		if ( typeof ancestors[ i ].index == "number" ) {
			absolutePath.push( ancestors[ i ].index );
		}
	}
	return absolutePath;
}

/**
 * get the content of the whole editor as a selection;
 * @param editor editor instance
 * @return {module @ckeditor/ckeditor5-engine/src/model/selection~Selection} selection
 */
export function getFullSelection( editor ) {
	let selection = editor.model.document.selection._selection;
	let startPosition = positionAtRoot( editor );
	let endPosition = positionAtRoot( editor, true );
	let range = new Range( startPosition, endPosition );
	return new Selection( range );
}

/**
 * creates a range from given path and indexes;
 * @param editor editor instance
 * @param {Array.<Number>} path like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @param {number} startIndex
 * @Param {number} endIndex
 * @return {module @ckeditor/ckeditor5-engine/src/model/range~Range} range
 */
export function getRange( editor, path, startIndex, endIndex ) {
	let selection = editor.model.document.selection._selection;
	let startPosition = positionAtRoot( editor );
	let endPosition = positionAtRoot( editor, true );
	startIndex = Math.max( 0, startIndex );
	endIndex = Math.max( 0, endIndex );
	let startPath = path.concat( [ startIndex ] );
	let endPath = path.concat( [ endIndex ] );
	startPosition.path = startPath;
	endPosition.path = endPath;
	return new Range( startPosition, endPosition );
}

/**
 * creates a range from two paths;
 * @param editor editor instance
 * @param {Array.<Number>} startPath like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @param {Array.<Number>} endPath like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @return {module @ckeditor/ckeditor5-engine/src/model/range~Range} range
 */
export function pathsToRange( editor, startPath, endPath ) {
	let selection = editor.model.document.selection._selection;
	let startPosition = positionAtRoot( editor );
	let endPosition = positionAtRoot( editor, true );
	startPosition.path = startPath;
	endPosition.path = endPath;
	return new Range( startPosition, endPosition );
}

export function positionsToRange( startPosition, endPosition = null ) {
	return new Range( startPosition, endPosition );
}

export function positionAtRoot( editor, end = false ) {
	let root = getRootElement( editor );
	if ( !root ) {
		return null;
	}
	let position = "";
	if ( end ) {
		let path = [];
		path.push( root.maxOffset );
		try {
			position = new Position( root, path );
		} catch ( e ) {
			console.warn( 'utils.js - positionAtRoot() - new Position( root, path ) Failed' );
		}
		if ( !position ) {
			try {
				position = Position._createAt( root, "end" );
			} catch ( e ) {
				console.warn( 'utils.js - positionAtRoot() - Position._createAt( root, "end" ) Failed' );
				position = null;
			}
		}
	} else {
		try {
			position = new Position( root, [ 0 ] );
		} catch ( e ) {
			console.warn( 'utils.js - positionAtRoot() - new Position( root, [ 0 ] ) Failed' );
			position = null;
		}
		if ( !position ) {
			try {
				position = Position._createAt( root, "start" );
			} catch ( e ) {
				console.warn( 'utils.js - positionAtRoot() - Position._createAt( root, "start" ) Failed' );
				position = null;
			}
		}
	}
	return position;
}

export function getRootElement( editor ) {
	let root = "";
	root = null;
	if ( !editor || !editor.model || !editor.model.document ) {
		return root;
	}
	if ( editor.model.document.selection && editor.model.document.selection.anchor &&
		editor.model.document.selection.anchor.root ) {
		root = editor.model.document.selection.anchor.root;
	}
	if ( !root && editor.model.document.roots && editor.model.document.roots.length > 0 ) {
		// root = editor.model.document.roots.get( editor.model.document.roots.length - 1 );
		let i = 1;
		while ( !root && i <= editor.model.document.roots.length ) {
			root = editor.model.document.roots.get( editor.model.document.roots.length - i );
			i++;
		}
	}
	return root;
}

export function getMainRoot( editor ) {
	if ( editor && editor.model && editor.model.document &&
		editor.model.document.roots && editor.model.document.roots._itemMap ) {
		return editor.model.document.roots._itemMap.get( "main" );
	}
	if ( editor && editor.model && editor.model.document &&
		editor.model.document.roots && editor.model.document.roots._items ) {
		for ( let root of editor.model.document.roots._items ) {
			if ( root.rootName == "main" ) return root;
		}
	}
	let root = getRootElement( editor );
	if ( root.rootName == "main" ) return root;
	return null;
}

export function pathToPosition( editor, path ) {
	let position = positionAtRoot( editor );
	position.path = path;
	return position;
}

/**
 * creates a selection from two paths;
 * @param editor editor instance
 * @param {Array.<Number>} startPath like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @param {Array.<Number>} endPath like {module @ckeditor/ckeditor5-engine/src/model/position~Position#path}
 * @return {module @ckeditor/ckeditor5-engine/src/model/selection~Selection} selection
 */
// TODO rename into "pathsToSelection"
export function createSelection( editor, startPath, endPath ) {
	// let range = pathsToRange( editor, startPath, endPath );
	// return new Selection( range );
	return rangeToSelection( pathsToRange( editor, startPath, endPath ) );
}

/**
 * creates a selection from a given range;
 * @param {module @ckeditor/ckeditor5-engine/src/model/range~Range} range
 * @return {module @ckeditor/ckeditor5-engine/src/model/selection~Selection} selection
 */
export function rangeToSelection( range ) {
	return new Selection( range );
}

/**
 * gets the range inside a given selection;
 * @param {module @ckeditor/ckeditor5-engine/src/model/selection~Selection} selection
 * @return {module @ckeditor/ckeditor5-engine/src/model/range~Range} range
 */
export function selectionToRange( selection ) {
	if ( selection._ranges.length > 0 ) {
		return selection._ranges[ 0 ];
	}
	return null;
}

export function getSelectionRange( selection ) {
	if ( !selection || getObjectProto( selection ).indexOf( "Selection" ) < 0 ) {
		console.warn( "Could not get invalid selection ranges." );
		return null;
	}
	if ( selection._selection ) selection = selection._selection;
	if ( !selection._ranges || getObjectProto( selection._ranges ) != "Array" || selection._ranges.length <= 0 ) {
		console.warn( "Could not get selection ranges." );
		return null;
	}
	if ( getObjectProto( selection._ranges[ 0 ] ).indexOf( "Range" ) < 0 ) {
		console.warn( "Could not get selection ranges: range not of requested type." );
		return null;
	}
	return selection._ranges[ 0 ];
}

/**
 * Returns the first element of the given type (elementName; for example: "table")
 * in the whole editor content.
 * @param editor editor instance
 * @param {string} elementName should be from editor.model.schema._sourceDefinitions
 */
export function findElementInRoot( editor, elementName ) {
	return findElementInSelection( editor, getFullSelection( editor ), elementName );
}

/**
 * Finds element in a selectioon by its name. Returns the first match. For example,
 * if elementName is "table", it will return the first table in the selection.
 * @param editor editor instance
 * @param {module @ckeditor/ckeditor5-engine/src/model/selection~Selection} selection current selection
 * @param {string} elementName should be from editor.model.schema._sourceDefinitions
 * @return {module @ckeditor/ckeditor5-engine/src/model/element~Element} element;
 */
export function findElementInSelection( editor, selection, elementName ) {
	let startIndex = selection._ranges[ 0 ].start.path[ 0 ];
	let endIndex = selection._ranges[ 0 ].end.path[ 0 ];
	let main = editor.data.model.document.getRoot( "main" );
	return findChildElement( main, startIndex, endIndex, elementName );
}

/**
 * Finds the first child element of given type (elementName; for example: "table")
 * in the parent element; The searching is done only in the elements with indexes
 * between startIndex and endIndex
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} parent parent element to search in
 * @param {number} startIndex the index of the element to begin the search from
 * @param {number} endIndex the index of the last element thet will be searched
 * @param {string} elementName should be from editor.model.schema._sourceDefinitions
 */
export function findChildElement( parent, startIndex, endIndex, elementName ) {
	// let childElement = "";
	let childElement = null;
	if ( !parent ) {
		return childElement;
	}
	if ( !parent.name ) {
		return childElement;
	}
	if ( parent.name == elementName ) {
		childElement = parent;
	} else if ( !!parent._children ) {
		if ( startIndex == 0 && endIndex == 0 ) {
			endIndex = parent._children._nodes.length - 1;
		}
		for ( let i = startIndex; i <= endIndex; i++ ) {
			let element = parent._children._nodes[ i ];
			childElement = findChildElement( element, 0, 0, elementName );
			if ( !!childElement ) {
				break;
			}
		}
	}
	return childElement;
}

export function findParentElement( editor, element, parentName ) {
	while ( element && ( ( element.name && element.name != parentName ) || !element.name ) &&
		element.parent && element.parent.name ) {
		element = element.parent;
	}
	if ( element && element.name == parentName ) {
		return element;
	}
	return null;
}


export function findAllElementsOfType( root, startPath, endPath, elementModelName ) {
	if ( !root || typeof root != "object" || !( startPath instanceof Array ) ||
		startPath.length < 1 || !( endPath instanceof Array ) || endPath.length < 1 ||
		typeof elementModelName != "string" ) return [];
	let elementsList = [];
	// findNextSibling
	if ( startPath[ 0 ] > endPath[ 0 ] ) {
		let tempPath = [ ...startPath ];
		startPath = [ ...endPath ];
		endPath = [ ...tempPath ];
	}
	let firstLevelChildren = [ ...root.getChildren() ];
	if ( startPath[ 0 ] + 1 > firstLevelChildren.length ) return [];
	if ( endPath[ 0 ] + 1 > firstLevelChildren.length )
		endPath = [ Number( firstLevelChildren.length - 1 ),
			Number( firstLevelChildren[ firstLevelChildren.length - 1 ].maxOffset )
		];
	if ( endPath[ 0 ] == startPath[ 0 ] ) {
		return;
	}
}

export function isProtoOfClass( element, className ) {
	if ( !element || !className ) return false;
	if ( element.toString().indexOf( className ) >= 0 ) return true;
	if ( typeof element == className ) return true;
	if ( !element.__proto__ || !element.__proto__.constructor ) return false;
	let constructor = element.__proto__.constructor.toString();
	if ( !constructor || !constructor.split( "{" ) || constructor.split( "{" ).length <= 0 ) return false;
	if ( constructor.split( "{" )[ 0 ].indexOf( className ) >= 0 ) return true;
	return false;
}

export function getObjectProto( object ) {
	if ( !object ) return "";
	let prototype = Object.getPrototypeOf( object );
	if ( !prototype || !prototype.constructor || !prototype.constructor.name ) return "";
	return prototype.constructor.name;
}

export function getReducedPathToElement( element ) {
	let ancestors = element.getAncestors();
	let absolutePath = [];
	for ( let ancestor of ancestors ) {
		if ( typeof ancestor.index != "number" || !ancestor.name ) continue;
		if ( ancestor.name == "table" || ancestor.name == "tbody" ) continue;
		if ( isProtoOfClass( ancestor, "AttributeElement" ) ) continue;
		absolutePath.push( ancestor.index );
	}
	return absolutePath;
}

export function offsetToPathPosition( editor, position ) {
	if ( !editor || !position || !position.parent ) return position;
	let path = getReducedPathToElement( position.parent );
	if ( !path || path.length <= 0 ) return position;
	position.offset ? path.push( position.offset ) : path.push( 0 );
	return pathToPosition( editor, path );
}

/**
 * Finds the closest element with given name (of given type) among the prevoius
 * elements that are children of the same parent;
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element to start with
 * @param {string} siblingName should be from editor.model.schema._sourceDefinitions
 * @return {module @ckeditor/ckeditor5-engine/src/model/element~Element} focusElement prevois sibling of the given "type" (name)
 */
export function findPrevoiusSibling( element, siblingName = "" ) {
	if ( element.name == "$root" ) {
		return null;
	}
	if ( siblingName ) {
		siblingName = element.name;
	}
	let focusElement = element;
	while ( !!focusElement.previousSibling ) {
		focusElement = focusElement.previousSibling;
		if ( focusElement.name == siblingName ) {
			break;
		}
	}
	if ( focusElement.name == siblingName ) {
		return focusElement;
	}
	return null;
}

/**
 * Finds the closest element with given name (of given type) among the next
 * elements that are children of the same parent;
 * @param {module @ckeditor/ckeditor5-engine/src/model/element~Element} element element to start with
 * @param {string} siblingName should be from editor.model.schema._sourceDefinitions
 * @return {module @ckeditor/ckeditor5-engine/src/model/element~Element} focusElement prevois sibling of the given "type" (name)
 */
export function findNextSibling( editor, element, siblingName = "" ) {
	if ( element.name == "$root" ) {
		return null;
	}
	if ( siblingName ) {
		siblingName = element.name;
	}
	let focusElement = element;
	while ( !!focusElement.nextSibling ) {
		focusElement = focusElement.nextSibling;
		if ( focusElement.name == siblingName ) {
			break;
		}
	}
	if ( focusElement.name == siblingName ) {
		return focusElement;
	}
	return null;
}

export function findTableCells( editor, selection = "" ) {
	let tableCells = [];
	selection = selection ? selection : editor.model.document.selection;
	let table = !!selection._ranges[ 0 ] ? findElementInSelection( editor, selection, "table" ) : "";
	if ( table && table._children && table._children._nodes && table._children._nodes.length > 0 ) {
		for ( let tableRow of table._children._nodes ) {
			if ( tableRow && tableRow._children && tableRow._children._nodes && tableRow._children._nodes.length > 0 ) {
				for ( let tableCell of tableRow._children._nodes ) {
					tableCells.push( tableCell );
				}
			}
		}
	}
	return tableCells;
}

export function stringToArray( stringArray ) {
	stringArray = typeof stringArray == "string" ?
		( stringArray.indexOf( " " ) >= 0 ? stringArray.split( " " ) :
			( stringArray.indexOf( "," ) >= 0 ? stringArray.split( "," ) :
				stringArray.split( ";" ) ) ) :
		( typeof stringArray == "object" ? stringArray : Array.from( stringArray ) );
	return stringArray;
}

export function closeDropdownOnBlur( dropdownView ) {
	const view = dropdownView;
	dropdownView.on( 'render', () => {
		clickOutsideHandler( {
			emitter: view,
			activator: () => view.isOpen,
			callback: () => {
				view.isOpen = false;
			},
			contextElements: [ view.element ]
		} );
	} );
}

export function clickOutsideHandler( { emitter, activator, callback, contextElements } ) {
	emitter.listenTo( document, 'click', ( evt, { target } ) => {
		if ( !activator() ) {
			return;
		}
		for ( const contextElement of contextElements ) {
			if ( contextElement.contains( target ) ) {
				return;
			}
		}
		callback();
	} );
}

export function addDropdownCloseListener( editor, dropdown ) {
	dropdown.on( "change:isOpen", ( evt, data, newVal, oldVal ) => {
		if ( newVal || !oldVal ) return;
		let panelBalloons = editor.plugins.get( "PisaPanelBalloons" );
		if ( !panelBalloons ) return;
		panelBalloons.hideAll();
	} );
}

// export function closeAllBalloons( editor, closeLinkBalloon = true ) {
// 	let panelBalloons = editor.balloons || editor.plugins.get( "PisaPanelBalloons" );
// 	panelBalloons && panelBalloons.hideAll();
// 	if ( !closeLinkBalloon ) return;
// 	let linkBalloon = editor.plugins.get( "ContextualBalloon" );
// 	if ( !linkBalloon ) return;
// 	linkBalloon.view.hide();
// 	linkBalloon.view.set( "isVisible", false );
// 	linkBalloon.view.isVisible = false;
// }
