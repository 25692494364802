import Command from '@ckeditor/ckeditor5-core/src/command';
import Validator from '../pisautils/validator';
import Warner from '../pisautils/warner';
import { PRINTCONTENT } from './pisaprint';

export default class PisaPrintCommand extends Command {

	constructor( editor ) {
		super( editor, PRINTCONTENT );
	}

	execute( options = {} ) {
		const editor = this.editor;
		// printEditorContent( editor );
		// printInBlankWindow( editor );
		if ( Validator.isObjectPath( window, "window.pisasales.Info" ) &&
			Validator.isFunction( window.pisasales.Info.isFF ) &&
			window.pisasales.Info.isFF() )
			this._printInNewWindow();
		else printHiddenIFrame( editor );
		editor.editing.view.focus();
	}

	_printInNewWindow() {
		this._printContentInNewWindow( this.editorContent );
	}

	get editorContent() {
		if ( !Validator.isObjectPath( this, "this.editor.data.processor" ) ) return void 0;
		if ( !Validator.isFunction( this.editor.data.processor.getCleanData ) ) return void 0;
		let cleanData = void 0;
		try {
			cleanData = String( this.editor.data.processor.getCleanData() );
		} catch ( err ) {
			Warner.outputIf( true, void 0, {
				error: true,
				errorText: err,
				collapse: true,
				text: `Could not retrieve the editor content for it to be printed.`,
				pluginName: `PisaPrintCommand`,
				color: 4
			} );
		}
		return Validator.isString( cleanData ) ? cleanData : void 0;
	}

	_printContentInNewWindow( content ) {
		if ( !Validator.isString( content ) ) return;
		let printWindow = window.open( 'about:blank', 'Print', '' );
		printWindow.document.write( content );
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	}

}

function printHiddenIFrame( editor ) {

	let editorStyle = editor.sourceElement.parentElement.getAttribute( "style" );
	let fontFamily = getProperty( "font-family", editorStyle );
	let fontSize = getProperty( "font-size", editorStyle );

	let printContent = '<html><head>' + getStyleTagContent( window ) + '</head>' +
		`<body style="overflow: visible; font-family: ${fontFamily}; font-size: ${fontSize};">` +
		'<div class="ck ck-editor_main ck-content ck-editor__editable ck-widget" role="textbox" aria-label="Rich Text Editor, main">' +
		updateImgSrcTags( window, editor.getData() ) + '</div></body></html>';

	var hiddenFrame = document.createElement( "iframe" );
	hiddenFrame.onload = () => {
		hiddenFrame.contentWindow.__container__ = hiddenFrame;
		hiddenFrame.contentWindow.onbeforeunload = closePrint;
		hiddenFrame.contentWindow.onafterprint = closePrint;
		hiddenFrame.contentWindow.focus();
		hiddenFrame.contentWindow.print();
	}
	// hiddenFrame.onload = setPrint;
	hiddenFrame.style.visibility = "hidden";
	hiddenFrame.style.position = "fixed";
	hiddenFrame.style.right = "0";
	hiddenFrame.style.bottom = "0";
	hiddenFrame.src = "about:blank";
	hiddenFrame.id = "hiddenFrame";
	hiddenFrame.srcdoc = printContent;
	hiddenFrame.sandbox = "allow-modal allow-popups allow-popups-to-escape-sandbox allow-modals allow-scripts allow-forms allow-same-origin";
	window.document.body.appendChild( hiddenFrame );
}

function getProperty( property, source ) {
	let result = "";
	if ( !!source ) {
		if ( source.indexOf( property ) >= 0 ) {
			result = source.substring( source.indexOf( property ) + property.length + 1 );
			if ( result.indexOf( ";" ) >= 0 ) {
				result = result.substring( 0, result.indexOf( ";" ) );
			}
		}
		return result.replace( /;/g, "" );
	}
	return result;
}

function getStyleTagContent( editorWindow ) {
	let styleString = "";
	let styleTags = editorWindow.document.getElementsByTagName( "style" );
	for ( let tag of styleTags ) {
		styleString = styleString + "<style>" + tag.innerHTML + "</style>";
	}
	return styleString;
}

function updateImgSrcTags( targetWindow, content ) {
	let div = targetWindow.document.createElement( "div" );
	div.innerHTML = content.toString();
	let images = div.getElementsByTagName( "img" );
	for ( let img of images ) {
		let source = img.getAttribute( "src" );
		let origin = targetWindow.location.origin;
		if ( source.charAt( 0 ) == "/" ) {
			img.setAttribute( "src", origin + source );
		}
	}
	return div.innerHTML;
}

function setPrint() {
	this.contentWindow.__container__ = this;
	this.contentWindow.onbeforeunload = closePrint;
	this.contentWindow.onafterprint = closePrint;
	this.contentWindow.focus();
	this.contentWindow.print();
}

function closePrint() {
	document.body.removeChild( this.__container__ );
}

function setBody( editor ) {
	let printContents = editor.getData();
	printContents = updateImgSrcTags( window, printContents );
	let div = document.createElement( "div" );
	div.innerHTML = printContents;
	div.setAttribute( "class", "ck ck-editor_main ck-content ck-editor__editable ck-widget" );
	div.setAttribute( "role", "textbox" );
	div.setAttribute( "aria-label", "Rich Text Editor, main" );
	return div;
}

function printEditorContent( editor ) {
	let styleTags = getStyleTagContent( window );
	let newWindow = window.open( "/psadbg/cke5/print.html" );
	newWindow.addEventListener( "beforeprint", () => {
		newWindow.document.body.appendChild( setBody( editor ) );
		newWindow.document.head.innerHTML = styleTags;
		//console.log(newWindow.document);
		newWindow.document.close();
	} );
	newWindow.focus();
	newWindow.print();
	newWindow.close();
}

function printInNewWindow( content ) {
	let printWindow = window.open( 'about:blank', 'Print', '' );
	printWindow.document.write( content );
	printWindow.document.close();
	printWindow.print();
}

function printInBlankWindow( editor ) {
	let styleTags = getStyleTagContent( window );
	let newWindow = window.open();
	newWindow.document.body.appendChild( setBody( editor ) );
	newWindow.document.head.innerHTML = getStyleTagContent( window );

	//console.log(newWindow.document);
	newWindow.document.close();
	newWindow.focus();
	newWindow.print();
	newWindow.close();
}
