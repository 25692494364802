import Validator from '../../pisautils/validator';
import AttachmentObject from '../../pisautils/attachmentobject';

export default class PlaceholderViewHighlighter extends AttachmentObject {

	constructor( hostPlugin ) {

		super( hostPlugin );

		// this._sayHi();

		if ( Validator.isObjectPath( hostPlugin,
				"hostPlugin.editor.editing.view.document.selection" ) )
			hostPlugin.editor.editing.view.document.selection
			.on( "change", ( eventInfo ) => {
				if ( !Validator.isFunction( hostPlugin.onViewChange ) ) return;
				hostPlugin.onViewChange( eventInfo );
			} );

	}

	activateFocusedPlaceholder() {
		if ( !Validator.isObject( this.lastFocusedPlaceholder ) ) return;
		if ( !Validator.isArray( this.lastFocusedPlaceholder ) )
			return this.upcastWriter.addClass( "pisa-focused-placeholder",
				this.lastFocusedPlaceholder );
		for ( let line of this.lastFocusedPlaceholder ) {
			this.upcastWriter.addClass( "pisa-focused-placeholder", line );
		}
	}

	deactivateFocusedPlaceholder() {
		if ( !Validator.isObject( this.lastFocusedPlaceholder ) ) return;
		if ( !Validator.isArray( this.lastFocusedPlaceholder ) ) {
			this.upcastWriter.removeClass( "pisa-focused-placeholder",
				this.lastFocusedPlaceholder );
			this.lastFocusedPlaceholder = void 0;
			return;
		}
		for ( let line of this.lastFocusedPlaceholder ) {
			this.upcastWriter.removeClass( "pisa-focused-placeholder", line );
		}
		this.lastFocusedPlaceholder = void 0;
	}

	onViewChange( eventInfo ) {
		if ( !this.changesAllowed ) return this.deactivateFocusedPlaceholder();
		let selection = this.editor.editing.view.document.selection;
		let anchorParent = Validator.couldBe( selection.anchor, "Position" ) ?
			selection.anchor.parent : void 0;
		if ( !Validator.isObject( anchorParent ) )
			return this.deactivateFocusedPlaceholder();
		anchorParent = this._getPlaceholderParent( anchorParent );
		if ( !Validator.couldBe( anchorParent, "Element" ) )
			return this.deactivateFocusedPlaceholder();
		let focusParent = Validator.couldBe( selection.focus, "Position" ) ?
			selection.focus.parent : void 0;
		if ( !Validator.isObject( focusParent ) )
			return this.deactivateFocusedPlaceholder();
		focusParent = this._getPlaceholderParent( focusParent );
		if ( !Validator.couldBe( focusParent, "Element" ) )
			return this.deactivateFocusedPlaceholder();
		if ( anchorParent != focusParent )
			return this.deactivateFocusedPlaceholder();
		if ( focusParent.name == "dfn" )
			return this._highlightInlinePlaceholder( focusParent );
		if ( focusParent.name != "div" || !Validator.isMap( focusParent._attrs ) ||
			!Validator.isString( focusParent._attrs.get( "data-title" ) ) ||
			!Validator.isString( focusParent._attrs.get( "data-value" ) ) )
			return this.deactivateFocusedPlaceholder();
		this._highlightMultilinePlaceholder( focusParent );
		// this.lastFocusedPlaceholder = anchorParent;
		// this.activateFocusedPlaceholder();
	};

	_highlightInlinePlaceholder( placeholder ) {
		this.lastFocusedPlaceholder = placeholder;
		this.activateFocusedPlaceholder();
	}

	_highlightMultilinePlaceholder( placeholder ) {
		this.lastFocusedPlaceholder = this._getMultilinePlaceholderViewLines( placeholder );
		this.activateFocusedPlaceholder();
	}

	_getMultilinePlaceholderViewLines( randomLine ) {
		let lines = [ randomLine ];
		const groupIndex = this._getViewGroupIndex( randomLine );
		if ( !Validator.isString( groupIndex ) ) return lines;
		let previousLine = randomLine;
		while ( this._isViewMultilinePlaceholder( previousLine.previousSibling ) &&
			this._getViewGroupIndex( previousLine.previousSibling ) == groupIndex ) {
			previousLine = previousLine.previousSibling;
			lines.unshift( previousLine );
		}
		let nextLine = randomLine;
		while ( this._isViewMultilinePlaceholder( nextLine.nextSibling ) &&
			this._getViewGroupIndex( nextLine.nextSibling ) == groupIndex ) {
			nextLine = nextLine.nextSibling;
			lines.push( nextLine );
		}
		return lines;
	}

	_getViewGroupIndex( multilinePlaceholderLine, asNumber = false ) {
		if ( !Validator.isObject( multilinePlaceholderLine ) ||
			!Validator.isMap( multilinePlaceholderLine._attrs ) ) return void 0;
		let groupIndex = multilinePlaceholderLine._attrs.get( "data-group-index" );
		return asNumber ? ( Validator.isPositiveInteger( groupIndex ) ? groupIndex :
				Number( groupIndex ) ) : Validator.isString( groupIndex ) ? groupIndex :
			String( groupIndex );
	}

	_isViewMultilinePlaceholder( element ) {
		return Validator.couldBe( element, "Element" ) && element.name == "div" &&
			Validator.isMap( element._attrs ) &&
			Validator.isString( element._attrs.get( "data-title" ) ) &&
			Validator.isString( element._attrs.get( "data-value" ) );
	}

}

// const ATTRIBUTES_TO_MIRROR = [ "activateFocusedPlaceholder",
// 	"deactivateFocusedPlaceholder", "onViewChange"
// ];
