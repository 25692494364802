import Command from '@ckeditor/ckeditor5-core/src/command';
import { SWITCH_VISIBILITY } from './pisaswitchvisibilitycommand';
import { enableAndExecute } from '../utils';

export const CHANGE_TOOLBAR = "pisaChangeToolbar";

export default class ChangeToolbarCommand extends Command {

	constructor( editor ) {
		super( editor, CHANGE_TOOLBAR );
	}

	execute( options = {} ) {
		const editor = this.editor;
		const toolbarItems = editor.ui.view.toolbar.items._items;
		if ( options.button ) {
			let index = 0;
			for ( let i = 0; i < toolbarItems.length; i++ ) {
				if ( toolbarItems[ i ] == options.button ) {
					index = i;
					break;
				}
			}
			options.value = index;
		}
		if ( typeof options.value == "number" ) {
			for ( let i = parseInt( options.value ) + 1; i < toolbarItems.length; i++ ) {
				enableAndExecute( editor, SWITCH_VISIBILITY, { view: toolbarItems[ i ], visible: false } );
			}
		} else {
			const showAll = !( options.hide == true || options.hide == "true" ||
				options.show == false || options.show == "false" ||
				options.hideAll == true || options.hideAll == "true" ||
				options.showAll == false || options.showAll == "false" ||
				options.value == "hide" || options.value == "hideAll" );
			for ( let item of toolbarItems ) {
				enableAndExecute( editor, SWITCH_VISIBILITY, { view: item, visible: showAll } );
			}
		}
	}
}
