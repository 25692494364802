import AttachmentObject from '../pisautils/attachmentobject';
import Validator from '../pisautils/validator';
import HtmHelper from '../pisautils/htmhelper';
import Warner from '../pisautils/warner';

export default class WatchdogHelpers extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );

		this._addUnmodifiableGetter( {
			hostPlugin: hostPlugin,
			propertyName: "editableElement",
			getCallback: () => {
				return !Validator.isObjectPath( hostPlugin, "hostPlugin.editor.ui.view.editable" ) ||
					!HtmHelper.isHtmlElement( hostPlugin.editor.ui.view.editable.element ) ? void 0 :
					hostPlugin.editor.ui.view.editable.element;
			},
			setCallback: ( newEditableElementValue ) => {}
		} );

	}

	_addCaptureErrorHandling() {
		this._boundCaptureErrorHandler = evt => {
			const error = evt.error || evt.reason;
			// Warner.nameTrace( `CAPTURE ERROR` );
		};
		window.addEventListener( 'error', this._boundCaptureErrorHandler, true );
		window.addEventListener( 'unhandledrejection', this._boundCaptureErrorHandler, true );
	}

	_setWindowOnErrorFunction() {
		this._onWindowError = evt => {
			const error = evt.error || evt.reason;
			// Warner.nameTrace( `WINDOW ERROR` );
		}
		if ( Validator.isFunction( window.onerror ) ) return false;
		window.onerror = function( evt ) {
			if ( !Validator.isObjectPath( window, "window.pisasales" ) ||
				!Validator.isFunction( window.pisasales.getCke5ObjReg ) ) return;
			const editorRegistry = window.pisasales.getCke5ObjReg();
			if ( !Validator.is( editorRegistry, "ObjReg" ) ) return;
			if ( !Validator.isMap( editorRegistry._objReg, true ) ) return;
			const iterator = editorRegistry._objReg.values();
			if ( !Validator.isIterable( iterator ) ) return;
			const editorInstances = [ ...iterator ];
			if ( !Validator.isInteger( editorInstances.length ) ||
				editorInstances.length < 1 ) return;
			const firstEntry = editorInstances[ 0 ];
			if ( !Validator.is( firstEntry, "CkEdt5" ) ) return;
			const watchdog = firstEntry.watchdog;
			if ( !Validator.is( watchdog, "PisaEditorWatchdog" ) ) return;
			watchdog._onWindowError( evt );
		};
		return true;
	}

	_changeRwtErrorHandling() {
		this._onRwtError = err => {
			if ( Validator.isObject( err ) && !Validator.isString( err.wdgId ) )
				err.wdgId = this.wdgId;
			Warner.nameTrace( `The watchdog for the editor with the widget ID` +
				` "${ this.wdgId}" detected an JavaScript Error that was originally` +
				` supposed to be handled by the runtime error handler` +
				` <window.rwt.runtime.ErrorHandler~processJavaScriptError>, but will` +
				` be handled by this watchdog instead.` );
			this._handleError( err );
		}
		if ( !Validator.isObjectPath( window.rwt, "rwt.runtime.ErrorHandler" ) ||
			!Validator.isFunction( window.rwt.runtime.ErrorHandler.processJavaScriptError ) )
			return false;
		if ( !Validator.isFunction( window.rwt.runtime.ErrorHandler._processJavaScriptError ) )
			window.rwt.runtime.ErrorHandler._processJavaScriptError =
			window.rwt.runtime.ErrorHandler.processJavaScriptError
		window.rwt.runtime.ErrorHandler.processJavaScriptError = ( err ) => {
			if ( !Validator.isObjectPath( window, "window.pisasales" ) ||
				!Validator.isFunction( window.pisasales.getCke5ObjReg ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const editorRegistry = window.pisasales.getCke5ObjReg();
			if ( !Validator.is( editorRegistry, "ObjReg" ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			if ( !Validator.isMap( editorRegistry._objReg, true ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const iterator = editorRegistry._objReg.values();
			if ( !Validator.isIterable( iterator ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const editorInstances = [ ...iterator ];
			if ( !Validator.isInteger( editorInstances.length ) ||
				editorInstances.length < 1 )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const firstEntry = editorInstances[ 0 ];
			if ( !Validator.is( firstEntry, "CkEdt5" ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const watchdog = firstEntry.watchdog;
			if ( !Validator.is( watchdog, "PisaEditorWatchdog" ) )
				return window.rwt.runtime.ErrorHandler._processJavaScriptError( err );
			const lastFocusedWatchdog = watchdog.lastFocusedWatchdog;
			if ( Validator.is( lastFocusedWatchdog, "PisaEditorWatchdog" ) )
				return lastFocusedWatchdog._onRwtError( err );
			watchdog._onRwtError( err );
		};
	}

	_changeRwtMessageProcessorErrorHandling() {
		this._onRwtMessageProcessorError = err => {
			if ( Validator.isObject( err ) && !Validator.isString( err.wdgId ) )
				err.wdgId = this.wdgId;
			Warner.nameTrace( `The watchdog for the editor with the widget ID` +
				` "${ this.wdgId}" detected an JavaScript Error that was originally` +
				` supposed to be handled by the runtime message processor` +
				` <window.rwt.remote.MessageProcessor._processError>, but will` +
				` be handled by this watchdog instead.` );
			this._handleError( err );
		}
		if ( !Validator.isObjectPath( window.rwt, "rwt.remote.MessageProcessor" ) ||
			!Validator.isFunction( window.rwt.remote.MessageProcessor._processError ) )
			return false;
		if ( !Validator.isFunction( window.rwt.remote.MessageProcessor._doProcessError ) )
			window.rwt.remote.MessageProcessor._doProcessError =
			window.rwt.remote.MessageProcessor._processError;
		window.rwt.remote.MessageProcessor._processError = ( err ) => {
			if ( !Validator.isObjectPath( window, "window.pisasales" ) ||
				!Validator.isFunction( window.pisasales.getCke5ObjReg ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const editorRegistry = window.pisasales.getCke5ObjReg();
			if ( !Validator.is( editorRegistry, "ObjReg" ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			if ( !Validator.isMap( editorRegistry._objReg, true ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const iterator = editorRegistry._objReg.values();
			if ( !Validator.isIterable( iterator ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const editorInstances = [ ...iterator ];
			if ( !Validator.isInteger( editorInstances.length ) ||
				editorInstances.length < 1 )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const firstEntry = editorInstances[ 0 ];
			if ( !Validator.is( firstEntry, "CkEdt5" ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const watchdog = firstEntry.watchdog;
			if ( !Validator.is( watchdog, "PisaEditorWatchdog" ) )
				return window.rwt.remote.MessageProcessor._doProcessError( err );
			const lastFocusedWatchdog = watchdog.lastFocusedWatchdog;
			if ( Validator.is( lastFocusedWatchdog, "PisaEditorWatchdog" ) )
				return lastFocusedWatchdog._onRwtMessageProcessorError( err );
			watchdog._onRwtMessageProcessorError( err );
		}
	}

	letAnotherWatchdogHandleTheError( watchdog, error, evt ) {
		if ( !Validator.is( watchdog, "PisaEditorWatchdog" ) || watchdog === this ||
			!Validator.isObject( error ) ) return false;
		if ( Validator.isString( watchdog.wdgId ) &&
			!Validator.isString( error.wdgId ) )
			error.wdgId = watchdog.wdgId;
		watchdog._handleError( error, evt );
		return true;
	}

	_destroyDocumentView() {
		if ( !Validator.isObjectPath( this,
				"this.editor.editing.view.document" ) ) return false;
		Validator.removeSetterAndSet(
			this.editor.editing.view, "_postFixersInProgress", false );
		Validator.removeSetterAndSet(
			this.editor.editing.view, "isRenderingInProgress", false );
		Validator.removeSetterAndSet(
			this.editor.editing.view.document, "isReadOnly", true );
		if ( Validator.isFunction( this.editor.editing.view.destroy ) )
			this.editor.editing.view.destroy();
		if ( Validator.isFunction( this.editor.editing.destroy ) )
			this.editor.editing.destroy();
		return true;
	}

	_removeSourceElementReference() {
		if ( !Validator.isObjectPath( this, "this.editor.sourceElement" ) )
			return false;
		this.editor.sourceElement = void 0;
		delete this.editor.sourceElement;
		return true;
	}

	hideAndFreezeBalloons() {
		const allBalloonsClosed = this.closeAllBalloons();
		const allBalloonsFrozen = this.freezeAllBalloons();
		return allBalloonsClosed && allBalloonsFrozen;
	}

	closeAllBalloons() {
		const editingBalloonsClosed = this.closeEditingBalloons();
		const linkBalloonsClosed = this.closeLinkBalloons();
		return editingBalloonsClosed && linkBalloonsClosed;
	}

	closeEditingBalloons() {
		if ( !Validator.isObjectPath( this, "this.editor.balloons" ) ||
			!Validator.isFunction( this.editor.balloons.hideAll ) ) return false;
		this.editor.balloons.hideAll();
		return true;
	}

	closeLinkBalloons() {
		if ( !Validator.isObjectPath( this, "this.editor.objects.linkBalloon" ) ||
			!Validator.isFunction( this.editor.objects.linkBalloon.hide ) ) return false;
		this.editor.objects.linkBalloon.hide();
		return true;
	}

	freezeAllBalloons() {
		const editingBalloonsFrozen = this.freezeEditingBalloons();
		const linkBalloonsFrozen = this.freezeLinkBalloons();
		return editingBalloonsFrozen && linkBalloonsFrozen;
	}

	freezeEditingBalloons() {
		if ( !Validator.isObjectPath( this, "this.editor.balloons" ) ||
			!Validator.isFunction( this.editor.balloons.freeze ) ) return false;
		this.editor.balloons.freeze();
		return true;
	}

	freezeLinkBalloons() {
		if ( !Validator.isObjectPath( this, "this.editor.objects.linkBalloon" ) ||
			!Validator.isFunction( this.editor.objects.linkBalloon.freeze ) ) return false;
		this.editor.objects.linkBalloon.freeze();
		return true;
	}

	_destroyPsaExt() {
		let psaExt = this.psaExt;
		if ( !Validator.isObject( psaExt ) ||
			!Validator.isFunction( psaExt.destroy ) ) return false;
		psaExt.destroy();
		this.psaExt = void 0;
		return true;
	}

}
