
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import PisaSubscriptEditing from './pisasubscriptediting';
import PisaSubscriptUI from './pisasubscriptui';

export default class PisaSubscript extends Plugin {

	static get requires() {
		return [ PisaSubscriptEditing, PisaSubscriptUI ];
	}

	static get pluginName() {
		return 'PisaSubscript';
	}
}
