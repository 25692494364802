import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { ALIGN_TABLE, PLACE_TABLE } from './pisatableui';
import Validator from '../pisautils/validator';

const STYLE_TAG = "style";
const TABLE_TAG = "table";
const BORDER_COLLAPSE_VIEW_ATTRIBUTE = "border-collapse";
const BORDER_COLLAPSE_MODEL_ATTRIBUTE = "borderCollapse";
const ADD_TABLE_UPCAST_EVENT = "element:table";

export const marginInlineStart = [ '0pt', 'p', 'm', '36pt', '72pt', '108pt', '144pt', '180pt', '216pt', '252pt', '288pt' ];
export const TABLE_PLACEMENT_OPTIONS = [ 'table !important', 'inline-table !important' ];
export const TABLE_BORDER_COLLAPSE_OPTIONS = [ "collapse" ];

export default class PisaTableEditing extends Plugin {

	constructor( editor ) {
		super( editor );

		editor.config.define( ALIGN_TABLE, {
			options: marginInlineStart
		} );

		editor.config.define( PLACE_TABLE, {
			options: TABLE_PLACEMENT_OPTIONS
		} );

		editor.config.define( BORDER_COLLAPSE_MODEL_ATTRIBUTE, {
			options: TABLE_BORDER_COLLAPSE_OPTIONS
		} );

	}

	init() {

		this._convertStyleAttribute( [ {
			model: ALIGN_TABLE,
			view: "margin-inline-start"
		}, {
			model: PLACE_TABLE,
			view: "display"
		}, {
			model: BORDER_COLLAPSE_MODEL_ATTRIBUTE,
			view: BORDER_COLLAPSE_VIEW_ATTRIBUTE
		} ] );

		this.addBorderCollapsePostFixer();
		this.addEditingDowncastDispatcherForCollapsedBorderOnTables();
	}

	addBorderCollapsePostFixer() {
		const editor = this.editor;

		// please make sure that this post fixer DOES NOT return true, unless
		// you want to cause an infinite loop
		editor.model.document.registerPostFixer( writer => {
			const changes = editor.model.document.differ.getChanges();
			for ( const entry of changes ) {
				if ( entry.name != "table" || entry.type != "insert" ) continue;
				let tableFigure = entry.position.nodeAfter;
				writer.setAttribute( BORDER_COLLAPSE_MODEL_ATTRIBUTE,
					TABLE_BORDER_COLLAPSE_OPTIONS[ 0 ], tableFigure );
			}
		} );

		delete this.addBorderCollapsePostFixer;
	}

	addEditingDowncastDispatcherForCollapsedBorderOnTables() {
		this.editor.conversion.for( 'editingDowncast' )
			.add( this._getDowncastTableDispatcher() );

		delete this._getDowncastTableDispatcher;
		delete this.addEditingDowncastDispatcherForCollapsedBorderOnTables;
	}

	_convertStyleAttribute( attributeNames ) {
		if ( !( attributeNames instanceof Array ) ) return;
		attributeNames = attributeNames.filter( name =>
			!!name && typeof name == "object" && !!name.model &&
			typeof name.model == "string" && name.model.length > 0 &&
			!!name.view && typeof name.view == "string" && name.view.length > 0 );
		if ( attributeNames.length < 1 ) return;
		let paths = [ "this.editor.conversion", "this.editor.model.schema" ];
		attributeNames.forEach( name => {
			paths.push( `this.editor.config._config.${ name.model }.options` );
		} );
		for ( let path of paths ) {
			if ( this._isValidObjectOrPath( this, path ) ) continue;
			console.warn( `Could not add style attribute conversion to CK Editor 5 ` +
				`because the path "${ path }" is invalid.` );
			return;
		}
		const conversion = this.editor.conversion;
		if ( typeof conversion.attributeToAttribute != "function" ) return;
		const schema = this.editor.model.schema;
		if ( typeof schema.extend != "function" ) return;
		// the actual conversion
		attributeNames.forEach( name => {
			const options = this.editor.config._config[ `${ name.model }` ].options;
			if ( !( options instanceof Array ) ) return;
			schema.extend( TABLE_TAG, { allowAttributes: name.model } );
			const definition = _buildDefinition( options, name.model, name.view );
			conversion.attributeToAttribute( definition );
		} );
	}

	_isValidObjectOrPath( parentObject, pathString ) {
		return this.editor.data.processor._isValidObjPath( parentObject, pathString );
	}

	_getDowncastTableDispatcher() {
		// SMALLEST priority assures that this dispatcher will react to the
		// insertion AFTER the default dispatcher (the one that creates the figure,
		// the table etc.) does
		return dispatcher => dispatcher.on( 'insert:table',
			this._downcastDispatcherOnInsertTable(), { priority: Number.MIN_SAFE_INTEGER } );
	}

	_downcastDispatcherOnInsertTable() {
		return ( evt, data, conversionApi ) => {
			if ( !Validator.isFunctionPath( conversionApi,
					"conversionApi.mapper.toViewPosition" ) ) return;
			if ( !Validator.isArrayPath( data, "data.range.start" ) );
			const viewPosition = conversionApi.mapper.toViewPosition( data.range.start );
			if ( !Validator.couldBe( viewPosition, "Position" ) ||
				!Validator.couldBe( viewPosition.nodeAfter, "ContainerElement" ) ||
				viewPosition.nodeAfter.name != "figure" ) return;
			if ( !Validator.isArray( viewPosition.nodeAfter._children, true ) ) return;
			const tableContainerElement = viewPosition.nodeAfter._children.find(
				child => Validator.couldBe( child, "ContainerElement" ) && child.name == "table" );
			if ( !Validator.isObject( tableContainerElement ) ||
				!Validator.couldBe( tableContainerElement._styles, "StylesMap" ) ) return;
			tableContainerElement._styles.set( BORDER_COLLAPSE_VIEW_ATTRIBUTE,
				TABLE_BORDER_COLLAPSE_OPTIONS[ 0 ] );
		};
	}

}

function _buildDefinition( options, modelName, viewName ) {
	const definition = {
		model: {
			key: modelName,
			values: options.slice()
		},
		view: {}
	};

	for ( const option of options ) {
		let value = {};
		value[ viewName ] = option;
		definition.view[ option ] = {
			key: STYLE_TAG,
			value: value
		};
	}

	return definition;
}
