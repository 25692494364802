import Command from '@ckeditor/ckeditor5-core/src/command';
import { ALIGN_TABLE } from './pisatableui';
import { INCREASE_TABLE_MARGIN } from './pisatableui';
import { /*isInsideTable,*/ selectionHasTable } from './pisadecreasemargincommand';
import { lastValidSelection } from '../pisafire/pisafirecommand';
import { setSelection } from '../utils';

export default class PisaIncreaseMarginCommand extends Command {

	constructor( editor ) {
		super( editor, INCREASE_TABLE_MARGIN );
	}

	refresh() {
		this.isEnabled = selectionHasTable( this.editor );
	}

	execute( options = {} ) {
		const editor = this.editor;
		const selection = lastValidSelection( editor, options );
		editor.execute( ALIGN_TABLE, { value: "p" } );
		selection && setSelection( editor, selection.getFirstRange() );
	}
}
